export const lisKategoriLokasi = [
  "PENDIDIKAN",
  "PELAYANAN KESEHATAN",
  "KANTOR PEMERINTAHAN",
  "LOKASI PUBLIK",
  "PUSAT KEGIATAN MASYARAKAT",
  "PELAYANAN USAHA",
  "LOKASI WISATA",
  "TEMPAT IBADAH",
  "PERTAHANAN & KEAMANAN",
];

export const listProvinsi = [
  "Aceh",
  "Sumatera Utara",
  "Sumatera Barat",
  "Riau",
  "Kepulauan Riau",
  "Jambi",
  "Sumatera Selatan",
  "Kepulauan Bangka Belitung",
  "Bengkulu",
  "Lampung",
  "DKI Jakarta",
  "Jawa Barat",
  "Banten",
  "Jawa Tengah",
  "DI Yogyakarta",
  "Jawa Timur",
  "Bali",
  "Nusa Tenggara Barat",
  "Nusa Tenggara Timur",
  "Kalimantan Barat",
  "Kalimantan Tengah",
  "Kalimantan Selatan",
  "Kalimantan Timur",
  "Kalimantan Utara",
  "Sulawesi Utara",
  "Sulawesi Barat",
  "Sulawesi Tengah",
  "Sulawesi Tenggara",
  "Sulawesi Selatan",
  "Gorontalo",
  "Maluku",
  "Maluku Utara",
  "Papua Barat",
  "Papua",
];
