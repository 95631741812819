import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import API from '../../../../services';

export default function DeleteNewsletter(props) {
    var { modal, toggle, data, refresh } = props;
    // console.log(props)
    const handleDelete = (val) => {
        let param = {
            email: val.email
        }
        API.deleteNewsletter(param).then(resoponse => {
            toggle()
            refresh()
            // window.location.reload()
        }).catch()
    }
    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Hapus</ModalHeader>
                <ModalBody className="text-center">
                    <h5 style={{ color: '#393939' }}>
                        {data !== undefined && data.email}
                    </h5>
                    <hr width={140} />
                    <p>Anda yakin ingin Hapus Newsletter ?</p>
                    <div className="m-2">
                        <Button onClick={() => handleDelete(data)} style={{ backgroundColor: "#C42127" }}>Hapus</Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
