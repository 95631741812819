import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDFVisitor = (dataTable, activeTab) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title = `Data Survey Kepuasan ${activeTab}`;
  const headers = [["No", "Nama", "Lokasi", "Tanggal", "Rating", "Komentar"]];

  const data = dataTable.map((data, index) => {
    return [
      index + 1,
      data.name ? data.name : "-",
      data.location,
      moment(data.tanggal_pembuatan).format("DD-MM-YYYY"),
      data.rating,
      data.comment,
    ];
  });
  let a = 0;
  let content = {
    startY: 50,
    head: headers,
    body: data,
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`Survey Kepuasan_Report.pdf`);
};
