// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_headText__3ELSv {\n\ttext-align: center !important;\n\tmargin-left: 40px !important;\n\tcolor: #e15050 !important;\n}\n\n.styles_title__2dtOx {\n\tcolor: #333;\n\tfont-weight: bold;\n\ttext-transform: uppercase;\n}\n\n.styles_sub_title__38UwX {\n\tcolor: #777;\n}\n\n.styles_text__Lkwy7 {\n\tcolor: #777;\n\tfont-weight: bold;\n\tmargin-bottom: 50px;\n}\n\n.styles_input_alasan__3qRO2 {\n\ttext-align: center;\n\theight: 150px;\n\twidth: 50%;\n\tdisplay: flex;\n\tbackground-color: #f4f5ff;\n\tborder: none !important;\n\tmargin-bottom: 6% !important;\n}\n\n.styles_btn_cancel__3vLsq {\n\tcursor: pointer;\n}\n\n.styles_wrapper__BubBx {\n\tmargin-bottom: 50px;\n\tdisplay: block !important;\n}\n\n.styles_btn_confirm__2gVjZ {\n\tbackground-color: #e15050 !important;\n\tpadding: 10px 40px !important;\n\tcolor: #fff !important;\n\tcursor: pointer;\n\tfont-size: 18px !important;\n\tmargin-bottom: 10px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"headText": "styles_headText__3ELSv",
	"title": "styles_title__2dtOx",
	"sub_title": "styles_sub_title__38UwX",
	"text": "styles_text__Lkwy7",
	"input_alasan": "styles_input_alasan__3qRO2",
	"btn_cancel": "styles_btn_cancel__3vLsq",
	"wrapper": "styles_wrapper__BubBx",
	"btn_confirm": "styles_btn_confirm__2gVjZ"
};
module.exports = exports;
