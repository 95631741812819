import React, { useEffect } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);

  return (
    <Table
      style={{ display: "none" }}
      id="DownloadTableXLSLokasiKabupaten"
      className="DownloadTableXLS">
      <thead>{`DAFTAR LIST KABUPATEN/KOTA DAN LOKASI DI PROVINSI ${props.prov}`}</thead>
      {/* <br /> */}
      <thead>
        <tr>{`PERIODE CUT OFF TANGGAL 01/01/2020 - ${props.date}`}</tr>
      </thead>
      <thead>
        <tr>
          <th>Kabupaten/Kota</th>
          <th>Nama Lokasi</th>
          {/* <th>Jumlah Lokasi AI Terintegrasi</th>  */}
          <th>Jumlah IP Terintegrasi</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
            <td>{data.kab_kot ?? "-"}</td>
            <td>{data.location ?? "-"}</td>
            {/* <td>{data.ai ?? "-"}</td> */}
            <td>{data.ip ?? "-"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
