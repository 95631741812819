import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import API from "../../../../services";

export default function StatusPopup(props) {
  let { modal, toggle, data } = props;

  const handleStatus = (val) => {
    let typeOfContent;

    if (props.typeContent) {
      typeOfContent = props.typeContent.charAt(0).toUpperCase() + props.typeContent.slice(1);
    }

    if (typeOfContent !== "Video") {
      let param = {
        idArticle: val._id,
      };

      API.postChangeStatusArticle(param)
        .then((response) => {
          props.refreshPage();
          window.location.reload();
        })
        .catch((e) => {
          console.error('StatusPopup', { error: e.message })
        });
    } else {
      let param = {
        idVideoHome: val?._id,
      };

      API.changeStatusVideo(param)
        .then((response) => {
          props.refreshPage();
          window.location.reload();
        })
        .catch((e) => {
          console.error('StatusPopup', { error: e.message })
        });
    }
  };

  const typeContent = props.typeContent ? props.typeContent : "Artikel";

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>NONAKTIFKAN {typeContent.toUpperCase()}</ModalHeader>
        <ModalBody className="text-center">
          <h3 style={{ color: "#393939" }}>{data !== undefined && data.judul}</h3>
          <hr width={140} />
          <p>Anda yakin ingin Posting {typeContent} ini pada Homepage?</p>
          <div className="m-2">
            <Button onClick={() => handleStatus(data)} style={{ backgroundColor: "#C42127" }}>
              Non Aktif
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
