// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_headText__28prP {\n\ttext-align: center !important;\n\tmargin-left: 30px !important;\n\tcolor: #C42127 !important;\n}\n\n.styles_title__iJeoX {\n\tcolor: #333;\n\tfont-weight: bold;\n\ttext-transform: uppercase;\n}\n\n.styles_sub_title__QOVcD {\n\tcolor: #777;\n}\n\n.styles_text__2fmFC {\n\tcolor: #777;\n\tfont-weight: bold;\n\tmargin-bottom: 50px;\n}\n\n.styles_input_alasan__1sh-5 {\n\ttext-align: center;\n\theight: 150px;\n\twidth: 50%;\n\tdisplay: flex;\n\tbackground-color: #f4f5ff;\n\tborder: none !important;\n\tmargin-bottom: 6% !important;\n}\n\n.styles_btn_cancel__1e1GE {\n\tcursor: pointer;\n}\n\n.styles_wrapper__3G7UP {\n\tmargin-bottom: 50px;\n\tdisplay: block !important;\n}\n\n.styles_btn_confirm__3nivc {\n\tbackground-color: #e15050 !important;\n\tpadding: 10px 40px !important;\n\tcolor: #fff !important;\n\tcursor: pointer;\n\tfont-size: 18px !important;\n\tmargin-bottom: 10px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"headText": "styles_headText__28prP",
	"title": "styles_title__iJeoX",
	"sub_title": "styles_sub_title__QOVcD",
	"text": "styles_text__2fmFC",
	"input_alasan": "styles_input_alasan__1sh-5",
	"btn_cancel": "styles_btn_cancel__1e1GE",
	"wrapper": "styles_wrapper__3G7UP",
	"btn_confirm": "styles_btn_confirm__3nivc"
};
module.exports = exports;
