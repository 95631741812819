import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  UncontrolledDropdown,
  Card,
  DropdownToggle,
  Container,
} from "reactstrap";
import Header from "../../components/VerticalLayout/Header";
import API from "../../services";
import ReactApexChart from "react-apexcharts";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { addDays, addMonths, differenceInDays, differenceInMonths } from "date-fns";
import moment from "moment";
import {
  MDBContainer,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBDataTable,
} from "mdbreact";
import "./style.css";
import { FilterIP, FilterLocation } from "./dropdown";

import { set } from "date-fns";

const SiteStatistics = (props) => {
  const [location, setLocation] = useState("");
  const [ip, setIp] = useState([]);
  const [ipAddress, setIpAddress] = useState("");
  const [modalDate, setModalDate] = useState(false);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [siteStat, setSiteStat] = useState({});
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  const [table, setTable] = useState({
    columns: [],
    rows: [],
  });

  const [isp, setIsp] = useState({
    provinsi: "",
    longitude: "",
    kabupaten: "",
    latitude: "",
    nama_lokasi: "",
    terakhir_diakses: "",
  });

  const searchOnClick = (e) => {
    if (location) {
      API.postGetIPAddress({
        location: location,
      }).then((result) => {
        if ((result.statusCode = 200)) {
          setIp(result.data.data);
        } else if ((result.statusCode = 404)) {
        } else {
          alert("Error");
        }
      });
    }
  };

  const selectDateOnClick = (e) => {
    setModalDate(true);
  };

  const ipAddressOnChange = async (val) => {
    setIpAddress(val);
    if (val != 0) {
      API.postGetISP({
        ip: val,
      }).then((result) => {
        if ((result.statusCode = 200)) {
          const data = result.data.data;
          setIsp({
            provinsi: data.provinsi,
            longitude: data.longitude,
            kabupaten: data.kabupaten_kota,
            latitude: data.latitude,
            nama_lokasi: data.nama_lokasi_baru,
            terakhir_diakses: data.last_access
              ? moment(data.last_access).format("DD / MM / YYYY")
              : "No Data",
          });
        } else if ((result.statusCode = 404)) {
          // console.log("No data")
        } else {
          alert("Error");
        }
      });
    }
  };

  const [state, setState] = useState(
    [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      }
    ]
  );
  const handleChange = (date) => {
    let firstState = [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ];
    if (state == firstState) {
      setState([
        {
          startDate: date[0].startDate,
          endDate: addDays(date[0].endDate, 7),
          key: "selection",
        },
      ]);
    } else {
      setState(date);
    }
  };
  const handleCheckTanggal = () => {
    toggleCalendar();
    setDate(state[0]);
    API.postGetSiteStatistics({
      ip: ipAddress,
      start: moment(state[0].startDate).format("DD/MM/YYYY"),
      end: moment(state[0].endDate).format("DD/MM/YYYY"),
    })
      .then((result) => {
        if ((result.statusCode = 200)) {
          const data = result.data.data;
          setSiteStat(data);
        } else if ((result.statusCode = 404)) {
          // console.log("No data")
        } else {
          alert("Error");
        }
      })
      .catch((e) => {
        setSiteStat([]);
        alert("No Data");
      });
  };
  const toggleCalendar = () =>{
    setModalDate(!modalDate)
  }

  useEffect(() => {
    setOption({
      xaxis: {
        categories: Object.keys(siteStat),
      },
    });
    setSeries([
      {
        name: "Data",
        data: Object.values(siteStat),
      },
    ]);
    setTable({
      columns: [
        {
          label: "#",
          field: "no",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Tanggal",
          field: "tanggal",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Jumlah Kunjungan",
          field: "dfp",
          sort: "disabled",
          width: 200,
        },
      ],
      rows: Object.entries(siteStat).map(([k, v], i) => {
        return {
          no: i,
          tanggal: k,
          dfp: v,
        };
      }),
    });
    return () => {};
  }, [siteStat]);

  const [locationList, setLocationList] = useState([]);
  useEffect(() => {
    API.getLocationStatistic()
      .then((res) => {
        if (res.data.success) {
          const data = res.data.data;
          setLocationList(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="Site Statistic" />
        </header>
      </div>
      <main>
        <div className="container-fluid">
          <MDBContainer fluid={true}>
            <Card style={{ padding: "8px" }}>
              <Row md="4">
                <Col>
                  <FilterLocation list={locationList} clicked={(value) => setLocation(value)} />
                </Col>
                <Col>
                  <Button className="btn btnDelete btn-rounded" onClick={searchOnClick}>
                    Cari
                  </Button>
                </Col>
              </Row>
            </Card>
            <Card
              style={{
                padding: "8px",
                paddingLeft: "32px",
                paddingRight: "32px",
                alignItems: "center",
              }}>
              <Row style={{ padding: "16px" }}>
                <FilterIP
                  type={"IP Address"}
                  filter={ipAddress}
                  location={location}
                  clicked={ipAddressOnChange}
                  list={ip}
                />
              </Row>
              <Row className="labelData">
                {Object.entries(isp).map(([k, v]) => (
                  <React.Fragment>
                    <Col md="2" sm="2">
                      <p className="label">
                        {k
                          .split("_")
                          .map((e) => e.charAt(0).toUpperCase().concat(e.slice(1)))
                          .join(" ")}
                      </p>
                    </Col>
                    <Col md="2" sm="2" className="label">
                      :
                    </Col>
                    <Col md="2" sm="2" className="value">
                      {v}
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            </Card>
            <Card
              style={{
                padding: "8px",
                paddingLeft: "32px",
                paddingRight: "32px",
                alignItems: "center",
              }}>
              <Row>
                <Button
                  className="btn btnDelete btn-rounded"
                  onClick={selectDateOnClick}
                  block
                  disabled={!ipAddress}>
                  {Object.values(date).every((e) => e != "")
                    ? `${moment(date.startDate).format("DD/MM/YYYY")} - ${moment(date.endDate).format("DD/MM/YYYY")}`
                    : "Select Date"}
                </Button>
              </Row>
              <Row>
                <MDBModal centered={true} isOpen={modalDate} toggle={toggleCalendar} size="lg">
                  <MDBModalHeader className="text-center displayContent ">
                    <p className="mx-auto">Date Picker</p>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <Container>
                      <Row className="rowCalendar">
                        <DateRangePicker
                          className="mx-auto"
                          onChange={(item) => handleChange([item.selection])}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          direction="horizontal"
                          months={2}
                          maxDate={new Date()}
                          ranges={state}
                          direction="horizontal"
                        />
                      </Row>
                      <Row>
                        <Button
                          color=""
                          onClick={handleCheckTanggal}
                          className="btn btn-rounded btnCekJam mx-auto">
                          Ok
                        </Button>
                      </Row>
                    </Container>
                  </MDBModalBody>
                </MDBModal>
              </Row>
              <Row>
                {series.length != 0 && (
                  <ReactApexChart
                    id="siteStat"
                    options={option}
                    series={series}
                    type="line"
                    height="400"
                    width="700"
                  />
                )}
              </Row>
              <Row>
                {table.rows.length != 0 && (
                  <MDBDataTable
                    responsive
                    striped
                    searching={false}
                    displayEntries={false}
                    data={table}
                  />
                )}
              </Row>
            </Card>
          </MDBContainer>
        </div>
      </main>
    </React.Fragment>
  );
};

export default SiteStatistics;
