import React, { useState } from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
// import "./style.css";
import { Container, Button, Row, Col } from "reactstrap";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
// ------------------REDUX---------------------
import moment from "moment";

const ModalCalendar = (props) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [dateRange, setDateRange] = useState([]);

  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];
    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const handleChange = (date) => {
    setState([
      {
        startDate: date[0].startDate,
        endDate: date[0].endDate,
        key: "selection",
      },
    ]);
    setDateRange(enumerateDaysBetweenDates(moment(date[0].startDate), moment(date[0].endDate)));
  };

  const handleSubmit = () => {
    props.setStartDate(moment(state[0].startDate).format("DD/MM/YYYY"));
    props.setEndDate(moment(state[0].endDate).format("DD/MM/YYYY"));
    props.toggleModalCalendar();
    props.setDateRange(dateRange);
  };

  return (
    <MDBContainer>
      <MDBModal
        className="DatePickerCustom"
        size="lg"
        isOpen={props.modalCalendar}
        toggle={props.toggleModalCalendar}
        centered={true}>
        <MDBModalHeader className="text-center displayContent">
          <Container>
            <Row className="col-lg-12">
              <Col className="col-lg-4 mx-auto">Pilih Tanggal</Col>
            </Row>
          </Container>
        </MDBModalHeader>
        <MDBModalBody>
          <Container className="containerCalendar">
            <Row className="rowCalendar">
              {/* <DateRangePicker
                className="mx-auto"
                onChange={(item) => handleChange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                direction="horizontal"
                minDate={new Date()}
                months={2}
                ranges={state}
                direction="horizontal"
              /> */}
              <DateRangePicker
                className="mx-auto"
                ranges={state}
                onChange={(item) => handleChange([item.selection])}
                showSelectionPreview={true}
                direction="horizontal"
                months={1}
                maxDate={moment(new Date()).subtract(1, "days").toDate()}
                minDate={moment("01/01/2020").toDate()}
              />
            </Row>
            <Row>
              <Button
                color=""
                onClick={handleSubmit}
                className="btn btn-rounded btnCekJam mx-auto px-5">
                OK
              </Button>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalCalendar;
