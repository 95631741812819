import {
  DATA_DETAIL_KONTEN,
  DATA_AJUKAN_KEMBALI,
  DATA_UBAH_JADWAL,
  DATA_UPDATE_REVISI,
  DATA_NOTIF,
  DATA_DUMMY_SURVEY,
  DATA_LOG_HISTORY,
} from "./actionTypes";

const initialState = {
  data: {},
  dataAjukan: {},
  dataUpdate: {},
  dataUbahJadwal: {},
  dataNotif: { data: "empty" },
  dataDummySurvey: window.localStorage.getItem("dataDummySurvey"),
  dataLogHistory: { data: {} }
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_DETAIL_KONTEN:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    case DATA_AJUKAN_KEMBALI:
      state = {
        ...state,
        dataAjukan: action.payload,
      };
    case DATA_UPDATE_REVISI:
      state = {
        ...state,
        dataUpdate: action.payload,
      };
    case DATA_UBAH_JADWAL:
      state = {
        ...state,
        dataUbahJadwal: action.payload,
      };
    case DATA_NOTIF:
      state = {
        ...state,
        dataNotif: { data: action.payload },
      };
    case DATA_DUMMY_SURVEY:
      console.log("REDUCER_DATA_DUMMY_SURVEY", action.payload);
      state = {
        ...state,
        dataDummySurvey: action.payload,
      };
    case DATA_LOG_HISTORY:
      state = {
        ...state,
        dataLogHistory: action.payload,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default dataReducer;
