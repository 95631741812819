import React from "react";
import Header from "../../components/VerticalLayout/Header";
import CardEditArticle from "../../components/Admin/Card/CardEditArticle";
import { useLocation } from "react-router-dom";

export default function EditArticle() {
  const getData = useLocation();
  // console.log(getData);
  const data = getData.state;

  return (
    <div>
      <Header title="Edit Artikel" />
      <CardEditArticle data={data} />
    </div>
  );
}
