import Loadable from "react-loadable";
import Loading from "../../../../../components/Loading";

const SummaryTrafficKontenAsync = Loadable({
  loader: () =>
    import(
      "../../../../../pages/BusinessIntelligence/Admin/Measures/SummaryTrafficKonten" /* webpackChunkName: "SummaryTrafficKontenAsync" */
    ),
  loading: Loading,
});

export default SummaryTrafficKontenAsync;
