import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);

  let judul;
  switch (props.activeTab) {
    case "1":
      judul = "Data Unique Device";
      break;
    case "2":
      judul = "Data Site AI";
      break;
    case "3":
      judul = "Data Behaviour Site";
      break;
    default:
      break;
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS1" className="DownloadTableXLS">
      <thead>{`${judul}`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>ID</th>
          <th>Terakhir Login</th>
          <th>Jumlah Visit</th>
          <th>OS</th>
          <th>Browser</th>
          <th>Model</th>
          <th>Resolusi</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{data._id ?? "-"}</td>
            <td>{`${moment(data.lastLogin).format("DD-MM-YYYY")}` ?? "-"}</td>
            <td>{data.jumlahVisit ?? "-"}</td>
            <td>{data.os ?? "-"}</td>
            <td>{data.browser.toString() ?? "-"}</td>
            <td>{data.model ?? "-"}</td>
            <td>{data.resolution}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
