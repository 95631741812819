import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
  Progress,
} from "reactstrap";
import { MDBNav, MDBNavItem, MDBNavLink, MDBSpinner } from "mdbreact";
import { Link, useHistory, withRouter } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// -------------------Service------------------------
import API from "../../../services";
// -------------------ASSET------------------------
//css
import "./style.css";
import IconUpload from "../../../assets/icon/IconUpload.svg";
import IconUploadToggle from "../../../assets/icon/IconUploadToggle.svg";
import IconDownload from "../../../assets/icon/IconDownload.svg";
import IconDownloadToggle from "../../../assets/icon/IconDownloadToggle.svg";

// -------------------COMPONENT--------------------
import ModalCalendar from "../../../components/ContentPartner/Popup/BuatKonten/Calendar";
import ModalJamTayang from "../../../components/ContentPartner/Popup/BuatKonten/JamTayang";
import ModalTambahProvinsi from "../../../components/Admin/Popup/KontenDefault/TambahProvinsi";
import Header from "../../../components/VerticalLayout/Header";
import { listDurasi, listKategori } from "./const";
import { FilterProvince, FilterCategory, FilterDuration } from "./dropdownFilter";
//SweetAlert
import Navigation from "../../../components/Admin/Navigation/Navigation";
import SweetAlert from "react-bootstrap-sweetalert";

function BuatKontenDefault(props) {
  //get user device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const history = useHistory();
  //color jamtayang
  const [newColor, setNewColor] = useState("#E5E5E5");

  ///handle change tab
  const [activeTab, setActiveTab] = useState("video");

  //state Form input
  const [fileVideo, setFileVideo] = useState(null);
  const [fileVideoName, setFileVideoName] = useState("");
  const [kategori, setKategori] = useState("");
  const [judul, setJudul] = useState("");
  const [link, setLink] = useState("");
  const [provinsi, setProvinsi] = useState({ id: "", value: "" });
  const [media, setMedia] = useState("");
  const [linkNews, setLinkNews] = useState("");
  const [sinopsis, setSinopsis] = useState("");
  //thumbnail video
  const [thumbnailVideo, setThumbnailVideo] = useState(null);
  const [thumbnailVideoName, setThumbnailVideoName] = useState(null);
  const [errorThumbnailSize, setErrorThumbnailSize] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [jamTayang, setJamTayang] = useState([]);
  const [showProvinsiTambahan, setshowProvinsiTambahan] = useState([]);
  const [provinsiTambahan, setProvinsiTambahan] = useState([]);
  const [thumbnailYT, setThumbnailYT] = useState("");
  const [thumbnailLocal, setThumbnailLocal] = useState("");

  const [errorFileSize, setErrorFileSize] = useState("");
  //list
  const [listProvinsi, setListProvinsi] = useState([]);
  //notif
  const [successSubmitted, setSuccessSubmitted] = useState("");
  //loading
  const [loading, setIsLoading] = useState(false);
  //modal loading
  const [modalLoading, setModalLoading] = useState(false);
  //btn OK
  const [okVid, setOKVid] = useState(false);
  const [okThumbnailVid, setOKThumbnailVid] = useState(false);
  const [okThumbnailBanner, setOKThumbnailBanner] = useState(false);
  const [okThumbnailNews, setOKThumbnailNews] = useState(false);
  const [attachedThumbnail, setAttachedThumbnail] = useState(null);
  //sweet alert
  const [successUploadContent, setSuccessUploadContent] = useState(false);
  const [successUploadThumbnail, setSuccessUploadThumbnail] = useState(false);

  const [isiBerita, setIsiBerita] = useState("");

  useEffect(() => {
    API.getAllProvince()
      .then((res) => {
        if (res.data.success) {
          setListProvinsi(res.data.data);
        }
      })
      .catch(() => {
        history.push("/pages-500");
      });
  }, []);
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
    handleClear();
  };

  const tabStyle = {
    color: "#c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };

  //file video
  const hiddenFileInputVideo = useRef(null);
  //file thumbnail vieo
  const hiddenFileThumbnailVideo = useRef(null);
  const handleClickUploadThumbnailVideo = () => {
    hiddenFileThumbnailVideo.current.click();
  };
  const handleChangeUploadThumbnailContent = (e) => {
    setErrorFileSize("");
    const fileUploaded = e.target.files[0];
    if (fileUploaded.size / 1024 / 1024 <= 0.5) {
      setThumbnailVideo(fileUploaded);
      setThumbnailVideoName(fileUploaded.name);
      setErrorThumbnailSize("");
      let reader = new FileReader();
      reader.onload = (e) => {
        setAttachedThumbnail(e.target.result);
      };
      reader.readAsDataURL(fileUploaded);
    } else {
      setErrorThumbnailSize("Ukuran file tidak boleh melebihi 500KB");
    }
  };
  //handle Attach thumbnail
  const handleAttachThumbnail = () => {
    switch (activeTab) {
      case "video":
        setOKThumbnailVid(true);
        break;
      case "banner":
        setOKThumbnailBanner(true);
        break;
      case "news":
        setOKThumbnailNews(true);
        break;
      default:
        break;
    }
    document.getElementById("imgThumbnailVideo").src = attachedThumbnail;
  };

  // var currentVID = document.getElementById("currentVID");
  const handleChangeUploadFileContent = (e) => {
    setErrorFileSize("");
    setErrorThumbnailSize("");
    //clear
    setFileVideo(null);
    setFileVideoName("");
    setThumbnailLocal("");
    setThumbnailYT("");
    //if video uploaded
    if (activeTab == "video") {
      //change input if video type youtube
      setOKVid(false);
      setLink("");
      setThumbnailYT("");
      const fileUploaded = e.target.files[0];
      if (fileUploaded.size / 1024 / 1024 <= 100) {
        setFileVideo(fileUploaded);
        setMedia("video");
        setFileVideoName(fileUploaded.name);
        setThumbnailLocal(URL.createObjectURL(new Blob([fileUploaded])));
      } else {
        setErrorFileSize("Ukuran file tidak boleh melebihi 100MB");
      }
    }
    //if banner uploaded
    if (activeTab == "banner") {
      const fileUploaded = e.target.files[0];
      if (fileUploaded.size / 1024 / 1024 <= 2) {
        setFileVideo(fileUploaded);
        setFileVideoName(fileUploaded.name);
        setErrorFileSize("");
        let reader = new FileReader();
        reader.onload = (e) => {
          setAttachedThumbnail(e.target.result);
        };
        reader.readAsDataURL(fileUploaded);
      } else {
        setErrorFileSize("Ukuran file tidak boleh melebihi 2MB");
      }
    }

    //if news thumbnail uploaded
    if (activeTab == "news") {
      const fileUploaded = e.target.files[0];
      if (fileUploaded.size / 1024 / 1024 <= 2) {
        setFileVideo(fileUploaded);
        setFileVideoName(fileUploaded.name);
        setErrorFileSize("");
        let reader = new FileReader();
        reader.onload = (e) => {
          setAttachedThumbnail(e.target.result);
        };
        reader.readAsDataURL(fileUploaded);
      } else {
        setErrorFileSize("Ukuran file tidak boleh melebihi 2MB");
      }
    }
  };
  const handleClickUploadVideo = () => {
    hiddenFileInputVideo.current.click();
  };
  //calendar modal
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  //onchange filter provinsi

  const filterData = useCallback(
    (filt, id, type) => {
      switch (type) {
        case "Kategori":
          setKategori(filt);
          break;

        case "Provinsi":
          setProvinsi({
            id: id,
            value: filt,
          });
          break;
      }
    },
    [kategori, provinsi]
  );

  const handleClear = () => {
    setKategori("");
    setJudul("");
    setLink("");
    setFileVideo(null);
    setFileVideoName("");
    setProvinsi("");

    setThumbnailYT(null);
    setThumbnailLocal(null);
    setThumbnailVideo(null);
    setLinkNews("");
    setSinopsis("");
    setErrorFileSize("");
    setErrorThumbnailSize("");
    setSuccessSubmitted("");
    //btn
    setOKVid(false);
    setOKThumbnailVid(false);
    setOKThumbnailBanner(false);
    setOKThumbnailNews(false);

    setAttachedThumbnail(null);
    setThumbnailVideoName("");
    setIsiBerita("");
  };

  const handleSubmitContent = async () => {
    let listIDProvince = [...new Set(_.map(provinsiTambahan, "_id"))];
    setModalLoading(true);
    let param = new FormData();
    param.append("username", window.localStorage.getItem("users"));
    param.append("user", window.localStorage.getItem("idUser"));
    param.append("category", kategori);
    param.append("content", activeTab);
    param.append("title", judul);
    param.append("province", JSON.stringify(listIDProvince));
    param.append("media", media);
    param.append("file", fileVideo);

    param.append("bodyBerita", isiBerita);

    if (activeTab == "news") {
      param.append("link", linkNews);
      param.append("sinopsis", sinopsis);
    } else if (activeTab == "video") {
      param.append("link", link);
      param.append("thumbnail", thumbnailVideo);
    } else {
    }

    API.postCreateContentDefault(param)
      .then((res) => {
        setIsLoading(true);
        if (res.data.success) {
          new Promise(
            () => {
              setSuccessSubmitted("successCreatedContent");
              setIsLoading(false);
            },
            setTimeout(() => {
              history.push("/admin/konten-default");
            }, 3000)
          );
        } else {
          new Promise(
            () => {
              setSuccessSubmitted("failCreatedContent");
              setIsLoading(false);
            },
            setTimeout(() => {
              history.push("/admin/konten-default");
            }, 3000)
          );
        }
      })
      .catch((e) => {
        new Promise(
          () => {
            setSuccessSubmitted("failFetch");
            setIsLoading(false);
          },
          setTimeout(() => {
            history.push("/admin/konten-default");
          }, 3000)
        );
      });
  };
  const handleThumbnail = (e) => {
    if (e.target.value == "") {
      setThumbnailYT("");
    }
    if (activeTab == "video") {
      let patternMustYTURL =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (e.target.value.match(patternMustYTURL)) {
        let newval;
        if (e.target.value.match(/(\?|&)v=([^&#]+)/)) {
          newval = e.target.value.match(/(\?|&)v=([^&#]+)/).pop();
        } else if ((newval = e.target.value.match(/(\.be\/)+([^\/]+)/))) {
          newval = e.target.value.match(/(\?|&)v=([^&#]+)/).pop();
        } else if ((newval = e.target.value.match(/(\embed\/)+([^\/]+)/))) {
          newval = e.target.value.pop().replace("?rel=0", "");
        }
        setThumbnailYT(newval);
      }
    } else {
      setThumbnailYT("");
    }
  };

  const handleLink = (e) => {
    if (e.target.value == "") {
      setThumbnailYT("");
    }
    if (activeTab == "video") {
      let patternMustYTURL =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (e.target.value.match(patternMustYTURL)) {
        let newval;
        if (e.target.value.match(/(\?|&)v=([^&#]+)/)) {
          newval = e.target.value.match(/(\?|&)v=([^&#]+)/).pop();
        } else if ((newval = e.target.value.match(/(\.be\/)+([^\/]+)/))) {
          newval = e.target.value.match(/(\?|&)v=([^&#]+)/).pop();
        } else if ((newval = e.target.value.match(/(\embed\/)+([^\/]+)/))) {
          newval = e.target.value.pop().replace("?rel=0", "");
        }
        return newval;
      }
    }
  };
  //scrapping news
  const [newsConfirmed, setNewsConfirmed] = useState(false);
  const handleLinkNews = () => {
    let param = {
      url: linkNews,
      userId: window.localStorage.getItem("idUser"),
    };
    API.postCheckNews(param)
      .then((res) => {
        if (res.status == 200) {
          setNewsConfirmed(true);
        } else {
          setNewsConfirmed(true);
        }
      })
      .catch((e) => {
        setNewsConfirmed(true);
      });
  };

  const handleChangeLink = (e) => {
    if (activeTab == "video") {
      setPercentage(100);
      setOKVid(false);
      setThumbnailLocal("");
      setFileVideo(null);
      //^when change to local
      handleThumbnail(e);
      setMedia("youtube");
    }
    setLink(handleLink(e));
  };
  //content success
  function ContentSuccessUploadHandler() {
    new Promise(
      () => {
        setSuccessUploadContent(true);
      },
      setTimeout(() => {
        setSuccessUploadContent(false);
      }, 3500)
    );
  }
  const [percentage, setPercentage] = useState(0);
  //handle when press ok video
  const handleProgress = () => {
    function _(el) {
      return document.getElementById(el);
    }
    setOKVid(true);
    //file video
    let formData = new FormData();
    formData.append("fileVid", fileVideo);
    let ajax = new XMLHttpRequest();
    ajax.upload.addEventListener("progress", progressHandler, false);
    ajax.addEventListener("load", ContentSuccessUploadHandler, false);
    ajax.open("POST", fileVideo);
    ajax.send(formData);

    function progressHandler(e) {
      let percent = (e.loaded / e.total) * 100;
      setPercentage(Math.round(percent)); // percentage of progress BAR
    }
  };

  const disableButton = () => {
    switch (activeTab) {
      case "video":
        return percentage !== 100 || kategori == "" || judul == "" || provinsiTambahan.length == 0;
        break;
      case "banner":
        return kategori == "" || judul == "" || provinsiTambahan.length == 0;
        break;
      case "news":
        return (
          kategori == "" || judul == "" || provinsiTambahan.length == 0 || newsConfirmed == false
        );
        break;

      default:
        break;
    }
  };

  //modalTambahProvinsi
  const [modalTambahProvinsi, setModalTambahProvinsi] = useState(false);
  const toggleModalTambahProvinsi = () => {
    setModalTambahProvinsi(!modalTambahProvinsi);
  };

  const handleSelectedProvince = (province) => {
    setshowProvinsiTambahan(province.slice(1, province.length));
    setProvinsiTambahan(listProvinsi.filter((it) => province.includes(it.name)));
  };

  return (
    <React.Fragment>
      {modalLoading ? (
        successSubmitted == "successCreatedContent" ? (
          <SweetAlert
            title="Konten Default Berhasil Dibuat"
            success
            showConfirm={false}
            onConfirm={""}>
            {judul}
          </SweetAlert>
        ) : successSubmitted == "failCreatedContent" || successSubmitted == "failFetch" ? (
          <SweetAlert
            title="Konten Default Tidak Berhasil Dibuat"
            error
            showConfirm={false}
            onConfirm={""}>
            {judul}
          </SweetAlert>
        ) : (
          <SweetAlert
            showConfirm={false}
            onConfirm={""}
            title="Mohon Tunggu"
            style={{
              padding: "3em",
              borderRadius: "20px",
            }}>
            <Spinner
              color="danger"
              style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
              type="grow"
            />
            <div>Konten {judul} sedang dibuat</div>
          </SweetAlert>
        )
      ) : null}
      {successUploadContent && percentage == 100 ? (
        <SweetAlert
          title={`${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Berhasil di Upload`}
          success
          showConfirm={false}
          onConfirm={""}
          style={{ textTransform: "capitalize " }}></SweetAlert>
      ) : successUploadThumbnail ? (
        <SweetAlert
          title={`Thumbnail ${
            activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
          } Berhasil di Upload`}
          success
          showConfirm={false}
          style={{ textTransform: "capitalize " }}
          onConfirm={""}></SweetAlert>
      ) : (
        <></>
      )}
      <div className="page-content">
        <header>
          <Header title="Buat Konten Default" btn={true} btntype="back" btntext="Konten Default" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <MDBContainer > */}
            <div className="classic-tabs">
              <Row style={{ overflowY: "auto" }}>
                <Col className="col-12">
                  <Row>
                    <Col className="col-lg-6 mx-auto" id="formKonten">
                      <Card className="mx-auto" style={{ boxShadow: "0px 0px 10px #D3D3D3" }}>
                        <div className="classic-tabs">
                          <Row style={{ padding: "10px 0" }}>
                            <Col lg="6" className="mx-auto my-auto">
                              <Navigation
                                tabStyle={tabStyle}
                                activeTab={activeTab}
                                handleChangeTab={handleChangeTab}
                                customStyle={customStyle}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="col-lg-6 mx-auto">
                      <Alert
                        className="wrapperAlert"
                        color={notif ? "success" : "danger"}
                        isOpen={isSuccessSubmitted}>
                        {message}
                      </Alert>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col className="mx-auto col-lg-6">
                      <Row className="w-85 mx-auto">
                        <Form className="w-100">
                          <FormGroup>
                            <Label for="kategori">Kategori</Label>
                            <FilterCategory
                              type={"Kategori"}
                              filter={kategori}
                              clicked={filterData}
                              list={listKategori}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="judul">Judul Konten</Label>
                            <Input
                              type="text"
                              name="text"
                              id="judul"
                              value={judul}
                              onChange={(e) => setJudul(e.target.value)}
                              className="inputWrapper"
                            />
                          </FormGroup>
                          {/* FIELD UPLOADD ASSET */}
                          {activeTab == "news" ? (
                            <>
                              <FormGroup>
                                <Label for="linkNews">Link Berita</Label>
                                <div className="w-100">
                                  <Row className="d-flex">
                                    <div className="col-lg-10" id="BtnNews">
                                      <Input
                                        type="text"
                                        name="text"
                                        id="linkNews"
                                        value={linkNews}
                                        onChange={(e) => {
                                          setLinkNews(e.target.value);
                                          setNewsConfirmed(false);
                                        }}
                                        className="inputWrapper"
                                      />
                                    </div>
                                    <div className="col-lg-2 nopadding">
                                      <Button
                                        onClick={handleLinkNews}
                                        disabled={linkNews == ""}
                                        className="btnAsk btn btn-rounded btnOK">
                                        OK
                                      </Button>
                                    </div>
                                  </Row>
                                </div>
                                {newsConfirmed ? (
                                  <FormText className="formTextSuccess" color="#55BA59">
                                    Link Berita berhasil diunggah
                                  </FormText>
                                ) : (
                                  <></>
                                )}
                              </FormGroup>
                              {/* thumbnail berita */}
                              <FormGroup>
                                <Label for="ThumbnailNews">Thumbnail Berita</Label>

                                <div
                                  className="w-100"
                                  style={{ position: "relative", top: "-20px" }}>
                                  <Button
                                    onClick={handleClickUploadVideo}
                                    color=""
                                    className={
                                      isMobile
                                        ? "btnAsk  btnUploadVideoDefault btn btn-rounded"
                                        : "btnAsk  btnUploadVideoDefault btn btn-rounded"
                                    }>
                                    <img className="IconUpload" src={IconUpload}></img>
                                    {`${"    "}`}Upload
                                  </Button>
                                  <div className="w-100">
                                    <Row className="d-flex">
                                      <div className="col-lg-10">
                                        <Input
                                          type="text"
                                          value={link}
                                          disabled={true}
                                          placeholder={fileVideo !== null ? `${fileVideoName}` : ""}
                                          id="upload"
                                          className="inputWrapper inputPosition"
                                        />
                                        <input
                                          type="file"
                                          id="newlocalFILE"
                                          accept={activeTab == "news" ? ".jpg,.png" : ""}
                                          ref={hiddenFileInputVideo}
                                          onChange={handleChangeUploadFileContent}
                                          style={{ display: "none" }}
                                        />
                                      </div>
                                      <div className="col-lg-2 nopadding">
                                        <Button
                                          onClick={handleAttachThumbnail}
                                          disabled={fileVideo == null}
                                          className="btnAsk btn btn-rounded">
                                          OK
                                        </Button>
                                      </div>
                                    </Row>
                                  </div>

                                  {errorFileSize !== "" ? (
                                    <FormText className="formTextError" color="#c42127">
                                      {errorFileSize}
                                    </FormText>
                                  ) : (
                                    <FormText className="formText" color="muted">
                                      {activeTab == "video"
                                        ? "Mp4. maksimal 100 MB"
                                        : activeTab == "banner"
                                        ? "Unggah file dengan ekstensi .png atau .jpg (Maksimal ukuran file 2MB)"
                                        : activeTab == "news"
                                        ? "Unggah file dengan ekstensi .png atau .jpg (Maksimal ukuran file 2MB)"
                                        : ""}
                                    </FormText>
                                  )}

                                  <center className="mt-2">
                                    <div>
                                      <center className="mt-2">
                                        {activeTab == "news" ? (
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "245px",
                                              objectFit: "cover",
                                              display: okThumbnailNews ? "block" : "none",
                                            }}
                                            id="imgThumbnailVideo"></img>
                                        ) : (
                                          ""
                                        )}
                                      </center>
                                    </div>
                                  </center>
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <Label for="sinopsis">Sinopsis Berita (Maks 200 Karakter)</Label>
                                <Input
                                  maxLength="200"
                                  type="textarea"
                                  name="text"
                                  id="sinopsis"
                                  value={sinopsis}
                                  onChange={(e) => setSinopsis(e.target.value)}
                                  className="inputWrapper textAreaSinopsis"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="isiBerita">Isi Berita</Label>
                                <Input
                                  type="textarea"
                                  name="isiBerita"
                                  id="isiBerita"
                                  value={isiBerita}
                                  onChange={(e) => setIsiBerita(e.target.value)}
                                  className="inputWrapper textAreaSinopsis"></Input>
                              </FormGroup>
                            </>
                          ) : activeTab == "video" ? (
                            <>
                              <FormGroup>
                                <Label for="ThumbnailVideo">Video</Label>
                                <div
                                  className="w-100"
                                  style={{ position: "relative", top: "-31px" }}>
                                  <Button
                                    onClick={handleClickUploadVideo}
                                    // disabled={link !== "" || activeTab == "news"}
                                    color=""
                                    className={
                                      isMobile
                                        ? "btnAsk btnUploadVideoDefault btn btn-rounded"
                                        : "btnAsk btnUploadVideoDefault btn btn-rounded"
                                    }>
                                    <img className="IconUpload" src={IconUpload}></img>
                                    {`${"    "}`}Upload
                                  </Button>
                                  <Row className="d-flex">
                                    <div className="col-lg-10 ">
                                      <Input
                                        type="text"
                                        onChange={handleChangeLink}
                                        value={link}
                                        // disabled={fileVideo !== null}
                                        placeholder={
                                          fileVideo !== null
                                            ? `${fileVideoName}`
                                            : activeTab == "video"
                                            ? "atau paste link video youtube disini"
                                            : activeTab == "banner"
                                            ? link
                                            : activeTab == "news"
                                            ? link
                                            : ""
                                        }
                                        id="upload"
                                        className="inputWrapper inputPosition"
                                      />
                                      <input
                                        type="file"
                                        id="newlocalFILE"
                                        accept={
                                          activeTab == "video"
                                            ? ".mp4"
                                            : activeTab == "banner"
                                            ? ".jpg,.png"
                                            : ""
                                        }
                                        ref={hiddenFileInputVideo}
                                        onChange={handleChangeUploadFileContent}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                    <div className="col-lg-2 nopadding">
                                      <Button
                                        disabled={thumbnailLocal == "" && thumbnailYT == ""}
                                        onClick={handleProgress}
                                        // onClick={() => setOKVid(true)}
                                        className="btnAsk btn btn-rounded">
                                        OK
                                      </Button>
                                    </div>
                                  </Row>
                                  {/* percentage of video when upload */}
                                  {fileVideo !== null && errorFileSize == "" && okVid ? (
                                    <Row className="col-lg-10 mt-2" style={{ marginLeft: "-5px" }}>
                                      <Progress
                                        id="progressBarVid"
                                        className="w-100"
                                        striped
                                        color="primary"
                                        max="100"
                                        value={percentage}>
                                        {`${percentage} %`}
                                      </Progress>
                                    </Row>
                                  ) : (
                                    ""
                                  )}

                                  {errorFileSize !== "" ? (
                                    <FormText className="formTextError" color="#c42127">
                                      {errorFileSize}
                                    </FormText>
                                  ) : (
                                    <FormText className="formTextRequire" color="#c42127">
                                      Mp4. maksimal 100 MB
                                    </FormText>
                                  )}

                                  <div>
                                    <center className="mt-2">
                                      {activeTab == "video" ? (
                                        okVid && thumbnailLocal !== "" && thumbnailYT == "" ? (
                                          <div style={{ width: "100%", height: "245px" }}>
                                            <video width="inherit" height="100%" muted controls>
                                              <source
                                                id="currentVID"
                                                src={thumbnailLocal}
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                        ) : okVid && thumbnailLocal == "" && thumbnailYT !== "" ? (
                                          <iframe
                                            className="video"
                                            width="100%"
                                            height="245"
                                            allowfullscreen="allowfullscreen"
                                            mozallowfullscreen="mozallowfullscreen"
                                            msallowfullscreen="msallowfullscreen"
                                            oallowfullscreen="oallowfullscreen"
                                            webkitallowfullscreen="webkitallowfullscreen"
                                            src={`https://www.youtube.com/embed/${thumbnailYT}`}></iframe>
                                        ) : (
                                          <></>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </center>
                                  </div>
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <Label for="ThumbnailVideo">Thumbnail Video</Label>
                                <div
                                  className="w-100"
                                  style={{ position: "relative", top: "-20px" }}>
                                  <Button
                                    onClick={handleClickUploadThumbnailVideo}
                                    color=""
                                    className={
                                      isMobile
                                        ? "btnAsk btnUploadVideoDefault btn btn-rounded"
                                        : "btnAsk btnUploadVideoDefault btn btn-rounded"
                                    }>
                                    <img className="IconUpload" src={IconUpload}></img>
                                    {`${"    "}`}Upload
                                  </Button>
                                  <Row className="d-flex">
                                    <div className="col-lg-10">
                                      <Input
                                        disabled={true}
                                        value={thumbnailVideoName}
                                        placeholder={
                                          thumbnailVideo !== null ? `${thumbnailVideoName}` : ""
                                        }
                                        id="upload"
                                        className="inputWrapper inputPosition"
                                      />
                                      <input
                                        type="file"
                                        id="newlocalFILE"
                                        accept={".jpg,.png"}
                                        ref={hiddenFileThumbnailVideo}
                                        onChange={handleChangeUploadThumbnailContent}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                    <div className="col-lg-2 nopadding">
                                      <Button
                                        onClick={handleAttachThumbnail}
                                        disabled={thumbnailVideo == null}
                                        className="btnAsk btn btn-rounded">
                                        OK
                                      </Button>
                                    </div>
                                  </Row>

                                  {errorThumbnailSize !== "" ? (
                                    <FormText className="formTextError" color="#c42127">
                                      {errorThumbnailSize}
                                    </FormText>
                                  ) : (
                                    <FormText className="formTextRequire" color="#c42127">
                                      JPEG/PNG. maksimal 500 KB
                                    </FormText>
                                  )}
                                  <div>
                                    <center className="mt-2">
                                      {activeTab == "video" ? (
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "245px",
                                            objectFit: "cover",
                                            display: okThumbnailVid ? "block" : "none",
                                          }}
                                          id="imgThumbnailVideo"></img>
                                      ) : (
                                        ""
                                      )}
                                    </center>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          ) : (
                            <>
                              <FormGroup>
                                <Label for="ThumbnailVideo">Banner</Label>

                                <div
                                  className="w-100"
                                  style={{ position: "relative", top: "-20px" }}>
                                  <Button
                                    onClick={handleClickUploadVideo}
                                    disabled={link !== "" || activeTab == "news"}
                                    color=""
                                    className={
                                      isMobile
                                        ? "btnAsk btnUploadVideoDefault btn btn-rounded"
                                        : "btnAsk btnUploadVideoDefault btn btn-rounded"
                                    }>
                                    <img className="IconUpload" src={IconUpload}></img>
                                    {`${"    "}`}Upload
                                  </Button>
                                  <Row className="d-flex">
                                    <div className="col-lg-10">
                                      <Input
                                        type="text"
                                        onChange={handleChangeLink}
                                        value={link}
                                        disabled={fileVideo !== null}
                                        placeholder={
                                          fileVideo !== null
                                            ? `${fileVideoName}`
                                            : activeTab == "video"
                                            ? "atau paste link video youtube disini"
                                            : activeTab == "banner"
                                            ? link
                                            : activeTab == "news"
                                            ? link
                                            : ""
                                        }
                                        id="upload"
                                        className="inputWrapper inputPosition"
                                      />
                                      <input
                                        type="file"
                                        id="newlocalFILE"
                                        accept={
                                          activeTab == "video"
                                            ? ".mp4"
                                            : activeTab == "banner"
                                            ? ".jpg,.png"
                                            : ""
                                        }
                                        ref={hiddenFileInputVideo}
                                        onChange={handleChangeUploadFileContent}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                    <div className="col-lg-2 nopadding">
                                      <Button
                                        onClick={handleAttachThumbnail}
                                        disabled={fileVideo == null}
                                        className="btnAsk btn btn-rounded">
                                        OK
                                      </Button>
                                    </div>
                                  </Row>

                                  {errorFileSize !== "" ? (
                                    <FormText className="formTextError" color="#c42127">
                                      {errorFileSize}
                                    </FormText>
                                  ) : (
                                    <FormText className="formTextRequire" color="#c42127">
                                      JPEG/PNG. maksimal 2 MB
                                    </FormText>
                                  )}
                                  <div>
                                    <center className="mt-2">
                                      {activeTab == "banner" ? (
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "245px",
                                            objectFit: "cover",
                                            display: okThumbnailBanner ? "block" : "none",
                                          }}
                                          id="imgThumbnailVideo"></img>
                                      ) : (
                                        ""
                                      )}
                                    </center>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          )}

                          {/* <FormGroup>
                            <Label for="provinsi">Provinsi</Label>
                            <FilterProvince
                              type={"Provinsi"}
                              filter={provinsi}
                              clicked={filterData}
                              list={listProvinsi}
                            />
                          </FormGroup> */}

                          <FormGroup>
                            <Label for="tambahan">Provinsi</Label>
                            <Button
                              color=""
                              onClick={toggleModalTambahProvinsi}
                              id="tanggal"
                              className="inputWrapper btnTanggal">
                              <p className="selectedDate">
                                {showProvinsiTambahan.id !== ""
                                  ? showProvinsiTambahan.sort().join(",")
                                  : ""}
                              </p>
                            </Button>
                            <ModalTambahProvinsi
                              modalTambahProvinsi={modalTambahProvinsi}
                              toggleModalTambahProvinsi={toggleModalTambahProvinsi}
                              activeTab={activeTab}
                              province={provinsi ? provinsi._id : ""}
                              // emergency={isEmergency}
                              listSelected={showProvinsiTambahan}
                              selectedProvince={handleSelectedProvince}
                            />
                          </FormGroup>

                          <div className="wrapperBtnCreate">
                            <Button className="btnClear" onClick={handleClear} color="">
                              Clear
                            </Button>
                            <Button
                              className="btnAskSubmitDefault btn btn-rounded"
                              disabled={disableButton()}
                              // disabled={
                              //   kategori == "" ||
                              //   judul == "" ||
                              //   provinsi == "" ||
                              //   errorFileSize !== "" ||
                              //   errorThumbnailSize !== "" ||
                              //   loading == true
                              // }
                              onClick={handleSubmitContent}
                              color="">
                              {loading ? <>Loading...</> : <>Ajukan Konten Default</>}
                            </Button>
                          </div>
                          <div className="wrapperKonten"></div>
                        </Form>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* </MDBContainer> */}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

export default BuatKontenDefault;
