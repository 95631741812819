import React, { useState, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Spinner,
    Container,
    CardBody
  } from "reactstrap";

import { ChromePicker } from 'react-color';
import SweetAlert from "react-bootstrap-sweetalert";
import API from '../../../services';

export default function BannerLandingPage() {
    const history = useHistory();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // console.info({ navigator: navigator.userAgent, isMobile })

    const [colorState, setColorState] = useState('#191ED1');
    const [loading, setLoading] = useState(false);
    const [bannerText, setBannerText] = useState({
        textHello: 'SELAMAT HARI',
        textTitle: 'KEMERDEKAAN RI INDONESIA',
        textDates: '17 AGUSTUS 2022',
        color: {
            textHello: '#191ED1',
            textTitle: '#191ED1',
            textDates: '#191ED1',
        }
    });

    function handleChangeState (event, type) {
        
        const { name, value } = event.target;

        // if type color
        if (type === 'color') setBannerText((prev) => ({...prev, color: { ...prev.color, [name]: value  } }))
        else setBannerText(prev => ({...prev, [name]: value }))
    };

    async function onSubmitBanner () {
        setLoading(true)
        const payload = {
            userId: window.localStorage.getItem("idUser"),
            ...bannerText
        };

        try {
            const response = await API.bannerSubmit(payload)
            const { data } = response
            if (data.status) {
                setTimeout(() => {
                    setLoading(true)
                    history.push("/admin/home");
                }, 2000)
            }
        } catch (e) {
            console.error(e)
        }
       
    };

    function handleChangeComplete (color) {
        setColorState(color.hex);
    };

    function handleClear () {
        setBannerText({
            textHello: '',
            textTitle: '',
            textDates: '',
            color: {
                textHello: '',
                textTitle: '',
                textDates: '',
            }
        })
    };

    const isReadyToPreview = useMemo(() => {
        let valid = false;

        if (
            bannerText.color?.textHello &&
            bannerText.color?.textTitle &&
            bannerText.color?.textDates
        ) {
            valid = true
        }

        return valid;
    }, [bannerText])

    const disableBtn = useMemo(() =>{
        const { textHello, textTitle, textDates } = bannerText;
        if (loading) return true
        else if (
            !textHello || !textTitle || !textDates
        ) return true

        return false;
    }, [bannerText, loading])

    return (
        <div>
            {loading && (
                <SweetAlert
                    showConfirm={false}
                    onConfirm={""}
                    title="Mohon Tunggu"
                    style={{
                        padding: "3em",
                        borderRadius: "20px",
                    }}>
                    <Spinner
                        color="danger"
                        style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
                        type="grow"
                    />
                    <div>Banner sedang dibuat</div>
                </SweetAlert>
            )}

            <Container style={{ marginBottom: "10%" }}>
                <Card style={{ boxShadow: "0px 0px 10px #D3D3D3", paddingBottom: "30px", paddingTop: "20px" }}>
                    <CardBody>
                        <h4>
                            Banner Text Landing Page
                        </h4>
                    </CardBody>
                    <div
                        style={{
                            padding: '1rem',
                        }}
                    >
                        <Form className="w-100">
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label for="Username">Text Hello</Label>
                                        <Input
                                            type="text"
                                            name="textHello"
                                            id="textHello"
                                            placeholder='SELAMAT HARI'
                                            value={bannerText.textHello}
                                            onChange={(e) => handleChangeState(e)}
                                            className="inputWrapper"
                                        />
                                    </Col>
                                    <Col>
                                        <Label for="Username">Color</Label>
                                        <Input
                                            type="text"
                                            name="textHello"
                                            id="textHello"
                                            value={bannerText.color?.textHello || ''}
                                            placeholder='Hexa color'
                                            onChange={(e) => handleChangeState(e, 'color')}
                                            className="inputWrapper"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label for="Username">Text Title</Label>
                                        <Input
                                            type="text"
                                            name="textTitle"
                                            id="textTitle"
                                            placeholder='KEMERDEKAAN RI'
                                            value={bannerText.textTitle}
                                            onChange={(e) => handleChangeState(e)}
                                            className="inputWrapper"
                                        />
                                    </Col>
                                    <Col>
                                        <Label for="Username">Color</Label>
                                        <Input
                                            type="text"
                                            name="textTitle"
                                            id="textTitle"
                                            value={bannerText.color?.textTitle || ''}
                                            placeholder='Hexa color'
                                            onChange={(e) => handleChangeState(e, 'color')}
                                            className="inputWrapper"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label for="Username">Text Dates</Label>
                                        <Input
                                            type="text"
                                            name="textDates"
                                            id="textDates"
                                            placeholder='17 AGUSTUS 1945'
                                            value={bannerText.textDates}
                                            onChange={(e) => handleChangeState(e)}
                                            className="inputWrapper"
                                        />
                                    </Col>
                                    <Col>
                                        <Label for="Username">Color</Label>
                                        <Input
                                            type="text"
                                            name="textDates"
                                            id="textDates"
                                            value={bannerText.color?.textDates || ''}
                                            placeholder='Hexa color'
                                            onChange={(e) => handleChangeState(e, 'color')}
                                            className="inputWrapper"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <div>
                                <Row>
                                    <Col>
                                        <p style={{ marginTop: '1rem' }}>Color Picker: </p> 
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <ChromePicker
                                                color={colorState}
                                                onChange={handleChangeComplete}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        {Boolean(isReadyToPreview) && (
                                            <Container style={{ textAlign: "center", marginTop: '4rem' }}>
                                                <Card>
                                                    <h4 style={{ color: `${bannerText.color.textHello}` }}>
                                                        { bannerText.textHello }
                                                    </h4>
                                                    <h4 style={{ color: `${bannerText.color.textTitle}` }}>
                                                        { bannerText.textTitle }
                                                    </h4>
                                                    <h4 style={{ color: `${bannerText.color.textDates}` }}>
                                                        { bannerText.textDates }
                                                    </h4>
                                                </Card>
                                            </Container>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            
                            <div className="wrapperBtnCreate"
                                style={{
                                    marginTop: '5rem',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    className="btnClear"
                                    onClick={handleClear}
                                    color=""
                                >
                                Clear
                                </Button>
                                <Button
                                    disabled={disableBtn}
                                    className="btnAskSubmit btn btn-rounded"
                                    onClick={onSubmitBanner}
                                >
                                    {loading ? 'Submiting...' : 'Sumbit' }
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card>
            </Container>
        </div>
    )
}
