import React, { useState, useEffect } from "react";
import {
  Spinner,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Button,
  Input,
  ButtonDropdown,
} from "reactstrap";

import API from "../../../services";

// ASSETS
import iconDown from "../../../assets/icon/back.png";

// STYLES
import "../styles.css";

// REDUX
import { useSelector } from "react-redux";

export default function FilterOrgProv(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown((prevState) => !prevState);

  return (
    <>
      <ButtonDropdown style={{ width: "100%" }} isOpen={dropdown} toggle={toggleDropdown}>
        <DropdownToggle
          style={{
            backgroundColor: "#F8F9FA",
            borderRadius: "20px",
            borderColor: "#2C3280",
            paddingTop: "1px",
            paddingBottom: "1px",
          }}>
          <div
            style={{
              color: "#2C3280",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            <span>{props.dataType}</span>
            <img style={{ height: "24px" }} src={iconDown} />
          </div>
        </DropdownToggle>
        <DropdownMenu
          style={{
            maxHeight: "300px",
            minWidth: "100%",
            overflowY: "auto",
          }}>
          <DropdownItem
            onClick={(e) => {
              props.setDataType("Video");
            }}>
            Video
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              props.setDataType("Banner");
            }}>
            Banner
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              props.setDataType("Berita");
            }}>
            Berita
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
}
