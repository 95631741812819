import React, { useState } from "react";
import { MDBModal } from "mdbreact";
import "./style.css";

// ------------------API-----------------------
import API from "../../../../../services";

const ModalDelete = ({
  modalDelete,
  toggleModalDelete,
  dataModalDelete,
  handleAlert,
  fetchData,
}) => {
  let idContent;
  let type;
  if (dataModalDelete.activeTab == "1") {
    idContent = dataModalDelete.idVid;
    type = "video";
  } else if (dataModalDelete.activeTab == "2") {
    idContent = dataModalDelete.idBanner;
    type = "banner";
  } else {
    idContent = dataModalDelete.idNews;
    type = "news";
  }
  let param = {
    idContent: idContent,
    typeContent: type,
  };
  const onHandleSubmit = () => {
    API.postDeleteContent(param)
      .then((res) => {
        // console.log("resssponse", res);
        if (res.data.success) {
          toggleModalDelete();
          handleAlert.setNotif(true);
          handleAlert.setAlert(true);
          handleAlert.setMessage("Konten berhasil dihapus");
          setTimeout(() => {
            handleAlert.setAlert(false);
          }, 5000);
          fetchData();
        } else {
          toggleModalDelete();
          handleAlert.setNotif(true);
          handleAlert.setAlert(false);
          handleAlert.setMessage(res.err);
          setTimeout(() => {
            handleAlert.setAlert(false);
          }, 5000);
          fetchData();
        }
      })
      .catch((e) => {
        toggleModalDelete();
        handleAlert.setNotif(true);
        handleAlert.setAlert(false);
        handleAlert.setMessage("error");
        setTimeout(() => {
          handleAlert.setAlert(false);
        }, 5000);
        fetchData();
      });
  };

  return (
    <MDBModal isOpen={modalDelete} toggle={toggleModalDelete} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleDelete">HAPUS KONTEN</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalDelete}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">{dataModalDelete.title ?? "-"}</h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleDelete">Anda yakin ingin Hapus konten ini?</h4>
          </div>

          <div className="mx-auto wrapperBtnVerification">
            <button
              type="button"
              onClick={onHandleSubmit}
              className="w-100 btn btnDelete btn-rounded">
              Hapus
            </button>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalDelete;
