import React, { useState, useRef } from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import XLSX from "xlsx";
import "./index.css";

import { Container, Button, Row, Alert, Col, Spinner } from "reactstrap";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { exportPDF } from "../../components/SupportCenter/Download/SurveyKepuasanCP/PDF/DownloadPDF";
import { exportPDFVisitor } from "../../components/SupportCenter/Download/SurveyKepuasanCP/PDF/DownloadPDFVisitor";

import DownloadXLS from "../../components/SupportCenter/Download/SurveyKepuasanCP/XLS/DownloadXLS";
// import DownloadXLSVisitor from "../../components/SupportCenter/Download/SurveyKepuasanCP/XLS/DownloadXLSVisitor";

import { generateDocXLS } from "./../../components/SupportCenter/Download/SurveyKepuasanCP/XLS/generateDocXLS";

// ------------------ API --------------------
import API from "../../services";

// ------------------REDUX---------------------
import { connect } from "react-redux";
import moment from "moment";

const FilterCalendar = (props) => {
  const [isOpen, setIsOpen] = useState(false); //open alert
  const [notif, setNotif] = useState("");
  const [message, setMessage] = useState("");
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  const [dataDownload, setDataDownload] = useState([]);
  const [dataVisitorDownload, setDataVisitorDownload] = useState([]);

  const inputXLS = useRef();

  const fetchAPISurveyKepuasanCP = () => {
    props.setLoading(true);
    API.getSurveyKepuasanCP()
      .then((res) => {
        props.setLoading(false);

        setDataDownload(res?.data?.data?.ratingCP);
      })
      .catch((e) => {
        props.setLoading(false);
        //history.push("/pages-500");
        setDataVisitorDownload([]);
      });
  };

  const downloadExcel = async (data) => {
    const doc_xls = await generateDocXLS(data);

    console.log(doc_xls.length, " generated ");

    let wb = XLSX.utils.book_new();
    var ws_name = "Report";

    var ws = XLSX.utils.aoa_to_sheet(doc_xls);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    /* write workbook */
    XLSX.writeFile(wb, `excel_survey_kepusan_visitor.xlsx`);
  };

  const fetchAPIVisitor = (params, withParams = true) => {
    props.setLoading(true);
    // KHUSUS untuk pdf atau xls
    const page = 1;
    API.getSurveyKepuasanVisitor(page, params, withParams)
      .then((res) => {
        setDataVisitorDownload(res?.data?.data?.ratingVisitor);

        if (params.type === "pdf") {
          if (props.activeTab == "cp") {
            //console.log(" data => ", dataDownload);
            exportPDF(dataDownload, props.activeTab);
          } else {
            exportPDFVisitor(res?.data?.data?.ratingVisitor, props.activeTab);
          }
        } else {
          if (props.activeTab != "cp") {
            downloadExcel(res?.data?.data?.ratingVisitor);
          }
        }

        props.setLoading(false);
      })

      .catch((e) => {
        props.setLoading(false);
        setDataVisitorDownload([]);
        // history.push("/pages-500");
      });
  };

  const handleChangeYear = (e) => {
    setYear(e.target.value);
  };

  const handleChangeMonth = (e) => {
    setMonth(e.target.value);
  };

  const handleDownload = (type = "pdf") => {
    if (!month || !year) {
      setIsOpen(true);
      setMessage("month and year must be select ");
      return false;
    }

    const params = {};

    if (month) {
      params.month = month;
    }

    if (year) {
      params.year = year;
    }

    if (props.date) {
      params.date = props.date;
    }

    if (props.search) {
      params.search = props.search;
    }

    params.type = type;

    // console.log(" dataDownload ===> ", dataDownload);

    fetchAPIVisitor(params, true);
  };

  var downloadXLS = "";
  var ButtonDownload = "";

  ButtonDownload = (
    <Button
      disabled={props.loading}
      color=""
      onClick={handleDownload}
      className="btn btn-rounded btnCekJam mx-auto">
      Download {props.downloadType}
    </Button>
  );

  if (props.downloadType != "PDF") {
    ButtonDownload = (
      <>
        {props.activeTab == "cp" ? (
          <>
            <ReactHTMLTableToExcel
              id="TableXLSModal"
              className="btn btn-rounded btnCekJam mx-auto d-none"
              table="DownloadTableXLSModal"
              filename={`Survey Kepuasan ${props.activeTab}_Report_${props.role}`}
              sheet="users"
              buttonText="Download XLS Old"
              disabled={props.loading}
              ref={inputXLS}
            />
            <DownloadXLS
              id="DownloadTableXLSModal"
              className="DownloadTableXLSModal"
              data={dataDownload}
              activeTab={props.activeTab}
            />
            {/* <Button
              disabled={props.loading}
              color=""
              onClick={() => handleDownload("xls")}
              className="btn btn-rounded btnCekJam mx-auto">
              Download {props.downloadType}
            </Button> */}
          </>
        ) : (
          <>
            {/* <ReactHTMLTableToExcel
              id="TableXLSModal"
              className="btn btn-rounded btnCekJam mx-auto d-none"
              table="DownloadTableXLSVisitorModal"
              filename={`Survey Kepuasan ${props.activeTab}_Report_${props.role}`}
              sheet="users"
              buttonText="Download XLS Old"
              disabled={props.loading}
              ref={inputXLS}
            />
            <DownloadXLSVisitor
              id="DownloadTableXLSVisitorModal"
              className="DownloadTableXLSVisitorModal"
              data={dataVisitorDownload}
              activeTab={props.activeTab}
            /> */}
            <Button
              disabled={props.loading}
              color=""
              onClick={() => handleDownload("xls")}
              className="btn btn-rounded btnCekJam mx-auto">
              Download {props.downloadType}
            </Button>
          </>
        )}
      </>
    );
  }

  const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  const thisYear = new Date().getFullYear();

  return (
    <MDBContainer>
      <MDBModal
        className="DatePickerCustom"
        size="lg"
        isOpen={props.modalCalendar}
        toggle={props.toggleModalCalendar}
        centered={true}
        hiddenModal={() => {
          setMonth("");
          setYear("");
          setMessage("");
          setIsOpen(false);
        }}>
        <MDBModalHeader className="text-center displayContent">
          <Row className="col-lg-12">
            <Col className="col-lg-4 mx-auto">Pilih Bulan dan Tahun </Col>
          </Row>
        </MDBModalHeader>
        <MDBModalBody>
          <Container className="containerCalendar">
            <Row className="col-lg-12 mx-auto">
              <Col className="col-lg-12 mx-auto">
                <Alert
                  className="w-100 text-center wrapperAlert"
                  color={notif ? "success" : "danger"}
                  isOpen={isOpen}>
                  {message}
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-12 mx-auto">
                <center> {props.loading ? <Spinner></Spinner> : ""} </center>
              </Col>
            </Row>
            <Row className="rowCalendar">
              <Col className="col-lg-6 mx-auto">
                <div className="form-group">
                  Month
                  <select
                    className="form-control"
                    value={month}
                    onChange={(e) => handleChangeMonth(e)}>
                    <option value=""> -- Pilih Bulan -- </option>
                    {moment.months().map((item, index) => {
                      return <option value={index + 1}>{item}</option>;
                    })}
                  </select>
                </div>
              </Col>
              <Col className="col-lg-6 mx-auto">
                Year
                <select className="form-control" value={year} onChange={(e) => handleChangeYear(e)}>
                  <option value=""> -- Pilih Tahun -- </option>
                  {range(thisYear - 1, thisYear, 1).map((item) => {
                    return <option value={item}> {item}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row>{ButtonDownload}</Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};
export default connect(mapStatetoProps, null)(FilterCalendar);
