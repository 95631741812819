// import React from 'react'
// import styles from './style.module.css';
// // import { withRouter } from 'react-router';

// const MenuItem = ({ item, className, keys, action, active }) => {
// 	const onClick = () => action(item);
// 	const customStyle = {
// 		backgroundColor: `${(active !== item) ? "#c42127" : "#2c3280"}`,
// 	}
// 	return (
// 		<div className={className} style={customStyle} onClick={onClick} key={keys}>
// 			<b>{item}</b>
// 		</div>)
// }

// export default function sideMenuBI({ active, action }) {
//     const listSubMenuBI = [
// 		"Persebaran Pengunjung",
// 		"Demografi Pengunjung",
// 		"Trafik Konten",
// 		"ISP",
// 		"Device Fingerprint",
// 		"Survey",
// 		"User Behavior"
// 	];
//     return (
//         <div className={styles.cardBI}>
// 			<p className={styles.dashboard_title}>Measures</p>
// 			<React.Fragment>
// 				{listSubMenuBI.map((menu, index) => {
// 					return <MenuItem
// 						item={menu}
// 						className={styles.buttonBI}
// 						keys={ `${index}` }
// 						action={action}
// 						active={active}
// 					/>
// 				})}
// 			</React.Fragment>
// 		</div>
//     )
// }

import React, { useEffect, useState } from "react";
import "./style.css";
// import "./style.module.css";
import { useHistory } from "react-router-dom";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
// import logo from "../../assets/images/BAKTI.png";
import { Button } from "reactstrap";

// import iconA from "../../assets/icon/BAKTI-ICON.png";
//redux
import { connect, useDispatch } from "react-redux";
//action
import { changeSidebarType, toggleLeftmenu } from "../../store/actions";
import { setDataLogHistory } from "../../store/data/actions";

import styles from "./style.module.css";

const SidebarContent = (props) => {
  const role = window.localStorage.getItem("role").toLocaleLowerCase().replace(/\s/g, "");
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const history = useHistory();
  const [active, setActive] = useState("");

  const dispatch = useDispatch();
  const handleResetReduxLogHistory = () => {
    dispatch(setDataLogHistory({}));
  };

  const toggleSidebar = () => {
    // console.log("asdasdadasds");
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  };
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = props.location.pathname;
    setActive(props.location.pathname);
    const initMenu = () => {
      new MetisMenu("#side-menu-bi");
      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu-bi");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  // const role = window.localStorage.getItem("role");
  // const role = "contentpartner"

  // const customStyle = {
  // 	backgroundColor: `${(active === item) ? "#c42127" : "#2c3280"}`,
  // }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  }
  return (
    <React.Fragment>
      <div style={{ border: "2px solid #f7f7f7", borderRadius: "12px" }} className="p-3">
        <h5 className={styles.measures_title}>Measures</h5>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu-bi">
            <li>
              <Link
                to={`/${role}/business-intelligence-PersebaranPengunjung`}
                className="waves-effect m-0 py-1 px-0">
                {/* <i className="bx bx-home-circle"></i> */}
                {/* <span>{props.t("Persebaran Pengunjung")}</span> */}
                <Button
                  onClick={handleResetReduxLogHistory}
                  className={
                    active !== `/${role}/business-intelligence-PersebaranPengunjung`
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("Persebaran Pengunjung")}
                </Button>
              </Link>
            </li>
            <li>
              <Link
                to={`/${role}/business-intelligence-DemografiPengunjung`}
                className=" waves-effect m-0 py-1 px-0">
                {/* <i className="bx bx-copy"></i> */}
                <Button
                  onClick={handleResetReduxLogHistory}
                  className={
                    active !== `/${role}/business-intelligence-DemografiPengunjung`
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("Demografi Pengunjung")}
                </Button>
              </Link>
            </li>
            <li>
              <Link
                to={`/${role}/business-intelligence-TrafficKonten`}
                className=" waves-effect m-0 py-1 px-0">
                {/* <i className="bx bxs-edit"></i> */}
                <Button
                  onClick={handleResetReduxLogHistory}
                  className={
                    active !== `/${role}/business-intelligence-TrafficKonten`
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("Trafik Konten")}
                </Button>
              </Link>
            </li>
            <li>
              <Link
                to={`/${role}/business-intelligence-SummaryTrafficKonten`}
                className=" waves-effect m-0 py-1 px-0">
                {/* <i className="bx bxs-edit"></i> */}
                <Button
                  onClick={handleResetReduxLogHistory}
                  className={
                    active !== `/${role}/business-intelligence-SummaryTrafficKonten`
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("Summary Trafik Konten")}
                </Button>
              </Link>
            </li>
            <li>
              <Link
                to={`/${role}/business-intelligence-ISP`}
                className=" waves-effect m-0 py-1 px-0">
                {/* <i className="bx bx-show-alt"></i> */}
                <Button
                  onClick={handleResetReduxLogHistory}
                  className={
                    active !== `/${role}/business-intelligence-ISP`
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("ISP")}
                </Button>
              </Link>
            </li>
            <li>
              <Link
                to={`/${role}/business-intelligence-DeviceFingerPrint`}
                className=" waves-effect m-0 py-1 px-0">
                {/* <i className="bx bx-show-alt"></i> */}
                <Button
                  onClick={handleResetReduxLogHistory}
                  className={
                    active !== `/${role}/business-intelligence-DeviceFingerPrint`
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("Device Fingerprint")}
                </Button>
              </Link>
            </li>
            <li>
              <Link
                to={`/${role}/business-intelligence-Survey`}
                className=" waves-effect m-0 py-1 px-0">
                {/* <i className="bx bx-show-alt"></i> */}
                <Button
                  onClick={handleResetReduxLogHistory}
                  className={
                    active !== `/${role}/business-intelligence-Survey`
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("Survey")}
                </Button>
              </Link>
            </li>
            <li>
              <Link
                to={`/${role}/business-intelligence-UserBehavior`}
                className=" waves-effect m-0 py-1 px-0">
                {/* <i className="bx bx-show-alt"></i> */}
                <Button
                  onClick={handleResetReduxLogHistory}
                  className={
                    active !== `/${role}/business-intelligence-UserBehavior`
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("User Behavior")}
                </Button>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/${role}/business-intelligence-VisitorJourney"
                className=" waves-effect m-0 py-1 px-0">
                <Button onClick={handleResetReduxLogHistory}
                  className={
                    active !== "/admin/business-intelligence-VisitorJourney"
                      ? styles.buttonBI
                      : styles.buttonBIActive
                  }>
                  {props.t("Visitor Journey")}
                </Button>
              </Link>
            </li> */}

            {isMobile ? (
              <li>
                <Link
                  to="#"
                  onClick={() => history.goBack()}
                  className=" waves-effect m-0 py-1 px-0">
                  <i className="bx bx-arrow-back"></i>
                  <Button
                    onClick={handleResetReduxLogHistory}
                    className={
                      active !== `/${role}/business-intelligence-DemografiPengunjung`
                        ? styles.buttonBI
                        : styles.buttonBIActive
                    }>
                    {props.leftSideBarType === "default" ? `` : ` Back`}
                  </Button>
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { leftMenu, leftSideBarType } = state.Layout;
  return { leftMenu, leftSideBarType };
};
export default connect(mapStatetoProps, { toggleLeftmenu, changeSidebarType })(
  withRouter(withNamespaces()(SidebarContent))
);
