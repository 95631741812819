import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Card, CardBody, Button } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

// STYLE
import "./style.scss";
import style from "./style.module.css";

// API
import API from "../../../services";

// REDUX
import { useDispatch } from "react-redux";
import { setDataLogHistory } from "../../../store/data/actions";

const TableLogHistory = (props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [loading, setLoading] = useState(true);
  let idUser = window.localStorage.getItem("idUser");
  let role = window.localStorage.getItem("role");
  const history = useHistory();
  const dispatch = useDispatch();

  const [reload, setReload] = useState(false);

  const handleRedirect = (param, data_measure) => {
    dispatch(setDataLogHistory(data_measure));
    const urlRole = role.toLowerCase();
    switch (param) {
      case "isp":
        history.push(`/${urlRole}/business-intelligence-ISP`);
        break;
      case "persebaran":
        history.push(`/${urlRole}/business-intelligence-PersebaranPengunjung`);
        break;
      case "demografi":
        history.push(`/${urlRole}/business-intelligence-DemografiPengunjung`);
        break;
      case "summary_trafik":
        history.push(`/${urlRole}/business-intelligence-SummaryTrafficKonten`);
        break;
      case "traffik":
        history.push(`/${urlRole}/business-intelligence-TrafficKonten`);
        break;
      case "survey":
        history.push(`/${urlRole}/business-intelligence-Survey`);
        break;
      case "behavior":
        history.push(`/${urlRole}/business-intelligence-UserBehavior`);
        break;

      default:
        break;
    }
  };

  const deleteData = (id, title) => {
    const param = {
      id: id,
    };
    Swal.fire({
      title: "Apakah kamu yakin ingin menghapus data ini?",
      text: "Data akan hilang",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2c3280",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yakin",
    }).then((result) => {
      if (result.isConfirmed) {
        API.deleteSaveLogHistory(param)
          .then((res) => {
            setReload(!reload);
            Swal.fire({
              position: "center",
              icon: "success",
              title: `Data dengan judul ${title} berhasil dihapus`,
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            setReload(!reload);
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Data dengan judul ${title} tidak berhasil dihapus`,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  useEffect(() => {
    const param = {
      user: idUser,
    };

    API.getDataLogHistory(param)
      .then((res) => {
        if (res.data.success) {
          setRows(res.data.data);
          setLoading(false);
        } else {
          setRows([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setRows([]);
        setLoading(false);
      });
  }, [reload]);

  const checkMeasure = (e) => {
    switch (e) {
      case "demografi":
        return "Demografi Pengunjung";
      case "persebaran":
        return "Persebaran Pengunjung";
      case "survey":
        return "Survey";
      case "traffik":
        return "Traffik Konten";
      case "isp":
        return "ISP";
      case "behavior":
        return "User Behavior";
      case "summary_trafik":
        return "Summary Trafik Konten";

      default:
        return "-";
    }
  };
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Judul Data",
        field: "title",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Disimpan Oleh",
      //   field: "save_by",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Measure",
        field: "measure",
        sort: "asc",
        width: 200,
      },
      {
        label: "Tanggal Disimpan",
        field: "save_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "",
        field: "open",
        sort: "disabled",
        width: 200,
      },
      {
        label: "",
        field: "delete",
        sort: "disabled",
        width: 200,
      },
    ],
    rows:
      rows.length === 0
        ? [
            {
              title: "Tidak ada data",
              save_by: "",
              measure: "",
              save_date: "",
              open: "",
              delete: "",
            },
          ]
        : rows.map((e, i) => ({
            no : i + 1,
            title: e.title ?? "-",
            save_by: e.user && (e.user.name ?? "-"),
            measure: checkMeasure(e.measure ?? "-"),
            save_date: e.tanggal_pembuatan
              ? moment(e.tanggal_pembuatan).format("DD MMM YYYY") ?? "-"
              : "-",
            open: [
              <div key={i} style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  key={i}
                  className={`${style.btnOpen} px-4`}
                  onClick={() => {
                    handleRedirect(e.measure, e);
                  }}>
                  <span className={style.btnTextColorBlue}>Buka</span>
                </Button>
              </div>,
            ],
            delete: [
              <div key={i} style={{ display: "flex", justifyContent: "center" }}>
                <span
                  key={i}
                  className="mdi mdi-delete-forever mdi-18px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    deleteData(e._id, e.title);
                  }}
                />
              </div>,
            ],
          })),
  };

  return (
    <React.Fragment>
      <div className="m-5 p-5">
        {loading ? (
          <div>
            <h1>
              <Skeleton />
            </h1>
            <Skeleton count={10} />
          </div>
        ) : rows == null ? (
          <div> TERJADI KESALAHAN</div>
        ) : (
          <MDBDataTable
            // className="text-center"
            responsive
            striped
            bordered
            // searching={false}
            displayEntries={false}
            info={false}
            entries={8} //How much data that you want to show in 1 table
            // disableRetreatAfterSorting //Show red Warning after use
            data={data}
            noBottomColumns={true}
            // paging={false}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TableLogHistory;
