import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import "./style.css";
import moment from "moment";
import API from "../../../../services";
import Skeleton from "react-loading-skeleton";
const ReplyMessage = ({ isRefresh, data }) => {
  const [replyMessagedata, setReplyMessagedata] = useState([]);
  const [load, setLoad] = useState(true);
  const getReply = () => {
    setLoad(true);
    let param = {
      id: data._id,
    };

    API.postReplyTicketDetail(param)
      .then((res) => {
        if (res.data.success) {
          setReplyMessagedata(res.data.data.reply);
          setLoad(false);
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    getReply();
  }, [data, isRefresh]);

  return (
    <Container className="text-center" style={{ overflowY: "auto" }}>
      {load ? (
        <Skeleton count="10" />
      ) : replyMessagedata.length === 0 ? (
        <React.Fragment>
          <Row className="text-center">
            <Col lg={{ size: 12 }}>Tidak ada data history</Col>
          </Row>
        </React.Fragment>
      ) : (
        replyMessagedata.map((data) => {
          if (data.repliedType === "support") {
            return (
              <React.Fragment>
                <Row className="text-center">
                  <Col lg={{ size: 12 }}>{moment(data.repliedAt).format("DD-MM-YYYY")}</Col>
                </Row>
                <Row>
                  <Col lg="12" className={`text-left supportReply font-weight-bold mb-1`}>
                    {data.repliedBy}
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className={`text-left supportReply`}>
                  {data.message}
                    {/* <div dangerouslySetInnerHTML={{__html:data.message}}></div> */}
                  </Col>
                </Row>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <Row className="text-center">
                  <Col lg={{ size: 12 }}>{moment(data.repliedAt).format("DD-MM-YYYY")}</Col>
                </Row>
                <Row>
                  <Col lg="12" className={`text-right userReply font-weight-bold mb-1`}>
                    {data.repliedBy}
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className={`text-right userReply`}>
                    {data.message}
                    {/* <div dangerouslySetInnerHTML={{__html:data.message}}></div> */}
                  </Col>
                </Row>
              </React.Fragment>
            );
          }
        })
      )}
    </Container>
  );
};

export default ReplyMessage;
