import React, { useState, useRef, useEffect } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import mapboxgl from "mapbox-gl";
// import style from "../style.module.css";
// import { LocalContext } from "./LocalContext";
import { drawGeoPoint } from "./maphelper";
import {} from "react-router-dom";
import { profileSuccess } from "../../store/actions";

const Map = ReactMapboxGl({
  maxZoom: 20,
  minZoom: 1,
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
});

export default function MapHooks(props) {
  const mapRef = useRef(null);
  const [name, setName] = useState("BALI");

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  return (
    <>
      {!props.loading
        ? props.data?.length !== 0 && (
            <Map
              ref={mapRef}
              zoom={props.zoom}
              center={props.center}
              maxBounds={[
                [86.160622, -17.545353],
                [149.441872, 18.118934],
              ]}
              trackResize={true}
              style={"mapbox://styles/mapbox/streets-v11"}
              containerStyle={{ height: "100%", width: "100%" }}
              onStyleLoad={async (map, load) => {
                map.addControl(new mapboxgl.FullscreenControl());
                map.addControl(new mapboxgl.NavigationControl());
                drawGeoPoint(map, props.data, name, popup, props.type, props.cond);
              }}
            />
          )
        : ""}
    </>
  );
}
