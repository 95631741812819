import React from 'react';
import { Row, Col, Card, CardBody, CardTitle,Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

// Charts
import Line from "../KontenPartnerAnalis/LineChart"


const EChart = (props) => {
    // console.log("propsEChart", props.data)
    // console.log('chart awal',props)
    return (
        <React.Fragment>
            <div className="page-content">
            <Container fluid={true}>
                <Row>
                <Col lg="12">
                    <Card>
                    <CardBody>
                        <div id="line-chart" className="e-chart">
                            <Line />
                        </div>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </Container>
            </div>
        </React.Fragment>
        );
    }
    
export default EChart;