// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_headText__N-88f {\n\ttext-align: center !important;\n\tmargin-left: 30px !important;\n\tcolor: #55BA59 !important;\n}\n\n.style_title__2w54v {\n\tcolor: #333;\n\tfont-weight: bold;\n\ttext-transform: uppercase;\n}\n\n.style_sub_title__2jaFZ {\n\tcolor: #888;\n}\n\n.style_text__2o9nt {\n\tcolor: #888;\n\tfont-weight: bold;\n\tmargin-bottom: 50px;\n}\n\n.style_btn_cancel__3Zh0D {\n\tcursor: pointer;\n}\n\n.style_wrapper__2dZus {\n\tmargin-bottom: 50px;\n\tdisplay: block !important;\n}\n\n.style_btn_confirm__3rrYV {\n\tbackground-color: #55BA59 !important;\n\tpadding: 10px 40px !important;\n\tcolor: #fff !important;\n\tcursor: pointer;\n\tfont-size: 18px !important;\n\tmargin-bottom: 10px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"headText": "style_headText__N-88f",
	"title": "style_title__2w54v",
	"sub_title": "style_sub_title__2jaFZ",
	"text": "style_text__2o9nt",
	"btn_cancel": "style_btn_cancel__3Zh0D",
	"wrapper": "style_wrapper__2dZus",
	"btn_confirm": "style_btn_confirm__3rrYV"
};
module.exports = exports;
