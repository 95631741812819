import React, { useState, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Alert, Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment";

//i18n
import { withNamespaces } from "react-i18next";

import PopupRating from "../../ContentPartner/Popup/PopupRating";
// --------Redux---------
import { connect } from "react-redux";
import { getDatasNotif } from "../../../store/actions";

const NotificationDropdown = (props) => {
  let history = useHistory();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [modalRating, setModalRating] = useState(false);
  const [dataNotif, setDataNotif] = useState("");
  const [data, setData] = useState([]);

  // Alert notif
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [notif, setNotif] = useState(false);

  const getNotif = () => {
    setData(props.data);
  };
  useEffect(() => {
    getNotif();
    return () => { };
  }, [props.data]);

  const toggleRating = () => {
    setModalRating(!modalRating);
  };

  const handlePopupRating = (idContent, typeContent) => {
    setDataNotif({ id: idContent, type: typeContent });
    setModalRating(!modalRating);
  };

  const checkNotif = window.localStorage.getItem("notif");

  const handleNotif = () => {
    props.data.length !== 0
      ? props.data[0] !== undefined
        ? window.localStorage.setItem("notif", props.data[0]._id ? props.data[0]._id : "")
        : window.localStorage.setItem("notif", "")
      : window.localStorage.setItem("notif", "");
    // setNotif(false)
    // setData([])
  };

  const differenceTime = (date) => {
    var dateNow = moment(Date.now());
    const dates = moment(date);
    const diff = dateNow.diff(dates, "seconds");

    if (diff <= 60) {
      return "a moment ago";
    } else if (diff < 3600) {
      return `${(diff / 60).toFixed()} minutes ago`;
    } else if (diff <= 3600 * 24) {
      return `${(diff / 3600).toFixed()} hours ago`;
    } else if (diff <= 86400 * 30) {
      return `${(diff / 86400).toFixed()} days ago`;
    } else {
      return "long time ago";
    }
  };
  //click notif
  const handleClickNotif = (val) => {
    props.getDatasNotif(val);
    if (
      val.notif_type === "lolos kurasi" ||
      val.notif_type === "approved" ||
      val.notif_type === "declined" ||
      val.notif_type === "tayang" ||
      val.notif_type === "selesai tayang"
    ) {
      history.push("/content-partner/konten");
    }
    if (val.notif_type === "revisi") {
      history.push("/content-partner/konten-revisi");
    }
    if (
      val.notif_type === "pending" ||
      val.notif_type === "cancelled" ||
      val.notif_type === "tidak lolos kurasi"
    ) {
      history.push("/content-partner/library-konten");
    }
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li">
        <DropdownToggle
          onClick={() => {
            handleNotif();
          }}
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown">
          {data.length !== 0 &&
            props.data != undefined &&
            checkNotif !== (props ? (props.data[0] ? (props.data[0]._id ? "" : "") : "") : "") ? (
              <i className="bx bx-bell bx-tada"></i>
            ) : (
              <i className="bx bx-bell"></i>
            )}
          {data.length !== 0 &&
            props.data != undefined &&
            checkNotif !== (props ? (props.data[0] ? (props.data[0]._id ? "" : "") : "") : "") && (
              <span className="badge badge-danger badge-pill">-</span>
            )}
        </DropdownToggle>

        <PopupRating
          modalRating={modalRating}
          toggleRating={toggleRating}
          data={dataNotif}
          handleAlert={{
            setNotif: setNotif,
            setAlert: setIsSuccessSubmitted,
            setMessage: setMessage,
          }}
        />

        <DropdownMenu
          style={{ transform: "none", width: "300px", maxHeight: "400px", overflowY: "scroll" }}
          className="dropdown-menu dropdown-menu-lg p-0"
          right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {"Notifications"} </h6>
              </Col>
            </Row>
          </div>
          <Alert color={notif ? "success" : "danger"} isOpen={isSuccessSubmitted}>
            {message}
          </Alert>

          {data.length === 0 ? (
            <SimpleBar>
              <a className="text-reset notification-item">
                <div className="media">
                  {/* <div onClick={() => console.log("")} className="media-body"> */}
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">No notifications</p>
                  </div>
                  {/* </div> */}
                </div>
              </a>
            </SimpleBar>
          ) : (
              <SimpleBar>
                {data.map((val) => {
                  return (
                    <a className="text-reset notification-item">
                      <div className="media">
                        <div onClick={() => handleClickNotif(val)} className="media-body">
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">{val.message}</p>
                            {val.notif_type === "selesai tayang" && (
                              <a
                                onClick={() => handlePopupRating(val.content._id, val.content_type)}
                                className="mb-2 text-primary">
                                {props.t("rate")}
                              </a>
                            )}
                            {/* <a
                            onClick={() =>
                              handlePopupRating(val.content._id, val.content_type)
                            }
                            className="mb-2 text-primary">
                            {props.t("rate")}
                          </a> */}
                            <li className="mb-0">
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              {differenceTime(val.tanggal_pembuatan)}{" "}
                            </li>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </SimpleBar>
            )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { getDatasNotif })(withRouter(NotificationDropdown));
