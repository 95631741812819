import React, { useState, useEffect } from "react";
import {
  Spinner,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Button,
  Input,
  ButtonDropdown,
} from "reactstrap";

import API from "../../services";

// AUTOCOMPLETE MATERIAL UI
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

// ASSETS
import iconDown from "../../assets/icon/back.png";

// STYLES
import "./styles.css";

// REDUX
import { useSelector } from "react-redux";

export default function FilterOrgProv(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);
  const [dropdownOrg, setDropdownOrg] = useState(false);
  const toggleDropdownOrg = () => setDropdownOrg((prevState) => !prevState);
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown((prevState) => !prevState);

  // const onChangeClearTitle = () => {
  //   setDataTitle("");
  // };

  const [pilihOrganization, setPilihOrganization] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_org_trafik_provinsi
      : "Semua Organisasi"
  );
  const [idOrg, setIdOrg] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.id_org_trafik_provinsi
      : ""
  );

  const [orgList, setOrgList] = useState([]);
  const [dataType, setDataType] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.data_type_trafik_provinsi
      : "Video"
  );

  const getOrgList = () => {
    API.getListOrg()
      .then((res) => {
        if (res.data.success) {
          setOrgList(res.data.data);
        } else {
          setOrgList([]);
        }
      })
      .catch((e) => {
        setOrgList(null);
      });
  };

  useEffect(() => {
    getOrgList();
    // setResetTitle(true);
  }, [idOrg, dataType]);

  const getData = () => {
    props.setLoading(true);
    // props.setShowComponent(true);
    // setResetTitle(false);
    props.setDataSave(
      Object.assign(props.dataSave, {
        data_type_trafik_provinsi: dataType,
        id_org_trafik_provinsi: idOrg,
        title_org_trafik_provinsi: pilihOrganization,
        id_provinsi_trafik_provinsi: props.idProvince,
      })
    );
    props.setOrg(pilihOrganization);
    props.setContent(dataType);

    const param =
      props.idProvince === "" && idOrg === ""
        ? {
            type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
          }
        : props.idProvince !== "" && idOrg === ""
        ? {
            type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
            province: props.idProvince,
          }
        : props.idProvince === "" && idOrg !== ""
        ? {
            type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
            organization: idOrg,
          }
        : props.idProvince !== "" &&
          idOrg !== "" && {
            type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
            province: props.idProvince,
            organization: idOrg,
          };
    API.getSummaryProvince(param)
      .then((res) => {
        if (res.data.success) {
          props.setData(res.data.data);
          // console.log(res.data.data);
          if (res.data.data) {
            if (res.data.data.content === 0) {
              setTimeout(() => {
                alert(`Tidak ada data ${dataType} pada ${pilihOrganization} di provinsi tersebut`);
              }, 500);
            }
          }
          props.setLoading(false);
        } else {
          props.setData([]);
          setTimeout(() => {
            alert(`Tidak ada data ${dataType} pada ${pilihOrganization} di provinsi tersebut`);
          }, 500);
          props.setLoading(false);
        }
      })
      .catch((e) => {
        props.setData(null);
        setTimeout(() => {
          alert(`Tidak ada data ${dataType} pada ${pilihOrganization} di provinsi tersebut`);
        }, 500);
        props.setLoading(false);
      });
  };
  const [resetFn, setResetFn] = useState(false);

  useEffect(() => {
    // const abortController = new AbortController();

    getData();
    // return function cancel() {
    //   abortController.abort();
    // };
  }, [props.idProvince, resetFn]);

  const resetAwait = () => {
    setPilihOrganization("Semua Organisasi");
    setIdOrg("");
    setDataType("Video");
    props.setIdProvince("");
    props.setResetFilter(true);
    // setResetFn((prevState) => !prevState);
  };

  const filterReset = () => {
    setResetFn((prevState) => !prevState);
    resetAwait();
  };

  const changeOrganization = (value) => {
    setPilihOrganization(value.name);
    setIdOrg(value._id);
  };
  const clearOrganization = () => {
    setPilihOrganization("Semua Organisasi");
    setIdOrg("");
  };

  return (
    <>
      <Row className="py-2">
        <Col lg="3" style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <ButtonDropdown style={{ width: "100%" }} isOpen={dropdown} toggle={toggleDropdown}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{dataType}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Video");
                }}>
                Video
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Banner");
                }}>
                Banner
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Berita");
                }}>
                Berita
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        <Col lg="4" style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {/* <ButtonDropdown style={{ width: "100%" }} isOpen={dropdownOrg} toggle={toggleDropdownOrg}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{pilihOrganization}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
                zIndex: "9999",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setPilihOrganization("Semua Organisasi");
                  setIdOrg("");
                }}>
                Semua Organisasi
              </DropdownItem>
              {orgList === null ? (
                <DropdownItem>Terjadi Kesalahan Server</DropdownItem>
              ) : (
                orgList.map((e) => (
                  <DropdownItem
                    key={e._id}
                    onClick={(i) => {
                      setIdOrg(e._id);
                      setPilihOrganization(e.name);
                    }}>
                    {e.name}
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          </ButtonDropdown> */}
          <Autocomplete
            // classes={style.dropdownAutocomplete}
            id="combo-box-demo"
            options={orgList !== null ? orgList : []}
            defaultValue={{ name: pilihOrganization }}
            value={{ name: pilihOrganization }}
            getOptionLabel={(option) => {
              return option.name;
            }}
            onChange={(e, value) => {
              value !== null ? changeOrganization(value) : clearOrganization();
            }}
            renderOption={(option) => (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>{option.name}</div>
                </div>
              </React.Fragment>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // value={dataTitle}
                placeholder="Masukan Nama Organisasi"
              />
            )}
          />
        </Col>
        <Col lg="1" className="p-0">
          <Button
            style={{
              backgroundColor: "#2C3280",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={() => {
              getData();
              props.setResetFilter(false);
            }}>
            <span style={{ fontSize: "11px" }}>Cari</span>
          </Button>
        </Col>
        <Col lg="1" className="p-0">
          <Button
            disabled={dataType === "Video" && pilihOrganization === "Semua Organisasi"}
            style={{
              backgroundColor: "#C42127",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={filterReset}>
            <span style={{ fontSize: "11px" }}>Reset</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}
