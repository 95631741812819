import React, { useState, useEffect } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from "../../../assets/images/back.png";

function FilterButton(props) {
  const { type, filter, clicked, list } = props;
  const [filterContain, setFilterContain] = useState([]);
  useEffect(() => {
    setFilterContain(list);
  }, [list, filter]);

  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle
          disabled={list.length === 0}
          caret
          color="primary"
          className="dropdown-rekomendasi">
          <div>{filter === "" ? `Loading ...` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus">
          {filterContain.map((fc, i) => {
            return (
              <MDBDropdownItem active={filter !== ""} onClick={() => clicked(fc)} key={i}>
                {fc}
              </MDBDropdownItem>
            );
          })}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
}
export default FilterButton;
