import Loadable from "react-loadable";
import Loading from "../../../../../components/Loading";

const TrafficKontenAsync = Loadable({
  loader: () =>
    import(
      "../../../../../pages/BusinessIntelligence/Admin/Measures/TrafficKonten" /* webpackChunkName: "TrafficKontenAsync" */
    ),
  loading: Loading,
});

export default TrafficKontenAsync;
