import React, { useState } from "react";
import { MDBModal } from "mdbreact";
import "./style.css";
// sweetalert2
import Swal from "sweetalert2";
// ------------------API-----------------------
import API from "../../../../services";
const ModalKirimPesan = ({
  modalKP,
  toggleModalKP,
  dataModalKP,
  handleAlert,
  fetchData,
}) => {
  const [comment, setComment] = useState("");
  const [subject, setSubject] = useState("");
  let idUser = dataModalKP.id

  let param = {
    idUser:  idUser ,
    message: comment,
    subjek: subject
  };

  const onHandleSubmit = () => {
      API.postSendMessage(param)
      .then(res => {
        if(res.data.success){
          Swal.fire({
            position: "center",
            icon: "success",
            title: `Pesan Berhasil dikirim`,
            showConfirmButton: false,
            timer: 1500,
          });
          toggleModalKP()
        }
        
      })
      .catch(e => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `Pesan Tidak Berhasil dikirim`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
  };

  return (
    <MDBModal isOpen={modalKP} toggle={toggleModalKP} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleKirim">KIRIM PESAN</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalKP}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">
                {dataModalKP.organization}
            </h4>
          </div>
          <div className="w-75 text-left">
            <p className="subTitleKirim">Subjek :</p>
          </div>
          <div className="form-group wrapperTextAreaDecline">
            <textarea
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              className="form-control textAreaSubject"
              rows="1"></textarea>
          </div>
          <div className="w-75 text-left">
            <p className="subTitleKirim">Pesan :</p>
          </div>
          <div className="form-group wrapperTextAreaDecline">
            <textarea
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              className="form-control textAreaDecline"
              rows="5"></textarea>
          </div>
          <div className="mx-auto wrapperBtnVerification">
            <button
              type="button"
              onClick={onHandleSubmit}
              className="w-100 btn wrapperBtnKirim btn-rounded">
              Kirim
            </button>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalKirimPesan;
