import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../../Filter/filter";
import _ from "lodash";
// ------------------API-----------------------
import API from "../../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconCheck from "../../../../../assets/icon/IconCheck.svg";
import IconDecline from "../../../../../assets/icon/IconDecline.svg";
import IconEdit from "../../../../../assets/icon/IconEdit.svg";
import IconGreenCheckList from "../../../../../assets/icon/IconGreenChecklist.svg";

// ------------------Component----------------
import ModalPreviewVideo from "../../../Popup/KontenDefault/PreviewContent";
import ModalActived from "../../../Popup/KontenDefault/ActivedContent";
import ModalPreviewContent from "../../../Popup/KontenDefault/PreviewContent";
// ------------------Redux----------------

import { connect } from "react-redux";
import noImage from "../../../../../assets/images/noImage.jpg";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
const TableKontenDefault = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  //notif
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [notif, setNotif] = useState(false);
  //alert
  const [alert, setAlert] = useState("");
  let typeContent = "";
  switch (props.activeTab) {
    case "1":
      typeContent = "video";
      break;
    case "2":
      typeContent = "banner";
      break;
    case "3":
      typeContent = "news";
      break;
    default:
      break;
  }
  const fetchAPI = () => {
    setLoading(true);
    API.getKontenDefaultAktif({ type: typeContent })
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setLoading(false);
        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(res.data.data);
        } else {
          setRows(result);
          document.getElementById("tableKontenDefault").rows[1].style.transition = "0.8s";
          document.getElementById("tableKontenDefault").rows[1].style.backgroundColor = "#e6f3ff";
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    fetchAPI();
  }, [refresh, props.idContent]);
  //throw data to parent for download
  const filterData = (item) => {
    // console.log("yg bkl ke lempar", item)
    props.handleData(item);
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search, props.activeTab]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      const index = [
        data.title ? data.title : data.details.title,
        data.requested_by
          ? data.requested_by.organization
            ? data.requested_by.organization.name
              ? data.requested_by.organization.name
              : ""
            : ""
          : "",
        data.category,
        data.proposed_provinsi.name,
        data.status,
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }

  //modal detail
  const [modalDetail, setModalDetail] = useState(false);
  const [dataModalDetail, setDataModalDetail] = useState({});
  const toggleModalDetail = () => {
    setModalDetail(!modalDetail);
  };
  const handleModalActivedContent = (data) => {
    setDataModalDetail({
      data: data,
      title: props.activeTab === "1" ? "VIDEO" : props.activeTab === "2" ? "BANNER" : "BERITA",
      province: data.proposed_provinsi.name,
      idProvince: data.proposed_provinsi._id,
      handleFetch: handleFetch,
      param: {
        idContent: data._id,
        idProvince: data.proposed_provinsi._id,
      },
    });
    toggleModalDetail();
  };
  //modal preview video
  const [modalPreviewContent, setModalPreviewContent] = useState(false);
  const [dataModalPreviewContent, setDataModalPreviewContent] = useState({});
  const toggleModalPreviewContent = () => {
    setModalPreviewContent(!modalPreviewContent);
  };
  const handleModalPreviewContent = (data) => {
    setDataModalPreviewContent({
      title:
        props.activeTab == "3"
          ? data.title
            ? data.title
            : "-"
          : data.details.title
          ? data.details.title
          : data.title
          ? data.title
          : "-",
      url: data.details ? (data.details.url ? data.details.url : data.details) : "",
      type: data.type ?? "-",
    });
    toggleModalPreviewContent();
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  const ButtonAction = ({ data }) => {
    return (
      <Button
        onClick={() => handleModalActivedContent(data)}
        className="btn-rounded btnDetail py-0 px-1 m-auto">
        <span style={{ fontSize: "11px", fontWeight: "bold" }}>Ganti Konten</span>
      </Button>
    );
  };
  const PreviewComponent = ({ it }) => {
    return props.activeTab === "3" ? (
      <a href={it.link ?? "-"} target="_blank">
        <center>
          <img
            alt={it.title ?? "-"}
            className="wrapperImg"
            src={`${process.env.REACT_APP_BE_URL}/${it.details.url ?? "-"}`}></img>
        </center>
      </a>
    ) : (
      <center style={{ width: "fit-content" }}>
        {/* {!it.tanggal_pembuatan.includes("2019") ? ( */}

        <img
          alt={props.activeTab == "3" ? it.title : it.details.title}
          className="wrapperImgYT"
          onClick={() => handleModalPreviewContent(it)}
          src={
            it.details
              ? it.details.thumbnail
                ? `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.thumbnail : ""}`
                : `${process.env.REACT_APP_BE_URL}/${
                    it.details ? (it.details.url ? it.details.url : it.details) : ""
                  }`
              : "-"
          }></img>
        {/* ) : (
          <img
            alt={props.activeTab == "3" ? it.title : it.details.title}
            className="wrapperImgYT"
            src={noImage}></img>
        )} */}
      </center>
    );
  };
  const reverseData = _.orderBy(items, "status", "desc");
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 200,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 150,
      },
      {
        label: "",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, i) => ({
            no: i + 1,
            judul:
              props.activeTab == "3"
                ? it.title
                  ? it.title
                  : "-"
                : it.details.title
                ? it.details.title
                : it.title
                ? it.title
                : "-",
            organisasi: it
              ? it.requested_by
                ? it.requested_by.organization
                  ? it.requested_by.organization.name
                    ? it.requested_by.organization.name
                    : "-"
                  : "-"
                : "-"
              : "-",
            kategori: it ? it.category : "-",
            provinsi: it
              ? it.proposed_provinsi
                ? it.proposed_provinsi.name
                  ? it.proposed_provinsi.name
                  : "-"
                : "-"
              : "-",
            status: it.status == "activated" ? "Aktif" : "Tidak Aktif",
            preview: [<PreviewComponent it={it} />],
            aksi: <ButtonAction data={it ? it : ""} />,
          })),
  };

  return (
    <React.Fragment>
      {alert == "successActivedContent" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil di Aktifkan"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alert == "failedActivedContent" ? (
        <SweetAlert
          timeout={3}
          title={"Konten Tidak Berhasil di Aktifkan"}
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : alert == "failedFetch" ? (
        <SweetAlert
          timeout={3}
          error
          title="User tidak berhasil di kurasi"
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : (
        ""
      )}
      <ModalPreviewContent
        ModalPreviewContent={modalPreviewContent}
        dataModalPreviewContent={dataModalPreviewContent}
        toggleModalPreviewContent={toggleModalPreviewContent}
        fetchData={handleFetch}
        activeTab={typeContent}
      />
      <ModalActived
        dataModalActivedContent={dataModalDetail}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalActivedContent={modalDetail}
        toggleModalActivedContent={toggleModalDetail}
        activeTab={props.activeTab}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">
                      Tidak ada konten yang dapat di aktifkan
                    </div>
                  ) : (
                    <MDBDataTable
                      id="tableKontenDefault"
                      noBottomColumns={true}
                      className="text-center"
                      responsive
                      disableRetreatAfterSorting
                      striped
                      bordered
                      data={data}
                      displayEntries={false}
                      pagesAmount={5}
                      entries={10}
                      searching={false}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TableKontenDefault);
