import React, { useEffect, useState } from "react";
import {
  Row,
  CardBody,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Alert,
  Modal,
  ModalBody,
} from "reactstrap";

import { MDBModalHeader, MDBNavLink } from "mdbreact";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";

// COMPONENT
import CardMap from "./Map/CardMap";
import CardGender from "./CardGender";
import CardPendidikan from "./CardPendidikan";
import CardPieChart from "./CardPieChart";
import CardPersebaranUmur from "./CardPersebaranUmur";

import ModalCalendar from "../../components/Admin/Popup/BI/Calendar";
import ModalSave from "../../components/Admin/Popup/BI/LogHistory/SaveFilter";

// STYLE CSS
import style from "./style.module.css";
import carbon_save from "../../assets/icon/carbon_save.png";
import iconDown from "../../assets/icon/back.png";
import cal from "../../assets/icon/calendar.png";

// API
import API from "../../services";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "./Download/XLS/DownloadDetailDemografi";

export default function Index() {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);

  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  const [titleData, setTitleData] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.content_title
      : "Kunjungan"
  );

  const [dataType, setDataType] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.data_type
      : "visit"
  );
  const [dataDemografi, setDataDemografi] = useState([]);
  const [dataDemografiDetail, setDataDemografiDetail] = useState([]);
  const [dataDemografiTopics, setDataDemografiTopics] = useState({});
  const [idProvince, setIdProvince] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.province
      : ""
  );

  const [loading, setLoading] = useState(true);
  const [loadingTopics, setLoadingTopics] = useState(true);

  //calendar modal
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  // MODAL SAVE
  const [modalSave, setModalSave] = useState(false);
  const toggleModalSave = () => {
    setModalSave(!modalSave);
  };

  const [dropdownOpenDate, setDropdownOpenDate] = useState(false);
  const toggleDate = () => setDropdownOpenDate((prevState) => !prevState);

  const [dropdownOpenDateAkumulasi, setDropdownOpenDateAkumulasi] = useState(false);
  const toggleDateAkumulasi = () => setDropdownOpenDateAkumulasi((prevState) => !prevState);

  const [dropdownOpenYear, setDropdownOpenYear] = useState(false);
  const toggleYear = () => setDropdownOpenYear((prevState) => !prevState);
  const [titleDate, setTitleDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.survey_title
      : "Daily"
  );

  const [error, setError] = useState(false);
  const [errorDetail, setErrorDetail] = useState(false);

  // TANGGAL DEFAULT
  var currentDate = new Date();

  const [endDate, setEndDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.end_date
      : moment(currentDate).subtract(1, "days").format("DD/MM/YYYY")
  );

  const listYear = _.range(2020, currentDate.getFullYear() + 1);
  // const listYear = _.range(2020, currentDate.getFullYear() + 5);

  // const start = currentDate.setDate(currentDate.getDate() - 1);
  const [startDate, setStartDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.start_date
      : moment(currentDate).subtract(1, "days").format("DD/MM/YYYY")
  );

  const [dropdownOpenData, setDropdownOpenData] = useState(false);
  const toggleData = () => setDropdownOpenData((prevState) => !prevState);

  const getDropdownData = (dat, title) => {
    setTitleData(title);
    setDataType(dat);
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  };

  // const [dateYear, setDateYear] = useState(currentDate.getFullYear());

  const [month, setMonth] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0 ? dataReduxLogHistory.data.filter.month : ""
  );
  const [year, setYear] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.year
      : currentDate.getFullYear()
  );

  const getDataDemografi = () => {
    if (startDate == "" || endDate == "") {
      return alert("Filter Belum Lengkap");
    } else {
      setLoading(true);
      const param =
        idProvince !== ""
          ? {
              type: dataType,
              start: startDate,
              end: endDate,
              province: idProvince,
            }
          : {
              type: dataType,
              start: startDate,
              end: endDate,
            };

      API.getBIDemografi(param)
        .then((res) => {
          if (res.data.success) {
            setDataDemografi(res.data.data);
            // console.log(res.data);
            setTimeout(() => {
              if (!res.data.data)
                alert(
                  `Data pada filter ${
                    dataType === "visit"
                      ? "Kunjungan"
                      : dataType === "device"
                      ? "Device Unik"
                      : "DFP"
                  } ${titleDate} pada tanggal tersebut tidak ada`
                );
            }, 500);
            setLoading(false);
            setError(false);
          }
        })
        .catch((e) => {
          setDataDemografi(null);
          setLoading(false);
          setError(true);
        });

      API.getBIDemografiDetail(param)
        .then((res) => {
          if (res.data.success) {
            setDataDemografiDetail(res.data.data);
            setLoading(false);
            setErrorDetail(false);
          }
        })
        .catch((e) => {
          setDataDemografiDetail(null);
          setLoading(false);
          setErrorDetail(true);
        });

      setLoadingTopics(true);
      const paramTopics =
        idProvince !== ""
          ? {
              type: dataType,
              start: startDate,
              end: endDate,
              province: idProvince,
            }
          : {
              type: dataType,
              start: startDate,
              end: endDate,
            };
      API.getBIDemografiTopics(paramTopics)
        .then((res) => {
          if (res.data.success) {
            setDataDemografiTopics(res.data.data);
            setLoadingTopics(false);
          }
        })
        .catch((e) => {
          setDataDemografiTopics(null);
          setLoadingTopics(false);
        });
    }
  };

  // const getDataTopics = () => {
  //   if (startDate == "" || endDate == "") {
  //     return alert("Tanggal Belum Dipilih");
  //   } else {
  //   }
  // };

  // useEffect(() => {
  //   getDataDemografi();
  //   getDataTopics();
  //   const handleProps = () => {};
  //   handleProps();
  //   return () => {
  //     handleProps();
  //   };
  // }, [dataType, startDate, endDate, idProvince]);
  useEffect(() => {
    getDataDemografi();
    // getDataTopics();
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [idProvince]);

  var firstDay = new Date(year, currentDate.getMonth() - currentDate.getMonth(), 1);
  // var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

  const [daily, setDaily] = useState(true);
  const [weekly, setWeekly] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [akumulasi, setAkumulasi] = useState(false);

  const [titleYear, setTitleYear] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.year
      : "Pilih Tahun"
  );
  const [titleMonth, setTitleMonth] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.traffic_duration
      : "Pilih Bulan"
  );

  const [titleWeek, setTitleWeek] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.traffic_time
      : "Pilih Minggu"
  );

  const handleDropdownDate = (param) => {
    setDaily(false);
    setWeekly(false);
    setMonthly(false);
    setAkumulasi(false);
    setTitleYear("Pilih Tahun");
    setTitleMonth("Pilih Bulan");
    setTitleWeek("Pilih Minggu");
    setStartDate("");
    setEndDate("");

    switch (param) {
      case "daily":
        setDaily(true);
        setTitleDate("Daily");
        setStartDate(moment(currentDate).subtract(2, "months").format("DD/MM/YYYY"));
        setEndDate(moment(currentDate).subtract(2, "months").format("DD/MM/YYYY"));
        break;
      case "weekly":
        setWeekly(true);
        setTitleDate("Weekly");
        // setStartDate(moment(currentDate).subtract(8, "days").format("DD/MM/YYYY"));
        // setEndDate(moment(currentDate).subtract(1, "days").format("DD/MM/YYYY"));
        break;
      case "monthly":
        setMonthly(true);
        setTitleDate("Monthly");
        // setTitleYear("Pilih Tahun");
        // setTitleMonth("Pilih Bulan");
        // setTitleWeek("Pilih Minggu");
        // setStartDate(moment(currentDate).format("DD/MM/YYYY"));
        // setEndDate(moment(currentDate).format("DD/MM/YYYY"));
        break;
      case "akumulasi":
        setAkumulasi(true);
        setTitleDate("Akumulasi");
        // setTitleYear("Pilih Tahun");
        // setTitleMonth("Pilih Bulan");
        // setTitleWeek("Pilih Minggu");
        break;
      default:
        break;
    }
  };
  const handleDropdownDateRedux = (param) => {
    switch (param) {
      case "daily":
        setDaily(true);
        setTitleDate("Daily");
        // setStartDate(moment(currentDate).subtract(1, "days").format("DD/MM/YYYY"));
        // setEndDate(moment(currentDate).subtract(1, "days").format("DD/MM/YYYY"));
        break;
      case "weekly":
        setWeekly(true);
        setTitleDate("Weekly");
        // setStartDate(moment(currentDate).subtract(8, "days").format("DD/MM/YYYY"));
        // setEndDate(moment(currentDate).subtract(1, "days").format("DD/MM/YYYY"));
        break;
      case "monthly":
        setMonthly(true);
        setTitleDate("Monthly");
        // setTitleYear("Pilih Tahun");
        // setTitleMonth("Pilih Bulan");
        // setTitleWeek("Pilih Minggu");
        // setStartDate(moment(currentDate).format("DD/MM/YYYY"));
        // setEndDate(moment(currentDate).format("DD/MM/YYYY"));
        break;
      case "akumulasi":
        setAkumulasi(true);
        setTitleDate("Akumulasi");
        // setTitleYear("Pilih Tahun");
        // setTitleMonth("Pilih Bulan");
        // setTitleWeek("Pilih Minggu");
        break;
      default:
        break;
    }
  };

  const handleSelectMonth = (e, i) => {
    setTitleMonth(e.currentTarget.textContent);
    if (akumulasi) {
      setStartDate(moment(firstDay).format("DD/MM/YYYY"));
      setEndDate(moment(new Date(year, i + 1, 0)).format("DD/MM/YYYY"));
    } else if (monthly) {
      setStartDate(moment(new Date(year, i, 1)).format("DD/MM/YYYY"));
      setEndDate(moment(new Date(year, i + 1, 0)).format("DD/MM/YYYY"));
    } else {
      setMonth(i + 1);
      setTitleWeek("Pilih Minggu");
    }
  };

  const handleSelectYear = (e, i) => {
    // if (e.currentTarget.textContent == 2020 && dataType == "device") {
    if (e.currentTarget.textContent == 2020 && titleData == "Device Unik") {
      alert(`Tidak ada data ${titleData} di tahun ${e.currentTarget.textContent}`);
    }
    // } else if (e.currentTarget.textContent != 2020 && dataType == "dfp") {
    else if (e.currentTarget.textContent != 2020 && titleData == "DFP") {
      alert(`Tidak ada data ${titleData} di tahun ${e.currentTarget.textContent}`);
    } else {
      setTitleYear(e.currentTarget.textContent);
      setYear(e.currentTarget.textContent);
      setTitleMonth("Pilih Bulan");
    }
  };

  const [dropdownWeek, setDropdownWeek] = useState(false);
  const toggleWeek = () => setDropdownWeek((prevState) => !prevState);

  const renderDropdownWeek = () => {
    const start = moment(`01/${month}/${year}`, "DD/MM/YYYY").startOf("month");
    const end = moment(start).endOf("month");

    var current = start;

    const listWeek = [];

    while (current.isSameOrBefore(end)) {
      var curr = moment(current).startOf("isoWeek");
      var currEnd = moment(current).endOf("isoWeek");

      if (curr.month() == start.month()) {
        listWeek.push(`${curr.format("DD MMM YY")} - ${currEnd.format("DD MMM YY")}`);
      }
      current = currEnd.add(1, "days");
    }

    return listWeek;
  };

  const handleSelectWeek = (e) => {
    const date = e.split("-");
    setTitleWeek(e);
    setStartDate(moment(date[0], "DD MMM YY").format("DD/MM/YYYY"));
    setEndDate(moment(date[1], "DD MMM YY").format("DD/MM/YYYY"));
  };

  useEffect(() => {
    if (
      Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.measure === "demografi"
    ) {
      handleDropdownDateRedux(dataReduxLogHistory.data.filter.survey_title.toLowerCase());
    }
  }, []);

  const resetFilter = () => {
    setTitleData("Kunjungan");
    setTitleDate("Daily");
    setDataType("visit");
    handleDropdownDate("daily");
  };
  return (
    <div className="container-fluid" style={{ marginBottom: "100px" }}>
      <ModalCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setYear={setYear}
        setDataType={setDataType}
        setTitleData={setTitleData}
        dataType={dataType}
        titleData={titleData}
        defaultDate={startDate}
      />
      <ModalSave
        modal={modalSave}
        toggle={toggleModalSave}
        data={{
          data_type: dataType,
          content_title: titleData,
          survey_title: titleDate,
          province: idProvince,
          year: titleYear,
          month: month,
          traffic_duration: titleMonth,
          traffic_time: titleWeek,
          start_date: startDate,
          end_date: endDate,
          measure: "demografi",
        }}
      />
      {errorDetail && ""}
      <Row
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}>
        <CardBody
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <span className={style.titleMenuBI}>DEMOGRAFI PENGUNJUNG</span>
          <div>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col>
                <Button
                  style={{
                    backgroundColor: "#2C3280",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingBottom: "3px",
                    paddingTop: "3px",
                    minWidth: "100%",
                  }}
                  onClick={toggleModalSave}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <img src={carbon_save} className="mr-2" />
                    <span>Save</span>
                  </div>
                </Button>
              </Col>
              <Col>
                {/* DOWNLOAD XLS */}
                {dataDemografiDetail !== undefined && dataDemografiDetail !== null ? (
                  <>
                    <Modal
                      size="md"
                      isOpen={modalDownloadXLS}
                      toggle={toggleDownloadXLS}
                      centered={true}>
                      <MDBModalHeader toggle={toggleDownloadXLS}>
                        <span className={style.headText}>Download XLS</span>
                      </MDBModalHeader>
                      <ModalBody>
                        <center className="container-popup">
                          <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                          <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                            Batal
                          </Button>
                          <Button
                            color=""
                            className={style.btn_confirm_xls}
                            onClick={toggleDownloadXLS}>
                            <ReactHTMLTableToExcel
                              id="TableXLS"
                              className={style.DownloadTableXLS}
                              table="DownloadTableXLSDemografi"
                              filename={`Demografi_Detail_${startDate}_${endDate}`}
                              sheet="users"
                              buttonText="Download"
                            />
                          </Button>
                        </center>
                      </ModalBody>
                    </Modal>
                    <Col
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-start",
                      }}>
                      <MDBNavLink to="#" className={style.textDownload}>
                        Download
                      </MDBNavLink>
                      <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                        XLS
                      </MDBNavLink>
                    </Col>
                    <DownloadXLS
                      id="DownloadTableXLSDemografi"
                      className="DownloadTableXLS"
                      data={dataDemografiDetail}
                      dataDemografi={dataDemografi}
                      title={`Data Demografi Pengunjung Berdasarkan ${titleData} ${titleDate} Tanggal ${startDate} - ${endDate}`}
                    />
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Row>

      <Row className="mt-3">
        <label>Pilih Data:</label>
      </Row>
      <Row className="mb-3">
        <Col>
          <Row>
            <ButtonDropdown
              style={{ minWidth: "100%" }}
              isOpen={dropdownOpenData}
              toggle={toggleData}>
              <DropdownToggle
                style={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: "20px",
                  borderColor: "#2C3280",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}>
                <div
                  style={{
                    color: "#2C3280",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span>{titleData}</span>
                  <img src={iconDown} />
                </div>
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                {year == 2020 ? (
                  <>
                    <DropdownItem
                      onClick={(e) => {
                        getDropdownData("visit", "Kunjungan");
                      }}>
                      Kunjungan
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        getDropdownData("dfp", "DFP");
                      }}>
                      DFP
                    </DropdownItem>
                    {/* <DropdownItem
                      onClick={(e) => {
                        getDropdownData("device", "Device Unik");
                      }}>
                      Device Unik
                    </DropdownItem> */}
                  </>
                ) : (
                  <>
                    {" "}
                    <DropdownItem
                      onClick={(e) => {
                        getDropdownData("visit", "Kunjungan");
                      }}>
                      Kunjungan
                    </DropdownItem>
                    {/* <DropdownItem
                      onClick={(e) => {
                        getDropdownData("dfp", "DFP Unik");
                      }}>
                      DFP Unik
                    </DropdownItem> */}
                    <DropdownItem
                      onClick={(e) => {
                        getDropdownData("dfp", "Device Unik");
                      }}>
                      Device Unik
                    </DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </Row>
        </Col>
        <Col xs="12" md="4" lg="3" style={{ display: "flex", alignItems: "end" }}>
          <ButtonDropdown style={{ width: "100%" }} isOpen={dropdownOpenDate} toggle={toggleDate}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "4px",
                paddingBottom: "4px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{titleDate}</span>
                <img src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
              <DropdownItem
                onClick={(e) => {
                  handleDropdownDate("daily");
                }}>
                Daily
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  handleDropdownDate("weekly");
                }}>
                Weekly
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  handleDropdownDate("monthly");
                }}>
                Monthly
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  handleDropdownDate("akumulasi");
                }}>
                Akumulasi
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>

        {akumulasi || monthly || weekly ? (
          <>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <ButtonDropdown
                style={{ width: "100%" }}
                isOpen={dropdownOpenYear}
                toggle={toggleYear}>
                <DropdownToggle
                  style={{
                    backgroundColor: "#F8F9FA",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}>
                  <div
                    style={{
                      color: "#2C3280",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <span>{titleYear}</span>
                    <img src={iconDown} />
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                  {listYear.map((e, i) => (
                    <DropdownItem
                      key={i}
                      onClick={(a) => {
                        handleSelectYear(a, i);
                      }}>
                      {e}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <ButtonDropdown
                style={{ width: "100%" }}
                isOpen={dropdownOpenDateAkumulasi}
                toggle={toggleDateAkumulasi}>
                <DropdownToggle
                  disabled={titleYear == "Pilih Tahun"}
                  style={{
                    backgroundColor: "#F8F9FA",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}>
                  <div
                    style={{
                      color: "#2C3280",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <span>{titleMonth}</span>
                    <img src={iconDown} />
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                  {moment
                    .months()
                    .slice(0, titleYear == currentDate.getFullYear() ? currentDate.getMonth() : 12)
                    .length !== 0 ? (
                    moment
                      .months()
                      .slice(
                        0,
                        titleYear == currentDate.getFullYear() ? currentDate.getMonth() : 12
                      )
                      .map((e, i) => (
                        <DropdownItem
                          key={i}
                          onClick={(a) => {
                            handleSelectMonth(a, i);
                          }}>
                          {e}
                        </DropdownItem>
                      ))
                  ) : (
                    <div className="px-3">Tidak Ada Data</div>
                  )}
                </DropdownMenu>
              </ButtonDropdown>
            </Col>

            {weekly && (
              <Col style={{ display: "flex", alignItems: "end" }}>
                <ButtonDropdown style={{ width: "100%" }} isOpen={dropdownWeek} toggle={toggleWeek}>
                  <DropdownToggle
                    disabled={titleMonth == "Pilih Bulan"}
                    style={{
                      backgroundColor: "#F8F9FA",
                      borderRadius: "20px",
                      borderColor: "#2C3280",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}>
                    <div
                      style={{
                        color: "#2C3280",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      <span>{titleWeek}</span>
                      <img src={iconDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                    {renderDropdownWeek().map((res, i) => (
                      <DropdownItem
                        key={i}
                        onClick={(a) => {
                          handleSelectWeek(res);
                        }}>
                        {res}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            )}
          </>
        ) : (
          <Col style={{ display: "flex", alignItems: "end" }}>
            <Button
              onClick={toggleModalCalendar}
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                {daily && <span className="mx-5">{startDate}</span>}
                <img src={cal} />
              </div>
            </Button>
          </Col>
        )}
      </Row>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col md="2">
          <Button
            onClick={() => {
              getDataDemografi();
              // getDataTopics();
            }}
            style={{
              backgroundColor: "#2c3280",
              borderRadius: "20px",
              borderColor: "#2c3280",
              minWidth: "100%",
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <i className="mdi mdi-magnify mr-2" />
              <span>Search</span>
            </div>
          </Button>
        </Col>
        <Col md="2">
          <Button
            onClick={resetFilter}
            style={{
              backgroundColor: "#C42127",
              borderRadius: "20px",
              borderColor: "#C42127",
              minWidth: "100%",
            }}>
            <div>
              <span>Reset</span>
            </div>
          </Button>
        </Col>
      </Row>
      {error ? (
        <Alert color="danger" className="text-center my-5">
          Tidak Ada Data
        </Alert>
      ) : (
        <>
          {/* MAP CARD */}
          <CardMap
            title={titleData}
            loading={loading}
            data={dataDemografi}
            setIdProvince={setIdProvince}
            idProvince={idProvince}
          />
          <CardGender
            title={titleData}
            loading={loading}
            data={dataDemografi}
            date={`${startDate} - ${endDate}`}
          />
          <CardPendidikan
            title={titleData}
            loading={loading}
            data={dataDemografi}
            date={`${startDate} - ${endDate}`}
          />
          <CardPersebaranUmur
            title={titleData}
            loading={loading}
            data={dataDemografi}
            date={`${startDate} - ${endDate}`}
          />
          <CardPieChart
            title={titleData}
            loading={loading}
            data={dataDemografi}
            dataTopics={dataDemografiTopics}
            loadingTopics={loadingTopics}
            date={`${startDate} - ${endDate}`}
          />
        </>
      )}
    </div>
  );
}
