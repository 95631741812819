import React from "react";
import { Row, Col, Spinner } from "reactstrap";

// STYLE
import style from "./style.module.css";

// ASSETS
import fingerprintIcon from "../../assets/images/FingerprintBI/finger_print.svg";
import devicesIcon from "../../assets/images/FingerprintBI/Devices.svg";
import userIcon from "../../assets/images/FingerprintBI/User.svg";
import webIcon from "../../assets/images/FingerprintBI/ls_web.svg";
import osIcon from "../../assets/images/FingerprintBI/la_android.svg";

export default function CardISP(props) {
  return (
    <div className="my-5">
      <Row className="text-center my-3 text-dark">
        <Col md="12" lg="4" className="justify-content-center">
          <img style={{ height: "50px" }} src={fingerprintIcon} />
          <div className={style.titlePendidikan} style={{ marginTop: "20px" }}>
            TOTAL DFP UNIK
          </div>
          {props.loading ? (
            <div className="my-3">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className={style.valueFingerprint}>
              {" "}
              {(props.data ? props.data.dfp ?? 0 : 0).toLocaleString()}
            </div>
          )}
        </Col>
        <Col
          md="12"
          lg="4"
          style={{ borderRight: "1px solid #C0C0C0", borderLeft: "1px solid #C0C0C0" }}>
          <img style={{ height: "50px" }} src={devicesIcon} />
          <div className={style.titlePendidikan} style={{ marginTop: "20px" }}>
            TOTAL DEVICE UNIK
          </div>
          {props.loading ? (
            <div className="my-3">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className={style.valueFingerprint}>
              {" "}
              {(props.data ? props.data.mac ?? 0 : 0).toLocaleString()}
            </div>
          )}
        </Col>
        <Col md="12" lg="4">
          <img style={{ height: "50px" }} src={userIcon} />
          <div className={style.titlePendidikan} style={{ marginTop: "20px" }}>
            TOTAL KUNJUNGAN
          </div>
          {props.loading ? (
            <div className="my-3">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className={style.valueFingerprint}>
              {" "}
              {(props.data ? props.data.visit ?? 0 : 0).toLocaleString()}
            </div>
          )}
        </Col>
      </Row>
      <Row className="text-center my-3 text-dark mt-5">
        <Col md="12" lg="6" className="justify-content-center">
          <img style={{ height: "50px" }} src={webIcon} />
          <div className={style.titlePendidikan} style={{ marginTop: "20px" }}>
            TOTAL BROWSER
          </div>
          {props.loading ? (
            <div className="my-3">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className={style.valueFingerprint}>
              {" "}
              {(props.data ? props.data.browser ?? 0 : 0).toLocaleString()}
            </div>
          )}
        </Col>
        <Col md="12" lg="6" style={{ borderLeft: "1px solid #C0C0C0" }}>
          <img style={{ height: "50px" }} src={osIcon} />
          <div className={style.titlePendidikan} style={{ marginTop: "20px" }}>
            TOTAL OS
          </div>
          {props.loading ? (
            <div className="my-3">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className={style.valueFingerprint}>
              {" "}
              {(props.data ? props.data.os ?? 0 : 0).toLocaleString()}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
