import React, { useState } from "react";
import { Row, CardBody, Button, Col, Card, Modal, ModalBody } from "reactstrap";
import { MDBModalHeader, MDBNavLink } from "mdbreact";
import moment from "moment";

import style from "./style.module.css";

import FilterPeakHour from "./FilterPeakHour";
import Chart from "./ChartPeakHour";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "./Download/XLS/DownloadCardPeakHour";

export default function CardTrafikProvinsi(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [prov, setProv] = useState("");
  const [org, setOrg] = useState("");
  const [content, setContent] = useState("");

  // MODAL DOWNLOAD
  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);
  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };
  return (
    <div>
      <Row style={{ minHeight: "500px" }}>
        <Col md="12">
          <Card body style={{ boxShadow: "none" }}>
            <div className="p-3">
              <Row>
                <div className={`${style.titleCardBI}`}>PEAK HOUR</div>
              </Row>
              <div>
                <Modal
                  size="md"
                  isOpen={modalDownloadXLS}
                  toggle={toggleDownloadXLS}
                  centered={true}>
                  <MDBModalHeader toggle={toggleDownloadXLS}>
                    <span className={style.headText}>Download XLS</span>
                  </MDBModalHeader>
                  <ModalBody>
                    <center className="container-popup">
                      <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                      <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                        Batal
                      </Button>
                      <Button
                        color=""
                        className={style.btn_confirm_xls}
                        onClick={toggleDownloadXLS}>
                        <ReactHTMLTableToExcel
                          id="TableXLS"
                          className={style.DownloadTableXLS}
                          table="DownloadTableXLSPeakHour"
                          filename={`summary-peak-hour ${moment(new Date()).format("DD-MM-YYYY")}`}
                          sheet="users"
                          buttonText="Download"
                        />
                      </Button>
                    </center>
                  </ModalBody>
                </Modal>
                {loading ? (
                  ""
                ) : (
                  <Col
                    className="my-1"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}>
                    <MDBNavLink to="#" className={style.textDownload}>
                      Download
                    </MDBNavLink>
                    <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                      XLS
                    </MDBNavLink>
                  </Col>
                )}
                <DownloadXLS
                  id="DownloadTableXLSPeakHour"
                  className="DownloadTableXLS"
                  data={data}
                  content={content}
                  org={org}
                  prov={prov}
                />
              </div>
              <div className="mb-5">
                <FilterPeakHour
                  setData={setData}
                  dataSave={props.dataSave}
                  setLoading={setLoading}
                  setDataSave={props.setDataSave}
                  dataSave={props.dataSave}
                  setProv={setProv}
                  setContent={setContent}
                  setOrg={setOrg}
                />
              </div>
              <Chart data={data} loading={loading} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
