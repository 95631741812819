import React from "react";

import { Modal, ModalBody, Button, Row } from "reactstrap";
import styles from "./styles.module.css";
import { MDBModalHeader } from "mdbreact";

function PopupNonAktif({ toggleNonAktif, modalNonAktif, data, deleteAction, handleChangeAlasan }) {
  return (
    <>
      <Modal size="lg" isOpen={modalNonAktif} toggle={toggleNonAktif} centered={true}>
        <MDBModalHeader toggle={toggleNonAktif}>
          <span className={styles.headText}> Alasan Non Aktif Pengguna </span>
        </MDBModalHeader>
        <ModalBody>
          <center className="container-popup">
            <Row className={`justify-content-center ${styles.wrapper}`}>
              <h4 className={styles.title}>{data.username}</h4>
              {/* <h5 className={styles.sub_title}>{data.type}</h5> */}
            </Row>

            <div className={styles.text}>Anda yakin ingin Non Aktifkan pengguna ini?</div>
            <textarea
              name="alasan_tolak"
              className={styles.input_alasan}
              onChange={handleChangeAlasan}
              placeholder="Tulis Alasan Penolakan..."></textarea>
            {/* <Button color="" className={styles.btn_cancel} onClick={toggleVerify}>Tidak</Button> */}
            <Button color="" className={styles.btn_confirm} onClick={deleteAction}>
              Non Aktifkan
            </Button>
          </center>
        </ModalBody>
      </Modal>
    </>
  );
}

export default PopupNonAktif;
