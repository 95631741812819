import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "./style.css";
import DownWhite from "../../../assets/images/backwhite.png";
// -------------------COMPONENT--------------------
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Header from "../../../components/VerticalLayout/Header";
import TableKontenSAVideo from "../../../components/SuperAdmin/Tables/Konten/Video";
import TableKontenSABanner from "../../../components/SuperAdmin/Tables/Konten/Banner";
import TableKontenSABerita from "../../../components/SuperAdmin/Tables/Konten/Berita";

import TableKontenSAVideoTidakLolos from "../../../components/SuperAdmin/Tables/Konten/TidakLolos/Video";
import TableKontenSABannerTidakLolos from "../../../components/SuperAdmin/Tables/Konten/TidakLolos/Banner";
import TableKontenSABeritaTidakLolos from "../../../components/SuperAdmin/Tables/Konten/TidakLolos/Berita";

import { exportPDF } from "../../../components/SuperAdmin/Download/Konten/PDF/DownloadPDF";
import DownloadXLS from "../../../components/SuperAdmin/Download/Konten/XLS/DownloadXLS";

// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function KontenSuperAdmin(props) {
  let role = window.localStorage.getItem("role");
  const [title, setTitle] = useState("");
  //handle data xls
  const [data, setData] = useState([]);
  //handle data when change tab
  const handleDataVideo = (vid) => {
    setData(vid);
  };

  const [konten, setKonten] = useState("scheduled");

  const [categoryContent, setCategoryContent] = useState("0");

  const handleDataBanner = (banner) => {
    setData(banner);
  };

  const handleDataBerita = (berita) => {
    setData(berita);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const [activeSubTab1, setActiveSubTab1] = useState("1");
  const [activeSubTab2, setActiveSubTab2] = useState("1");
  const [activeSubTab3, setActiveSubTab3] = useState("1");

  const [idContent, setIdContent] = useState("");

  //handle download pdf
  const handleDownloadPDF = () => {
    exportPDF(data, activeTab, activeSubTab1, activeSubTab2, activeSubTab3, title);
  };

  const tabStyle = {
    color: "#c42127",
  };
  const subTabStyle = {
    color: "#c42127",
    // borderBottom: "1px solid #c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };

  useEffect(() => {
    setTitle(
      activeTab === "1" && activeSubTab1 === "1"
        ? "Konten_Video_Disetujui"
        : activeTab === "1" && activeSubTab1 === "2"
        ? "Konten_Banner_Disetujui"
        : activeTab === "1" && activeSubTab1 === "3"
        ? "Konten_Berita_Disetujui"
        : activeTab === "2" && activeSubTab2 === "1"
        ? "Konten_Video_Tidak_Disetujui"
        : activeTab === "2" && activeSubTab2 === "2"
        ? "Konten_Banner_Tidak_Disetujui"
        : activeTab === "2" && activeSubTab2 === "3"
        ? "Konten_Berita_Tidak_Disetujui"
        : ""
    );
  }, [activeSubTab1, activeSubTab2, activeTab]);

  useEffect(() => {
    let notifContentType = props.dataNotif.data;
    if (notifContentType !== "empty") {
      switch (notifContentType.data.content_type) {
        case "video":
          if (notifContentType.data.notif_type === "tidak lolos kurasi") {
            setActiveTab("3");
            setActiveSubTab1("1");
          } else if (notifContentType.data.notif_type === "disetujui") {
            setActiveTab("1");
            setActiveSubTab1("1");
          } else if (notifContentType.data.notif_type === "declined") {
            setActiveTab("2");
            setActiveSubTab1("1");
          }
        case "banner":
          if (notifContentType.data.notif_type === "tidak lolos kurasi") {
            setActiveTab("3");
            setActiveSubTab1("1");
          } else if (notifContentType.data.notif_type === "disetujui") {
            setActiveTab("1");
            setActiveSubTab1("1");
          } else if (notifContentType.data.notif_type === "declined") {
            setActiveTab("2");
            setActiveSubTab1("1");
          }
        case "news":
          if (notifContentType.data.notif_type === "tidak lolos kurasi") {
            setActiveTab("3");
            setActiveSubTab1("1");
          } else if (notifContentType.data.notif_type === "disetujui") {
            setActiveTab("1");
            setActiveSubTab1("1");
          } else if (notifContentType.data.notif_type === "declined") {
            setActiveTab("2");
            setActiveSubTab1("1");
          }
        default:
          setActiveTab("1");
          setActiveSubTab1("1");
          break;
      }
    } else {
      console.log("else");
    }
    setIdContent(
      notifContentType
        ? notifContentType.data
          ? notifContentType.data.content
            ? notifContentType.data.content._id
              ? notifContentType.data.content._id
              : "-"
            : "-"
          : "-"
        : "-"
    );
  }, [props.dataNotif.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="Konten" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Table" /> */}
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12">
                    <MDBNav classicTabs className="wrapperNav">
                      <div className="wrapperTab">
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "1" ? tabStyle : {}}
                            className="tabMenu waves-light"
                            active={activeTab === "1"}
                            onClick={() => {
                              setActiveTab("1");
                              setKonten("scheduled");
                            }}>
                            DISETUJUI
                          </MDBNavLink>
                          <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "2" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "2"}
                            onClick={() => {
                              setActiveTab("2");
                              setKonten("declined");
                            }}>
                            TIDAK DISETUJUI
                          </MDBNavLink>
                          <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "3" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "3"}
                            onClick={() => {
                              setActiveTab("3");
                              setKonten("declined");
                            }}>
                            TIDAK LOLOS KURASI
                          </MDBNavLink>
                          <hr style={activeTab === "3" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                      </div>

                      {/* <div className="wrapperAction">
                        <MDBNavLink to="#" className="textDownload">
                          Download
                        </MDBNavLink>
                        <ReactHTMLTableToExcel
                          id="TableXLS"
                          className="DownloadTableXLS"
                          table="DownloadTableXLS"
                          filename={`Data_${title}_${role}`}
                          sheet="users"
                          buttonText="XLS"
                        />
                        <MDBNavLink onClick={handleDownloadPDF} link to="#">
                          PDF
                        </MDBNavLink>
                      </div>
                      <DownloadXLS
                        id="DownloadTableXLS"
                        className="DownloadTableXLS"
                        data={data}
                        activeTab={activeTab}
                        title={title}
                        activeSubTab1={activeSubTab1}
                        activeSubTab2={activeSubTab2}
                        activeSubTab3={activeSubTab3}
                      /> */}
                      <div
                        className="wrapperDropdownResponsive"
                        style={{
                          width: "25%",
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "0.75%",
                        }}>
                        <MDBDropdown
                          style={{ width: "100% !important" }}
                          className="dropdown-download-doc">
                          <MDBDropdownToggle
                            caret
                            color="primary"
                            className="dropdown-download font-weight-bold">
                            <div className="wrapper-selected">Download</div>
                            <div>
                              <img src={DownWhite} alt="dropdown-icons" />
                            </div>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu className="dropdown-menus dropdown-show">
                            <MDBDropdownItem
                              style={{ color: "#556ee6" }}
                              className="text-center"
                              onClick={handleDownloadPDF}>
                              PDF
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                              <ReactHTMLTableToExcel
                                id="TableXLS"
                                className="DownloadTableXLSDropdown"
                                table="DownloadTableXLS"
                                filename={`Data_${title}_${role}`}
                                sheet="users"
                                buttonText="XLS"
                              />
                            </MDBDropdownItem>
                            <DownloadXLS
                              id="DownloadTableXLS"
                              className="DownloadTableXLS"
                              data={data}
                              activeTab={activeTab}
                              title={title}
                              activeSubTab1={activeSubTab1}
                              activeSubTab2={activeSubTab2}
                              activeSubTab3={activeSubTab3}
                            />
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                    </MDBNav>

                    <MDBTabContent className="card" activeItem={activeTab}>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "1" && (
                          <Col className="col-12">
                            {/* <div className="tabDescription">
                              Merupakan kumpulan library konten yang sudah selesai jadwal
                              penayangannya
                            </div> */}
                            <MDBNav classicTabs className="wrapperNav">
                              <div className="wrapperTab">
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab1 === "1" ? subTabStyle : {}}
                                    className="tabMenu waves-light"
                                    active={activeSubTab1 === "1"}
                                    onClick={() => setActiveSubTab1("1")}>
                                    VIDEO
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab1 === "2" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab1 === "2"}
                                    onClick={() => setActiveSubTab1("2")}>
                                    BANNER
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab1 === "3" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab1 === "3"}
                                    onClick={() => setActiveSubTab1("3")}>
                                    BERITA
                                  </MDBNavLink>
                                </MDBNavItem>
                              </div>
                            </MDBNav>

                            <MDBTabContent className="card" activeItem={activeSubTab1}>
                              <MDBTabPane tabId={activeSubTab1}>
                                {activeSubTab1 === "1" && (
                                  <TableKontenSAVideo
                                    idContent={idContent}
                                    handleData={handleDataVideo}
                                    activeTab={activeSubTab1}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                                {activeSubTab1 === "2" && (
                                  <TableKontenSABanner
                                    idContent={idContent}
                                    handleData={handleDataBanner}
                                    activeTab={activeSubTab1}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                                {activeSubTab1 === "3" && (
                                  <TableKontenSABerita
                                    idContent={idContent}
                                    handleData={handleDataBerita}
                                    activeTab={activeSubTab1}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                              </MDBTabPane>
                            </MDBTabContent>
                          </Col>
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "2" && (
                          <Col className="col-12">
                            {/* <div className="tabDescription">
                              Merupakan kumpulan konten yang belum berkesempatan tayang di Landing
                              Page dikarenakan ditolak kurator maupun Super Admin
                            </div> */}
                            <MDBNav classicTabs className="wrapperNav">
                              <div className="wrapperTab">
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab2 === "1" ? subTabStyle : {}}
                                    className="tabMenu waves-light"
                                    active={activeSubTab2 === "1"}
                                    onClick={() => setActiveSubTab2("1")}>
                                    VIDEO
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab2 === "2" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab2 === "2"}
                                    onClick={() => setActiveSubTab2("2")}>
                                    BANNER
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab2 === "3" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab2 === "3"}
                                    onClick={() => setActiveSubTab2("3")}>
                                    BERITA
                                  </MDBNavLink>
                                </MDBNavItem>
                              </div>
                            </MDBNav>

                            <MDBTabContent className="card" activeItem={activeSubTab2}>
                              <MDBTabPane tabId={activeSubTab2}>
                                {activeSubTab2 === "1" && (
                                  <TableKontenSAVideo
                                    idContent={idContent}
                                    handleData={handleDataVideo}
                                    activeTab={activeSubTab2}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                                {activeSubTab2 === "2" && (
                                  <TableKontenSABanner
                                    idContent={idContent}
                                    handleData={handleDataBanner}
                                    activeTab={activeSubTab2}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                                {activeSubTab2 === "3" && (
                                  <TableKontenSABerita
                                    idContent={idContent}
                                    handleData={handleDataBerita}
                                    activeTab={activeSubTab2}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                              </MDBTabPane>
                            </MDBTabContent>
                          </Col>
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "3" && (
                          <Col className="col-12">
                            <MDBNav classicTabs className="wrapperNav">
                              <div className="wrapperTab">
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab3 === "1" ? subTabStyle : {}}
                                    className="tabMenu waves-light"
                                    active={activeSubTab3 === "1"}
                                    onClick={() => setActiveSubTab3("1")}>
                                    VIDEO
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab3 === "2" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab3 === "2"}
                                    onClick={() => setActiveSubTab3("2")}>
                                    BANNER
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab3 === "3" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab3 === "3"}
                                    onClick={() => setActiveSubTab3("3")}>
                                    BERITA
                                  </MDBNavLink>
                                </MDBNavItem>
                              </div>
                            </MDBNav>

                            <MDBTabContent className="card" activeItem={activeSubTab3}>
                              <MDBTabPane tabId={activeSubTab3}>
                                {activeSubTab3 === "1" && (
                                  <TableKontenSAVideoTidakLolos
                                    idContent={idContent}
                                    handleData={handleDataVideo}
                                    activeTab={activeSubTab3}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                                {activeSubTab3 === "2" && (
                                  <TableKontenSABannerTidakLolos
                                    idContent={idContent}
                                    handleData={handleDataVideo}
                                    activeTab={activeSubTab3}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                                {activeSubTab3 === "3" && (
                                  <TableKontenSABeritaTidakLolos
                                    idContent={idContent}
                                    handleData={handleDataVideo}
                                    activeTab={activeSubTab3}
                                    categoryContent={categoryContent}
                                    konten={konten}
                                  />
                                )}
                              </MDBTabPane>
                            </MDBTabContent>
                          </Col>
                        )}
                      </MDBTabPane>
                    </MDBTabContent>
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(KontenSuperAdmin));
