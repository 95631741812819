import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormGroup,
  FormText,
  Button,
} from "reactstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SweetAlert from "react-bootstrap-sweetalert";
import IconGreenCheckList from "../../../assets/icon/IconGreenChecklist.svg";
import API from "../../../services";
import { Link } from "react-router-dom";

export default function CardCreateArticle(props) {
  const [valueSinopsis, setValueSinopsis] = useState("");
  const [modal, setModal] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);
  const [title, setTitle] = useState("");
  const [imgArticle, setImgArticle] = useState("");

  const [typeVideo, setTypeVideo] = useState("local");
  const [link, setLink] = useState("");
  const [videoFile, setVideoFile] = useState("");

  //const users = window.localStorage.getItem("role");
  const users = window.localStorage.getItem("users");
  const Idusers = window.localStorage.getItem("idUser");

  const [errorT, setErrorT] = useState(false);
  const [errorI, setErrorI] = useState(false);
  const [errorIM, setErrorIM] = useState("Terjadi Kesalahan");
  const [errorV, setErrorV] = useState(false);
  const [errorVideo, setErrorVideo] = useState(false);
  const [errorVideoMessage, setErrorVideoMessage] = useState("Terjadi Kesalahan");

  const handleFormTitleArticle = (e) => {
    let { value } = e.target;
    setTitle(value);
    setErrorT(false);
  };

  const handleFormImgArticle = (e) => {
    let value = e.target.files[0];
    let maxfilesize = 512 * 1024; //500 KB
    let filesize = value?.size || 0;
    if (filesize > maxfilesize) {
      setImgArticle("");
      setErrorI(true);
      setErrorIM("Besar file gambar melebihi batas maksimal");
    } else {
      setImgArticle(value);
      setErrorI(false);
    }
  };

  const handleFormVideo = (e) => {
    let value = e.target.files[0];
    let maxfilesize = 30 * 1024 * 1024; //100 MB
    let filesize = value?.size || 0;
    if (filesize > maxfilesize) {
      setVideoFile("");
      setErrorI(true);
      setErrorIM("Besar file Video melebihi batas maksimal");
    } else {
      setVideoFile(value);
      setErrorI(false);
    }
  };

  const handleOnChange = (e, editor) => {
    const data = editor.getData();
    setValueSinopsis(data);
    setErrorV(false);
  };

  const handleCloseAlert = () => {
    setTimeout(() => {
      setModal(false);
    }, 1500);
  };

  const handleCloseAlertFailed = () => {
    setTimeout(() => {
      setModalFailed(false);
    }, 1500);
  };

  const handleCreateArticel = () => {
    var submit = false;

    if (typeContent !== "Video") {
      if (title === "") {
        setErrorT(true);
      } else if (typeof imgArticle !== "object") {
        setErrorI(true);
        setErrorIM("Thumbnail Gambar Artikel tidak boleh kosong");
      } else if (valueSinopsis === "") {
        setErrorV(true);
      } else {
        submit = true;
      }
    } else if (typeContent === "Video") {
      if (title === "") {
        setErrorT(true);
      } else if (typeof imgArticle !== "object") {
        setErrorI(true);
        setErrorIM("Thumbnail Gambar Artikel tidak boleh kosong");
      } else if (valueSinopsis === "") {
        setErrorV(true);
      } else {
        if (typeVideo === "local") {
          if (typeof videoFile !== "object") {
            setErrorVideo(true);
            setErrorVideoMessage("Video tidak boleh kosong");
          } else {
            submit = true;
          }
        } else {
          if (!link) {
            setErrorVideo(true);
            setErrorVideoMessage("Link tidak boleh kosong");
          } else {
            submit = true;
          }
        }
      }
    }

    if (submit) {
      setErrorT(false);
      setErrorI(false);
      setErrorV(false);

      if (link) {
        var video_id = ytv(link);
      }

      const bodyFormData = new FormData();

      bodyFormData.append("username", users);
      if (typeContent === "Video") {
        bodyFormData.append("thumbnail", imgArticle);
        if (typeVideo === "local") {
          bodyFormData.append("file", videoFile);
        } else {
          bodyFormData.append("link", video_id);
        }
      } else {
        bodyFormData.append("file", imgArticle);
      }

      bodyFormData.append("userId", Idusers);
      bodyFormData.append("body", valueSinopsis);
      bodyFormData.append("judul", title);

      if (typeContent === "Video") {
        bodyFormData.append("tipeVideo", typeVideo);
      }

      if (typeContent !== "Video") {
        API.postCreateArticle(bodyFormData)
          .then((response) => {
            setModal(!modal);
            handleCloseAlert();
            window.location.href = "/admin/artikel";
          })
          .catch((e) => {
            return e;
          });
      } else if (typeContent === "Video") {
        API.postCreateVideo(bodyFormData)
          .then((response) => {
            setModal(!modal);
            handleCloseAlert();
            window.location.href = "/adminartikel/video";
          })
          .catch((e) => {
            return e;
          });
      }
    }
  };

  const handleChangeVideoType = (type) => {
    setTypeVideo(type);
  };

  const handleChangeLink = (link) => {
    setLink(link);
  };

  const ytv = (link) => {
    var video_id = link.split("v=")[1];
    var ampersandPosition = video_id.indexOf("&");
    if (ampersandPosition != -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }

    return video_id;
  };

  let ckeditorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",

        "superscript",
        "subscript",
        "|",
        "link",

        "|",
        "bulletedList",
        "numberedList",
        "|",
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "",
  };

  var { typeContent } = props;

  typeContent = typeContent
    ? typeContent.charAt(0).toUpperCase() + typeContent.slice(1)
    : "Artikel";

  return (
    <div>
      <Container>
        <Card>
          <CardBody>
            <FormGroup>
              <Label for="titleArticle">Judul {typeContent}</Label>
              <Input
                type="text"
                name={`title${typeContent}`}
                onChange={handleFormTitleArticle}
                id={`title${typeContent}`}
                placeholder={`Tulis Judul ${typeContent}`}
              />
              {errorT ? (
                <div style={{ color: "red", fontSize: "12px", paddingTop: "5px" }}>
                  Judul {`${typeContent}`} tidak boleh kosong
                </div>
              ) : (
                <div></div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="thumbnailImg">Thumbnail Gambar {`${typeContent}`}</Label>
              <Input
                type="file"
                name="thumbnailImg"
                onChange={handleFormImgArticle}
                id="thumbnailImg"
                accept="image/png,image/jpg,image/jpeg"
              />
              <FormText color="muted">Format JPG, JPEG, atau PNG maksimal size 500KB</FormText>
              {errorI ? (
                <div style={{ color: "red", fontSize: "12px", paddingTop: "5px" }}>{errorIM}</div>
              ) : (
                <div></div>
              )}
            </FormGroup>
            {typeContent === "Video" ? (
              <FormGroup>
                <Label for=""> Type Video </Label>
                <Input
                  type="select"
                  className="col-md-4"
                  value={typeVideo}
                  onChange={(event) => handleChangeVideoType(event.target.value)}>
                  <option value="local"> Local </option>
                  <option value="youtube"> Youtube </option>
                </Input>
              </FormGroup>
            ) : (
              ""
            )}

            {typeContent === "Video" ? (
              <FormGroup>
                <Label>Video {typeVideo === "youtube" ? "Link" : "File"}</Label>
                {typeVideo === "local" ? (
                  <Input
                    type="file"
                    name="file"
                    id="file"
                    onChange={handleFormVideo}
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                ) : (
                  ""
                )}
                {typeVideo === "youtube" ? (
                  <Input
                    type="text"
                    name="link"
                    id="link"
                    onChange={(event) => handleChangeLink(event.target.value)}
                  />
                ) : (
                  ""
                )}

                {errorVideo ? (
                  <div style={{ color: "red", fontSize: "12px", paddingTop: "5px" }}>
                    {errorVideoMessage}
                  </div>
                ) : (
                  <div></div>
                )}
              </FormGroup>
            ) : (
              ""
            )}

            <FormGroup>
              <Label> Description </Label>
              <CKEditor editor={ClassicEditor} onChange={handleOnChange} config={ckeditorConfig} />
              {errorV ? (
                <div style={{ color: "red", fontSize: "12px", paddingTop: "5px" }}>
                  Isi deskripsi tidak boleh kosong
                </div>
              ) : (
                <div></div>
              )}
            </FormGroup>
            <FormGroup row className="justify-content-center">
              <Button style={{ backgroundColor: "#2C3280" }} onClick={() => handleCreateArticel()}>
                Buat {`${typeContent}`}
              </Button>
            </FormGroup>
          </CardBody>
        </Card>
        <SweetAlert show={modal} showConfirm={false} title="">
          <img src={IconGreenCheckList} alt="checklist" />
          <h4>Buat {`${typeContent}`} Berhasil</h4>
        </SweetAlert>
        <SweetAlert danger show={modalFailed} showConfirm={false} title="Edit Gagal!"></SweetAlert>
      </Container>
    </div>
  );
}
