import Get from "./Get";
import Post from "./Post";
import Put from "./Put";
import Delete from "./Delete";

// GET
// NOTE : Gunakan true or false, kalau true dia kirim paramas ke url,
// kalau false tidak kirim params
const getHome = (category) => Get("content/kuratorhome", category, true);
const getKontenKuratorVideo = (category) =>
  Get(`content/getVideosByCategoryStatus/${category}/Belum%20Kurasi`, "", false);
const getKontenKuratorBanner = (category) =>
  Get(`content/getBannerByCategoryStatus/${category}/Belum%20Kurasi`, "", false);
const getKontenKuratorBerita = (category) =>
  Get(`content/getNewsByCategoryStatus/${category}/Belum%20Kurasi`, "", false);
const getKontenTwitter = () => Get(`article/hit/twitter`, "", false);
const getReviewKonten = (category) => Get("content/getContentByCategoryStatus", category, true);
const getReviewKontenRevisi = (category) =>
  Get("content/getContentRevisiByCategory", category, true);
const getCPNews = (idUser) => Get("content/newskontenpreview", idUser, true);
const getCPVideo = (idUser) => Get("content/videokontenpreview", idUser, true);
const getCPBanner = (idUser) => Get("content/bannerkontenpreview", idUser, true);

//content partner
const getKontenRevisiVideo = (idUser) => Get("content/getvideoRevisiByUser", idUser, true);
const getKontenRevisiBanner = (idUser) => Get("content/getbannerRevisiByUser", idUser, true);
const getKontenRevisiNews = (idUser) => Get("content/getnewsRevisiByUser", idUser, true);
const getHomeContentPartner = (idUser) => Get("content/cphome", idUser, true);
const getAllProvince = () => Get(`provinsi/all`, "", false);

//superadmin

const getPersetujuanKontenVideo = () => Get("content/getpersetujuan/video", "", false);
const getPersetujuanKontenBanner = () => Get("content/getpersetujuan/banner", "", false);
const getPersetujuanKontenNews = () => Get("content/getpersetujuan/news", "", false);
const getTableRegisterAdmin = () => Get("user/admin/all", "", false);

const getCheckToken = (token) => Get("user/password/reset", token, true);
//admin
const getUserRequested = () => Get("user/requested", "", false);
const getUserByRole = (role) => Get(`user/${role}/all`, "", false);

// HOME ADMIN
const getHomeAdmin = () => Get(`content/adminhome`, "", false);
const getHitArticle = () => Get("article/socialmedia", "", false);
const getDashoardArticle = () => Get("article/dashboard", "", false);
const getCountNews = () => Get("newsletter/count", "", false);
const getTableArticel = () => Get("article/getall", "", false);
const getTableNewsLetter = () => Get("newsletter/getall", "", false);
const getKontenDefault = (type) => Get(`content/${type}/default`, "", false);
const getKontenAdmin = (type) => Get(`content/${type}/admin`, "", false);
const getLocationStatistic = () => Get(`statistics/ip/location/all`, "", false);

//KONTEN DEFAULT
const getKontenDefaultAktif = (data) => Post(`content/default/active`, data, true);
const getKontenDefaultAll = (data) => Post(`content/default/group/title`, data, true);
const getKontenDefaultTidakAktif = (data) => Post(`content/default/nonactive/province`, data, true);
const getKontenDefaultActivate = (data, type) =>
  Post(`content/${type}/default/activate`, data, true);

// KONTEN REPORT
const getKontenISPProvince = (data) => Post("statistics/isp/province", data, true);
const getKontenReport = (data) => Post("statistics/content/report", data, true);
const getKontenReportDetail = (data) => Post("statistics/content/report/detail", data, true);

///////////////////////////////////////////////////////////////////////////////
// BI /////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
const getProvince = () => Get("location/province", "", false);
const getKab = (data) => Post("location/kabkota", data, true);
const getKec = (data) => Post("location/kecamatan", data, true);
const getDesa = (data) => Post("location/desa", data, true);

const getTableKontenBanner = () => Get(`content/banner`, "", false);
const getTableKontenNews = () => Get(`content/news`, "", false);
const getTableKontenVideo = () => Get(`content/video`, "", false);

// PERSEBARAN PENGUNJUNG
const getBIVisitor = (data) => Post("bi/visitor", data, true);

// DEMOGRAPHY
const getBIDemografi = (data) => Post("bi/demography", data, true);
const getBIDemografiDetail = (data) => Post("bi/demography/detail", data, true);
const getBIDemografiTopics = (data) => Post("bi/demography/topics", data, true);

// ISP
const getBIISP = () => Get(`bi/isp/dashboard`, "", false);
const getBIISPData = (data) => Post(`bi/isp/data`, data, true);

// DFP
const getBIDFP = () => Get(`bi/dfp`, "", false);

// TRAFFIC KONTEN
const getListOrg = () => Get(`bi/traffic/org/all`, "", false);
const getBITrafficKontenOrg = (data) => Post("bi/traffic/content/org", data, true);
const getBITrafficDetailImpresKlik = (data, type) => Post(`content/${type}/cp/detail`, data, true);
const getBITrafficKontenKategori = (data) => Post(`bi/traffic/content/category`, data, true);
const getBITrafficKontenDurasi = (data) => Post(`bi/traffic/content/duration`, data, true);
const getBITrafficKontenTime = (data) => Post(`bi/traffic/content/time`, data, true);
const getBITrafficKontenKategTime = (data) => Post(`bi/traffic/content/category/time`, data, true);
const getBITrafficKontenDemografi = (data) => Post(`bi/traffic/content/demography`, data, true);
const getBITrafficKontenLocation = (data) => Post(`bi/traffic/content/location`, data, true);

// SURVEY
const getDashboardSurvey = () => Get(`bi/survey`, "", false);
const getFilterSurvey = () => Get(`bi/survey/all`, "", false);
const getDataStatistikSurvey = (data) => Post(`bi/survey/question`, data, true);
const getDataStatistikSurveyDemography = (data) =>
  Post(`bi/survey/question/demography`, data, true);

const getDataDemografiSurveyDetail = (data) => Post(`bi/survey/detail`, data, true);

// USER BEHAVIOUR
const getDataUserBehaviour = (data) => Post(`bi/userbehaviour`, data, true);

// LOG HISTORY
const getDataLogHistory = (data) => Post(`bi/history/get`, data, true);
const postSaveLogHistory = (data) => Post(`bi/history/save`, data, true);
const deleteSaveLogHistory = (data) => Post(`bi/history/delete`, data, true);

//SUMMARY TRAFIK KOTEN
const getSummaryKonten = () => Get(`bi/summary/traffic`, "", false);
const getSummaryProvince = (data) => Post(`bi/summary/traffic/province`, data, true);
const getSummaryPeak = (data) => Post(`bi/summary/traffic/peak`, data, true);
const getSummaryDemografi = () => Get(`bi/summary/traffic/demography`, "", false);

//////////////////////////////////////////////////////////////////////////////////////////////////////////

// POST
const postLogin = (data) => Post("user/loginAdmin", data);
const postResetPass = (data) => Post("user/password/reset", data);
const postKontenLolosKurasi = (path, data) => Post(path, data);
const postKontenTidakLolosKurasi = (path, data) => Post(path, data);
const postKontenRevisiKurasi = (path, data) => Post(path, data);
const postKontenVideoCP = (data) => Post("Content/video/cp", data);
const postKontenBannerCP = (data) => Post("Content/banner/cp", data);
const postKontenBeritaCP = (data) => Post("Content/news/cp", data);
const postCreateContentCP = (data) => Post("content/create", data);
const postCheckTimeAvailable = (data) => Post("content/schedule/check", data);
const postProvinceAvailable = (data) => Post("content/schedule/province/check", data);
const postCreateContentEmergencyCP = (data) => Post("content/create/emergency", data);
const postDeleteContent = (data) => Post("content/deleteContent", data);
const postForgotPass = (data) => Post("user/password/reset", data);
const postChangeStatusArticle = (data) => Post("article/changestatus", data);
const deleteArticel = (data) => Post("article/delete", data);
const deleteNewsletter = (data) => Post("newsletter/delete", data);
const checkUser = (data) => Post("user/check", data);
const postReplyTicket = (data) => Post(`ticketing/replyTicket`, data); // reply ticket
const postGetTicket = (data) => Post(`ticketing/reply/last/support`, data); // get last email reply

// BANNER LANDING PAGE
const bannerSubmit = (data) => Post("bannerText/create", data);

// POST UPDATE PROFILE
const postChangeAvatar = (data) => Post("user/profile/picture", data);

//SuperAdmin
const postRegistrasiAdmin = (data) => Post("user/createadmin", data);
const postAktifAdmin = (data) => Post("user/aktif", data);
const postNonAktifAdmin = (data) => Post("user/nonaktif", data);
const postDeleteAdmin = (data) => Post("user/delete", data);
const getContentSAVideo = (status) => Get(`content/video/${status}`, "", false);
const getContentSABanner = (status) => Get(`content/banner/${status}`, "", false);
const getContentSABerita = (status) => Get(`content/news/${status}`, "", false);

//admin
const postApproveUser = (data) => Post("user/approve", data);
const postDeleteUser = (data) => Post("user/delete", data);
const postDeclineUser = (data) => Post("user/decline", data);
const postCreateUser = (role, data) => Post(`user/${role}`, data);
const postActivedContent = (type, data) => Post(`content/${type}/default/activate`, data);
const postCreateContentDefault = (data) => Post(`content/create/default`, data);
const postCheckNews = (data) => Post(`content/newsscraping`, data);
const postSendMessage = (data) => Post(`content/sendmessage`, data);
const cancelContent = (data) => Post(`content/cancel`, data);
//survey admin
const getSurveyDefault = () => Get(`survey/default`, "", false);
const postSurveyDefaultDetail = (data) => Post(`survey/default/detail`, data);
const createSurveyDefault = (data) => Post(`survey/default/add`, data);
const changeSurveyStatus = (data) => Post(`survey/default/changestatus`, data);
const postAddSurvey = (data) => Post(`survey/add`, data);
const getSurveyAdmin = () => Get(`survey/getall`, "", false);
const postHasilSurvey = (data) => Post(`survey/detail`, data);
const postBlockDateSurvey = (data) => Post(`content/survey/check`, data);

// HOME ADMIN
const postStatisticsHomeProvince = (data) => Post(`statistics/content/province`, data);

// admin -> Konten
const postConfirmUser = (data) => Post(`user/confirm`, data);

// admin -> Konten Statistik -> detail
const postSiteAnalisisBanner = (data) => Post(`statistics/banner`, data);
const postSiteAnalisisVideo = (data) => Post(`statistics/video`, data);
const postSiteAnalisisNews = (data) => Post(`statistics/news`, data);

//impresi
const postImpressandClickedContentCP = (type, data) => Post(`content/${type}/cp/detail`, data);
//library
const postLibraryKontenVideoCP = (data) => Post("Content/video/cp", data);
const postLibraryKontenBannerCP = (data) => Post("Content/banner/cp", data);
const postLibraryKontenBeritaCP = (data) => Post("Content/news/cp", data);
const postReCreateLibraryCP = (data) => Post("content/recreate", data);
const postUbahJadwalContentCP = (data) => Post("content/ubahjadwal", data);

const postChangePassword = (data) => Post("user/changepassword", data);
const postSiteChecker = (data) => Post("content/searchIp", data);
const postPreviewKontenTayang = (data) => Post("content/preview", data);
const postUpdateKontenRevisi = (data) => Post("content/update", data);

//Site Statistics
const postGetIPAddress = (data) => Post("statistics/ip/location", data);
const postGetISP = (data) => Post("statistics/isp", data);
const postGetSiteStatistics = (data) => Post("statistics/sitestats", data);

//CP Analitics
const getCPAnalitic = () => Get("content/count/cp", "", false);
const postGetCPContent = (data) => Post("content/cp/all", data);

const postCPHomeGetImpressiProv = (data) => Post("content/cphome/getImpressiProv", data);
const postRegister = (data) => Post("user/register", data);

const postEditArticle = (data) => Post("article/edit", data);
const postCreateArticle = (data) => Post("article/create", data);

//Rating konten tayang
const postRatingKonten = (data) => Post("content/createratingcp", data);
//SUPERADMIN
const postDeclinePersetujuanKonten = (data) => Post("content/declineContent", data);
const postApprovePersetujuanKonten = (data) => Post("content/approvecontent", data);

// KONTEN SUPER ADMIN TIDAK LOLOS
const getKontenTidakLolosSA = (type) => Get(`content/${type}/tidaklolos`, "", false);

//Support
// ========> home
const getHomeSupport = () => Get("ticketing/search/stats", "", false);
const getTimeIssueAverage = () => Get("ticketing/search/solved/average", "", false);
const getNewestIssue = () => Get("ticketing/search/latest", "", false);
const getAllTicket = () => Get("ticketing/search", "", false);
// const getAllTicket = () => Get("ticketing/rating/visitor/", "", false);
const getDataBar = () => Get("ticketing/search/count/daily", "", false);
const getRating = () => Get("ticketing/countrating", "", false);
const getAvgRating = () => Get("ticketing/rating/average", "", false);
const getDataSiteAI = (params) => Get("statistics/site-ai", params, true);
const getContentCount = () => Get("content/count/content", "", false);
// ========> surveykepuasan
const getSurveyKepuasanCP = (page, params, bool) =>
  Get(`ticketing/rating/Contentpartner/${page}`, params, bool);
const getSurveyKepuasanVisitor = (page, params, bool) =>
  Get(`ticketing/rating/visitor/${page}`, params, bool);
//=========> ticket
//get
const getNoTiket = () => Get("ticketing/count", "", false);

//post
const postAddTicket = (data) => Post(`ticketing/add`, data);
const postKontenSupport = (type, data) => Post(`content/${type}/all`, data);
const postReplyEmail = (data) => Post(`ticketing/replysupport`, data);
const postKontenTayang = (data) => Post("schedule/kontentayang", data);
const getBehaviourSite = (params) => Get(`statistics/unique`, params, true);
const getUniqueDevice = (params) => Get(`statistics/device`, params, true);
const getVisitHomepage = () => Get("article/visit/homepage", "", false);
const postBulkSC = (data) => Post("content/searchip/bulk", data);
const postReplyTicketDetail = (data) => Post("ticketing/detail", data);
// PUT
const putStatusNotif = (data) => Put("notif/read", data);

// VIDEO
/*
create http://localhost:3500/video/create
get all http://api-staging.devlabs.id/video/getall/1
buat di homepage http://api-staging.devlabs.id/video/homepage
changestatus http://localhost:3500/video/changestatus
delete http://localhost:3500/video/delete/{ID VIDEO}

*/

const postCreateVideo = (data) => Post("video/create", data, true);
const putEditVideo = (data, idVideo) => Put(`video/edit/${idVideo}`, data, true);
const getAllVideo = (page, params) => Get(`video/getall/${page}`, params, true);
const getVideoHomepage = () => Get("video/homepage", "", false);
const changeStatusVideo = (params) => Put("video/changestatus", params, true);
const postDeleteVideo = (idVideo) => Delete(`video/delete/${idVideo}`, "", false);
const getVideoDashboard = () => Get("video/dashboard", "", false);

// Silahkan isi function yg akan digunakan
const API = {
  postLogin,
  getHome,
  getKontenKuratorVideo,
  getKontenKuratorBanner,
  getKontenKuratorBerita,
  getKontenTwitter,
  postKontenTayang,
  postKontenLolosKurasi,
  postKontenTidakLolosKurasi,
  postKontenRevisiKurasi,
  postChangePassword,
  getReviewKonten,
  getReviewKontenRevisi,
  postRegister,
  postForgotPass,
  postResetPass,
  getCheckToken,
  // CONTENT PARTNER
  getHomeContentPartner,
  postKontenVideoCP,
  postKontenBannerCP,
  postKontenBeritaCP,
  getCPNews,
  getCPVideo,
  getCPBanner,
  getKontenRevisiVideo,
  getKontenRevisiBanner,
  getKontenRevisiNews,
  postCPHomeGetImpressiProv,
  postLibraryKontenVideoCP,
  postLibraryKontenBannerCP,
  postLibraryKontenBeritaCP,
  postCreateContentCP,
  postCheckTimeAvailable,
  postProvinceAvailable,
  getAllProvince,
  postImpressandClickedContentCP,
  postPreviewKontenTayang,
  postUpdateKontenRevisi,
  postReCreateLibraryCP,
  postCreateContentEmergencyCP,
  postDeleteContent,
  postUbahJadwalContentCP,
  postRatingKonten,
  cancelContent,

  // BANNER LANDING PAGE
  bannerSubmit,

  // SITE CHECKER
  postSiteChecker,

  //SUPERADMIN
  getContentSAVideo,
  getContentSABanner,
  getContentSABerita,

  //post
  postDeclinePersetujuanKonten,
  postApprovePersetujuanKonten,

  //get
  getPersetujuanKontenVideo,
  getPersetujuanKontenBanner,
  getPersetujuanKontenNews,
  getTableRegisterAdmin,
  getCountNews,
  postRegistrasiAdmin,
  postAktifAdmin,
  postDeleteAdmin,
  postNonAktifAdmin,
  getDashoardArticle,
  getTableArticel,
  getTableNewsLetter,

  //ADMIN\
  postChangeAvatar,
  getSurveyDefault,
  postSurveyDefaultDetail,
  createSurveyDefault,
  changeSurveyStatus,

  //post
  postApproveUser,
  postDeleteUser,
  postDeclineUser,
  postCreateUser,
  postEditArticle,
  postCreateArticle,
  postCheckNews,
  postAddSurvey,
  postHasilSurvey,
  postBlockDateSurvey,
  postSendMessage,
  postReplyTicket,
  postGetTicket,
  //Admin - site Analisis
  postSiteAnalisisBanner,
  postSiteAnalisisVideo,
  postSiteAnalisisNews,
  getTableKontenBanner,
  getTableKontenNews,
  getTableKontenVideo,

  postChangeStatusArticle,
  deleteArticel,
  deleteNewsletter,

  postActivedContent,
  postCreateContentDefault,
  postGetIPAddress,
  postGetISP,
  postGetSiteStatistics,
  postChangeStatusArticle,
  deleteArticel,
  deleteNewsletter,
  getCPAnalitic,
  postGetCPContent,
  postConfirmUser,

  //get
  getUserRequested,
  getUserByRole,
  getKontenDefault,
  getLocationStatistic,

  //KONTEN REPORT
  getKontenISPProvince,
  getKontenReport,
  getKontenReportDetail,

  // BI
  getProvince,
  getKab,
  getKec,
  getDesa,
  getBIVisitor,
  getBIDemografi,
  getBIDemografiDetail,
  getBIDemografiTopics,
  getListOrg,
  getBITrafficKontenOrg,
  getBITrafficDetailImpresKlik,
  getBIISP,
  getBIISPData,
  getBIDFP,
  getBITrafficKontenKategori,
  getBITrafficKontenDurasi,
  getBITrafficKontenTime,
  getBITrafficKontenKategTime,
  getBITrafficKontenDemografi,
  getBITrafficKontenLocation,
  getDashboardSurvey,
  getFilterSurvey,
  getDataStatistikSurvey,
  getDataStatistikSurveyDemography,
  getDataUserBehaviour,
  getDataLogHistory,
  postSaveLogHistory,
  deleteSaveLogHistory,
  getSummaryKonten,
  getSummaryProvince,
  getSummaryPeak,
  getSummaryDemografi,
  getDataDemografiSurveyDetail,

  // KONTEN DEFAULT
  getKontenDefaultAktif,
  getKontenDefaultTidakAktif,
  getKontenDefaultAll,
  getKontenDefaultActivate,

  getKontenAdmin,
  getHomeAdmin,
  postStatisticsHomeProvince,

  // KONTEN SA TIDAK LOLOS
  getKontenTidakLolosSA,

  //SUPPORT
  getHomeSupport,
  getTimeIssueAverage,
  getNewestIssue,
  getDataBar,
  getAllTicket,
  getRating,
  getSurveyKepuasanCP,
  getSurveyKepuasanVisitor,
  getNoTiket,
  getDataSiteAI,
  getContentCount,
  getSurveyAdmin,
  postAddTicket,
  postKontenSupport,
  postReplyEmail,
  getBehaviourSite,
  getUniqueDevice,
  getHitArticle,
  getVisitHomepage,
  checkUser,
  postBulkSC,
  getAvgRating,
  postReplyTicketDetail,
  //put
  putStatusNotif,

  postCreateVideo,
  putEditVideo,
  getAllVideo,
  getVideoHomepage,
  changeStatusVideo,
  postDeleteVideo,
  getVideoDashboard,
};

export default API;
