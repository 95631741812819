import React from "react";
import Header from "../../components/VerticalLayout/Header";
import Banner from "../../components/Admin/BannerLandingPage";
import API from "../../services";

export default function BannerLandingPage(props) {
  // console.info({ props });
  return (
    <div>
      <Header title="Banner landing Page" />
      <Banner />
    </div>
  );
}
