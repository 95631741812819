import React, { useEffect, useState } from "react";
import { Row, Card, Col } from "reactstrap";

import "./CardHome.css";
import video from "../../../../assets/images/video.png";
import banner from "../../../../assets/images/banner.png";
import berita from "../../../../assets/images/berita.png";

export default function CardHome(props) {
  var currentdate = new Date();
  var hours = currentdate.getHours();
  var minutes = currentdate.getMinutes().toString();
  var year = new Date().getFullYear();
  var date = currentdate.getDate();
  var month = currentdate.getMonth() + 1;

  if (minutes.length === 1) {
    minutes = "0" + minutes;
  }

  var strMonth;
  switch (month) {
    case 1:
      strMonth = "JANUARI";
      break;
    case 2:
      strMonth = "FEBRUARI";
      break;
    case 3:
      strMonth = "MARET";
      break;
    case 4:
      strMonth = "APRIL";
      break;
    case 5:
      strMonth = "MEI";
      break;
    case 6:
      strMonth = "JUNI";
      break;
    case 7:
      strMonth = "JULI";
      break;
    case 8:
      strMonth = "AGUSTUS";
      break;
    case 9:
      strMonth = "SEPTEMBER";
      break;
    case 10:
      strMonth = "OKTOBER";
      break;
    case 11:
      strMonth = "NOVEMBER";
      break;
    case 12:
      strMonth = "DESEMBER";
      break;
    default:
      break;
  }

  var tggl = date + " " + strMonth + " " + year;
  var jam = hours + ":" + minutes;

  // console.log(jam);
  // console.log(currentdate);
  return (
    <div className="mx-5">
      <Row className="mt-5 mb-5">
        <Col md="4">
          <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
            <div className="p-3 py-5">
              <Row>
                <div className="ml-2 mr-2">
                  <img src={video} alt="img-title" />
                </div>
                <div className="mt-1">
                  <div className="title-card" style={{ color: "#8CC6C9", textAlign: "start" }}>
                    KONTEN VIDEO
                  </div>
                  <div className="date-card">{tggl}</div>
                  <div className="clock-card">{jam}</div>
                </div>
              </Row>
              <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                <div className=""></div>
                <div className="tit-score">
                  <div className="title-month-card mr-2 ml-2">Total</div>
                  <div className="title-total-card  ml-2">Bulan ini</div>
                </div>
              </Row>
              <Row className="justify-content-between  wrap-total">
                <div className="title-left">Terdaftar</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalRegistered-vid" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[10]
                          ? props.data[10].KontenVideo.terdaftar.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-montlyRegistered-vid" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[10]
                          ? props.data[10].KontenVideo.terdaftarMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between mt-2  wrap-total">
                <div className="title-left">Lolos Kurasi</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalPassed-vid" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[10]
                          ? props.data[10].KontenVideo.LolosKurasi.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-monthlyPassed-vid" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[10]
                          ? props.data[10].KontenVideo.lolosKurasiMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between mt-2  wrap-total">
                <div className="title-left">Disetujui</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalApproved-vid" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[10]
                          ? props.data[10].KontenVideo.Disetujui.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-monthlyApproved-vid" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[10]
                          ? props.data[10].KontenVideo.DisetujuiMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md="4">
          <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
            <div className="p-3 py-5">
              <Row>
                <div className="ml-2 mr-2">
                  <img src={banner} alt="img-title" />
                </div>
                <div className="mt-1">
                  <div className="title-card" style={{ color: "#E2B674", textAlign: "start" }}>
                    KONTEN BANNER
                  </div>
                  <div className="date-card">{tggl}</div>
                  <div className="clock-card">{jam}</div>
                </div>
              </Row>
              <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                <div className=""></div>
                <div className="tit-score">
                  <div className="title-month-card mr-2 ml-2">Total</div>
                  <div className="title-total-card  ml-2">Bulan ini</div>
                </div>
              </Row>
              <Row className="justify-content-between  wrap-total">
                <div className="title-left">Terdaftar</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalRegistered-banner" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[8]
                          ? props.data[8].KontenBanner.terdaftar.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-monthlyRegistered-banner" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[8]
                          ? props.data[8].KontenBanner.terdaftarMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between mt-2  wrap-total">
                <div className="title-left">Lolos Kurasi</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalPassed-banner" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[8]
                          ? props.data[8].KontenBanner.LolosKurasi.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-monthlyPassed-banner" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[8]
                          ? props.data[8].KontenBanner.lolosKurasiMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between mt-2  wrap-total">
                <div className="title-left">Disetujui</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalApproved-banner" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[8]
                          ? props.data[8].KontenBanner.Disetujui.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-monthlyApproved-banner" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[8]
                          ? props.data[8].KontenBanner.DisetujuiMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md="4">
          <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
            <div className="p-3 py-5">
              <Row>
                <div className="ml-2 mr-2">
                  <img src={berita} alt="img-title" />
                </div>
                <div className="mt-1">
                  <div className="title-card" style={{ color: "#C18ECE", textAlign: "start" }}>
                    KONTEN BERITA
                  </div>
                  <div className="date-card">{tggl}</div>
                  <div className="clock-card">{jam}</div>
                </div>
              </Row>
              <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                <div className=""></div>
                <div className="tit-score">
                  <div className="title-month-card mr-2 ml-2">Total</div>
                  <div className="title-total-card  ml-2">Bulan ini</div>
                </div>
              </Row>
              <Row className="justify-content-between wrap-total">
                <div className="title-left">Terdaftar</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalRegistered-news" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[9]
                          ? props.data[9].KontenNews.terdaftar.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-totalRegistered-news" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[9]
                          ? props.data[9].KontenNews.terdaftarMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between mt-2  wrap-total">
                <div className="title-left">Lolos Kurasi</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalPassed-news" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[9]
                          ? props.data[9].KontenNews.LolosKurasi.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-monthlyPassed-news" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[9]
                          ? props.data[9].KontenNews.lolosKurasiMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between mt-2  wrap-total">
                <div className="title-left">Disetujui</div>
                <div className="adminCardVal">
                  <div id="wrapper-totalApproved-news" className="value-monthTotalAdmin">
                    {!props.loading
                      ? props.data
                        ? props.data[9]
                          ? props.data[9].KontenNews.Disetujui.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                  <div id="wrapper-monthlyApproved-news" className="value-total ml-2">
                    {!props.loading
                      ? props.data
                        ? props.data[9]
                          ? props.data[9].KontenNews.DisetujuiMonthly.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
