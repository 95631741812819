import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const KontenTayangAsync = Loadable({
  loader: () =>
    import("../../../pages/KontenTayang/Admin" /* webpackChunkName: "KontenTayangAsync" */),
  loading: Loading,
});

export default KontenTayangAsync;
