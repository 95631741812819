import React, { useEffect, useState } from "react";

import { ColumnChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import "../toastui.scss";
import "tui-chart/dist/tui-chart.css";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";

var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  // title: {
  //     color: '#8791af',
  // },
  xAxis: {
    // title: {
    //     color: '#8791af'
    // },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#49ADAD", "#1E1BB7"],
  },
};

const ColumnChartToast = (props) => {
  const [option, setOption] = useState({});
  useEffect(() => {
    if (props.dataMax) {
      setOption({
        chart: {
          width: props.width,
          height: 380,
          // title: 'Monthly Revenue',
          // format: "1,000",
        },
        yAxis: {
          // title: "Amount",
          min: 0,
          max: props.dataMax ? props.dataMax : 0,
        },
        // xAxis: {
        // 	title: "Month",
        // },
        legend: {
          align: "bottom",
          // visible: false,
          showCheckbox: false,
        },
        chartExportMenu: {
          visible: false, // default is true.
        },
      });
    }
  }, []);

  const data = {
    categories: props.data !== undefined ? Object.keys(props.data) : "",
    series: [
      {
        name: "Jumlah",
        data: props.data !== undefined ? Object.values(props.data) : "",
      },
    ],
  };

  // untuk apply theme
  TuiChart.registerTheme("myTheme", theme);
  option.theme = "myTheme";
  return (
    <React.Fragment>
      {_.size(option) !== 0 ? (
        <ColumnChart data={data} options={option ? option : option} />
      ) : (
        <Skeleton count={10} />
      )}
    </React.Fragment>
  );
};
export default ColumnChartToast;
