import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "./style.css";
// -------------------COMPONENT--------------------
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Header from "../../components/VerticalLayout/Header";
import TablePersetujuanKontenSAVideo from "../../components/SuperAdmin/Tables/PersetujuanKonten/Video";
import TablePersetujuanKontenSABanner from "../../components/SuperAdmin/Tables/PersetujuanKonten/Banner";
import TablePersetujuanKontenSABerita from "../../components/SuperAdmin/Tables/PersetujuanKonten/Berita";

import { exportPDF } from "../../components/SuperAdmin/Download/PersetujuanKonten/PDF/DownloadPDF";
import DownloadXLS from "../../components/SuperAdmin/Download/PersetujuanKonten/XLS/DownloadXLS";
// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function PersetujuanKontenSuperAdmin(props) {
  let role = window.localStorage.getItem("role");
  //handle data xls
  const [data, setData] = useState([]);
  const [idContent, setIdContent] = useState("");
  const [categoryContent, setCategoryContent] = useState("0");

  //handle data when change tab
  const handleDataVideo = (vid) => {
    setData(vid);
  };

  const handleDataBanner = (banner) => {
    setData(banner);
  };

  const handleDataBerita = (berita) => {
    setData(berita);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  //handle download pdf
  const handleDownloadPDF = () => {
    exportPDF(data, activeTab);
  };

  const tabStyle = {
    color: "#c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };
  useEffect(() => {
    let notifContentType = props.dataNotif.data;
    if (notifContentType !== "empty") {
      switch (notifContentType.data.content_type) {
        case "video":
          handleChangeTab("1");
          break;
        case "banner":
          handleChangeTab("2");
          break;
        case "news":
          handleChangeTab("3");
          break;
        default:
          handleChangeTab("1");
          break;
      }
      setIdContent(notifContentType.data.content._id);
      setCategoryContent(notifContentType.data.content.category);
    }
  }, [props.dataNotif.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="Dashboard Admin" className="SuperAdminHeader" />
        </header>
        <main>
          <div className="container-fluid">
            <div>Flat Clicker</div>
            {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Table" /> */}
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12">
                    <MDBNav classicTabs className="wrapperNav">
                      <div className="wrapperTab">
                        {/* <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "1" ? tabStyle : {}}
                            className="tabMenu waves-light"
                            active={activeTab === "1"}
                            onClick={() => handleChangeTab("1")}>
                            VIDEO
                          </MDBNavLink>
                          <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                        </MDBNavItem> */}
                        {/* <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "2" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "2"}
                            onClick={() => handleChangeTab("2")}>
                            BANNER
                          </MDBNavLink>
                          <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                        </MDBNavItem> */}
                        {/* <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "3" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "3"}
                            onClick={() => handleChangeTab("3")}>
                            BERITA
                          </MDBNavLink>
                          <hr style={activeTab === "3" ? customStyle : { display: "none" }} />
                        </MDBNavItem> */}
                      </div>
                      <div className="wrapperAction">
                        <MDBNavLink to="#" className="textDownload">
                          Download
                        </MDBNavLink>
                        <ReactHTMLTableToExcel
                          id="TableXLS"
                          className="DownloadTableXLS"
                          table="DownloadTableXLS"
                          filename={`Conten_Report_${role}`}
                          sheet="users"
                          buttonText="XLS"
                        />
                        <MDBNavLink onClick={handleDownloadPDF} link to="#">
                          PDF
                        </MDBNavLink>
                      </div>
                      <DownloadXLS
                        id="DownloadTableXLS"
                        className="DownloadTableXLS"
                        data={data}
                        activeTab={activeTab}
                      />
                    </MDBNav>

                    <MDBTabContent className="card" activeItem={activeTab}>
                      {/* <MDBTabPane tabId="1">
                        {activeTab === "1" && (
                          <TablePersetujuanKontenSAVideo
                            handleData={handleDataVideo}
                            activeTab={activeTab}
                            idContent={idContent}
                            categoryContent={categoryContent}
                          />
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId="2">
                        {activeTab === "2" && (
                          <TablePersetujuanKontenSABanner
                            handleData={handleDataBanner}
                            activeTab={activeTab}
                            idContent={idContent}
                            categoryContent={categoryContent}
                          />
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId="3">
                        {activeTab === "3" && (
                          <TablePersetujuanKontenSABerita
                            handleData={handleDataBerita}
                            activeTab={activeTab}
                            idContent={idContent}
                            categoryContent={categoryContent}
                          />
                        )}
                      </MDBTabPane> */}
                      <MDBTabPane tabId="1">
                        {activeTab === "1" && (
                          <TablePersetujuanKontenSAVideo
                            handleData={handleDataVideo}
                            activeTab={activeTab}
                            idContent={idContent}
                            categoryContent={categoryContent}
                          />
                        )}
                      </MDBTabPane>
                    </MDBTabContent>
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(PersetujuanKontenSuperAdmin));
