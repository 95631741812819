import React, { useEffect, useState, useRef } from "react";
import { Row, CardBody, Button, Col } from "reactstrap";
import { useSelector } from "react-redux";
// COMPONENT COMBINATION
import CombOrgProv from "./CombinationTrafficKonten/CardOrgProv";
import CombOrgProvKabKec from "./CombinationTrafficKonten/CardOrgProvKabKec";
import CombOrgProvKat from "./CombinationTrafficKonten/CardOrgProvKat";
import CombOrgProvJam from "./CombinationTrafficKonten/CardOrgProvJam";
import CombOrgKategDurasi from "./CombinationTrafficKonten/CardOrgKategDurasi";
import CombOrgKategJam from "./CombinationTrafficKonten/CardOrgKategJam";
import CombOrgProvDemografi from "./CombinationTrafficKonten/CardOrgProvDemografi";
import CombOrgProvLokasi from "./CombinationTrafficKonten/CardOrgProvLokasi";

// MODAL HELPER
import ModalHelper from "./Popup/helper";

// STYLE CSS
import style from "./style.module.css";
import carbon_save from "../../assets/icon/carbon_save.png";

// ASSETS
import helperIcon from "../../assets/icon/help_center-24px.svg";

import ModalSave from "../../components/Admin/Popup/BI/LogHistory/SaveFilter";

// API
// import API from "../../services";
// REDUX
import { useDispatch } from "react-redux";
import { setDataLogHistory } from "../../store/data/actions";

export default function Index() {
  // const [titleData, setTitleData] = useState("Kunjungan");
  // const [typeData, setTypeData] = useState("visit");
  // const [dataDemografi, setDataDemografi] = useState([]);
  const dispatch = useDispatch();
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);
  const [modalSave, setModalSave] = useState(false);
  const toggleModalSave = () => {
    setModalSave(!modalSave);
  };

  // MODAL HELPER
  const [modalHelper, setModalHelper] = useState(false);
  const toggleModalHelper = () => {
    setModalHelper(!modalHelper);
  };

  const [dataSave, setDataSave] = useState({});

  const [selectOrganisasi, setSelectOrganisasi] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.traffic_org
      : false
  );
  const [selectProvinsi, setSelectProvinsi] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.traffic_prov
      : false
  );
  const [selectKabupaten, setSelectKabupaten] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.traffic_kab
      : false
  );
  const [selectKecamatan, setSelectKecamatan] = useState(false);
  const [selectKategori, setSelectKategori] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.traffic_category
      : false
  );
  const [selectDurasi, setSelectDurasi] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.traffic_duration
      : false
  );
  const [selectJamTayang, setSelectJamTayang] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.traffic_time
      : false
  );
  const [selectDemografi, setSelectDemografi] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_provinsi_trafik_provinsi
      : false
  );
  const [selectLokasi, setSelectLokasi] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_provinsi_peak
      : false
  );

  // CONDITION
  const [showOrgProv, setShowOrgProv] = useState(false);
  const [showOrgProvKabKec, setShowOrgProvKabKec] = useState(false);
  const [showOrgProvKat, setShowOrgProvKat] = useState(false);
  const [showOrgProvJam, setShowOrgProvJam] = useState(false);
  const [showOrgKategoriDurasi, setShowOrgKategoriDurasi] = useState(false);
  const [showOrgKategoriJam, setShowOrgKategoriJam] = useState(false);
  const [showOrgProvDemografi, setShowOrgProvDemografi] = useState(false);
  const [showOrgProvLokasi, setShowOrgProvLokasi] = useState(false);

  const handleSelectFilter = (param) => {
    switch (param) {
      case "org":
        setSelectOrganisasi(!selectOrganisasi);
        break;
      case "prov":
        setSelectProvinsi(!selectProvinsi);
        break;
      case "kab":
        setSelectKabupaten(!selectKabupaten);
        break;
      case "kec":
        setSelectKecamatan(!selectKecamatan);
        break;
      case "kateg":
        setSelectKategori(!selectKategori);
        break;
      case "durasi":
        setSelectDurasi(!selectDurasi);
        break;
      case "jam_tayang":
        setSelectJamTayang(!selectJamTayang);
        break;
      case "demografi":
        setSelectDemografi(!selectDemografi);
        break;
      case "lokasi":
        setSelectLokasi(!selectLokasi);
        break;

      default:
        break;
    }
  };

  // const getDataDemografi = () => {
  //   setLoading(false);
  // setLoading(true);
  // const param =
  //   idProvince !== ""
  //     ? {
  //         type: typeData,
  //         start: startDate,
  //         end: endDate,
  //         province: idProvince,
  //       }
  //     : {
  //         type: typeData,
  //         start: startDate,
  //         end: endDate,
  //       };

  // API.getBIDemografi(param)
  //   .then((res) => {
  //     console.log(res.data.data);
  //     if (res.data.success) {
  //       setDataDemografi(res.data.data);
  //       setLoading(false);
  //     }
  //   })
  //   .catch((e) => {
  //     setDataDemografi(null);
  //     setLoading(false);
  //   });
  // };

  // const list = [
  //   [0,1],
  //   [0,1,2,3],
  //   [0,1,4],
  //   [0,1,6],
  //   [0,4,5],
  //   [0,4,6]
  // ]

  // useEffect(() => {
  //   getDataDemografi();
  // }, [typeData, startDate, endDate, idProvince]);

  const checkCombination = () => {
    if (
      Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.measure === "traffik"
    ) {
      dispatch(setDataLogHistory({}));
    }
    setShowOrgProv(false);
    setShowOrgProvKabKec(false);
    setShowOrgProvKat(false);
    setShowOrgProvJam(false);
    setShowOrgKategoriDurasi(false);
    setShowOrgKategoriJam(false);
    setShowOrgProvDemografi(false);
    setShowOrgProvLokasi(false);
    if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProv(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProvKabKec(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProvKat(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProvJam(true);
    } else if (
      !selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      selectKategori &&
      selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgKategoriDurasi(true);
    } else if (
      !selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      selectKategori &&
      !selectDurasi &&
      selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgKategoriJam(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProvDemografi(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      selectLokasi
    ) {
      setShowOrgProvLokasi(true);
    } else {
      alert("Tidak ada kombinasi");
    }
  };

  const checkCombinationRedux = () => {
    setShowOrgProv(false);
    setShowOrgProvKabKec(false);
    setShowOrgProvKat(false);
    setShowOrgProvJam(false);
    setShowOrgKategoriDurasi(false);
    setShowOrgKategoriJam(false);
    setShowOrgProvDemografi(false);
    setShowOrgProvLokasi(false);
    if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProv(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProvKabKec(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProvKat(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProvJam(true);
    } else if (
      !selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      selectKategori &&
      selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgKategoriDurasi(true);
    } else if (
      !selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      selectKategori &&
      !selectDurasi &&
      selectJamTayang &&
      !selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgKategoriJam(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      selectDemografi &&
      !selectLokasi
    ) {
      setShowOrgProvDemografi(true);
    } else if (
      selectProvinsi &&
      selectOrganisasi &&
      !selectKabupaten &&
      !selectKecamatan &&
      !selectKategori &&
      !selectDurasi &&
      !selectJamTayang &&
      !selectDemografi &&
      selectLokasi
    ) {
      setShowOrgProvLokasi(true);
    } else {
      alert("Tidak ada kombinasi");
    }
  };
  useEffect(() => {
    if (
      Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.measure === "traffik"
    ) {
      checkCombinationRedux();
    }
  }, []);

  return (
    <div className="container-fluid" style={{ marginBottom: "100px" }}>
      {/* <ModalCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        // selectedDate={handleSelectedDate}
      /> */}
      <ModalSave
        modal={modalSave}
        toggle={toggleModalSave}
        data={{
          measure: "traffik",
          traffic_org: selectOrganisasi,
          traffic_prov: selectProvinsi,
          traffic_kab: selectKabupaten,
          traffic_category: selectKategori,
          traffic_duration: selectDurasi,
          traffic_time: selectJamTayang,
          title_provinsi_trafik_provinsi: selectDemografi,
          title_provinsi_peak: selectLokasi,
          ...dataSave,
        }}
      />
      <Row
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}>
        <CardBody
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <span className={style.titleMenuBI}>TRAFIK KONTEN</span>
          <div>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col>
                <Button
                  style={{
                    backgroundColor: "#2C3280",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingBottom: "3px",
                    paddingTop: "3px",
                    minWidth: "100%",
                  }}
                  onClick={() => {
                    toggleModalSave();
                  }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <img src={carbon_save} className="mr-2" />
                    <span>Save</span>
                  </div>
                </Button>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Row>
      {/* MODAL HELPER */}
      <ModalHelper modal={modalHelper} toggle={toggleModalHelper} />
      <Row
        style={{
          display: "flex",
          // alignItems: "center",
          fontSize: "16px",
          fontWeight: "bold",
          // cursor: "pointer",
        }}
        className="my-1">
        <span>Panduan Pencarian Klik Disini!</span>
        <div style={{ display: "flex" }} className="tooltipBI ml-2">
          <img
            src={helperIcon}
            onClick={toggleModalHelper}
            className="hoverHelper"
            style={{ width: "19px" }}
          />
        </div>
      </Row>
      <Row>
        <span
          className={`${style.titleCardBI} my-1 mt-3 mx-3`}
          style={{ fontWeight: "normal", color: "black" }}>
          Pilih Data Traffik Konten
        </span>
      </Row>
      <div>
        <Row className="py-2 mb-1">
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: selectOrganisasi ? "#2C3280" : "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "100%",
              }}
              onClick={() => {
                handleSelectFilter("org");
              }}>
              <span style={{ fontSize: "11px", color: selectOrganisasi ? "#F8F9FA" : "#2C3280" }}>
                Organisasi
              </span>
            </Button>
          </Col>
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: selectProvinsi ? "#2C3280" : "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "100%",
              }}
              onClick={() => {
                handleSelectFilter("prov");
              }}>
              <span style={{ fontSize: "11px", color: selectProvinsi ? "#F8F9FA" : "#2C3280" }}>
                Provinsi
              </span>
            </Button>
          </Col>
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: selectKabupaten ? "#2C3280" : "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "100%",
              }}
              onClick={() => {
                handleSelectFilter("kab");
              }}>
              <span style={{ fontSize: "11px", color: selectKabupaten ? "#F8F9FA" : "#2C3280" }}>
                Kabupaten
              </span>
            </Button>
          </Col>
          {/* <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {" "}
          <Button
            style={{
              backgroundColor: selectKecamatan ? "#2C3280" : "#F8F9FA",
              borderRadius: "20px",
              borderColor: "#2C3280",
              paddingBottom: "3px",
              paddingTop: "3px",
              minWidth: "100%",
            }}
            onClick={() => {
              handleSelectFilter("kec");
            }}>
            <span style={{ fontSize: "11px", color: selectKecamatan ? "#F8F9FA" : "#2C3280" }}>
              Kecamatan
            </span>
          </Button>
        </Col> */}
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: selectKategori ? "#2C3280" : "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "100%",
              }}
              onClick={() => {
                handleSelectFilter("kateg");
              }}>
              <span style={{ fontSize: "11px", color: selectKategori ? "#F8F9FA" : "#2C3280" }}>
                Kategori
              </span>
            </Button>
          </Col>
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: selectDurasi ? "#2C3280" : "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "100%",
              }}
              onClick={() => {
                handleSelectFilter("durasi");
              }}>
              <span style={{ fontSize: "11px", color: selectDurasi ? "#F8F9FA" : "#2C3280" }}>
                Durasi
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: selectJamTayang ? "#2C3280" : "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "100%",
              }}
              onClick={() => {
                handleSelectFilter("jam_tayang");
              }}>
              <span style={{ fontSize: "11px", color: selectJamTayang ? "#F8F9FA" : "#2C3280" }}>
                Jam Tayang
              </span>
            </Button>
          </Col>
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: selectDemografi ? "#2C3280" : "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "100%",
              }}
              onClick={() => {
                handleSelectFilter("demografi");
              }}>
              <span style={{ fontSize: "11px", color: selectDemografi ? "#F8F9FA" : "#2C3280" }}>
                Demografi
              </span>
            </Button>
          </Col>
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: selectLokasi ? "#2C3280" : "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "100%",
              }}
              onClick={() => {
                handleSelectFilter("lokasi");
              }}>
              <span style={{ fontSize: "11px", color: selectLokasi ? "#F8F9FA" : "#2C3280" }}>
                Kategori Lokasi
              </span>
            </Button>
          </Col>
          <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
            {" "}
            <Button
              style={{
                backgroundColor: "#C42127",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
                minWidth: "20%",
              }}
              onClick={checkCombination}>
              <span style={{ fontSize: "11px" }}>OK</span>
            </Button>
          </Col>
        </Row>
        <div className="mt-3">
          {showOrgProv && <CombOrgProv setDataSave={setDataSave} />}
          {showOrgProvKabKec && <CombOrgProvKabKec setDataSave={setDataSave} />}
          {showOrgProvKat && <CombOrgProvKat setDataSave={setDataSave} />}
          {showOrgProvJam && <CombOrgProvJam setDataSave={setDataSave} />}
          {showOrgKategoriDurasi && <CombOrgKategDurasi setDataSave={setDataSave} />}
          {showOrgKategoriJam && <CombOrgKategJam setDataSave={setDataSave} />}
          {showOrgProvDemografi && <CombOrgProvDemografi setDataSave={setDataSave} />}
          {showOrgProvLokasi && <CombOrgProvLokasi setDataSave={setDataSave} />}
        </div>
      </div>
    </div>
  );
}
