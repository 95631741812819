import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import API from "../../../../services";

export default function PostingPopupInfo(props) {
  var { modal, toggle, data, typeContent } = props;
  const posting = (val) => {
    if (props.typeContent !== "Video") {
      let param = {
        idArticle: val._id,
      };
      API.postChangeStatusArticle(param)
        .then((response) => {
          toggle();
          window.location.reload();
        })
        .catch((e) => {
          console.error('PostingPopupInfo', { error: e.message })
        });
    } else {
      let param = {
        idVideoHome: val._id,
      };

      API.changeStatusVideo(param)
        .then((response) => {
          toggle();
          window.location.reload();
        })
        .catch((e) => {
          console.error('PostingPopupInfo', { error: e.message })
        });
    }
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Posting {typeContent}</ModalHeader>
        <ModalBody className="text-center">
          <h3 style={{ color: "#393939" }}>{data !== undefined && data.judul}</h3>
          <hr width={140} />
          <p>Anda yakin ingin Posting {typeContent} ini pada Homepage?</p>
          <div className="m-2">
            <Button onClick={() => posting(data)} style={{ backgroundColor: "#2C3280" }}>
              Posting
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
