import React from "react";
import { Redirect } from "react-router-dom";

// Profile kurator
import Profilekurator from "../pages/Authentication/user-profile";
// Profile content partner
import Profilecp from "../pages/Authentication/cp-profile";

// Authentication related pages
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPwd from "../pages/Authentication/ResetPassword";
import RegisterCP from "../Homepages/Pages/register";

// Homepages
import Homepages from "../Homepages/Pages/homepage";
import HubungiPages from "../Homepages/Pages/hubungi";
import ArtikelPage from "../Homepages/Pages/Artikel/page";

//Super Admin
import loginSuper from "../pages/Authentication/Login";
import ProfileSuperAdmin from "../pages/Authentication/superAdminProfile";
import PersetujuanKontenSuperAdmin from "../pages/PersetujuanKonten/SuperAdmin";
import dashboardAdmin from "../pages/DashboardSuperAdmin";
import BusinessIntelligenceSup from "../pages/BusinessIntelligence/SuperAdmin";
import RegistrasiAdmin from "../pages/RegistrasiAdmin/SuperAdmin";
import TambahPengguna from "../components/SuperAdmin/Tables/RegistrasiAdmin/TambahPengguna";
import KontenSuperAdmin from "../pages/Konten/SuperAdmin";

// Admin
import HomeAdmin from "../pages/Home/Admin";
import BuatSurveyDefault from "../pages/BuatSurveyDefault/";
import PenggunaAdmin from "../pages/Pengguna/Admin";
import TambahPenggunaAdmin from "../pages/TambahPengguna";
import SiteAnalysis from "../pages/SiteAnalysis/Admin";
import SiteAnalysisCP from "../pages/SiteAnalysis/CP";
import KontenDefault from "../pages/KontenDefault/Admin";
import KontenStatistik from "../pages/KontenStatistik";
import KontenReport from "../pages/KontenReport";
import Konten from "../pages/Konten/Admin";
import KontenTayangAdmin from "../pages/KontenTayang/Admin";
import CreateKontenAdmin from "../pages/BuatKonten/Admin";
import CreateKontenDefault from "../pages/CreateKontenDefault/Admin";
import CPAnalisis from "../pages/CPAnalisis";
import CPAnalisisDetail from "../components/Admin/Table/CPTableKonten";
import BusinessIntelligenceAdmin from "../pages/BusinessIntelligence/Admin";
import PesebaranPengunjung from "../components/PersebaranPengunjung/FilterPersebaranPengunjung";
import DemografiPengunjung from "../pages/BusinessIntelligence/Admin/Measures/DemografiPengunjung";
import TrafficKonten from "../pages/BusinessIntelligence/Admin/Measures/TrafficKonten";
import ISP from "../pages/BusinessIntelligence/Admin/Measures/ISP";
import DeviceFingerPrint from "../pages/BusinessIntelligence/Admin/Measures/DeviceFingerprint";
import Survey from "../pages/BusinessIntelligence/Admin/Measures/Survey";
import UserBehavior from "../pages/BusinessIntelligence/Admin/Measures/UserBehavior";
import ContentPreviewAdmin from "../pages/DetailKontenPreview/Admin";
import SurveyAdmin from "../pages/Survey/Admin";
import BuatSurveyAdmin from "../pages/BuatSurvey";
import SurveyDefaultPreview from "../pages/PreviewSurveyDefault/Admin";
import DuplikatSurvey from "../pages/DuplikatSurvey";
import BannerLandingPage from "../pages/BannerLandingPage";

//Support
import HomeSp from "../pages/Home/Support";
import UserProfile from "../pages/Authentication/user-profile";
import TicketPage from "../pages/Ticket/SupportCenter";
import DetailKontenContentPartner from "../pages/DetailKonten/ContentPartner";
import SurveyKepuasanSummary from "../pages/SurveyKepuasanSummary/SupportCenter";
import SiteStatistics from "../pages/SiteStatistics";
import UniqueDeviceAdmin from "../pages/UniqueDevice/Admin";
import HasilSurvey from "../pages/HasilSurvey";
import HasilSurveyDefault from "../pages/HasilSurveyDefault";
import KontenSupport from "../pages/Konten/Support";

//Site Checker
import SiteChecker from "../pages/SiteChecker";

//500 error
import Pages500 from "../pages/Utility/pages-500";
import Artikel from "../pages/Artikel";
import Video from "../pages/Video";
import EditArticle from "../pages/Artikel/EditArticle";
import CreateArticle from "../pages/Artikel/CreateArticle";
import KontenTayangKurator from "../pages/KontenTayang/Kurator";

const userRoutes = [
  // Homepages
  { path: "/login", component: loginSuper },
  // Super Admin
  { path: "/superadmin/persetujuan-konten", component: PersetujuanKontenSuperAdmin },
  { path: "/superadmin/profile", component: ProfileSuperAdmin },
  { path: "/superadmin/registrasi-admin", component: RegistrasiAdmin },
  { path: "/superadmin/tambah-pengguna", component: TambahPengguna },
  { path: "/superadmin/dashboard-admin", component: dashboardAdmin },
  { path: "/superadmin/business-intelligence", component: BusinessIntelligenceAdmin },
  { path: "/superadmin/konten", component: KontenSuperAdmin },

  // Admin
  { path: "/admin/home", component: HomeAdmin },
  { path: "/admin/artikel", component: Artikel },
  { path: "/admin/video", component: Video },
  { path: "/admin/edit-article", component: EditArticle },
  { path: "/admin/create-article", component: CreateArticle },
  { path: "/admin/profile", component: ProfileSuperAdmin },

  // Admin -> Pengguna
  { path: "/admin/pengguna", component: PenggunaAdmin },
  // Admin -> Artikel
  { path: "/admin/artikel", component: Artikel },
  { path: "/admin/video", component: Video },
  // { path: "/admin/site-analysis", component: SiteAnalysis },
  { path: "/admin/tambah-pengguna", component: TambahPenggunaAdmin },

  // Admin -> Konten
  { path: "/admin/konten", component: KontenDefault },
  { path: "/admin/konten-create", component: CreateKontenAdmin },
  { path: "/admin/konten-statistik", component: KontenStatistik },
  { path: "/admin/konten-statistik-detail", component: SiteAnalysis },
  { path: "/admin/konten-default", component: KontenDefault },
  { path: "/admin/konten-preview", component: ContentPreviewAdmin },
  { path: "/admin/konten-default-create", component: CreateKontenDefault },
  { path: "/admin/statistik-site", component: SiteStatistics },
  { path: "/admin/konten-tayang", component: KontenTayangAdmin },
  { path: "/admin/konten-preview", component: KontenDefault },
  { path: "/admin/konten-report", component: KontenReport },

  // Admin -> Survey
  { path: "/admin/survey/:id", component: SurveyAdmin },
  { path: "/admin/survey", component: SurveyAdmin },
  { path: "/admin/create/survey", component: BuatSurveyAdmin },
  { path: "/admin/result/survey", component: HasilSurvey },
  { path: "/admin/result/survey_default", component: HasilSurveyDefault },
  { path: "/admin/create/survey_default", component: BuatSurveyDefault },
  { path: "/admin/survey/preview", component: SurveyDefaultPreview },
  { path: "/admin/duplikat/survey", component: DuplikatSurvey },

  // Admin -> Site Analis
  { path: "/admin/statistik-site", component: SiteStatistics },
  { path: "/admin/unique-device", component: UniqueDeviceAdmin },
  { path: "/admin/site-checker", component: SiteChecker },

  // Admin -> Konten Partner
  { path: "/admin/cp-analitics", component: CPAnalisis },
  { path: "/admin/cp-analitics-detail", component: CPAnalisisDetail },
  // { path: "/admin/cp-analitics/detail/statistik-konten", component: SiteAnalysis },
  { path: "/admin/cp-analitics-detail-statistik-konten", component: SiteAnalysisCP },
  // Admin -> BI
  { path: "/admin/business-intelligence", component: BusinessIntelligenceSup },

  // Banner
  { path: "/admin/banner-landing-page", component: BannerLandingPage },

  { path: "/admin/business-intelligence-PersebaranPengunjung", component: PesebaranPengunjung },
  { path: "/admin/business-intelligence-DemografiPengunjung", component: DemografiPengunjung },
  { path: "/admin/business-intelligence-TrafficKonten", component: TrafficKonten },
  { path: "/admin/business-intelligence-ISP", component: ISP },
  { path: "/admin/business-intelligence-DeviceFingerPrint", component: DeviceFingerPrint },
  { path: "/admin/business-intelligence-Survey", component: Survey },
  { path: "/admin/business-intelligence-UserBehavior", component: UserBehavior },

  { path: "/admin/log-history", component: BusinessIntelligenceSup },

  // Support
  { path: "/support-center/home", component: HomeSp },
  { path: "/support-center/profile", component: UserProfile },
  { path: "/support-center/pengguna", component: PenggunaAdmin },
  { path: "/support-center/konten", component: KontenSupport },
  { path: "/support-center/konten-tayang", component: KontenTayangAdmin },
  { path: "/support-center/detail-konten", component: SiteAnalysis },
  { path: "/support-center/site-checker", component: SiteChecker },
  { path: "/support-center/survey-kepuasan", component: SurveyKepuasanSummary },
  { path: "/support-center/ticket", component: TicketPage },
  { path: "/support-center/detail-konten", component: SiteAnalysis },

  //Admin Artikel
  { path: "/adminartikel/artikel", component: Artikel },
  { path: "/adminartikel/edit-article", component: EditArticle },
  { path: "/adminartikel/create-article", component: CreateArticle },
  { path: "/adminartikel/profile", component: ProfileSuperAdmin },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const authRoutes = [
  { path: "/hubungi", component: HubungiPages },
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:token", component: ResetPwd },
  { path: "/register", component: Register },
  { path: "/register-cp", component: RegisterCP },
  { path: "/artikel/:id", component: ArtikelPage },
  { path: "/pages500", component: Pages500 },
];

export { userRoutes, authRoutes };
