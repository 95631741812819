import React, { useState, useEffect } from "react";
import {
  Spinner,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Button,
  Input,
  ButtonDropdown,
} from "reactstrap";
import moment from "moment";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import API from "../../services";

// ASSETS
import iconDown from "../../assets/icon/back.png";

// STYLES
import "./styles.css";

// REDUX
import { useSelector } from "react-redux";

export default function FilterOrgProv(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);
  const [dropdownOrg, setDropdownOrg] = useState(false);
  const toggleDropdownOrg = () => setDropdownOrg((prevState) => !prevState);
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown((prevState) => !prevState);
  const [dropdownProv, setDropdownProv] = useState(false);
  const toggleDropdownProv = () => setDropdownProv((prevState) => !prevState);
  const [listProv, setListProv] = useState([]);

  const [pilihOrganization, setPilihOrganization] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_org_peak
      : "Semua Organisasi"
  );
  const [idOrg, setIdOrg] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.id_org_peak
      : ""
  );

  const [orgList, setOrgList] = useState([]);

  const [dataType, setDataType] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.data_type_peak
      : "Video"
  );
  const [dataProvince, setDataProvince] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_provinsi_peak
      : "Semua Provinsi"
  );
  const [idProvince, setIdProvince] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.id_provinsi_peak
      : ""
  );

  const getOrgList = () => {
    API.getListOrg()
      .then((res) => {
        if (res.data.success) {
          setOrgList(res.data.data);
        } else {
          setOrgList([]);
        }
      })
      .catch((e) => {
        setOrgList(null);
      });
  };

  const getListProvinces = () => {
    API.getAllProvince()
      .then((res) => {
        setListProv(res.data.data);
      })
      .catch((err) => setListProv([]));
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    getListProvinces();
    getOrgList();
  }, []);

  const getData = () => {
    props.setLoading(true);
    // props.setShowComponent(true);
    // setResetTitle(false);

    const param =
      idProvince === "" && idOrg === ""
        ? {
            type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
          }
        : idProvince !== "" && idOrg === ""
        ? {
            type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
            province: idProvince,
          }
        : idProvince === "" && idOrg !== ""
        ? {
            type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
            organization: idOrg,
          }
        : idProvince !== "" &&
          idOrg !== "" && {
            type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
            province: idProvince,
            organization: idOrg,
          };
    API.getSummaryPeak(param)
      .then((res) => {
        if (res.data.success) {
          props.setData(res.data.data);
          setData(res.data.data);
          if (res.data.data) {
            if (Object.entries(res.data.data)) {
              if (Object.values(res.data.data.impress).filter((e) => e !== 0).length === 0) {
                setTimeout(() => {
                  alert(
                    `Tidak ada data pada filter ${dataType} ${dataProvince} ${pilihOrganization}`
                  );
                }, 500);
              }
              // console.log(Object.values(res.data.data.impress).filter((e) => e !== 0));
            }
          }
          props.setLoading(false);
        } else {
          props.setData([]);
          setTimeout(() => {
            alert(`Tidak ada data pada filter ${dataType} ${dataProvince} ${pilihOrganization}`);
          }, 500);
          setData([]);
          props.setLoading(false);
        }
      })
      .catch((e) => {
        props.setData(null);
        setTimeout(() => {
          alert(
            `Error: "Users not Found" di filter ${dataType} ${dataProvince} ${pilihOrganization}`
          );
        }, 500);
        setData(null);
        props.setLoading(false);
      });
  };

  const [resetFn, setResetFn] = useState(false);

  useEffect(() => {
    getData();
    props.setOrg(pilihOrganization);
    props.setContent(dataType);
    props.setProv(dataProvince);
  }, [resetFn]);

  useEffect(() => {
    props.setDataSave(
      Object.assign(props.dataSave, {
        data_type_peak: dataType,
        id_org_peak: idOrg,
        title_org_peak: pilihOrganization,
        id_provinsi_peak: idProvince,
        title_provinsi_peak: dataProvince,
      })
    );
  }, [resetFn, dataType, idOrg, pilihOrganization, idProvince, dataProvince]);

  const resetAwait = () => {
    setPilihOrganization("Semua Organisasi");
    setIdOrg("");
    setDataType("Video");
    setIdProvince("");
  };

  const filterReset = () => {
    setResetFn((prevState) => !prevState);
    resetAwait();
  };

  const changeOrganization = (value) => {
    setPilihOrganization(value.name);
    setIdOrg(value._id);
  };
  const clearOrganization = () => {
    setPilihOrganization("Semua Organisasi");
    setIdOrg("");
  };

  return (
    <>
      <Row className="py-2" style={{ marginBottom: "100px" }}>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <ButtonDropdown style={{ width: "100%" }} isOpen={dropdown} toggle={toggleDropdown}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{dataType}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "150px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Video");
                }}>
                Video
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Banner");
                }}>
                Banner
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Berita");
                }}>
                Berita
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <ButtonDropdown
            style={{ width: "100%" }}
            isOpen={dropdownProv}
            toggle={toggleDropdownProv}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{dataProvince}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "150px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setDataProvince("Semua Provinsi");
                  setIdProvince("");
                }}>
                Semua Provinsi
              </DropdownItem>
              {listProv.length !== 0 &&
                listProv.map((i, index) => (
                  <DropdownItem
                    key={index}
                    onClick={(e) => {
                      setDataProvince(i.name);
                      setIdProvince(i._id);
                    }}>
                    {i.name}
                  </DropdownItem>
                ))}
              {/* <DropdownItem
                onClick={(e) => {
                  setDataType("Video");
                }}>
                Video
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Banner");
                }}>
                Banner
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Berita");
                }}>
                Berita
              </DropdownItem> */}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {/* <ButtonDropdown style={{ width: "100%" }} isOpen={dropdownOrg} toggle={toggleDropdownOrg}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{pilihOrganization}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "150px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setPilihOrganization("Semua Provinsi");
                  setIdOrg("");
                }}>
                Semua Organisasi
              </DropdownItem>
              {orgList === null ? (
                <DropdownItem>Terjadi Kesalahan Server</DropdownItem>
              ) : (
                orgList.map((e) => (
                  <DropdownItem
                    key={e._id}
                    onClick={(i) => {
                      setIdOrg(e._id);
                      setPilihOrganization(e.name);
                    }}>
                    {e.name}
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          </ButtonDropdown> */}
          <Autocomplete
            // classes={style.dropdownAutocomplete}
            id="combo-box-demo"
            options={orgList !== null ? orgList : []}
            defaultValue={{ name: pilihOrganization }}
            value={{ name: pilihOrganization }}
            getOptionLabel={(option) => {
              return option.name;
            }}
            onChange={(e, value) => {
              value !== null ? changeOrganization(value) : clearOrganization();
            }}
            renderOption={(option) => (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>{option.name}</div>
                </div>
              </React.Fragment>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // value={dataTitle}
                placeholder="Masukan Nama Organisasi"
              />
            )}
          />
        </Col>
        <Col lg="1" className="p-0">
          <Button
            style={{
              backgroundColor: "#2C3280",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={getData}>
            <span style={{ fontSize: "11px" }}>Cari</span>
          </Button>
        </Col>
        <Col lg="1" className="p-0">
          <Button
            disabled={idOrg == "" && dataType == "Video" && idProvince == ""}
            style={{
              backgroundColor: "#C42127",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={filterReset}>
            <span style={{ fontSize: "11px" }}>Reset</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}
