import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const KontenDefaultAdminAsync = Loadable({
  loader: () =>
    import("../../../pages/KontenDefault/Admin" /* webpackChunkName: "KontenDefaultAdminAsync" */),
  loading: Loading,
});

export default KontenDefaultAdminAsync;
