import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import _ from "lodash";

const DownloadXLS = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.data) {
      if (props.data.length !== 0) {
        setData(_.groupBy(props.data, (e) => e.demografi.id));
        // console.log(Object.entries(_.groupBy(props.data, (e) => e.demografi.id)));
      } else {
        setData(null);
      }
    } else {
      setData(null);
    }
  }, [props.data]);

  // var number = 1;

  return (
    <Table
      style={{ display: "none" }}
      id="DownloadTableXLSSurveyDemografiDetail"
      className="DownloadTableXLS">
      <thead>Detail Demografi Pengunjung Tanggal {props.date}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Nama</th>
          {/* <th>Count Person</th> */}
          <th>Umur</th>
          <th>Gender</th>
          <th>Status</th>
          <th>Jenjang Pendidikan</th>
          <th>Judul Survey</th>
          <th>Pertanyaan Survey</th>
          <th>Pilihan Jawaban</th>
          <th>Jawaban</th>
          <th>Provinsi</th>
          <th>Tanggal Survey</th>
          <th>Jam Survey</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.entries(data).map(([k, v], index) => {
            return v.map((e, i) => (
              <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
                {/* NO */}
                {i === 0 && <td rowSpan={v.length}> {index + 1}</td>}
                {/* NAMA */}
                {i === 0 && <td rowSpan={v.length}> {e?.demografi?.name ?? "-"}</td>}
                {/* COUNT PERSON */}
                {/* {i === 0 && <td rowSpan={v.length}> {v.length ?? "-"}</td>} */}
                {/* UMUR */}
                {i === 0 && <td rowSpan={v.length}> {e?.demografi?.age ?? "-"}</td>}
                {/* GENDER */}
                {i === 0 && <td rowSpan={v.length}> {e?.demografi?.gender ?? "-"}</td>}
                {/* STATUS */}
                {i === 0 && <td rowSpan={v.length}> {e?.demografi?.status ?? "-"}</td>}
                {/* JENJANG */}
                {i === 0 && <td rowSpan={v.length}> {e?.demografi?.education ?? "-"}</td>}
                <td>{e?.survey?.name ?? "-"}</td>
                <td>{e?.survey?.question ?? "-"}</td>
                <td>{e?.survey?.options ?? "-"}</td>
                <td>{e?.survey?.answer ?? "-"}</td>
                <td>{e.province ?? "-"}</td>
                <td>{e.date ?? "-"}</td>
                <td>'{e.time ?? "-"}</td>
              </tr>
            ));
          })
        ) : (
          <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
            <td>-</td>
            {/* <td>-</td> */}
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
