import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);

  let judul;
  switch (props.activeTab) {
    case "1":
      judul = "Data Unique Device";
      break;
    case "2":
      judul = "Data Site AI";
      break;
    case "3":
      judul = "Data Behaviour Site";
      break;
    default:
      break;
  }
  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS2" className="DownloadTableXLS">
      <thead>{`${judul}`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Nama Lokasi AI</th>
          <th>Provinsi</th>
          <th>Kabupaten</th>
          <th>Default Bandwidth</th>
          <th>IP Address</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{data ? (data.name ? data.name : "-") : "-"}</td>
            <td>{data ? (data.province ? data.province : "-") : "-"}</td>
            <td>{data ? (data.kab_kota ? data.kab_kota : "-") : "-"}</td>
            <td>{data ? (data.bandwidth ? data.bandwidth : "-") : "-"}</td>
            <td>{data ? (data.ip ? data.ip.map((res) => res).toString() : "-") : "-"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
