import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Card,
  TabPane,
  TabContent,
  Button,
  Alert,
  Spinner,
  Modal,
  ModalBody,
} from "reactstrap";

import { MDBModalHeader, MDBNavLink } from "mdbreact";
import moment from "moment";

// COMPONENTS
import CardSurvey from "./CardSurvey";
import DemografiPengunjung from "./DemografiPengunjungSurvey";
import StatistikSurvey from "./StatistikSurvey";
import SummaryHasilSurvey from "./SummaryHasilSurvey";

// REDUX
import { useSelector } from "react-redux";

import style from "./style.module.css";
import carbon_save from "../../assets/icon/carbon_save.png";

import API from "../../services";

// MODAL
import ModalSave from "../../components/Admin/Popup/BI/LogHistory/SaveFilter";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "./Download/XLS/DownloadCardSurvey";

const Tab = (props) => {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  // MODAL DOWNLOAD
  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);
  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  const [activeTab, setactiveTab] = React.useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.condition
      : "1"
  );
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(true);

  const [dataSave, setDataSave] = useState({ survey_title: "", province: "", start_date: "" });

  const TabStyle = {
    color: "#2C3280",
    fontSize: "16px",
    fontWeight: "bold",
    borderTop: "1px solid #2C3280",
    borderRight: "1px solid #2C3280",
    borderLeft: "1px solid #2C3280",
    padding: "5px 10px 10px 10px",
    borderRadius: "15px",
  };

  const ActiveTabStyle = {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    borderTop: "1px solid #2C3280",
    borderRight: "1px solid #2C3280",
    borderLeft: "1px solid #2C3280",
    padding: "5px 10px 10px 10px",
    borderRadius: "15px",
    backgroundColor: "#2C3280",
  };

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  // MODAL SAVE
  const [modalSave, setModalSave] = useState(false);
  const toggleModalSave = () => {
    setModalSave(!modalSave);
  };

  const getDashboardSurvey = () => {
    API.getDashboardSurvey()
      .then((res) => {
        if (res.data.success) {
          setDashboardData(res.data.data);
          setLoading(false);
        } else {
          setDashboardData(null);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        setDashboardData(null);
      });
  };

  useEffect(() => {
    getDashboardSurvey();
    // setLoading(false);
    // setDashboardData(null);
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, []);

  // console.log("dashboard dataa", dashboardData);

  return (
    <>
      <ModalSave modal={modalSave} toggle={toggleModalSave} data={dataSave} />
      <Row>
        <Col lg={12}>
          <Row
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              paddingBottom: "15px",
              paddingTop: "15px",
              display: "flex",
              justifyContent: "center",
            }}
            className="mb-3">
            <CardBody
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <span className={style.titleMenuBI}>SURVEY</span>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="float-right" style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{
                      backgroundColor: "#2C3280",
                      borderRadius: "20px",
                      borderColor: "#2C3280",
                      paddingBottom: "3px",
                      paddingTop: "3px",
                    }}
                    // disabled={activeTab === "1"}
                    onClick={toggleModalSave}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      <img src={carbon_save} className="mr-2" />
                      <span>Save</span>
                    </div>
                  </Button>
                </div>
                <div>
                  <Modal
                    size="md"
                    isOpen={modalDownloadXLS}
                    toggle={toggleDownloadXLS}
                    centered={true}>
                    <MDBModalHeader toggle={toggleDownloadXLS}>
                      <span className={style.headText}>Download XLS</span>
                    </MDBModalHeader>
                    <ModalBody>
                      <center className="container-popup">
                        <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                        <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                          Batal
                        </Button>
                        <Button
                          color=""
                          className={style.btn_confirm_xls}
                          onClick={toggleDownloadXLS}>
                          <ReactHTMLTableToExcel
                            id="TableXLS"
                            className={style.DownloadTableXLS}
                            table="DownloadTableXLSCard"
                            filename={`card-survey ${moment(new Date()).format("DD-MM-YYYY")}`}
                            sheet="card survey"
                            buttonText="Download"
                          />
                        </Button>
                      </center>
                    </ModalBody>
                  </Modal>
                  {loading ? (
                    ""
                  ) : (
                    <>
                      <Col
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}>
                        <MDBNavLink to="#" className={style.textDownload}>
                          Download
                        </MDBNavLink>
                        <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                          XLS
                        </MDBNavLink>
                      </Col>
                      <DownloadXLS
                        id="DownloadTableXLSCard"
                        className="DownloadTableXLS"
                        data={dashboardData}
                      />
                    </>
                  )}
                </div>
              </div>
            </CardBody>
          </Row>
          {loading ? (
            <div className="d-flex justify-content-center" style={{ marginTop: "30vh" }}>
              <Spinner type="grow" />
            </div>
          ) : (
            <>
              <Card>
                <CardSurvey data={dashboardData} loading={loading} />
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        // className={classnames({
                        //   active: activeTab === "1",
                        // })}
                        className="py-2 px-0"
                        onClick={() => {
                          toggle("1");
                        }}>
                        <span style={activeTab == "1" ? ActiveTabStyle : TabStyle}>
                          DEMOGRAFI PENGUJUNG MENGISI SURVEI
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        // className={classnames({
                        //   active: activeTab === "2",
                        // })}
                        className="py-2 px-0"
                        onClick={() => {
                          toggle("2");
                        }}>
                        <span style={activeTab == "2" ? ActiveTabStyle : TabStyle}>
                          STATISTIK HASIL SURVEI
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        // className={classnames({
                        //   active: activeTab === "2",
                        // })}
                        className="py-2 px-0"
                        onClick={() => {
                          toggle("3");
                        }}>
                        <span style={activeTab == "3" ? ActiveTabStyle : TabStyle}>
                          SUMMARY HASIL SURVEI
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" className="p-3">
                      <Row>
                        <Col sm="12">
                          {dashboardData ? (
                            Object.values(dashboardData).length !== 0 ? (
                              <DemografiPengunjung
                                data={dashboardData}
                                loading={loading}
                                setDataSave={setDataSave}
                                activeTab={activeTab}
                              />
                            ) : (
                              <Alert color="danger" className="text-center">
                                Terjadi Kesalahan Pada Server
                              </Alert>
                            )
                          ) : (
                            <Alert color="danger" className="text-center">
                              Terjadi Kesalahan Pada Server
                            </Alert>
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                      <Row>
                        <Col sm="12">
                          <StatistikSurvey setDataSave={setDataSave} activeTab={activeTab} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3" className="p-3">
                      <Row>
                        <Col sm="12">
                          {" "}
                          <SummaryHasilSurvey
                            data={dashboardData}
                            loading={loading}
                            setDataSave={setDataSave}
                            activeTab={activeTab}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Tab;
