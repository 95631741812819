import React, { useState, useEffect } from "react";
import { MDBModal, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./style.css";
import moment from "moment";
import IconEmergency from "../../../../../assets/icon/IconEmergency.svg";

// ------------------API-----------------------
import API from "../../../../../services";
import _ from "lodash";

const DetailKonten = ({ ModalDetailKonten, toggleModalDetailKonten, dataModalDetailKonten }) => {
  let data = dataModalDetailKonten !== undefined ? dataModalDetailKonten.data : [];
  if (data !== undefined)
    return (
      <MDBModal
        id="ModalDetailKonten"
        isOpen={ModalDetailKonten}
        toggle={toggleModalDetailKonten}
        size="lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="w-100 text-center">
              <p className="topTitleEdit">DETAIL KONTEN</p>
            </div>
            <button
              type="button"
              className="close pull-right"
              aria-label="Close"
              onClick={toggleModalDetailKonten}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
            <div className="w-40 mx-auto wrapperSubTitleVerif">
              {/* <h4 className="subTitle">{data.title.length == 0 ? data.details.title : data.title}</h4> */}
            </div>

            <div className="w-100 mx-auto wrapperVerification">
              <MDBTable responsive borderless id="TableDetailKonten">
                <MDBTableHead>
                  <tr>
                    <th>Organisasi</th>
                    <th>Kategori</th>
                    <th>Hasil Kurasi</th>
                    <th>Tanggal Awal Tayang</th>
                    <th>Tanggal Akhir Tayang</th>
                    <th>Durasi</th>
                    <th>Jam Tayang</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td>
                      {data
                        ? data.requested_by
                          ? data.requested_by.organization
                            ? data.requested_by.organization.name
                              ? data.requested_by.organization.name
                              : "-"
                            : "-"
                          : "-"
                        : "-"}
                    </td>
                    <td>{data ? (data.category ? data.category : "-") : "-"}</td>
                    <td>
                      {data.emergency !== undefined && data.emergency !== "" ? (
                        <div className="d-flex pointer">
                          <img src={IconEmergency}></img>
                          <label
                            key={data._id}
                            className="mx-auto py-1 pointer"
                            style={{ color: "#C42127" }}>
                            Emergency
                          </label>
                        </div>
                      ) : data.status_kurator[data.status_kurator.length - 1].status ==
                        "Tidak Lolos Kurasi" ? (
                        <label key={data._id} className="mx-auto py-1" style={{ color: "#C42127" }}>
                          Tidak Lolos Kurasi
                        </label>
                      ) : data.status_kurator[data.status_kurator.length - 1].status ==
                        "Lolos Kurasi" ? (
                        <label key={data._id} className="mx-auto py-1" style={{ color: "#55BA59" }}>
                          Lolos Kurasi
                        </label>
                      ) : (
                        <>{data.status_kurator[data.status_kurator.length - 1].status}</>
                      )}
                    </td>
                    <td>{`${moment(data.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")}`}</td>
                    <td>{`${moment(data.proposed_date.slice(-1), "DD-MM-YYYY").format(
                      "DD-MM-YYYY"
                    )}`}</td>
                    <td>{data ? (data.proposed_duration ? data.proposed_duration : "-") : "-"}</td>
                    <td>
                      {data
                        ? data.proposed_time[0]
                          ? data.proposed_time[0].slice(-1).pop()
                          : "-"
                        : "-"}
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
      </MDBModal>
    );
  else {
    return <></>;
  }
};

export default DetailKonten;
