import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import ReactPaginate from "react-paginate";

// ------------------REDUX---------------------
import { connect } from "react-redux";
import { detailData } from "../../../../../store/actions";
// ------------------API-----------------------
import API from "../../../../../services";
// import ProvinceModal from "./../modals/ProvinceModal";

// -------------------ASSET--------------------
//css
import "./style.scss";

const TableUniqueDevice = (props) => {
  const searchRef = useRef();
  //const [rows, setList] = useState([]);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");

  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageDisplay, setPageDisplay] = useState(0);

  const fetchAPIVideo = async (params) => {
    setList([]);
    setLoading(true);
    setPage(params.page ?? 1);

    await API.getUniqueDevice(params)

      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
          setList([]);
          setLoading(false);
        } else {
          // console.log(JSON.stringify(res.data.data));
          setIsEmpty(false);
          setTimeout(() => {
            //setList([res.data.data[0], res.data.data[1]]);
            setList(res.data.data);
          }, 1000);
          // setList(res.data.data);
          setPageCount(res.data.count);
          setPageDisplay(10);

          setLoading(false);
        }

        // return API.getUniqueDevice({
        //   search: params.search,
        //   limit: 0,
        //   skip: 0,
        // });
      })
      // .then((res) => {
      //   //alert(res.data.count + " again");
      //   props.handleData([]);
      //   if (res.data.count > 0) {
      //     props.handleData(res.data.data);
      //   }

      //   setLoading(false);
      // })
      .catch((e) => {
        console.log("error , ", e.message);
        alert("error , ", e.message);
        // history.push("/pages-500");
        setList([]);
        setLoading(false);
        //props.setLoading(false)
      });
  };

  const handlePageClick = (data) => {
    //console.log("data from handle ==> ", data);
    //const searchValue = searchRef.current.value;
    setPage(data.selected + 1);
  };

  //set search data based on input value
  const handleSearch = () => {
    const params = { limit: 10 };

    const searchValue = searchRef.current.value;
    setSearch(searchValue);

    params.page = 1;
    params.skip = 0;

    if (searchValue) {
      params.search = searchValue;
    }

    // props.setSearch(searchValue);

    fetchAPIVideo(params);
    // setLoading(true);
    // setPage(1);

    // setTimeout(function () {
    //   setLoading(false);
    // }, 3000);
  };

  useEffect(() => {
    const params = { limit: 10 };

    const searchValue = searchRef.current.value;

    if (page) {
      params.page = page;
      params.skip = (page - 1) * 10;
    }

    if (searchValue) {
      params.search = searchValue;
    }

    fetchAPIVideo(params);
  }, [page]);

  // data for export/download unique device
  useEffect(() => {
    async function getExportDataUniqueDevice() {
    const uniqueDeviceData = await API.getUniqueDevice({
      limit: 100000, // since the data was big, we need to find solution for export logic
      page: 1,
      skip: 0,
    });

    // set into callback props parent
    if (uniqueDeviceData?.data?.data) {
      setLoading(false);
      props.handleData(uniqueDeviceData.data.data || []);
    }
  }

  // call the function
  // in prod we have some issue in api, cuz have big data and broke the limit of query in mongodb
  // getExportDataUniqueDevice();
  }, []);

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Terakhir Login",
        field: "last_login",
        sort: "asc",
        width: 150,
      },
      {
        label: "Jumlah Visit",
        field: "total_visit",
        sort: "asc",
        width: 150,
      },
      {
        label: "OS",
        field: "os",
        sort: "asc",
        width: 150,
      },
      {
        label: "Browser",
        field: "browser",
        sort: "asc",
        width: 150,
      },
      {
        label: "Model",
        field: "model",
        sort: "asc",
        width: 150,
      },
      {
        label: "Resolusi",
        field: "resolusi",
        sort: "asc",
        width: 150,
      },
    ],

    rows:
      list === null
        ? [
            {
              id: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : list.map((it, index) => ({
            no: index + (page - 1) * 10 + 1,
            id: it._id ?? "-",
            last_login:
              `${moment(it.lastLogin).format("YYYYMMDD")}
            ${moment(it.lastLogin).format("DD-MM-YYYY")}` ?? "-",
            total_visit: it.jumlahVisit ?? "-",
            os: it.os ?? "-",
            browser:
              it.browser
                .map((im) => {
                  return im;
                })
                .join() ?? "-",
            model: it.model ?? "-",
            resolusi: it.resolution ?? "-",
          })),
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="text-bold"></div>
            </Col>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="text-center" style={{ width: "100%", textAlign: "center" }}>
                    {loading ? <Spinner color="primary" /> : ""}
                  </div>
                  <div className="d-flex ml-auto w-25 my-3">
                    {/* <Button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={toggleModalProvince}>
                      Provinsi
                    </Button> */}
                    <input
                      style={{ margin: "0 5px 0 5px" }}
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      type="text"
                    />
                    <Button
                      className="btn btn-primary btn-sm"
                      onClick={handleSearch}
                      style={{ margin: "0 5px 0 5px", backgroundColor: "#2c3280" }}
                      value={search}
                      disabled={loading}>
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  {list.length === 0 && !isEmpty && loading ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">Data Tidak ditemukan</div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="tableUniqueDevice"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                        paging={false}
                      />
                      <div className="float-left">
                        <span>
                          Showing {(page - 1) * 10 + 1} to{" "}
                          {(page - 1) * 10 + 10 > pageCount ? pageCount : (page - 1) * 10 + 10} of{" "}
                          {pageCount}
                        </span>
                      </div>
                      <div className="float-right">
                        <ReactPaginate
                          pageCount={Math.ceil(pageCount / pageDisplay) ?? 0}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          initialPage={page - 1 ?? 0} // INDEX
                          forcePage={page - 1 ?? 0}
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          activeClassName="active"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"></ReactPaginate>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {/* <ProvinceModal
          toggleModalProvince={toggleModalProvince}
          modalProvince={modalProvince}
          setSelectedProvince={setSelectedProvince}
          selectedProvince={selectedProvince}
          selectedAll={selectedAll}
          setSelectedAll={setSelectedAll}></ProvinceModal> */}
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStatetoProps, { detailData })(TableUniqueDevice);
