import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const ContentPartnerAsync = Loadable({
  loader: () =>
    import("../../../pages/Konten/ContentPartner" /* webpackChunkName: "ContentPartnerAsync" */),
  loading: Loading,
});

export default ContentPartnerAsync;
