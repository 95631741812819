import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data, props.data2, props.activeTab, props.activeSubTab1, props.activeSubTab2]);
  let judul;
  switch (props.activeTab) {
    case "1":
      judul = "KONTEN";
      break;
    case "2":
      judul = "KONTEN ARCHIEVE";
      break;
    default:
      break;
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`DATA ${judul} ${role}`}</thead>
      <thead>
        <tr>
          {props.activeTab == "2" ? (
            <>
              <th>No</th>
              <th>Judul</th>
              <th>Kategori</th>
              <th>Status</th>
              <th style={{ width: "300px" }}>Preview</th>
            </>
          ) : (
            <>
              <th>No</th>
              <th>Judul</th>
              <th>Organisasi</th>
              <th>Kategori</th>
              <th>Kategori Lokasi</th>
              <th>Durasi</th>
              <th>Provinsi</th>
              <th>Tanggal Tayang</th>
              <th>Status</th>
              <th style={{ width: "300px" }}>Preview</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {props.activeTab == "2" ? (
          <>
            {props.data2.map((data, index) => (
              <tr
                key={index}
                style={{ height: "200px", textAlign: "center", verticalAlign: "middle" }}>
                <td>{index + 1}</td>
                <td>{data.title ? data.title : data.details.title ? data.details.title : "-"}</td>
                <td>{data.category ?? "-"}</td>
                <td>
                  {data.status == "submitted"? 
                    data.status_kurator[data.status_kurator.length - 1].status == "Revisi"?
                    "Revisi"
                    :data.status_kurator[data.status_kurator.length - 1].status == "Tidak Lolos Kurasi"?
                    "Tidak Lolos Kurasi"
                    :data.status_kurator[data.status_kurator.length - 1].status == "Lolos Kurasi"?
                    "Lolos Kurasi"
                    :data.status_kurator[data.status_kurator.length - 1].status == "Belum Kurasi"?
                    "Baru"
                    :"-"
                  :data.status == "scheduled"?
                  "Tayang"
                  :data.status == "declined"?
                  "Ditolak"
                  :"-"}
                </td>
                <td>
                  <img
                    src={
                      props.activeSubTab2 === "1"
                      ? `${process.env.REACT_APP_SELF_URL}/${data.details.thumbnail}`
                      : `${process.env.REACT_APP_SELF_URL}/${data.details.url}`
                    }
                    height="100"
                    style={{
                      objectFit: "cover",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}></img>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <>
            {props.data.map((data, index) => {
             return( 
             <tr
                key={index}
                style={{ height: "200px", textAlign: "center", verticalAlign: "middle" }}>
                <td>{index + 1}</td>
                <td>{data.title ? data.title : data.details.title ? data.details.title : "-"}</td>
                <td>{data.requested_by.organization.name ?? "-"}</td>
                <td>{data.category ?? "-"}</td>
                <td>{data.location&&data.location.join(",")}</td>
                <td>{data.proposed_duration ?? "-"}</td>
                <td>{data.list_provinsi.map((prov) => prov.name).toString()}</td>
                <td>
                  {`${moment(data.proposed_date[0],"DD-MM-YYYY").format("DD-MM-YYYY")}\n s/d \n${moment(data.proposed_date.slice(-1),"DD-MM-YYYY").format("DD-MM-YYYY")}`}
                </td>
                <td>
                  {data.status == "submitted"? 
                    data.status_kurator[data.status_kurator.length - 1].status == "Revisi"?
                    "Revisi"
                    :data.status_kurator[data.status_kurator.length - 1].status == "Tidak Lolos Kurasi"?
                    "Tidak Lolos Kurasi"
                    :data.status_kurator[data.status_kurator.length - 1].status == "Lolos Kurasi"?
                    "Lolos Kurasi"
                    :data.status_kurator[data.status_kurator.length - 1].status == "Belum Kurasi"?
                    "Baru"
                    :"-"
                  :data.status == "scheduled"?
                  "Tayang"
                  :data.status == "declined"?
                  "Ditolak"
                  :"-"}
                </td>
                <td>
                  <img
                    src={
                      props.activeSubTab1 === "1"
                        ? `${process.env.REACT_APP_SELF_URL}/${data.details.thumbnail}`
                        : `${process.env.REACT_APP_SELF_URL}/${data.details.url}`
                    }
                    height="100"
                    style={{
                      objectFit: "cover",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}></img>
                </td>
              </tr>)
            })}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
