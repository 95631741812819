import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <>
      <Table
        style={{ display: "none" }}
        id="DownloadXLSSummary"
        className="DownloadTableXLS">
        <thead>Demografi Pengunjung Mengisi Survei, Judul Survei {props.title}</thead>
        <thead>
          <tr>
            <th>Pengunjung Mengisi Survey</th>
            <th>Pengunjung Laki-Laki</th>
            <th>Pengunjung Perempuan</th>
            <th>Pengunjung SD</th>
            <th>Pengunjung SMP Sederajat</th>
            <th>Pengunjung SMA Sederajat</th>
            <th>Pengunjung Pendidikan Tinggi Sederajat</th>
            <th>Pengunjung Berstatus Menikah</th>
            <th>Pengunjung Berstatus Lajang</th>
            <th>Usia 0-10 Tahun</th>
            <th>Usia 11-20 Tahun</th>
            <th>Usia 21-30 Tahun</th>
            <th>Usia 31-40 Tahun</th>
            <th>Usia 41-50 Tahun</th>
            <th>Usia 51-60 Tahun</th>
            <th>Usia 61-70 Tahun</th>
            <th>Usia 71-80 Tahun</th>
            <th>Usia 81-90 Tahun</th>
            <th>Usia Lebih dari 91 Tahun</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            Object.keys(data).length !== 0 && (
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>{data.count ?? "-"}</td>
                <td>{data.gender ? data.gender.pria ?? "-" : "-"}</td>
                <td>{data.gender ? data.gender.wanita ?? "-" : "-"}</td>
                <td>{data.education ? data.education.sd_sederajat ?? "-" : "-"}</td>
                <td>{data.education ? data.education.smp_sederajat ?? "-" : "-"}</td>
                <td>{data.education ? data.education.sma_sederajat ?? "-" : "-"}</td>
                <td>{data.education ? data.education.pendidikan_tinggi_sederajat ?? "-" : "-"}</td>
                <td>{data.status ? data.status.menikah ?? "-" : "-"}</td>
                <td>{data.status ? data.status.lajang ?? "-" : "-"}</td>
                <td>{data.age ? data.age["1"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["2"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["3"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["4"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["5"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["6"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["7"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["8"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["9"] ?? "-" : "-"}</td>
                <td>{data.age ? data.age["10"] ?? "-" : "-"}</td>
              </tr>
            )
          ) : (
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default DownloadXLS;
