import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const SiteAnalysisAsync = Loadable({
  loader: () =>
    import("../../../pages/SiteAnalysis/Admin" /* webpackChunkName: "SiteAnalysisAsync" */),
  loading: Loading,
});

export default SiteAnalysisAsync;
