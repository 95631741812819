import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  //   useEffect(() => {
  //     const handleProps = () => {};
  //     handleProps();
  //     return () => {
  //       handleProps();
  //     };
  //   }, [props.data]);
  let judul;
  switch (props.activeTab) {
    case "1":
      judul = "KONTEN REPORT VIDEO";
      break;
    case "2":
      judul = "KONTEN REPORT BANNER";
      break;
    case "3":
      judul = "KONTEN REPORT BERITA";
      break;
    default:
      break;
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`DATA ${judul} `}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Tanggal</th>
          <th>Total Impresi</th>
          <th>Total Klik</th>
          <th>Total Pengguna Unik</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
            <td>{data ? data.no ?? "-" : "-"}</td>
            <td>{data ? data.tanggal ?? "-" : "-"}</td>
            <td>{data.total_impresi ?? "-"}</td>
            <td>{data.total_klik ?? "-"}</td>
            <td>{data.total_pengguna_unik ?? "-"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
