import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);

    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);

  return (
    <Table
      style={{ display: "none", textAlign: "center" }}
      id="DownloadCardISP"
      className="DownloadTableXLS">
      <thead>{``}</thead>
      <thead>
        <tr>
          <th>ISP Terintegrasi</th>
          <th>IP Address Terintegrasi ICMS</th>
          <th>Lokasi Site AI Terintegrasi ICMS</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr>
                <td>{data ? data.isp ?? "-" : "-"}</td>
                <td>{data ? data.ip ?? "-" : "-"}</td>
                <td>{data ? data.lokasi ?? "-" : "-"}</td>
              </tr>
            </>
          ) : (
            <>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </>
          )
        ) : (
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
