import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const UpdateKontenRevisiContentPartnerAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/UpdateKontenRevisi/ContentPartner" /* webpackChunkName: "UpdateKontenRevisiContentPartnerAsync" */
    ),
  loading: Loading,
});

export default UpdateKontenRevisiContentPartnerAsync;
