import Loadable from "react-loadable";
import Loading from "../../components/Loading";

const CreateArtikelAsync = Loadable({
  loader: () =>
    import("../../pages/Artikel/CreateArticle" /* webpackChunkName: "CreateArtikelAsync" */),
  loading: Loading,
});

export default CreateArtikelAsync;
