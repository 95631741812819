import React, { useState, useMemo } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import Select from "react-select";

import Down from "../../assets/images/back.png";

export const FilterKuratorCategory = (props) => {
  const { filter, clicked } = props;
  const [isDisable, setIsDisable] = useState(false);
  const optionsKategori = [
    { value: "Kesehatan", label: "Kesehatan", isDisabled: isDisable },
    { value: "Pariwisata", label: "Pariwisata", isDisabled: isDisable },
    { value: "Edukasi", label: "Edukasi", isDisabled: isDisable },
    { value: "Berita", label: "Berita", isDisabled: isDisable },
    { value: "Keterampilan Non Teknis", label: "Keterampilan Non Teknis", isDisabled: isDisable },
    { value: "Sains & Teknologi", label: "Sains & Teknologi", isDisabled: isDisable },
    { value: "Olahraga", label: "Olahraga", isDisabled: isDisable },
    { value: "Komunitas", label: "Komunitas", isDisabled: isDisable },
    { value: "Agribisnis", label: "Agribisnis", isDisabled: isDisable },
    { value: "Kewirausahaan", label: "Kewirausahaan", isDisabled: isDisable },
  ];

  const customStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      background: "#F8F9FA",
      borderRadius: "20px",
      // This line disable the blue border
      boxShadow: "none",
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#2C3280",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#FFF",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      ":hover": {
        backgroundColor: data.color,
        color: "red",
      },
    }),
  };
  const handleKategori = (selectedKategori) => {
    // console.log("selectedKategori", selectedKategori);
    selectedKategori !== null
      ? selectedKategori.length >= 3
        ? setIsDisable(true)
        : setIsDisable(false)
      : setIsDisable(false);
    props.clicked(selectedKategori);
  };

  return (
    <div className="wrapperDropdownKurator">
      <Select
        styles={customStyle}
        classNamePrefix="select2-selection"
        className="dropdownKategori"
        placeholder="Pilih Kategori"
        options={optionsKategori}
        isMulti
        onChange={handleKategori}
      />
    </div>
  );
};
