import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
    // console.log(props.data);
  }, [props.data]);

  return (
    <>
      <Table style={{ display: "none" }} id="DownloadTableXLSCard" className="DownloadTableXLS">
        <thead>DASHBOARD SURVEY</thead>
        <thead>
          <tr>
            <th>Total Survey Dibuat</th>
            <th>Pengunjung Mengisi Survey</th>
            <th>Total Pertanyaan Survey</th>
          </tr>
        </thead>
        <tbody>
          {data && Object.keys(data).length !== 0 ? (
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{data.survey ?? "-"}</td>
              <td>{data.dfp ?? "-"}</td>
              <td>{data.question ?? "-"}</td>
            </tr>
          ) : (
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default DownloadXLS;
