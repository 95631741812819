import React from "react";
import { CardBody, Card, Row, Button, Col, Spinner } from "reactstrap";

// STYLE
import style from "./style.module.css";

// ASSETS
import manIcon from "../../assets/icon/manicon.png";
import womanIcon from "../../assets/icon/womanicon.png";

export default function CardGender(props) {
  return (
    <div className="mb-5">
      <Card className="p-3">
        <CardBody>
          <Row
            style={{
              paddingBottom: "15px",
              paddingTop: "15px",
            }}>
            <CardBody
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span className={style.titleCardBI}>JENIS KELAMIN</span>
              {/* <div className="float-right">
                <Button
                  style={{
                    backgroundColor: "#2C3280",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingBottom: "3px",
                    paddingTop: "3px",
                  }}
                  //   onClick={handleSubmitDataFilter}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <span>Lihat Detail Tabel</span>
                  </div>
                </Button>
              </div> */}
            </CardBody>
          </Row>
          <Row className="text-center my-3">
            <Col lg="6">
              <Row style={{ borderRight: "1px solid #C0C0C0" }}>
                <Col lg="4">
                  <div>
                    <img src={manIcon} />
                  </div>
                </Col>
                <Col lg="4">
                  <div className={style.titleMan}>LAKI - LAKI</div>
                  {props.loading ? (
                    <div className="my-3">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <div className={style.valueMan}>
                      {props.data
                        ? Object.values(props.data).length !== 0
                          ? props.data.gender
                            ? props.data.gender.pria.toLocaleString() ?? 0
                            : 0
                          : 0
                        : 0}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Row className="justify-content-end">
                <Col lg="4">
                  <div className={style.titleWoman}>PEREMPUAN</div>
                  {props.loading ? (
                    <div className="my-3">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <div className={style.valueWoman}>
                      {props.data
                        ? Object.values(props.data).length !== 0
                          ? props.data.gender
                            ? props.data.gender.wanita.toLocaleString() ?? 0
                            : 0
                          : 0
                        : 0}
                    </div>
                  )}
                </Col>
                <Col lg="4">
                  <div>
                    <img src={womanIcon} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
