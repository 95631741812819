import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState("");
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  console.log(props);

  return data ? (
    <Table
      style={{ display: "none" }}
      id="DownloadTableXLSSurveyStatistik"
      className="DownloadTableXLS">
      <thead>Statistik Hasil Survei {props.prov}</thead>
      <thead>
        <tr>
          <th>Pertanyaan</th>
          <th>Jawaban</th>
          <th>Total Pengguna Mengisi Survei</th>
        </tr>
      </thead>
      {data.length !== 0 && data[0].type == "essay" ? (
        <tbody>
          {data ? (
            data.length !== 0 ? (
              <>
                {data.map((e, i) => (
                  <tr key={i} style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{e.question}</td>
                    <td>
                      {e.type === "essay" && e.answer.length !== 0
                        ? e.answer.map((d) => {
                            return `${d}, `;
                          })
                        : "-"}
                      {/* {console.log("jwbn", e.answer)}
                        {console.log("jwbn", e.answer.length)} */}
                    </td>
                    <td>
                      {e.type === "essay"
                        ? e.answer.length
                        : Object.entries(e.answer).length !== 0
                        ? Object.entries(e.answer)
                            .filter(([d, v]) => {
                              return d !== "";
                            })
                            .reduce((acc, [d, v]) => acc + v, 0)
                        : "-"}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </>
            )
          ) : (
            <>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </>
          )}
        </tbody>
      ) : (
        <tbody>
          {data ? (
            data.length !== 0 ? (
              <>
                {data.map((e, i) => (
                  <tr key={i} style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{e.question}</td>
                    <td>
                      {Object.entries(e.answer).length !== 0
                        ? Object.entries(e.answer).map(([d, v]) => {
                            return d !== "" && `${d}: ${v}, `;
                          })
                        : "-"}
                    </td>
                    {/* <td>{Object.values(e.answer).reduce((acc, curr) => acc + curr, 0)}</td> */}
                    <td>
                      {Object.entries(e.answer).length !== 0
                        ? Object.entries(e.answer)
                            .filter(([d, v]) => {
                              return d !== "";
                            })
                            .reduce((acc, [d, v]) => acc + v, 0)
                        : "-"}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </>
            )
          ) : (
            <>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </>
          )}
        </tbody>
      )}
    </Table>
  ) : (
    ""
  );
};

export default DownloadXLS;
