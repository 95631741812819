import React, { useState, useMemo, useEffect } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from "../../assets/images/back.png";


export const FilterJumlahSurvei = (props) => {
  const { filter, clicked } = props;
  let listJumlahSurvey = useMemo(() => {
    return props.list;
  }, [props.list]);
  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle color="primary" className="dropdown-rekomendasi">
          <div className="wrapperDropdown">{filter === "" ? `Pilih ${props.type}` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus disabled browser-default">
          {listJumlahSurvey.map((fc, i) => (
            <MDBDropdownItem
              active={filter === fc}
              onClick={() => clicked(fc)}
              key={i}>
              {fc}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

export const FilterMaxPilihan = (props) => {
  const { filter, clicked, indexSurvei, typeSurvei } = props;
  useEffect(() =>{return}, [filter, clicked, indexSurvei, typeSurvei])
  let listMaxPilihan = useMemo(() => {
    return props.list;
  }, [props.list]);
  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle color="primary" className="dropdown-rekomendasi">
          <div className="wrapperDropdown">{filter === "" ? `Pilih ${props.type}` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus disabled browser-default">
          {listMaxPilihan.map((fc, i) => (
            <MDBDropdownItem
              active={filter === fc}
              onClick={() => clicked(fc, indexSurvei, typeSurvei)}
              key={i}>
              {fc}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};



