import React from "react";

import { ColumnChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import "../toastui.scss";

var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  xAxis: {
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#49ADAD", "#1E1BB7"],
  },
};

const LineChartToast = (props) => {
  const _width = window.screen.width / 2.6;

  const data = {
    categories: props.data
      ? props.data[7]
        ? props.data[7].statistikDfp.map((res) => {
            return res.date;
          })
        : [""]
      : [""],
    // categories: ["Tanggal 1", "Tanggal 1", "Tanggal 1"],
    series: [
      {
        name: "Jmlh Pengguna",
        data: props.data
          ? props.data[7]
            ? props.data[7].statistikDfp.map((res) => {
                return res.total;
              })
            : [0]
          : [0],
      },
    ],
  };

  // const options = {
  //   chart: {
  //     width: _width,
  //     height: 380,
  //   },
  //   yAxis: {
  //     title: "Jmlh Pengguna (Unique Device)",
  //     min: 1,
  //     max: props.data
  //       ? props.data[7] &&
  //         Math.max(
  //           ...props.data[7].statistikDfp.map((res) => {
  //             return res.total + 50;
  //           })
  //         )
  //       : 100,
  //     pointOnColumn: true,
  //   },
  //   xAxis: {
  //     title: "Waktu",
  //   },
  //   legend: {
  //     align: "bottom",
  //     // visible: false,
  //     showCheckbox: false,
  //   },
  //   chartExportMenu: {
  //     visible: false, // default is true.
  //   },
  //   series: {
  //     showDot: true,
  //   },
  // };

  const options = {
    chart: {
      width: _width,
      height: 380,
      // title: 'Jumlah',
      // format: "1,000",
    },
    yAxis: {
      title: "Jumlah",
      min: 1,
      max: props.data
        ? props.data[7] &&
          Math.max(
            ...props.data[7].statistikDfp.map((res) => {
              return res.total;
            })
          ) + 10
        : 100,
    },
    xAxis: {
      title: "Waktu",
    },
    legend: {
      align: "bottom",
      // visible: false,
      showCheckbox: false,
    },
    chartExportMenu: {
      visible: false, // default is true.
    },
    // series: {
    //   barWidth: 20,
    // },
  };
  // untuk apply theme
  TuiChart.registerTheme("homeAdminLineChart", theme);
  options.theme = "homeAdminLineChart";
  return (
    <React.Fragment>
      {props.data && props.data[7] ? (
        props.data[7].statistikDfp.length != 0 ? (
          <ColumnChart data={data} options={options} />
        ) : (
          "Tidak Ada Data"
        )
      ) : (
        "Tidak Ada Data"
      )}
    </React.Fragment>
  );
};
export default LineChartToast;
