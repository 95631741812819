import React, { useState, useEffect } from "react";
import { MDBModal } from "mdbreact";
import "./style.css";

// ------------------API-----------------------
import API from "../../../../../services";

const ModalActivedContent = ({
  modalActivedContent,
  toggleModalActivedContent,
  dataModalActivedContent,
  handleAlert,
  fetchData,
}) => {
  const onHandleSubmit = () => {
    API.postActivedContent("video")
      .then(async (res) => {
        // console.log("resss", res);
        if (res.data.success) {
          new Promise(() => {
            toggleModalActivedContent();
            handleAlert.setAlert("successActivedContent");
            setTimeout(() => {
              handleAlert.setAlert("");
            }, 2000);
          }, fetchData());
        } else {
          new Promise(() => {
            toggleModalActivedContent();
            handleAlert.setAlert("failedActivedContent");
            setTimeout(() => {
              handleAlert.setAlert("");
            }, 2000);
          }, fetchData());
        }
      })
      .catch((e) => {
        new Promise(() => {
          toggleModalActivedContent();
          handleAlert.setAlert("failedFetch");
          setTimeout(() => {
            handleAlert.setAlert("");
          }, 2000);
        }, fetchData());
      });
  };

  return (
    <MDBModal isOpen={modalActivedContent} toggle={toggleModalActivedContent} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleActivedContent">AKTIFKAN SURVEY DEFAULT</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalActivedContent}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitleActived">Nama Survey</h4>
          </div>

          <div className="w-60 pl-4 mx-auto wrapperVerification">
            <h4 className="subTitleActivedContent">{`Aktifkan survey ini sebagai survey default`}</h4>
          </div>

          <div className="mx-auto wrapperBtnVerification">
            <button
              type="button"
              onClick={onHandleSubmit}
              className="w-100 btn btnActivedContent btn-rounded">
              Aktifkan
            </button>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalActivedContent;
