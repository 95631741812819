import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const DetailKontenPreviewContentPartnerAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/DetailKontenPreview/ContentPartner" /* webpackChunkName: "DetailKontenPreviewContentPartnerAsync" */
    ),
  loading: Loading,
});

export default DetailKontenPreviewContentPartnerAsync;
