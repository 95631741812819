import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Skeleton from "react-loading-skeleton";

// STYLE
import "./style.scss";
import "./style.css";

// import API from "../../../../services";

const TableDFPBrand = (props) => {
  const [rows, setRows] = useState([]);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRows(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const data = {
    columns: [
      {
        label: "",
        field: "answers",
        sort: "disabled",
        // width: 200,
      },
    ],
    rows:
      rows.length === 0
        ? [
            {
              answers: "Tidak ada jawaban",
            },
          ]
        : rows.map((res) => ({ answers: res })),
  };

  return (
    <React.Fragment>
      <div style={{ height: "400px", overflowY: "auto", overflowX: "hidden" }}>
        {rows == null ? (
          <div> TERJADI KESALAHAN</div>
        ) : (
          <MDBDataTable
            responsive
            striped
            bordered
            searching={false}
            displayEntries={false}
            // info={false}
            entries={5} //How much data that you want to show in 1 table
            data={data}
            noBottomColumns={true}
            paginationLabel={["<", ">"]}
            infoLabel={["Menampilkan", "-", "dari", "data"]}
            pagesAmount={3}
            // paging={false}
            // paginationLabel={}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TableDFPBrand;
