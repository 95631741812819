import React, { useEffect, useState } from "react";
import Map from "../../../ContentPartner/Map/Home/MapsVector";
import {
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Col,
  CardBody,
  CardTitle,
  Card,
  Alert,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";

import API from "../../../../services";

export default function CardMap() {
  const [prov, setProv] = useState("DKI JAKARTA");
  const [listProv, setListProv] = useState([]);
  const [dataImpressi, setDataImpressi] = useState([
    { videoImpressi: 0, bannerImpressi: 0, newsImpressi: 0 },
  ]);
  const [loading, setLoading] = useState(true);
  const [idRegion, setIdRegion] = useState("ID_jk");

  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  const skeletonCostume = <Skeleton circle={true} height={80} width={80} />;

  const [idProvince, setIdProvince] = useState("5c073c0b2f1e2050e0c6bf64");

  const onclikRegion = (param) => {
    switch (param) {
      case "ID_pa":
        setProv("PAPUA");
        setIdProvince("5c08b0452f1e2050e0c6df14");
        break;
      case "ID_pb":
        setProv("PAPUA BARAT");
        setIdProvince("5c08ad1f2f1e2050e0c6de2c");
        break;
      case "ID_ma":
        setProv("MALUKU");
        setIdProvince("5c089dcd2f1e2050e0c6dc5f");
        break;
      case "ID_mu":
        setProv("MALUKU UTARA");
        setIdProvince("5c0897752f1e2050e0c6dc35");
        break;
      case "ID_nt":
        setProv("NUSA TENGGARA TIMUR");
        setIdProvince("5c08a8b82f1e2050e0c6dce1");
        break;
      case "ID_nb":
        setProv("NUSA TENGGARA BARAT");
        setIdProvince("5c04a81b21fb6a653aaab2a5");
        break;
      case "ID_sa":
        setProv("SULAWESI UTARA");
        setIdProvince("5c08dcf72f1e2050e0c6e55e");
        break;
      case "ID_st":
        setProv("SULAWESI TENGAH");
        setIdProvince("5c08c8322f1e2050e0c6e21b");
        break;
      case "ID_sr":
        setProv("SULAWESI BARAT");
        setIdProvince("5c08cd192f1e2050e0c6e2d9");
        break;
      case "ID_sn":
        setProv("SULAWESI SELATAN");
        setIdProvince("5c08cf2f2f1e2050e0c6e325");
        break;
      case "ID_sg":
        setProv("SULAWESI TENGGARA");
        setIdProvince("5c08d80e2f1e2050e0c6e471");
        break;
      case "ID_go":
        setProv("GORONTALO");
        setIdProvince("5c0748772f1e2050e0c6c0a9");
        break;
      case "ID_ba":
        setProv("BALI");
        setIdProvince("5c04de111143c12c8bdbc9c4");
        break;
      case "ID_jt":
        setProv("JAWA TENGAH");
        setIdProvince("5c079ff62f1e2050e0c6cd4c");
        break;
      case "ID_jl":
        setProv("JAWA TIMUR");
        setIdProvince("5c07d086aea8f971c78f62d6");
        break;
      case "ID_jb":
        setProv("JAWA BARAT");
        setIdProvince("5c077c892f1e2050e0c6c50f");
        break;
      case "ID_yo":
        setProv("DI YOGYAKARTA");
        setIdProvince("5c065e5a2f1e2050e0c6bd55");
        break;
      case "ID_jk":
        setProv("DKI JAKARTA");
        setIdProvince("5c073c0b2f1e2050e0c6bf64");
        break;
      case "ID_bt":
        setProv("BANTEN");
        setIdProvince("5c06068b2f1e2050e0c6b038");
        break;
      case "ID_ki":
        setProv("KALIMANTAN TIMUR");
        setIdProvince("5c0888a62f1e2050e0c6da0f");
        break;
      case "ID_ku":
        setProv("KALIMANTAN UTARA");
        setIdProvince("5c088ac22f1e2050e0c6da81");
        break;
      case "ID_kb":
        setProv("KALIMANTAN BARAT");
        setIdProvince("5c07d0af2f1e2050e0c6d814");
        break;
      case "ID_kt":
        setProv("KALIMANTAN TENGAH");
        setIdProvince("5c0885d32f1e2050e0c6d978");
        break;
      case "ID_ks":
        setProv("KALIMANTAN SELATAN");
        setIdProvince("5c0882342f1e2050e0c6d8d1");
        break;
      case "ID_bb":
        setProv("KEPULAUAN BANGKA BELITUNG");
        setIdProvince("5c088d0d2f1e2050e0c6dabc");
        break;
      case "ID_be":
        setProv("BENGKULU");
        setIdProvince("5c0637062f1e2050e0c6b6e1");
        break;
      case "ID_la":
        setProv("LAMPUNG");
        setIdProvince("5c088faa2f1e2050e0c6db41");
        break;
      case "ID_ss":
        setProv("SUMATERA SELATAN");
        setIdProvince("5c08dd62aea8f971c78f66f4");
        break;
      case "ID_sb":
        setProv("SUMATERA BARAT");
        setIdProvince("5c08d5e6aea8f971c78f662c");
        break;
      case "ID_su":
        setProv("SUMATERA UTARA");
        setIdProvince("5c08e3e72f1e2050e0c6e619");
        break;
      case "ID_ja":
        setProv("JAMBI");
        setIdProvince("5c075a6c2f1e2050e0c6c3d9");
        break;
      case "ID_ri":
        setProv("RIAU");
        setIdProvince("5c08c3c92f1e2050e0c6e162");
        break;
      case "ID_kr":
        setProv("KEPULAUAN RIAU");
        setIdProvince("5c088e5c2f1e2050e0c6daf3");
        break;
      case "ID_ac":
        setProv("ACEH");
        setIdProvince("5c048b737fddb13f493b0cbc");
        break;

      default:
        break;
    }
    setIdRegion(param);
  };

  const selectedDropdownProv = (param, idProv) => {
    setIdProvince(idProv);
    switch (param) {
      case "PAPUA":
        setIdRegion("ID_pa");
        break;
      case "PAPUA BARAT":
        setIdRegion("ID_pb");
        break;
      case "MALUKU":
        setIdRegion("ID_ma");
        break;
      case "MALUKU UTARA":
        setIdRegion("ID_mu");
        break;
      case "NUSA TENGGARA TIMUR":
        setIdRegion("ID_nt");
        break;
      case "NUSA TENGGARA BARAT":
        setIdRegion("ID_nb");
        break;
      case "SULAWESI UTARA":
        setIdRegion("ID_sa");
        break;
      case "SULAWESI TENGAH":
        setIdRegion("ID_st");
        break;
      case "SULAWESI BARAT":
        setIdRegion("ID_sr");
        break;
      case "SULAWESI SELATAN":
        setIdRegion("ID_sn");
        break;
      case "SULAWESI TENGGARA":
        setIdRegion("ID_sg");
        break;
      case "GORONTALO":
        setIdRegion("ID_go");
        break;
      case "BALI":
        setIdRegion("ID_ba");
        break;
      case "JAWA TENGAH":
        setIdRegion("ID_jt");
        break;
      case "JAWA TIMUR":
        setIdRegion("ID_jl");
        break;
      case "JAWA BARAT":
        setIdRegion("ID_jb");
        break;
      case "DI YOGYAKARTA":
        setIdRegion("ID_yo");
        break;
      case "DKI JAKARTA":
        setIdRegion("ID_jk");
        break;
      case "BANTEN":
        setIdRegion("ID_bt");
        break;
      case "KALIMANTAN TIMUR":
        setIdRegion("ID_ki");
        break;
      case "KALIMANTAN UTARA":
        setIdRegion("ID_ku");
        break;
      case "KALIMANTAN BARAT":
        setIdRegion("ID_kb");
        break;
      case "KALIMANTAN TENGAH":
        setIdRegion("ID_kt");
        break;
      case "KALIMANTAN SELATAN":
        setIdRegion("ID_ks");
        break;
      case "KEPULAUAN BANGKA BELITUNG":
        setIdRegion("ID_bb");
        break;
      case "BENGKULU":
        setIdRegion("ID_be");
        break;
      case "LAMPUNG":
        setIdRegion("ID_la");
        break;
      case "SUMATERA SELATAN":
        setIdRegion("ID_ss");
        break;
      case "SUMATERA BARAT":
        setIdRegion("ID_sb");
        break;
      case "SUMATERA UTARA":
        setIdRegion("ID_su");
        break;
      case "JAMBI":
        setIdRegion("ID_ja");
        break;
      case "RIAU":
        setIdRegion("ID_ri");
        break;
      case "KEPULAUAN RIAU":
        setIdRegion("ID_kr");
        break;
      case "ACEH":
        setIdRegion("ID_ac");
        break;

      default:
        break;
    }
  };

  const getStatisticAdminProvince = () => {
    const param = {
      province: idProvince,
    };
    setLoading(true);
    API.postStatisticsHomeProvince(param)
      .then((res) => {
        setDataImpressi(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        setDataImpressi([]);
      });
  };

  const getLisProvinces = () => {
    API.getAllProvince()
      .then((res) => {
        setListProv(res.data.data);
      })
      .catch((err) => setListProv([]));
  };

  useEffect(() => {
    getStatisticAdminProvince();
    getLisProvinces();
  }, [prov, idProvince]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const dropdownProvinces = () => {
    return (
      <Dropdown className="dropdown-provinsi-select" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          style={{
            minWidth: "100%",
            backgroundColor: "white",
            borderRadius: "25px",
            borderColor: "#C42127",
            borderWidth: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}>
          <div
            style={{
              color: "#C42127",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              fontSize: "15px",
            }}>
            <span>{prov}</span>
            <i className="mdi mdi-24px mdi-chevron-down" style={{ color: "#C42127" }} />
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="mx-auto"
          style={{ maxHeight: "300px", width: "100%", overflowY: "auto", borderRadius: "15px" }}>
          {listProv.length !== 0
            ? listProv.map((a, i) => (
              <DropdownItem key={i}>
                <div
                  className="text-center"
                  onClick={() => {
                    setProv(a.name);
                    selectedDropdownProv(a.name, a._id);
                  }}>
                  <span style={{ color: "#C42127", fontSize: "15px" }}> {a.name}</span>
                </div>
              </DropdownItem>
            ))
            : listProv.map((a) => (
              <DropdownItem>
                <div className="text-center">
                  <span style={{ color: "#C42127", fontSize: "15px" }}>No Data</span>
                </div>
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <div className="mb-5">
      <Container>
        {error && (
          <Alert color="danger" isOpen={visible} toggle={onDismiss} className="text-center">
            Terjadi Kesalahan Pada Server
          </Alert>
        )}
      </Container>
      <Row className="d-flex justify-content-center mb-3">
        <Col xs="12" md="8" lg="4">
          {dropdownProvinces()}
        </Col>
      </Row>
      <div className="p-2 mx-3 border" style={{ borderRadius: "13px" }}>
        <Row className="d-flex justify-content-end">
          <Col lg="5">
            <Card
              className="p-1"
              style={{
                zIndex: "99",
                position: "absolute",
                right: "5%",
                width: "70%",
                border: "1.5px solid",
                borderRadius: "15px",
                borderColor: "#2C3280",
                // opacity:'0.5'
              }}>
              <CardBody>
                <CardTitle className="text-center">TOTAL IMPRESI</CardTitle>
                <Row>
                  <Col md="12" lg="7" className="title-map" style={{ color: "#8CC6C9" }}>
                    Video
                  </Col>
                  <Col id="wrapper-impressVid-prov" md="12" lg="5" className="value-map">
                    {loading ? (
                      <div>
                        <Skeleton />
                      </div>
                    ) : dataImpressi ? (
                      dataImpressi?.video?.toLocaleString() ?? 0
                    ) : (
                          0
                        )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="7" className="title-map" style={{ color: "#E2B674" }}>
                    Banner
                  </Col>
                  <Col id="wrapper-impressBanner-prov" md="12" lg="5" className="value-map">
                    {loading ? (
                      <div>
                        <Skeleton />
                      </div>
                    ) : dataImpressi ? (
                      dataImpressi?.banner?.toLocaleString() ?? 0
                    ) : (
                          0
                        )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="7" className="title-map" style={{ color: "#C18ECE" }}>
                    Berita
                  </Col>
                  <Col id="wrapper-impressNews-prov" md="12" lg="5" className="value-map">
                    {loading ? (
                      <div>
                        <Skeleton />
                      </div>
                    ) : dataImpressi ? (
                      dataImpressi?.news?.toLocaleString() ?? 0
                    ) : (
                          0
                        )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* MAP VECTOR */}
        <div style={{ zIndex: "98" }}>
          <Map onclikRegion={onclikRegion} idRegion={idRegion} />
        </div>
      </div>

      <Container fluid>
        <Row className="mt-5 mb-5">
          <Col md="6">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp my-5">
                <div className="title-card">JUMLAH LOKASI TITIK AI TERINTEGRASI</div>
                <div id="wrapper-totalIntegrated-prov" className="value-admin-impress-map my-5 mx-auto" style={{ width: "100px" }}>
                  {loading ? skeletonCostume : dataImpressi ? dataImpressi.integrated_ai?.toLocaleString() ?? 0 : 0}
                </div>
              </div>
            </Row>
          </Col>
          <Col md="6">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner-cp my-5">
                <div className="title-card">JUMLAH KUNJUNGAN</div>
                <div id="wrapper-totalVisit-prov" className="value-admin-impress-map my-5 mx-auto" style={{ width: "100px" }}>
                  {loading ? skeletonCostume : dataImpressi ? dataImpressi.visit_count?.toLocaleString() ?? 0 : 0}
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
        <Row>
          <h3 className="title-card m-4" style={{ textAlign: "start" }}>
            TRAFFIC KONTEN PER PROVINSI
          </h3>
        </Row>
        <Row className="mb-5" style={{ fontSize: "15px" }}>
          <Col>
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp my-5">
                <div className="title-admin-impress-map">Total Impresi</div>
                <div id="wrapper-totalImpress-prov" className="value-admin-impress-map my-5 mx-auto" style={{ width: "100px" }}>
                  {loading ? skeletonCostume : dataImpressi ? dataImpressi.total_impress?.toLocaleString() ?? 0 : 0}
                </div>
              </div>
            </Row>
          </Col>
          <Col>
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp my-5">
                <div className="title-admin-impress-map">Total Klik</div>
                <div id="wrapper-totalClicked-prov" className="value-admin-impress-map my-5 mx-auto" style={{ width: "100px" }}>
                  {loading ? skeletonCostume : dataImpressi ? dataImpressi.total_lick?.toLocaleString() ?? 0 : 0}
                </div>
              </div>
            </Row>
          </Col>
          <Col>
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner-cp my-5">
                <div className="title-admin-impress-map">Jumlah Pengguna Unik</div>
                <div id="wrapper-totalDfp-prov" className="value-admin-impress-map my-5 mx-auto" style={{ width: "100px" }}>
                  {loading ? skeletonCostume : dataImpressi ? dataImpressi.dfp_unique?.toLocaleString() ?? 0 : 0}
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
