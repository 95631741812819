// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_wrapperTab__1WtYJ {\n    display: flex;\n    margin-bottom: -50px !important;\n  }", ""]);
// Exports
exports.locals = {
	"wrapperTab": "style_wrapperTab__1WtYJ"
};
module.exports = exports;
