import React from 'react'
import styles from '../Assets/css/homepages.module.css'
import '../Assets/css/mdbstyle.css'
import HomeBanner from '../components/Banner/HomeBanner'
import Header from '../components/Header/Header'
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    NavLink,
} from "reactstrap";
import {
    MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage,
    MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn
} from "mdbreact";

import AI from '../Assets/image/IconAI.svg'
import BTS from '../Assets/image/IconBTS.svg'
import Band from '../Assets/image/IconBand.svg'
import Relok from '../Assets/image/IconRelok.svg'
import CP from '../Assets/image/CPIcon.svg'

import dummy from './Artikel/dummy.json'

function Homepages() {
    const article = []
    for(let i of dummy.data){
        article.push(
            <MDBCol key={i.id} md="4">
                <MDBCard className={styles.cardsec2}>
                    <MDBCardImage className={styles.imageThumbnail} src={i.image} />
                    <MDBCardBody>
                        <MDBCardTitle className={styles.cardTitle}>{i.judul}</MDBCardTitle>
                        <MDBCardText className={styles.cardSubtitle}>
                            {i.content}
                        </MDBCardText>
                        <Link key={i.id} to={`/artikel/${i.id}`} style={{padding:'0px'}}>  
                            <MDBBtn color="primary" style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>Baca Lebih</MDBBtn>
                        </Link> 
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        )
    }
    return (
        <div style={{backgroundColor:"white"}}>
            <div>
                <Header />
                <HomeBanner/>
                <div className={styles.lastSec}>

                    {/* section 1 */}
                    <section style={{paddingBottom: "50px"}}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className={styles.secTit}>Siapa itu BAKTI Online?</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={styles.singleText} style={{paddingLeft: "10%", paddingRight: "10%"}}>BAKTI Online adalah suatu perpanjangan program dari BAKTI Kominfo yang koridor fokusnya adalah pembangunan infrastruktur komunikasi di seluruh pelosok atau daerah 3T (Terluar, Tertinggal, Terdepan) di Indonesia. Sementara BAKTI Kominfo bertugas pada penyediaan internet, BAKTI Online menunjang upaya tersebut dengan produk “Landing Page”-nya untuk penyediaan konten-konten positif bagi Masyarakat 3T. Singkatnya, BAKTI Online hadir untuk mewadahi daya maya Masyarakat 3T yang lebih sehat dan berkualitas.</div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* section 2 */}
                    <section style={{ marginTop: '0px'}}>
                        <MDBContainer className={styles.sectBorder} style={{paddingTop:"50px"}}>
                            <Row style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <center className={styles.secTit}>Artikel Kami</center>
                            </Row>
                            <MDBCarousel className="z-depth-1" activeItem={1} length={1} slide={true} showControls={false} showIndicators={true} multiItem>
                                <MDBCarouselInner>
                                    <MDBRow style={{paddingLeft:'15px', paddingRight:'15px'}}>
                                        <MDBCarouselItem itemId="1">
                                            <MDBRow className={styles.sec2Row}>
                                                {article}
                                            </MDBRow>
                                        </MDBCarouselItem>
                                    </MDBRow>
                                </MDBCarouselInner>
                            </MDBCarousel>
                        </MDBContainer>
                    </section>

                    {/* section 3a */}
                    <section style={{backgroundColor: "#F8F8F8", paddingTop:"50px", paddingBottom: "50px"}}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className={styles.secTit}>#BersamaBAKTI Kita Jadi #Pahlawan Konten Positif</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={styles.singleText} style={{paddingLeft: "10%", paddingRight: "10%"}}>{"#BersamaBAKTI Online Kita Dukung daya maya Masyarakat 3T yang sehat dan berkualitas sehingga meningkatkan kualitas SDM Masyarakat 3T. Dengan aktif membuat, mengkurasi, dan membagikan konten positif, kita sudah berpartisipasi langsung dalam menciptakan iklim internet sehat serta otomatis menjadi #PahlawanKonten Positif bagi Indonesia, dari Indonesia."}</div>
                                </Col>
                            </Row>

                            <Row style={{marginTop:"50px"}}>
                                <Col xs="6" md="3" className={styles.featureCol}>
                                    <img className={styles.iconImageHubungi} src={AI} alt="AI" />
                                    {/* <div className={styles.featitle}>Feature 01</div> */}
                                    <div className={styles.feasub}>“Tol Langit” wujudkan pemerataan pembangunan akses telekomunikasi dan informasi di seluruh Indonesia sejak 2019</div>
                                </Col>
                                <Col xs="6" md="3" className={styles.featureCol}>
                                    <img className={styles.iconImageHubungi} src={BTS} alt="BTS" />
                                    {/* <div className={styles.featitle}>Feature 02</div> */}
                                    <div className={styles.feasub}>11 Ribu Desa 3T di Indonesia sudah Terselimuti Jaringan 4G per 2020</div>
                                </Col>
                                <Col xs="6" md="3" className={styles.featureCol}>
                                    <img className={styles.iconImageHubungi} src={Relok} alt="Relok" />
                                    {/* <div className={styles.featitle}>Feature 03</div> */}
                                    <div className={styles.feasub}>Per Agustus 2020, sudah 1 Juta Device di Daerah 3T yang terkoneksi internet BAKTI </div>
                                </Col>
                                <Col xs="6" md="3" className={styles.featureCol}>
                                    <img className={styles.iconImageHubungi} src={Band} alt="Band" />
                                    {/* <div className={styles.featitle}>Feature 04</div> */}
                                    <div className={styles.feasub}>BAKTI Fasilitasi Penyediaan Aplikasi dan Situs Web yang mendorong peran penerjemah guna menjembatani komunikasi Pemerintah. </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* section 3b */}
                    <section style={{marginTop:"50px"}}>
                        <Container style={{paddingTop: "25px"}}>
                            <Row>
                                <Col>
                                    <div className={styles.secTit}>Yuk, Ikutan Gabung Menjadi #DonaturKonten Positif!</div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: "50px", paddingBottom: "50px"}}>
                                <Col xs="12" md="3" className={styles.cpCol}>
                                <img className={styles.iconImageHubungi} src={CP} alt="CP" />
                                </Col>
                                <Col xs="12" md="5" className={styles.cpCol}>
                                    <div className={styles.cpSub}>Dengan bergabung sebagai Content Partner, otomatis kamu sudah berpartisipasi dalam upaya mencerdaskan dan meningkatkan kualitas Sumber Daya Manusia di daerah pelosok 3T. Donasi konten-konten positif kamu adalah peluru informasi yang kekuatannya bisa merubah hidup masyarakat pelosok bahkan masa depan Negara kelak. Bersama kita wujudkan Indonesia yang bebas kesenjangan digital dan ciptakan iklim komunikasi digital  yang positif.</div>
                                </Col>
                                <Col xs="12" md="4" className={styles.cpCol}>
                                    <NavLink tag={Link} to="/register-cp">    
                                        <Button color="" className={styles.buttonCPDaftar}>Daftar Sebagai Content Partner</Button>    
                                    </NavLink>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* section 4 */}
                    <section style={{ marginTop: '0px'}}>
                        <Container className={styles.sectBorder} style={{paddingTop:"50px"}}>
                            <Row>
                                <Col>
                                    <div className={styles.secTit}>Bantuan & FAQ</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={styles.singleText} style={{paddingLeft: "10%", paddingRight: "10%"}}>Pelajari pendayagunaan Platform BAKTI Online di sini.</div>
                                </Col>
                            </Row>
                            <Row style={{marginTop:"30px"}}>
                                <Col xs="12" md={{size: 6, offset: 3}} className={styles.cpCol}>
                                    <a style={{width:'100%'}} href="http://202.182.55.252/faq/">
                                        <Button className={styles.buttonCPMasuk}>FAQ</Button>
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section style={{height:'100px', paddingTop: '50px'}}>
                        <Container className={styles.sectBorder}>
                        <div style={{marginTop:'10px', color:'#00438B', textAlign:'left'}}>Copyright 2020 All Rights Reserved</div>
                        </Container>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Homepages