import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import { MDBModal } from "mdbreact";
// ------------------API-----------------------
import API from "../../../../services";
import ReplyMessage from "../../../../components/SupportCenter/Popup/ReplyMessage/ReplyMessage";
const ModalDetailPertanyaan = ({
  modalDetailPertanyaan,
  toggleModalDetailPertanyaan,
  dataModalDetailPertanyaan,
  handleAlert,
  fetchData,
}) => {
  const [comment, setComment] = useState("");
  const [detailTicket, setDetailTicket] = useState({});
  const getDataDetailPertanyaan = () => {
    setDetailTicket(dataModalDetailPertanyaan ? dataModalDetailPertanyaan.data : "");
  };
  useEffect(() => {
    if (Object.keys(dataModalDetailPertanyaan).length !== 0) getDataDetailPertanyaan();
  }, [dataModalDetailPertanyaan]);
  return (
    <MDBModal isOpen={modalDetailPertanyaan} toggle={toggleModalDetailPertanyaan} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleDelete">DETAIL PERTANYAAN ISSUE</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalDetailPertanyaan}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column  align-items-center">
          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleDecline">
              Pertanyaan :{" "}
              {dataModalDetailPertanyaan
                ? dataModalDetailPertanyaan.data
                  ? dataModalDetailPertanyaan.data.message
                  : "-"
                : "-"}
            </h4>
          </div>
        </div>
        {dataModalDetailPertanyaan ? (
          dataModalDetailPertanyaan.data ? (
            dataModalDetailPertanyaan.data.type === "Message" ||
            dataModalDetailPertanyaan.data.type === "Phone" ? (
              <div className="modal-body d-flex flex-column  align-items-center">
                <div className="w-40 mx-auto wrapperVerification mb-1">
                  <h4 className="subTitleDecline">
                    Jawaban : {dataModalDetailPertanyaan.data.closed.message}
                  </h4>
                </div>
              </div>
            ) : (
              "-"
            )
          ) : (
            "-"
          )
        ) : (
          "-"
        )}
        {dataModalDetailPertanyaan ? (
          dataModalDetailPertanyaan.data ? (
            dataModalDetailPertanyaan.data.type !== "Message" &&
            dataModalDetailPertanyaan.data.type !== "Phone" ? (
              <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
                <div className="w-40 mx-auto wrapperVerification">
                  <Row>
                    <Container className="textBoxReply">
                      <ReplyMessage isRefresh={true} data={detailTicket} />
                    </Container>
                  </Row>
                </div>
              </div>
            ) : (
              "-"
            )
          ) : (
            "-"
          )
        ) : (
          "-"
        )}
      </div>
    </MDBModal>
  );
};

export default ModalDetailPertanyaan;
