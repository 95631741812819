import Loadable from "react-loadable";
import Loading from "../../components/Loading";

const BuatSurveyDefaultAsync = Loadable({
  loader: () =>
    import("../../pages/BuatSurveyDefault" /* webpackChunkName: "BuatSurveyDefaultAsync" */),
  loading: Loading,
});

export default BuatSurveyDefaultAsync;
