import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Row, Card, Col, Button, Spinner, CardBody } from "reactstrap";
import Skeleton from "react-loading-skeleton";

// import "tui-chart/dist/tui-chart.css";
import { PieChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "../../style.module.css";
import "../../toastui.scss";

// import API from "../../../../services";

var theme = {
  // series: {
  series: {
    colors: [
      "#4E72BE",
      "#F0908E",
      "#91A7D5",
      "#ced591",
      "#EBB389",
      "#D591D2",
      "#91d5ce",
      "#c22525",
      "#f0f06c",
      "#ebc51e",
    ],
    label: {
      color: "#fff",
      fontFamily: "sans-serif",
    },
  },
  // },
};

export default function CardChart(props) {
  const DonutChartToast = (param, title, type) => {
    const data = {
      categories: [title],
      series:
        type === "status"
          ? [
              {
                name: "Lajang",
                data: param ? (param.status ? param.status.lajang ?? 0 : 0) : 0,
                // data: 20,
              },
              {
                name: "Menikah",
                data: param ? (param.status ? param.status.menikah ?? 0 : 0) : 0,
                // data: 20,
              },
            ]
          : [
              {
                name: "SD Sederajat",
                data: param ? (param.education ? param.education.sd_sederajat ?? 0 : 0) : 0,
                // data: 20,
              },
              {
                name: "SMP Sederajat",
                data: param ? (param.education ? param.education.smp_sederajat ?? 0 : 0) : 0,
                // data: 20,
              },
              {
                name: "SMA Sederajat",
                data: param ? (param.education ? param.education.sma_sederajat ?? 0 : 0) : 0,
                // data: 20,
              },
              {
                name: "Pendidikan Tinggi",
                data: param
                  ? param.education
                    ? param.education.pendidikan_tinggi_sederajat ?? 0
                    : 0
                  : 0,
                // data: 20,
              },
            ],
    };

    const options = {
      chart: {
        width: 300,
        height: 350,
        format: function (value, chartType, areaType, valuetype, legendName) {
          if (areaType === "makingSeriesLabel") {
            // formatting at series area
            value = value;
          }

          return value;
        },
      },
      series: {
        radiusRange: ["40%", "100%"],
        showLabel: true,
        labelAlign: "outer",
        labelFilter: function (labelInfo) {
          return labelInfo.ratio > 0.3; // Less than 5% does not expose.
        },
      },
      tooltip: {
        suffix: "",
      },
      legend: {
        align: "bottom",
        // visible: false,
        showCheckbox: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename:
          type === "status" ? "Chart_Status_Perkawinan_Survey" : "Chart_Jenjang_Pendidikan_Survey",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("surveyPieTheme", theme);
    options.theme = "surveyPieTheme";

    return (
      <React.Fragment>
        <PieChart data={data} options={options} theme={theme} />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Row className="mt-5 mb-5" style={{ height: "500px" }}>
        <Col md="12" lg="6">
          <Card body style={{ height: "100%" }}>
            <div className="p-3">
              <Row
                style={{
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}>
                <CardBody
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span className={style.titleCardBI}>JENJANG PENDIDIKAN</span>
                  <div className="float-right">
                    {/* {props.loading ? (
                      ""
                    ) : (
                      <div className="float-right">
                        <Button
                          style={{
                            backgroundColor: "#2C3280",
                            borderRadius: "20px",
                            borderColor: "#2C3280",
                            paddingBottom: "3px",
                            paddingTop: "3px",
                          }}
                          //   onClick={handleSubmitDataFilter}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}>
                            <span>Lihat Detail Tabel</span>
                          </div>
                        </Button>
                      </div>
                    )} */}
                  </div>
                </CardBody>
              </Row>
              <Row>
                <div className="mt-1 mx-auto">
                  <div className="d-flex justify-content-center align-items-center">
                    {props.loading ? (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <Spinner color="primary" />
                      </div>
                    ) : props.data && props.data.length !== 0 ? (
                      DonutChartToast(props.data, "Jenjang Pendidikan", "pendidikan")
                      // ""
                    ) : (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md="12" lg="6">
          <Card body style={{ height: "100%" }}>
            <div className="p-3">
              <Row
                style={{
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}>
                <CardBody
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span className={style.titleCardBI}>STATUS PERKAWINAN</span>
                  <div className="float-right"></div>
                </CardBody>
              </Row>
              <Row>
                <div className="mt-1 mx-auto">
                  <div className="d-flex justify-content-center align-items-center">
                    {props.loading ? (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <Spinner color="primary" />
                      </div>
                    ) : props.data ? (
                      DonutChartToast(props.data, "Status Perkawinan", "status")
                      // ""
                    ) : (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
