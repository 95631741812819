import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Col,
} from "reactstrap";

import ImgTerbaru from "../../../assets/images/terbaru.png";
import ImgPopuler from "../../../assets/images/populer.png";
import ImgBaca from "../../../assets/images/banyak-baca.png";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PostingPopupInfo from "../Popup/Article/PostingPopupInfo";
import BacaPopupInfo from "../Popup/Article/BacaPopupInfo";
import StatusPopupInfo from "../Popup/Article/StatusPopupInfo";
import DeletePopupInfo from "../Popup/Article/DeletePopupInfo";

export default function InfoArticle(props) {
  const history = useHistory();
  const { data, loading } = props;

  const [modalBaca, setModalBaca] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalPosting, setModalPosting] = useState(false);
  const [toData, setToData] = useState([]);
  let allData = data.data;
  let role = window.localStorage.getItem("role");

  let newsData = allData ? (allData.newest ? (allData.newest ? allData.newest : "") : "") : "";
  let newsTitle = allData
    ? allData.newest
      ? allData.newest
        ? allData.newest.judul
        : ""
      : ""
    : "";
  let newsDate = allData
    ? allData.newest
      ? allData.newest
        ? allData.newest.tanggal_pembuatan
        : ""
      : ""
    : "";
  let urlNews = allData
    ? allData.newest
      ? allData.newest
        ? allData.newest.details
          ? allData.newest.details.image
          : ""
        : ""
      : ""
    : "";
  let monthNews = moment(newsDate).format("MMMM");
  let dayNews = moment(newsDate).format("DD");
  let yearsNews = moment(newsDate).format("YYYY");
  let imgNews = `${process.env.REACT_APP_BE_URL + "/" + urlNews}`;

  let populerData = allData
    ? allData.popular
      ? allData.popular
        ? allData.popular
        : ""
      : ""
    : "";
  let populerTitle = allData
    ? allData.popular
      ? allData.popular
        ? allData.popular.judul
        : ""
      : ""
    : "";
  let populerDate = allData
    ? allData.popular
      ? allData.popular
        ? allData.popular.tanggal_pembuatan
        : ""
      : ""
    : "";
  let urlPopuler = allData
    ? allData.popular
      ? allData.popular
        ? allData.popular.details
          ? allData.popular.details.image
          : ""
        : ""
      : ""
    : "";
  let monthPopuler = moment(populerDate).format("MMMM");
  let dayPopuler = moment(populerDate).format("DD");
  let yearsPopuler = moment(populerDate).format("YYYY");
  let imgPopuler = `${process.env.REACT_APP_BE_URL + "/" + urlPopuler}`;

  let mostReadData = allData
    ? allData.mostRead
      ? allData.mostRead
        ? allData.mostRead
        : ""
      : ""
    : "";
  let mostReadTitle = allData
    ? allData.mostRead
      ? allData.mostRead
        ? allData.mostRead.judul
        : ""
      : ""
    : "";
  let mostReadDate = allData
    ? allData.mostRead
      ? allData.mostRead
        ? allData.mostRead.tanggal_pembuatan
        : ""
      : ""
    : "";
  let urlmostRead = allData
    ? allData.mostRead
      ? allData.mostRead
        ? allData.mostRead.details
          ? allData.mostRead.details.image
          : ""
        : ""
      : ""
    : "";
  let monthMostRead = moment(mostReadDate).format("MMMM");
  let dayMostRead = moment(mostReadDate).format("DD");
  let yearsMostRead = moment(mostReadDate).format("YYYY");
  let imgMostRead = `${process.env.REACT_APP_BE_URL + "/" + urlmostRead}`;

  const toggleBaca = (val) => {
    setToData(val);
    setModalBaca(!modalBaca);
  };

  const togglePosting = (val) => {
    setToData(val);
    setModalPosting(!modalPosting);
  };

  const toggleStatus = (val) => {
    setToData(val);
    setModalStatus(!modalStatus);
  };

  const toggleDelete = (val) => {
    setToData(val);
    setModalDelete(!modalDelete);
  };

  const handleEdit = (val) => {
    // roleFromRouter === "adminartikel"
    role === "Artikel Admin"
      ? history.push({
          pathname: "/adminartikel/edit-article",
          state: val,
        })
      : history.push({
          pathname: "/admin/edit-article",
          state: val,
        });

    // history.push({
    //   pathname: "/admin/edit-article",
    //   state: val
    // })
  };

  const typeContent = props.typeContent
    ? props.typeContent.charAt(0).toUpperCase() + props.typeContent.slice(1)
    : "Artikel";

  const textBaca = typeContent === "Video" ? "Lihat" : "Baca";

  return (
    <div>
      <Container>
        {loading == true ? (
          <div className="mt-2 mb-2">
            <Skeleton height={100} />{" "}
          </div>
        ) : (
          <Row xs="3">
            <Col lg="4" md="12" sm="12" xs="12">
              <Card>
                <CardBody className="card-info-article">
                  <div className="pt-2">
                    <p>Terbaru</p>
                  </div>
                  <CardImg
                    top
                    width="100%"
                    src={imgNews}
                    className="thumbnail-img"
                    alt="Card image cap"
                  />
                  <div className="wrap-content-info-articel">
                    <div className="title-info-article container-title">
                      <p>{newsTitle}</p>
                    </div>
                    <div className="date-info-articel">
                      <p>{role + "," + " " + dayNews + " " + monthNews + " " + yearsNews}</p>
                    </div>
                  </div>
                  <Row xs="3" className="mb-3">
                    <Col>
                      {newsData.status === "Non Aktif" ? (
                        <Button
                          onClick={() => togglePosting(newsData)}
                          style={{ backgroundColor: "#2C3280", width: "100%" }}>
                          Posting
                        </Button>
                      ) : (
                        <Button
                          onClick={() => toggleBaca(newsData)}
                          style={{ backgroundColor: "#55BA59", width: "100%" }}>
                          {textBaca}
                        </Button>
                      )}
                    </Col>
                    <Col>
                      <Button
                        onClick={() => handleEdit(newsData)}
                        style={{ backgroundColor: "#E5BA4E", width: "100%" }}>
                        Edit
                      </Button>{" "}
                    </Col>
                    <Col>
                      {newsData.status === "Aktif" ? (
                        <Button
                          onClick={() => toggleStatus(newsData)}
                          style={{ backgroundColor: "#C42127 ", width: "100%" }}>
                          Non Aktif
                        </Button>
                      ) : (
                        <Button
                          onClick={() => toggleDelete(newsData)}
                          style={{ backgroundColor: "#DBDBDB", width: "100%" }}>
                          Hapus
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="12" sm="12" xs="12">
              <Card>
                <CardBody className="card-info-article">
                  <div className="pt-2">
                    <p>Populer</p>
                  </div>
                  <CardImg
                    top
                    width="100%"
                    src={imgPopuler}
                    className="thumbnail-img"
                    alt="Card image cap"
                  />
                  <div className="wrap-content-info-articel">
                    <div className="title-info-article container-title">
                      <p>{populerTitle}</p>
                    </div>
                    <div className="date-info-articel">
                      <p>
                        {role + "," + " " + dayPopuler + " " + monthPopuler + " " + yearsPopuler}
                      </p>
                    </div>
                  </div>
                  <Row xs="3" className="mb-3">
                    <Col>
                      {populerData.status === "Non Aktif" ? (
                        <Button
                          onClick={() => togglePosting(populerData)}
                          style={{ backgroundColor: "#2C3280", width: "100%" }}>
                          Posting
                        </Button>
                      ) : (
                        <Button
                          onClick={() => toggleBaca(populerData)}
                          style={{ backgroundColor: "#55BA59", width: "100%" }}>
                          {textBaca}
                        </Button>
                      )}
                    </Col>
                    <Col>
                      <Button
                        onClick={() => handleEdit(populerData)}
                        style={{ backgroundColor: "#E5BA4E", width: "100%" }}>
                        Edit
                      </Button>{" "}
                    </Col>
                    <Col>
                      {populerData.status === "Aktif" ? (
                        <Button
                          onClick={() => toggleStatus(populerData)}
                          style={{ backgroundColor: "#C42127 ", width: "100%" }}>
                          Non Aktif
                        </Button>
                      ) : (
                        <Button
                          onClick={() => toggleDelete(populerData)}
                          style={{ backgroundColor: "#DBDBDB", width: "100%" }}>
                          Hapus
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="12" sm="12" xs="12">
              <Card>
                <CardBody className="card-info-article">
                  <div className="pt-2">
                    <p>Banyak {textBaca}</p>
                  </div>
                  <CardImg
                    top
                    width="100%"
                    src={imgMostRead}
                    className="thumbnail-img"
                    alt="Card image cap"
                  />
                  <div className="wrap-content-info-articel">
                    <div className="title-info-article container-title">
                      <p>{mostReadTitle}</p>
                    </div>
                    <div className="date-info-articel">
                      <p>
                        {role + "," + " " + dayMostRead + " " + monthMostRead + " " + yearsMostRead}
                      </p>
                    </div>
                  </div>
                  <Row xs="3" className="mb-3">
                    <Col>
                      {mostReadData.status === "Non Aktif" ? (
                        <Button
                          onClick={() => togglePosting(mostReadData)}
                          style={{ backgroundColor: "#2C3280", width: "100%" }}>
                          Posting
                        </Button>
                      ) : (
                        <Button
                          onClick={() => toggleBaca(mostReadData)}
                          style={{ backgroundColor: "#55BA59", width: "100%" }}>
                          {textBaca}
                        </Button>
                      )}
                    </Col>
                    <Col>
                      <Button
                        onClick={() => handleEdit(mostReadData)}
                        style={{ backgroundColor: "#E5BA4E", width: "100%" }}>
                        Edit
                      </Button>{" "}
                    </Col>
                    <Col>
                      {mostReadData.status === "Aktif" ? (
                        <Button
                          onClick={() => toggleStatus(mostReadData)}
                          style={{ backgroundColor: "#C42127 ", width: "100%" }}>
                          Non Aktif
                        </Button>
                      ) : (
                        <Button
                          onClick={() => toggleDelete(mostReadData)}
                          style={{ backgroundColor: "#DBDBDB", width: "100%" }}>
                          Hapus
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <PostingPopupInfo modal={modalPosting} toggle={togglePosting} data={toData} typeContent={typeContent}  />
        <BacaPopupInfo modal={modalBaca} toggle={toggleBaca} data={toData} typeContent={typeContent} />
        <StatusPopupInfo modal={modalStatus} toggle={toggleStatus} data={toData} typeContent={typeContent} />
        <DeletePopupInfo modal={modalDelete} toggle={toggleDelete} data={toData} typeContent={typeContent} />
      </Container>
    </div>
  );
}
