import React, { useState, useEffect } from "react";
import {
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Button,
  ButtonDropdown,
} from "reactstrap";

import API from "../../../services";

import axios from "../Config/Axios";

// ASSETS
import iconDown from "../../../assets/icon/back.png";

// STYLES
import "../style.css";

// MODAL
import ModalCalendarRange from "../CalendarRange";

import cal from "../../../assets/icon/calendar.png";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";

export default function Filter(props) {
  // MODAL DROPDOWN LIST PROVINSI
  const [dropdownProvinceList, setDropdownProvinceList] = useState(false);
  const toggleDropdownProvinceList = () => setDropdownProvinceList((prevState) => !prevState);

  // STATE LIST PROVINSI
  const [provList, setProvList] = useState([]);
  const [lokasiList, setLokasiList] = useState([]);
  const [titleProv, setTitleProv] = useState("Pilih Provinsi");
  const [titleLokasi, setTitleLokasi] = useState("");
  const [idProv, setIdProv] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState([]);

  // console.log("datesss", dateRange);

  // MODAL DROPDOWN LOKASI
  const [dropdownTanggal, setDropdownTanggal] = useState(false);
  const toggleDropdownTanggal = () => setDropdownTanggal((prevState) => !prevState);

  const getAllProvince = () => {
    API.getAllProvince()
      .then((res) => {
        if (res.data.success) {
          setProvList(res.data.data);
        } else {
          setProvList([]);
        }
      })
      .catch((e) => {
        setProvList(null);
      });
  };

  // const getDataKontenReport = () => {
  //   if (idProv && startDate !== "01/01/2000" && endDate !== "01/01/2000" && titleLokasi) {
  //     props.setLoading(true);
  //     const param = {
  //       province: idProv,
  //       type: props.activeTab === "1" ? "video" : props.activeTab === "2" ? "banner" : "news",
  //       date: startDate,
  //       // end: endDate,
  //       ...(titleLokasi !== "" && { ai: titleLokasi }),
  //     };
  //     props.setShowTableVideo(true);

  //     API.getKontenReportDetail(param)
  //       .then((res) => {
  //         if (res.data.success) {
  //           props.setData(res.data.data);
  //           props.setLoading(false);
  //         } else {
  //           props.setData([]);
  //           props.setLoading(false);
  //         }
  //       })
  //       .catch((e) => {
  //         props.setData(null);
  //         props.setLoading(false);
  //       });

  //   } else {
  //     props.setShowTableVideo(false);
  //     return alert("Filter data belum lengkap");
  //   }
  // };

  const getDataDetail = () => {
    // const param = {
    //   province: props.idProvince,
    //   type: props.activeTab === "1" ? "video" : props.activeTab === "2" ? "banner" : "news",
    //   date: props.date,
    //   ai: titleLokasi,
    // };
    if (!idProv || dateRange.length === 0 || !titleLokasi || !startDate || !endDate) {
      return alert("Silahkan Lengkapi Filter Data Terlebih Dahulu");
    } else {
      props.setLoading(true);
      props.setShowTableVideo(true);
      props.setError(false);
      props.setData([]);

      Promise.all(
        dateRange.map((e) =>
          // API.getKontenReportDetail({
          //   province: idProv,
          //   type: props.activeTab === "1" ? "video" : props.activeTab === "2" ? "banner" : "news",
          //   date: e,
          //   ai: titleLokasi,
          // })
          axios.post(`/statistics/content/report/detail`, {
            province: idProv,
            type: props.activeTab === "1" ? "video" : props.activeTab === "2" ? "banner" : "news",
            date: e,
            ai: titleLokasi,
          })
        )
      )
        .then((e) => {
          props.setDataDetail(e);
          props.setLoading(false);
          props.setData(
            e.map((e, i) => {
              if (e.data.count !== 0) {
                if (e.data.data) {
                  if (e.data.data.length !== 0) {
                    return {
                      date: e.data.data[0].date ?? JSON.parse(e.config.data).date ?? "-",
                      impress:
                        e.data.data.reduce((acc, curr) => acc + curr.impress, 0) ??
                        "Terjadi Kesalahan",
                      click:
                        e.data.data.reduce((acc, curr) => acc + curr.click, 0) ??
                        "Terjadi Kesalahan",
                      unique_dfp:
                        _.uniq(_.flatten(e.data.data.map((e) => e.unique_dfp))).length ??
                        "Terjadi Kesalahan",
                    };
                  }
                }
              } else {
                return {
                  date: JSON.parse(e.config.data).date ?? "-",
                  impress: "Data tidak ditemukan",
                  click: "Data tidak ditemukan",
                  unique_dfp: "Data tidak ditemukan",
                };
              }
            })
          );
        })
        .catch((e) => {
          props.setError(true);
          setTimeout(() => {
            props.setLoading(false);
          }, 300);
        });
    }

    // .catch((e) => {
    //   props.setData(null);
    //   props.setLoading(false);
    // });
    // API.getKontenReportDetail(param)
    //   .then((res) => {
    //     if (res.data.success) {
    //       setData(
    //         res.data.data.map((e, i) => ({
    //           no: i + 1,
    //           judul: e
    //             ? e.video
    //               ? e.video.details
    //                 ? e.video.details.title ?? "-"
    //                 : "-"
    //               : "-"
    //             : "-",
    //           org: e
    //             ? e.video
    //               ? e.video.requested_by
    //                 ? e.video.requested_by.organization
    //                   ? e.video.requested_by.organization.name ?? "-"
    //                   : "-"
    //                 : "-"
    //               : "-"
    //             : "-",
    //           jam: e.time ?? "-",
    //           durasi: e ? (e.video ? e.video.proposed_duration ?? "-" : "-") : "-",
    //           total_impresi: e.impress ?? "-",
    //           total_klik: e.click ?? "-",
    //           total_dfp: e.unique_dfp ?? "-",
    //         }))
    //       );
    //       setKontenList(
    //         _.uniq(
    //           res.data.data.map((e, i) =>
    //             e ? (e.video ? (e.video.details ? e.video.details.title ?? "-" : "-") : "-") : "-"
    //           )
    //         )
    //       );
    //       setLoading(false);
    //     } else {
    //       setData([]);
    //       setLoading(false);
    //     }
    //   })
    // .catch((e) => {
    //   setData(null);
    //   setLoading(false);
    // });
  };

  const getKontenISPProvince = () => {
    const param = { province: idProv };

    API.getKontenISPProvince(param)
      .then((res) => {
        if (res.data.success) {
          setLokasiList(res.data.data);
        } else {
          setLokasiList([]);
        }
      })
      .catch((e) => {
        setLokasiList(null);
      });
  };

  useEffect(() => {
    getAllProvince();
  }, []);

  useEffect(() => {
    if (idProv !== "") {
      getKontenISPProvince();
      setTitleLokasi("");
    }
    props.setIdProvince(idProv);
    props.setProvince(titleProv);
  }, [idProv]);

  useEffect(() => {
    props.setLokasi(titleLokasi);
  }, [titleLokasi]);

  return (
    <>
      {/* Modal Calendar Range */}
      <ModalCalendarRange
        modalCalendar={dropdownTanggal}
        toggleModalCalendar={toggleDropdownTanggal}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        // selectedDate={handleSelectedDate}
      />
      <Row className="py-2 mb-2">
        {/* DROPDOWN LIST PROVINSI */}
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <ButtonDropdown
            style={{ width: "100%" }}
            isOpen={dropdownProvinceList}
            toggle={toggleDropdownProvinceList}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{titleProv}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              {provList ? (
                provList.length == 0 ? (
                  <DropdownItem>Mohon Tunggu...</DropdownItem>
                ) : (
                  provList.map((e, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => {
                        setIdProv(e._id);
                        setTitleProv(e.name);
                      }}>
                      {e.name}
                    </DropdownItem>
                  ))
                )
              ) : (
                <DropdownItem>Terjadi Kesalahan</DropdownItem>
              )}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>

        {/* DROPDOWN LOKASI */}
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <Autocomplete
            // classes={style.dropdownAutocomplete}
            id="combo-box-demo"
            options={lokasiList !== null ? lokasiList : []}
            defaultValue={titleLokasi}
            value={titleLokasi}
            getOptionLabel={(option) => {
              return option;
            }}
            onChange={(e, value) => {
              value !== null ? setTitleLokasi(value) : setTitleLokasi("");
            }}
            renderOption={(option) => (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>{option}</div>
                </div>
              </React.Fragment>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // value={dataTitle}
                placeholder="Cari Lokasi..."
              />
            )}
          />
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <Button
            onClick={toggleDropdownTanggal}
            style={{
              backgroundColor: "#F8F9FA",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "100%",
            }}>
            <div
              style={{
                color: "#2C3280",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <span className="mr-1">
                {!startDate && !endDate ? "Pilih Tanggal" : `${startDate} - ${endDate}`}
              </span>
              <img src={cal} />
            </div>
          </Button>
        </Col>
        <Col lg="1" className="p-0">
          <Button
            style={{
              backgroundColor: "#2C3280",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            // onClick={getDataKontenReport}>
            onClick={getDataDetail}>
            <span style={{ fontSize: "11px" }}>Cari</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}
