import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table
      style={{ display: "none" }}
      id="DownloadTableXLSTrafikProvinsi"
      className="DownloadTableXLS">
      <thead>Trafik Konten Provinsi</thead>
      <thead>
        <tr>
          <th>Konten</th>
          <th>Provinsi</th>
          <th>Organisasi</th>
          <th>Total Konten</th>
          <th>Total Impresi</th>
          <th>Total Klik</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>{props ? props.content ?? "-" : "-"}</td>
                <td>{props ? props.prov ?? "-" : "-"}</td>
                <td>{props ? props.org ?? "-" : "-"}</td>
                <td>{data.content ?? "-"}</td>
                <td>{data.impress ?? "-"}</td>
                <td>{data.click ?? "-"}</td>
              </tr>
            </>
          ) : (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </>
          )
        ) : (
          <>
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
