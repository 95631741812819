import React, { useState } from "react";
import { MDBModal } from "mdbreact";
import "./style.css";
// ------------------API-----------------------
import API from "../../../../../services";
const ModalDecline = ({
  modalDecline,
  toggleModalDecline,
  dataModalDecline,
  handleAlert,
  fetchData,
}) => {
  return (
    <MDBModal isOpen={modalDecline} toggle={toggleModalDecline} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleDecline">ALASAN PENOLAKAN</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalDecline}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">
              {dataModalDecline
                ? dataModalDecline.data
                  ? dataModalDecline.data.details
                    ? dataModalDecline.data.details.title
                    : ""
                  : ""
                : ""}
              {dataModalDecline
                ? dataModalDecline.data
                  ? dataModalDecline.data
                    ? dataModalDecline.data.title
                    : ""
                  : ""
                : ""}
            </h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleDecline">Pengguna ini telah ditolak</h4>
          </div>
          <div className="form-group wrapperTextAreaDecline">
            <textarea
              value={
                dataModalDecline
                  ? dataModalDecline.data
                    ? dataModalDecline.data
                      ? dataModalDecline.data.commentAdmin
                      : ""
                    : ""
                  : ""
              }
              className="form-control textAreaDecline"
              disabled
              rows="5"></textarea>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalDecline;
