import React, { useState } from "react";
import { Row, Button, Col, Card, Modal, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import { MDBModalHeader, MDBNavLink } from "mdbreact";
import moment from "moment";

// STYLE
import style from "./style.module.css";

import FilterTrafikProvinsi from "./FilterTrafikProvinsi";
import CardMap from "./Map/CardMap";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "./Download/XLS/DownloadCardTrafikProvinsi";

export default function CardTrafikProvinsi(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  // MODAL DOWNLOAD
  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);
  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  const [idProvince, setIdProvince] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.id_provinsi_trafik_provinsi
      : ""
  );
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [prov, setProv] = useState("");
  const [org, setOrg] = useState("");
  const [content, setContent] = useState("");

  const [resetFilter, setResetFilter] = useState(false);

  return (
    <div>
      <Row style={{ minHeight: "500px" }}>
        <Col md="12">
          <Card body style={{ boxShadow: "none" }}>
            <div className="p-3">
              <Row>
                <div className={`${style.titleCardBI}`}>TRAFIK PROVINSI</div>
              </Row>
              <div>
                <Modal
                  size="md"
                  isOpen={modalDownloadXLS}
                  toggle={toggleDownloadXLS}
                  centered={true}>
                  <MDBModalHeader toggle={toggleDownloadXLS}>
                    <span className={style.headText}>Download XLS</span>
                  </MDBModalHeader>
                  <ModalBody>
                    <center className="container-popup">
                      <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                      <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                        Batal
                      </Button>
                      <Button
                        color=""
                        className={style.btn_confirm_xls}
                        onClick={toggleDownloadXLS}>
                        <ReactHTMLTableToExcel
                          id="TableXLS"
                          className={style.DownloadTableXLS}
                          table="DownloadTableXLSTrafikProvinsi"
                          filename={`summary-traffik-provinsi ${moment(new Date()).format(
                            "DD-MM-YYYY"
                          )}`}
                          sheet="users"
                          buttonText="Download"
                        />
                      </Button>
                    </center>
                  </ModalBody>
                </Modal>
                {loading || resetFilter ? (
                  ""
                ) : (
                  <Col
                    className="my-1"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}>
                    <MDBNavLink to="#" className={style.textDownload}>
                      Download
                    </MDBNavLink>
                    <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                      XLS
                    </MDBNavLink>
                  </Col>
                )}
                <DownloadXLS
                  id="DownloadTableXLSTrafikProvinsi"
                  className="DownloadTableXLS"
                  data={data}
                  content={content}
                  org={org}
                  prov={prov}
                />
              </div>
              <FilterTrafikProvinsi
                idProvince={idProvince}
                prov={prov}
                setLoading={setLoading}
                setData={setData}
                setIdProvince={setIdProvince}
                setDataSave={props.setDataSave}
                dataSave={props.dataSave}
                setOrg={setOrg}
                setContent={setContent}
                setResetFilter={setResetFilter}
                resetFilter={resetFilter}
              />
              {resetFilter ? (
                ""
              ) : (
                <div>
                  <CardMap
                    idProvince={idProvince}
                    setIdProvince={setIdProvince}
                    loading={loading}
                    data={data}
                    setDataSave={props.setDataSave}
                    dataSave={props.dataSave}
                    setProv={setProv}
                  />
                </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
