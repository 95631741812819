import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const BuatKontenAdminAsync = Loadable({
  loader: () =>
    import("../../../pages/BuatKonten/Admin" /* webpackChunkName: "BuatKontenAdminAsync" */),
  loading: Loading,
});

export default BuatKontenAdminAsync;
