import React, { useEffect, useState } from "react";
import moment from "moment";
import { Spinner, Modal, ModalBody, Button, Col } from "reactstrap";
import { MDBModalHeader, MDBNavLink } from "mdbreact";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLSUB from "./Download/XLS/DownloadCardSurveyStatistik";

import style from "./style.module.css";

// COMPONENT
import CardStatistikSurvey from "./CardStatistikSurvey";
import FilterStatistik from "./FilterStatistik";

export default function DemografiPengunjungSurvey(props) {
  const [showComponent, setShowComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataProv, setDataProv] = useState("");

  // MODAL DOWNLOAD
  const [modalDownloadXLSUB, setModalDownloadXLSUB] = useState(false);
  const toggleDownloadXLSUB = () => {
    setModalDownloadXLSUB(!modalDownloadXLSUB);
  };

  return (
    <div className="container-fluid" style={{ marginBottom: "100px" }}>
      <FilterStatistik
        setShowComponent={setShowComponent}
        showComponent={showComponent}
        setData={setData}
        setDataSave={props.setDataSave}
        activeTab={props.activeTab}
        setLoading={setLoading}
        setDataProv={setDataProv}
      />
      {showComponent ? (
        data !== null ? (
          <>
            <div>
              <Modal
                size="md"
                isOpen={modalDownloadXLSUB}
                toggle={toggleDownloadXLSUB}
                centered={true}>
                <MDBModalHeader toggle={toggleDownloadXLSUB}>
                  <span className={style.headText}>Download XLS</span>
                </MDBModalHeader>
                <ModalBody>
                  <center className="container-popup">
                    <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                    <Button color="" className={style.btn_reject} onClick={toggleDownloadXLSUB}>
                      Batal
                    </Button>
                    <Button
                      color=""
                      className={style.btn_confirm_xls}
                      onClick={toggleDownloadXLSUB}>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className={style.DownloadTableXLS}
                        table="DownloadTableXLSSurveyStatistik"
                        filename={`statistik-hasil-survey ${moment(new Date()).format(
                          "DD-MM-YYYY"
                        )}`}
                        sheet="hasil survey"
                        buttonText="Download"
                      />
                    </Button>
                  </center>
                </ModalBody>
              </Modal>
              <Col
                className="my-3"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}>
                <MDBNavLink to="#" className={style.textDownload}>
                  Download
                </MDBNavLink>
                <MDBNavLink onClick={toggleDownloadXLSUB} link to="#">
                  XLS
                </MDBNavLink>
              </Col>
              <DownloadXLSUB
                id="DownloadTableXLSSurveyStatistik"
                className="DownloadTableXLS"
                data={data}
                prov={dataProv}
              />
            </div>
            <CardStatistikSurvey loading={showComponent} data={data} />
          </>
        ) : (
          <div> Tidak ada data</div>
        )
      ) : loading ? (
        <div className="d-flex justify-content-center mt-5">
          {" "}
          <Spinner type="grow" />{" "}
        </div>
      ) : (
        <div> Isi Filter Data Terlebih Dahulu </div>
      )}
    </div>
  );
}
