import React, { useEffect, useState } from "react";
import { Row, Card, Col } from "reactstrap";

import "./CardHome.css";
import video from "../../../../assets/images/video.png";
import banner from "../../../../assets/images/banner.png";
import berita from "../../../../assets/images/berita.png";

import API from "../../../../services";

export default function CardHome(props) {
  //-----------------------GET TIME-----------------------
  let currentdate = new Date();
  let hours = currentdate.getHours();
  let minutes = currentdate.getMinutes().toString();
  let year = new Date().getFullYear();
  let date = currentdate.getDate();
  let month = currentdate.getMonth() + 1;
  let [cVid, setCVid] = useState({
    totalVideo: "0",
    monthlyVideo: "0",
    scheduledVideo: "0",
    monthlyscheduledVideo: "0",
  });
  let [cBanner, setCBanner] = useState({
    totalBanner: "0",
    monthlyBanner: "0",
    scheduledBanner: "0",
    monthlyscheduledBanner: "0",
  });
  let [cNews, setCNews] = useState({
    totalNews: "0",
    monthlyNews: "0",
    scheduledNews: "0",
    monthlyscheduledNews: "0",
  });
  if (minutes.length === 1) {
    minutes = "0" + minutes;
  }

  let strMonth;
  switch (month) {
    case 1:
      strMonth = "JANUARI";
      break;
    case 2:
      strMonth = "FEBRUARI";
      break;
    case 3:
      strMonth = "MARET";
      break;
    case 4:
      strMonth = "APRIL";
      break;
    case 5:
      strMonth = "MEI";
      break;
    case 6:
      strMonth = "JUNI";
      break;
    case 7:
      strMonth = "JULI";
      break;
    case 8:
      strMonth = "AGUSTUS";
      break;
    case 9:
      strMonth = "SEPTEMBER";
      break;
    case 10:
      strMonth = "OKTOBER";
      break;
    case 11:
      strMonth = "NOVEMBER";
  }
  var tggl = date + " " + strMonth + " " + year;
  var jam = hours + ":" + minutes;

  useEffect(() => {
    API.getContentCount().then((res) => {
      let json = res.data.data;
      if (res.data.success) {
        setCVid((prev) => ({
          ...prev,
          totalVideo: json.totalVideo,
          monthlyVideo: json.monthlyVideo,
          scheduledVideo: json.scheduledVideo,
          monthlyscheduledVideo: json.monthlyscheduledVideo,
        }));
        setCBanner((prev) => ({
          ...prev,
          totalBanner: json.totalBanner,
          monthlyBanner: json.monthlyBanner,
          scheduledBanner: json.scheduledBanner,
          monthlyscheduledBanner: json.monthlyscheduledBanner,
        }));
        setCNews((prev) => ({
          ...prev,
          totalNews: json.totalNews,
          monthlyNews: json.monthlyNews,
          scheduledNews: json.scheduledNews,
          monthlyscheduledNews: json.monthlyscheduledNews,
        }));
      } else {
        props.setError("Gagal Memuat Data Konten");
      }
    });
  }, []);

  return (
    <div className="mx-5">
      <Row className="mt-5 mb-5">
        <Col md="4">
          <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
            <div className="p-3 py-5">
              <Row>
                <div className="ml-2 mr-2">
                  <img src={video} alt="img-title" />
                </div>
                <div className="mt-1">
                  <div className="title-card" style={{ color: "#8CC6C9" }}>
                    KONTEN VIDEO
                  </div>
                  <div className="date-card">{tggl}</div>
                  <div className="clock-card">{jam}</div>
                </div>
              </Row>
              <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                <div className=""></div>
                <div className="tit-score">
                  <div className="title-month-card mr-2 ml-2">Total</div>
                  <div className="title-total-card  ml-2">Bulan ini</div>
                </div>
              </Row>
              <Row className="justify-content-between  wrap-total">
                <div className="title-left">Terdaftar</div>
                <div className="scoreVal">
                  <div className="value-totalSupport">
                    {cVid.totalVideo == undefined ? "0" : cVid.totalVideo}
                  </div>
                  <div className="value-monthTopSupport">
                    {cVid.monthlyVideo == undefined ? "0" : cVid.monthlyVideo}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between  wrap-total mt-2">
                <div className="title-left">Disetujui</div>
                <div className="scoreVal">
                  <div className="value-totalSupport">
                    {cVid.scheduledVideo == undefined ? "0" : cVid.scheduledVideo}
                  </div>
                  <div className="value-monthTopSupport">
                    {cVid.monthlyscheduledVideo == undefined
                      ? "0"
                      : cVid.monthlyscheduledVideo}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md="4">
          <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
            <div className="p-3 py-5">
              <Row>
                <div className="ml-2 mr-2">
                  <img src={banner} alt="img-title" />
                </div>
                <div className="mt-1">
                  <div className="title-card" style={{ color: "#E2B674" }}>
                    KONTEN BANNER
                  </div>
                  <div className="date-card">{tggl}</div>
                  <div className="clock-card">{jam}</div>
                </div>
              </Row>
              <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                <div className=""></div>
                <div className="tit-score">
                  <div className="title-month-card mr-2 ml-2">Total</div>
                  <div className="title-total-card  ml-2">Bulan ini</div>
                </div>
              </Row>

              <Row className="justify-content-between  wrap-total">
                <div className="title-left">Terdaftar</div>
                <div className="scoreVal">
                  <div className="value-totalSupport">
                    {cBanner.totalBanner == undefined ? "0" : cBanner.totalBanner}
                  </div>
                  <div className="value-monthTopSupport">
                    {cBanner.monthlyBanner == undefined ? "0" : cBanner.monthlyBanner}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between  wrap-total mt-2">
                <div className="title-left">Disetujui</div>
                <div className="scoreVal">
                  <div className="value-totalSupport">
                    {cBanner.scheduledBanner == undefined ? "0" : cBanner.scheduledBanner}
                  </div>
                  <div className="value-monthTopSupport">
                    {cBanner.monthlyscheduledBanner == undefined
                      ? "0"
                      : cBanner.monthlyscheduledBanner}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md="4">
          <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
            <div className="p-3 py-5">
              <Row>
                <div className="ml-2 mr-2">
                  <img src={berita} alt="img-title" />
                </div>
                <div className="mt-1">
                  <div className="title-card" style={{ color: "#C18ECE" }}>
                    KONTEN BERITA
                  </div>
                  <div className="date-card">{tggl}</div>
                  <div className="clock-card">{jam}</div>
                </div>
              </Row>
              <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                <div className=""></div>
                <div className="tit-score">
                  <div className="title-month-card mr-2 ml-2">Total</div>
                  <div className="title-total-card  ml-2">Bulan ini</div>
                </div>
              </Row>
              <Row className="justify-content-between  wrap-total">
                <div className="title-left">Terdaftar</div>
                <div className="scoreVal">
                  <div className="value-totalSupport">
                    {cNews.totalNews == undefined ? "0" : cNews.totalNews}
                  </div>
                  <div className="value-monthTopSupport">
                    {cNews.monthlyNews == undefined ? "0" : cNews.monthlyNews}
                  </div>
                </div>
              </Row>
              <Row className="justify-content-between  wrap-total mt-2">
                <div className="title-left">Disetujui</div>
                <div className="scoreVal">
                  <div className="value-totalSupport">
                    {cNews.scheduledNews == undefined ? "0" : cNews.scheduledNews}
                  </div>
                  <div className="value-monthTopSupport">
                    {cNews.monthlyscheduledNews == undefined ? "0" : cNews.monthlyscheduledNews}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
