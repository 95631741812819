import React from "react";
import { Row, Card } from "reactstrap";
// -------------------ASSET------------------------
//css
import "../style.css";
// -------------------COMPONENT--------------------
import Header from "../../../../components/VerticalLayout/Header";
import Table from "../../../../components/LogHistory/Tabel";

// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function LogHistory(props) {
  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="Log History" className="SuperAdminHeader" />
        </header>
        <main>
          <div className="container-fluid">
            <Row>
              <div style={{ width: "100%" }} className="mx-5">
                <Card className="p-3" style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
                  <Table />
                </Card>
              </div>
            </Row>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(LogHistory));
