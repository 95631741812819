import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  let title;

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`DATA TIKET `}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>No. Tiket</th>
          <th>Nama/Organisasi</th>
          <th>No Handphone</th>
          <th>Jenis</th>
          <th>Kategori</th>
          <th>Level</th>
          <th>Tanggal Masuk</th>
          <th>Tanggal Closed</th>
          <th>Status</th>
          <th>Kepuasan</th>
          <th>Pertanyaan</th>
          <th>Jawaban</th>
        </tr>
      </thead>
      <tbody>
        {props?.data?.map((it, index) => {
          return (
            <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{index + 1}</td>
              <td> {it.ticketNum ?? "-"}</td>
              <td> {it.name ? it.name : it.email ? it.email : "-"}</td>
              <td> {JSON.stringify(it.phone) ?? "-"}</td>
              <td>{it ? (it.type ? it.type ?? "-" : "-") : "-"}</td>
              <td> {it ? (it.kategori ? it.kategori ?? "-" : "-") : "-"}</td>
              <td> {it ? (it.level ? it.level : "-") : "-"}</td>
              <td>
                {it
                  ? it.tanggal_pembuatan
                    ? moment(it.tanggal_pembuatan).format("DD-MM-YYYY")
                    : "-"
                  : "-"}
              </td>
              <td>
                {it
                  ? it.closed
                    ? it.closed.closedAt
                      ? moment(it.closed.closedAt).format("DD-MM-YYYY")
                      : "-"
                    : "-"
                  : "-"}
              </td>
              <td>{it ? (it.status ? it.status.toLowerCase() : "-") : "-"}</td>
              <td>{it ? (it.closed ? it.closed.closedType : "") : ""}</td>
              <td>{it.message}</td>
              <td>
                {it.reply.map(
                  (item, i) => i + 1 + "." + item.repliedBy + " : " + item.message + "\n"
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
