import React from "react";
import { Row, Col } from "reactstrap";
import Header from "../../../../components/VerticalLayout/Header";
import SideMenuBI from "../../../../components/SideMenuBI";
import UserBehaviorPage from "../../../../components/UserBehavior";

export default function UserBehavior() {
  const [menu, setMenu] = React.useState("Persebaran Pengunjung");
  const changeMainSectionView = (item) => setMenu(item);
  return (
    <div className="page-content">
      <header>
        <Header title="Business Intelligence" className="SuperAdminHeader" />
      </header>
      <main>
        <div className="container-fluid">
          <Row>
            <Col md="2">
              <SideMenuBI active={menu} action={changeMainSectionView} />
            </Col>
            <Col md="10">
              <UserBehaviorPage />
            </Col>
          </Row>
        </div>
      </main>
    </div>
  );
}
