import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../../Filter/filter";
// ------------------API-----------------------
import API from "../../../../../services";

// ------------------Popup-----------------------

import PopVerif from "../PopupAktif";
import PopDecline from "../PopupNonAktif";
import PopDelete from "../PopupDelete";
// import ModalTambahPengguna from "../TambahPengguna";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconCheck from "../../../../../assets/icon/IconCheck.svg";
import IconDecline from "../../../../../assets/icon/IconDecline.svg";
import IconInfo from "../../../../../assets/icon/IconInfo.svg";
import IconEmergency from "../../../../../assets/icon/IconEmergency.svg";
import IconGreenCheckList from "../../../../../assets/icon/IconGreenChecklist.svg";
import IconRedCheckList from "../../../../../assets/icon/IconRedChecklist.svg";
// ------------------Component----------------
import ModalPreviewVideo from "../../../Popup/PersetujuanKonten/PreviewVideo";
import ModalVerif from "../../../Popup/PersetujuanKonten/Verification";
import ModalDecline from "../../../Popup/PersetujuanKonten/Decline";
import DetailKonten from "../../../Popup/PersetujuanKonten/DetailKonten";
import ModalPreviewEmergencyDoc from "../../../Popup/PersetujuanKonten/PreviewEmergencyDoc";
// ------------------Redux----------------

import { connect } from "react-redux";
import noImage from "../../../../../assets/images/noImage.jpg";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import ModalTidakLolosUser from "../PopUpTidakLolos";

const TableRegisterAdmin = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);

  //modal Tidak Lolos
  const [modalTidakLolos, setModalTidakLolos] = useState(false);
  const [dataModalTidakLolos, setDataModalTidakLolos] = useState({});
  const toggleModalTidakLolos = () => {
    setModalTidakLolos(!modalTidakLolos);
  };
  const handleModalTidakLolos = (it) => {
    setDataModalTidakLolos({
      title: it.name ? it.name : "-",
      comment:
        it.status === "confirmation" ? "Belum di konfirmasi" : "Ditolak karena alasan tertentu",
    });
    toggleModalTidakLolos();
  };

  // popup aktif
  const [modalAktif, setModalAktif] = useState(false);
  const [dataAktif, setDataAktif] = useState([]);
  // pop Non aktif
  const [dataNonAktif, setDataNonAktif] = useState([]);
  const [modalNonAktif, setModalNonAktif] = useState(false);
  // pop trash
  const [dataTrash, setDataTrash] = useState([]);
  const [modalTrash, setModalTrash] = useState(false);

  // handle alasan
  const [dataAlasan, setDataAlasan] = useState("");

  //Tambah Pengguna
  const [modalTambahPengguna, setModalTambahPengguna] = useState(false);
  const [dataTambahPengguna, setDataTambahPengguna] = useState({});

  const refreshTable = () => {
    setRefresh(refresh + 1);
  };

  //alert
  const [alert, setAlert] = useState("");
  const fetchAPI = () => {
    setLoading(true);
    API.getTableRegisterAdmin()
      .then((res) => {
        // console.log(res.data.data)
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setLoading(false);
        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(res.data.data);
        } else {
          setRows(result);
          document.getElementById("TableRegisterAdmin").rows[1].style.transition = "0.8s";
          document.getElementById("TableRegisterAdmin").rows[1].style.backgroundColor = "#e6f3ff";
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    // console.log("props.categoryContent !==0", props.categoryContent !== "0", props.categoryContent);
    // if (props.categoryContent !== "0") {
    //   fetchAPI(props.categoryContent);
    // } else {
    //   fetchAPI();
    // }
    fetchAPI();
  }, [refresh]);
  //throw data to parent for download
  const filterData = (item) => {
    // console.log("yg bkl ke lempar", item)
    props.handleData(item);
  };

  const tambahAction = () => {
    alert("hahaha");
  };

  let toggleTambahPengguna = (pop) => {
    setDataTambahPengguna(pop);
    setModalTambahPengguna(!modalTambahPengguna);
  };

  const toggleAktif = () => {
    setModalAktif(!modalAktif);
  };

  let toggleNonAktif = (i) => {
    // setDataDelete(i);
    setModalNonAktif(!modalNonAktif);
  };

  let toggleDelete = (i) => {
    setModalTrash(!modalTrash);
  };

  const handleChange = (e) => {
    let term = e.target.value;
    setDataAlasan(term);
  };

  let handleAktif = (i) => {
    // console.log(i)
    setDataAktif(i);
    toggleAktif();
  };

  let handleNonAktif = (i) => {
    setDataNonAktif(i);
    toggleNonAktif();
  };

  let handleDelete = (i) => {
    setDataTrash(i);
    toggleDelete();
  };

  const AktifAction = () => {
    API.postAktifAdmin({
      username: dataAktif.username,
    })
      .then(function (res) {
        // console.log("verif", res)
        toggleAktif();
        refreshTable();
        setRows([]);
      })
      .catch((err) => {
        // console.log(err);
        refreshTable();
      });
  };

  const deleteAction = () => {
    // console.log("non aktif")
    API.postNonAktifAdmin({
      username: dataNonAktif.username,
      comment: dataAlasan,
    })
      .then(function (res) {
        // console.log("Non Aktif", res)
        toggleNonAktif();
        refreshTable();
        setRows([]);
      })
      .catch((err) => {
        // console.log(err)
        refreshTable();
      });
  };

  const TrashAction = () => {
    // console.log("object")
    API.postDeleteAdmin({
      username: dataTrash.username,
    })
      .then((res) => {
        // console.log("delete", res)
        toggleDelete();
        refreshTable();
        setRows([]);
      })
      .catch((err) => {
        // console.log(err)
        refreshTable();
      });
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      const index = [data.username, data.name, data.email, data.organization.name];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  const ButtonAction = ({ title, id, it }) => {
    return (
      <div className="wrapperBtnAction">
        {it.status === "approved" ? (
          <img
            className="btnAction"
            src={IconDecline}
            // onClick={() => handleDecline(title, id, it)}
            onClick={() => handleNonAktif(it)}></img>
        ) : (
          <img
            className="btnAction"
            src={IconCheck}
            // onClick={() => handleVerif(title, id, it)}
            onClick={() => handleAktif(it)}></img>
        )}
        {it.status !== "approved" && (
          <i
            className="bx bx-trash"
            style={{ fontSize: "20px" }}
            color=""
            onClick={() => handleDelete(it)}></i>
        )}
      </div>
    );
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 200,
      },
      {
        label: "Nama",
        field: "nama",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Waktu Registrasi",
        field: "waktu_registrasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Aksi",
        field: "aksi",
        sort: "disabled",
        width: 50,
      },
    ],

    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, index) => ({
            no: index + 1,
            username: it.username ?? "-",
            nama: it.name ?? "-",
            email: it.email ?? "-",
            // judul: it.details.title ?? "-",
            organisasi: it.organization.name ?? "-",
            waktu_registrasi: moment(it.tanggal_pembuatan).format("DD-MM-YYYY"),
            status:
              it.status === "approved" ? (
                <label style={{ color: "#54EC24" }}>Approved</label>
              ) : (
                <label style={{ color: "#D43522" }} onClick={() => handleModalTidakLolos(it)}>
                  Non Aktif
                </label>
              ),
            aksi: <ButtonAction it={it} />,
          })),
  };

  return (
    <React.Fragment>
      {alert == "success" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil di Setujui"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alert == "fail" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil di Tolak"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconRedCheckList}></img>
        </SweetAlert>
      ) : alert == "failedFetch" ? (
        <SweetAlert
          timeout={3}
          error
          title="Konten Tidak Berhasil di Kurasi"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconRedCheckList}></img>
        </SweetAlert>
      ) : (
        ""
      )}

      {/* <ModalTambahPengguna
				modalTambahPengguna={modalTambahPengguna}
				toggleTambahPengguna={() => toggleTambahPengguna(dataTambahPengguna)}
				data={dataTambahPengguna}
				tambahAction={tambahAction}
			/> */}

      <ModalTidakLolosUser
        dataModalTidakLolos={dataModalTidakLolos}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalTidakLolos={modalTidakLolos}
        toggleModalTidakLolos={toggleModalTidakLolos}
      />

      <PopVerif
        modalAktif={modalAktif}
        toggleAktif={() => toggleAktif(dataAktif)}
        data={dataAktif}
        AktifAction={AktifAction}
      />

      <PopDecline
        modalNonAktif={modalNonAktif}
        toggleNonAktif={() => toggleNonAktif(dataNonAktif)}
        data={dataNonAktif}
        handleChangeAlasan={handleChange}
        deleteAction={deleteAction}
      />

      <PopDelete
        modalTrash={modalTrash}
        toggleDelete={() => toggleDelete(dataTrash)}
        data={dataTrash}
        TrashAction={TrashAction}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex w-80 my-3">
                    <Link to="/superadmin/tambah-pengguna">
                      <Button
                        style={{ width: "120%", backgroundColor: "#2C3280", borderRadius: "10px" }}
                        // onClick={() => toggleTambahPengguna()}
                      >
                        + Tambah Admin
                      </Button>
                    </Link>
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                      style={{
                        width: "30%",
                        marginLeft: "60%",
                        borderRadius: "10px",
                        border: "1px solid #2C3280",
                      }}
                    />
                  </div>

                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">Tidak Ada Data</div>
                  ) : (
                    <MDBDataTable
                      id="TableRegisterAdmin"
                      noBottomColumns={true}
                      className="text-center"
                      responsive
                      disableRetreatAfterSorting
                      striped
                      bordered
                      data={data}
                      displayEntries={false}
                      pagesAmount={5}
                      entries={10}
                      searching={false}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TableRegisterAdmin);
