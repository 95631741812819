import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import AppAdmin from "../../App/AppAdmin";
import AppSuperAdmin from "../../App/AppSuperAdmin";
import AppSupport from "../../App/AppSupport";
import AppAdminArtikel from "../../App/AppAdminArtikel"

const Authmiddleware = ({
	component: Component,
	layout: Layout,

}) => (
		<Route
			render={props => {
				// here you can apply condition
				let role = window.localStorage.getItem("role");

				if (!role) {
					if (props.location.pathname !== '/login') {
						return (
							<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
						)
					} else {
						return (
							<Component {...props} />
						)
					}
				} else {
					if (role) {
						role = role.replace(/\s+/g, "").toString();
						switch (role) {
							case "ArtikelAdmin":
								return (
									<Layout>
										<AppAdminArtikel {...props} />
									</Layout>
								)
							case "Admin":
								return (
									<Layout>
										<AppAdmin {...props} />
									</Layout>
								)
							case "SuperAdmin":
								return (
									// <Layout>
									<AppSuperAdmin {...props} />
									// </Layout>
								)
							case "SupportCenter":
								return (
									<Layout>
										<AppSupport {...props} />
									</Layout>
								)
							default:
								break;
						}
					}
				}
			}}
		/>
	);

export default withRouter(Authmiddleware);

