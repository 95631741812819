import React, { useState } from 'react'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from '../../../../assets/images/back.png';
import './style.css'

function FilterButton(props) {
    const { filter, clicked } = props;
    const [filterContain ] = useState([
        "Aceh",
        "Sumatera Utara",
        "Sumatera Barat",
        "Riau",
        "Kepulauan Riau",
        "Jambi",
        "Sumatera Selatan",
        "Kepulauan Bangka Belitung",
        "Bengkulu",
        "Lampung",
        "DKI Jakarta",
        "Jawa Barat",
        "Banten",
        "Jawa Tengah",
        "DI Yogyakarta",
        "Jawa Timur",
        "Bali",
        "Nusa Tenggara Barat",
        "Nusa Tenggara Timur",
        "Kalimantan Barat",
        "Kalimantan Tengah",
        "Kalimantan Selatan",
        "Kalimantan Timur",
        "Kalimantan Utara",
        "Sulawesi Utara",
        "Sulawesi Barat",
        "Sulawesi Tengah",
        "Sulawesi Tenggara",
        "Sulawesi Selatan",
        "Gorontalo",
        "Maluku",
        "Maluku Utara",
        "Papua Barat",
        "Papua",
    ]);

    //Change uppercase and lowercase
    // const formatText = (text) => {
    //     text = text.toLowerCase()
    //     let textArray = text.split(' ')
    //     let textJoin = textArray.reduce((join, text) => {
    //         if (text === 'dki' || text === 'di') {
    //             text = text.toUpperCase();
    //         } else {
    //             text = text.charAt(0).toUpperCase() + text.slice(1);
    //         }
    //         join.push(text)
    //         return join;
    //     }, [])
    //     return textJoin.join(' ')
    // }

    // async function fetchAPIProvinsi() {
    //     const response = await fetch(`http://202.182.55.252:3000/provinsi/all`)
    //     const json = await response.json()
    //     // get Rows  
    //     let rowsData = [];
    //     for (let i of json.data) {
    //         rowsData.push(formatText(i.name));
    //     }
    //     setFilterContain(rowsData);
    // }
    // useEffect(() => {
    //     fetchAPIProvinsi();
    // }, [])

    return (
        <div>
            <MDBDropdown>
                <MDBDropdownToggle caret color='primary' className="dropdown-rekomendasi">
                    <div>{filter === '' ? 'Pilih Provinsi' : filter}</div>
                    <div><img src={Down} alt="dropdown-icons"/></div>
                </MDBDropdownToggle>
                <MDBDropdownMenu className='dropdown-menus'>
                    {filterContain.map((fc, i) => (
                        <MDBDropdownItem active={filter === fc} onClick={() => clicked(fc)} key={i}>
                            {fc}
                        </MDBDropdownItem>
                    ))}
                </MDBDropdownMenu>
            </MDBDropdown>
        </div>
    )
}
export default FilterButton;