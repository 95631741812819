import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table
      style={{ display: "none" }}
      id="DownloadTableXLSTrafikDemografi"
      className="DownloadTableXLS">
      <thead>Trafik Summary Konten Demografi</thead>
      <thead>
        <tr>
          <th>Konten</th>
          <th>Jumlah Konten Diakses oleh Laki - Laki</th>
          <th>Jumlah Konten Diakses oleh Perempuan</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Video</td>
                <td>
                  {data.video ? (data.video.gender ? data.video.gender.pria ?? "-" : "-") : "-"}
                </td>
                <td>
                  {data.video ? (data.video.gender ? data.video.gender.wanita ?? "-" : "-") : "-"}
                </td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Banner</td>
                <td>
                  {data.banner ? (data.banner.gender ? data.banner.gender.pria ?? "-" : "-") : "-"}
                </td>
                <td>
                  {data.banner ? (data.banner.gender ? data.banner.gender.wanita ?? "-" : "-") : "-"}
                </td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Berita</td>
                <td>{data.news ? (data.news.gender ? data.news.gender.pria ?? "-" : "-") : "-"}</td>
                <td>{data.news ? (data.news.gender ? data.news.gender.wanita ?? "-" : "-") : "-"}</td>
              </tr>
            </>
          ) : (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Video</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Banner</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Berita</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </>
          )
        ) : (
          <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Video</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Banner</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Berita</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
