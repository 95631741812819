import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLSSummary" className="DownloadTableXLS">
      <thead>Summary Trafik Konten</thead>
      <thead>
        <tr>
          <th>Jenis Konten</th>
          <th>Video</th>
          <th>Banner</th>
          <th>Berita</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Konten</td>
                <td>{data.video ? data.video.count ?? "-" : "-"}</td>
                <td>{data.banner ? data.banner.count ?? "-" : "-"}</td>
                <td>{data.news ? data.news.count ?? "-" : "-"}</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Impresi</td>
                <td>{data.video ? data.video.impress ?? "-" : "-"}</td>
                <td>{data.banner ? data.banner.impress ?? "-" : "-"}</td>
                <td>{data.news ? data.news.impress ?? "-" : "-"}</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Klik</td>
                <td>{data.video ? data.video.click ?? "-" : "-"}</td>
                <td>{data.banner ? data.banner.click ?? "-" : "-"}</td>
                <td>{data.news ? data.news.click ?? "-" : "-"}</td>
              </tr>
            </>
          ) : (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Konten</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Impresi</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Klik</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </>
          )
        ) : (
          <>
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>Total Konten</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>Total Impresi</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>Total Klik</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
