import React, { useEffect } from 'react';
import { Container, Card, CardBody, Row, Col, Button } from 'reactstrap';
import { MDBDataTable, MDBDataTableV5 } from 'mdbreact';
import API from '../../../services';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import DeleteNewsletter from '../Popup/Article/DeleteNewsletter';

export default function TableNewsletter() {
    const [dataRows, setDataRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalHapus, setModalHapus] = useState(false);
    const [toModalHapus, setToModalHapus] = useState([]);

    const getTableNewsLetter = () => {
        API.getTableNewsLetter().then(response => {
            var res = response.data.data;
            setDataRows(res)
            setLoading(false)
        }).catch(
            setLoading(true)
        )
    }

    useEffect((val) => {
        getTableNewsLetter()
    }, [])

    const data = {
        columns: [
            {
                label: "Kabupaten",
                field: "kabupaten",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Kecamatan",
                field: "kecamatan",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Nama Lokasi",
                field: "namalokasi",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Total Impresi",
                field: "totalimpresi",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Total Titik",
                field: "totaltitik",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Maks Impresi",
                field: "maksimpresi",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Maks Klik",
                field: "maksklik",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Pengguna Unik",
                field: "penggunaunik",
                sort: "disabled",
                width: 200,
            },
        ],
        rows: dataRows.map((val) => ({
            kabupaten: "Majalengka",
            kecamatan: "Cipageran",
            namalokasi: "Jurang",
            totalimpresi: "12",
            totaltitik: "2",
            maksimpresi: "44",
            maksklik: "32",
            penggunaunik: "12",
            email: val.email,
            inputNewsletter: moment(val.tanggal_pembuatan).format('DD/MM/YYYY'),
            // action: <Button onClick={() => handleHapus(val)} style={{ backgroundColor: "#C42127" }}>Hapus</Button>
        }))
    }
    return (
        <div>
            <Container>
                <div style={{ marginBottom: "10%" }}>
                    {
                        loading == true ? <div className="mt-2 mb-2"><Skeleton height={100} /></div> :
                            <Card style={{ paddingBottom: "10px", paddingTop: "0px" }}>
                                <CardBody>
                                    <MDBDataTableV5
                                        responsive
                                        striped
                                        searching={false}
                                        entries={5}
                                        displayEntries={false}
                                        data={data}
                                    />
                                </CardBody>
                            </Card>
                    }
                </div>
                {/* <DeleteNewsletter modal={modalHapus} toggle={handleHapus} data={toModalHapus} /> */}
            </Container>
        </div>
    )
}
