// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_titleMenuBI__2jsjD {\n  font-size: 18px;\n  color: #2c3280;\n  font-weight: bold;\n  text-align: center;\n}\n.style_buttonSaveLog__3iaLw {\n  border-radius: 18px !important;\n  background-color: #2c3280 !important;\n}\n\n.style_inputSaveLog__L7L2L {\n  border-radius: 18px !important;\n  border-color: #2c3280 !important;\n  text-align: center !important;\n  width: 85% !important;\n}\n", ""]);
// Exports
exports.locals = {
	"titleMenuBI": "style_titleMenuBI__2jsjD",
	"buttonSaveLog": "style_buttonSaveLog__3iaLw",
	"inputSaveLog": "style_inputSaveLog__L7L2L"
};
module.exports = exports;
