import React, { useMemo } from 'react'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from '../../../assets/images/back.png';
import './style.css'

export const FilterButtonProvinsi = (props) => {
    const { filter, clicked } = props;
    //memorize props list
    let listProvince = useMemo(() => {
      return props.list;
    }, [props.list]);
    return (
      <div>
        <MDBDropdown>
          <MDBDropdownToggle caret color="primary" className="dropdown-rekomendasi">
            <div>{filter.value === "" ? `Pilih ${props.type}` : filter.value}</div>
            <div>
              <img src={Down} alt="dropdown-icons" />
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="dropdown-menus disabled browser-default">
            {listProvince.map((fc, i) => (
              <MDBDropdownItem
                active={filter.value === fc.name}
                onClick={() => clicked(fc.name, fc._id, props.type)}
                key={i}>
                {fc.name}
              </MDBDropdownItem>
            ))}
          </MDBDropdownMenu>
        </MDBDropdown>
      </div>
    );
};