import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

import Filter from "../../../../Filter/filter";
// ------------------API-----------------------
import API from "../../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
import "./style.css";
//icon
import IconDelete from "../../../../../assets/icon/IconDelete.svg";
//img
import IconCommentBlack from "../../../../../assets/icon/IconCommentBlack.svg";
import ImgNews from "../../../../../assets/images/ImgNews.png";

import IconCommentRed from "../../../../../assets/icon/IconCommentRed.svg";

// ------------------Component----------------
import ModalTidakLolos from "../../../Popup/ArchieveKonten/TidakLolos";
import ModalTidakDisetujui from "../../../Popup/ArchieveKonten/TidakDisetujui";
import ModalCancelled from "../../../Popup/ArchieveKonten/Cancelled";
import ModalDelete from "../../../Popup/ArchieveKonten/Delete";
import FilterCalendar from "../../../../../components/Filter/filterCalendar";
// ------------------Redux----------------
import dummy from "../../../../../data/dummy1.json";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
//action
import { ubahJadwal, setTab } from "../../../../../store/actions";

const TableAchieveKontenBerita = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  //notif
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [notif, setNotif] = useState(false);

  //calendar
  const [sDate, setStartDate] = useState("");
  const [eDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [modalCalendar, setModalCalendar] = useState(false);
  const [tanggal, setTanggal] = useState("");
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };
  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //throw data to check availability
    setStartDate(startDate.format("DD-MM-YYYY"));
    setEndDate(endDate.format("DD-MM-YYYY"));
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };
  //type content
  let typeContent = "";
  switch (props.activeTab) {
    case "1":
      typeContent = "video";
      break;
    case "2":
      typeContent = "banner";
      break;
    case "3":
      typeContent = "news";
      break;
    default:
      break;
  }
  const fetchAPIBerita = () => {
    let param = {
      type: "library",
    };
    API.postKontenSupport(typeContent, param)
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        let result = res.data.data.filter(
          (e) =>
            e.status_kurator[e.status_kurator.length - 1].status == "Tidak Lolos Kurasi" ||
            e.status_kurator[e.status_kurator.length - 1].status == "Decline" ||
            e.status == "pending" ||
            e.status == "cancelled" ||
            e.status == "tidak disetujui"
        );

        if (result.length == 0) {
          setIsEmpty(true);
        }
        // console.log(res.data.data)
        props.handleData(result);
        let resultNotif =
          props.idContent && props.idContent.length
            ? result.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = result
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        resultNotif.unshift(filter);

        if (resultNotif[0] == undefined) {
          setRows(result);
        } else {
          setRows(resultNotif);
          document.getElementById("tableArchievedNews").rows[1].style.transition = "0.8s";
          document.getElementById("tableArchievedNews").rows[1].style.backgroundColor = "#e6f3ff";
        }
      })
      .catch((e) => {
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    fetchAPIBerita();
    // handleSidebar()
  }, [refresh]);
  //throw data to parent for download
  const filterData = (item) => {
    props.handleData(item);
  };
  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search, tanggal]);

  var items = [];
  if (rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      const status =
        data.status_kurator[data.status_kurator.length - 1].status == "tidak disetujui"
          ? "tidak disetujui"
          : data.status_kurator[data.status_kurator.length - 1].status == "Tidak Lolos Kurasi"
          ? "Tidak Lolos"
          : data.status == "pending"
          ? "Pending"
          : data.status == "cancelled"
          ? "Cancelled"
          : "";
      // const index = `${data.details.title} ${data.category} ${data.status_kurator[data.status_kurator.length - 1].status}`.toLowerCase();
      const index = [data.title, data.category, status];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }

  //filtering data
  // const items =
  //   rows.length > 0
  //     ? rows.filter((data) => {
  //         if (search === "") return data;
  //         else if (
  //           (props.activeTab === "1" ? data.details.title : data.title)
  //             .toLowerCase()
  //             .includes(search.toLowerCase()) ||
  //           data.category.toLowerCase().includes(search.toLocaleLowerCase()) ||
  //           data.status.toLowerCase().includes(search.toLocaleLowerCase())
  //         ) {
  //           return data;
  //         }
  //       })
  //     : [];

  //modal delete
  const [modalDelete, setModalDelete] = useState(false);
  const [dataModalDelete, setDataModalDelete] = useState({});
  const toggleModalDelete = () => {
    setModalDelete(!modalDelete);
  };
  const handleModalDelete = (title, id) => {
    setDataModalDelete({
      title: title,
      idNews: id,
      activeTab: props.activeTab,
    });
    toggleModalDelete();
  };
  //modal TIdak lolos
  const [modalTidakLolos, setModalTidakLolos] = useState(false);
  const [dataModalTidakLolos, setDataModalTidakLolos] = useState({});
  const toggleModalTidakLolos = () => {
    setModalTidakLolos(!modalTidakLolos);
  };
  const handleModalTidakLolos = (data) => {
    setDataModalTidakLolos({
      activeTab: props.activeTab,
      data: data,
    });
    toggleModalTidakLolos();
  };
  //modal tidak disetujui
  const [modalTidakDisetujui, setModalTidakDisetujui] = useState(false);
  const [dataModalTidakDisetujui, setDataModalTidakDisetujui] = useState({});
  const toggleModalTidakDisetujui = () => {
    setModalTidakDisetujui(!modalTidakDisetujui);
  };
  const handleModalTidakDisetujui = (data) => {
    setDataModalTidakDisetujui({
      activeTab: props.activeTab,
      data: data,
    });
    toggleModalTidakDisetujui();
  };
  //modal Cancelled
  const [modalCancelled, setModalCancelled] = useState(false);
  const [dataModalCancelled, setDataModalCancelled] = useState({});
  const toggleModalCancelled = () => {
    setModalCancelled(!modalCancelled);
  };
  const handleModalCancelled = (data) => {
    setDataModalCancelled({
      activeTab: props.activeTab,
      data: data,
    });
    toggleModalCancelled();
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  const handleDataUbahJadwal = (status, data) => {
    props.ubahJadwal(data);
    props.setTab(props.activeTab ?? "1");
    history.push({
      pathname: "/content-partner/ubah-jadwal",
      state: { tab: props.activeTab, status: status },
    });
  };

  const handleClick = (status, kurator, data) => {
    switch (status) {
      case "Tidak Lolos Kurasi":
        console.log("tidak");
        handleModalTidakLolos(data);
        break;
      case "tidak disetujui":
        console.log("tidak disetujui");
        handleModalTidakDisetujui(data);
        break;
      case "cancelled":
        console.log("cancelled");
        handleModalCancelled(data);
        break;
    }
    switch (kurator) {
      case "Tidak Lolos Kurasi":
        console.log("Tidak Lolos Kurasi");

        handleModalTidakLolos(data);
        break;
    }
  };

  // const ButtonAction = ({ title, id, status, data }) => {
  //   return (
  //     <div className="wrapperBtnAction d-flex align-items-center">
  //       {status == "Tidak Lolos" ? (
  //         <></>
  //       ) : (
  //         <Button
  //           className="btn btn-rounded btnAskArchieveTable"
  //           onClick={() => handleDataUbahJadwal(status, data)}>
  //           Ubah Jadwal
  //         </Button>
  //       )}

  //       <img
  //         className="btnAction"
  //         src={IconDelete}
  //         onClick={() => handleModalDelete(title, id)}></img>
  //     </div>
  //   );
  // };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 200,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.length !== 0
        ? items.map((it, index) => ({
            no: index + 1,
            judul: it.title ?? "-",
            kategori: it.category ?? "-",
            status: it ? (
              it.status_kurator[it.status_kurator.length - 1].status ? (
                <>
                  <span
                    onClick={() =>
                      handleClick(
                        it.status,
                        it.status_kurator[it.status_kurator.length - 1].status,
                        it
                      )
                    }
                    className={
                      it.status_kurator[it.status_kurator.length - 1].status == "tidak disetujui" ||
                      it.status_kurator[it.status_kurator.length - 1].status == "Tidak Lolos Kurasi"
                        ? "declined"
                        : it.status == "pending"
                        ? "pending"
                        : it.status == "cancelled"
                        ? "cancelled"
                        : ""
                    }>
                    {it.status_kurator[it.status_kurator.length - 1].status == "tidak disetujui"
                      ? "tidak disetujui"
                      : it.status_kurator[it.status_kurator.length - 1].status ==
                        "Tidak Lolos Kurasi"
                      ? "Tidak Lolos"
                      : it.status == "pending"
                      ? "Pending"
                      : it.status == "cancelled"
                      ? "Cancelled"
                      : ""}
                  </span>
                  <span className="ml-2">
                    <img
                      src={
                        it.status_kurator[it.status_kurator.length - 1].status == "tidak disetujui"
                          ? IconCommentRed
                          : it.status_kurator[it.status_kurator.length - 1].status ==
                            "Tidak Lolos Kurasi"
                          ? IconCommentRed
                          : it.status == "pending"
                          ? ""
                          : it.status == "cancelled"
                          ? IconCommentBlack
                          : ""
                      }></img>
                  </span>
                </>
              ) : (
                "-"
              )
            ) : (
              "-"
            ),
            preview: [
              <a key={index} href={it.link ?? "-"} target="_blank">
                <center>
                  <img
                    alt={it.title ?? "-"}
                    className="wrapperImg"
                    src={`${process.env.REACT_APP_BE_URL}/${it.details.url ?? "-"}`}></img>
                </center>
              </a>,
            ],
          }))
        : [],
  };

  return (
    <React.Fragment>
      <FilterCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        selectedDate={handleSelectedDate}
      />
      <ModalTidakLolos
        dataModalTidakLolos={dataModalTidakLolos}
        modalTidakLolos={modalTidakLolos}
        toggleModalTidakLolos={toggleModalTidakLolos}
      />
      <ModalTidakDisetujui
        dataModalTidakDisetujui={dataModalTidakDisetujui}
        modalTidakDisetujui={modalTidakDisetujui}
        toggleModalTidakDisetujui={toggleModalTidakDisetujui}
      />
      <ModalCancelled
        dataModalCancelled={dataModalCancelled}
        modalCancelled={modalCancelled}
        toggleModalCancelled={toggleModalCancelled}
      />

      <ModalDelete
        dataModalDelete={dataModalDelete}
        handleAlert={{
          setNotif: setNotif,
          setAlert: setIsSuccessSubmitted,
          setMessage: setMessage,
        }}
        modalDelete={modalDelete}
        fetchData={handleFetch}
        toggleModalDelete={toggleModalDelete}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  <Container>
                    <Alert color={notif ? "success" : "danger"} isOpen={isSuccessSubmitted}>
                      {message}
                    </Alert>
                  </Container>
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">Tidak ada konten archieve</div>
                  ) : (
                    <MDBDataTable
                      id="tableArchievedNews"
                      noBottomColumns={true}
                      className="text-center"
                      responsive
                      disableRetreatAfterSorting
                      striped
                      bordered
                      data={data}
                      displayEntries={false}
                      pagesAmount={5}
                      entries={10}
                      searching={false}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layout } = state.Layout;
  return { layout };
};
export default connect(mapStatetoProps, { ubahJadwal, setTab })(
  withRouter(withNamespaces()(TableAchieveKontenBerita))
);
