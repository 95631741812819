import {
  DATA_DETAIL_KONTEN,
  DATA_AJUKAN_KEMBALI,
  DATA_UBAH_JADWAL,
  DATA_UPDATE_REVISI,
  DATA_NOTIF,
  DATA_DUMMY_SURVEY,
  DATA_LOG_HISTORY,
} from "./actionTypes";
//detail data konten konten patner
export const detailData = (data) => {
  return {
    type: DATA_DETAIL_KONTEN,
    payload: { data },
  };
};

//detail data ajukan kembali content patner
export const ajukanData = (data) => {
  return {
    type: DATA_AJUKAN_KEMBALI,
    payload: { data },
  };
};

export const updateData = (data) => {
  return {
    type: DATA_UPDATE_REVISI,
    payload: { data },
  };
};

//detail data ubah jadwal content patner
export const ubahJadwal = (data) => {
  return {
    type: DATA_UBAH_JADWAL,
    payload: { data },
  };
};

//get data Notif
export const getDatasNotif = (data) => {
  return {
    type: DATA_NOTIF,
    payload: { data },
  };
};

//survey
export const setDummySurvey = (data) => {
  return {
    type: DATA_DUMMY_SURVEY,
    payload: data.map((el) => el),
  };
};

// LOG HISTORY BI
export const setDataLogHistory = (data) => {
  return {
    type: DATA_LOG_HISTORY,
    payload: { data },
  };
};
