import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable, MDBModal } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../../components/Filter/filter";
import _ from "lodash";
// ------------------API-----------------------
import API from "../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconCheck from "../../../../assets/icon/IconCheck.svg";
import IconDecline from "../../../../assets/icon/IconDecline.svg";
import IconEdit from "../../../../assets/icon/IconEdit.svg";
import IconEmergency from "../../../../assets/icon/IconEmergency.svg";
import IconGreenCheckList from "../../../../assets/icon/IconGreenChecklist.svg";
import noImage from "../../../../assets/images/noImage.jpg";

// ------------------Component----------------
import ModalActived from "../../../../components/Admin/Popup/KontenDefault/ActivedContent";
import ModalPreviewContent from "../../../../components/Admin/Popup/KontenDefault/PreviewContent";
import ModalTidakLolos from "../../../../components/Admin/Popup/StatistikKonten/ModalTidakLolos";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import { detailData, setTabDetail } from "../../../../store/actions";
import ConfirmationCancel from "../../ConfirmationCancel";

const TableStatVideo = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  //notif
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [notif, setNotif] = useState(false);
  //alert
  const [alert, setAlert] = useState("");
  let typeContent = "";
  switch (props.activeTab) {
    case "1":
      typeContent = "video";
      break;
    case "2":
      typeContent = "banner";
      break;
    case "3":
      typeContent = "news";
      break;
    default:
      break;
  }
  const fetchAPI = async () => {
    setLoading(true);
    API.getTableKontenVideo(typeContent)
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setLoading(false);
        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];

        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          let data = _.partition(res.data.data, { status: "scheduled" }).flat();
          setRows(data);
        } else {
          setRows(result);
          //MUST tableKontenStatistikVideo DIRECT TO NOTIF
          document.getElementById("tableKontenStatistikVideo").rows[1].style.transition = "0.8s";
          document.getElementById("tableKontenStatistikVideo").rows[1].style.backgroundColor =
            "#e6f3ff";
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    fetchAPI();
  }, [refresh, props.idContent]);
  //throw data to parent for download
  const filterData = (item) => {
    //
    props.handleData(item);
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search, props.activeTab]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      let statusKurator = data.status_kurator[data.status_kurator.length - 1].status;
      let status = data.status;
      let emergency = data.emergency;
      const statusSearched =
        status === "submitted"
          ? emergency
            ? "emergency"
            : statusKurator === "Lolos Kurasi"
            ? "Lolos Kurasi"
            : statusKurator === "Tidak Lolos Kurasi"
            ? "Tidak Lolos Kurasi"
            : statusKurator === "Revisi"
            ? "Revisi"
            : statusKurator === "Sudah Revisi"
            ? "Sudah Revisi"
            : statusKurator
          : status === "declined"
          ? "Ditolak"
          : status === "approved"
          ? "Disetujui"
          : status === "scheduled"
          ? "Tayang"
          : data.status == "declined"
          ? "ditolak"
          : data.status == "Terjadwal"
          ? "Terjadwal"
          : data.status == "submitted"
          ? "Baru"
          : data.status == "canceled"
          ? "Dibatalkan"
          : "";
      const index = [
        props.activeTab !== "3" ? data.details.title : data.title,
        data.requested_by
          ? data.requested_by.organization
            ? data.requested_by.organization.name
              ? data.requested_by.organization.name
              : ""
            : ""
          : "",
        data.location.join(),
        data.category,
        `${data.proposed_duration}Jam`,
        `${moment(data.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")}\n s/d \n${moment(
          data.proposed_date.slice(-1),
          "DD-MM-YYYY"
        ).format("DD-MM-YYYY")} `,
        `${data.proposed_time[0][0]}`,
        data.list_provinsi.map((x) => x.name) ?? "-",
        statusSearched,
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }

  //modal detail
  // const [modalDetail, setModalDetail] = useState(false);
  // const [dataModalDetail, setDataModalDetail] = useState({});
  // const toggleModalDetail = () => {
  //   setModalDetail(!modalDetail);
  // };
  const handleDetailContent = (data) => {
    props.setTabDetail("video");
    props.detailData(data);

    history.push("/admin/konten-statistik-detail?tab=video&konten=statistic");
  };
  //modal Tidak Lolos
  const [modalTidakLolos, setModalTidakLolos] = useState(false);
  const [dataModalTidakLolos, setDataModalTidakLolos] = useState({});
  const toggleModalTidakLolos = () => {
    setModalTidakLolos(!modalTidakLolos);
  };
  const handleModalTidakLolos = (it) => {
    setDataModalTidakLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      comment: it.comment,
      activeTab: props.activeTab,
      commentAdmin: it.commentAdmin,
      kategoriTolak: it.kategoriTolak,
    });
    toggleModalTidakLolos();
  };
  //modal preview video
  const [modalPreviewContent, setModalPreviewContent] = useState(false);
  const [dataModalPreviewContent, setDataModalPreviewContent] = useState({});
  const toggleModalPreviewContent = () => {
    setModalPreviewContent(!modalPreviewContent);
  };
  const handleModalPreviewContent = (data) => {
    setDataModalPreviewContent({
      title:
        props.activeTab == "3"
          ? data.title
            ? data.title
            : "-"
          : data.details.title
          ? data.details.title
          : "-",
      url: data.details.url ?? "-",
      type: data.type ?? "-",
    });
    toggleModalPreviewContent();
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const [dataCancel, setDataCancel] = useState([]);
  const toggleConfirm = (data) => {
    setOpenConfirm(!openConfirm);
    setDataCancel(data);
  };

  const [alertNonAktif, setAlertNonAktif] = useState("");
  const [messageNonAktif, setMessageNonAktif] = useState("");

  const cancelContentClick = () => {
    API.cancelContent({
      typeContent: "video",
      idContent: dataCancel._id,
    })
      .then((values) => {
        setOpenConfirm(false);
        setAlertNonAktif("success");
        setMessageNonAktif(dataCancel.title ? dataCancel.title : dataCancel.details.title);
        setRows([]);
        fetchAPI();
        setTimeout(() => {
          setAlertNonAktif("");
        }, 3000);
      })
      .catch((err) => {
        setAlertNonAktif("err");
        setTimeout(() => {
          setAlertNonAktif("");
        }, 3000);
      });
  };

  const ButtonAction = ({ data }) => {
    return data.status == "scheduled" ? (
      <div>
        <Button
          style={{ width: "100%", paddingTop: "2px", paddingBottom: "2px", fontSize: "11px" }}
          onClick={() => handleDetailContent(data)}
          className="btn-rounded btnDetail">
          Detail
        </Button>
        <Button
          style={{ width: "100%", paddingTop: "2px", paddingBottom: "2px", fontSize: "11px" }}
          onClick={() => toggleConfirm(data)}
          className="btn-rounded btnCancel mt-2">
          Cancel
        </Button>
      </div>
    ) : (
      <div>
        {/* <Button
          style={{ width: "100%", paddingTop: "2px", paddingBottom: "2px", fontSize: "11px" }}
          onClick={() => toggleConfirm(data)}
          className="btn-rounded btnCancel"
          disabled>
          Cancel
        </Button> */}
      </div>
    );
  };
  const PreviewComponent = ({ it }) => {
    return (
      <center style={{ width: "fit-content" }}>
        {!it.tanggal_pembuatan.includes("2019") ? (
          <img
            alt={props.activeTab == "3" ? it.title : it.details.title}
            className="wrapperImgYT"
            onClick={() => handleModalPreviewContent(it)}
            src={
              it.details
                ? it.details.thumbnail
                  ? `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.thumbnail : ""}`
                  : `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.url : ""}`
                : "-"
            }></img>
        ) : (
          <img
            alt={props.activeTab == "3" ? it.title : it.details.title}
            className="wrapperImgYT"
            src={noImage}></img>
        )}
      </center>
    );
  };

  const Status = ({ it }) => {
    let status = it.status;
    let statusKurator = it.status_kurator[it.status_kurator.length - 1].status;
    let emergency = it.emergency;

    if (status === "submitted") {
      if (typeof emergency !== "undefined") {
        return (
          <div
            className="d-flex pointer"
            // onClick={() => handleModalDoc(it.details.title, it)}
          >
            <img src={IconEmergency}></img>
            <label
              // onClick={() => handleModalDoc(it.details.title, it)}
              key={it._id}
              className="mx-auto py-1 pointer"
              style={{ color: "#C42127" }}>
              Emergency
            </label>
          </div>
        );
      } else {
        switch (statusKurator) {
          case "Tidak Lolos Kurasi":
            return (
              <label
                // onClick={() => handleModalTidakLolos(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#C42127" }}>
                Tidak Lolos Kurasi
              </label>
            );
            break;
          case "Lolos Kurasi":
            return (
              <label
                // onClick={() => handleModalLolos(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#55BA59" }}>
                Lolos Kurasi
              </label>
            );
            break;
          case "Revisi":
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                Revisi
              </label>
            );
            break;
          case "Sudah Revisi":
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                Sudah Revisi
              </label>
            );
            break;
          default:
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                {_.startCase(_.toLower(statusKurator))}
              </label>
            );
            break;
        }
      }
    } else {
      switch (status) {
        case "declined":
          return (
            <label
              onClick={() => handleModalTidakLolos(it)}
              key={it._id}
              className="mx-auto py-1"
              style={{ color: "#c42127" }}>
              Ditolak
            </label>
          );
          break;
        case "approved":
          return (
            <label
              // onClick={() => handleModalLolos(it)}
              key={it._id}
              className="mx-auto py-1"
              style={{ color: "#c42127" }}>
              Disetujui
            </label>
          );
          break;
        case "scheduled":
          return (
            <label
              // onClick={() => handleModalLolos(it)}
              key={it._id}
              className="mx-auto py-1">
              Tayang
            </label>
          );
          break;

        default:
          return (
            //todo
            <label
              // onClick={() => handleModalLolos(it)}
              // style={{ color: "#c42127" }}
              key={it._id}
              className="mx-auto py-1">
              {_.startCase(_.toLower(status))}
            </label>
          );
          break;
      }
    }
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 100,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori Lokasi",
        field: "kategorilokasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      {
        label: "Durasi",
        field: "durasi",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tanggal Tayang",
        field: "tanggaltayang",
        sort: "asc",
        width: 150,
      },
      {
        label: "Jam Tayang",
        field: "jamtayang",
        sort: "asc",
        width: 150,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 150,
      },
      {
        label: "",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, i) => ({
            no: i + 1,
            judul:
              props.activeTab == "3"
                ? it.title
                  ? it.title
                  : "-"
                : it.details.title
                ? it.details.title
                : "-",
            organisasi: it?.requested_by?.organization?.name ?? "-",
            kategorilokasi:
              it.location.length > 2
                ? `${it ? (it.location[0] ? it.location[0] : "-") : "-"}, ${
                    it ? (it.location[1] ? it.location[1] : "-") : "-"
                  }, ${it ? (it.location[2] ? it.location[2] : "-") : "-"}...`
                : it.location.length > 1
                ? `${it.location[0] ? it.location[0] : "-"},${
                    it.location[1] ? it.location[1] : "-"
                  }`
                : `${it.location[0] ? it.location[0] : "-"}`,
            kategori: it.category ?? "-",
            durasi: it.proposed_duration + "Jam" ?? "-",
            tanggaltayang:
              it.proposed_date.length > 0
                ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}\n s/d \n${moment(it.proposed_date.slice(-1), "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )} `
                : "-",
            jamtayang: it.proposed_time ? `${it.proposed_time[0][0]}` : "-",
            provinsi:
              it.list_provinsi.length > 2
                ? `${it ? (it.list_provinsi[0] ? it.list_provinsi[0].name : "-") : "-"}, ${
                    it ? (it.list_provinsi[1] ? it.list_provinsi[1].name : "-") : "-"
                  }, ${it ? (it.list_provinsi[2] ? it.list_provinsi[2].name : "-") : "-"}...`
                : it.list_provinsi.length > 1
                ? `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"},${
                    it.list_provinsi[1] ? it.list_provinsi[1].name : "-"
                  }`
                : `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"}`,
            status: <Status it={it ?? "-"} />,
            preview: [<PreviewComponent it={it} />],
            aksi: <ButtonAction data={it ? it : ""} />,
          })),
  };

  return (
    <React.Fragment>
      <ConfirmationCancel
        modalCancel={openConfirm}
        toggleConfirm={() => toggleConfirm()}
        onCancelClick={() => cancelContentClick()}
        dataCancel={dataCancel}
      />
      {alert == "successActivedContent" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil di Aktifkan"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alert == "failedActivedContent" ? (
        <SweetAlert
          timeout={3}
          title={"Konten Tidak Berhasil di Aktifkan"}
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : alert == "failedFetch" ? (
        <SweetAlert
          timeout={3}
          error
          title="User tidak berhasil di kurasi"
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : (
        ""
      )}
      {alertNonAktif == "success" ? (
        <SweetAlert
          timeout={3}
          title={`Berhasil menonaktifkan konten ${messageNonAktif}`}
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alertNonAktif == "err" ? (
        <SweetAlert
          timeout={3}
          title={"Gagal menonaktifkan konten"}
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : (
        ""
      )}
      <ModalPreviewContent
        ModalPreviewContent={modalPreviewContent}
        dataModalPreviewContent={dataModalPreviewContent}
        toggleModalPreviewContent={toggleModalPreviewContent}
        fetchData={handleFetch}
        activeTab={typeContent}
      />
      <ModalTidakLolos
        dataModalTidakLolos={dataModalTidakLolos}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalTidakLolos={modalTidakLolos}
        toggleModalTidakLolos={toggleModalTidakLolos}
      />
      {/* <ModalActived
        dataModalActivedContent={dataModalDetail}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalActivedContent={modalDetail}
        toggleModalActivedContent={toggleModalDetail}
        activeTab={typeContent}
      /> */}

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">
                      Tidak ada konten yang dapat di aktifkan
                    </div>
                  ) : (
                    <MDBDataTable
                      id="tableKontenStatistikVideo"
                      noBottomColumns={true}
                      className="text-center"
                      responsive
                      disableRetreatAfterSorting
                      striped
                      bordered
                      data={data}
                      displayEntries={false}
                      entriesOptions={[5, 10, 15, 20]}
                      pagesAmount={5}
                      entries={10}
                      searching={false}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, { detailData, setTabDetail })(TableStatVideo);
