import React, { useState } from "react";
import { MDBModal } from "mdbreact";

const ConfirmationCancel = (props) => {
  const onCancel = () => {
    props.onCancelClick()
  };

  return (
    <MDBModal isOpen={props.modalCancel} toggle={props.toggleConfirm} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleDecline">CANCEL KONTEN</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={props.toggleConfirm}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">{
                props.dataCancel 
                    ? props.dataCancel.title 
                        ? props.dataCancel.title 
                        : props.dataCancel.details 
                            ? props.dataCancel.details.title 
                            : "" 
                    : ""}</h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleDecline">Anda yakin ingin cancel konten ini?</h4>
          </div>

          <div className="mx-auto wrapperBtnVerification">
            <button
              type="button"
              onClick={onCancel}
              className="w-100 btn btnDecline btn-rounded">
              Cancel Content
            </button>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ConfirmationCancel;
