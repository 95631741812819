import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";

const Example = (props) => {
  const location = useLocation();
  const [data, setData] = useState(
    location.state
      ? {
          name: location.state.name ?? "-",
          create_date: location.state.register_date ?? "-",
          org: location.state.organization ?? "-",
          content:
            Object.values(location.state.content_count).length !== 0
              ? location.state.content_count.video +
                  location.state.content_count.banner +
                  location.state.content_count.news ?? "-"
              : "-",
          content_video:
            Object.values(location.state.content_count).length !== 0
              ? location.state.content_count.video ?? "-"
              : "-",
          content_banner:
            Object.values(location.state.content_count).length !== 0
              ? location.state.content_count.banner ?? "-"
              : "-",
          content_news:
            Object.values(location.state.content_count).length !== 0
              ? location.state.content_count.news ?? "-"
              : "-",
          last_update: location.state.last_content_create ?? "-",
        }
      : {
          name: "-",
          create_date: "-",
          org: "-",
          content: "-",
          content_video: "-",
          content_banner: "-",
          content_news: "-",
          last_update: "-",
        }
  );

  return (
    <Table borderless responsive className="text-center">
      <thead>
        <tr>
          <th>Nama</th>
          <th>Organisasi</th>
          <th>Tanggal Registrasi</th>
          <th>Jumlah Konten</th>
          <th>Total Video</th>
          <th>Total Banner</th>
          <th>Total Berita</th>
          <th>Terakhir Upload Konten</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">{data.name}</th>
          <td>{data.org}</td>
          <td>
            {data.create_date
              ? data.create_date !== "-"
                ? moment(data.create_date).format("DD-MM-YYYY")
                : "-"
              : "-"}
          </td>
          {/* <td>{data.create_date}</td> */}
          <td>{data.content}</td>
          <td>{data.content_video}</td>
          <td>{data.content_banner}</td>
          <td>{data.content_news}</td>
          <td>
            {data.last_update
              ? data.last_update !== "-"
                ? moment(data.last_update).format("DD-MM-YYYY")
                : "-"
              : "-"}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default Example;
