import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import API from "../../../../services";

export default function DeletePopup(props) {
  var { modal, toggle, data } = props;
  // console.log(toggle)
  const handleDelete = (val) => {
    var param = {
      idArticle: val._id,
    };
    if (props.typeContent === "video") {
      param = val._id;
    }

    if (props.typeContent !== "video") {
      API.deleteArticel(param)
        .then((response) => {
          toggle();
          window.location.reload();
        })
        .catch((e) => {
          return e;
        });
    } else {
      API.postDeleteVideo(param)
        .then((response) => {
          toggle();
          window.location.reload();
        })
        .catch((e) => {
          return e;
        });
    }
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Hapus Artikel</ModalHeader>
        <ModalBody className="text-center">
          <h3 style={{ color: "#393939" }}>{data !== undefined && data.judul}</h3>
          <hr width={140} />
          <p>Anda yakin ingin Hapus Article ?</p>
          <div className="m-2">
            <Button onClick={() => handleDelete(data)} style={{ backgroundColor: "#C42127" }}>
              Hapus
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
