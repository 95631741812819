import React, { useState } from "react";
import { MDBModal } from "mdbreact";
import { Spinner } from "reactstrap";
import "./style.css";

// ------------------API-----------------------
import API from "../../../../../services";

const ModalVerif = ({ modalVerif, toggleModalVerif, dataModalVerif, handleAlert, fetchData }) => {
  const typeContent = dataModalVerif.activeTab == "1" ? "video" : dataModalVerif.activeTab == "2" ? "banner" : "news"
  const [isLoading, setIsLoading] = useState(false)

  let param = {
    idContent: dataModalVerif.id,
    type: typeContent,
  };


  const onHandleSubmit = () => {
    setIsLoading(true)
    API.postApprovePersetujuanKonten(param)
      .then(async (res) => {
        setIsLoading(false)
        if (res.data.success) {
          new Promise(() => {
            toggleModalVerif();
            handleAlert.setAlert("success");
            setTimeout(() => {
              handleAlert.setAlert("");
            }, 2000);
          }, fetchData());
        } else {
          new Promise(() => {
            toggleModalVerif();
            handleAlert.setAlert("failedFetch");
            setTimeout(() => {
              handleAlert.setAlert("");
            }, 2000);
          }, fetchData());
        }
      })
      .catch((e) => {
        setIsLoading(false)
        new Promise(() => {
          toggleModalVerif();
          handleAlert.setAlert("failedApprove");
          setTimeout(() => {
            handleAlert.setAlert("");
          }, 2000);
        }, fetchData());
      });
  };

  return (
    <MDBModal isOpen={modalVerif} toggle={toggleModalVerif} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleApproved">SETUJUI KONTEN</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalVerif}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">{dataModalVerif.title ?? "-"}</h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleApproved">Anda yakin ingin Menyetujui Konten ini?</h4>
          </div>

          <div className="mx-auto wrapperBtnVerification">
            {
              isLoading ?
              <center><Spinner color="success"/></center>
              :
              <button
                type="button"
                onClick={onHandleSubmit}
                className="w-100 btn btnApproved btn-rounded">
                Setujui
              </button>
            }
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalVerif;
