import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const KontenSupportAsync = Loadable({
  loader: () =>
    import("../../../pages/Konten/Support" /* webpackChunkName: "KontenSupportAsync" */),
  loading: Loading,
});

export default KontenSupportAsync;
