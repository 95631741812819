import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Row, Card, Col, Button, Spinner, CardBody } from "reactstrap";
import Skeleton from "react-loading-skeleton";

// import "tui-chart/dist/tui-chart.css";
import { PieChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "./style.module.css";
import "./toastui.scss";

// import API from "../../../../services";

var theme = {
  // series: {
  series: {
    colors: ["#8CC6C9", "#E2B674", "#C18ECE"],
    label: {
      color: "#fff",
      fontFamily: "sans-serif",
    },
  },
  // },
};

export default function CardChart(props) {
  const DonutChartToast = (param, type) => {
    const data = {
      categories: ["User Behavior"],
      series: [
        {
          name: "Video",
          data: param
            ? type == "card"
              ? param.card
                ? param.card.Video ?? 0
                : 0
              : param.popup
              ? param.popup.Video ?? 0
              : 0
            : 0,
        },
        {
          name: "Banner",
          data: param
            ? type == "card"
              ? param.card
                ? param.card.Banner ?? 0
                : 0
              : param.popup
              ? param.popup.Banner ?? 0
              : 0
            : 0,
        },
        {
          name: "Berita",
          data: param
            ? type == "card"
              ? param.card
                ? param.card.News ?? 0
                : 0
              : param.popup
              ? param.popup.News ?? 0
              : 0
            : 0,
        },
      ],
    };

    const options = {
      chart: {
        width: 300,
        height: 350,
        format: function (value, chartType, areaType, valuetype, legendName) {
          if (areaType === "makingSeriesLabel") {
            // formatting at series area
            value = value;
          }

          return value;
        },
      },
      series: {
        radiusRange: ["40%", "100%"],
        showLabel: true,
        labelFilter: function (labelInfo) {
          return labelInfo.ratio > 0.3; // Less than 5% does not expose.
        },
      },
      tooltip: {
        suffix: "",
      },
      legend: {
        align: "bottom",
        // visible: false,
        showCheckbox: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_UB_Klik",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("UBPieTheme", theme);
    options.theme = "UBPieTheme";

    return (
      <React.Fragment>
        <PieChart data={data} options={options} theme={theme} />
      </React.Fragment>
    );
  };


  return (
    <div>
      <Row className="mt-5 mb-5" style={{ height: "500px" }}>
        <Col md="12" lg="6">
          <Card body style={{ height: "100%" }}>
            <div className="p-3">
              <Row
                style={{
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}>
                <CardBody
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span className={style.titleCardBI}>
                    PERBANDINGAN KLIK TERBANYAK BERDASARKAN KONTEN
                  </span>
                </CardBody>
              </Row>
              <Row>
                <div className="mt-1 mx-auto">
                  <div className="d-flex justify-content-center align-items-center">
                    {props.loading ? (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <Spinner color="primary" />
                      </div>
                    ) : props.data && props.data.length !== 0 ? (
                      props.data.card ? (
                        props.data.card.Video == 0 &&
                        props.data.card.Banner == 0 &&
                        props.data.card.News == 0 ? (
                          <div style={{ marginTop: "150px" }}>
                            <span className={style.titleCardBI} style={{ fontSize: "35px" }}>
                              0
                            </span>
                          </div>
                        ) : (
                          DonutChartToast(props.data, "card")
                        )
                      ) : (
                        <div> 0 </div>
                      )
                    ) : (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md="12" lg="6">
          <Card body style={{ height: "100%" }}>
            <div className="p-3">
              <Row
                style={{
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}>
                <CardBody
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span className={style.titleCardBI}>
                    PERBANDINGAN KLIK POPUP TERBANYAK BERDASARKAN KONTEN
                  </span>
                </CardBody>
              </Row>
              <Row>
                <div className="mt-1 mx-auto">
                  <div className="d-flex justify-content-center align-items-center">
                    {props.loading ? (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <Spinner color="primary" />
                      </div>
                    ) : props.data && props.data.length !== 0 ? (
                      props.data.popup ? (
                        props.data.popup.Video == 0 &&
                        props.data.popup.Banner == 0 &&
                        props.data.popup.News == 0 ? (
                          <div style={{ marginTop: "150px" }}>
                            <span className={style.titleCardBI} style={{ fontSize: "35px" }}>
                              0
                            </span>
                          </div>
                        ) : (
                          DonutChartToast(props.data, "popup")
                        )
                      ) : (
                        <div> 0 </div>
                      )
                    ) : (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
