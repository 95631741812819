import React, { useMemo, useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

const StatistiKontenCP = (props) => {
  let { impressData, dates } = props;
  const [loading, setLoading] = useState(false);
  const SkeletonLoading = () => {
    return <Skeleton count={10} />;
  };
  let dateCat = impressData
    ? impressData[0]
      ? impressData[0].impress
        ? Object.keys(impressData[0].impress)
        : [0]
      : [0]
    : [0];
  console.log("dates", dateCat);
  let impresi = impressData
    ? impressData[0]
      ? impressData[0].impress
        ? Object.values(impressData[0].impress)
        : [0]
      : [0]
    : [0];
  let clicked = impressData
    ? impressData[0]
      ? impressData[0].impress
        ? Object.values(impressData[0].clicked)
        : [0]
      : [0]
    : [0];
  let dfp = impressData
    ? impressData[0]
      ? impressData[0].impress
        ? Object.values(impressData[0].unique_dfp)
        : [0]
      : [0]
    : [0];

  const series = [
    {
      name: "Impresi",
      data: impresi,
    },
    {
      name: "Klik",
      data: clicked,
    },
    { name: "Pengguna Unik", data: dfp },
  ];

  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#B48743", "#2C3280", "#55BA59"],
    dataLabels: { enabled: !1 },
    stroke: { width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5] },
    markers: { size: 0, hover: { sizeOffset: 6 } },
    xaxis: {
      categories: dateCat,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (e) {
              return e;
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e;
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e;
            },
          },
        },
      ],
    },
    grid: { borderColor: "#f1f1f1" },
  };
  return (
    <Row className="mt-5 mb-5 d-flex justify-content-center">
      <Col lg={8}>
        <Card>
          <CardBody>
            {loading ? (
              <ReactApexChart
                id="KontenChart"
                options={options}
                series={series}
                type="line"
                height="380"
              />
            ) : (
              <SkeletonLoading />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default StatistiKontenCP;
