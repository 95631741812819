import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";

const ModalPreviewVideo = ({ modalKonten, toggleModalKonten, dataModalKonten }) => {
  return (
    <MDBContainer>
      <MDBModal size="lg" isOpen={modalKonten} toggle={toggleModalKonten} centered={true}>
        <MDBModalHeader centered toggle={toggleModalKonten}>
          {dataModalKonten.title}
        </MDBModalHeader>
        <MDBModalBody>
          <div className="player-wrapper">
            <div className="wrapperVideo">
              <iframe
                className="video"
                title={dataModalKonten.title ?? "-"}
                width="100%"
                height="500"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
                src={
                  dataModalKonten.type === "youtube"
                    ? `//www.youtube.com/embed/${
                        typeof dataModalKonten.url === "undefined" ? "" : dataModalKonten.url
                      }`
                    : `${process.env.REACT_APP_BE_URL}/${
                        typeof dataModalKonten.url === "undefined" ? "" : dataModalKonten.url
                      }`
                }></iframe>
            </div>
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalPreviewVideo;
