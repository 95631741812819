import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data, props.data2, props.activeTab, props.activeSubTab2]);
  let judul;
  if (props.activeTab == "1") {
    judul = "Konten Partner";
  } else {
    switch (props.activeSubTab2) {
      case "1":
        judul = "KONTEN VIDEO";
        break;
      case "2":
        judul = "KONTEN BANNER";
        break;
      case "3":
        judul = "KONTEN BERITA";
        break;
      default:
        break;
    }
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`DATA ${judul} ${role}`}</thead>
      <thead>
        <tr>
          {props.activeTab == "2" ? (
            <>
              <th>No</th>
              <th>Judul</th>
              <th>Kategori</th>
              <th>Durasi</th>
              <th>Provinsi</th>
              <th>Tanggal Tayang</th>
              <th>Tanggal Pengajuan</th>
              <th>Status</th>
              <th style={{ width: "300px" }}>Preview</th>
            </>
          ) : (
            <>
              <th>No</th>
              <th>Nama</th>
              <th>Organisasi</th>
              <th>Tanggal Registrasi</th>
              <th>Jumlah Konten</th>
              <th>Total Video</th>
              <th>Total Banner</th>
              <th>Total Berita</th>
              <th>Terakhir Upload Konten</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {props.activeTab == "2" ? (
          <>
            {props.data2.map((data, index) => 
            {
              let statusKurator = data.status_kurator[data.status_kurator.length - 1].status;
              let status = data.status;
              let emergency = data.emergency;
              const statusSearched =
                status === "submitted"
                  ? emergency
                    ? "emergency"
                    : statusKurator === "Lolos Kurasi"
                    ? "Lolos Kurasi"
                    : statusKurator === "Tidak Lolos Kurasi"
                    ? "Tidak Lolos Kurasi"
                    : statusKurator === "Revisi"
                    ? "Revisi"
                    : statusKurator === "Sudah Revisi"
                    ? "Sudah Revisi"
                    : statusKurator
                  : status === "declined"
                  ? "Ditolak"
                  : status === "approved"
                  ? "Disetujui"
                  : status === "scheduled"
                  ? "Tayang"
                  : data.status == "declined"
                  ? "ditolak"
                  : data.status == "Terjadwal"
                  ? "Terjadwal"
                  : data.status == "submitted"
                  ? "Baru"
                  : data.status == "canceled"
                  ? "Dibatalkan"
                  : "";
              return(<tr
                key={index}
                style={{ height: "200px", textAlign: "center", verticalAlign: "middle" }}>
                <td>{index + 1}</td>
                <td>{data.title ? data.title : data.details.title ? data.details.title : "-"}</td>
                <td>{data.category ?? "-"}</td>
                <td>{data.proposed_duration ?? "-"}</td>
                <td>
                  {data.list_provinsi.length > 0
                    ? data.list_provinsi.map((prov) => prov.name).toString()
                    : "-"}
                </td>
                <td>
                  {data.proposed_date.length > 0
                    ? `${moment(data.proposed_date[0], "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      )}\n s/d \n${moment(data.proposed_date.slice(-1), "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      )} `
                    : "-"}
                </td>
                <td>
                  {data.proposed_date.length > 0
                    ? `${moment(data.proposed_date[0], "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      )}\n s/d \n${moment(data.proposed_date.slice(-1), "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      )} `
                    : "-"}
                </td>
                <td>
                  {statusSearched}
                </td>
                <td>
                  <img
                    src={
                      props.activeSubTab2 === "1"
                        ? `${process.env.REACT_APP_SELF_URL}${data.details.thumbnail}`
                        : `${process.env.REACT_APP_SELF_URL}/${data.details.url}`
                    }
                    height="100"
                    style={{
                      objectFit: "cover",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}></img>
                </td>
              </tr>)
            })}
          </>
        ) : (
          <>
            {props.data.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.name}</td>
                <td>{data.organization}</td>
                <td>{moment(data.register_date).format("DD-MM-YYYY")}</td>
                <td>
                  {Object.values(data.content_count).length !== 0
                    ? data.content_count.news +
                        data.content_count.banner +
                        data.content_count.video ?? "-"
                    : "-"}
                </td>
                <td>
                  {Object.values(data.content_count).length !== 0 ? data.content_count.video : "-"}
                </td>
                <td>
                  {Object.values(data.content_count).length !== 0 ? data.content_count.banner : "-"}
                </td>
                <td>
                  {Object.values(data.content_count).length !== 0 ? data.content_count.news : "-"}
                </td>
                <td>
                  {data.last_content_create === "-"
                    ? "-"
                    : moment(data.last_content_create).format("DD-MM-YYYY")}
                </td>
              </tr>
            ))}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
