import React, { useState, useEffect } from "react";
import { MDBModal, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./style.css";
import moment from "moment";
// ------------------API-----------------------
import API from "../../../../../../services";
import _ from "lodash";
// import get from "lodash.get";
import { Row, Container } from "reactstrap";

const ModalDetailSupport = ({
  modalDetailPengguna,
  toggleModalDetailPengguna,
  dataModalDetailPengguna,
}) => {
  let data = dataModalDetailPengguna !== undefined ? dataModalDetailPengguna.data : [];
  const StatusComponent = () => {
    switch (data.status) {
      case "Non Aktif":
        return (
          <span className="ml-2 pointer" style={{ color: "#C18ECE" }}>
            Non Aktif&nbsp;
          </span>
        );
        break;
      case "declined":
        return (
          <span className="ml-2 pointer" style={{ color: "#C42127" }}>
            Ditolak&nbsp;
          </span>
        );
        break;
      case "dormant":
        return (
          <span className="ml-2 " style={{ color: "#E5BA4E" }}>
            Dormant&nbsp;
          </span>
        );
        break;
      case "confirmation":
        return (
          <span className="ml-2 text-left" style={{ color: "#55BA59" }}>
            Belum Dikonfirmasi
          </span>
        );
        break;

      default:
        return <span className="ml-2">Aktif&nbsp;</span>;
        break;
    }
  };

  if (data !== undefined)
    return (
      <MDBModal
        id="ModalDetailPengguna"
        isOpen={modalDetailPengguna}
        toggle={toggleModalDetailPengguna}
        size="lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="w-100 text-center">
              <p className="topTitleEdit">DETAIL PENGGUNA</p>
            </div>
            <button
              type="button"
              className="close pull-right"
              aria-label="Close"
              onClick={toggleModalDetailPengguna}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
            <div className="w-40 mx-auto wrapperSubTitleVerif">
              {/* <h4 className="subTitle">{data.title.length == 0 ? data.details.title : data.title}</h4> */}
            </div>

            <Container>
              <MDBTable responsive borderless id="TableDetailKonten">
                <MDBTableHead>
                  <tr>
                    <th style={{ width: "140px" }}>Username</th>
                    <th>Nama</th>
                    <th>Email</th>
                    <th >Waktu Registrasi</th>
                    <th>Aktivitas Terbaru</th>
                    <th>Status</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td>{data.username ?? "-"}</td>
                    <td>{data.name ?? "-"}</td>
                    <td>{data.email ?? "-"}</td>
                    <td>
                      {
                        _.get(data, "tanggal_pembuatan", '-') !== '-' ?
                          moment(data.tanggal_pembuatan).format("DD-MM-YYYY")
                          : '-'
                      }
                    </td>
                    <td>
                      {
                        _.get(data, "lastActivity", '-') !== '-' ?
                          moment(data.lastActivity).format("DD-MM-YYYY")
                          : '-'
                      }
                    </td>
                    {/* <td>{moment(data.tanggal_pembuatan).format("DD-MM-YYYY")}</td> */}
                    {/* <td>{moment(data.tanggal_pembuatan).format("DD-MM-YYYY")}</td> */}
                    <td><StatusComponent /></td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </Container>
            <Container className="d-flex mb-3">
              <Row className="text-center col-lg-6 d-block">
                <div className="mx-auto ">Total Tiket</div>
                <div className="mx-auto textDetail">{_.get(data, "countTicket", "-")}</div>
              </Row>
              <Row className="text-center col-lg-6 d-block">
                <div className="mx-auto ">Rata-Rata SLA Penyelesaian Tiket</div>
                <div className="mx-auto textDetail">{_.get(data, "averageTime", "-")}</div>
              </Row>
            </Container>
            <Container className="d-flex ml-3">
              <Row className="text-center col-lg-2 d-block">
                <div className="mx-auto">Phone</div>
                <div className="mx-auto textDetail">{_.get(data, "countPhone", "-")}</div>
              </Row>
              <Row className="text-center col-lg-2 d-block">
                <div className="mx-auto">Email</div>
                <div className="mx-auto textDetail">{_.get(data, "countEmail", "-")}</div>
              </Row>
              <Row className="text-center col-lg-2 d-block">
                <div className="mx-auto">Message</div>
                <div className="mx-auto textDetail">{_.get(data, "countMessage", "-")}</div>
              </Row>
            </Container>
          </div>
        </div>
      </MDBModal>
    );
  else {
    return <></>;
  }
};

export default ModalDetailSupport;
