import React from 'react'
import styles from '../Assets/css/register.module.css'
import Header from '../components/Header/Header'

import { Row, Col, Container } from "reactstrap";
import HomeBanner from '../components/Banner/HomeBanner';
import FormRegister from '../components/Register/FormRegister';


function RegisterCP(){
    return(
        <div style={{backgroundColor:"white"}}>
            <div>
            <Header />
            <HomeBanner />
                <div className={styles.lastSec}>
                <section>
                    <Row>
                        <Col xs="12" sm="12" md={{ size: "10", offset: "1" }} style={{padding:"0px"}}>
                            <div className={styles.secTit}>Silahkan Lakukan Pendaftaran Disini</div>
                            <div className={styles.secSub}>Isi semua kolom pendaftaran yang tersedia</div>
                            <FormRegister/>
                        </Col>
                    </Row>
                </section>

                <section style={{height:'100px', paddingTop:'20px'}}>
                    <Container className={styles.sectBorder}>
                        <div style={{marginTop:'10px', color:'#00438B', textAlign:'left'}}>Copyright 2020 All Rights Reserved</div>
                    </Container>
                </section>
                </div>
            </div>
        </div>
    )
}

export default RegisterCP