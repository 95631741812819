import React from "react";
import { CardBody, Card, Row, Button, Col, Spinner } from "reactstrap";

// STYLE
import style from "../style.module.css";

// ASSETS
import userIcon from "../../../assets/images/user.svg";
import surveiIcon from "../../../assets/images/survei.svg";
import faqIcon from "../../../assets/images/faq.svg";

export default function CardSurvey(props) {
  return (
    <div className="my-5">
      <Col lg="12" style={{ minHeight: "100%" }}>
        <Row className="text-center h-100" style={{ display: "flex", justifyContent: "center" }}>
          <Col md="12" lg="4">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={userIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>PENGUNJUNG MENGISI SURVEY</div>
            </Col>
            {props.loading ? (
              <div className="my-3">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueSurvey}>
                
                { /* props.data ? props.data.count.toLocaleString() ?? 0 : 0 */ }
                {/* { (props?.data?.gender?.pria + props?.data?.gender?.wanita).toLocaleString() ? 0 : 0 } */}
              </Col>
            )}
          </Col>
        </Row>
      </Col>
    </div>
  );
}
