import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";

const ModalDetailPreview = ({ modalDetailPreview, toggleModalDetailPreview, dataModalDetailPreview }) => {
  return (
    <MDBContainer >
      <MDBModal  size="lg" isOpen={modalDetailPreview} toggle={toggleModalDetailPreview} centered="true">
        <MDBModalHeader centered toggle={toggleModalDetailPreview}>
          {dataModalDetailPreview.title}
        </MDBModalHeader>
        <MDBModalBody>
          <div className="image-wrapper">
            <img
              onerror="this.onerror=null;this.src='http://example.com/existent-image.jpg';"
              alt={dataModalDetailPreview.title ?? "-"}
              src={`${dataModalDetailPreview.url}`}
              style={{ height: "41vh",  width: "inherit", objectFit: "cover" }}
              ></img>
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalDetailPreview;
