import React, { useEffect } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);

  return (
    <Table
      style={{ display: "none" }}
      id="DownloadTableXLSTraffikOrgProvJam"
      className="DownloadTableXLS">
      {/* <thead>{`DAFTAR NAMA ISP DI PROVINSI ${props.prov}`}</thead>
      <thead>
        <tr>{`PERIODE CUT OFF TANGGAL 01/01/2020 - ${props.date}`}</tr>
      </thead> */}
      <thead>
        <tr>
          <th>Jam Tayang</th>
          <th>Total Impresi</th>
          <th>Maks Impresi</th>
          <th>Total Klik</th>
          <th>Maks Klik</th>
          <th>Total DFP Unik</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
            <td>{data.jam ?? "-"}</td>
            <td>{data.total_impresi ?? "-"}</td>
            <td>{data.maks_impresi ?? "-"}</td>
            <td>{data.total_klik ?? "-"}</td>
            <td>{data.maks_klik ?? "-"}</td>
            <td>{data.total_dfp_unik ?? "-"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
