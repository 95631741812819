// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_btnOpen__2fDVA {\n  background-color: #fff !important;\n  border-color: #2c3280 !important;\n  border-radius: 20px !important;\n  padding-top: 2px !important;\n  padding-bottom: 2px !important;\n}\n\n.style_btnTextColorBlue__cynBj {\n  color: #2c3280 !important;\n  font-weight: bold !important;\n  font-size: 11px !important;\n}\n\n.style_btnOpen__2fDVA:hover {\n  opacity: 0.5 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"btnOpen": "style_btnOpen__2fDVA",
	"btnTextColorBlue": "style_btnTextColorBlue__cynBj"
};
module.exports = exports;
