import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import API from "../../../../services";
import SweetAlert from "react-bootstrap-sweetalert";

export default function PostingPopup(props) {
  var { modal, toggle, data } = props;

  const posting = (val) => {
    var param = {
      idArticle: val._id,
    };
    if (props.typeContent === "video") {
      param = {
        idVideoHome: val._id,
      };
    }

    if (props.typeContent !== "video") {
      API.postChangeStatusArticle(param)
        .then((response) => {
          toggle();
          window.location.reload();
        })
        .catch((e) => {
          console.error('postChangeStatusArticle', { error: e.message })
        });
    } else {
      API.changeStatusVideo(param)
        .then((response) => {
          toggle();
          window.location.reload();
        })
        .catch((e) => {
          console.error('changeStatusVideo', { error: e.message })
        });
    }
  };

  const typeContent = props.typeContent ? props.typeContent : "Artikel"

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Posting {typeContent}</ModalHeader>
        <ModalBody className="text-center">
          <h3 style={{ color: "#393939" }}>{data !== undefined && data.judul}</h3>
          <hr width={140} />
          <p>Anda yakin ingin Posting  {typeContent} ini pada Homepage?</p>
          <div className="m-2">
            <Button onClick={() => posting(data)} style={{ backgroundColor: "#2C3280" }}>
              Posting
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
