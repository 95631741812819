import React from 'react'
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';

function PopupVideo(props) {
    let a = Object.keys(props.data).length === 0 
            ? "" 
            : typeof props.data.video.content_id === 'undefined' 
                ? props.data.video
                : props.data.video.content_id
    return (
        <MDBContainer>
            <MDBModal size="lg" isOpen={props.modalVid} toggle={props.toggleVid} centered={true}>
                <MDBModalHeader toggle={props.toggleVid}>Video</MDBModalHeader>
                <MDBModalBody>
                    <h5 style={{textAlign: 'center'}}>
                        {typeof a.details === 'undefined' 
                            ? ""
                            : a.details.title}
                    </h5>
                    <div className='player-wrapper'>
                        <iframe title={a.title} width="100%" height="500" 
                            src={
                               a.type === 'youtube' 
                                ?   `https://www.youtube.com/embed/${
                                        typeof a.details === 'undefined' 
                                            ? ""
                                            : a.details.url
                                    }`
                                :   `${process.env.REACT_APP_BE_URL}/${
                                        typeof a.details === 'undefined' 
                                            ? ""
                                            : a.details.url
                                    }`
                            }
                        ></iframe>
                    </div>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    );
};
export default PopupVideo;