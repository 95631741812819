import React, { useState, useEffect } from "react";
import {
  Spinner,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Button,
  Input,
  ButtonDropdown,
} from "reactstrap";

import API from "../../../../services";

// ASSETS
import iconDown from "../../../../assets/icon/back.png";

// STYLES
import "./styles.css";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { setDataLogHistory } from "../../../../store/data/actions";

// AUTOCOMPLETE MATERIAL UI
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function FilterOrgProvLokasi(props) {
  //REDUX
  const dispatch = useDispatch();
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  const [dropdownOrg, setDropdownOrg] = useState(false);
  const toggleDropdownOrg = () => setDropdownOrg((prevState) => !prevState);
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown((prevState) => !prevState);
  const [dropdownProvince, setDropdownProvince] = useState(false);
  const toggleDropdownProvince = () => setDropdownProvince((prevState) => !prevState);
  const [dropdownKategori, setDropdownKategori] = useState(false);
  const toggleDropdownKategori = () => {
    if (idOrg !== "") {
      setDropdownKategori((prevState) => !prevState);
    } else {
      alert("Pilih Organisasi Terlebih Dahulu");
    }
  };

  const [pilihOrganization, setPilihOrganization] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_organization
      : ""
  );
  const [idOrg, setIdOrg] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.organization
      : ""
  );

  const [orgList, setOrgList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [kategoriList, setKategoriList] = useState([]);
  const [dataType, setDataType] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.data_type
      : "Video"
  );
  const [provinsi, setProvinsi] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_provinsi
      : "Semua Provinsi"
  );
  const [kategori, setKategori] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.content_category
      : "Semua Kategori Lokasi"
  );

  const [idProv, setIdProv] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.province
      : ""
  );

  const getOrgList = () => {
    API.getListOrg()
      .then((res) => {
        if (res.data.success) {
          setOrgList(res.data.data);
        } else {
          setOrgList([]);
        }
      })
      .catch((e) => {
        setOrgList(null);
      });
  };

  const getProvinceList = () => {
    API.getProvince()
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        } else {
          setProvinceList([]);
        }
      })
      .catch((e) => {
        setProvinceList(null);
      });
  };

  // useEffect(() => {
  //   setIdKonten("");
  //   setTitle("");
  //   setTglTayang([]);
  //   // setResetTitle(true);
  //   if (idOrg !== "") {
  //     getTitleKonten();
  //   }
  // }, [idOrg, dataType]);

  useEffect(() => {
    getProvinceList();
    getOrgList();
  }, []);

  const getData = () => {
    props.setLoading(true);
    props.setShowComponent(true);
    // setResetTitle(false);

    if (idOrg === "") {
      alert("Organisasi belum dipilih");
      props.setLoading(false);
      props.setShowComponent(false);
    } else {
      const param =
        idProv === "" && kategori === "Semua Kategori Lokasi"
          ? {
              organization: idOrg,
              type:
                dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
            }
          : idProv !== "" && kategori === "Semua Kategori Lokasi"
          ? {
              type:
                dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
              province: idProv,
              organization: idOrg,
            }
          : idProv === "" && kategori !== "Semua Kategori Lokasi"
          ? {
              type:
                dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
              organization: idOrg,
              category: kategori,
            }
          : idProv !== "" &&
            kategori !== "Semua Kategori Lokasi" && {
              type:
                dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
              province: idProv,
              organization: idOrg,
              category: kategori,
            };
      API.getBITrafficKontenLocation(param)
        .then((res) => {
          if (res.data.success) {
            if (kategori !== "Semua Kategori Lokasi") {
              var filterRes = res.data.data.filter((e) => {
                return e.location == kategori;
              });
              props.setData(res.data.data);
              props.setDataFilter(filterRes);
              props.setLoading(false);
            } else {
              props.setData(res.data.data);
              props.setDataFilter(res.data.data);
              props.setLoading(false);
            }
          } else {
            props.setDataFilter(null);
            props.setLoading(false);
            setTimeout(() => {
              alert(
                `Tidak ada data pada filter Organisasi ${pilihOrganization}, ${dataType}, provinsi ${provinsi}, kategori lokasi ${kategori}`
              );
            }, 500);
          }
        })
        .catch((e) => {
          props.setDataFilter(null);
          props.setLoading(false);
          setTimeout(() => {
            alert(
              `Tidak ada data pada filter Organisasi ${pilihOrganization}, ${dataType}, provinsi ${provinsi}, kategori lokasi ${kategori}`
            );
          }, 500);
        });
    }
  };

  const getKategoriList = () => {
    const param = {
      organization: idOrg,
      type: dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase(),
    };
    API.getBITrafficKontenLocation(param)
      .then((res) => {
        if (res.data.success) {
          setKategoriList(
            res.data.data.map((e) => {
              return e.location;
            })
          );
        } else {
          setKategoriList([]);
        }
      })
      .catch((e) => {
        setKategoriList(null);
      });
  };

  useEffect(() => {
    if (idOrg !== "") {
      getKategoriList();
    }
  }, [idOrg]);

  useEffect(() => {
    props.setDataSave({
      data_type: dataType,
      organization: idOrg,
      title_organization: pilihOrganization,
      title_provinsi: provinsi,
      province: idProv,
      content_category: kategori,
    });
    props.setType(dataType);
  }, [dataType, idOrg, pilihOrganization, provinsi, idProv, kategori]);

  useEffect(() => {
    if (
      Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.measure === "traffik"
    ) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (props.data) {
      if (props.data.length !== 0) {
      }
      if (kategori !== "Semua Kategori Lokasi") {
        var filterRes = props.data.filter((e) => {
          return e.location == kategori;
        });
        // props.setLoading(true);
        props.setDataFilter(filterRes);

        // setTimeout(() => {
        //   props.setLoading(false);
        // }, 200);
      }
    }
  }, [kategori]);

  const filterReset = () => {
    setPilihOrganization("");
    setIdOrg("");
    setDataType("Video");
    setProvinsi("Semua Provinsi");
    setIdProv("");
    setKategori("Semua Kategori Lokasi");
    props.setShowComponent(false);
  };

  const changeOrganization = (value) => {
    setPilihOrganization(value.name);
    setIdOrg(value._id);
  };
  const clearOrganization = () => {
    setPilihOrganization("");
    setIdOrg("");
  };

  return (
    <>
      <Row className="py-2 mb-2">
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {/* <ButtonDropdown style={{ width: "100%" }} isOpen={dropdownOrg} toggle={toggleDropdownOrg}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{pilihOrganization}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "150px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              {orgList === null ? (
                <DropdownItem>Terjadi Kesalahan Server</DropdownItem>
              ) : (
                orgList.map((e) => (
                  <DropdownItem
                    key={e._id}
                    onClick={(i) => {
                      setIdOrg(e._id);
                      setPilihOrganization(e.name);
                    }}>
                    {e.name}
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          </ButtonDropdown> */}
          <Autocomplete
            // classes={style.dropdownAutocomplete}
            id="combo-box-demo"
            options={orgList !== null ? orgList : []}
            defaultValue={{ name: pilihOrganization }}
            value={{ name: pilihOrganization }}
            getOptionLabel={(option) => {
              return option.name;
            }}
            onChange={(e, value) => {
              value !== null ? changeOrganization(value) : clearOrganization();
            }}
            renderOption={(option) => (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>{option.name}</div>
                </div>
              </React.Fragment>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // value={dataTitle}
                placeholder="Pilih Organisasi"
              />
            )}
          />
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <ButtonDropdown style={{ width: "100%" }} isOpen={dropdown} toggle={toggleDropdown}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{dataType}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Video");
                }}>
                Video
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Banner");
                }}>
                Banner
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Berita");
                }}>
                Berita
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <ButtonDropdown
            style={{ width: "100%" }}
            isOpen={dropdownProvince}
            toggle={toggleDropdownProvince}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{provinsi}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "150px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setProvinsi("Semua Provinsi");
                  setIdProv("");
                }}>
                Semua Provinsi
              </DropdownItem>
              {provinceList === null ? (
                <DropdownItem>Terjadi Kesalahan Server</DropdownItem>
              ) : (
                provinceList.map((e) => (
                  <DropdownItem
                    key={e._id}
                    onClick={(i) => {
                      setIdProv(e._id);
                      setProvinsi(e.name);
                    }}>
                    {e.name}
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <ButtonDropdown
            style={{ width: "100%" }}
            isOpen={dropdownKategori}
            toggle={toggleDropdownKategori}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{kategori}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "150px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setKategori("Semua Kategori Lokasi");
                }}>
                Semua Kategori Lokasi
              </DropdownItem>
              {kategoriList === null ? (
                <DropdownItem>Tidak Ada Data</DropdownItem>
              ) : (
                kategoriList.map((e, idx) => (
                  <DropdownItem
                    key={idx}
                    onClick={(i) => {
                      setKategori(e);
                    }}>
                    {e}
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
      <Row style={{ display: "flex", justifyContent: "flex-end" }}>
        <Col lg="1" className="p-0">
          <Button
            style={{
              backgroundColor: "#2C3280",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={() => {
              getData();
              dispatch(setDataLogHistory({}));
            }}>
            <span style={{ fontSize: "11px" }}>Cari</span>
          </Button>
        </Col>
        <Col lg="1" className="p-0">
          <Button
            disabled={
              idOrg == "" &&
              dataType == "Video" &&
              idProv == "" &&
              kategori == "Semua Kategori Lokasi"
            }
            style={{
              backgroundColor: "#C42127",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={() => {
              filterReset();
              dispatch(setDataLogHistory({}));
            }}>
            <span style={{ fontSize: "11px" }}>Reset</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}
