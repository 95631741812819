import React, { useState, useEffect } from "react";
import {
  Spinner,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Button,
  Input,
  ButtonDropdown,
} from "reactstrap";
import moment from "moment";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import API from "../../../../services";

// ASSETS
import iconDown from "../../../../assets/icon/back.png";

// STYLES
import "./styles.css";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { setDataLogHistory } from "../../../../store/data/actions";

export default function FilterOrgProv(props) {
  // REDUX
  const dispatch = useDispatch();
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  const [dropdownOrg, setDropdownOrg] = useState(false);
  const toggleDropdownOrg = () => setDropdownOrg((prevState) => !prevState);
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown((prevState) => !prevState);

  const [pilihOrganization, setPilihOrganization] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_organization
      : ""
  );
  const [idOrg, setIdOrg] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.organization
      : ""
  );
  const [idKonten, setIdKonten] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_id
      : ""
  );
  const [title, setTitle] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.survey_title
      : ""
  );

  const [orgList, setOrgList] = useState([]);
  const [tglTayang, setTglTayang] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [dataType, setDataType] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.data_type
      : "Video"
  );

  const getOrgList = () => {
    API.getListOrg()
      .then((res) => {
        if (res.data.success) {
          setOrgList(res.data.data);
        } else {
          setOrgList([]);
        }
      })
      .catch((e) => {
        setOrgList(null);
      });
  };

  const getTitleKonten = () => {
    const param = {
      type: dataType.toLocaleLowerCase() == "berita" ? "news" : dataType.toLocaleLowerCase(),
      org: idOrg,
    };
    API.getBITrafficKontenOrg(param)
      .then((res) => {
        if (res.data.success) {
          setTitleList(res.data.data);
        } else {
          setTitleList([]);
        }
      })
      .catch((e) => {
        setTitleList(null);
      });
  };

  useEffect(() => {
    setIdKonten("");
    setTitle("");
    getOrgList();
    setTglTayang([]);
    // setResetTitle(true);
    if (idOrg !== "") {
      getTitleKonten();
    }
  }, [idOrg, dataType]);

  const getData = () => {
    props.setLoading(true);
    props.setShowComponent(true);
    setTglTayang([]);
    // setResetTitle(false);

    if (idOrg === "") {
      alert("Organisasi belum dipilih");
      props.setLoading(false);
      props.setShowComponent(false);
    } else if (idKonten == "") {
      alert("Judul tidak ditemukan");
      props.setLoading(false);
      props.setShowComponent(false);
    } else {
      const param = {
        id: idKonten,
      };
      const type =
        dataType.toLocaleLowerCase() === "berita" ? "news" : dataType.toLocaleLowerCase();
      API.getBITrafficDetailImpresKlik(param, type)
        .then((res) => {
          if (res.data.success) {
            props.setData(res.data.data);
            // setData(res.data.data);
            setTglTayang(
              Object.keys(
                res.data.data
                  ? res.data.data.graph
                    ? res.data.data.graph[0] && (res.data.data.graph[0].daily_unique_dfp ?? "")
                    : ""
                  : ""
              )
            );
            props.setLoading(false);
          } else {
            props.setData(null);
            // setData([]);
            props.setLoading(false);
            setTimeout(() => {
              alert(
                `Tidak ada data pada filter Organisasi ${pilihOrganization}, ${dataType}, konten ${title}`
              );
            }, 500);
          }
        })
        .catch((e) => {
          props.setData(null);
          // setData(null);
          props.setLoading(false);
          setTimeout(() => {
            alert(
              `Tidak ada data pada filter Organisasi ${pilihOrganization}, ${dataType}, konten ${title}`
            );
          }, 500);
        });
    }
  };

  const onChangeTitle = (param) => {
    setIdKonten(param._id);
    if (dataType === "Berita") {
      setTitle(param.title);
    } else if (dataType === "Video") {
      setTitle(
        param !== null
          ? param.details
            ? param.details.title ?? "No Title"
            : "No Title"
          : "No Title"
      );
    } else {
      setTitle(
        param !== null
          ? param.details
            ? param.details.title ?? "No Title"
            : "No Title"
          : "No Title"
      );
    }
  };

  useEffect(() => {
    props.setDataSave({
      data_type: dataType,
      organization: idOrg,
      title_organization: pilihOrganization,
      survey_title: title,
      title_id: idKonten,
      title_provinsi: props.province,
      province: props.idProvince,
    });
  }, [dataType, idOrg, pilihOrganization, idKonten, props.province, props.idProvince, title]);

  useEffect(() => {
    if (
      Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.measure === "traffik"
    ) {
      getData();
      setTimeout(() => {
        setIdKonten(dataReduxLogHistory.data.filter.title_id);
        setTitle(dataReduxLogHistory.data.filter.survey_title);
      }, 200);
    }
  }, []);

  const filterReset = () => {
    setPilihOrganization("");
    setIdOrg("");
    setDataType("Video");
    setIdKonten("");
    setTitle("");
    setTitleList([]);
    props.setShowComponent(false);
  };

  const changeOrganization = (value) => {
    setPilihOrganization(value.name);
    setIdOrg(value._id);
  };
  const clearOrganization = () => {
    setPilihOrganization("");
    setIdOrg("");
  };

  return (
    <>
      <Row className="py-2 mb-2">
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {/* <ButtonDropdown style={{ width: "100%" }} isOpen={dropdownOrg} toggle={toggleDropdownOrg}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{pilihOrganization}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              {orgList === null ? (
                <DropdownItem>Terjadi Kesalahan Server</DropdownItem>
              ) : (
                orgList.map((e) => (
                  <DropdownItem
                    key={e._id}
                    onClick={(i) => {
                      setIdOrg(e._id);
                      setPilihOrganization(e.name);
                    }}>
                    {e.name}
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          </ButtonDropdown> */}
          <Autocomplete
            // classes={style.dropdownAutocomplete}
            id="combo-box-demo"
            options={orgList !== null ? orgList : []}
            defaultValue={{ name: pilihOrganization }}
            value={{ name: pilihOrganization }}
            getOptionLabel={(option) => {
              return option.name;
            }}
            onChange={(e, value) => {
              value !== null ? changeOrganization(value) : clearOrganization();
            }}
            renderOption={(option) => (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>{option.name}</div>
                </div>
              </React.Fragment>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // value={dataTitle}
                placeholder="Pilih Organisasi"
              />
            )}
          />
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <ButtonDropdown style={{ width: "100%" }} isOpen={dropdown} toggle={toggleDropdown}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{dataType}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Video");
                }}>
                Video
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Banner");
                }}>
                Banner
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Berita");
                }}>
                Berita
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <Autocomplete
            // classes={style.dropdownAutocomplete}
            id="combo-box-demo"
            options={titleList !== null ? titleList : []}
            // inputValue={title !== "" ? title : ""}
            value={dataType == "Berita" ? { title: title } : { details: { title: title } }}
            defaultValue={dataType == "Berita" ? { title: title } : { details: { title: title } }}
            getOptionLabel={(option) => {
              if (dataType === "Berita") {
                return option.title;
              } else {
                return option !== null
                  ? option.details
                    ? option.details.title ?? "No Title"
                    : "No Title"
                  : "No Title";
              }
            }}
            onChange={(e, value) => {
              value !== null && onChangeTitle(value);
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder="Cari Judul Konten" />
            )}
            placeholder="Cari Judul Konten"
          />
        </Col>
        {tglTayang.length !== 0 && (
          <Col
            style={{
              paddingLeft: "3px",
              paddingRight: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "15px",
            }}>
            <div>
              {tglTayang.length > 1
                ? `${moment(tglTayang[0], "DD-MM-YYYY").format("DD/MM/YYYY")} - ${moment(
                    tglTayang[tglTayang.length - 1],
                    "DD-MM-YYYY"
                  ).format("DD/MM/YYYY")}`
                : `${moment(tglTayang[0], "DD-MM-YYYY").format("DD/MM/YYYY")} - ${moment(
                    tglTayang[0],
                    "DD-MM-YYYY"
                  ).format("DD/MM/YYYY")}`}
            </div>
          </Col>
        )}
        <Col lg="1" className="p-0">
          <Button
            style={{
              backgroundColor: "#2C3280",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={() => {
              getData();
              dispatch(setDataLogHistory({}));
            }}>
            <span style={{ fontSize: "11px" }}>Cari</span>
          </Button>
        </Col>
        <Col lg="1" className="p-0">
          <Button
            disabled={idOrg == "" && dataType == "Video" && idKonten == ""}
            style={{
              backgroundColor: "#C42127",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "90%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={() => {
              filterReset();
              dispatch(setDataLogHistory({}));
            }}>
            <span style={{ fontSize: "11px" }}>Reset</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}
