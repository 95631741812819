import React, { useState } from "react";
import { MDBModal } from "mdbreact";

const ModalTidakLolosUser = ({
  modalTidakLolos,
  toggleModalTidakLolos,
  dataModalTidakLolos,
  handleAlert,
  fetchData,
}) => {
    // console.log(dataModalTidakLolos)
  return (
    <MDBModal isOpen={modalTidakLolos} toggle={toggleModalTidakLolos} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleDecline">ADMIN NON AKTIF</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalTidakLolos}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">
              {dataModalTidakLolos.title ? dataModalTidakLolos.title : ""}
            </h4>
          </div>

          <div className="form-group wrapperTextAreaDecline">
            <textarea
              value={dataModalTidakLolos.comment ? dataModalTidakLolos.comment : ""}
              className="form-control textAreaDecline"
              disabled
              rows="5"></textarea>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalTidakLolosUser;
