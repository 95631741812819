import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const ReviewKontenKuratorAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/ReviewKontenKurator/Kurator" /* webpackChunkName: "ReviewKontenKuratorAsync" */
    ),
  loading: Loading,
});

export default ReviewKontenKuratorAsync;
