import React from "react";

import { Row, Card, Col, Spinner } from "reactstrap";
import Swal from "sweetalert2";

// import "tui-chart/dist/tui-chart.css";
import { PieChart, ColumnChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "./style.module.css";
import "./toastui.scss";

import smartphoneIcon from "../../assets/images/FingerprintBI/bi_phone.svg";
import desktopIcon from "../../assets/images/FingerprintBI/jam_computer.svg";
import tabletIcon from "../../assets/images/FingerprintBI/la_tablet.svg";

import Pdf from "react-to-pdf";

var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  // title: {
  //     color: '#8791af',
  // },
  xAxis: {
    // title: {
    //     color: '#8791af'
    // },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#1E1BB7"],
  },
};

export default function CardChart(props) {
  const downloadRef = React.createRef();

  const handlePDFDownload = (fn) => {
    Swal.fire({
      title: "Apakah kamu ingin mendownload halaman ini?",
      text: "Data PDF akan di download",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2c3280",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        fn();
      }
    });
  };

  const renderColumnChart = (param) => {
    const _width =
      window.screen.width > 993
        ? window.screen.width * 0.25
        : window.screen.width < 400
        ? window.screen.width * 0.77
        : window.screen.width * 0.4;
    const data = {
      categories: param
        ? param.device_type.map((res) => {
            return res.name;
          }) ?? ""
        : "",
      series: [
        {
          name: "Jumlah Pengguna",
          // data: param ? param.Agribisnis ?? 100 : 100,
          data: param
            ? param.device_type.map((res) => {
                return res.count;
              }) ?? 0
            : 0,
        },
      ],
    };
    const options = {
      chart: {
        width: _width,
        height: _width * 0.75 + 75,
        // title: "Monthly Revenue",
        // format: "1,000",
      },
      yAxis: {
        title: "Jumlah",
      },
      xAxis: {
        title: "Tipe Perangkat",
        min: 0,
        rotateLabel: true,
        // suffix: "$",
      },
      series: {
        showLabel: true,
      },
      legend: {
        align: "bottom",
        // visible: false,
        showCheckbox: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Tipe_Perangkat_Device_Fingerprint",
      },
    };

    // untuk apply theme
    TuiChart.registerTheme("themeTipePerangkat", theme);
    options.theme = "themeTipePerangkat";

    return (
      <React.Fragment>
        <ColumnChart data={data} options={options} />
      </React.Fragment>
    );
  };

  const defaultImg =
    "https://png.pngtree.com/thumb_back/fh260/background/20200821/pngtree-pure-white-minimalist-background-wallpaper-image_396581.jpg";

  const optionsPDF = {
    orientation: "landscape",
    // unit: 'in',
    // format: [4,2]
  };

  return (
    <div>
      <Row className="mt-5" style={{ minHeight: "450px" }}>
        <Col md="12">
          <Card body style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", marginBottom: "0px" }}>
            <div className="p-3">
              <Row
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className={`${style.titleCardBI}`}>PERSEBARAN TIPE PERANGKAT</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div>Download</div>
                  <div className="mx-3">
                    <Pdf
                      targetRef={downloadRef}
                      filename="dfp-tipe-perangkat.pdf"
                      options={optionsPDF}
                      scale={0.7}
                      x={4}
                      y={20}>
                      {({ toPdf }) => (
                        <a href="#" onClick={() => handlePDFDownload(toPdf)}>
                          PDF
                        </a>
                      )}
                    </Pdf>
                  </div>
                </div>
              </Row>
              <div className="row" ref={downloadRef}>
                <Col lg="6">
                  <div className="mt-1 mx-auto">
                    <div className="d-flex justify-content-center align-items-center">
                      {props.loading ? (
                        <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                          <Spinner color="primary" />
                        </div>
                      ) : props.data && props.data.length !== 0 ? (
                        props.data.device_type.length !== 0 ? (
                          renderColumnChart(props.data)
                        ) : (
                          <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                            <span className={style.titleCardBI}>Tidak Ada Data</span>
                          </div>
                        )
                      ) : (
                        <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                          <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg="6" style={{ minHeight: "100%" }}>
                  <Row className="text-center h-100">
                    <Col md="12" lg="4">
                      <Col
                        xs="12"
                        style={{
                          height: "33%",
                          fontWeight: "normal",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                        className={style.titlePendidikan}>
                        {/* <span
                          className="mdi mdi-checkbox-blank-circle mr-3"
                          style={{ color: "#7697de" }}
                        /> */}
                        <span>
                          {/* BUG DISINI */}
                          {(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[0].name ?? ""
                              : ""
                            : ""
                          ).toLocaleString()}
                        </span>
                      </Col>
                      <Col xs="12" className={`${style.titlePendidikan} my-3`}>
                        <img
                          style={{ height: "40px" }}
                          src={(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[0].name === "Smartphone"
                                ? smartphoneIcon
                                : props.data.device_type[0].name === "Desktop"
                                ? desktopIcon
                                : props.data.device_type[0].name === "Tablet"
                                ? tabletIcon
                                : defaultImg
                              : ""
                            : ""
                          ).toLocaleString()}
                        />
                      </Col>
                      {props.loading ? (
                        <div className="my-3">
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <Col xs="12" style={{ height: "33%" }} className={style.valueTipePerangkat}>
                          {" "}
                          {(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[0].count ?? 0
                              : 0
                            : 0
                          ).toLocaleString()}
                        </Col>
                      )}
                    </Col>
                    <Col md="12" lg="4">
                      <Col
                        xs="12"
                        style={{
                          height: "33%",
                          fontWeight: "normal",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                        className={style.titlePendidikan}>
                        {/* <span
                          className="mdi mdi-checkbox-blank-circle mr-3"
                          style={{ color: "#F0908E" }}
                        /> */}
                        <span>
                          {(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[1].name ?? ""
                              : ""
                            : ""
                          ).toLocaleString()}
                        </span>
                      </Col>
                      <Col xs="12" className={`${style.titlePendidikan} my-3`}>
                        <img
                          style={{ height: "40px" }}
                          src={(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[1].name === "Smartphone"
                                ? smartphoneIcon
                                : props.data.device_type[1].name === "Desktop"
                                ? desktopIcon
                                : props.data.device_type[1].name === "Tablet"
                                ? tabletIcon
                                : defaultImg
                              : ""
                            : ""
                          ).toLocaleString()}
                        />
                      </Col>
                      {props.loading ? (
                        <div className="my-3">
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <Col xs="12" style={{ height: "33%" }} className={style.valueTipePerangkat}>
                          {" "}
                          {(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[1].count ?? 0
                              : 0
                            : 0
                          ).toLocaleString()}
                        </Col>
                      )}
                    </Col>
                    <Col md="12" lg="4">
                      <Col
                        xs="12"
                        style={{
                          height: "33%",
                          fontWeight: "normal",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                        className={style.titlePendidikan}>
                        {/* <span
                          className="mdi mdi-checkbox-blank-circle mr-3"
                          style={{ color: "#55BA59" }}
                        /> */}
                        <span>
                          {(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[2].name ?? ""
                              : ""
                            : ""
                          ).toLocaleString()}
                        </span>
                      </Col>
                      <Col xs="12" className={`${style.titlePendidikan} my-3`}>
                        <img
                          style={{ height: "40px" }}
                          src={(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[2].name === "Smartphone"
                                ? smartphoneIcon
                                : props.data.device_type[2].name === "Desktop"
                                ? desktopIcon
                                : props.data.device_type[2].name === "Tablet"
                                ? tabletIcon
                                : defaultImg
                              : ""
                            : ""
                          ).toLocaleString()}
                        />
                      </Col>
                      {props.loading ? (
                        <div className="my-3">
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <Col xs="12" style={{ height: "33%" }} className={style.valueTipePerangkat}>
                          {" "}
                          {(props.data
                            ? props.data.device_type && props.data.device_type.length !== 0
                              ? props.data.device_type[2].count ?? 0
                              : 0
                            : 0
                          ).toLocaleString()}
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
