import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import _ from "lodash";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`Article`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Judul</th>
          <th>Tanggal Dibuat</th>
          <th>Tanggal Posting</th>
          <th>Jumlah Pembaca</th>
          <th>Status</th>
          <th>Like</th>
          <th>Dislike</th>
          <th>Facebook</th>
          <th>Twitter</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{data.judul}</td>
            <td>{_.get(data, "tanggal_pembuatan", "-") !== "-"
            ? moment(data.tanggal_pembuatan).format("DD-MM-YYYY")
            : "-"}</td>
            <td>{_.get(data, "tanggal_posting", "-") !== "-"
            ? moment(data.tanggal_posting).format("DD-MM-YYYY")
            : "-"}</td>
            <td>{data.jumlahPembaca}</td>
            <td>{data.status}</td>
            <td>{data.like}</td>
            <td>{data.dislike}</td>
            <td>{data.shareFacebook ?? "0"}</td>
            <td>{data.shareTwitter ?? "0"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
