import React from "react";

import { Row, Card, Col, Spinner } from "reactstrap";

// import "tui-chart/dist/tui-chart.css";
import { ColumnChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "./style.module.css";
import "./toastui.scss";

// import API from "../../../../services";
var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  // title: {
  //     color: '#8791af',
  // },
  xAxis: {
    // title: {
    //     color: '#8791af'
    // },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#83E687", "#559BEC", "#F66C6C", "#F7B859", "#C975F0", "#656565"],
  },
};

export default function ChartLokasi(props) {
  const renderColumnChart = (param) => {
    const _width = window.screen.width / 1.7;
    const data = {
        categories: param
          ? param.length !== 0
            ? param.map((e) => e.location)
            : []
          : [],
      // categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      series: [
        {
          name: "Total Impresi",
          // data: [21, 32, 43, 54, 65, 21, 32, 43, 54, 65],
            data: param
              ? param.length !== 0
                ? param.map((e) => e.total_impress)
                : []
              : [],
        },
        {
          name: "Maks Impresi",
          // data: [21, 65, 21, 32, 43, 54, 32, 43, 54, 65],
            data: param
              ? param.length !== 0
                ? param.map((e) => e.max_impressi)
                : []
              : [],
        },
        {
          name: "Total Klik",
          // data: [21, 32, 43, 54, 32, 21, 65, 43, 54, 65],
            data: param
              ? param.length !== 0
                ? param.map((e) => e.total_clicked)
                : []
              : [],
        },
        {
          name: "Maks Klik",
          // data: [21, 65, 21, 32, 43, 54, 32, 43, 54, 65],
            data: param
              ? param.length !== 0
                ? param.map((e) => e.max_clicked)
                : []
              : [],
        },
        {
          name: "Total DFP Unik",
          // data: [21, 65, 21, 32, 43, 54, 32, 43, 54, 65],
            data: param
              ? param.length !== 0
                ? param.map((e) => e.unique_dfp)
                : []
              : [],
        },
        // {
        //   name: "Total Device Unik",
        //   // data: param ? param.Agribisnis ?? 100 : 100,
        //   data: [55, 12, 32, 59, 43, 12],
        // },
      ],
    };

    const options = {
      chart: {
        width: _width,
        height: _width * 0.4,
        // title: 'Jumlah',
        // format: "1,000",
      },
      yAxis: {
        title: "Jumlah",
        min: 0,
        // max: props.data !== undefined ? 100 : 100,
      },
      xAxis: {
        title: "Kategori",
        rotateLabel: false,
      },
      legend: {
        align: "right",
        // visible: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Organisasi_Provinsi_Lokasi",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("themeOrgProvLokasi", theme);
    options.theme = "themeOrgProvLokasi";

    return (
      <React.Fragment>
        <ColumnChart data={data} options={options} />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Row style={{ height: "450px" }}>
        <Col md="12">
          <div className="p-3">
            <Row>
              <div className={`${style.titleCardBI}`} style={{ marginBottom: "50px" }}>
                Trafik Konten {props.typeData} berdasarkan Kategori Lokasi
              </div>
            </Row>
          </div>
          <div className="p-3" style={{ display: "flex" }}>
            <Row>
              <Col md="12">
                <div className="mt-1 mx-auto" style={{ display: "flex", justifyContent: "center" }}>
                  {props.loading ? (
                    <div
                      className="text-center"
                      style={{ marginBottom: "150px", marginTop: "150px" }}>
                      <Spinner color="primary" />
                    </div>
                  ) : props.dataFilter && props.dataFilter.length !== 0 ? (
                    renderColumnChart(props.dataFilter)
                  ) : (
                    <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                      <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                    </div>
                  )}
                  {/* {renderColumnChart()} */}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
