import React, { useState, useEffect, Fragment } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, Button, Container, CardBody, CardTitle } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import moment from "moment";
// ------------------CHART------------------------
import ReactApexChart from "react-apexcharts";
import "./style.css";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import { detailData } from "../../../store/actions";

// ------------------API-----------------------
import API from "../../../services";

// -------------------ASSET--------------------
//css
import "./datatables.scss";
import "./style.css";
//icon
import IconDown from "../../../assets/images/back.png";

// ------------------Component----------------
import Header from "../../../components/VerticalLayout/Header";
import ModalHistory from "../../../components/ContentPartner/Popup/Konten/History";
import ModalDetailTayang from "../../../components/ContentPartner/Popup/Konten/DetailTayang";
import DropdownFilter from "./dropdownFilter";

//dummy
import axios from "../../../config/api";
import StatistiKontenCP from "../../../components/ContentPartner/Chart/DetailKonten";
import TotalData from "../../../components/ContentPartner/Card/DetailKonten";
import _ from "lodash";

const DetailKontenContentPatner = (props) => {
  const [impressData, setImpressData] = useState([]);
  const [clickedData, setClickedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const history = useHistory();
  const [data, setData] = useState();
  const [dataTable, setDataTable] = useState();

  const [provinsi, setProvinsi] = useState(
    `${
      props
        ? props.data
          ? props.data.data
            ? props.data.data.list_provinsi
              ? props.data.data.list_provinsi[0].name
                ? props.data.data.list_provinsi[0].name
                : "-"
              : "-"
            : "-"
          : "-"
        : "-"
    }`
  );
  let it = props.data.data ?? {};

  const fetchAPIImpress = () => {
    let paramImpress = {
      id: it._id,
      type: "impress",
    };

    API.postImpressandClickedContentCP(props.tabDetail, paramImpress)
      .then((res) => {
        if (res.data.success) {
          setIsLoading(true);
          if (res.data.count == 0) {
            setIsEmpty(true);
          }
          filterDataChartByProvince(res.data.data.graph);
          filterDataTableByProvince(res.data.data.table);
        } else {
          alert("postImpressandClickedContentCP");
        }
      })
      .catch((e) => {
        alert("data impresi tidak bisa di dapat");
        setImpressData(["tidakAdaData"]);
      });
  };

  const filterDataChartByProvince = (arrData) => {
    let result = arrData.filter((it) => provinsi == it.province);
    setImpressData(result);
  };
  const filterDataTableByProvince = (arrData) => {
    let result = arrData.filter((it) => provinsi == it.province);
    if (result.length > 0) {
      setDataTable(result);
    }
  };

  useEffect(() => {
    fetchAPIImpress();
  }, [props.data.data, provinsi]);
  useEffect(() => {
    return () => {};
  }, [clickedData]);

  //modal history
  const [modalHistory, setModalHistory] = useState(false);
  const toggleModalHistory = () => {
    setModalHistory(!modalHistory);
  };
  //modalDetailTayang
  const [modalDetailTayang, setModalDetailTayang] = useState(false);
  const toggleModalDetailTayang = () => {
    setModalDetailTayang(!modalDetailTayang);
  };
  //onchange filter provinsi
  const filterDataDropdown = (filt) => {
    setProvinsi(filt);
  };

  //data table
  const [stateArrDFP, setStateArrDFP] = useState([]);

  //data for table top
  const DataDetail = {
    columns: [
      {
        label: "Judul",
        field: "judul",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "disabled",
        width: 150,
      },

      {
        label: "Durasi",
        field: "durasi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Tanggal Tayang",
        field: "tanggal_tayang",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Jam",
        field: "jam",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      Object.keys(it).length === 0
        ? [
            {
              tanggal_tayang: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : [
            {
              judul: it.title ? it.title ?? "-" : it.details.title ?? "-",
              organisasi: it.requested_by.organization.name ?? "-",
              kategori: it.category ?? "-",
              durasi: it.proposed_duration ?? "-",

              tanggal_tayang:
                it.proposed_date.length > 0
                  ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")} - ${moment(
                      it.proposed_date.slice(-1),
                      "DD-MM-YYYY"
                    ).format("DD-MM-YYYY")} `
                  : "-",
              jam:
                it.proposed_time.length > 0
                  ? `${it.proposed_time[0][0]} - ${it.proposed_time[0][1]} `
                  : "-",
              status: it ? (
                it.status ? (
                  <span
                    className={
                      it.status == "approved" || it.status == "emergency"
                        ? "approved"
                        : it.status == "scheduled"
                        ? "scheduled"
                        : it.status == "declined"
                        ? "declined"
                        : ""
                    }>
                    {it.status == "approved" || it.status == "emergency"
                      ? "Lolos Kurasi"
                      : it.status == "scheduled"
                      ? "Tayang"
                      : it.status == "declined"
                      ? "Tidak Lolos"
                      : ""}
                  </span>
                ) : (
                  "-"
                )
              ) : (
                "-"
              ),
            },
          ],
  };

  //data for table bottom

  let kabupaten =
    dataTable !== undefined && dataTable.length > 0 ? dataTable[0].kabupaten_kota : "0";
  let kecamatan = "-";
  let nama_lokasi = dataTable !== undefined && dataTable.length > 0 ? dataTable[0].location : "0";
  let maks_impresi =
    dataTable !== undefined && dataTable.length > 0 ? dataTable[0].max_impress : "0";
  let maks_klik = dataTable !== undefined && dataTable.length > 0 ? dataTable[0].max_clicked : "0";
  let pengguna_unik =
    dataTable !== undefined && dataTable.length > 0 ? dataTable[0].unique_dfp : "0";
  const DataLokasi = {
    columns: [
      {
        label: "Kabupaten",
        field: "kabupaten",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Kecamatan",
        field: "kecamatan",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Nama Lokasi",
        field: "nama_lokasi",
        sort: "disabled",
        width: 150,
      },

      {
        label: "Maks Impresi",
        field: "maks_impresi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Maks Klik",
        field: "maks_klik",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Pengguna Unik",
        field: "pengguna_unik",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      Object.keys(it).length === 0
        ? [
            {
              tanggal_tayang: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : [
            {
              kabupaten: kabupaten,
              kecamatan: kecamatan,
              nama_lokasi: nama_lokasi,
              maks_impresi: maks_impresi,
              maks_klik: maks_klik,
              pengguna_unik: pengguna_unik,
            },
          ],
  };
  const skeletonCostume = <Skeleton height={100} width={100} />;

  return (
    <React.Fragment>
      <ModalHistory modalHistory={modalHistory} toggleModalHistory={toggleModalHistory} />
      <ModalDetailTayang
        modalDetailTayang={modalDetailTayang}
        toggleModalDetailTayang={toggleModalDetailTayang}
      />
      <div className="page-content">
        <header>
          <Header title="Konten" btn={true} btntype="back" btntext="Konten" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <MDBContainer > */}
            {Object.keys(it).length === 0 ? (
              <div className="mt-5 mb-5 d-flex justify-content-center">
                Silahkan pilih konten terlebih dahulu
              </div>
            ) : (
              <Card className="mx-auto" style={{ boxShadow: "0px 0px 10px #D3D3D3" }}>
                <div className="classic-tabs">
                  <Row style={{ overflowY: "auto" }}>
                    <Col className="col-12">
                      <MDBDataTable
                        id="tableVid"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        paging={false}
                        striped
                        bordered
                        data={DataDetail}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                      />
                    </Col>
                  </Row>
                  <Container>
                    <Row>
                      <Col className="col-lg-5 mx-auto">
                        <DropdownFilter
                          type={"Provinsi"}
                          filter={provinsi}
                          clicked={filterDataDropdown}
                          list={props.data.data.list_provinsi}
                        />
                      </Col>

                      <Col className="col-lg-3 colDetailActions">
                        <Row className="mx-auto w-100 wrapperBtnDetailActions">
                          <Button
                            onClick={() => toggleModalDetailTayang()}
                            className="btn btn-rounded btnHistory mb-2">
                            Detail Penayangan
                          </Button>
                          <Button
                            onClick={() => toggleModalHistory()}
                            className="btn btn-rounded btnHistory">
                            History
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                  {/* CardKontenCP */}
                  <TotalData impressData={impressData} dates={it.proposed_date} />
                  <Card className="px-3 py-2">
                    <Row style={{ overflowY: "auto" }}>
                      <Col className="col-12">
                        {Object.keys(it).length === 0 ? (
                          <></>
                        ) : (
                          <MDBDataTable
                            id="tableVid"
                            noBottomColumns={true}
                            className="text-center"
                            responsive
                            striped
                            bordered
                            data={DataLokasi}
                            displayEntries={false}
                            pagesAmount={5}
                            entries={10}
                            searching={false}
                          />
                        )}
                      </Col>
                    </Row>
                  </Card>
                  <div style={{ height: "100px" }}></div>
                </div>
              </Card>
            )}

            {/* </MDBContainer> */}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  const { tabDetail } = state.Layout;
  return { data, tabDetail };
};

export default connect(mapStatetoProps)(DetailKontenContentPatner);
