import React, {useState,useEffect} from 'react';
import styles from "../../assets/css/login.module.css"
import { Row, Col, Alert, Card, CardBody,Container } from "reactstrap";
import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import profile from "../../assets/icon/Vector.png";
import logo from "../../assets/icon/BAKTI-ICON.png";
import background from "../../assets/images/bg-login.png"
import API from '../../services';

const ForgetPasswordPage = (props) => {
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [errorEmail, setErrorEmail] = useState("")
    const [errorPassword, setErrorPassword] = useState("")
    const [errorNewPassword, setErrorNewPassword] = useState("")

    const history = useHistory();

    //alert
    const [alertReset, setAlertReset] = useState(false);
    const [alertResetFail, setAlertResetFail] = useState(false)
  //notif
    const [notifReset, setNotifReset] = useState(false);
    const [notifResetFail, setNotifResetFail] = useState(false);

    const token = props.location.pathname.substr(props.location.pathname.lastIndexOf("/") + 1)

  // if (email === ""){
  //   setErrorEmail("Email Tidak Koleh Kosong")
  // }

    const checkExpiredToken = () =>{
        API.getCheckToken(`${token}`)
            .then((res) => {
                // console.log("cek",res)
            }).catch((err) => {
                // console.log(err)
            })
    }

    function handleValidSubmit() {
        // props.userForgetPassword(values, props.history);
        // console.log(password, newPassword)
        if(password !== "" && newPassword !== ""){
            API.postResetPass({
                password : password,
                resetPasswordToken : token
            }).then((res) => {
                // console.log(res.data.success)
                if(res.data.success === true){
                    setAlertReset(false);
                    setNotifReset(true);
                    setTimeout(() => {
                        setNotifReset(false)
                        history.push('/login');
                    }, 3000);
                } if (res.data.success === false){
                    setAlertResetFail(false);
                    setNotifResetFail(true);
                    setTimeout(() => {
                        setNotifResetFail(false)
                        history.push('/forgot-password');
                    }, 3000);
                }
            }).catch((err) => {
                // console.log(err)
            })
        }
    }

    useEffect(() => {
        checkExpiredToken()
    }, [])

    return (
        // <React.Fragment>
            
            <div className={styles.bgWrapp}>
                
                    <Row className="justify-content-center" style={{paddingTop : "10%", paddingBottom: "28%"}}>
                        <Container>
                        <Col md={8} lg={6} xl={5} style={{marginLeft: "24%"}}>
                        <Alert
                            color={alertReset ? "success" : "success"}
                            isOpen={notifReset}
                            style={{
                                width: "100%",
                                borderRadius: "6px",
                                textAlign: "center",
                                padding: "5px",
                                marginLeft: "0%",
                                marginTop: "10%" ,
                            }}>
                            {alertReset ? `` : `Perubahan Password Telah Sukses`}
                        </Alert>
                        <Alert
                            color={alertReset ? "danger" : "danger"}
                            isOpen={notifResetFail}
                            style={{
                                width: "100%",
                                borderRadius: "6px",
                                textAlign: "center",
                                padding: "5px",
                                marginLeft: "0%",
                                marginTop: "10%" ,
                            }}>
                            {alertReset ? `` : `Perubahan Password Gagal`}
                        </Alert>
                            <Card className="overflow-hidden">
                                <div className="" style={{backgroundColor : "#fff"}}>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="text-black p-4 text-center">
                                                <h5 className="text-black">Selamat Datang !</h5>
                                                <p>ICMS Bakti Kominfo.</p>
                                                <p>Reset</p>
                                            </div>
                                        </Col>
                                        {/* <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" style={{width : "100%" , height: "100%"}}/>
                                        </Col> */}
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="p-2">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>

                                            {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

                                            <div className="form-group">
                                                <AvField 
                                                    name="password" 
                                                    id="Password"
                                                    onChange={(e) => { setPassword(e.target.value) }}
                                                    
                                                    className="form-control" 
                                                    placeholder="Password" 
                                                    type="password" 
                                                    required 
                                                />
                                            </div>

                                            <div className="form-group">
                                                <AvField 
                                                    onChange={(e) => { setNewPassword(e.target.value) }}
                                                    name="NewPassword"
                                                    id="newpassword"
                                                    
                                                    type="password" 
                                                    required 
                                                    placeholder="newPassword" 
                                                />
                                            </div>

                                            <div className="mt-4 mb-4">
                                                <button className="btn btn-block waves-effect waves-light" type="submit" style={{backgroundColor : "#00438B", color: "#fff"}}>RESET</button>
                                            </div>

                                            {/* <div className="mt-4 mb-4 text-center">
                                                <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                            </div> */}
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            {/* <div className="mt-5 text-center">
                                <p>Don't have an account ? <Link to="register" className="font-weight-medium text-primary"> Signup now </Link> </p>
                                <p>© {new Date().getFullYear()} ICMS Bakti Kominfo. Crafted with <i className="mdi mdi-heart text-danger"></i> by kibs</p>
                            </div> */}
                        </Col>
                        </Container>
                    </Row>
                
            </div>
        // </React.Fragment>
    );
    }

// const mapStatetoProps = state => {
//   const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
//   return { forgetError, forgetSuccessMsg };
// };

export default withRouter(
    (ForgetPasswordPage)
);
