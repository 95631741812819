import React from "react";
import { CardBody, Card, Row, Button, Col, Spinner } from "reactstrap";

// STYLE
import style from "./style.module.css";

// ASSETS
import educationIcon from "../../assets/icon/educationicon.png";

// DOWNLOAD XLS
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import DownloadXLS from "./Download/XLS/DownloadPendidikanDetail";

export default function CardPendidikan(props) {
  return (
    <div className="my-5">
      <Card className="p-3" style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
        <CardBody>
          <Row
            style={{
              paddingBottom: "15px",
              paddingTop: "15px",
            }}>
            <CardBody
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span className={style.titleCardBI}>
                TOTAL {props.title.toUpperCase()} BERDASARKAN JENJANG PENDIDIKAN TERAKHIR
              </span>
            </CardBody>
          </Row>
          <Row className="text-center my-3 text-dark">
            <Col lg="1">
              <img src={educationIcon} />
            </Col>
            <Col style={{ borderRight: "1px solid #C0C0C0" }}>
              {" "}
              <div
                className={style.titlePendidikan}
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                SD
              </div>
              {props.loading ? (
                <div className="my-3">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className={style.valuePendidikan}>
                  {(props.data
                    ? props.data.education
                      ? props.data.education.sd_sederajat ?? 0
                      : 0
                    : 0
                  ).toLocaleString()}
                </div>
              )}
            </Col>
            <Col style={{ borderRight: "1px solid #C0C0C0" }}>
              {" "}
              <div
                className={style.titlePendidikan}
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                SMP/SEDERAJAT
              </div>
              {props.loading ? (
                <div className="my-3">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className={style.valuePendidikan}>
                  {(props.data
                    ? props.data.education
                      ? props.data.education.smp_sederajat ?? 0
                      : 0
                    : 0
                  ).toLocaleString()}
                </div>
              )}
            </Col>
            <Col style={{ borderRight: "1px solid #C0C0C0" }}>
              {" "}
              <div
                className={style.titlePendidikan}
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                SMA/SEDERAJAT
              </div>
              {props.loading ? (
                <div className="my-3">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className={style.valuePendidikan}>
                  {(props.data
                    ? props.data.education
                      ? props.data.education.sma_sederajat ?? 0
                      : 0
                    : 0
                  ).toLocaleString()}
                </div>
              )}
            </Col>
            <Col>
              {" "}
              <div
                className={style.titlePendidikan}
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                PENDIDIKAN TINGGI/SEDERAJAT
              </div>
              {props.loading ? (
                <div className="my-3">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className={style.valuePendidikan}>
                  {(props.data
                    ? props.data.education
                      ? props.data.education.pendidikan_tinggi_sederajat ?? 0
                      : 0
                    : 0
                  ).toLocaleString()}
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
