import React from "react";
import {
  MDBModal,
} from "mdbreact";
import moment from "moment";

import './style.css'

function PopupDetailRevisi(props) {
  return (
    <MDBModal isOpen={props.modal4} toggle={props.toggleRevisi} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            {props.statRevisi === "Revisi" ? <p className="topTitleRevisi">REVISI</p> : <p className="topTitleSudahRevisi">SUDAH REVISI</p>}
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={props.toggleRevisi}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">{props.title}</h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleDecline">
            {props.statRevisi === "Revisi" ? "Konten ini perlu Direvisi" : "Konten ini Sudah Direvisi"}
            </h4>
          </div>

          {/* <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="jamTayang">{props.tglTayang ? `${moment(props.tglTayang[0], "DD/MM/YYYY").format("DD/MM/YYYY") ?? "-"} - ${moment(props.tglTayang[props.tglTayang.length-1], "DD/MM/YYYY").format("DD/MM/YYYY") ?? "-"}` : props.tglTayang[0] ?? ""}</h4>
            <h4 className="jamTayang">{props.jamTayang && `${props.jamTayang[0][0]} - ${props.jamTayang[0][props.jamTayang[0].length-1]}`}</h4>
          </div> */}

          <div className="form-group wrapperTextAreaDecline">
            <textarea
              className="form-control textAreaDecline"
              value={props.detail}
              disabled
              rows="5"
            ></textarea>
          </div>

          <div className="form-group">
          <h4 className="jamTayang">List Provinsi :</h4>
            {props.listProv&&props.listProv.map(prov => (
              <center>
                <label>{prov.name}</label>
              </center>
            ))}
          </div>

        </div>
      </div>
    </MDBModal>
  );
}
export default PopupDetailRevisi;
