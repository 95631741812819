import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDF = (
  dataTable,
  activeTab,
  activeSubTab1,
  activeSubTab2,
  activeSubTab3,
  titleFile
) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title;
  if (activeTab === "1") {
    switch (activeSubTab1) {
      case "1":
        title = `Konten Video Disetujui ${role}`;
        break;
      case "2":
        title = `Konten Banner Disetujui ${role}`;
        break;
      case "3":
        title = `Konten Berita Disetujui ${role}`;
        break;

      default:
        break;
    }
  } else if (activeTab === "2") {
    switch (activeSubTab2) {
      case "1":
        title = `Konten Video Tidak Disetujui ${role}`;
        break;
      case "2":
        title = `Konten Banner Tidak Disetujui ${role}`;
        break;
      case "3":
        title = `Konten Berita Tidak Disetujui ${role}`;
        break;

      default:
        break;
    }
  } else {
    switch (activeSubTab3) {
      case "1":
        title = `Konten Video Tidak Lolos ${role}`;
        break;
      case "2":
        title = `Konten Banner Tidak Lolos ${role}`;
        break;
      case "3":
        title = `Konten Berita Tidak Lolos ${role}`;
        break;

      default:
        break;
    }
  }

  const headers = [
    [
      "No",
      "Judul",
      "Organisasi",
      "Provinsi",
      "Kategori",
      "Tanggal Pengajuan",
      "Tanggal Tayang",
      "Hasil Kurasi",
      "Preview",
    ],
  ];
  //add
  if (activeTab !== "1") {
    headers[0].splice(
      8,
      0,
      `Kategori ${activeTab === "3" ? "Tidak Lolos Kurasi" : "Tidak Disetujui"}`
    );
  }

  const data = dataTable.map((data, index) => {
    return [
      index + 1,
      data.title ? data.title ?? "-" : data.details.title ?? "-",
      data.requested_by.organization.name ?? "-",
      data.list_provinsi.map((prov) => prov.name).toString() ?? "-",
      data.category ?? "-",
      moment(data.tanggal_pembuatan).format("DD-MM-YYYY"),
      data.proposed_date.length > 0
        ? `${moment(data.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")}\n s/d \n${moment(
            data.proposed_date.slice(-1),
            "DD-MM-YYYY"
          ).format("DD-MM-YYYY")} `
        : "-",

      data.emergency !== undefined && data.emergency !== ""
        ? "Emergency"
        : data.status_kurator[data.status_kurator.length - 1].status == "Tidak Lolos Kurasi"
        ? "Tidak Lolos Kurasi"
        : data.status_kurator[data.status_kurator.length - 1].status == "Lolos Kurasi"
        ? "Lolos Kurasi"
        : "",
      activeTab !== "1" ? (data.kategoriTolak ? data.kategoriTolak : "-") : "",
      "",
    ];
  });

  let a = 0;
  let content = {
    startY: 50,
    head: headers,
    body: data,
    didDrawCell: async function (dataCell) {
      if (dataCell.column.index === headers[0].length - 1 && dataCell.cell.section === "body") {
        var diw = dataCell.cell.width - dataCell.cell.padding("horizontal");
        var dih = dataCell.cell.height - dataCell.cell.padding("vertical");
        if (dataCell.row.index < dataTable.length) {
          if (dataTable[dataCell.row.index]) {
            var img = new Image();
            img.src =
              activeTab === "1" && activeSubTab1 === "1" //tab lolos video
                ? process.env.REACT_APP_SELF_URL +
                  `/${dataTable[dataCell.row.index].details.thumbnail}`
                : activeTab === "2" && activeSubTab2 === "1" //tab disetujui video
                ? process.env.REACT_APP_SELF_URL +
                  `/${dataTable[dataCell.row.index].details.thumbnail}`
                : activeTab === "3" && activeSubTab3 === "1" //tab tdk lolos video
                ? process.env.REACT_APP_SELF_URL +
                  `/${dataTable[dataCell.row.index].details.thumbnail}`
                : process.env.REACT_APP_SELF_URL + `/${dataTable[dataCell.row.index].details.url}`;
            var poll = setInterval(function () {
              if (img.naturalWidth) {
                clearInterval(poll);
              }
            }, 10);
            if (
              activeTab === "1" && activeSubTab1 === "1"
                ? dataTable[dataCell.row.index].details.thumbnail
                : activeTab === "1" && activeSubTab2 === "2"
                ? dataTable[dataCell.row.index].details.thumbnail
                : activeTab === "1" && activeSubTab3 === "1"
                ? dataTable[dataCell.row.index].details.thumbnail
                : dataTable[dataCell.row.index].details.url
            ) {
              try {
                doc.addImage(
                  img,
                  dataCell.cell.x,
                  dataCell.cell.y + 5,
                  dih > diw ? diw : 0 / dih,
                  dih > diw ? 0 / diw : dih
                );
              } catch (e) {}
            } else {
              doc.addImage(
                "https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg",
                dataCell.cell.x,
                dataCell.cell.y + 5,
                dih > diw ? diw : 0 / dih,
                dih > diw ? 0 / diw : dih
              );
            }
          }
          a = a + 1;
        }
      } //
    },
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`Data_${titleFile}_${role}.pdf`);
};
