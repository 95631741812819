import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import _ from "lodash";

export const exportPDF = (dataTable, activeTab) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title = `Artikel`;

  const headers = [["No", "Judul", "Tanggal Dibuat", "Tanggal Posting", "Jumlah Pembaca", "Status", "Like", "Dislike", "Facebook", "Twitter"]];
  const data = dataTable.map((data, index) => {
    return [
        index + 1,
        data.judul,
        _.get(data, "tanggal_pembuatan", "-") !== "-"
        ? moment(data.tanggal_pembuatan).format("DD-MM-YYYY")
        : "-",
        _.get(data, "tanggal_posting", "-") !== "-"
        ? moment(data.tanggal_posting).format("DD-MM-YYYY")
        : "-",
        data.jumlahPembaca,
        data.status,
        data.like,
        data.dislike,
        data.shareFacebook ?? "0",
        data.shareTwitter ?? "0"
    ];
  });
  let a = 0
  let content = {
    startY: 50,
    head: headers,
    body: data,
    columnStyles: {
        // 0: {cellWidth: 100},
    },
  }
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`Article`);
};
