import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";

import API from "../../../../../services";

import { Button, Input, Row } from "reactstrap";

import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";

import _ from "lodash";

import ModalPreviewContentActivated from "../../../Popup/KontenDefault/PreviewContentActivated";

const ModalDetail = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // let typeContent = "";
  // switch (props.activeTab) {
  //   case "1":
  //     typeContent = "video";
  //     break;
  //   case "2":
  //     typeContent = "banner";
  //     break;
  //   case "3":
  //     typeContent = "news";
  //     break;
  //   default:
  //     break;
  // }

  //modal preview video
  const [modalPreviewContentActivated, setModalPreviewContentActivated] = useState(false);
  const [dataModalPreviewContentActivated, setDataModalPreviewContentActivated] = useState({});
  const toggleModalPreviewContentActivated = () => {
    setModalPreviewContentActivated(!modalPreviewContentActivated);
  };

  const handleModalPreviewContentActivated = (data) => {
    setDataModalPreviewContentActivated({
      title:
        props.activeTab == "3"
          ? data.title
            ? data.title
            : "-"
          : data.details.title
          ? data.details.title
          : data.title
          ? data.title
          : "-",
      url: data.details ? (data.details.url ? data.details.url : data.details) : "",
      type: data.type ?? "-",
      link: data.link ?? "-",
    });
    toggleModalPreviewContentActivated();
  };

  const PreviewComponent = ({ it }) => {
    return props.activeTab === "3" ? (
      <a href={it.link ?? "-"} target="_blank">
        <center>
          <img
            alt={it.title ?? "-"}
            className="wrapperImg"
            src={`${process.env.REACT_APP_BE_URL}/${it.details.url ?? "-"}`}></img>
        </center>
      </a>
    ) : (
      <center style={{ width: "fit-content" }}>
        {/* {!it.tanggal_pembuatan.includes("2019") ? ( */}
        <img
          alt={props.activeTab == "3" ? it.title : it.details.title}
          className="wrapperImgYT"
          onClick={() => handleModalPreviewContentActivated(it)}
          src={
            it.details
              ? it.details.thumbnail
                ? `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.thumbnail : ""}`
                : `${process.env.REACT_APP_BE_URL}/${
                    it.details ? (it.details.url ? it.details.url : it.details) : ""
                  }`
              : "-"
          }></img>
        {/* ) : (
          <img
            alt={props.activeTab == "3" ? it.title : it.details.title}
            className="wrapperImgYT"
            src={noImage}></img>
        )} */}
      </center>
    );
  };

  const changeKontenDefault = (id, id_prov) => {
    const param = {
      id: id,
      province: id_prov,
    };
    const dataType = props.type.toLowerCase() === "berita" ? "news" : props.type.toLowerCase();
    API.getKontenDefaultActivate(param, dataType)
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Konten Berhasil Diganti",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Konten Tidak Berhasil Diganti",
            showConfirmButton: false,
            timer: 1000,
          });
        }
        props.toggleModal();
        props.handleFetch();
      })
      .catch((e) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Konten Tidak Berhasil Diganti",
          showConfirmButton: false,
          timer: 1000,
        });
        props.toggleModal();
      });
  };

  const getDataDetail = () => {
    const param = {
      province: props.idProv,
      type: props.activeTab === "1" ? "video" : props.activeTab === "2" ? "banner" : "news",
    };
    setLoading(true);
    API.getKontenDefaultTidakAktif(param)
      .then((res) => {
        if (res.data.success) {
          setData(
            res.data.data.map((e, i) => ({
              no: i + 1,
              judul: e.details ? e.details.title ?? e.title ?? "-" : e.title ?? "-",
              organisasi: e.requested_by
                ? e.requested_by.organization
                  ? e.requested_by.organization.name ?? "-"
                  : "-"
                : "-",
              kategori: e.category ?? "-",
              preview: <PreviewComponent it={e} />,
              aksi: (
                <Button
                  onClick={() => changeKontenDefault(e._id, e.proposed_provinsi._id)}
                  className="btn-rounded btnDetail py-0 w-75">
                  <span style={{ fontSize: "11px", fontWeight: "bold" }}>Pilih</span>
                </Button>
              ),
            }))
          );
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((e) => {
        setData(null);
        setLoading(false);
      });
  };

  const dataDetail = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 50,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 50,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 50,
      },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 50,
      },
      {
        label: "",
        field: "aksi",
        sort: "disabled",
        width: 50,
      },
    ],

    rows: !data
      ? [
          {
            judul: [
              <Row key="a" className="justify-content-md-center">
                Terjadi Kesalahan
              </Row>,
            ],
          },
        ]
      : data,
  };

  useEffect(() => {
    getDataDetail();
  }, []);

  return loading ? (
    <div>
      <Skeleton count="5" />
    </div>
  ) : (
    <div>
      <ModalPreviewContentActivated
        ModalPreviewContentActivated={modalPreviewContentActivated}
        dataModalPreviewContentActivated={dataModalPreviewContentActivated}
        toggleModalPreviewContentActivated={toggleModalPreviewContentActivated}
        // fetchData={props.handleFetch}
        activeTab={props.activeTab === "1" ? "video" : props.activeTab === "2" ? "banner" : "news"}
      />
      <MDBDataTable
        id="modalDetailReport"
        noBottomColumns={true}
        className="text-center"
        responsive
        disableRetreatAfterSorting
        striped
        bordered
        data={dataDetail}
        displayEntries={false}
        // entriesOptions={[5, 10, 15, 20]}
        // pagesAmount={5}
        // entries={10}
        paging={false}
        searching={false}
        // scrollX
        // scrollY
        // maxHeight="400px"
      />
    </div>
  );
};
export default ModalDetail;
