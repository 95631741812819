import React, { useEffect, useState, createRef, useMemo } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import "./style.css";
import { Container, Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import { lisKategoriLokasi } from "./const";
// ------------------API---------------------
import API from "../../../../../services";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const ModalKategoriLokasi = (props) => {
  //todo next feature
  // const history = useHistory();
  const [select, setSelect] = useState([]);
  // const [availableProvince, setAvailableProvince] = useState([]);

  useEffect(() => {
   if(props.listSelected.length !== 0){
    setSelect([props.listSelected.join(",")]) 
   }else{
    setSelect([])
   }
  },[props.activeTab])
  const handleChangeRadio = (e, id) => {
      if (e.target.checked) {
          let value = [...select, e.target.value]
          setSelect([...new Set(value)]);
          props.selectedCatLocations([...new Set(value)]);
      } else {
        let filter;
        filter = select.filter((it) => it !== e.target.value);
        setSelect(filter);
        props.selectedCatLocations(filter);
      }
  };

  return (
    <MDBContainer>
      <MDBModal
        size="md"
        isOpen={props.modalCatLocations}
        toggle={props.toggleModalCatLocations}
        centered={true}>
        <MDBModalHeader centered toggle={props.toggleModalCatLocations}>
          Tambah Kategori Lokasi
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row
              className="col-lg-10 mx-auto col-offset-2"
              style={{
                backgroundColor: "#f7f7f7",
                height: "200px",
                width: "100%",
                overflowX: "auto",
              }}>
              <Col
                md="5"
                xs="5"
                lg="5"
                style={{ height: "200px", padding: "5px", overflowY: "hidden", overflowX: "auto" }}>
                <div
                  id="group1"
                  className="custom-control custom-checkbox mb-2"
                  style={{ overflowY: "scroll", height: "200px" }}>
                  {lisKategoriLokasi.map((list, i) => {
                      let isMatch = select.indexOf(list) !== -1
                      return (
                        <div key={i}>
                          <input
                            type="checkbox"
                            checked={isMatch}
                            className="custom-control-input input-mini"
                            name={`${list}${i}`}
                            value={`${list}`}
                            onChange={(e) => handleChangeRadio(e, list)}
                            id={i}></input>
                          <Label
                            className={
                                "custom-control-label textCheckbox"
                            }
                            for={i}>{`${list}`}</Label>
                        </div>
                      )
                  })}
                </div>
              </Col>
              <Col lg="2" md="2" xs="2">
                <div className="v3"></div>
              </Col>
              <Col
                lg="5"
                md="5"
                xs="5"
                style={{ marginLeft: "0 !important", height: "200px", overflowY: "hidden" }}>
                <div style={{ height: "190px", overflow: "scroll" }}>
                  {
                  select.map((e) => (
                    <Label className="textCheckbox d-block">{e}</Label>
                  ))
                }
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Button
                color=""
                onClick={props.toggleModalCatLocations}
                className="btn btn-rounded btnCekJam col-3 col-offset-2 mt-2 mx-auto">
                OK
              </Button>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
const mapStatetoProps = (state) => {
  const { dataAjukan } = state.dataReducer;
  return { dataAjukan };
};
export default connect(mapStatetoProps, null)(ModalKategoriLokasi);
