import React, { useState, useEffect } from "react";
import Header from "../../components/VerticalLayout/Header";
// import { Container, Card, CardBody, Form, FormGroup, Label, Input, FormText, Row, Col, Button } from 'reactstrap';

import ArticleTotal from "../../components/Admin/Card/ArticleTotal";
import InfoArticle from "../../components/Admin/Card/InfoArticle";
import TableArticle from "../../components/Admin/Table/TableArticle";
import TableNewsletter from "../../components/Admin/Table/TableNewsletter";
import TableTotalShare from "../../components/Admin/Table/TableTotalShare";
import API from "../../services";

export default function Artikel() {
  const [dataDashboard, setDataDashboard] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDashboard = () => {
    API.getDashoardArticle()
      .then((response) => {
        if (response.status === 200) {
          setDataDashboard(response.data);
          setLoading(false);
        }
      })
      .catch(setLoading(true));
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <div>
      <Header title="Artikel (Homepage)" />
      <ArticleTotal data={dataDashboard} loading={loading} />
      <InfoArticle data={dataDashboard} loading={loading} />
      <TableArticle role="adminartikel" />
      <TableNewsletter />
      <TableTotalShare />
    </div>
  );
}
