import React, { useState } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from "../../../../assets/images/back.png";
import "./style.css";

function FilterButton(props) {
  const { filter, clicked } = props;
  const [filterContain] = useState(props.listDropdown);

  //Change uppercase and lowercase
  // const formatText = (text) => {
  //     text = text.toLowerCase()
  //     let textArray = text.split(' ')
  //     let textJoin = textArray.reduce((join, text) => {
  //         if (text === 'dki' || text === 'di') {
  //             text = text.toUpperCase();
  //         } else {
  //             text = text.charAt(0).toUpperCase() + text.slice(1);
  //         }
  //         join.push(text)
  //         return join;
  //     }, [])
  //     return textJoin.join(' ')
  // }

  // async function fetchAPIProvinsi() {
  //     const response = await fetch(`http://202.182.55.252:3000/provinsi/all`)
  //     const json = await response.json()
  //     // get Rows
  //     let rowsData = [];
  //     for (let i of json.data) {
  //         rowsData.push(formatText(i.name));
  //     }
  //     setFilterContain(rowsData);
  // }
  // useEffect(() => {
  //     fetchAPIProvinsi();
  // }, [])

  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle caret color="primary" className="dropdown-rekomendasi">
          <div>{filter === "" ? `Pilih ${props.type}` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus">
          {filterContain.map((fc, i) => (
            <MDBDropdownItem active={filter === fc} onClick={() => clicked(fc, props.type)} key={i}>
              {fc}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
}
export default FilterButton;
