import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Button } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "./style.css";
import DownWhite from "../../../assets/images/backwhite.png";
// -------------------COMPONENT--------------------
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Header from "../../../components/VerticalLayout/Header";
import TableUniqueDevice from "../../../components/Admin/Tables/UniqueDevice/UniqueDevice";
import TableDataSiteAI from "../../../components/Admin/Tables/UniqueDevice/DataSiteAI";
import TableBehaviorSite from "../../../components/Admin/Tables/UniqueDevice/BehaviorSite";

import { exportPDF } from "../../../components/ContentPartner/Download/KontenPreview/PDF/DownloadPDF";
import DownloadXLS1 from "../../../components/ContentPartner/Download/KontenPreview/XLS/DownloadXLS1";
import DownloadXLS2 from "../../../components/ContentPartner/Download/KontenPreview/XLS/DownloadXLS2";
import DownloadXLS3 from "../../../components/ContentPartner/Download/KontenPreview/XLS/DownloadXLS3";

// ------------------- API -------------------------

import API from "../../../services";

function ContentPreviewCP(props) {
  let role = window.localStorage.getItem("role");
  //handle cant click download if data still onload
  const [loading, setLoading] = useState(true);
  //handle data xls
  const [data1, setData1] = useState([]); // UNIQUE DEVICE
  const [data2, setData2] = useState([]); // DATA SITE AI
  const [data3, setData3] = useState([]); // BEHAVIOR SITE

  // const [province, setProvince] = useState("");
  // const [search, setSearch] = useState("");
  //handle data when change tab
  const handleDataUniqueDevice = (data) => {
    // setLoading(true);

    // API.getUniqueDevice({
    //   search: search,
    //   skip: 0,
    //   limit: 0,
    // })
    //   .then((res) => {
    //     if (res.data.count > 0) {
    //       setData1(res.data.data);
    //     }

    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setData1([]);
    //     setLoading(false);
    //   });

    setData1(data);
    setLoading(false);
  };

  const handleDataSiteai = (data) => {
    // API.getDataSiteAI({
    //   provinsi: province,
    //   search: search,
    //   skip: 0,
    //   limit: 0,
    // })
    //   .then((res) => {
    //     if (res.data.count > 0) {
    //       console.log(" downloading => ", res.data.data);
    //       setData2(res.data.data);
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setData2([]);
    //     setLoading(false);
    //   });
    setData2(data);
    setLoading(false);
  };

  const handleDataBehaviourSite = (data) => {
    // setLoading(true);

    // API.getBehaviourSite({
    //   provinsi: province,
    //   search: search,
    //   skip: 0,
    //   limit: 0,
    // })
    //   .then((res) => {
    //     if (res.data.count > 0) {
    //       setData3(res.data.data);
    //     }

    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setData3([]);
    //     setLoading(false);
    //   });

    setData3(data);
    setLoading(false);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {}, [activeTab]);
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  //handle download pdf
  const handleDownloadPDF = () => {
    if (!loading) {
      //loading
      exportPDF(activeTab === "1" ? data2 : activeTab === "2" ? data3 : data1, activeTab);
      setLoading(false);
    }
  };

  // logic for preparing data before ready to download/export data
  const isBtnDownloadDisable = useMemo(() => {
    let disable = true;

    // change disable to false when data is ready
    if (
      (activeTab === "1" && data2.length) ||
      (activeTab === "2" && data3.length)
      // (activeTab === "3" && data1.length)
    ) disable = false;

    return disable;
  }, [data1, data2, data3, activeTab]);

  const tabStyle = {
    color: "#c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="Unique Device" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Table" /> */}
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12">
                    <MDBNav classicTabs className="wrapperNav">
                      <div className="wrapperTab">
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "1" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "1"}
                            onClick={() => handleChangeTab("1")}>
                            DATA SITE AI
                          </MDBNavLink>
                          <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "2" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "2"}
                            onClick={() => handleChangeTab("2")}>
                            BEHAVIOR SITE
                          </MDBNavLink>
                          <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "3" ? tabStyle : {}}
                            className="tabMenu waves-light"
                            active={activeTab === "3"}
                            onClick={() => handleChangeTab("3")}>
                            UNIQUE DEVICE
                          </MDBNavLink>
                          <hr style={activeTab === "3" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                      </div>
                      {/* <div className="wrapperAction">
                        <MDBNavLink to="#" className="textDownload">
                          Download
                        </MDBNavLink>
                        {activeTab === "1" ? (
                          loading ? (
                            <Button
                              color="#556ee6"
                              disable={loading}
                              className={
                                loading ? "disablePointer linkDownload" : "pointer linkDownload"
                              }>
                              XLS
                            </Button>
                          ) : (
                            <ReactHTMLTableToExcel
                              id="TableXLS"
                              className="DownloadTableXLS"
                              table="DownloadTableXLS1"
                              filename="Data Unique Device"
                              sheet="users"
                              buttonText="XLS"
                            />
                          )
                        ) : activeTab === "2" ? (
                          loading ? (
                            <Button
                              color="#556ee6"
                              disable={loading}
                              className={
                                loading ? "disablePointer linkDownload" : "pointer linkDownload"
                              }>
                              XLS
                            </Button>
                          ) : (
                            <ReactHTMLTableToExcel
                              id="TableXLS"
                              className="DownloadTableXLS"
                              table="DownloadTableXLS2"
                              filename="Data Site AI"
                              sheet="users"
                              buttonText="XLS"
                            />
                          )
                        ) : activeTab === "3" ? (
                          loading ? (
                            <Button
                              color="#556ee6"
                              disable={loading}
                              className={
                                loading ? "disablePointer linkDownload" : "pointer linkDownload"
                              }>
                              XLS
                            </Button>
                          ) : (
                            <ReactHTMLTableToExcel
                              id="TableXLS"
                              className="DownloadTableXLS"
                              table="DownloadTableXLS3"
                              filename="Data Behaviour Site"
                              sheet="users"
                              buttonText="XLS"
                            />
                          )
                        ) : (
                          <></>
                        )}

                        <Button
                          color="#556ee6"
                          disable={loading}
                          onClick={handleDownloadPDF}
                          className={
                            loading ? "disablePointer linkDownload" : "pointer linkDownload"
                          }>
                          PDF
                        </Button>
                      </div> */}

                      <div
                        className="wrapperDropdownResponsive"
                        style={{
                          width: "25%",
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "0.75%",
                        }}>
                        <MDBDropdown
                          style={{ width: "100% !important" }}
                          className="dropdown-download-doc">
                          <MDBDropdownToggle
                            disabled={isBtnDownloadDisable}
                            caret
                            color="primary"
                            className="dropdown-download font-weight-bold">
                            <div className="wrapper-selected">
                              {
                                (activeTab === '3') ? 'Download' :
                                  isBtnDownloadDisable ? 'Preparing data...' : 'Download'
                              }
                            </div>
                            <div>
                              <img src={DownWhite} alt="dropdown-icons" />
                            </div>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu className="dropdown-menus dropdown-show">
                            <MDBDropdownItem
                              style={{ color: "#556ee6" }}
                              disable={loading}
                              className={
                                loading
                                  ? "disablePointer linkDownload text-center"
                                  : "pointer linkDownload text-center"
                              }
                              onClick={handleDownloadPDF}>
                              PDF
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                              {activeTab === "3" ? (
                                loading ? (
                                  <Button
                                    //onClick={handleDataUniqueDevice}
                                    color="#556ee6"
                                    disable={loading}
                                    className={
                                      loading
                                        ? "disablePointer linkDownload text-center"
                                        : "pointer linkDownload text-center"
                                    }>
                                    XLS
                                  </Button>
                                ) : (
                                  <ReactHTMLTableToExcel
                                    id="TableXLS"
                                    className="DownloadTableXLSDropdown"
                                    table="DownloadTableXLS1"
                                    filename="Data Unique Device"
                                    sheet="users"
                                    buttonText="XLS"
                                  />
                                )
                              ) : activeTab === "1" ? (
                                loading ? (
                                  <Button
                                    //onClick={handleDataSiteai}
                                    color="#556ee6"
                                    disable={loading}
                                    className={
                                      loading
                                        ? "disablePointer linkDownload text-center"
                                        : "pointer linkDownload text-center"
                                    }>
                                    XLS
                                  </Button>
                                ) : (
                                  <ReactHTMLTableToExcel
                                    id="TableXLS"
                                    className="DownloadTableXLSDropdown"
                                    table="DownloadTableXLS2"
                                    filename="Data Site AI"
                                    sheet="users"
                                    buttonText="XLS"
                                  />
                                )
                              ) : activeTab === "2" ? (
                                loading ? (
                                  <Button
                                    //onClick={handleDataBehaviourSite}
                                    color="#556ee6"
                                    disable={loading}
                                    className={
                                      loading
                                        ? "disablePointer linkDownload text-center"
                                        : "pointer linkDownload text-center"
                                    }>
                                    XLS
                                  </Button>
                                ) : (
                                  <ReactHTMLTableToExcel
                                    id="TableXLS"
                                    className="DownloadTableXLSDropdown"
                                    table="DownloadTableXLS3"
                                    filename="Data Behaviour Site"
                                    sheet="users"
                                    buttonText="XLS"
                                  />
                                )
                              ) : (
                                <></>
                              )}
                            </MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                    </MDBNav>

                    <DownloadXLS1
                      id="DownloadTableXLS1"
                      className="DownloadTableXLS1"
                      data={data1}
                      activeTab={activeTab}
                    />

                    <DownloadXLS2
                      id="DownloadTableXLS2"
                      className="DownloadTableXLS2"
                      data={data2}
                      activeTab={activeTab}
                    />

                    <DownloadXLS3
                      id="DownloadTableXLS3"
                      className="DownloadTableXLS3"
                      data={data3}
                      activeTab={activeTab}
                    />
                    <MDBTabContent className="card" activeItem={activeTab}>
                      <MDBTabPane tabId="1">
                        {activeTab === "1" && (
                          <TableDataSiteAI
                            activeTab={activeTab}
                            // setSearch={setSearch}
                            // setProvince={setProvince}
                            handleData={handleDataSiteai}
                          />
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId="2">
                        {activeTab === "2" && (
                          <TableBehaviorSite
                            activeTab={activeTab}
                            // setSearch={setSearch}
                            // setProvince={setProvince}
                            handleData={handleDataBehaviourSite}
                          />
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId="3">
                        {activeTab === "3" && (
                          <TableUniqueDevice
                            activeTab={activeTab}
                            // setSearch={setSearch}
                            // setProvince={setProvince}
                            handleData={handleDataUniqueDevice}
                          />
                        )}
                      </MDBTabPane>
                    </MDBTabContent>
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

export default ContentPreviewCP;
