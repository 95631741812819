import Loadable from "react-loadable";
import Loading from "../../components/Loading";

const EditArticleAsync = Loadable({
  loader: () =>
    import("../../pages/Artikel/EditArticle" /* webpackChunkName: "EditArticleAsync" */),
  loading: Loading,
});

export default EditArticleAsync;
