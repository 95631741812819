import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <>
      <Table
        style={{ display: "none" }}
        id="DownloadTableXLSSurveySummaryProv"
        className="DownloadTableXLS">
        <thead>Persebaran Jumlah Pengisi Survei Per Provinsi Judul Survei {props.title}</thead>
        <thead>
          <tr>
            <th>Provinsi</th>
            <th>Jumlah</th>
          </tr>
        </thead>
        <tbody>
          {data && Object.keys(data).length !== 0 ? (
            data.count_province ? (
              data.count_province.length !== 0 ? (
                data.count_province.map((e, i) => (
                  <tr key={i} style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{e.province ?? "-"}</td>
                    <td>{e.count ?? "-"}</td>
                  </tr>
                ))
              ) : (
                <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <td>-</td>
                  <td>-</td>
                </tr>
              )
            ) : (
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>-</td>
                <td>-</td>
              </tr>
            )
          ) : (
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default DownloadXLS;
