// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_headText__1xTr2 {\n  text-align: center !important;\n  margin-left: 20px !important;\n}\n\n.styles_title__9F21P {\n  color: #333;\n  font-weight: bold;\n  text-transform: uppercase;\n}\n\n.styles_sub_title__3yo42 {\n  color: #777;\n}\n\n.styles_text__3QT8O {\n  color: #777;\n  font-weight: bold;\n  margin-bottom: 50px;\n}\n.styles_inputError__Hun-w {\n  background-color: #e8e8e8 !important;\n  /* border-color: none; */\n  width: 80% !important;\n  border-radius: 10px !important;\n  border: 1px solid #c42127 !important;\n  margin-bottom: 1% !important;\n  outline: 0 !important;\n  display: block !important;\n  justify-content: center !important;\n  padding-left: 2% !important;\n}\n.styles_input__1r8cr {\n  background-color: #e8e8e8 !important;\n  /* border-color: none; */\n  width: 80% !important;\n  border-radius: 10px !important;\n  border: 1px solid rgb(163, 157, 157) !important;\n  margin-bottom: 1% !important;\n  outline: 0 !important;\n  display: block !important;\n  justify-content: center !important;\n  padding-left: 2% !important;\n}\n\n.styles_uploadData__3bNnJ {\n  width: 80% !important;\n  border-radius: 10px !important;\n  border: 1px solid rgb(163, 157, 157) !important;\n  margin-top: 2% !important;\n  background-color: #f8f8f8;\n  padding: 4px;\n}\n\n.styles_btn_cancel__48AHc {\n  cursor: pointer;\n}\n\n.styles_wrapper__JbQ4l {\n  margin-bottom: 50px;\n  display: block !important;\n}\n\n.styles_btn_confirm__1mD7P {\n  background-color: #2c3280 !important;\n  padding: 8px 40px !important;\n  color: #fff !important;\n  cursor: pointer;\n  font-size: 14px !important;\n  border-radius: 10px !important;\n  margin-bottom: 10px !important;\n  margin-left: 32%;\n  margin-top: 2% !important;\n}\n\n.styles_wrapField__3vQyP {\n  margin-left: 16%;\n}\n\n.styles_lblInput__hdeox {\n  margin: 8px 0px 8px 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"headText": "styles_headText__1xTr2",
	"title": "styles_title__9F21P",
	"sub_title": "styles_sub_title__3yo42",
	"text": "styles_text__3QT8O",
	"inputError": "styles_inputError__Hun-w",
	"input": "styles_input__1r8cr",
	"uploadData": "styles_uploadData__3bNnJ",
	"btn_cancel": "styles_btn_cancel__48AHc",
	"wrapper": "styles_wrapper__JbQ4l",
	"btn_confirm": "styles_btn_confirm__1mD7P",
	"wrapField": "styles_wrapField__3vQyP",
	"lblInput": "styles_lblInput__hdeox"
};
module.exports = exports;
