import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";

function PopupVideo(props) {
  let a =
    Object.keys(props.data).length === 0
      ? ""
      : typeof props.data.video._id !== "undefined" && props.data.video;
  let link = `${process.env.REACT_APP_BE_URL}/${
    typeof a.localDetails === "undefined" ? "" : a.localDetails && a.localDetails[2].url
  }`;

  return (
    <MDBContainer>
      <MDBModal size="lg" isOpen={props.modalVid} toggle={props.toggleVid} centered={true}>
        <MDBModalHeader toggle={props.toggleVid}>Video</MDBModalHeader>
        <MDBModalBody>
          <h5 style={{ textAlign: "center" }}>
            {typeof a.details === "undefined" ? "" : a.details.title}
          </h5>
          <div className="player-wrapper">
            <iframe
              title={a.title}
              width="100%"
              height="500"
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
              src={
                //todo
                // a.type === "youtube"
                //   ? `https://www.youtube.com/embed/${
                //       typeof a.localDetails === "undefined" ? "" : a.localDetails[2].url
                //     }`
                //   : `${process.env.REACT_APP_BE_URL}/${
                //       typeof a.localDetails === "undefined" ? "" : a.localDetails[2].url
                //     }`
                link ? link : "-"
              }></iframe>
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
}
export default PopupVideo;
