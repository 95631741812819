import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

import Header from "../../../components/VerticalLayout/Header";
import "./style.css";

import { Col, Row, Container, Alert, Input } from "reactstrap";

import curate2 from "../../../assets/images/homeCP/curate(1).png";
import partner from "../../../assets/images/partner.png";
import devices from "../../../assets/images/Devices.png";
import user from "../../../assets/images/User.png";

import CardHomeCP from "../../../components/Card/Admin/CardHome/CardHome";
import CardLineChart from "../../../components/Card/Admin/CardHome/CardBarChart";
import CardMap from "../../../components/Card/Admin/CardHome/CardMap";
import API from "../../../services";

export default function Home() {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);

  const onDismiss = () => setVisible(false);
  let history = useHistory();

  // untuk loading skeleton
  const skeletonCostume = <Skeleton circle={true} height={50} width={50} />;

  // data category
  let idUser = window.localStorage.getItem("idUser");

  const getHome = () => {
    API.getHomeAdmin()
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setData(res.data.data);
        } else {
          setLoading(false);
          setData([]);
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        setData(null);
      });
  };

  useEffect(() => {
    getHome();
  }, []);

  return (
    <div>
      <Header title="Home" />
      <Container>
        {error && (
          <Alert color="danger" isOpen={visible} toggle={onDismiss} className="text-center">
            Terjadi Kesalahan Pada Server
          </Alert>
        )}
      </Container>
      <Container fluid>
        {/* <Row className="mx-auto">
          <Col md="9">
            <div className="float-right" style={{ textAlign: "right", lineHeight: 3 }}>
              Filter By
            </div>
          </Col>
          <Col md="3">
            <Input type="select">
              <option value="">-- Year --</option>
              <option value="2021"> 2021 </option>
              <option value="2022"> 2022 </option>
            </Input>
          </Col>
        </Row> */}
        <Row className="mt-5 mb-5">
          <Col md="3">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp">
                <div>
                  <img src={partner} alt="people" />
                </div>
                <div className="title-total">TOTAL CONTENT PARTNER</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                  <div id="wrapper-totalContentpartner" className="total-content">
                    {data ? (data[0] ? data[0].countContentPartner.toLocaleString() ?? 0 : 0) : 0}
                  </div>
                )}
              </div>
            </Row>
          </Col>
          <Col md="3">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp">
                <div>
                  <img src={curate2} alt="curate" />
                </div>
                <div className="title-total">KURATOR TERDAFTAR</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                  <div id="wrapper-kuratorRegistered" className="total-content">
                    {data ? (data[1] ? data[1].countKurator.toLocaleString() ?? 0 : 0) : 0}
                  </div>
                )}
              </div>
            </Row>
          </Col>
          <Col md="3">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp">
                <div>
                  <img src={user} alt="curated" />
                </div>
                <div className="title-total">PENGGUNA BARU BULAN INI</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                  <div id="wrapper-newUser" className="total-content">
                    {data ? (data[2] ? data[2].penggunaBaruBulanIni.toLocaleString() ?? 0 : 0) : 0}
                  </div>
                )}
              </div>
            </Row>
          </Col>
          <Col md="3">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner-cp">
                <div>
                  <img src={devices} alt="curated" />
                </div>
                <div className="title-total">TOTAL PENGGUNA UNIK</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                  <div id="wrapper-totalUniqueDfp" className="total-content">
                    {data ? (data[3] ? data[3].totaldfpunik.toLocaleString() ?? 0 : 0) : 0}
                  </div>
                )}
              </div>
            </Row>
          </Col>
        </Row>
        {loading == true ? (
          <Row className="justify-content-center mb-5">
            <Col md="8">
              <Skeleton count={3} />
            </Col>
          </Row>
        ) : (
          <Container fluid>
            <CardHomeCP data={data} loading={loading} />
            <CardLineChart data={data} loading={loading} />
            <CardMap data={data} loading={loading} />
          </Container>
        )}
      </Container>
    </div>
  );
}
