import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const BuatKontenPartnerAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/BuatKonten/ContentPartner" /* webpackChunkName: "BuatKontenPartnerAsync" */
    ),
  loading: Loading,
});

export default BuatKontenPartnerAsync;
