import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDF = (dataTable, activeTab) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginBottom = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  const headers = [
    activeTab === "1"
      ? ["No", "Nama Survei", "Jumlah Pertanyaan", "Provinsi", "Tanggal Awal", "Tanggal Akhir"]
      : ["No", "Nama Survei", "Jumlah Pertanyaan", "Status"],
  ];
  const data = dataTable.map((data, index) => {
    return activeTab === "1"
      ? [
          index + 1,
          data ? data._id : "-",
          data ? data.questions.length : "-",
          data.proposed_provinsi.length > 2
            ? `${data ? (data.proposed_provinsi[0] ? data.proposed_provinsi[0] : "-") : "-"}, ${
                data ? (data.proposed_provinsi[1] ? data.proposed_provinsi[1] : "-") : "-"
              }, ${data ? (data.proposed_provinsi[2] ? data.proposed_provinsi[2] : "-") : "-"}...`
            : data.proposed_provinsi.length > 1
            ? `${data.proposed_provinsi[0] ? data.proposed_provinsi[0] : "-"},${
                data.proposed_provinsi[1] ? data.proposed_provinsi[1] : "-"
              }`
            : `${data.proposed_provinsi[0] ? data.proposed_provinsi[0] : "-"}`,

          data
            ? data.questions[0]
              ? moment(data.questions[0].tanggal_pembuatan).format("DD-MM-YYYY")
              : "-"
            : "-",
          data
            ? data.questions[0]
              ? moment(data.questions[0].tanggal_pembaruan).format("DD-MM-YYYY")
              : "-"
            : "-",
        ]
      : [data ? data.name : "-", data ? data.questions.length : "-", data ? data.status : "-"];
  });

  doc.text(activeTab === "1" ? "Survey Report" : "Survey Default Report", marginBottom, 40);
  doc.autoTable({ head: headers, body: data });
  doc.save(activeTab === "1" ? `Survey_Report_${role}.pdf` : `Survey_Default_Report_${role}.pdf`);
};
