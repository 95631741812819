import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import API from "../../../../services";

export default function StatusPopupInfo(props) {
  let { modal, toggle, data } = props;

  const handleStatus = (val) => {
    let param = {
      idArticle: val._id,
    };

    if (props.typeContent !== "Video") {
      API.postChangeStatusArticle(param)
        .then((response) => {
          toggle();
          window.location.reload();
        })
        .catch((e) => {
          console.error('StatusPopupInfo', { error: e.message })
        });
    } else {
      param = {
        idVideoHome: val._id,
      };
      API.changeStatusVideo(param)
        .then((response) => {
          toggle();
          window.location.reload();
        })
        .catch((e) => {
          console.error('StatusPopupInfo', { error: e.message })
        });
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>NONAKTIFKAN ARTIKEL</ModalHeader>
        <ModalBody className="text-center">
          <h3 style={{ color: "#393939" }}>{data !== undefined && data.judul}</h3>
          <hr width={140} />
          <p>Anda yakin ingin Posting Artikel ini pada Homepage?</p>
          <div className="m-2">
            <Button onClick={() => handleStatus(data)} style={{ backgroundColor: "#C42127" }}>
              Non Aktif
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
