import React, { useEffect } from 'react';
import { Container, Card, CardBody, Row, Col, Button } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import API from '../../../services';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import DeleteNewsletter from '../Popup/Article/DeleteNewsletter';

export default function TableTotalShare() {
    const [dataRows, setDataRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const getHitArticle = () => {
        API.getHitArticle().then(response => {
            // console.log(response.data.data)
            setDataRows(response.data.data)
            setLoading(false)
        }).catch(
            setLoading(true)
        )
    }

    useEffect(() => {
        getHitArticle()
    }, [])

    const data = {
        columns: [
            {
                label: "Platform Social Media",
                field: "platform",
                sort: "disabled",
            },
            {
                label: "Total Hit",
                field: "totalhit",
                sort: "disabled",
            },
            {
                label: "Total Share",
                field: "totalshare",
                sort: "disabled",
            },
        ],
        rows: dataRows.map((val) => ({
            platform: val.type,
            totalhit: val.hits,
            totalshare: val.share
        }))
    }
    return (
        <div>
            <Container style={{ marginBottom: "10%" }}>
                <div>
                    {
                        loading == true ? <div className="mt-2 mb-2"><Skeleton height={100} /></div> :
                            <Card style={{ boxShadow: "0px 0px 10px #D3D3D3", paddingBottom: "30px", paddingTop: "20px" }}>
                                <CardBody>
                                    <h4>
                                        Total Share Sosial Media pada Homepage
                                    </h4>
                                    <MDBDataTable
                                        responsive
                                        striped
                                        searching={false}
                                        entries={10}
                                        info={false}
                                        infoLabel={""}
                                        paginationLabel={""}
                                        paging={false}
                                        displayEntries={false}
                                        data={data}
                                        noBottomColumns={true}
                                    />
                                </CardBody>
                            </Card>
                    }
                </div>
            </Container>
        </div>
    )
}
