import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import img from "../../../../..";

const DownloadXLS = (props) => {
  // console.log("props", props);
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  let judul;
  switch (props.activeTab) {
    case "1":
      judul = "KONTEN VIDEO";
      break;
    case "2":
      judul = "KONTEN BANNER";
      break;
    case "3":
      judul = "KONTEN BERITA";
      break;
    default:
      break;
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`DATA ${judul} ${role}`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Judul</th>
          <th>Organisasi</th>
          <th>Kategori</th>
          <th>Provinsi</th>
          <th>Status</th>
          <th style={{ width: "300px" }}>Preview</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index} style={{ height: "200px", textAlign: "center", verticalAlign: "middle" }}>
            <td>{index + 1}</td>
            <td>{data.title ? data.title ?? "-" : data.details.title ?? "-"}</td>
            <td>
              {data
                ? data.requested_by
                  ? data.requested_by.organization
                    ? data.requested_by.organization.name
                      ? data.requested_by.organization.name
                      : "-"
                    : "-"
                  : "-"
                : "-"}
            </td>
            <td>{data.category ?? "-"}</td>
            <td>{data.proposed_provinsi.name ?? "-"}</td>
            <td>{data.status == "activated" ? "Aktif" : "Tidak Aktif"}</td>
            <td>
              <img
                src={
                  props.activeTab === "1"
                    ? `${process.env.REACT_APP_SELF_URL}/${data.details.thumbnail}`
                    : typeof data.details === "object"
                    ? `${process.env.REACT_APP_SELF_URL}/${data.details.url}`
                    : `${process.env.REACT_APP_SELF_URL}/${data.details}`
                }
                height="100"
                style={{
                  objectFit: "cover",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}></img>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
