import React, { useEffect, useState, createRef, useMemo } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import "./style.css";
import { Container, Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
// ------------------REDUX---------------------
import { connect, useSelector } from "react-redux";
import { listProvinsi } from "./const";
// ------------------API---------------------
import _ from "lodash";

const ModalTambahProvSurvei = (props) => {
  const [selectAllProv, setSelectAllProv] = useState([]);
  const [selectAllIdProv, setSelectAllIdProv] = useState([]);
  const [isSelectAllProvince, setIsSelectAllProvince] = useState(false);
  const [select, setSelect] = useState("");
  const [idsProv, setIdsProv] = useState([]);
  useEffect(() => {
    props.existProv && setSelect(props.existProv);
    props.existProvIds && setIdsProv(props.existProvIds);

    setSelectAllProv(_.map(listProvinsi, "name"));
    setSelectAllIdProv(_.map(listProvinsi, "id"));
  }, [props.existProv, props.existProvIds]);
  const [filtered, setFiltered] = useState([]);
  const handleChangeRadio = (e, all) => {
    props.isLoadDate(false);
    let selectIdProv = e.target.getAttribute("data-prov");
    if (e.target.checked) {
      if (all == true) {
        setIsSelectAllProvince(true);
        setSelect(_.map(listProvinsi, "name"));
        setFiltered(_.map(listProvinsi, "name"));
        setIdsProv(_.map(listProvinsi, "_id"));
      } else {
        setIsSelectAllProvince(false);
        setSelect([...select, e.target.value]);
        setFiltered([...select, e.target.value]);
        setIdsProv([...idsProv, selectIdProv]);
      }
    } else {
      let filterProvId;
      let filterProvName;
      if (all) {
        setIsSelectAllProvince(false);
        filterProvId = idsProv.filter((it) => !selectAllIdProv.includes(it));
        filterProvName = select.filter((it) => !selectAllProv.includes(it));
        setSelect(filterProvName);
        setFiltered(filterProvName);
        setIdsProv(filterProvId);
      } else {
        setIsSelectAllProvince(false);
        filterProvId = idsProv.filter((it) => it !== e.target.value);
        filterProvName = select.filter((it) => it !== e.target.value);
        setSelect(filterProvName);
        setFiltered(filterProvName);
        setIdsProv(filterProvId);
      }
    }
  };
  let handleSubmit = () => {
    props.selectedProvince(filtered, idsProv);
    props.toggleModalTambahProvinsi();
  };
  return (
    <MDBContainer>
      <MDBModal
        size="md"
        isOpen={props.modalTambahProvinsi}
        toggle={props.toggleModalTambahProvinsi}
        centered="true">
        <MDBModalHeader centered toggle={props.toggleModalTambahProvinsi}>
          Pilih Provinsi
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row
              className="col-lg-10 mx-auto col-offset-2"
              style={{
                backgroundColor: "#f7f7f7",
                width: "100%",
                overflowX: "auto",
              }}>
              <Col className="" style={{ padding: "5px", overflowY: "hidden", overflowX: "auto" }}>
                <div
                  id="groupAllProvinsi"
                  className="custom-control custom-checkbox mb-2"
                  style={{ overflowY: "scroll" }}>
                  <input
                    type="checkbox"
                    checked={isSelectAllProvince}
                    className="custom-control-input input-mini"
                    onChange={(e) => handleChangeRadio(e, true)}></input>
                  <Label className={"custom-control-label textCheckbox"}>
                    Pilih Semua Provinsi
                  </Label>
                </div>
              </Col>
            </Row>
            <Row
              className="col-lg-10 mx-auto col-offset-2"
              style={{
                backgroundColor: "#f7f7f7",
                height: "200px",
                width: "100%",
                overflowX: "auto",
              }}>
              <Col
                md="5"
                xs="5"
                lg="5"
                style={{ height: "200px", padding: "5px", overflowY: "hidden", overflowX: "auto" }}>
                <div
                  id="groupAllProvinsi"
                  className="custom-control custom-checkbox mb-2"
                  style={{ overflowY: "scroll", height: "200px" }}>
                  {listProvinsi.map((list, i) => (
                    <div key={i}>
                      <input
                        type="checkbox"
                        data-prov={list._id}
                        checked={select.includes(list.name) ? true : false}
                        className="custom-control-input input-mini"
                        name={`${list.name}${i}`}
                        value={`${list.name}`}
                        onChange={(e) => handleChangeRadio(e, false)}
                        id={i}></input>
                      <Label
                        className={"custom-control-label textCheckbox"}
                        for={i}>{`${list.name}`}</Label>
                    </div>
                  ))}
                </div>
              </Col>
              <Col lg="2" md="2" xs="2">
                <div className="v3"></div>
              </Col>
              <Col
                lg="5"
                md="5"
                xs="5"
                style={{ marginLeft: "0 !important", height: "200px", overflowY: "hidden" }}>
                <div style={{ height: "190px", overflow: "scroll" }}>
                  {select !== "" ? (
                    select.map((e) => <Label className="textCheckbox d-block">{e}</Label>)
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Button
                color=""
                onClick={handleSubmit}
                className="btn btn-rounded btnCekJam col-3 col-offset-2 mt-2 mx-auto">
                OK
              </Button>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
export default ModalTambahProvSurvei;
