import React, { useState } from "react";
import { MDBModal } from "mdbreact";
import "./style.css";
// ------------------API-----------------------
import API from "../../../../../services";
const ModalDecline = ({
  modalDecline,
  toggleModalDecline,
  dataModalDecline,
  handleAlert,
  fetchData,
}) => {
  const [comment, setComment] = useState("");
  let idUser = dataModalDecline.id
  let param = {
    id:  idUser ,
    comment: comment,
  };
  const onHandleSubmit = () => {
    API.postDeclineUser(param)
      .then(async (res) => {
        if (res.data.success) {
          new Promise(() => {
            toggleModalDecline();
            handleAlert.setAlert("successDecline");
            setTimeout(() => {
              handleAlert.setAlert("");
            }, 2000);
          }, fetchData());
        } else {
          new Promise(() => {
            toggleModalDecline();
            handleAlert.setAlert("failedDecline");
            setTimeout(() => {
              handleAlert.setAlert("");
            }, 2000);
          }, fetchData());
        }
      })
      .catch((e) => {
        new Promise(() => {
          toggleModalDecline();
          handleAlert.setAlert("failedFetch");
          setTimeout(() => {
            handleAlert.setAlert("");
          }, 2000);
        }, fetchData());
      });
  };
  return (
    <MDBModal isOpen={modalDecline} toggle={toggleModalDecline} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleDecline">TOLAK PENGGUNA</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalDecline}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">
              {dataModalDecline
                ? dataModalDecline.data
                  ? dataModalDecline.data.details
                    ? dataModalDecline.data.details.title
                    : ""
                  : ""
                : ""}
              {dataModalDecline
                ? dataModalDecline.data
                  ? dataModalDecline.data
                    ? dataModalDecline.data.title
                    : ""
                  : ""
                : ""}
            </h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleDecline">Anda yakin ingin Tolak pengguna ini?</h4>
          </div>

          <div className="form-group wrapperTextAreaDecline">
            <textarea
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              className="form-control textAreaDecline"
              rows="5"></textarea>
          </div>
          <div className="mx-auto wrapperBtnVerification">
            <button
              type="button"
              onClick={onHandleSubmit}
              className="w-100 btn wrapperBtnDecline btn-rounded">
              Tolak
            </button>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalDecline;
