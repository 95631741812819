import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return props.cond == "prov" ? (
    <Table style={{ display: "none" }} id="DownloadTableXLSPersebaran" className="DownloadTableXLS">
      <thead>
        Data Persebaran Pengunjung Berdasarkan {props.titleData} {props.titleDate} Tanggal {props.startDate} - {props.endDate}
      </thead>
      <thead>
        <tr>
          <th>Provinsi</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Jumlah</th>
        </tr>
      </thead>
      <tbody>
        {data !== undefined &&
          data?.map((e, index) => (
            <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{e.name ? e.name ?? "-" : "-"}</td>
              <td>{e.latitude ? e.latitude ?? "-" : "-"}</td>
              <td>{e.longitude ? e.longitude ?? "-" : "-"}</td>
              <td>{e.count ? e.count ?? "-" : "-"}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  ) : props.cond == "kab" ? (
    <Table style={{ display: "none" }} id="DownloadTableXLSPersebaran" className="DownloadTableXLS">
      <thead>
        Data Persebaran Pengunjung Kabupaten {props?.startDate ?? ""} - {props?.endDate ?? ""}
      </thead>
      <thead>
        <tr>
          <th>Provinsi</th>
          <th>Kabupaten</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Jumlah</th>
        </tr>
      </thead>
      <tbody>
        {data !== undefined &&
          data?.map((e, index) => (
            <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{e.province ? e.province ?? "-" : "-"}</td>
              <td>{e.name ? e.name ?? "-" : "-"}</td>
              <td>{e.latitude ? e.latitude ?? "-" : "-"}</td>
              <td>{e.longitude ? e.longitude ?? "-" : "-"}</td>
              <td>{e.count ? e.count ?? "-" : "-"}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  ) : props.cond == "kec" ? (
    <Table style={{ display: "none" }} id="DownloadTableXLSPersebaran" className="DownloadTableXLS">
      <thead>
        Data Persebaran Pengunjung Kecamatan {props.startDate} - {props.endDate}
      </thead>
      <thead>
        <tr>
          <th>Provinsi</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Jumlah</th>
        </tr>
      </thead>
      <tbody>
        {data !== undefined &&
          data?.map((e, index) => (
            <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{e.name ? e.name ?? "-" : "-"}</td>
              <td>{e.latitude ? e.latitude ?? "-" : "-"}</td>
              <td>{e.longitude ? e.longitude ?? "-" : "-"}</td>
              <td>{e.count ? e.count ?? "-" : "-"}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  ) : props.cond == "desa" ? (
    <Table style={{ display: "none" }} id="DownloadTableXLSPersebaran" className="DownloadTableXLS">
      <thead>
        Data Persebaran Pengunjung Desa {props.startDate} - {props.endDate}
      </thead>
      <thead>
        <tr>
          <th>Provinsi</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Jumlah</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((e, index) => (
            <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{e.name ? e.name ?? "-" : "-"}</td>
              <td>{e.latitude ? e.latitude ?? "-" : "-"}</td>
              <td>{e.longitude ? e.longitude ?? "-" : "-"}</td>
              <td>{e.count ? e.count ?? "-" : "-"}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  ) : (
    <Table style={{ display: "none" }} id="DownloadTableXLSPersebaran" className="DownloadTableXLS">
      <thead>
        Data Persebaran Pengunjung {props?.startDate ?? ""} - {props?.endDate ?? ""}
      </thead>
      <thead>
        <tr>
          <th>Error</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
          <td>Error</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
