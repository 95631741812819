import Loadable from "react-loadable";
import Loading from "../../../../../components/Loading";

const ISPAsync = Loadable({
  loader: () =>
    import(
      "../../../../../pages/BusinessIntelligence/Admin/Measures/ISP" /* webpackChunkName: "ISPAsync" */
    ),
  loading: Loading,
});

export default ISPAsync;
