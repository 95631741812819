import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDF = (dataTable, dataTable2, activeTab, activeSubTabKelola, titleDownload) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title;
  switch (activeTab) {
    case "1":
      title = `Persetujuan Pengguna`;
      break;
    case "2":
      title = activeSubTabKelola === "1" ? `Kelola Pengguna Content Partner` : activeSubTabKelola === "2" ? "Kelola Pengguna Content Admin" : activeSubTabKelola === "3" ? "Kelola Pengguna Kurator" : "Kelola Pengguna Support";
      break;

    default:
      break;
  }
  const headers = [
    activeTab === "1" ?
    ["No", "Username", "Nama", "Email", "Organisasi", "Peran", "Waktu Registrasi", "No Telefon", "KTP"] :
    ["No", "Username", "Nama", "Email", "Organisasi", "Waktu Registrasi", "No Telefon", "Status"]
  ];

  const data = activeTab === "1" ?
    dataTable.map((data, index) => {
      return [
        index + 1,
        data.username,
        data.name,
        data.email,
        data.organization ? data.organization.name : "-",
        data.organization ? data.organization.role.description : "-",
        moment(data.tanggal_pembuatan).format("DD-MM-YYYY"),
        data.phone,
      ];
    }) : 
    dataTable2.map((data, index) => {
      let status = data.status.toLowerCase().replace(/ /g, "")
      return [
        index + 1,
        data.username ?? "-",
        data.name ?? "-",
        data.email ?? "-",
        data ? (data.organization ? data.organization.name ?? "-" : "-") : "-",
        `${moment(data.tanggal_pembuatan).format("DD-MM-YYYY")}` ?? "-",
        data.phone,
        status === "nonaktif" ? "Non Aktif" : status === "dormant" ? "Dormant" : status === "declined" ? "Ditolak" : status === "confirmation" ? "Belum Dikonfirmasi" : "Aktif"
      ];
    });

  let a = 0;
  let content = {
    startY: 50,
    head: headers,
    body: data,
    didDrawCell: async function (dataCell) {
      if(activeTab === "1"){
        if (dataCell.column.index === headers[0].length - 1 && dataCell.cell.section === "body") {
          var diw = dataCell.cell.width - dataCell.cell.padding("horizontal");
          var dih = dataCell.cell.height - dataCell.cell.padding("vertical");
          if (dataCell.row.index < dataTable.length) {
            if (dataTable[dataCell.row.index]) {
              var img = new Image();
              img.src = `${process.env.REACT_APP_SELF_URL}/${
                    dataTable[dataCell.row.index].doc_penunjang.fileKtp[0].path
              }`
              var poll = setInterval(function () {
                if (img.naturalWidth) {
                  clearInterval(poll);
                }
              }, 10);
              try {
                doc.addImage(
                  dataTable[dataCell.row.index]
                    ? img
                    : "",
                  dataCell.cell.x,
                  dataCell.cell.y + 5,
                  dih > diw ? diw : 0 / dih,
                  dih > diw ? 0 / diw : dih
                );
              } catch (e) {
                doc.addImage(
                  "https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg",
                  dataCell.cell.x,
                  dataCell.cell.y + 5,
                  dih > diw ? diw : 0 / dih,
                  dih > diw ? 0 / diw : dih
                );
              }
            }
            a = a + 1;
          }
        }
      }
    },
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(titleDownload);
};
