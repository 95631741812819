import React, { useEffect, useState } from 'react';
import {
    Row, Card, Col
} from "reactstrap";

import './CardHome.css';
import video from '../../../../assets/images/video.png'
import banner from '../../../../assets/images/banner.png'
import berita from '../../../../assets/images/berita.png'

export default function CardHome(props) {
    let value = props.data
    var currentdate = new Date()
    var hours = currentdate.getHours()
    var minutes = currentdate.getMinutes()
    var year = new Date().getFullYear()
    var date = currentdate.getDate();
    var month = currentdate.getMonth() + 1;

    var strMonth
    switch (month) {
        case 1:
            strMonth = "JANUARI"
            break;
        case 2:
            strMonth = "FEBRUARI"
            break;
        case 3:
            strMonth = "MARET"
            break;
        case 4:
            strMonth = "APRIL"
            break;
        case 5:
            strMonth = "MEI"
            break;
        case 6:
            strMonth = "JUNI"
            break;
        case 7:
            strMonth = "JULI"
            break;
        case 8:
            strMonth = "AGUSTUS"
            break;
        case 9:
            strMonth = "SEPTEMBER"
            break;
        case 10:
            strMonth = "OKTOBER"
            break;
        case 11:
            strMonth = "NOVEMBER"
            break;
        case 12:
            strMonth = "DESEMBER"
            break;
        default:
            break;
    }

    var tggl = date + " " + strMonth + " " + year;
    var addZero;
    if (minutes < 9) {
        addZero = 0;
    } else {
        addZero = ""
    }
    var jam = hours + ":" + addZero + minutes

    return (
        <div>
            <Row className="mt-5 mb-5">
                <Col md="4" >
                    <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
                        <div className="p-3">
                            <Row>
                                <div className="ml-2 mr-2">
                                    <img src={video} alt="img-title" />
                                </div>
                                <div className="mt-1">
                                    <div className="title-card" style={{ color: "#8CC6C9" }}>KONTEN VIDEO</div>
                                    <div className="date-card">{tggl}</div>
                                    <div className="clock-card">{jam}</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                                <div className=""></div>
                                <div className="tit-score">
                                    <div className="title-month-card mr-2 ml-2" >Bulan ini</div>
                                    <div className="title-total-card  ml-2">Total</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between  wrap-total">
                                <div className="title-left">Terdaftar</div>
                                <div className="scoreVal">
                                    <div className="value-monthTop">{value.video_TerdaftarMonthly == undefined ? "0" : value.video_TerdaftarMonthly}</div>
                                    <div className="value-total">{value.videos_TerdaftarTotal == undefined ? "0" : value.videos_TerdaftarTotal}</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between mt-2  wrap-total">
                                <div className="title-left">Lolos Kurasi</div>
                                <div className="d-flex">
                                    <div className="value-month mr-3 ml-2" >{value.video_disetujuiMonthly == undefined ? "0" : value.video_disetujuiMonthly}</div>
                                    <div className="value-total  ml-2">{value.videos_disetujuiTotal == undefined ? "0" : value.videos_disetujuiTotal}</div>
                                </div>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col md="4" >

                    <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
                        <div className="p-3">
                            <Row>
                                <div className="ml-2 mr-2">
                                    <img src={banner} alt="img-title" />
                                </div>
                                <div className="mt-1">
                                    <div className="title-card" style={{ color: "#E2B674" }}>KONTEN BANNER</div>
                                    <div className="date-card">{tggl}</div>
                                    <div className="clock-card">{jam}</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                                <div className=""></div>
                                <div className="tit-score">
                                    <div className="title-month-card mr-2 ml-2" >Bulan ini</div>
                                    <div className="title-total-card  ml-2">Total</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between  wrap-total">
                                <div className="title-left">Terdaftar</div>
                                <div className="scoreVal">
                                    <div className="value-monthTop" >{value.banner_TerdaftarMonthly == undefined ? "0" : value.banner_TerdaftarMonthly}</div>
                                    <div className="value-total">{value.banner_TerdaftarTotal == undefined ? "0" : value.banner_TerdaftarTotal}</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between mt-2  wrap-total">
                                <div className="title-left">Lolos Kurasi</div>
                                <div className="d-flex">
                                    <div className="value-month mr-3 ml-2" >{value.banner_disetujuiMonthly == undefined ? "0" : value.banner_disetujuiMonthly}</div>
                                    <div className="value-total  ml-2">{value.banners_disetujuiTotal == undefined ? "0" : value.banners_disetujuiTotal}</div>
                                </div>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col md="4" >

                    <Card body style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
                        <div className="p-3">
                            <Row>
                                <div className="ml-2 mr-2">
                                    <img src={berita} alt="img-title" />
                                </div>
                                <div className="mt-1">
                                    <div className="title-card" style={{ color: "#C18ECE" }}>KONTEN BERITA</div>
                                    <div className="date-card">{tggl}</div>
                                    <div className="clock-card">{jam}</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between ml-1 mr-1 mt-4 mb-2">
                                <div className=""></div>
                                <div className="tit-score">
                                    <div className="title-month-card mr-2 ml-2" >Bulan ini</div>
                                    <div className="title-total-card  ml-2">Total</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between  wrap-total">
                                <div className="title-left">Terdaftar</div>
                                <div className="scoreVal">
                                    <div className="value-monthTop">{value.news_TerdaftarMonthly == undefined ? "0" : value.news_TerdaftarMonthly}</div>
                                    <div className="value-total">{value.news_TerdaftarTotal == undefined ? "0" : value.news_TerdaftarTotal}</div>
                                </div>
                            </Row>
                            <Row className="justify-content-between mt-2  wrap-total">
                                <div className="title-left">Lolos Kurasi</div>
                                <div className="d-flex">
                                    <div className="value-month mr-3 ml-2" >{value.news_disetujuiMonthly == undefined ? "0" : value.news_disetujuiMonthly}</div>
                                    <div className="value-total  ml-2">{value.news_disetujuiTotal == undefined ? "0" : value.news_disetujuiTotal}</div>
                                </div>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
