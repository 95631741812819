import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const CpProfileAsync = Loadable({
  loader: () =>
    import("../../../pages/Authentication/cp-profile" /* webpackChunkName: "CpProfileAsync" */),
  loading: Loading,
});

export default CpProfileAsync;
