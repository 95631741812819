import React, {useState} from 'react';
import styles from "../../assets/css/login.module.css"
import { Row, Col, Alert, Card, CardBody,Container } from "reactstrap";
import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import profile from "../../assets/icon/Vector.png";
import logo from "../../assets/icon/BAKTI-ICON.png";
import background from "../../assets/images/bg-login.png";
import API from '../../services';

const ForgetPasswordPage = (props) => {
  const [email, setEmail] = useState("")
  const [errorEmail, setErrorEmail] = useState("")
  //alert
  const [alertForgot, setAlertForgot] = useState(false);
  const [alertForgotFail, setAlertForgotFail] = useState(false)
  //notif
  const [notifForgot, setNotifForgot] = useState(false);
  const [notifForgotFail, setNotifForgotFail] = useState(false);
  const history = useHistory();
  
  function handleValidSubmit() {
    // props.userForgetPassword(values, props.history);
    console.log(email)

    if (email === ""){
      setErrorEmail("Email Tidak Boleh Kosong");
    }
    
    if (email !== ""){
      API.postForgotPass({
        email: email,
        origin: "admin"
      }).then((res) => {
        // console.log(res.data.success)
        // console.log(res.data.message)
        if (res.data.success === true){
          setAlertForgot(false);
          setNotifForgot(true);
          setTimeout(() => {
            setNotifForgot(false)
            history.push('/login');
          }, 3000);
        } if ( res.data.success === false){
          setAlertForgotFail(false);
          setNotifForgotFail(true);
          setTimeout(() => {
            setNotifForgotFail(false)
            history.go(0);
          }, 3000);
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
    return (
      // <React.Fragment>
        <div className={styles.bgWrapp}>
          {/* <Container> */}
          <Row className="justify-content-center" style={{paddingTop : "10%", paddingBottom: "22%"}}>
            <Container>
              <Col md={8} lg={6} xl={5} style={{marginLeft: "24%"}}>
                <Alert
                  color={alertForgot ? "success" : "success"}
                  isOpen={notifForgot}
                  style={{
                      width: "100%",
                      borderRadius: "6px",
                      textAlign: "center",
                      padding: "5px",
                      marginLeft: "0%",
                      marginTop: "10%" ,
                  }}>
                  {alertForgot ? `` : `Periksa Email Anda dan Lakukan Verifikasi Di Dalam Email`}
                </Alert>
                <Alert
                  color={alertForgotFail ? "danger" : "danger"}
                  isOpen={notifForgotFail}
                  style={{
                      width: "100%",
                      borderRadius: "6px",
                      textAlign: "center",
                      padding: "5px",
                      marginLeft: "0%",
                      marginTop: "10%" ,
                  }}>
                  {alertForgotFail ? `` : `User Tidak Tersedia Di Sistem`}
                </Alert>
                <Card className="overflow-hidden">
                  <div className="" style={{backgroundColor : "#fff"}}>
                    <Row>
                      <Col className="col-12">
                        <div className="text-black p-4 text-center">
                          <h5 className="text-black">Selamat Datang !</h5>
                          <p>ICMS Bakti Kominfo.</p>
                          <p>Forgot Password</p>
                        </div>
                      </Col>
                      {/* <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col> */}
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        {/* <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logo} alt="" className="rounded-circle" style={{width : "100%" , height: "100%"}} />
                          </span>
                        </div> */}
                      </Link>
                    </div>
                    <div className="p-2">

                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={(e,v) => handleValidSubmit(e,v)}
                      >

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <Row className="form-group">
                          <Col className="text-right">
                            {/* <Link to="reset-password"> */}
                              <button
                                className="btn w-md waves-effect waves-light"
                                type="submit"
                                style={{backgroundColor : "#00438B", color: "#fff"}}
                              >
                              Reset
                              </button>
                            {/* </Link> */}
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-3 mb-6 text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="login"
                      className="font-weight-medium"
                      style={{ color: "#00438B"}}
                    >
                      Login
                      </Link>{" "}
                  </p>
                  {/* <p>© {new Date().getFullYear()} ICMS Bakti Kominfo. Crafted with <i className="mdi mdi-heart text-danger"></i> by kibs</p> */}
                </div>
              </Col>
            </Container>
          </Row>
          {/* </Container> */}
        </div>
      // </React.Fragment>
    );
    }

// const mapStatetoProps = state => {
//   const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
//   return { forgetError, forgetSuccessMsg };
// };

export default withRouter(
  (ForgetPasswordPage)
);
