// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_titleMenuBI__29IZa {\n  font-size: 18px;\n  color: #2c3280;\n  font-weight: bold;\n}\n\n.style_titleCardBI__2o4Ks {\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.style_titleMan__2ZkKU {\n  color: #2c3280;\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.style_titleWoman__3T6Nr {\n  color: #c42127;\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.style_valueMan__3c7bK {\n  color: #2c3280;\n  font-size: 30px;\n  font-weight: bold;\n  margin-top: 10px;\n}\n\n.style_valueWoman__16Inb {\n  color: #c42127;\n  font-size: 30px;\n  font-weight: bold;\n  margin-top: 10px;\n}\n\n.style_titlePendidikan__3HQI6 {\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.style_valuePendidikan__rwljX {\n  font-size: 30px;\n  font-weight: bold;\n}\n\n.style_valueFingerprint__3ddBJ {\n  color: #2c3280;\n  font-size: 30px;\n  font-weight: bold;\n}\n\n.style_valuePersebaranOS__3AlH5 {\n  color: #2c3280;\n  font-size: 20px;\n  font-weight: bold;\n}\n", ""]);
// Exports
exports.locals = {
	"titleMenuBI": "style_titleMenuBI__29IZa",
	"titleCardBI": "style_titleCardBI__2o4Ks",
	"titleMan": "style_titleMan__2ZkKU",
	"titleWoman": "style_titleWoman__3T6Nr",
	"valueMan": "style_valueMan__3c7bK",
	"valueWoman": "style_valueWoman__16Inb",
	"titlePendidikan": "style_titlePendidikan__3HQI6",
	"valuePendidikan": "style_valuePendidikan__rwljX",
	"valueFingerprint": "style_valueFingerprint__3ddBJ",
	"valuePersebaranOS": "style_valuePersebaranOS__3AlH5"
};
module.exports = exports;
