import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../Filter/filter";
import _ from "lodash";
// ------------------API-----------------------
import API from "../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconGreenCheckList from "../../../../assets/icon/IconGreenChecklist.svg";
import IconInfo from "../../../../assets/icon/IconInfo.svg";
import "./style.css";

// ------------------Component----------------
// import DetailKonten from "../../../../components/SuperAdmin/Popup/PersetujuanKonten/DetailKonten";

// ------------------Redux----------------

import { connect } from "react-redux";
import noImage from "../../../../assets/images/noImage.jpg";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

const TableSurvey = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  //notif
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [notif, setNotif] = useState(false);

  const hasilSurveyKlik = (it) => {
    history.push({
      pathname: `/admin/result/survey`,
      state: { ids: it.ids, title: it._id },
    });
  };

  //alert
  const [alert, setAlert] = useState("");

  const fetchAPI = () => {
    setLoading(true);
    API.getSurveyAdmin()
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setLoading(false);
        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(res.data.data);
        } else {
          setRows(result);
          document.getElementById("TableSurveyDefault").rows[1].style.transition = "0.8s";
          document.getElementById("TableSurveyDefault").rows[1].style.backgroundColor = "#e6f3ff";
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    fetchAPI();
  }, [refresh, props.idContent]);
  //throw data to parent for download
  const filterData = (item) => {
    //console.log("yg bkl ke lempar");
    props.handleData(item);
    //console.log("props => ", props?.handleData(item));
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search, props.activeTab]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;

      const index = [
        data ? data._id : "-",
        data ? data.questions.length : "-",
        data.proposed_provinsi.map((x) => x),
        moment(data.proposed_date[data.proposed_date.length - 1], "DD-MM-YYYY").format(
          "DD-MM-YYYY"
        ),
        moment(data.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY"),
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }

  //modal detail
  const [modalDetail, setModalDetail] = useState(false);
  const [dataModalDetail, setDataModalDetail] = useState({});
  const toggleModalDetail = () => {
    setModalDetail(!modalDetail);
  };
  const handleModalActivedContent = (data) => {
    setDataModalDetail({
      data: data,
      title:
        props.activeTab == "3"
          ? typeof data.title !== undefined
            ? data.title
            : "-"
          : typeof data.details.title !== undefined
          ? data.details.title
          : "-",
      param: {
        idContent: data._id,
        idProvince: data.proposed_provinsi._id,
      },
    });
    toggleModalDetail();
  };
  //modal preview video
  const [modalPreviewContent, setModalPreviewContent] = useState(false);
  const [dataModalPreviewContent, setDataModalPreviewContent] = useState({});
  const toggleModalPreviewContent = () => {
    setModalPreviewContent(!modalPreviewContent);
  };
  const handleModalPreviewContent = (data) => {
    setDataModalPreviewContent({
      title:
        props.activeTab == "3"
          ? data.title
            ? data.title
            : "-"
          : data.details.title
          ? data.details.title
          : "-",
      url: data.details.url,
      type: data.type ?? "-",
    });
    toggleModalPreviewContent();
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  //modal detail konten
  const [ModalDetailKonten, setModalDetailKonten] = useState(false);
  const [dataModalDetailKonten, setDataModalDetailKonten] = useState({});
  const toggleModalDetailKonten = () => {
    setModalDetailKonten(!ModalDetailKonten);
  };
  const handleModalDetailKonten = (it) => {
    setDataModalDetailKonten({
      data: it,
    });
    toggleModalDetailKonten();
  };
  //modal lolos
  const [modalLolos, setModalLolos] = useState(false);
  const [dataModalLolos, setDataModalLolos] = useState({});
  const toggleModalLolos = () => {
    setModalLolos(!modalLolos);
  };
  const handleModalLolos = (it) => {
    setDataModalLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      activeTab: props.activeTab,
    });
    toggleModalLolos();
  };
  //modal Tidak Lolos
  const [modalTidakLolos, setModalTidakLolos] = useState(false);
  const [dataModalTidakLolos, setDataModalTidakLolos] = useState({});
  const toggleModalTidakLolos = () => {
    setModalTidakLolos(!modalTidakLolos);
  };
  const handleModalTidakLolos = (it) => {
    setDataModalTidakLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      comment: it.comment,
      activeTab: props.activeTab,
    });
    toggleModalTidakLolos();
  };

  const ButtonAction = ({ it }) => {
    return (
      <img className="btnAction" src={IconInfo} onClick={() => handleModalDetailKonten(it)}></img>
    );
  };
  let wordLast = document.querySelectorAll("#TableSurveyDefault").children;
  const PreviewComponent = ({ it }) => {
    return (
      <center style={{ width: "fit-content" }}>
        {!it.tanggal_pembuatan.includes("2019") ? (
          <img
            alt={props.activeTab == "3" ? it.title : it.details.title}
            className="wrapperImgYT"
            onClick={() => handleModalPreviewContent(it)}
            src={
              it.details
                ? it.details.thumbnail
                  ? `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.thumbnail : ""}`
                  : `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.url : ""}`
                : "-"
            }></img>
        ) : (
          <img
            alt={props.activeTab == "3" ? it.title : it.details.title}
            className="wrapperImgYT"
            src={noImage}></img>
        )}
      </center>
    );
  };

  // let widthOfDate = document.qu("")
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Nama Survei",
        field: "survei",
        sort: "asc",
        width: 500,
      },
      {
        label: "Jumlah Pertanyaan",
        field: "jmlh_pertanyaan",
        sort: "asc",
        width: 150,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Awal",
        field: "tgl_awal",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Akhir",
        field: "tgl_akhir",
        sort: "asc",
        width: 150,
      },
      {
        label: "",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              survei: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, i) => ({
            no: i + 1,
            survei: it ? it._id : "-",
            jmlh_pertanyaan: it ? it.questions.length : "-",
            provinsi:
              it.proposed_provinsi.length > 2
                ? `${it ? (it.proposed_provinsi[0] ? it.proposed_provinsi[0] : "-") : "-"}, ${
                    it ? (it.proposed_provinsi[1] ? it.proposed_provinsi[1] : "-") : "-"
                  }, ${it ? (it.proposed_provinsi[2] ? it.proposed_provinsi[2] : "-") : "-"}...`
                : it.proposed_provinsi.length > 1
                ? `${it.proposed_provinsi[0] ? it.proposed_provinsi[0] : "-"},${
                    it.proposed_provinsi[1] ? it.proposed_provinsi[1] : "-"
                  }`
                : `${it.proposed_provinsi[0] ? it.proposed_provinsi[0] : "-"}`,
            tgl_awal: it
              ? it.proposed_date
                ? `${moment(it.proposed_date[0][it.proposed_date.length - 1], "DD-MM-YYYY").format(
                    "YYYYMMDD"
                  )}
                ${_.first(it.proposed_date[0])}`
                : "-"
              : "-",
            tgl_akhir: it
              ? it.proposed_date
                ? `${moment(it.proposed_date[0][it.proposed_date.length - 1], "DD-MM-YYYY").format(
                    "YYYYMMDD"
                  )}
                ${_.last(it.proposed_date[0])}`
                : "-"
              : "-",
            aksi: (
              <>
                <Row>
                  <Col xs="12" xl="6">
                    <Button
                      onClick={() => {
                        history.push({
                          pathname: `/admin/duplikat/survey`,
                          state: it,
                        });
                      }}
                      color=""
                      className="buttonLeftSurvey">
                      Duplikat
                    </Button>
                  </Col>
                  <Col xs="12" xl="6">
                    <Button
                      color=""
                      className="buttonRightSurvey"
                      onClick={() => {
                        hasilSurveyKlik(it ? it : "-");
                      }}>
                      Lihat Hasil
                    </Button>
                  </Col>
                  {/* <Col xs="12" xl="2" style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'2px'}}>
                  <a><img className="btnAction" src={IconInfo} onClick={()=>{detailKlik(it)}}></img></a>
                </Col> */}
                </Row>
              </>
            ),
          })),
  };
  // module.export = {
  //   var : "aaaaaa"
  // }
  return (
    <React.Fragment>
      {alert == "successActivedContent" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil di Aktifkan"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alert == "failedActivedContent" ? (
        <SweetAlert
          timeout={3}
          title={"Konten Tidak Berhasil di Aktifkan"}
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : alert == "failedFetch" ? (
        <SweetAlert
          timeout={3}
          error
          title="User tidak berhasil di kurasi"
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : (
        ""
      )}

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">
                      Tidak ada konten yang dapat di aktifkan
                    </div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="TableSurveyAdmin"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                        onSort={{ column: "survei" }}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TableSurvey);
