import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
  Progress,
} from "reactstrap";
import Navigation from "../../../components/Navigation/Navigation";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Swal from "sweetalert2";
// -------------------Service------------------------
import API from "../../../services";
// -------------------ASSET------------------------
//css
import "./style.css";
import IconUpload from "../../../assets/icon/IconUpload.svg";
import IconUploadToggle from "../../../assets/icon/IconUploadToggle.svg";
import IconDownload from "../../../assets/icon/IconDownload.svg";
import IconDownloadToggle from "../../../assets/icon/IconDownloadToggle.svg";
import IconEmergency from "../../../assets/icon/IconEmergency.svg";
//doc
import FORMAT_EMERGENCY from "./TemplateEmergencyFIX.docx";
import ModalDescEmergency from "../../../components/ContentPartner/Popup/BuatKonten/ModalDescEmergency";
// -------------------COMPONENT--------------------
import ModalCalendar from "../../../components/ContentPartner/Popup/BuatKonten/Calendar";
import ModalJamTayang from "../../../components/ContentPartner/Popup/BuatKonten/JamTayang";
import ModalTambahProvinsi from "../../../components/ContentPartner/Popup/BuatKonten/TambahProvinsi";
import ModalKategoriLokasi from "../../../components/ContentPartner/Popup/BuatKonten/KategoriLokasi";
import Header from "../../../components/VerticalLayout/Header";
import { listDurasi, listKategori } from "./const";
import { FilterProvince, FilterCategory, FilterDuration } from "./dropdownFilter";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

function BuatKontenAdmin(props) {
  //get user device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const history = useHistory();
  //color jamtayang
  const [newColor, setNewColor] = useState("#E5E5E5");

  ///handle change tab
  const [activeTab, setActiveTab] = useState("video");

  // error msg
  const [errorMsg, setErrorMsg] = useState([]);
  console.info('errorMsg', errorMsg);

  //state Form input
  const [fileVideo, setFileVideo] = useState(null);
  const [fileVideoName, setFileVideoName] = useState("");
  const [fileEmergency, setFileEmergency] = useState(null);
  const [fileEmergencyName, setFileEmergencyName] = useState("");
  const [isEmergency, setIsEmergency] = useState(false);
  const [kategori, setKategori] = useState("");
  const [judul, setJudul] = useState("");
  const [link, setLink] = useState("");
  const [provinsi, setProvinsi] = useState({ id: "", value: "" });
  const [durasi, setDurasi] = useState("");
  const [tanggal, setTanggal] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [media, setMedia] = useState("");
  const [linkNews, setLinkNews] = useState("");
  const [sinopsis, setSinopsis] = useState("");
  const [isiBerita, setIsiBerita] = useState("");
  //thumbnail video
  const [thumbnailVideo, setThumbnailVideo] = useState(null);
  const [thumbnailVideoName, setThumbnailVideoName] = useState(null);
  const [errorThumbnailSize, setErrorThumbnailSize] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [jamTayang, setJamTayang] = useState([]);
  const [showProvinsiTambahan, setshowProvinsiTambahan] = useState([]);
  const [ProvinsiTambahan, setProvinsiTambahan] = useState([]);
  const [thumbnailYT, setThumbnailYT] = useState("");
  const [thumbnailLocal, setThumbnailLocal] = useState("");
  //modal category location
  const [catLocations, setCatLocations] = useState([]);

  const [errorFileSize, setErrorFileSize] = useState("");
  //list
  const [listProvinsi, setListProvinsi] = useState([]);
  //notif
  const [successSubmitted, setSuccessSubmitted] = useState("");
  //loading
  const [loading, setIsLoading] = useState(false);
  //modal loading
  const [modalLoading, setModalLoading] = useState(false);
  //ref
  const radioEmergency = useRef();
  const [errorEmergencyDocSize, setErrorEmergencyDocSize] = useState("");
  //btn OK
  const [okVid, setOKVid] = useState(false);
  const [okThumbnailVid, setOKThumbnailVid] = useState(false);
  const [okThumbnailBanner, setOKThumbnailBanner] = useState(false);
  const [okThumbnailNews, setOKThumbnailNews] = useState(false);
  const [attachedThumbnail, setAttachedThumbnail] = useState(null);
  //tooltip
  // const [trightDownload, settrightDownload] = useState(false);
  // const [trightUpload, settrightUpload] = useState(false);
  //progress
  const [percentage, setPercentage] = useState(0);
  const [percentageEmergency, setPercentageEmergency] = useState(0);

  //scrapping news
  const [newsConfirmed, setNewsConfirmed] = useState(false);
  // const [popoverOpen, setPopoverOpen] = useState(false);
  const handleLinkNews = () => {
    let param = {
      url: linkNews,
      userId: window.localStorage.getItem("idUser"),
    };
    API.postCheckNews(param)
      .then((res) => {
        if (res.status == 200) {
          setNewsConfirmed(true);
        } else {
          setNewsConfirmed(true);
        }
      })
      .catch((e) => {
        setNewsConfirmed(true);
      });
  };

  const maxLengthInputTitle = useMemo(() => {
    let defaultLength = 90;

    if (activeTab === 'banner' || activeTab === 'news') defaultLength = 35;
    return defaultLength;
  }, [activeTab])

  const handleErroMsg = (value, type, titleLength = 90) => {
    let maxLength = titleLength;

    // if type was banner or news
    if (type === 'banner' || type === 'news') maxLength = 35;
    
    if (value.length >= maxLength) {
      const newError = { type, message: `* Maksimal judul konten ${type} adalah ${maxLength} karakter` };
      setErrorMsg([newError]);
    } else setErrorMsg([]);
  };

  useEffect(() => {
    API.getAllProvince()
      .then((res) => {
        if (res.data.success) {
          // res.data.data.unshift({
          //   latitude: "0",
          //   longitude: "0",

          //   tanggal_pembuatan: "",
          //   tanggal_perubahan: "",
          //   _id: "0",
          //   name: "Semua Provinsi",
          // });
          setListProvinsi(res.data.data);
        }
      })
      .catch(() => {
        history.push("/pages-500");
      });
  }, []);
  // useEffect(() => (radioEmergency.current.checked = false), [radioEmergency]);
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
    handleClear();
  };

  const tabStyle = {
    color: "#c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };
  //file emergency
  const hiddenFileInput = useRef(null);
  const handleChangeUploadFileEmergency = (e) => {
    setErrorEmergencyDocSize("");
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      if (fileUploaded.size / 1024 / 1024 <= 2) {
        handleProgressEmergency();
        setFileEmergency(fileUploaded);
        setFileEmergencyName(fileUploaded.name);
      } else {
        setErrorEmergencyDocSize("Ukuran file tidak boleh melebihi 2MB");
      }
    }
  };

  const handleClickUploadEmergency = () => {
    hiddenFileInput.current.click();
  };
  //file video
  const hiddenFileInputVideo = useRef(null);
  //file thumbnail vieo
  const hiddenFileThumbnailVideo = useRef(null);
  const handleClickUploadThumbnailVideo = () => {
    hiddenFileThumbnailVideo.current.click();
  };
  const handleChangeUploadThumbnailContent = (e) => {
    setErrorFileSize("");
    const fileUploaded = e
      ? e.target
        ? e.target.files[0]
          ? e.target.files[0]
          : fileVideo
        : fileVideo
      : fileVideo;

    // console.info({ fileSize: fileUploaded.size, size: fileUploaded.size / 1024 / 1024, base: 0.1 })

    if (fileUploaded ? fileUploaded.size / 1024 / 1024 <= 0.1 : true) {
      if (fileUploaded !== null) {
        setThumbnailVideo(fileUploaded ? fileUploaded : "");
        setThumbnailVideoName(fileUploaded ? fileUploaded.name : "");
        setErrorThumbnailSize("");
        let reader = new FileReader();
        reader.onload = (e) => {
          setAttachedThumbnail(e ? (e.target ? (e.target.result ? e.target.result : "") : "") : "");
        };
        reader.readAsDataURL(fileUploaded ? fileUploaded : "");
      } else {
      }
    } else {
      setErrorThumbnailSize("Ukuran file tidak boleh melebihi 100KB");
    }
  };
  //handle Attach thumbnail
  const handleAttachThumbnail = () => {
    switch (activeTab) {
      case "video":
        setOKThumbnailVid(true);
        thumbnailSuccessUploadHandler();
        break;
      case "banner":
        setOKThumbnailBanner(true);
        ContentSuccessUploadHandler();
        setPercentage(100);
        break;
      case "news":
        setOKThumbnailNews(true);
        thumbnailSuccessUploadHandler();
        setPercentage(100);

        break;
      default:
        break;
    }
    document.getElementById("imgThumbnailVideo").src = attachedThumbnail;
  };

  // var currentVID = document.getElementById("currentVID");
  const handleChangeUploadFileContent = async (e) => {
    setErrorFileSize("");
    setErrorThumbnailSize("");
    //clear
    setThumbnailLocal("");
    setThumbnailYT("");
    //video type local
    if (activeTab == "video") {
      //change input if video type youtube
      setOKVid(false);
      setLink("");
      setThumbnailYT("");

      const fileUploaded = e.target.files[0];
      if (fileUploaded) {
        if (fileUploaded.size / 1024 / 1024 <= 100) {
          setFileVideo(fileUploaded);
          setMedia("video");
          setFileVideoName(fileUploaded.name);
          setThumbnailLocal(URL.createObjectURL(new Blob([fileUploaded ? fileUploaded : null])));
        } else {
          setErrorFileSize("Ukuran file tidak boleh melebihi 100MB");
        }
      }
    }
    //if banner uploaded
    if (activeTab == "banner") {
      const fileUploaded = e.target.files[0];
      if (fileUploaded) {
        if (fileUploaded.size / 1024 / 1024 <= 2) {
          setFileVideo(fileUploaded);
          setFileVideoName(fileUploaded.name);
          setErrorFileSize("");
          let reader = new FileReader();
          reader.onload = (e) => {
            setAttachedThumbnail(e.target.result);
          };
          reader.readAsDataURL(fileUploaded);
        } else {
          setErrorFileSize("Ukuran file tidak boleh melebihi 2MB");
        }
      }
    }

    //if news thumbnail uploaded
    if (activeTab == "news") {
      const fileUploaded = e.target.files[0];
      if (fileUploaded) {
        if (fileUploaded.size / 1024 / 1024 <= 2) {
          setFileVideo(fileUploaded);
          setFileVideoName(fileUploaded.name);
          setErrorFileSize("");
          let reader = new FileReader();
          reader.onload = (e) => {
            setAttachedThumbnail(e.target.result);
          };
          reader.readAsDataURL(fileUploaded);
        } else {
          setErrorFileSize("Ukuran file tidak boleh melebihi 2MB");
        }
      }
    }
  };
  const handleClickUploadVideo = () => {
    setFileVideo(null);
    setFileVideoName("");
    setOKVid(false);
    setLink("");
    hiddenFileInputVideo.current.click();
  };
  //calendar modal
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //throw data to check availability
    setStartDate(startDate.format("DD-MM-YYYY"));
    setEndDate(endDate.format("DD-MM-YYYY"));
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };
  //modalJamTayang
  const [modalJamTayang, setModalJamTayang] = useState(false);
  const toggleModalJamTayang = () => {
    setModalJamTayang(!modalJamTayang);
  };
  //modalTambahProvinsi
  const [modalTambahProvinsi, setModalTambahProvinsi] = useState(false);
  const toggleModalTambahProvinsi = () => {
    setModalTambahProvinsi(!modalTambahProvinsi);
  };
  //modalKategoriLokasi
  const [modalCatLocations, setModalCatLocations] = useState(false);
  const toggleModalCatLocations = () => {
    setModalCatLocations(!modalCatLocations);
  };

  //onchange filter provinsi

  const filterData = useCallback(
    (filt, id, type) => {
      switch (type) {
        case "Kategori":
          setKategori(filt);
          break;
        case "Durasi":
          setDurasi(filt);
          break;
        case "Provinsi":
          setProvinsi({
            id: id,
            value: filt,
          });
          break;
      }
    },
    [kategori, durasi, provinsi]
  );
  if (jamTayang.length > durasi + 1) {
    setJamTayang([]);
  }

  const handleJamTayang = (jam) => {
    let timeAdded = _.compact(jam);
    //set jam tayang to 0
    setJamTayang([]);
    if (jamTayang.length !== 0) {
      if (Math.abs(parseInt(jamTayang.slice(-1).pop()) - parseInt(timeAdded)) > 1) {
        if (jamTayang.length == 0) {
          setJamTayang([...jamTayang, ...timeAdded]);
        } else {
          setJamTayang([]);
        }
      } else {
        setJamTayang([]);
      }
    } else {
      setJamTayang([...jamTayang, ...timeAdded]);
    }
  };
  const handleSelectedProvince = (province) => {
    setshowProvinsiTambahan(province.slice(0, province.length));
    setProvinsiTambahan(listProvinsi.filter((it) => province.includes(it.name)));
  };
  //add location category
  const handleSelectedCatLocation = async (categories) => {
    setCatLocations(categories.slice(0, categories.length));
  };
  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  const handleClear = () => {
    setKategori("");
    setJudul("");
    setLink("");
    setFileVideo(null);
    setFileVideoName("");
    setProvinsi("");
    setDurasi("");
    setTanggal("");
    setSelectedDate("");
    setJamTayang("");
    setProvinsiTambahan([]);
    setshowProvinsiTambahan([]);
    setIsEmergency(false);
    setFileEmergencyName("");
    setFileEmergency(null);
    setThumbnailVideo(null);
    setLinkNews("");
    setSinopsis("");
    setErrorFileSize("");
    setErrorThumbnailSize("");
    setSuccessSubmitted("");
    setErrorEmergencyDocSize("");
    setCatLocations([]);
    //btn
    setOKVid(false);
    setOKThumbnailVid(false);
    setOKThumbnailBanner(false);
    setOKThumbnailNews(false);

    setAttachedThumbnail(null);
    setThumbnailVideoName("");
    setNewsConfirmed(false);
    setIsiBerita("");
    setErrorMsg([]);
  };

  const handleSubmitContent = async () => {
    setModalLoading(true);
    let listIDProvince = [...new Set(_.map(ProvinsiTambahan, "_id").concat([provinsi.id]))];

    let jamTayangs = [];
    tanggal.forEach(() => {
      jamTayangs.push(jamTayang);
    });
    let param = new FormData();
    param.append("username", window.localStorage.getItem("users"));
    param.append("category", kategori);
    param.append("user", window.localStorage.getItem("idUser"));
    param.append("content", activeTab);
    param.append("date", JSON.stringify(tanggal));
    param.append("title", judul);
    param.append("duration", durasi);
    param.append("province", JSON.stringify(listIDProvince));
    param.append("media", media);
    param.append("time", JSON.stringify(jamTayangs));
    param.append("file", fileVideo);
    param.append("location", JSON.stringify(catLocations));

    param.append("bodyBerita", isiBerita);

    if (activeTab == "news") {
      param.append("link", linkNews);
      param.append("sinopsis", sinopsis);
    } else if (activeTab == "video") {
      param.append("link", link);
      param.append("thumbnail", thumbnailVideo);
    } else {
    }

    if (isEmergency) {
      param.append("doc", fileEmergency);
    }

    if (!isEmergency) {
      API.postCreateContentCP(param)
        .then((res) => {
          if (res.data.success) {
            new Promise(
              () => {
                setSuccessSubmitted("success");
              },
              setTimeout(() => {
                history.push("/admin/konten");
              }, 3000)
            );
          } else {
            new Promise(
              () => {
                setSuccessSubmitted("fail");
              },
              setTimeout(() => {
                history.push("/admin/konten");
              }, 3000)
            );
          }
        })
        .catch((e) => {
          new Promise(
            () => {
              setSuccessSubmitted("fail");
            },
            setTimeout(() => {
              history.push("/admin/konten");
            }, 3000)
          );
        });
    } else {
      API.postCreateContentEmergencyCP(param)
        .then((res) => {
          setIsLoading(true);
          if (res.data.success) {
            new Promise(
              () => {
                setSuccessSubmitted("success");
              },
              setTimeout(() => {
                history.push("/admin/konten");
              }, 3000)
            );
          } else {
            new Promise(
              () => {
                setSuccessSubmitted("fail");
              },
              setTimeout(() => {
                history.push("/admin/konten");
              }, 3000)
            );
          }
        })
        .catch((e) => {
          new Promise(
            () => {
              setSuccessSubmitted("fail");
            },
            setTimeout(() => {
              history.push("/admin/konten");
            }, 3000)
          );
        });
    }
  };
  const handleThumbnail = (e) => {
    if (e.target.value == "") {
      setThumbnailYT("");
    }
    if (activeTab == "video") {
      let patternMustYTURL =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (e.target.value.match(patternMustYTURL)) {
        let newval;
        if (e.target.value.match(/(\?|&)v=([^&#]+)/)) {
          newval = e.target.value.match(/(\?|&)v=([^&#]+)/).pop();
        } else if ((newval = e.target.value.match(/(\.be\/)+([^\/]+)/))) {
          newval = e.target.value.match(/(\?|&)v=([^&#]+)/).pop();
        } else if ((newval = e.target.value.match(/(\embed\/)+([^\/]+)/))) {
          newval = e.target.value.pop().replace("?rel=0", "");
        }
        setThumbnailYT(newval);
      }
    } else {
      setThumbnailYT("");
    }
  };

  const handleLink = (e) => {
    if (e.target.value == "") {
      setThumbnailYT("");
    }
    if (activeTab == "video") {
      let patternMustYTURL =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (e.target.value.match(patternMustYTURL)) {
        let newval;
        if (e.target.value.match(/(\?|&)v=([^&#]+)/)) {
          newval = e.target.value.match(/(\?|&)v=([^&#]+)/).pop();
        } else if ((newval = e.target.value.match(/(\.be\/)+([^\/]+)/))) {
          newval = e.target.value.match(/(\?|&)v=([^&#]+)/).pop();
        } else if ((newval = e.target.value.match(/(\embed\/)+([^\/]+)/))) {
          newval = e.target.value.pop().replace("?rel=0", "");
        }
        return newval;
      }
    }
  };
  //type youtube
  const handleChangeLink = (e) => {
    if (activeTab == "video") {
      setOKVid(false);
      setThumbnailLocal("");
      setFileVideo(null);
      //^when change to local
      handleThumbnail(e);
      setMedia("youtube");
    }
    setLink(handleLink(e));
  };

  //modal desc
  const [modalDesc, setModalDesc] = useState(false);

  const toggleModalDesc = () => {
    setModalDesc(!modalDesc);
  };
  function ContentSuccessUploadHandler() {
    Swal.fire({
      position: "center",
      icon: "success",
      title: `Konten ${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Berhasil Diupload`,
      showConfirmButton: false,
      timer: 2500,
    });
  }
  function thumbnailSuccessUploadHandler() {
    Swal.fire({
      position: "center",
      icon: "success",
      title: `Thumbnail ${
        activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
      } Berhasil Diupload`,
      showConfirmButton: false,
      timer: 2500,
    });
  }
  function EmergencySuccessUploadHandler() {
    Swal.fire({
      position: "center",
      icon: "success",
      title: `Dokumen Emergency ${
        activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
      } Berhasil Diupload`,
      showConfirmButton: false,
      timer: 2500,
    });
  }
  //progressbar video
  const handleProgressVideo = () => {
    setOKVid(true);
    //file video
    let formData = new FormData();
    formData.append("fileVid", fileVideo);
    let ajax = new XMLHttpRequest();
    ajax.upload.addEventListener("progress", progressHandler, false);
    ajax.addEventListener("load", ContentSuccessUploadHandler("fileVideo"), false);
    ajax.open("POST", formData);
    ajax.send(formData);
    function progressHandler(e) {
      let percent = (e.loaded / e.total) * 100;
      setPercentage(Math.round(percent)); // percentage of progress BAR
    }
  };
  //progressbar emergency
  const handleProgressEmergency = () => {
    //file video
    let formData = new FormData();
    formData.append("fileEmergency", fileEmergency);
    let ajax = new XMLHttpRequest();
    ajax.upload.addEventListener("progress", progressHandler, false);
    ajax.addEventListener("load", EmergencySuccessUploadHandler, false);
    ajax.open("POST", formData);
    ajax.send(formData);

    function progressHandler(e) {
      let percent = (e.loaded / e.total) * 100;
      setPercentageEmergency(Math.round(percent)); // percentage of progress BAR
    }
  };
  const disableButton = () => {
    switch (activeTab) {
      case "video":
        return okThumbnailVid == false || media == "video"
          ? percentage !== 100
          : false ||
              kategori == "" ||
              judul == "" ||
              provinsi == "" ||
              durasi == "" ||
              tanggal == "" ||
              jamTayang == "" ||
              loading == true ||
              catLocations.length == 0;
        break;
      case "banner":
        return (
          okThumbnailBanner == false ||
          kategori == "" ||
          judul == "" ||
          provinsi == "" ||
          durasi == "" ||
          tanggal == "" ||
          jamTayang == "" ||
          loading == true ||
          catLocations.length == 0
        );
        break;
      case "news":
        return (
          okThumbnailNews == false ||
          kategori == "" ||
          judul == "" ||
          provinsi == "" ||
          durasi == "" ||
          tanggal == "" ||
          jamTayang == "" ||
          loading == true ||
          newsConfirmed == false ||
          catLocations.length == 0
        );
        break;

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <ModalDescEmergency modalDesc={modalDesc} toggleModalDesc={toggleModalDesc} />
      {modalLoading ? (
        successSubmitted == "success" ? (
          <SweetAlert
            title="Konten Berhasil Dibuat"
            success
            showConfirm={false}
            onConfirm={() => {}}>
            {judul}
          </SweetAlert>
        ) : successSubmitted == "fail" ? (
          <SweetAlert
            title="Konten Tidak Berhasil Dibuat"
            error
            showConfirm={false}
            onConfirm={() => {}}>
            {judul}
          </SweetAlert>
        ) : (
          <SweetAlert
            showConfirm={false}
            onConfirm={""}
            title="Mohon Tunggu"
            style={{
              padding: "3em",
              borderRadius: "20px",
            }}>
            <Spinner
              color="danger"
              style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
              type="grow"
            />
            <div>Konten {judul} sedang dibuat</div>
          </SweetAlert>
        )
      ) : null}

      <div className="page-content">
        <header>
          <Header title="Konten" btn={true} btntype="buatkonten" btntext="Konten" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <MDBContainer > */}
            <div className="classic-tabs">
              <Row style={{ overflowY: "auto" }}>
                <Col className="col-12">
                  <Row>
                    <Col className="col-lg-6 mx-auto" id="formKonten">
                      <Card className="mx-auto" style={{ boxShadow: "0px 0px 10px #D3D3D3" }}>
                        <div className="classic-tabs">
                          <Row style={{ padding: "10px 0" }}>
                            <Col lg="6" className="mx-auto my-auto">
                              <Navigation
                                tabStyle={tabStyle}
                                activeTab={activeTab}
                                handleChangeTab={handleChangeTab}
                                customStyle={customStyle}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="col-lg-6 mx-auto">
                      <Alert
                        className="wrapperAlert"
                        color={notif ? "success" : "danger"}
                        isOpen={isSuccessSubmitted}>
                        {message}
                      </Alert>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col className={isMobile ? "col-12 d-block" : "col-lg-3"}>
                      <Row className="ml-2">Checklist di sini untuk emergency content</Row>
                      <Row>
                        <Col className="wrapperEmergency">
                          <input
                            className="mr-2"
                            checked={isEmergency}
                            ref={radioEmergency}
                            onChange={(e) => setIsEmergency(!isEmergency)}
                            value={isEmergency}
                            type="checkbox"
                            id="cb1"
                          />
                          <h5 className="textEmergency">EMERGENCY CONTENT</h5>
                          <img className="IconEmergency" src={IconEmergency}></img>
                        </Col>
                      </Row>
                      <Row className="wrapperDoc">
                        <h6 onClick={toggleModalDesc} className={"textUploadToggle pointer"}>
                          <u>Apa itu Emergency Content?</u>
                        </h6>
                        {isEmergency ? (
                          <div className="mt-3 d-block w-30 textUploadToggle  mb-1">
                            untuk mengunduh surat keterangan,klik tombol <br></br> di bawah ini
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="d-block w-40 textUploadToggle">
                          <Link
                            className={
                              isMobile
                                ? isEmergency
                                  ? "w-100 mb-3"
                                  : "linkDisabled w-100 mb-3"
                                : isEmergency
                                ? ""
                                : "linkDisabled mb-3"
                            }
                            to={`${FORMAT_EMERGENCY}`}
                            target="_blank"
                            download="Format Surat Emergency.docx">
                            <Button
                              color=""
                              disabled={!isEmergency}
                              className={
                                isMobile
                                  ? isEmergency
                                    ? "btnUploadToggle  text-center  btn btn-rounded w-80 "
                                    : "btnUpload  text-center  btn btn-rounded w-80"
                                  : isEmergency
                                  ? "btnUploadToggle  btn btn-rounded w-80"
                                  : "btnUpload  btn btn-rounded w-80"
                              }>
                              <img
                                className="IconUpload"
                                src={isEmergency ? IconDownloadToggle : IconDownload}></img>
                              {`${"   "}`}Unduh Format Emergency
                            </Button>
                          </Link>
                          {isEmergency ? (
                            <div className="d-block w-40 mb-1">
                              Jika sudah mengisi surat keterangan, unggah disini
                            </div>
                          ) : (
                            <></>
                          )}

                          <Button
                            onClick={handleClickUploadEmergency}
                            disabled={!isEmergency}
                            color=""
                            className={
                              isMobile
                                ? isEmergency
                                  ? "btnDownloadToggle text-center btn btn-rounded w-80"
                                  : "btnDownload text-center btn btn-rounded  linkDisabled w-80"
                                : isEmergency
                                ? "btnDownloadToggle btn btn-rounded w-80"
                                : "btnDownload  btn btn-rounded linkDisabled w-80"
                            }>
                            <img
                              className="IconUpload"
                              src={isEmergency ? IconUploadToggle : IconUpload}></img>
                            {`${"    "}`}Unggah Surat Emergency
                          </Button>

                          <div
                            className={
                              isMobile
                                ? isEmergency
                                  ? "d-block mt-2 textUploadToggle"
                                  : "d-block mt-2 textUpload"
                                : isEmergency
                                ? "d-block ml-2 my-2 textUploadToggle"
                                : "d-block ml-2 my-2 textUpload"
                            }>
                            {errorEmergencyDocSize == "" ? (
                              <p> PDF Maks. 2MB</p>
                            ) : (
                              <p className="formTextDocEmergencyError">{errorEmergencyDocSize}</p>
                            )}
                            <Row className="font-weight-bold">
                              {fileEmergencyName !== "" ? fileEmergencyName : "No file choosen"}
                            </Row>
                            <Row>
                              {fileEmergencyName !== "" &&
                                `Upload Progress ${percentageEmergency}%`}
                            </Row>
                          </div>

                          {/* <FormText
                            className="d-block formTextPDF"
                            color={isEmergency ? "#c42127" : "#A6A6A6"}>
                            PDF maksimal 2 MB
                          </FormText> */}

                          <input
                            key={Date.now()}
                            type="file"
                            accept=".pdf"
                            ref={hiddenFileInput}
                            onChange={handleChangeUploadFileEmergency}
                            style={{ display: "none" }}
                          />
                        </div>
                      </Row>
                    </Col>
                    <Col className="col-lg-6">
                      <Row className="w-85 mx-auto">
                        <Form className="w-100">
                          <FormGroup>
                            <Label for="kategori">Kategori</Label>
                            <FilterCategory
                              type={"Kategori"}
                              filter={kategori}
                              clicked={filterData}
                              list={listKategori}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="judul">
                              Judul Konten
                              {Boolean(judul.length) && (` (${judul.length} char - Maksimal judul konten banner adalah ${maxLengthInputTitle} karakter)`)}
                            </Label>
                            <Input
                              type="text"
                              name="text"
                              id="judul"
                              maxlength={maxLengthInputTitle}
                              value={judul}
                              onChange={(e) => {
                                setJudul(e.target.value)
                                handleErroMsg(e.target.value, activeTab);
                              }}
                              className="inputWrapper"
                            />
                            {/* {errorMsg.map((err) => (<span style={{color: 'red'}} key={err.type}> {err.message} </span>))} */}
                          </FormGroup>
                          {/* FIELD UPLOADD ASSET */}
                          {activeTab == "news" ? (
                            <>
                              <FormGroup>
                                <Label for="linkNews">Link Berita</Label>
                                <div className="w-100">
                                  <Row className="d-flex">
                                    <div className="col-lg-10" id="BtnNews">
                                      <Input
                                        type="text"
                                        name="text"
                                        id="linkNews"
                                        value={linkNews}
                                        onChange={(e) => {
                                          setLinkNews(e.target.value);
                                          setNewsConfirmed(false);
                                        }}
                                        className="inputWrapper"
                                      />
                                    </div>
                                    <div className="col-lg-2 nopadding">
                                      <Button
                                        onClick={handleLinkNews}
                                        disabled={linkNews == ""}
                                        className="btnAsk btn btn-rounded btnOK">
                                        OK
                                      </Button>
                                    </div>
                                  </Row>
                                </div>
                                {newsConfirmed ? (
                                  <FormText className="formTextSuccess" color="#55BA59">
                                    Link Berita berhasil diunggah
                                  </FormText>
                                ) : (
                                  <></>
                                )}
                              </FormGroup>
                              {/* thumbnail berita */}
                              <FormGroup>
                                <Label for="ThumbnailNews">Thumbnail Berita</Label>

                                <div
                                  className="w-100"
                                  style={{ position: "relative", top: "-20px" }}>
                                  <Button
                                    onClick={handleClickUploadVideo}
                                    color=""
                                    className={
                                      isMobile
                                        ? "btnAsk  btnUploadVideoThumbnail btn btn-rounded"
                                        : "btnAsk  btnUploadVideoThumbnail btn btn-rounded"
                                    }>
                                    <img className="IconUpload" src={IconUpload}></img>
                                    {`${"    "}`}Upload
                                  </Button>
                                  <div className="w-100">
                                    <Row className="d-flex">
                                      <div className="col-lg-10">
                                        <Input
                                          type="text"
                                          value={link}
                                          disabled={true}
                                          placeholder={fileVideo !== null ? `${fileVideoName}` : ""}
                                          id="upload"
                                          className="inputWrapper inputPosition"
                                        />
                                        <input
                                          type="file"
                                          id="newlocalFILE"
                                          accept={activeTab == "news" ? ".jpg,.png" : ""}
                                          ref={hiddenFileInputVideo}
                                          onChange={handleChangeUploadFileContent}
                                          style={{ display: "none" }}
                                        />
                                      </div>
                                      <div className="col-lg-2 nopadding">
                                        <Button
                                          onClick={handleAttachThumbnail}
                                          disabled={fileVideo == null}
                                          className="btnAsk btn btn-rounded btnOK">
                                          OK
                                        </Button>
                                      </div>
                                    </Row>
                                  </div>

                                  {errorFileSize !== "" ? (
                                    <FormText className="formTextError" color="#c42127">
                                      {errorFileSize}
                                    </FormText>
                                  ) : (
                                    <FormText className="formText" color="muted">
                                      {activeTab == "video"
                                        ? "Mp4. maksimal 100 MB"
                                        : activeTab == "banner"
                                        ? "Unggah file dengan ekstensi .png atau .jpg (Maksimal ukuran file 2MB)"
                                        : activeTab == "news"
                                        ? "Unggah file dengan ekstensi .png atau .jpg (Maksimal ukuran file 2MB)"
                                        : ""}
                                    </FormText>
                                  )}

                                  <center className="mt-2">
                                    <div>
                                      <center className="mt-2">
                                        {activeTab == "news" ? (
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "245px",
                                              objectFit: "contain",
                                              display: okThumbnailNews ? "block" : "none",
                                            }}
                                            id="imgThumbnailVideo"></img>
                                        ) : (
                                          ""
                                        )}
                                      </center>
                                    </div>
                                  </center>
                                </div>
                              </FormGroup>
                              <FormGroup>
                                <Label for="sinopsis">Sinopsis Berita (Maks 500 Karakter)</Label>
                                <Input
                                  maxLength="500"
                                  type="textarea"
                                  name="text"
                                  id="sinopsis"
                                  value={sinopsis}
                                  onChange={(e) => setSinopsis(e.target.value)}
                                  className="inputWrapper textAreaSinopsis"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="isiBerita">Isi Berita</Label>
                                <Input
                                  type="textarea"
                                  name="isiBerita"
                                  id="isiBerita"
                                  value={isiBerita}
                                  onChange={(e) => setIsiBerita(e.target.value)}
                                  className="inputWrapper textAreaSinopsis"></Input>
                              </FormGroup>
                            </>
                          ) : activeTab == "video" ? (
                            <>
                              <FormGroup>
                                <Label for="ThumbnailVideo">Video</Label>

                                <div
                                  className="w-100"
                                  style={{ position: "relative", top: "-31px" }}>
                                  <Row>
                                    <Button
                                      onClick={handleClickUploadVideo}
                                      // disabled={link !== "" || activeTab == "news"}
                                      color=""
                                      className={
                                        isMobile
                                          ? "btnAsk btnUploadVideo btn btn-rounded"
                                          : "btnAsk btnUploadVideo btn btn-rounded"
                                      }>
                                      <img className="IconUpload" src={IconUpload}></img>
                                      {`${"    "}`}Upload
                                    </Button>
                                  </Row>
                                  <Row className="d-flex">
                                    {/* hereeeee */}
                                    <div className="col-lg-10 ">
                                      <Input
                                        type="text"
                                        onChange={handleChangeLink}
                                        value={link}
                                        // disabled={fileVideo !== null}
                                        placeholder={
                                          fileVideo !== null
                                            ? `${fileVideoName}`
                                            : activeTab == "video"
                                            ? "atau paste link video youtube disini"
                                            : activeTab == "banner"
                                            ? link
                                            : activeTab == "news"
                                            ? link
                                            : ""
                                        }
                                        id="upload"
                                        className="inputWrapper inputPosition"
                                      />

                                      <input
                                        type="file"
                                        id="newlocalFILE"
                                        accept={
                                          activeTab == "video"
                                            ? ".mp4"
                                            : activeTab == "banner"
                                            ? ".jpg,.png"
                                            : ""
                                        }
                                        ref={hiddenFileInputVideo}
                                        onChange={handleChangeUploadFileContent}
                                        style={{ display: "none" }}
                                      />
                                    </div>

                                    <div className="col-lg-2 nopadding pl-3">
                                      <Button
                                        disabled={fileVideo === null && link === ""}
                                        onClick={handleProgressVideo}
                                        className="btnAsk btn btn-rounded btnOK">
                                        OK
                                      </Button>
                                    </div>
                                  </Row>
                                  {/* percentage of video when upload */}
                                  {fileVideo !== null && errorFileSize == "" && okVid ? (
                                    <Row className="col-lg-10 mt-2" style={{ marginLeft: "-5px" }}>
                                      <Progress
                                        id="progressBarVid"
                                        className="w-100"
                                        striped
                                        color="primary"
                                        max="100"
                                        value={percentage}>
                                        {`${percentage} %`}
                                      </Progress>
                                    </Row>
                                  ) : (
                                    ""
                                  )}

                                  {errorFileSize !== "" ? (
                                    <FormText className="formTextError" color="#c42127">
                                      {errorFileSize}
                                    </FormText>
                                  ) : (
                                    <FormText className="formTextRequire" color="#c42127">
                                      Mp4. maksimal 100 MB
                                    </FormText>
                                  )}

                                  <div>
                                    <center className="mt-2">
                                      {activeTab == "video" ? (
                                        okVid && thumbnailLocal !== "" && thumbnailYT == "" ? (
                                          <div style={{ width: "100%", height: "245px" }}>
                                            <video
                                              id="videoKontenCP"
                                              width="inherit"
                                              height="100%"
                                              muted
                                              controls>
                                              <source
                                                id="currentVID"
                                                src={thumbnailLocal ? thumbnailLocal : ""}
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                        ) : okVid && thumbnailLocal == "" && thumbnailYT !== "" ? (
                                          <iframe
                                            className="video"
                                            width="100%"
                                            height="245"
                                            allowfullscreen="allowfullscreen"
                                            mozallowfullscreen="mozallowfullscreen"
                                            msallowfullscreen="msallowfullscreen"
                                            oallowfullscreen="oallowfullscreen"
                                            webkitallowfullscreen="webkitallowfullscreen"
                                            src={`https://www.youtube.com/embed/${thumbnailYT}`}></iframe>
                                        ) : (
                                          <></>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </center>
                                  </div>
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <Label for="ThumbnailVideo">Thumbnail Video</Label>
                                <div
                                  className="w-100"
                                  style={{ position: "relative", top: "-20px" }}>
                                  <Button
                                    onClick={handleClickUploadThumbnailVideo}
                                    color=""
                                    className={
                                      isMobile
                                        ? "btnAsk btnUploadVideoThumbnail btn btn-rounded"
                                        : "btnAsk btnUploadVideoThumbnail btn btn-rounded"
                                    }>
                                    <img className="IconUpload" src={IconUpload}></img>
                                    {`${"    "}`}Upload
                                  </Button>
                                  <Row className="d-flex">
                                    <div className="col-lg-10">
                                      <Input
                                        disabled={true}
                                        value={thumbnailVideoName}
                                        placeholder={
                                          thumbnailVideo !== null ? `${thumbnailVideoName}` : ""
                                        }
                                        id="upload"
                                        className="inputWrapper inputPosition"
                                      />
                                      <input
                                        type="file"
                                        id="newlocalFILE"
                                        accept={".jpg,.png"}
                                        ref={hiddenFileThumbnailVideo}
                                        onChange={handleChangeUploadThumbnailContent}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                    <div className="col-lg-2 nopadding  pl-3">
                                      <Button
                                        onClick={handleAttachThumbnail}
                                        disabled={thumbnailVideo == null}
                                        className="btnAsk btn btn-rounded btnOK">
                                        OK
                                      </Button>
                                    </div>
                                  </Row>

                                  {errorThumbnailSize !== "" ? (
                                    <FormText className="formTextError" color="#c42127">
                                      {errorThumbnailSize}
                                    </FormText>
                                  ) : (
                                    <FormText className="formTextRequire" color="#c42127">
                                      JPEG/PNG. maksimal 100 KB
                                    </FormText>
                                  )}
                                  <div>
                                    <center className="mt-2">
                                      {activeTab == "video" ? (
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "245px",
                                            objectFit: "contain",
                                            display: okThumbnailVid ? "block" : "none",
                                          }}
                                          id="imgThumbnailVideo"></img>
                                      ) : (
                                        ""
                                      )}
                                    </center>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          ) : (
                            <>
                              <FormGroup>
                                <Label for="ThumbnailVideo">Banner</Label>

                                <div
                                  className="w-100"
                                  style={{ position: "relative", top: "-20px" }}>
                                  <Button
                                    onClick={handleClickUploadVideo}
                                    color=""
                                    className={
                                      isMobile
                                        ? "btnAsk btnUploadVideoThumbnail btn btn-rounded"
                                        : "btnAsk btnUploadVideoThumbnail btn btn-rounded"
                                    }>
                                    <img className="IconUpload" src={IconUpload}></img>
                                    {`${"    "}`}Upload
                                  </Button>
                                  <Row className="d-flex">
                                    <div className="col-lg-10">
                                      <Input
                                        type="text"
                                        onChange={handleChangeLink}
                                        value={link}
                                        disabled={fileVideo !== null}
                                        placeholder={
                                          fileVideo !== null
                                            ? `${fileVideoName}`
                                            : activeTab == "video"
                                            ? "atau paste link video youtube disini"
                                            : activeTab == "banner"
                                            ? link
                                            : activeTab == "news"
                                            ? link
                                            : ""
                                        }
                                        id="upload"
                                        className="inputWrapper inputPosition"
                                      />
                                      <input
                                        type="file"
                                        id="newlocalFILE"
                                        accept={
                                          activeTab == "video"
                                            ? ".mp4"
                                            : activeTab == "banner"
                                            ? ".jpg,.png"
                                            : ""
                                        }
                                        ref={hiddenFileInputVideo}
                                        onChange={handleChangeUploadFileContent}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                    <div className="col-lg-2 nopadding">
                                      <Button
                                        onClick={handleAttachThumbnail}
                                        disabled={fileVideo == null}
                                        className="btnAsk btn btn-rounded btnOK">
                                        OK
                                      </Button>
                                    </div>
                                  </Row>

                                  {errorFileSize !== "" ? (
                                    <FormText className="formTextError" color="#c42127">
                                      {errorFileSize}
                                    </FormText>
                                  ) : (
                                    <FormText className="formTextRequire" color="#c42127">
                                      JPEG/PNG. maksimal 2 MB
                                    </FormText>
                                  )}
                                  <div>
                                    <center className="mt-2">
                                      {activeTab == "banner" ? (
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "245px",
                                            objectFit: "contain",
                                            display: okThumbnailBanner ? "block" : "none",
                                          }}
                                          id="imgThumbnailVideo"></img>
                                      ) : (
                                        ""
                                      )}
                                    </center>
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          )}

                          <FormGroup>
                            <Label for="provinsi">Provinsi</Label>
                            <FilterProvince
                              type={"Provinsi"}
                              filter={provinsi}
                              clicked={filterData}
                              list={listProvinsi}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="durasi">Durasi</Label>
                            <FilterDuration
                              type={"Durasi"}
                              filter={durasi}
                              clicked={filterData}
                              list={listDurasi}
                            />
                          </FormGroup>
                          {/* kategori lokasi */}
                          <FormGroup>
                            <Label for="tambahan">Pilih Kategori Lokasi Penayangan</Label>
                            <Button
                              color=""
                              disabled={provinsi == "" || durasi == ""}
                              onClick={toggleModalCatLocations}
                              id="tanggal"
                              className="inputWrapper btnTambahProv">
                              <p className="selectedDate">
                                {catLocations
                                  ? catLocations.id !== ""
                                    ? catLocations.sort().join(",")
                                    : ""
                                  : ""}
                              </p>
                            </Button>
                            <ModalKategoriLokasi
                              activeTab={activeTab}
                              modalCatLocations={modalCatLocations}
                              toggleModalCatLocations={toggleModalCatLocations}
                              listSelected={catLocations.length !== 0 ? catLocations : []}
                              selectedCatLocations={handleSelectedCatLocation}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="tanggal">Tanggal</Label>
                            <Button
                              color=""
                              onClick={toggleModalCalendar}
                              id="tanggal"
                              className="inputWrapper btnTanggal">
                              <p className="selectedDate">{selectedDate}</p>
                            </Button>
                            <ModalCalendar
                              modalCalendar={modalCalendar}
                              toggleModalCalendar={toggleModalCalendar}
                              selectedDate={handleSelectedDate}
                              isEmergency={isEmergency}
                            />
                          </FormGroup>
                          {/* {console.log(`${jamTayang.sort()[0]} - ${(parseInt(jamTayang.sort().slice(-1).pop()) + parseInt(`01.00`)).toFixed(2) }`)} */}
                          <FormGroup>
                            <Label for="jam_tayang">Jam Tayang</Label>
                            <Button
                              color=""
                              disabled={provinsi == "" || durasi == ""}
                              onClick={toggleModalJamTayang}
                              id="jam_tayang"
                              className="inputWrapper btnTanggal">
                              <p className="selectedDate">
                                {jamTayang.length > 0
                                  ? `${jamTayang.sort()[0]} - ${
                                      parseInt(jamTayang.sort().slice(-1).pop()) +
                                        parseInt(`01.00`) >
                                      9
                                        ? `${(
                                            parseInt(jamTayang.sort().slice(-1).pop()) +
                                            parseInt(`01.00`)
                                          ).toFixed(2)}`
                                        : `0` +
                                          `${(
                                            parseInt(jamTayang.sort().slice(-1).pop()) +
                                            parseInt(`01.00`)
                                          ).toFixed(2)}`
                                    }`
                                  : ""}
                              </p>
                            </Button>
                            <ModalJamTayang
                              location={catLocations}
                              modalJamTayang={modalJamTayang}
                              toggleModalJamTayang={toggleModalJamTayang}
                              selectedDate={selectedDate}
                              durasi={durasi}
                              startDate={startDate}
                              endDate={endDate}
                              activeTab={activeTab}
                              province={provinsi.id}
                              emergency={isEmergency}
                              selectedJamTayang={handleJamTayang}
                              setNewColor={setNewColor}
                              newColor={newColor}
                              jamTayang={jamTayang}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="tambahan">
                              Provinsi Tambahan<span className="textOpsional">(Opsional)</span>
                            </Label>
                            <Button
                              color=""
                              disabled={provinsi == "" || durasi == "" || jamTayang == []}
                              onClick={toggleModalTambahProvinsi}
                              id="tanggal"
                              className="inputWrapper btnTambahProv">
                              <p className="selectedDate">
                                {showProvinsiTambahan
                                  ? showProvinsiTambahan.id !== ""
                                    ? showProvinsiTambahan.sort().join(",")
                                    : ""
                                  : ""}
                              </p>
                            </Button>
                            <ModalTambahProvinsi
                              activeTab={activeTab}
                              province={provinsi}
                              modalTambahProvinsi={modalTambahProvinsi}
                              toggleModalTambahProvinsi={toggleModalTambahProvinsi}
                              selectedDate={selectedDate}
                              startDate={startDate}
                              endDate={endDate}
                              activeTab={activeTab}
                              province={provinsi.id}
                              emergency={isEmergency}
                              time={jamTayang}
                              durasi={durasi}
                              listSelected={showProvinsiTambahan}
                              selectedProvince={handleSelectedProvince}
                            />
                          </FormGroup>
                          <div className="wrapperBtnCreate">
                            <Button className="btnClear" onClick={handleClear} color="">
                              Clear
                            </Button>
                            <Button
                              className="btnAskSubmit btn btn-rounded"
                              disabled={disableButton()}
                              onClick={handleSubmitContent}
                              color="">
                              {loading ? <>Loading...</> : <>Ajukan Konten</>}
                            </Button>
                          </div>
                          <div className="wrapperKonten"></div>
                        </Form>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* </MDBContainer> */}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

export default BuatKontenAdmin;
