import React, { useEffect } from 'react';
import { Container, Card, CardBody, Row, Col, Button, Table } from 'reactstrap';
import { MDBDataTable, MDBDataTableV5 } from 'mdbreact';
import API from '../../../services';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import DeleteNewsletter from '../Popup/Article/DeleteNewsletter';

export default function TableNewsletter(props) {
    const {getDataTable} = props;
    // console.log(getDataTable)
    const allDatatable = getDataTable.state;
    // console.log(allDatatable)

    return (
        <div>
            <Container>
                <div style={{ marginBottom: "2%" }}>
                    <Card style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                                <CardBody>
                                    <Table borderless responsive>
                                        <thead>
                                            <tr>
                                                <th>Judul</th>
                                                <th>Organisasi</th>
                                                <th>Durasi</th>
                                                <th>Tanggal Tayang</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{backgroundColor : "rgba(0,0,0,0.1)"}}>
                                            <tr>
                                            <th scope="row">{allDatatable.category}</th>
                                            <td>{allDatatable.requested_by.organization.name}</td>
                                            <td>{allDatatable.proposed_duration + "jam"}</td>
                                            <td>{allDatatable.proposed_date.length > 0
                                                ? `${moment(allDatatable.proposed_date[0], "DD-MM-YYYY").format(
                                                    "DD-MM-YYYY"
                                                    )}\n s/d \n${moment(allDatatable.proposed_date.slice(-1), "DD-MM-YYYY").format(
                                                    "DD-MM-YYYY"
                                                    )} `
                                                : "-"}
                                            </td>
                                            <td>{allDatatable.status}</td>
                                            </tr>
                                        </tbody>
                                        </Table>
                                </CardBody>
                            </Card>
                </div>
                {/* <DeleteNewsletter modal={modalHapus} toggle={handleHapus} data={toModalHapus} /> */}
            </Container>
        </div>
    )
}
