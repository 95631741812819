import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import API from '../../../services';

class Line extends Component {
    state = {
        data: [],
        dataImpresi : [],
        dataImpresiDate : [],
        dataKlik: [],
    }

    componentDidMount(props){
        API.postSiteAnalisisBanner({
            id: "5f69f8609802a7099f3dcccb",
            provinsi:"PAPUA BARAT"
        }).then((res) =>{
            console.log('123',res);
            const person ={
                "2020-11-04": 10,
                "2020-11-05": 11,
                "2020-11-06": 11,
                "2020-11-07": 12,
                "2020-11-08": 7,
                "2020-11-09": 14,
                "2020-11-10": 3,
                "2020-11-11": 10,
                "2020-11-12": 14,
                "2020-11-13": 12,
                "2020-11-14": 7,
                "2020-11-15": 7,
                "2020-11-16": 7,
                "2020-11-17": 10,
                "2020-11-18": 7,
                "2020-11-19": 15,
                "2020-11-20": 7,
                "2020-11-21": 10,
                "2020-11-22": 10,
                "2020-11-23": 12,
                "2020-11-24": 5,
                "2020-11-25": 9,
                "2020-11-26": 12,
                "2020-11-27": 10,
                "2020-11-28": 12,
                "2020-11-29": 8,
                "2020-11-30": 12,
                "2020-12-01": 9,
                "2020-12-02": 12,
                "2020-12-03": 16,
                "2020-12-04": 8
            }

            const klik = {
                "2020-11-04": 13,
                "2020-11-05": 10,
                "2020-11-06": 7,
                "2020-11-07": 13,
                "2020-11-08": 9,
                "2020-11-09": 7,
                "2020-11-10": 10,
                "2020-11-11": 5,
                "2020-11-12": 11,
                "2020-11-13": 13,
                "2020-11-14": 11,
                "2020-11-15": 8,
                "2020-11-16": 8,
                "2020-11-17": 11,
                "2020-11-18": 11,
                "2020-11-19": 10,
                "2020-11-20": 9,
                "2020-11-21": 6,
                "2020-11-22": 12,
                "2020-11-23": 8,
                "2020-11-24": 7,
                "2020-11-25": 12,
                "2020-11-26": 7,
                "2020-11-27": 8,
                "2020-11-28": 8,
                "2020-11-29": 12,
                "2020-11-30": 9,
                "2020-12-01": 6,
                "2020-12-02": 13,
                "2020-12-03": 7,
                "2020-12-04": 11
            }
        
            const propertyName = Object.keys(person); 
            const values = Object.values(person); 
            const klikValues = Object.values(klik); 
            this.setState({
                dataImpresi : values,
                dataImpresiDate : propertyName,
                dataKlik: klikValues
            })
        })
    }

    getOption = () => {
        return {
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                zlevel: 0,
                x: 50,
                x2: 50,
                y: 30,
                y2: 30,
                borderWidth: 0,
            },
            xAxis: {
                type: 'category',
                data: this.state.dataImpresiDate,
                axisLable: {
                    color: "#ffffff"
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788d"
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLable: {
                    color: "#ffffff"
                },
                axisLine: {
                    lineStyle: {
                        color: "#74788d"
                    }
                }
            },
            series: [{
                data: this.state.dataImpresi,
                type: 'line',
                name: 'Impresi'
            },{
                name: 'Klik',
                data: this.state.dataKlik,
                type: 'line'
            },{
                name: 'Pengguna Unik',
                data: [9, 6, 1, 2, 3, 10, 35],
                type: 'line'
            }],
            colors: [
                '#f46a6a', '#34c38f', '#556ee6'
            ],
            textStyle: {
                color: ['#74788d']
            }
        };
    };
    render() {
        return (
            <React.Fragment>
                <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
            </React.Fragment>
        );
    }
}
export default Line;