// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_titleMenuBI__YNUCG {\n  font-size: 18px;\n  color: #2c3280;\n  font-weight: bold;\n}\n\n.style_titleCardBI__2sEKo {\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.style_titleMan__3txHo {\n  color: #2c3280;\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.style_titleWoman__JdZJk {\n  color: #c42127;\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.style_valueMan__22t9u {\n  color: #2c3280;\n  font-size: 30px;\n  font-weight: bold;\n  margin-top: 10px;\n}\n\n.style_valueWoman__-di2x {\n    color: #c42127;\n    font-size: 30px;\n    font-weight: bold;\n    margin-top: 10px;\n}\n\n.style_titlePendidikan__22eSv {\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.style_valuePendidikan__1DSsc {\n  font-size: 30px;\n  font-weight: bold;\n  margin-top: 10px;\n}", ""]);
// Exports
exports.locals = {
	"titleMenuBI": "style_titleMenuBI__YNUCG",
	"titleCardBI": "style_titleCardBI__2sEKo",
	"titleMan": "style_titleMan__3txHo",
	"titleWoman": "style_titleWoman__JdZJk",
	"valueMan": "style_valueMan__22t9u",
	"valueWoman": "style_valueWoman__-di2x",
	"titlePendidikan": "style_titlePendidikan__22eSv",
	"valuePendidikan": "style_valuePendidikan__1DSsc"
};
module.exports = exports;
