import React from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, Row } from "reactstrap";

import "./style.css";

import LineChart from "./LineChart.js";
import CardDetail from "./CardDetailChart";

function CardLineChart(props) {
  return (
    <div className="container-fluid my-5">
      <Row>
        <Col md="12" lg="7">
          <Card style={{ height: "45rem" }}>
            <h4 className="m-4" style={{ fontWeight: "bold" }}>
              JUMLAH PENGUNJUNG (DEVICE FINGERPRINT) BULAN INI
            </h4>
            <div className="d-flex justify-content-center">
              {/* {props.loading ? (
                <Skeleton count={3} />
              ) : props.data !== undefined ? ( */}
              <LineChart data={props.data} loading={props.loading} />
              {/* ) : (
                "Tidak Ada Data"
              )} */}
            </div>
          </Card>
        </Col>
        <Col md="12" lg="5">
          <div style={{ height: "500px" }}>
            <CardDetail data={props.data} loading={props.loading} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CardLineChart;
