import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "./style.css";
import style from "./style.module.css";
import API from "../../../services";
// -------------------COMPONENT--------------------

import TableCPAPartner from "./TableCPAnalisPartner";

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Header from "../../VerticalLayout/Header";
import TableKontenContentPartnerVideo from "./Content/Video";
import TableKontenContentPartnerBanner from "./Content/Banner";
import TableKontenContentPartnerBerita from "./Content/Berita";
import { useLocation, useHistory } from "react-router-dom";

// import { exportPDF } from "../../ContentPartner/Download/Konten/PDF/DownloadPDF";
// import DownloadXLS from "../../ContentPartner/Download/Konten/XLS/DownloadXLS";
import dummy from "../../../data/dummy.json";
// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function KontenContentPartner(props) {
  let role = window.localStorage.getItem("role");

  //handle data xls
  const [data, setData] = useState([]);
  const [idContent, setIdContent] = useState("");
  const [video, setVideo] = useState([]);
  const [news, setNews] = useState([]);
  const [banner, setBanner] = useState([]);

  const [loading, setLoading] = useState(true);

  //handle data when change tab
  const handleDataVideo = (vid) => {
    setData(vid);
  };

  const handleDataBanner = (banner) => {
    setData(banner);
  };

  const handleDataBerita = (berita) => {
    setData(berita);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  //handle download pdf
  const handleDownloadPDF = () => {
    // exportPDF(data, activeTab);
  };

  const tabStyle = {
    color: "#c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };

  // useEffect(() => {
  //   let notifContentType = props.dataNotif.data;
  //   if (notifContentType !== "empty") {
  //     switch (notifContentType.data.content_type) {
  //       case "video":
  //         handleChangeTab("1");
  //         break;
  //       case "banner":
  //         handleChangeTab("2");
  //         break;
  //       case "news":
  //         handleChangeTab("3");
  //         break;
  //       default:
  //         handleChangeTab("1");
  //         break;
  //     }
  //     setIdContent(
  //       notifContentType
  //         ? notifContentType.data
  //           ? notifContentType.data.content
  //             ? notifContentType.data.content._id
  //               ? notifContentType.data.content._id
  //               : "-"
  //             : "-"
  //           : "-"
  //         : "-"
  //     );
  //   }
  // }, [props.dataNotif.data]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    if (location.state) {
      API.postGetCPContent({
        id: location.state.id,
      }).then((result) => {
        if (result.status == 200) {
          const data = result.data.data;
          setVideo(data.video);
          setBanner(data.banner);
          setNews(data.news);
          setLoading(false);
        }
      });
    } else {
      history.push("/admin/cp-analitics");
      // dimas , comment this
    }
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="Konten" btn={true} btntype="back" btntext="Kembali" />
        </header>
        <TableCPAPartner />
        <main>
          <div className="container-fluid">
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12" style={{ paddingRight: "6% !important" }}>
                    <MDBNav classicTabs className="wrapperNav">
                      <div className={style.wrapperTab}>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "1" ? tabStyle : {}}
                            className="tabMenu waves-light"
                            active={activeTab === "1"}
                            onClick={() => handleChangeTab("1")}>
                            VIDEO
                          </MDBNavLink>
                          <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "2" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "2"}
                            onClick={() => handleChangeTab("2")}>
                            BANNER
                          </MDBNavLink>
                          <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "3" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "3"}
                            onClick={() => handleChangeTab("3")}>
                            BERITA
                          </MDBNavLink>
                          <hr style={activeTab === "3" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                      </div>
                      <div className="wrapperAction"></div>
                    </MDBNav>

                    <MDBTabContent className="card" activeItem={activeTab}>
                      <MDBTabPane tabId="1">
                        {activeTab === "1" && (
                          <TableKontenContentPartnerVideo
                            idContent={idContent}
                            handleData={handleDataVideo}
                            activeTab={activeTab}
                            loading={loading}
                            dataKonten={video}
                          />
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId="2">
                        {activeTab === "2" && (
                          <TableKontenContentPartnerBanner
                            idContent={idContent}
                            handleData={handleDataBanner}
                            loading={loading}
                            dataKonten={banner}
                          />
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId="3">
                        {activeTab === "3" && (
                          <TableKontenContentPartnerBerita
                            idContent={idContent}
                            handleData={handleDataBerita}
                            activeTab={activeTab}
                            loading={loading}
                            dataKonten={news}
                          />
                        )}
                      </MDBTabPane>
                    </MDBTabContent>
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

// const mapStateToProps = (state) => {
//   const { dataNotif } = state.dataReducer;
//   return { dataNotif };
// };
// export default connect(mapStateToProps, null)(withRouter(KontenContentPartner));

export default KontenContentPartner;
