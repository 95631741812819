import React, { useEffect, useState } from "react";
import Map from "../../../ContentPartner/Map/Home/MapsVector";
import {
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Col,
  CardBody,
  CardTitle,
  Card,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";

import API from "../../../../services";

export default function CardMap() {
  const [prov, setProv] = useState("PILIH PROVINSI");
  const [listProv, setListProv] = useState([]);
  const [dataImpressi, setDataImpressi] = useState([
    { videoImpressi: 0, bannerImpressi: 0, newsImpressi: 0 },
  ]);
  const [loading, setLoading] = useState(true);
  const [idRegion, setIdRegion] = useState("");

  const skeletonCostume = <Skeleton circle={true} height={80} width={80} />;

  let idUser = window.localStorage.getItem("idUser");

  const onclikRegion = (param) => {
    switch (param) {
      case "ID_pa":
        setProv("PAPUA");
        break;
      case "ID_pb":
        setProv("PAPUA BARAT");
        break;
      case "ID_ma":
        setProv("MALUKU");
        break;
      case "ID_mu":
        setProv("MALUKU UTARA");
        break;
      case "ID_nt":
        setProv("NUSA TENGGARA TIMUR");
        break;
      case "ID_nb":
        setProv("NUSA TENGGARA BARAT");
        break;
      case "ID_sa":
        setProv("SULAWESI UTARA");
        break;
      case "ID_st":
        setProv("SULAWESI TENGAH");
        break;
      case "ID_sr":
        setProv("SULAWESI BARAT");
        break;
      case "ID_sn":
        setProv("SULAWESI SELATAN");
        break;
      case "ID_sg":
        setProv("SULAWESI TENGGARA");
        break;
      case "ID_go":
        setProv("GORONTALO");
        break;
      case "ID_ba":
        setProv("BALI");
        break;
      case "ID_jt":
        setProv("JAWA TENGAH");
        break;
      case "ID_jl":
        setProv("JAWA TIMUR");
        break;
      case "ID_jb":
        setProv("JAWA BARAT");
        break;
      case "ID_yo":
        setProv("DI YOGYAKARTA");
        break;
      case "ID_jk":
        setProv("DKI JAKARTA");
        break;
      case "ID_bt":
        setProv("BANTEN");
        break;
      case "ID_ki":
        setProv("KALIMANTAN TIMUR");
        break;
      case "ID_ku":
        setProv("KALIMANTAN UTARA");
        break;
      case "ID_kb":
        setProv("KALIMANTAN BARAT");
        break;
      case "ID_kt":
        setProv("KALIMANTAN TENGAH");
        break;
      case "ID_ks":
        setProv("KALIMANTAN SELATAN");
        break;
      case "ID_bb":
        setProv("KEPULAUAN BANGKA BELITUNG");
        break;
      case "ID_be":
        setProv("BENGKULU");
        break;
      case "ID_la":
        setProv("LAMPUNG");
        break;
      case "ID_ss":
        setProv("SUMATERA SELATAN");
        break;
      case "ID_sb":
        setProv("SUMATERA BARAT");
        break;
      case "ID_su":
        setProv("SUMATERA UTARA");
        break;
      case "ID_ja":
        setProv("JAMBI");
        break;
      case "ID_ri":
        setProv("RIAU");
        break;
      case "ID_kr":
        setProv("KEPULAUAN RIAU");
        break;
      case "ID_ac":
        setProv("ACEH");
        break;

      default:
        break;
    }
    setIdRegion(param);
  };

  const selectedDropdownProv = (param) => {
    switch (param) {
      case "PAPUA":
        setIdRegion("ID_pa");
        break;
      case "PAPUA BARAT":
        setIdRegion("ID_pb");
        break;
      case "MALUKU":
        setIdRegion("ID_ma");
        break;
      case "MALUKU UTARA":
        setIdRegion("ID_mu");
        break;
      case "NUSA TENGGARA TIMUR":
        setIdRegion("ID_nt");
        break;
      case "NUSA TENGGARA BARAT":
        setIdRegion("ID_nb");
        break;
      case "SULAWESI UTARA":
        setIdRegion("ID_sa");
        break;
      case "SULAWESI TENGAH":
        setIdRegion("ID_st");
        break;
      case "SULAWESI BARAT":
        setIdRegion("ID_sr");
        break;
      case "SULAWESI SELATAN":
        setIdRegion("ID_sn");
        break;
      case "SULAWESI TENGGARA":
        setIdRegion("ID_sg");
        break;
      case "GORONTALO":
        setIdRegion("ID_go");
        break;
      case "BALI":
        setIdRegion("ID_ba");
        break;
      case "JAWA TENGAH":
        setIdRegion("ID_jt");
        break;
      case "JAWA TIMUR":
        setIdRegion("ID_jl");
        break;
      case "JAWA BARAT":
        setIdRegion("ID_jb");
        break;
      case "DI YOGYAKARTA":
        setIdRegion("ID_yo");
        break;
      case "DKI JAKARTA":
        setIdRegion("ID_jk");
        break;
      case "BANTEN":
        setIdRegion("ID_bt");
        break;
      case "KALIMANTAN TIMUR":
        setIdRegion("ID_ki");
        break;
      case "KALIMANTAN UTARA":
        setIdRegion("ID_ku");
        break;
      case "KALIMANTAN BARAT":
        setIdRegion("ID_kb");
        break;
      case "KALIMANTAN TENGAH":
        setIdRegion("ID_kt");
        break;
      case "KALIMANTAN SELATAN":
        setIdRegion("ID_ks");
        break;
      case "KEPULAUAN BANGKA BELITUNG":
        setIdRegion("ID_bb");
        break;
      case "BENGKULU":
        setIdRegion("ID_be");
        break;
      case "LAMPUNG":
        setIdRegion("ID_la");
        break;
      case "SUMATERA SELATAN":
        setIdRegion("ID_ss");
        break;
      case "SUMATERA BARAT":
        setIdRegion("ID_sb");
        break;
      case "SUMATERA UTARA":
        setIdRegion("ID_su");
        break;
      case "JAMBI":
        setIdRegion("ID_ja");
        break;
      case "RIAU":
        setIdRegion("ID_ri");
        break;
      case "KEPULAUAN RIAU":
        setIdRegion("ID_kr");
        break;
      case "ACEH":
        setIdRegion("ID_ac");
        break;

      default:
        break;
    }
  };

  const getDataImpressiProv = () => {
    if (prov === "PILIH PROVINSI") {
      setLoading(false);
    }
    const param = {
      idUser: idUser,
      provinsi: prov,
    };
    setLoading(true);
    API.postCPHomeGetImpressiProv(param)
      .then((res) => {
        setDataImpressi(res.data.data);
        setLoading(false);
      })
      .catch((err) => setDataImpressi(null));
  };

  const getLisProvinces = () => {
    API.getAllProvince()
      .then((res) => {
        setListProv(res.data.data);
      })
      .catch((err) => setListProv([]));
  };

  useEffect(() => {
    getDataImpressiProv();
    getLisProvinces();
  }, [prov]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const dropdownProvinces = () => {
    return (
      <Dropdown className="dropdown-provinsi-select" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          style={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: "25px",
            borderColor: "#C42127",
            borderWidth: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          caret>
          <span
            // className="d-flex"
            style={{ color: "#C42127", fontWeight: "bold", fontSize: "15px" }}>
            {prov}
          </span>
          {"  "}
          <i className="mdi mdi-24px mdi-chevron-down" style={{ color: "#C42127" }} />
        </DropdownToggle>
        <DropdownMenu
          className="mx-auto"
          style={{ maxHeight: "300px", width: "100%", overflowY: "auto", borderRadius: "15px" }}>
          {listProv.length !== 0
            ? listProv.map((a) => (
                <DropdownItem>
                  <div
                    className="text-center"
                    onClick={() => {
                      setProv(a.name);
                      selectedDropdownProv(a.name);
                    }}>
                    <span style={{ color: "#C42127", fontSize: "15px" }}> {a.name}</span>
                  </div>
                </DropdownItem>
              ))
            : listProv.map((a) => (
                <DropdownItem>
                  <div className="text-center">
                    <span style={{ color: "#C42127", fontSize: "15px" }}>No Data</span>
                  </div>
                </DropdownItem>
              ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <div className="mb-5">
      <Row className="d-flex justify-content-center mb-3">
        <Col xs="12" md="8" lg="4">
          {dropdownProvinces()}
        </Col>
      </Row>
      <div className="p-2 mx-3 border" style={{ borderRadius: "13px" }}>
        <Row className="d-flex justify-content-end">
          <Col lg="5">
            <Card
              className="p-1"
              style={{
                zIndex: "99",
                position: "absolute",
                right: "5%",
                width: "70%",
                border: "1.5px solid",
                borderRadius: "15px",
                borderColor: "#2C3280",
                // opacity:'0.5'
              }}>
              <CardBody>
                <CardTitle className="text-center">TOTAL IMPRESI</CardTitle>
                <Row>
                  <Col md="12" lg="7" className="title-map" style={{ color: "#8CC6C9" }}>
                    Video
                  </Col>
                  <Col md="12" lg="5" className="value-map">
                    {loading == true ? (
                      <Skeleton />
                    ) : dataImpressi !== null ? (
                      dataImpressi.videoImpresi ?? 0
                    ) : (
                      "Tidak ada Data"
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="7" className="title-map" style={{ color: "#E2B674" }}>
                    Banner
                  </Col>
                  <Col md="12" lg="5" className="value-map">
                    {loading == true ? (
                      <Skeleton />
                    ) : dataImpressi !== null ? (
                      dataImpressi.banerImpresi ?? 0
                    ) : (
                      "Tidak ada Data"
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="7" className="title-map" style={{ color: "#C18ECE" }}>
                    Berita
                  </Col>
                  <Col md="12" lg="5" className="value-map">
                    {loading == true ? (
                      <Skeleton />
                    ) : dataImpressi !== null ? (
                      dataImpressi.newsImpresi ?? 0
                    ) : (
                      "Tidak ada Data"
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Map selectedProv={onclikRegion} idRegion={idRegion} /> */}
        <div style={{ zIndex: "98" }}>
          <Map onclikRegion={onclikRegion} idRegion={idRegion} />
        </div>
      </div>
      {/* <Container fluid>
        <Row className="title-card d-flex justify-content-center my-5">
          TOTAL IMPRESI KONTEN PER PROVINSI
        </Row>
        <Row className="mt-5 mb-5" style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)" }}>
          <Col md="4">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp my-5">
                <div className="title-card" style={{ color: "#8CC6C9" }}>
                  VIDEO
                </div>
                <div className="value-percent my-5" style={{ width: "100px" }}>
                  {loading == true
                    ? skeletonCostume
                    : dataImpressi !== null
                    ? dataImpressi.videoImpresi ?? 0
                    : "Tidak ada Data"}
                </div>
              </div>
            </Row>
          </Col>
          <Col md="4">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp my-5">
                <div className="title-card" style={{ color: "#E2B674" }}>
                  BANNER
                </div>
                <div className="value-percent my-5" style={{ width: "100px" }}>
                  {loading == true
                    ? skeletonCostume
                    : dataImpressi !== null
                    ? dataImpressi.bannerImpresi ?? 0
                    : "Tidak ada Data"}
                </div>
              </div>
            </Row>
          </Col>
          <Col md="4">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner-cp my-5">
                <div className="title-card" style={{ color: "#C18ECE" }}>
                  BERITA
                </div>
                <div className="value-percent my-5" style={{ width: "100px" }}>
                  {loading == true
                    ? skeletonCostume
                    : dataImpressi !== null
                    ? dataImpressi.newsImpresi ?? 0
                    : "Tidak ada Data"}
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
}
