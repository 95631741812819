import React from "react";
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "mdbreact";
import { Col, Row } from "reactstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "../../../../components/Admin/SurveyAdmin/XLS/DownloadXLS";

const HeaderXLS = (props) => {
  return (
    <Row className="col-lg-3" className="d-flex justify-content-end flex-wrap">
      <Col lg="1" md="1" xs="1"></Col>
      <Col lg="2" xs="2">
        <Dropdown toggle={function noRefCheck() {}}>
          <DropdownToggle caret={true} color="primary">
            {" "}
            Download{" "}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <ReactHTMLTableToExcel
                id="TableXLS"
                className="DownloadTableXLS"
                table="DownloadTableXLS"
                filename={props.title}
                sheet="users"
                buttonText="XLS"
              />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <DownloadXLS id="DownloadTableXLS" className="DownloadTableXLS" data={props.data} />
      </Col>
    </Row>
  );
};

export default HeaderXLS;
