import React from "react";

import { Modal, ModalBody, Button, Row } from "reactstrap";
import styles from "./styles.module.css";
import { MDBModalHeader } from "mdbreact";

function PopupConfirmation({
	toggleDelete,
	modalTrash,
	data,
	TrashAction,
	// handleChangeAlasan,
}) {
	return (
		<>
			<Modal
				size="lg"
				isOpen={modalTrash}
				toggle={toggleDelete}
				centered={true}
			>
				<MDBModalHeader toggle={toggleDelete}>
					<span className={styles.headText}> HAPUS PENGGUNA </span>
				</MDBModalHeader>
				<ModalBody>
					<center className="container-popup">
						{/* <Row className={`justify-content-center ${styles.wrapper}`}>
                            <h3 className={styles.title}>Verifikasi Pengguna</h3>
                        </Row> */}

						<Row className={`justify-content-center ${styles.wrapper}`}>
							<h4 className={styles.title}>{data.username}</h4>
						</Row>

						<div className={styles.text}>Anda yakin ingin hapus pengguna ini?</div>
						{/* <textarea
							name="alasan_tolak"
							className={styles.input_alasan}
							onChange={handleChangeAlasan}
							placeholder="Tulis Alasan Penolakan..."
						></textarea> */}
						{/* <Button color="" className={styles.btn_cancel} onClick={toggleVerify}>Tidak</Button> */}
						<Button
							color=""
							className={styles.btn_confirm}
							onClick={TrashAction}
						>
							HAPUS
						</Button>
					</center>
				</ModalBody>
			</Modal>
		</>
	);
}

export default PopupConfirmation;
