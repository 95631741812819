import React from "react";
import { MDBModal, MDBCardBody, MDBCardTitle, MDBCard } from "mdbreact";

// import style from "./style.module.css";

const helperPopup = (props) => {
  return (
    <MDBModal isOpen={props.modal} toggle={props.toggle} size="lg" centered={true}>
      <MDBCard className="p-2">
        <MDBCardTitle>
          <div className="text-center p-3">PANDUAN PENCARIAN TRAFFIK KONTEN</div>
        </MDBCardTitle>
        <MDBCardBody>
          <div style={{ minHeight: "300px" }}>
            <div className="mx-3" style={{ fontWeight: "bold" }}>
              Kombinasi Data Berdasarkan:
            </div>
            <ol>
              <li className="my-2">Data Organisasi dan Provinsi</li>
              <li className="my-2">
                Data Organisasi ={">"} Provinsi ={">"} Kabupaten
              </li>
              <li className="my-2">
                Data Organisasi ={">"} Provinsi ={">"} Kategori Konten
              </li>
              <li className="my-2">
                Data Organisasi ={">"} Kategori ={">"} Durasi Penayangan Konten
              </li>
              <li className="my-2">
                Data Organisasi ={">"} Provinsi ={">"} Jam Tayang
              </li>
              <li className="my-2">
                Data Organisasi ={">"} Kategori Konten ={">"} Jam Tayang
              </li>
            </ol>
          </div>
        </MDBCardBody>
      </MDBCard>
    </MDBModal>
  );
};

export default helperPopup;
