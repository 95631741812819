import React, { useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import Pdf from "react-to-pdf";

// COMPONENTS
import FilterOrgProvLokasi from "./Filter/FilterOrgProvLokasi";
import ChartLokasi from "../Chart/ChartOrgProvLokasi";

export default function CardOrgProv(props) {
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(true);

  const [prov, setProv] = useState("");
  const [idProvince, setIdProvince] = useState("");
  const [type, setType] = useState("");

  const [showComponent, setShowComponent] = useState(false);
  const downloadRef = React.createRef();

  const handlePDFDownload = (fn) => {
    Swal.fire({
      title: "Apakah kamu ingin mendownload halaman ini?",
      text: "Data PDF akan di download",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2c3280",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        fn();
      }
    });
  };

  return (
    <div>
      <FilterOrgProvLokasi
        setData={setData}
        setDataFilter={setDataFilter}
        setLoading={setLoading}
        setShowComponent={setShowComponent}
        province={prov}
        idProvince={idProvince}
        setDataSave={props.setDataSave}
        data={data}
        setType={setType}
      />
      {showComponent ? (
        loading ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
            <Spinner type="grow" color="danger" />
          </div>
        ) : data && dataFilter === null ? (
          <div>Tidak Ada Data</div>
        ) : (
          <>
            <div>
              <ChartLokasi data={data} dataFilter={dataFilter} typeData={type} />
            </div>
          </>
        )
      ) : (
        "Silahkan isi Filter Data terlebih dahulu Untuk menampilkan Data"
      )}
    </div>
  );
}
