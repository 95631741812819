import React from "react";

import { Row, Card, Col, Spinner } from "reactstrap";

// import "tui-chart/dist/tui-chart.css";
import { ColumnChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "../../style.module.css";
import "../../toastui.scss";

// import API from "../../../../services";
var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  // title: {
  //     color: '#8791af',
  // },
  xAxis: {
    // title: {
    //     color: '#8791af'
    // },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#2C3280"],
  },
};

export default function CardPersebaranOS(props) {
  const renderColumnChart = (param) => {
    const _width = window.screen.width / 1.8;
    // console.log("asdad", Object.values(param ? param.age ?? 0 : 0));
    // console.log("sssssss", Math.max(...Object.values(param ? param.age ?? 0 : 0)));
    const data = {
      categories: [
        "Usia 0-10",
        "Usia 11-20",
        "Usia 21-30",
        "Usia 31-40",
        "Usia 41-50",
        "Usia 51-60",
        "Usia 61-70",
        "Usia 71-80",
        "Usia 81-90",
        "Usia >91",
      ],
      series: [
        {
          name: "Jumlah Pengunjung",
          // data: param ? param.Agribisnis ?? 100 : 100,
          data: Object.values(param ? param.age ?? 0 : 0),
          // data: [20, 12, 56, 78, 43, 67, 19, 20, 12, 49],
        },
      ],
    };

    const options = {
      chart: {
        width: _width,
        height: _width * 0.4,
        // title: 'Jumlah',
        // format: "1,000",
      },
      yAxis: {
        // title: "Jumlah",
        min: 0,
        // max: Math.max(...Object.values(param ? param.age ?? 0 : 0)) + 1000,
        title: "Jumlah",
      },
      xAxis: {
        title: "Rentang Umur",
      },
      legend: {
        align: "bottom",
        // visible: false,
        showCheckbox: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Persebaran_Umur_Survey",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("persebaranUmurTheme", theme);
    options.theme = "persebaranUmurTheme";

    return (
      <React.Fragment>
        <ColumnChart data={data} options={options} />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Row style={{ height: "450px" }} className="my-3">
        <Col md="12">
          <div className="p-3">
            <Row>
              {" "}
              <div className={`${style.titleCardBI} mb-3`}>PERSEBARAN UMUR</div>
            </Row>
            <Row>
              <Col md="12" style={{ display: "flex", justifyContent: "center" }}>
                <div className="mt-1 mx-auto">
                  {props.loading ? (
                    <div
                      className="text-center"
                      style={{ marginBottom: "150px", marginTop: "150px" }}>
                      <Spinner color="primary" />
                    </div>
                  ) : props.data && props.data.length !== 0 ? (
                    renderColumnChart(props.data)
                    // ""
                  ) : (
                    <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                      <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
