import React, { useEffect, useState } from "react";
import Map from "./MapsVector";
import {
  Spinner,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Col,
  CardBody,
  Card,
} from "reactstrap";
// import iconDown from "../../../assets/icon/back.png";

import API from "../../../services";

import { useSelector } from "react-redux";

export default function CardMap(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);
  const [prov, setProv] = useState(
    props.idProvince === "5c073c0b2f1e2050e0c6bf64" ? "DKI JAKARTA" : ""
  );
  // const [idProv, setIdProv] = useState("");
  const [listProv, setListProv] = useState([]);
  // const [dataImpressi, setDataImpressi] = useState([
  //   { videoImpressi: 0, bannerImpressi: 0, newsImpressi: 0 },
  // ]);
  // const [loading, setLoading] = useState(true);
  const [idRegion, setIdRegion] = useState("ID_jk");

  const onclikRegion = (param) => {
    switch (param) {
      case "ID_pa":
        setProv("PAPUA");
        props.setIdProvince("5c08b0452f1e2050e0c6df14");
        break;
      case "ID_pb":
        setProv("PAPUA BARAT");
        props.setIdProvince("5c08ad1f2f1e2050e0c6de2c");
        break;
      case "ID_ma":
        setProv("MALUKU");
        props.setIdProvince("5c089dcd2f1e2050e0c6dc5f");
        break;
      case "ID_mu":
        setProv("MALUKU UTARA");
        props.setIdProvince("5c0897752f1e2050e0c6dc35");
        break;
      case "ID_nt":
        setProv("NUSA TENGGARA TIMUR");
        props.setIdProvince("5c08a8b82f1e2050e0c6dce1");
        break;
      case "ID_nb":
        setProv("NUSA TENGGARA BARAT");
        props.setIdProvince("5c04a81b21fb6a653aaab2a5");
        break;
      case "ID_sa":
        setProv("SULAWESI UTARA");
        props.setIdProvince("5c08dcf72f1e2050e0c6e55e");
        break;
      case "ID_st":
        setProv("SULAWESI TENGAH");
        props.setIdProvince("5c08c8322f1e2050e0c6e21b");
        break;
      case "ID_sr":
        setProv("SULAWESI BARAT");
        props.setIdProvince("5c08cd192f1e2050e0c6e2d9");
        break;
      case "ID_sn":
        setProv("SULAWESI SELATAN");
        props.setIdProvince("5c08cf2f2f1e2050e0c6e325");
        break;
      case "ID_sg":
        setProv("SULAWESI TENGGARA");
        props.setIdProvince("5c08d80e2f1e2050e0c6e471");
        break;
      case "ID_go":
        setProv("GORONTALO");
        props.setIdProvince("5c0748772f1e2050e0c6c0a9");
        break;
      case "ID_ba":
        setProv("BALI");
        props.setIdProvince("5c04de111143c12c8bdbc9c4");
        break;
      case "ID_jt":
        setProv("JAWA TENGAH");
        props.setIdProvince("5c079ff62f1e2050e0c6cd4c");
        break;
      case "ID_jl":
        setProv("JAWA TIMUR");
        props.setIdProvince("5c07d086aea8f971c78f62d6");
        break;
      case "ID_jb":
        setProv("JAWA BARAT");
        props.setIdProvince("5c077c892f1e2050e0c6c50f");
        break;
      case "ID_yo":
        setProv("DI YOGYAKARTA");
        props.setIdProvince("5c065e5a2f1e2050e0c6bd55");
        break;
      case "ID_jk":
        setProv("DKI JAKARTA");
        props.setIdProvince("5c073c0b2f1e2050e0c6bf64");
        break;
      case "ID_bt":
        setProv("BANTEN");
        props.setIdProvince("5c06068b2f1e2050e0c6b038");
        break;
      case "ID_ki":
        setProv("KALIMANTAN TIMUR");
        props.setIdProvince("5c0888a62f1e2050e0c6da0f");
        break;
      case "ID_ku":
        setProv("KALIMANTAN UTARA");
        props.setIdProvince("5c088ac22f1e2050e0c6da81");
        break;
      case "ID_kb":
        setProv("KALIMANTAN BARAT");
        props.setIdProvince("5c07d0af2f1e2050e0c6d814");
        break;
      case "ID_kt":
        setProv("KALIMANTAN TENGAH");
        props.setIdProvince("5c0885d32f1e2050e0c6d978");
        break;
      case "ID_ks":
        setProv("KALIMANTAN SELATAN");
        props.setIdProvince("5c0882342f1e2050e0c6d8d1");
        break;
      case "ID_bb":
        setProv("KEPULAUAN BANGKA BELITUNG");
        props.setIdProvince("5c088d0d2f1e2050e0c6dabc");
        break;
      case "ID_be":
        setProv("BENGKULU");
        props.setIdProvince("5c0637062f1e2050e0c6b6e1");
        break;
      case "ID_la":
        setProv("LAMPUNG");
        props.setIdProvince("5c088faa2f1e2050e0c6db41");
        break;
      case "ID_ss":
        setProv("SUMATERA SELATAN");
        props.setIdProvince("5c08dd62aea8f971c78f66f4");
        break;
      case "ID_sb":
        setProv("SUMATERA BARAT");
        props.setIdProvince("5c08d5e6aea8f971c78f662c");
        break;
      case "ID_su":
        setProv("SUMATERA UTARA");
        props.setIdProvince("5c08e3e72f1e2050e0c6e619");
        break;
      case "ID_ja":
        setProv("JAMBI");
        props.setIdProvince("5c075a6c2f1e2050e0c6c3d9");
        break;
      case "ID_ri":
        setProv("RIAU");
        props.setIdProvince("5c08c3c92f1e2050e0c6e162");
        break;
      case "ID_kr":
        setProv("KEPULAUAN RIAU");
        props.setIdProvince("5c088e5c2f1e2050e0c6daf3");
        break;
      case "ID_ac":
        setProv("ACEH");
        props.setIdProvince("5c048b737fddb13f493b0cbc");
        break;

      default:
        break;
    }
    setIdRegion(param);
  };

  const selectedDropdownProv = (param, idProv) => {
    props.setIdProvince(idProv);
    switch (param) {
      case "PAPUA":
        setIdRegion("ID_pa");
        break;
      case "PAPUA BARAT":
        setIdRegion("ID_pb");
        break;
      case "MALUKU":
        setIdRegion("ID_ma");
        break;
      case "MALUKU UTARA":
        setIdRegion("ID_mu");
        break;
      case "NUSA TENGGARA TIMUR":
        setIdRegion("ID_nt");
        break;
      case "NUSA TENGGARA BARAT":
        setIdRegion("ID_nb");
        break;
      case "SULAWESI UTARA":
        setIdRegion("ID_sa");
        break;
      case "SULAWESI TENGAH":
        setIdRegion("ID_st");
        break;
      case "SULAWESI BARAT":
        setIdRegion("ID_sr");
        break;
      case "SULAWESI SELATAN":
        setIdRegion("ID_sn");
        break;
      case "SULAWESI TENGGARA":
        setIdRegion("ID_sg");
        break;
      case "GORONTALO":
        setIdRegion("ID_go");
        break;
      case "BALI":
        setIdRegion("ID_ba");
        break;
      case "JAWA TENGAH":
        setIdRegion("ID_jt");
        break;
      case "JAWA TIMUR":
        setIdRegion("ID_jl");
        break;
      case "JAWA BARAT":
        setIdRegion("ID_jb");
        break;
      case "DI YOGYAKARTA":
        setIdRegion("ID_yo");
        break;
      case "DKI JAKARTA":
        setIdRegion("ID_jk");
        break;
      case "BANTEN":
        setIdRegion("ID_bt");
        break;
      case "KALIMANTAN TIMUR":
        setIdRegion("ID_ki");
        break;
      case "KALIMANTAN UTARA":
        setIdRegion("ID_ku");
        break;
      case "KALIMANTAN BARAT":
        setIdRegion("ID_kb");
        break;
      case "KALIMANTAN TENGAH":
        setIdRegion("ID_kt");
        break;
      case "KALIMANTAN SELATAN":
        setIdRegion("ID_ks");
        break;
      case "KEPULAUAN BANGKA BELITUNG":
        setIdRegion("ID_bb");
        break;
      case "BENGKULU":
        setIdRegion("ID_be");
        break;
      case "LAMPUNG":
        setIdRegion("ID_la");
        break;
      case "SUMATERA SELATAN":
        setIdRegion("ID_ss");
        break;
      case "SUMATERA BARAT":
        setIdRegion("ID_sb");
        break;
      case "SUMATERA UTARA":
        setIdRegion("ID_su");
        break;
      case "JAMBI":
        setIdRegion("ID_ja");
        break;
      case "RIAU":
        setIdRegion("ID_ri");
        break;
      case "KEPULAUAN RIAU":
        setIdRegion("ID_kr");
        break;
      case "ACEH":
        setIdRegion("ID_ac");
        break;

      default:
        break;
    }
  };

  const getLisProvinces = () => {
    API.getAllProvince()
      .then((res) => {
        const listProv = res.data.data;
        setListProv(listProv);
      })
      .catch((err) => setListProv([]));
  };

  const getProvinceNameRedux = () => {
    API.getAllProvince()
      .then((res) => {
        const listProv = res.data.data;
        setListProv(listProv);
        if (listProv.length !== 0) {
          const provFilter = listProv.filter((e) => {
            return e._id === props.idProvince;
          });
          if (provFilter.length !== 0) {
            setProv(provFilter[0].name);
            selectedDropdownProv(provFilter[0].name, provFilter[0]._id);
          }
        }
      })
      .catch((err) => setListProv([]));
  };

  useEffect(() => {
    // getDataImpressiProv();
    getLisProvinces();
    props.setProvinceXLS(prov);
  }, [prov, props.idProvince]);

  useEffect(() => {
    if (props.idProvince === "5c073c0b2f1e2050e0c6bf64") {
      setProv("DKI JAKARTA");
      selectedDropdownProv("DKI JAKARTA", "5c073c0b2f1e2050e0c6bf64");
    }

    if (props.resetBtn && props.idProvince === "5c073c0b2f1e2050e0c6bf64") {
      setProv("Pilih Provinsi");
      selectedDropdownProv("", "");
      setIdRegion("");
    }
  }, [props.idProvince, props.resetBtn]);

  useEffect(() => {
    if (Object.keys(dataReduxLogHistory.data).length !== 0) {
      getProvinceNameRedux();
    }
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const dropdownProvinces = () => {
    return (
      <Dropdown className="dropdown-provinsi-select" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          style={{
            minWidth: "100%",
            backgroundColor: "white",
            borderRadius: "25px",
            borderColor: "#C42127",
            borderWidth: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}>
          <div
            style={{
              color: "#C42127",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              fontSize: "15px",
            }}>
            <span>{prov}</span>
            <i className="mdi mdi-24px mdi-chevron-down" style={{ color: "#C42127" }} />
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="mx-auto"
          style={{ maxHeight: "300px", width: "100%", overflowY: "auto", borderRadius: "15px" }}>
          {listProv.length !== 0
            ? listProv.map((a, i) => (
                <DropdownItem
                  key={i}
                  onClick={() => {
                    setProv(a.name);
                    selectedDropdownProv(a.name, a._id);
                  }}>
                  <div className="text-center">
                    <span style={{ color: "#C42127", fontSize: "15px" }}> {a.name}</span>
                  </div>
                </DropdownItem>
              ))
            : listProv.map((a) => (
                <DropdownItem>
                  <div className="text-center">
                    <span style={{ color: "#C42127", fontSize: "15px" }}>No Data</span>
                  </div>
                </DropdownItem>
              ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <div>
      <CardBody style={{ border: "1px solid #2C3280", borderRadius: "10px" }}>
        <Row className="d-flex justify-content-center my-3">
          <Col xs="12" md="8" lg="4">
            {dropdownProvinces()}
          </Col>
        </Row>
        <div className="p-2 mx-3">
          <Row className="d-flex justify-content-end">
            <Col md="5" lg="4">
              <Card
                className="p-1"
                style={{
                  zIndex: "99",
                  position: "absolute",
                  right: "5%",
                  width: "70%",
                  border: "2px solid",
                  borderRadius: "7px",
                  borderColor: "#2C3280",
                  // opacity:'0.5'
                }}>
                <CardBody className="px-1">
                  {/* <CardTitle className="text-center">TOTAL</CardTitle> */}
                  <Row className="my-2">
                    <Col lg="8">
                      <span>Total Lokasi AI Terintegrasi</span>
                    </Col>
                    <Col lg="4" className="text-center p-0">
                      {props.loading ? (
                        <div>
                          <Spinner size="10px" color="primary" size="sm" />
                        </div>
                      ) : (
                        <span style={{ color: "#2C3280", fontWeight: "bold", fontSize: "14px" }}>
                          {props.data ? props.data.total_ai ?? 0 : 0}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col lg="8">
                      <span>Total IP Terintegrasi</span>
                    </Col>
                    <Col lg="4" className="text-center p-0">
                      {props.loading ? (
                        <div>
                          <Spinner size="10px" color="primary" size="sm" />
                        </div>
                      ) : (
                        <span style={{ color: "#2C3280", fontWeight: "bold", fontSize: "14px" }}>
                          {props.data ? props.data.total_isp ?? 0 : 0}
                        </span>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* MAP VECTOR */}
          <div style={{ zIndex: "98" }}>
            <Map onclikRegion={onclikRegion} idRegion={idRegion} />
          </div>
        </div>
      </CardBody>
    </div>
  );
}
