import React from 'react';
import { Row, Col } from "reactstrap";

import Header from '../../../components/VerticalLayout/Header'
import KontenTayangTable from "../../../components/Kurator/Tables/KontenTayang"

const KontenTayangKurator = (props) => {

    return (
        <>
        <div className="page-content">
            <Header title="Konten Tayang"/>
            <div className="container-fluid">
                <Row>
                    <Col className="col-12">
                        <KontenTayangTable/>
                    </Col>
                </Row>
            </div>
        </div>
        </>
    );
    }
        
export default KontenTayangKurator;