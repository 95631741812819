import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "./style.css";
import style from "./style.module.css";
import DownWhite from "../../../assets/images/backwhite.png";
// -------------------COMPONENT--------------------
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Header from "../../../components/VerticalLayout/Header";
import TableSurveyContentPartner from "../../../components/SupportCenter/Table/TableSurveyContentPartner";
import TableSurveyVisitor from "../../../components/SupportCenter/Table/TableSurveyContentVisitor";

// import { exportPDF } from "../../../components/SupportCenter/Download/SurveyKepuasanCP/PDF/DownloadPDF";
// import { exportPDFVisitor } from "../../../components/SupportCenter/Download/SurveyKepuasanCP/PDF/DownloadPDFVisitor";
import DownloadXLS from "../../../components/SupportCenter/Download/SurveyKepuasanCP/XLS/DownloadXLS";
import DownloadXLSVisitor from "../../../components/SupportCenter/Download/SurveyKepuasanCP/XLS/DownloadXLSVisitor";

// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function SurveyKepuasanSummary(props) {
  let role = window.localStorage.getItem("role");
  //handle data xls
  const [data, setData] = useState([]);
  const [dataVisitor, setDataVisitor] = useState([]);

  const [idContent, setIdContent] = useState("");
  const [categoryContent, setCategoryContent] = useState("0");
  const [activeSubTabKelola, setActiveSubTabKelola] = useState("1");

  const [toggleDownload, setToggleDownload] = useState(false);
  const [downloadType, setDownloadType] = useState("PDF");

  //handle data when change tab
  const handleData = (data) => {
    setData(data);
  };

  const handleDataVisitor = (data) => {
    setDataVisitor(data);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("cp");
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  //handle download pdf
  const handleDownloadPDF = () => {
    setToggleDownload(!toggleDownload);
    setDownloadType("PDF");
    // if (activeTab == "cp") {
    //   exportPDF(data, activeTab);
    // } else {
    //   exportPDFVisitor(dataVisitor, activeTab);
    // }
  };

  const handleDownloadXLS = () => {
    setToggleDownload(!toggleDownload);
    setDownloadType("XLS");
  };

  const tabStyle = {
    color: "#c42127",
  };
  const subTabStyle = {
    color: "#c42127",
    // borderBottom: "1px solid #c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };
  useEffect(() => {
    let notifContentType = props.dataNotif.data;
    if (notifContentType !== "empty") {
      switch (notifContentType.data.content_type) {
        case "video":
          handleChangeTab("1");
          break;
        case "banner":
          handleChangeTab("2");
          break;
        case "news":
          handleChangeTab("3");
          break;
        default:
          handleChangeTab("1");
          break;
      }
      setIdContent(notifContentType.data.content._id);
      setCategoryContent(notifContentType.data.content.category);
    }
  }, [props.dataNotif.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="SURVEI KEPUASAN PELANGGAN" className="SuperAdminHeader" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Table" /> */}
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12">
                    <MDBNav classicTabs className="wrapperNav">
                      <div className={style.wrapperTab}>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "cp" ? tabStyle : {}}
                            className="tabMenu waves-light"
                            active={activeTab === "cp"}
                            onClick={() => handleChangeTab("cp")}>
                            CONTENT PARTNER
                          </MDBNavLink>
                          <hr style={activeTab === "cp" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "visitor" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "visitor"}
                            onClick={() => handleChangeTab("visitor")}>
                            VISITOR
                          </MDBNavLink>
                          <hr style={activeTab === "visitor" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                      </div>
                      {/* <div className="wrapperAction">
                        <MDBNavLink to="#" className="textDownload">
                          Download
                        </MDBNavLink>
                        <ReactHTMLTableToExcel
                          id="TableXLS"
                          className="DownloadTableXLS"
                          table="DownloadTableXLS"
                          filename={`Survey Kepuasan ${activeTab}_Report_${role}`}
                          sheet="users"
                          buttonText="XLS"
                        />
                        <MDBNavLink onClick={handleDownloadPDF} link to="#">
                          PDF
                        </MDBNavLink>
                      </div>
                      {activeTab == "cp" ? (
                        <DownloadXLS
                          id="DownloadTableXLS"
                          className="DownloadTableXLS"
                          data={data}
                          activeTab={activeTab}
                        />
                      ) : (
                        <DownloadXLSVisitor
                          id="DownloadTableXLSVisitor"
                          className="DownloadTableXLS"
                          data={dataVisitor}
                          activeTab={activeTab}
                        />
                      )} */}
                      <div
                        className="wrapperDropdownResponsive"
                        style={{
                          width: "25%",
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "0.75%",
                        }}>
                        <MDBDropdown
                          style={{ width: "100% !important" }}
                          className="dropdown-download-doc">
                          <MDBDropdownToggle
                            caret
                            color="primary"
                            className="dropdown-download font-weight-bold">
                            <div className="wrapper-selected">Download</div>
                            <div>
                              <img src={DownWhite} alt="dropdown-icons" />
                            </div>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu className="dropdown-menus dropdown-show">
                            <MDBDropdownItem
                              style={{ color: "#556ee6" }}
                              className="text-center"
                              onClick={handleDownloadPDF}>
                              PDF
                            </MDBDropdownItem>
                            <MDBDropdownItem
                              style={{ color: "#556ee6" }}
                              className="text-center"
                              onClick={handleDownloadXLS}>
                              XLS
                              {/* <ReactHTMLTableToExcel
                                id="TableXLS"
                                className="DownloadTableXLSDropdown"
                                table="DownloadTableXLS"
                                filename={`Survey Kepuasan ${activeTab}_Report_${role}`}
                                sheet="users"
                                buttonText="XLS"
                              /> */}
                            </MDBDropdownItem>
                            {activeTab == "cp" ? (
                              <DownloadXLS
                                id="DownloadTableXLS"
                                className="DownloadTableXLS"
                                data={data}
                                activeTab={activeTab}
                              />
                            ) : (
                              <DownloadXLSVisitor
                                id="DownloadTableXLSVisitor"
                                className="DownloadTableXLS"
                                data={dataVisitor}
                                activeTab={activeTab}
                              />
                            )}
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                    </MDBNav>

                    <MDBTabContent className="card" activeItem={activeTab}>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "cp" && (
                          <Col className="col-12">
                            <TableSurveyContentPartner
                              idContent={idContent}
                              handleData={handleData}
                              activeTab={activeTab}
                            />
                          </Col>
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "visitor" && (
                          <Col className="col-12">
                            <TableSurveyVisitor
                              idContent={idContent}
                              handleData={handleDataVisitor}
                              activeTab={activeTab}
                              toggleDownload={toggleDownload}
                              setToggleDownload={setToggleDownload}
                              downloadType={downloadType}
                              role={role}
                            />
                          </Col>
                        )}
                      </MDBTabPane>
                    </MDBTabContent>
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(SurveyKepuasanSummary));
