import React from "react";

import { Row, Card, Col, Spinner, CardBody, CardTitle } from "reactstrap";

// import "tui-chart/dist/tui-chart.css";
import { PieChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import Table from "./Tabel/StatistikHasilSurvey";

// STYLES
import style from "./style.module.css";
import "./toastui.scss";

// import API from "../../../../services";
var theme = {
  // series: {
  series: {
    colors: [
      "#4E72BE",
      "#F0908E",
      "#91A7D5",
      "#ced591",
      "#EBB389",
      "#D591D2",
      "#91d5ce",
      "#c22525",
      "#f0f06c",
      "#ebc51e",
    ],
    label: {
      color: "#fff",
      fontFamily: "sans-serif",
    },
  },
  // },
};

export default function CardStatistikSurvey(props) {
  const DonutChartToast = (param, title, type) => {
    const data = {
      categories: [param.question],
      series:
        param !== undefined
          ? Object.entries(param.answer).map(([key, value], i) =>
              key === ""
                ? {
                    name: "",
                    data: 0,
                  }
                : {
                    name: key,
                    data: value,
                  }
            ) ?? [
              {
                name: "",
                data: 0,
              },
            ]
          : [
              {
                name: "",
                data: 0,
              },
            ],
    };

    const options = {
      chart: {
        width: 300,
        height: 350,
        format: function (value, chartType, areaType, valuetype, legendName) {
          if (areaType === "makingSeriesLabel") {
            // formatting at series area
            value = value;
          }

          return value;
        },
      },
      series: {
        radiusRange: ["40%", "100%"],
        showLabel: true,
        labelFilter: function (labelInfo) {
          return labelInfo.ratio > 0.3; // Less than 5% does not expose.
        },
      },
      tooltip: {
        suffix: "",
      },
      legend: {
        align: "bottom",
        visible: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Statistik_Survey",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("statistikSurveyPieTheme", theme);
    options.theme = "statistikSurveyPieTheme";

    return (
      <React.Fragment>
        <PieChart data={data} options={options} theme={theme} />
      </React.Fragment>
    );
  };

  const checkColor = (index) => {
    switch (index) {
      case 1:
        return "#4E72BE";
        break;
      case 2:
        return "#F0908E";
        break;
      case 3:
        return "#91A7D5";
        break;
      case 4:
        return "#ced591";
        break;
      case 5:
        return "#EBB389";
        break;
      case 6:
        return "#D591D2";
        break;
      case 7:
        return "#91d5ce";
        break;
      case 8:
        return "#c22525";
        break;
      case 9:
        return "#f0f06c";
        break;

      default:
        break;
    }
  };

  const renderCardChart = (param) => {
    return param.map((res, i) => (
      // <Card key={i} style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }} className="my-3">
      <Card key={i} className="my-3">
        <CardTitle className={`${style.titleCardBI} ml-5 m-3`}>{res.question}</CardTitle>
        {res.type === "essay" ? (
          <CardBody>
            {/* {res.answer.map((e, index) => (
              <div key={index}>{e}</div>
            ))} */}
            <Table data={res.answer} />
          </CardBody>
        ) : (
          <CardBody>
            <Row>
              <Col
                lg="6"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {Object.keys(res.answer).length !== 0 && DonutChartToast(res)}
              </Col>
              <Col
                lg="6"
                className="d-flex align-items-center justify-content-center"
                // style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <div className="w-100">
                  {Object.entries(res.answer).map(([key, value], i) =>
                    key === "" ? (
                      ""
                    ) : (
                      <Row key={i} className="my-2">
                        <Col xs="8">
                          <span
                            className="mdi mdi-checkbox-blank-circle mr-1"
                            style={{
                              color: checkColor(i + 1),
                            }}
                          />
                          <span style={{ color: "#706F6F", fontSize: "13px" }}>{key}</span>
                        </Col>
                        <Col
                          xs="4"
                          style={{
                            color: "black",
                            fontSize: "13px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <div>{value}</div>
                        </Col>
                      </Row>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    ));
  };

  console.log(props.data);

  return (
    <div>
      <Row className="my-3">
        <Col md="12">
          <div className="p-3">
            {props.data && props.data.length !== 0 ? renderCardChart(props.data) : ""}
          </div>
        </Col>
      </Row>
    </div>
  );
}
