import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBCardBody,
  MDBModalBody,
  MDBCardTitle,
  MDBCard,
  MDBDataTable,
} from "mdbreact";

import API from "../../../services";

// import "./style.css";
import moment from "moment";
import style from "./style.module.css";
import "./style.scss";
import "./style.css";
import { Button, Input, Row } from "reactstrap";

import Skeleton from "react-loading-skeleton";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import _ from "lodash";

const ModalDetail = (props) => {
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterTitle, setFilterTitle] = useState("Semua Konten");
  const [kontenList, setKontenList] = useState([]);

  const getDataDetail = () => {
    const newData = props.dataDetail.filter((e, i) => {
      return (
        e.data.count !== 0 &&
        e.data.data.length !== 0 &&
        e.data.data[0] &&
        e.data.data[0].date === props.date
      );
    });
    if (newData.length !== 0) {
      setData(
        newData[0].data.data.map((e, i) => ({
          no: i + 1,
          judul: e ? (e.news ? e.news.title ?? "-" : "-") : "-",
          org: e
            ? e.news
              ? e.news.requested_by
                ? e.news.requested_by.organization
                  ? e.news.requested_by.organization.name ?? "-"
                  : "-"
                : "-"
              : "-"
            : "-",
          jam: e.time ?? "-",
          durasi: e ? (e.news ? e.news.proposed_duration ?? "-" : "-") : "-",
          total_impresi: e.impress ?? "-",
          total_klik: e.click ?? "-",
          total_dfp: e.unique_dfp ?? "-",
        }))
      );
      setKontenList(
        _.uniq(newData[0].data.data.map((e, i) => (e ? (e.news ? e.news.title ?? "-" : "-") : "-")))
      );
    }
    // const param = {
    //   province: props.idProvince,
    //   type: props.activeTab === "1" ? "news" : props.activeTab === "2" ? "banner" : "news",
    //   date: props.date,
    //   ai: props.lokasi,
    // };
    // setLoading(true);
    // API.getKontenReportDetail(param)
    //   .then((res) => {
    //     if (res.data.success) {
    //       setData(
    //         res.data.data.map((e, i) => ({
    //           no: i + 1,
    //           judul: e
    //             ? e.news
    //               ? e.news.details
    //                 ? e.news.details.title ?? "-"
    //                 : "-"
    //               : "-"
    //             : "-",
    //           org: e
    //             ? e.news
    //               ? e.news.requested_by
    //                 ? e.news.requested_by.organization
    //                   ? e.news.requested_by.organization.name ?? "-"
    //                   : "-"
    //                 : "-"
    //               : "-"
    //             : "-",
    //           jam: e.time ?? "-",
    //           durasi: e ? (e.news ? e.news.proposed_duration ?? "-" : "-") : "-",
    //           total_impresi: e.impress ?? "-",
    //           total_klik: e.click ?? "-",
    //           total_dfp: e.unique_dfp ?? "-",
    //         }))
    //       );
    //     setKontenList(
    //       _.uniq(
    //         res.data.data.map((e, i) =>
    //           e ? (e.news ? (e.news.details ? e.news.details.title ?? "-" : "-") : "-") : "-"
    //         )
    //       )
    //     );
    //     setLoading(false);
    //   } else {
    //     setData([]);
    //     setLoading(false);
    //   }
    // })
    // .catch((e) => {
    //   setData(null);
    //   setLoading(false);
    // });
  };

  const dataDetail = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Judul Konten",
        field: "judul",
        sort: "asc",
        width: 50,
      },
      {
        label: "Organisasi",
        field: "org",
        sort: "asc",
        width: 100,
      },
      {
        label: "Jam",
        field: "jam",
        sort: "asc",
        width: 150,
      },
      {
        label: "Durasi",
        field: "durasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Impresi",
        field: "total_impresi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Klik",
        field: "total_klik",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Pengguna Unik",
        field: "total_dfp",
        sort: "asc",
        width: 150,
      },
    ],

    rows: !data
      ? [
          {
            judul: [
              <Row key="a" className="justify-content-md-center">
                Terjadi Kesalahan
              </Row>,
            ],
          },
        ]
      : filterTitle !== "Semua Konten"
      ? dataFilter.map((e) => {
          return {
            ...e,
            total_dfp: e.total_dfp.length,
          };
        })
      : data.map((e) => {
          return {
            ...e,
            total_dfp: e.total_dfp.length,
          };
        }),
  };

  useEffect(() => {
    if (props.idProvince && props.activeTab && props.date && props.lokasi) {
      setFilterTitle("Semua Konten");
      if (props.dataDetail.length !== 0) {
        getDataDetail();
      }
    }
  }, [props.idProvince, props.activeTab, props.date, props.lokasi, props.dataDetail]);

  useEffect(() => {
    if (filterTitle !== "Semua Konten") {
      const res = data.filter((e) => e.judul.includes(filterTitle));
      setDataFilter(res);
    }
  }, [filterTitle]);

  return (
    <MDBContainer>
      <MDBModal
        // id="modal-content-detail"
        isOpen={props.modal}
        toggle={props.toggle}
        size="lg"
        className="modal-xl"
        centered={true}>
        <MDBCard>
          <div className="w-100" style={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              onClick={props.toggle}
              style={{ cursor: "pointer" }}
              className="mdi mdi-window-close m-2"></span>
          </div>
          <MDBCardTitle>
            <div
              className={`${style.titleMenuBI} row mb-3 mt-1`}
              style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
              <div className="col-4">DETAIL KONTEN BERITA</div>
            </div>
            <div
              className={` text-center my-4 row`}
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}>
              <div className="col-4">{props.province}</div>
              <div className="col-4">{props.lokasi}</div>
              <div className="col-4">{props.date}</div>
            </div>
          </MDBCardTitle>
          <MDBCardBody>
            <div style={{ minHeight: "70vh" }}>
              {loading ? (
                <div>
                  <h1>
                    <Skeleton />
                  </h1>
                  <Skeleton count={10} />
                </div>
              ) : (
                <div>
                  <div
                    className="row my-3"
                    style={{ fontWeight: "bold", display: "flex", justifyContent: "center" }}>
                    <div className="col">
                      <Autocomplete
                        // classes={style.dropdownAutocomplete}
                        id="combo-box-demo"
                        options={kontenList !== null ? kontenList : []}
                        defaultValue={filterTitle}
                        value={filterTitle}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        onChange={(e, value) => {
                          value !== null ? setFilterTitle(value) : setFilterTitle("Semua Konten");
                        }}
                        renderOption={(option) => (
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "12px",
                              }}>
                              <div>{option}</div>
                            </div>
                          </React.Fragment>
                        )}
                        // style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            // value={dataTitle}
                            placeholder="Cari Lokasi..."
                          />
                        )}
                      />
                    </div>
                    <div className="col">
                      <div>Total Impresi</div>
                      {filterTitle !== "Semua Konten" ? (
                        <div>
                          {dataFilter && dataFilter.length !== 0
                            ? dataFilter.reduce((acc, curr) => acc + curr.total_impresi, 0)
                            : ""}
                        </div>
                      ) : (
                        <div>
                          {data && data.length !== 0
                            ? data.reduce((acc, curr) => acc + curr.total_impresi, 0)
                            : ""}
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <div>Total Klik</div>
                      <div>
                        {filterTitle !== "Semua Konten" ? (
                          <div>
                            {dataFilter && dataFilter.length !== 0
                              ? dataFilter.reduce((acc, curr) => acc + curr.total_klik, 0)
                              : ""}
                          </div>
                        ) : (
                          <div>
                            {data && data.length !== 0
                              ? data.reduce((acc, curr) => acc + curr.total_klik, 0)
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div>Total Pengguna Unik</div>
                      {filterTitle !== "Semua Konten" ? (
                        <div>
                          {dataFilter && dataFilter.length !== 0
                            ? _.uniq(_.flatten(dataFilter.map((e) => e.total_dfp))).length
                            : ""}
                        </div>
                      ) : (
                        <div>
                          {data && data.length !== 0
                            ? _.uniq(_.flatten(data.map((e) => e.total_dfp))).length
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <MDBDataTable
                      id="modalDetailReport"
                      noBottomColumns={true}
                      className="text-center"
                      // responsive
                      // disableRetreatAfterSorting
                      // striped
                      // bordered
                      data={dataDetail}
                      displayEntries={false}
                      // entriesOptions={[5, 10, 15, 20]}
                      // pagesAmount={5}
                      // entries={10}
                      paging={false}
                      searching={false}
                      scrollX
                      scrollY
                      maxHeight="400px"
                    />
                  </div>
                </div>
              )}
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalDetail;
