import React, { useRef, useState } from "react";
// import { useDispatch } from 'react-redux';
import styles from "../../Assets/css/header.module.css";
import logo from "../../Assets/image/BAKTI.png";

// import { loginAction } from '../../config/redux/actions'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link, useHistory } from "react-router-dom";
import {
    Collapse,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Navbar,
    NavbarToggler,
    Nav,
    NavLink,
    NavItem,
    NavbarBrand,
    Row,
    Col,
    Alert,
    FormText
} from 'reactstrap';

import FB from '../../Assets/image/IconFacebook.svg'
import IG from '../../Assets/image/IconIG.svg'
import Twitter from '../../Assets/image/IconTwitter.svg'
import API from "../../../services";

function Header(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    // const [email, setEmail] = useState("")
    const [errorUsername, setErrorUsername] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setOpen] = useState(false);
    // const [alertSuccess, setAlertSuccess] = useState("")
    const [alertFail, setAlertFail] = useState("")
    const [error, setError] = useState("");
    const [noMatch, setNoMatch] = useState("");
    const [visible, setVisible] = useState(true);
    const history = useHistory();

    const [lupaPass, setLupaPass] = useState(false);

    //alert
    const [alertLogin, setAlertLogin] = useState(false);
  //notif
    const [notifLogin, setNotifLogin] = useState(false);

    // const username = useSelector(state =>  state.auth.username)
    // const dispatch = useDispatch()
    const onDismiss = () => setVisible(false);
    const toggle = () => setOpen(!dropdownOpen);
    const isToggle = () => setIsOpen((prevState) => !prevState);

    // function handleValidSubmit(event, values) {
    //     props.loginUser(values, props.history);
    // }

    const onButtonClick = () => {

        if (username === ""){
            setErrorUsername("Username Tidak Boleh Kosong")
        }
        if (password === ""){
            setErrorPassword("Password Tidak Boleh Kosong")
        }

        if ( username !== "" && password !== "" ){
            API.postLogin({
                username: username,
                password: password
            }).then(function(response){

                console.log("ini",response.data.success);
                // console.log(response.status)
                if(response.data.success === true){
                    // console.log(response)
                    window.localStorage.setItem("role", response.data.data.organization.role.description);
                    window.localStorage.setItem('users', response.data.data.username)
                    window.localStorage.setItem('idUser', response.data.data._id)
                    window.localStorage.setItem('kateg', response.data.data.kategori)
                    window.localStorage.setItem('selectKateg', response.data.data.kategori[0])
                    window.localStorage.setItem('org', response.data.data.organization._id)
                    window.localStorage.setItem('orgpt', response.data.data.organization.name)
                    window.localStorage.setItem('email', response.data.data.email)
                    // dispatch(loginAction({ username, password }))
                    history.push('/home')
                    // alert('Login Berhasil', response.data.message)
                } if (response.data.success === false){
                    // console.log("400",response.data.err);
                    setAlertLogin(false);
                    setNotifLogin(true);
                    setAlertFail(response.data.err)
                    setTimeout(() => {
                        setNotifLogin(false);
                    }, 3000);
                }
                setErrorUsername("")
                setErrorPassword("")
                setNoMatch("")
            }).catch(() => {
                
            })
        }
    }

    const handleLupaPass = () => {
        // const emailuser = emailref.current.value
        setLupaPass(!lupaPass)
    };

    const hadleKeyPress = (e) => {
        if (e.key === "Enter") {
            onButtonClick();
        }
        // console.log(response)
    }

    const renderNav = () => {
        return (
            <Nav className={styles.navlistbar} navbar>
                <NavItem className={styles.listnav}>
                    <NavLink className={styles.titnav} tag={Link} to="/homepages">BERANDA</NavLink>
                </NavItem>
                <p className={styles.listnav}>
                    <a className={styles.titnav} href="http://202.182.55.252/faq/">FAQ</a>
                </p>
                <NavItem className={styles.listnav}>
                    <NavLink className={styles.titnav} tag={Link} to="/hubungi">HUBUNGI KAMI</NavLink>
                </NavItem>
                <NavItem className={styles.listnavbtn}>
                    <NavLink tag={Link} to="/register-cp">
                        <button className={styles.btnDaftar}>DAFTAR SEBAGAI CONTENT PARTNER</button>
                    </NavLink>
                </NavItem>
                <NavItem className={styles.listnavbtn}>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={styles.btnMasuk}>
                        <DropdownToggle color="" className={styles.btnMask}> MASUK </DropdownToggle>
                        <DropdownMenu className={styles.loginPages} >
                            {!lupaPass ?
                                <div>
                                    <div className="d-flex justify-content-center mb-2">
                                        <FormText>{noMatch}</FormText>
                                        {/* {
                                            alertFail === "" ? "" : 
                                            <Alert color="danger" isOpen={visible} toggle={onDismiss}> Username dan Password salah ! </Alert>
                                        } */}
                                        <Alert
                                            color={alertLogin ? "success" : "danger"}
                                            isOpen={notifLogin}
                                            style={{
                                                width: "100%",
                                                borderRadius: "6px",
                                                textAlign: "center",
                                                padding: "5px",
                                                margin: "0px",
                                            }}>
                                            {alertLogin ? `` : `Username atau Password Salah`}
                                        </Alert>
                                    </div>
                                    <AvForm className={styles.formUs}>
                                        <div className={styles.secTit}> <span style={{ justifyContent: "center" }}>Silakan Masuk Di Sini</span></div>
                                        <div className={styles.inputUsername}> <label>Username</label> </div>
                                        <AvField
                                            onChange={(e) => { setUsername(e.target.value) }}  
                                            className={styles.fieldUs} 
                                            type="text"
                                            name="username"
                                            id="Usernmae"
                                            placeholder="Nama Akun Anda" 
                                            required
                                        />
                                        <FormText color="danger">{errorUsername}</FormText>
                                        <div className={styles.inputPass}><label>Password</label></div>
                                        <AvField 
                                            onChange={(e) => { setPassword(e.target.value) }} 
                                            className={styles.fieldUs} 
                                            type="password"
                                            name="password"
                                            id="password"
                                            onKeyPress={hadleKeyPress} 
                                            placeholder="Kata Sandi Anda" 
                                            required
                                        />
                                        <FormText color="danger">{errorPassword}</FormText>
                                    </AvForm>
                                    <div className="d-flex justify-content-center my-3">
                                        <button className="btn btn-success btn-block" style={{ backgroundColor: "#00438B" }} type="submit" onClick={onButtonClick}>Login</button>
                                    </div>
                                    <div className="d-flex justify-content-center my-3">
                                        <span className={styles.forgotPass}> <a href="#" onClick={handleLupaPass}>Lupa Password</a></span>
                                    </div>
                                </div>
                                :
                                <div>
                                    <AvForm className={styles.formUs}>
                                        <div className={styles.secTit}> <span style={{ justifyContent: "center" }}>Lupa Password</span></div>
                                        <div className={styles.inputUsername}> <label>Email</label> </div>
                                        <AvField className={styles.fieldUs} type="text" name="email" id="email" placeholder="Masukan Email" />
                                    </AvForm>
                                    <div className="d-flex justify-content-center my-3">
                                        <button className="btn btn-success btn-block" style={{ backgroundColor: "#00438B" }} type="submit">Submit</button>
                                    </div>
                                    <div className="d-flex justify-content-center my-3">
                                        <span className={styles.forgotPass}> <a href="#" onClick={handleLupaPass}>Login</a></span>
                                    </div>
                                </div>
                            }
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
                <Row className={styles.listnavbtnIcon}>
                    <Col xs="4" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <NavItem className={styles.listnavbtn}><a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer"><img className={styles.iconSosmed} src={FB} alt="FB"/></a></NavItem>
                    </Col>
                    <Col xs="4" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <NavItem className={styles.listnavbtn}><a href='https://www.instagram.com/baktionline.id/' target="_blank" rel="noopener noreferrer"><img className={styles.iconSosmed} src={IG} alt="IG"/></a></NavItem>
                    </Col>
                    <Col xs="4" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <NavItem className={styles.listnavbtn}><a href='https://twitter.com/baktionline' target="_blank" rel="noopener noreferrer"><img className={styles.iconSosmed} src={Twitter} alt="Twitter"/></a></NavItem>
                    </Col>
                </Row>
            </Nav>
        )
    }

    return (
        <div>
            <Navbar className={styles.navHead} color="" light expand="md">
                <NavbarBrand className={styles.navlogo} tag={Link} to="/">
                    <img src={logo} alt="logo navbar homepages" />
                </NavbarBrand>
                <NavbarToggler onClick={isToggle} />
                <Collapse isOpen={isOpen} navbar>
                    {renderNav()}
                </Collapse>
            </Navbar>
        </div>
    );
}

// export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(Header));
export default Header;


{/* <DropdownMenu className={styles.loginPages} right>
                                <form className={styles.formUs}>
                                    <div className={styles.secTit}> <span style={{ justifyContent: "center" }}>Silakan Masuk Di Sini</span></div>
                                    <div className={styles.inputUsername}> <label>Username</label> </div>
                                        <input ref={usernameRef} className={styles.fieldUs} type="text" placeholder="Nama Akun Anda" />
                                    <div className={styles.inputPass}><label>Password</label></div>
                                        <input ref={passwordRef} className={styles.fieldUs} type="password" onKeyPress={hadleKeyPress} placeholder="Kata Sandi Anda" />
                                </form>
                                    <div className="d-flex justify-content-center my-3">
                                        <button className="btn btn-success btn-block" style={{ backgroundColor: "#00438B" }} type="submit" onClick={onButtonClick}>Login</button>
                                    </div>
                                    <div className="d-flex justify-content-center my-3">
                                        <span className={styles.forgotPass}> <a href="#">Lupa Password</a></span>
                                    </div>
                            </DropdownMenu> */}
