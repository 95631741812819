import React, { useEffect, useState } from "react";
import { Row, Card, Col } from "reactstrap";
import API from "../../../../services";
import "./CardHome.css";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { formatDuration, intervalToDuration } from "date-fns";

export default function CardAverage(props) {
  let history = useHistory();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  // convert interval minutes
  // function convertAverage(interval) {
  //   return intervalToDuration({ start: 0, end: interval * 1000 });
  // }
  const fetchAPI = () => {
    API.getTimeIssueAverage()
      .then((res) => {
        if (res !== undefined) {
          let json = res.data.data;
          setLoading(false);
          setData(json.averageHours);
        } else {
          setLoading(false);
          setData([]);
        }
      })
      .catch((err) => {
        props.setError("Gagal Memuat Data");
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchAPI();
  }, []);
  // untuk loading skeleton
  const skeletonCostume = <Skeleton count={3} />;
  return (
    <div className="mx-auto my-5">
      <Row className="d-flex justify-content-center">
        <div className="wrap-content-partner-cp">
          <div className="title-total">WAKTU AVERAGE ISSUE DIJAWAB</div>
          <div className="total-content">{loading ? skeletonCostume : data ? data : "0"}</div>
        </div>
      </Row>
    </div>
  );
}
