import React, { useState, useEffect } from "react";
import Header from "../../components/VerticalLayout/Header";
// import {
//   Container,
//   Card,
//   CardBody,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   FormText,
//   Row,
//   Col,
//   Button,
// } from "reactstrap";

import ArticleTotal from "../../components/Admin/Card/ArticleTotal";
import InfoVideo from "../../components/Admin/Card/InfoVideo";
import TableArticle from "../../components/Admin/Table/TableArticle";
import TableNewsletter from "../../components/Admin/Table/TableNewsletter";
import TableTotalShare from "../../components/Admin/Table/TableTotalShare";
import API from "../../services";

export default function Video(props) {
  const [dataDashboard, setDataDashboard] = useState({});
  const [loading, setLoading] = useState(true);
  const contentType = "video";

  const getDashboard = () => {
    API.getDashoardArticle()
      .then((response) => {
        if (response.status === 200) {
          setDataDashboard(response.data);
          setLoading(false);
        }
      })
      .catch(setLoading(true));
  };

  const getDashboardVideo = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (contentType === "video") {
      getDashboardVideo();
      // getDashboard(); // video punya
    } else {
      getDashboard();
    }
  }, []);

  return (
    <div>
      <Header title="Video (Homepage)" />
      <ArticleTotal typeContent="video" data={dataDashboard} loading={loading} />
      <InfoVideo typeContent="video" loading={loading} />
      <TableArticle typeContent="video" role="adminartikel" />
      <TableNewsletter typeContent="video" />
      <TableTotalShare typeContent="video" />
    </div>
  );
}
