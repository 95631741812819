import React, { useEffect, useState } from "react";
import Map from "./MapsVector";
import {
  Spinner,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Col,
  CardBody,
  Card,
} from "reactstrap";

import { useSelector } from "react-redux";

export default function CardMap(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);
  const [prov, setProv] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_provinsi
      : props.data && props.data.length !== 0
      ? props.data[0].province ?? ""
      : ""
  );
  const [idRegion, setIdRegion] = useState("");

  const onclikRegion = (param) => {
    switch (param) {
      case "ID_pa":
        setProv("PAPUA");
        props.setIdProvince("5c08b0452f1e2050e0c6df14");
        break;
      case "ID_pb":
        setProv("PAPUA BARAT");
        props.setIdProvince("5c08ad1f2f1e2050e0c6de2c");
        break;
      case "ID_ma":
        setProv("MALUKU");
        props.setIdProvince("5c089dcd2f1e2050e0c6dc5f");
        break;
      case "ID_mu":
        setProv("MALUKU UTARA");
        props.setIdProvince("5c0897752f1e2050e0c6dc35");
        break;
      case "ID_nt":
        setProv("NUSA TENGGARA TIMUR");
        props.setIdProvince("5c08a8b82f1e2050e0c6dce1");
        break;
      case "ID_nb":
        setProv("NUSA TENGGARA BARAT");
        props.setIdProvince("5c04a81b21fb6a653aaab2a5");
        break;
      case "ID_sa":
        setProv("SULAWESI UTARA");
        props.setIdProvince("5c08dcf72f1e2050e0c6e55e");
        break;
      case "ID_st":
        setProv("SULAWESI TENGAH");
        props.setIdProvince("5c08c8322f1e2050e0c6e21b");
        break;
      case "ID_sr":
        setProv("SULAWESI BARAT");
        props.setIdProvince("5c08cd192f1e2050e0c6e2d9");
        break;
      case "ID_sn":
        setProv("SULAWESI SELATAN");
        props.setIdProvince("5c08cf2f2f1e2050e0c6e325");
        break;
      case "ID_sg":
        setProv("SULAWESI TENGGARA");
        props.setIdProvince("5c08d80e2f1e2050e0c6e471");
        break;
      case "ID_go":
        setProv("GORONTALO");
        props.setIdProvince("5c0748772f1e2050e0c6c0a9");
        break;
      case "ID_ba":
        setProv("BALI");
        props.setIdProvince("5c04de111143c12c8bdbc9c4");
        break;
      case "ID_jt":
        setProv("JAWA TENGAH");
        props.setIdProvince("5c079ff62f1e2050e0c6cd4c");
        break;
      case "ID_jl":
        setProv("JAWA TIMUR");
        props.setIdProvince("5c07d086aea8f971c78f62d6");
        break;
      case "ID_jb":
        setProv("JAWA BARAT");
        props.setIdProvince("5c077c892f1e2050e0c6c50f");
        break;
      case "ID_yo":
        setProv("DI YOGYAKARTA");
        props.setIdProvince("5c065e5a2f1e2050e0c6bd55");
        break;
      case "ID_jk":
        setProv("DKI JAKARTA");
        props.setIdProvince("5c073c0b2f1e2050e0c6bf64");
        break;
      case "ID_bt":
        setProv("BANTEN");
        props.setIdProvince("5c06068b2f1e2050e0c6b038");
        break;
      case "ID_ki":
        setProv("KALIMANTAN TIMUR");
        props.setIdProvince("5c0888a62f1e2050e0c6da0f");
        break;
      case "ID_ku":
        setProv("KALIMANTAN UTARA");
        props.setIdProvince("5c088ac22f1e2050e0c6da81");
        break;
      case "ID_kb":
        setProv("KALIMANTAN BARAT");
        props.setIdProvince("5c07d0af2f1e2050e0c6d814");
        break;
      case "ID_kt":
        setProv("KALIMANTAN TENGAH");
        props.setIdProvince("5c0885d32f1e2050e0c6d978");
        break;
      case "ID_ks":
        setProv("KALIMANTAN SELATAN");
        props.setIdProvince("5c0882342f1e2050e0c6d8d1");
        break;
      case "ID_bb":
        setProv("KEPULAUAN BANGKA BELITUNG");
        props.setIdProvince("5c088d0d2f1e2050e0c6dabc");
        break;
      case "ID_be":
        setProv("BENGKULU");
        props.setIdProvince("5c0637062f1e2050e0c6b6e1");
        break;
      case "ID_la":
        setProv("LAMPUNG");
        props.setIdProvince("5c088faa2f1e2050e0c6db41");
        break;
      case "ID_ss":
        setProv("SUMATERA SELATAN");
        props.setIdProvince("5c08dd62aea8f971c78f66f4");
        break;
      case "ID_sb":
        setProv("SUMATERA BARAT");
        props.setIdProvince("5c08d5e6aea8f971c78f662c");
        break;
      case "ID_su":
        setProv("SUMATERA UTARA");
        props.setIdProvince("5c08e3e72f1e2050e0c6e619");
        break;
      case "ID_ja":
        setProv("JAMBI");
        props.setIdProvince("5c075a6c2f1e2050e0c6c3d9");
        break;
      case "ID_ri":
        setProv("RIAU");
        props.setIdProvince("5c08c3c92f1e2050e0c6e162");
        break;
      case "ID_kr":
        setProv("KEPULAUAN RIAU");
        props.setIdProvince("5c088e5c2f1e2050e0c6daf3");
        break;
      case "ID_ac":
        setProv("ACEH");
        props.setIdProvince("5c048b737fddb13f493b0cbc");
        break;

      default:
        break;
    }
    setIdRegion(param);
  };

  const selectedDropdownProv = (param, idProv) => {
    // props.setIdProvince(idProv);
    switch (param) {
      case "PAPUA":
        setIdRegion("ID_pa");
        break;
      case "PAPUA BARAT":
        setIdRegion("ID_pb");
        break;
      case "MALUKU":
        setIdRegion("ID_ma");
        break;
      case "MALUKU UTARA":
        setIdRegion("ID_mu");
        break;
      case "NUSA TENGGARA TIMUR":
        setIdRegion("ID_nt");
        break;
      case "NUSA TENGGARA BARAT":
        setIdRegion("ID_nb");
        break;
      case "SULAWESI UTARA":
        setIdRegion("ID_sa");
        break;
      case "SULAWESI TENGAH":
        setIdRegion("ID_st");
        break;
      case "SULAWESI BARAT":
        setIdRegion("ID_sr");
        break;
      case "SULAWESI SELATAN":
        setIdRegion("ID_sn");
        break;
      case "SULAWESI TENGGARA":
        setIdRegion("ID_sg");
        break;
      case "GORONTALO":
        setIdRegion("ID_go");
        break;
      case "BALI":
        setIdRegion("ID_ba");
        break;
      case "JAWA TENGAH":
        setIdRegion("ID_jt");
        break;
      case "JAWA TIMUR":
        setIdRegion("ID_jl");
        break;
      case "JAWA BARAT":
        setIdRegion("ID_jb");
        break;
      case "DI YOGYAKARTA":
        setIdRegion("ID_yo");
        break;
      case "DKI JAKARTA":
        setIdRegion("ID_jk");
        break;
      case "BANTEN":
        setIdRegion("ID_bt");
        break;
      case "KALIMANTAN TIMUR":
        setIdRegion("ID_ki");
        break;
      case "KALIMANTAN UTARA":
        setIdRegion("ID_ku");
        break;
      case "KALIMANTAN BARAT":
        setIdRegion("ID_kb");
        break;
      case "KALIMANTAN TENGAH":
        setIdRegion("ID_kt");
        break;
      case "KALIMANTAN SELATAN":
        setIdRegion("ID_ks");
        break;
      case "KEPULAUAN BANGKA BELITUNG":
        setIdRegion("ID_bb");
        break;
      case "BENGKULU":
        setIdRegion("ID_be");
        break;
      case "LAMPUNG":
        setIdRegion("ID_la");
        break;
      case "SUMATERA SELATAN":
        setIdRegion("ID_ss");
        break;
      case "SUMATERA BARAT":
        setIdRegion("ID_sb");
        break;
      case "SUMATERA UTARA":
        setIdRegion("ID_su");
        break;
      case "JAMBI":
        setIdRegion("ID_ja");
        break;
      case "RIAU":
        setIdRegion("ID_ri");
        break;
      case "KEPULAUAN RIAU":
        setIdRegion("ID_kr");
        break;
      case "ACEH":
        setIdRegion("ID_ac");
        break;

      default:
        break;
    }
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const dropdownProvinces = () => {
    return (
      <Dropdown className="dropdown-provinsi-select" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          style={{
            minWidth: "100%",
            backgroundColor: "white",
            borderRadius: "25px",
            borderColor: "#C42127",
            borderWidth: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}>
          <div
            style={{
              color: "#C42127",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              fontSize: "15px",
            }}>
            <span>{prov}</span>
            <i className="mdi mdi-24px mdi-chevron-down" style={{ color: "#C42127" }} />
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="mx-auto"
          style={{ maxHeight: "300px", width: "100%", overflowY: "auto", borderRadius: "15px" }}>
          {props.data && props.data.length !== 0 ? (
            props.data.map((a, i) => (
              <DropdownItem
                key={i}
                onClick={() => {
                  setProv(a.province);
                  selectedDropdownProv(a.province);
                }}>
                <div className="text-center">
                  <span style={{ color: "#C42127", fontSize: "15px" }}> {a.province}</span>
                </div>
              </DropdownItem>
            ))
          ) : (
            <DropdownItem>
              <div className="text-center">
                <span style={{ color: "#C42127", fontSize: "15px" }}>No Data</span>
              </div>
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  };
  const filterData =
    props.data &&
    props.data.filter((e) => {
      return e.province == prov;
    });

  const getProvMap = () => {
    // props.setIdProvince(idProv);
    switch (prov) {
      case "PAPUA":
        setIdRegion("ID_pa");
        break;
      case "PAPUA BARAT":
        setIdRegion("ID_pb");
        break;
      case "MALUKU":
        setIdRegion("ID_ma");
        break;
      case "MALUKU UTARA":
        setIdRegion("ID_mu");
        break;
      case "NUSA TENGGARA TIMUR":
        setIdRegion("ID_nt");
        break;
      case "NUSA TENGGARA BARAT":
        setIdRegion("ID_nb");
        break;
      case "SULAWESI UTARA":
        setIdRegion("ID_sa");
        break;
      case "SULAWESI TENGAH":
        setIdRegion("ID_st");
        break;
      case "SULAWESI BARAT":
        setIdRegion("ID_sr");
        break;
      case "SULAWESI SELATAN":
        setIdRegion("ID_sn");
        break;
      case "SULAWESI TENGGARA":
        setIdRegion("ID_sg");
        break;
      case "GORONTALO":
        setIdRegion("ID_go");
        break;
      case "BALI":
        setIdRegion("ID_ba");
        break;
      case "JAWA TENGAH":
        setIdRegion("ID_jt");
        break;
      case "JAWA TIMUR":
        setIdRegion("ID_jl");
        break;
      case "JAWA BARAT":
        setIdRegion("ID_jb");
        break;
      case "DI YOGYAKARTA":
        setIdRegion("ID_yo");
        break;
      case "DKI JAKARTA":
        setIdRegion("ID_jk");
        break;
      case "BANTEN":
        setIdRegion("ID_bt");
        break;
      case "KALIMANTAN TIMUR":
        setIdRegion("ID_ki");
        break;
      case "KALIMANTAN UTARA":
        setIdRegion("ID_ku");
        break;
      case "KALIMANTAN BARAT":
        setIdRegion("ID_kb");
        break;
      case "KALIMANTAN TENGAH":
        setIdRegion("ID_kt");
        break;
      case "KALIMANTAN SELATAN":
        setIdRegion("ID_ks");
        break;
      case "KEPULAUAN BANGKA BELITUNG":
        setIdRegion("ID_bb");
        break;
      case "BENGKULU":
        setIdRegion("ID_be");
        break;
      case "LAMPUNG":
        setIdRegion("ID_la");
        break;
      case "SUMATERA SELATAN":
        setIdRegion("ID_ss");
        break;
      case "SUMATERA BARAT":
        setIdRegion("ID_sb");
        break;
      case "SUMATERA UTARA":
        setIdRegion("ID_su");
        break;
      case "JAMBI":
        setIdRegion("ID_ja");
        break;
      case "RIAU":
        setIdRegion("ID_ri");
        break;
      case "KEPULAUAN RIAU":
        setIdRegion("ID_kr");
        break;
      case "ACEH":
        setIdRegion("ID_ac");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getProvMap();
    props.setProvince(prov);
  }, [prov]);

  // console.log(
  //   "aaaaa",
  //   Math.max(Object.values(filterData.length !== 0 ? filterData[0].impress ?? 0 : 0))
  // );

  return (
    <div className="mt-2">
      <Row className="d-flex justify-content-center mb-3">
        <Col xs="12" md="8" lg="4">
          {dropdownProvinces()}
        </Col>
      </Row>
      <div className="p-2 mx-3 border" style={{ borderRadius: "13px" }}>
        <Row className="d-flex justify-content-end">
          <Col xs="9" lg="6">
            <Card
              className="p-1"
              style={{
                zIndex: "99",
                position: "absolute",
                right: "5%",
                minWidth: "70%",
                border: "2px solid",
                borderRadius: "7px",
                borderColor: "#2C3280",
                minHeight: "100px",
                // opacity:'0.5'
              }}>
              <CardBody>
                {/* <CardTitle className="text-center">TOTAL {props.title.toUpperCase()}</CardTitle> */}
                {props.totalImpressiCheckBox && (
                  <Row>
                    <Col xs="8">Total Impressi</Col>
                    <Col xs="4">
                      {filterData.length !== 0 ? filterData[0].total_impress ?? 0 : 0}
                    </Col>
                  </Row>
                )}
                {props.maksImpressiCheckBox && (
                  <Row>
                    <Col xs="8">Maks Impressi</Col>
                    <Col xs="4">
                      {filterData.length !== 0 ? filterData[0].max_impressi ?? 0 : 0}
                    </Col>
                  </Row>
                )}
                {props.totalKlikCheckBox && (
                  <Row>
                    <Col xs="8">Total Klik</Col>
                    <Col xs="4">
                      {filterData.length !== 0 ? filterData[0].total_clicked ?? 0 : 0}
                    </Col>
                  </Row>
                )}
                {props.maksKlikCheckBox && (
                  <Row>
                    <Col xs="8">Maks Klik</Col>
                    <Col xs="4">{filterData.length !== 0 ? filterData[0].max_clicked ?? 0 : 0}</Col>
                  </Row>
                )}
                {props.totalDFPCheckBox && (
                  <Row>
                    <Col xs="8">Total DFP</Col>
                    <Col xs="4">{filterData.length !== 0 ? filterData[0].unique_dfp ?? 0 : 0}</Col>
                  </Row>
                )}
                {/* {props.totalDeviceCheckBox && (
                  <Row>
                    <Col xs="8">Total Device</Col>
                    <Col xs="4">54</Col>
                  </Row>
                )} */}

                {/* <Row className="justify-content-center  ">
                  {props.loading ? (
                    <div className="my-2">
                      <Spinner size="10px" color="primary" size="sm" />
                    </div>
                  ) : (
                    <CardTitle
                      style={{ color: "#2C3280", fontSize: "18px" }}
                      className="text-center my-2">
                      {props.data ? props.data.count ?? 0 : 0}
                    </CardTitle>
                  )}
                </Row> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* MAP VECTOR */}
        <div style={{ zIndex: "98" }}>
          <Map onclikRegion={onclikRegion} idRegion={idRegion} />
        </div>
      </div>
    </div>
  );
}
