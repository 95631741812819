import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// import Home from "../pages/Home/kurator";
// import UserProfile from "../pages/Authentication/user-profile"
// import KontenTayangKurator from "../pages/KontenTayang/Kurator";
// import KontenKurator from "../pages/Konten/Kurator";
// import ReviewKontenKurator from "../pages/ReviewKontenKurator/Kurator";
// import userProfile from "../pages/Authentication/user-profile";
import Home from "../pagesAsync/HomeAsync/KuratorAsync";
import KontenTayangKurator from "../pagesAsync/KontenTayangAsync/KuratorAsync";
import KontenKurator from "../pagesAsync/KontenAsync/KuratorAsync";
import ReviewKontenKurator from "../pagesAsync/ReviewKontenKurator/Kurator";
import userProfile from "../pagesAsync/AuthenticationAsync/UserProfileAsync";

const AppKurator = (props) => {
  return (
    <Switch>
      <Redirect exact from={`${props.match.url}`} to={`${props.match.url}kurator/home`} />
      <Route exeact path={`${props.match.url}kurator/home`} component={Home} />
      <Route exeact path={`${props.match.url}kurator/profile`} component={userProfile} />
      <Route exeact path={`${props.match.url}kurator/konten`} component={KontenKurator} />
      <Route
        exeact
        path={`${props.match.url}kurator/konten-tayang`}
        component={KontenTayangKurator}
      />
      <Route
        exeact
        path={`${props.match.url}kurator/review-konten`}
        component={ReviewKontenKurator}
      />
      <Redirect to="/" />
    </Switch>
  );
};

export default AppKurator;
