import React, { useState, useEffect } from "react";
import { MDBContainer, MDBModal, MDBCardBody, MDBCardTitle, MDBCard } from "mdbreact";

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { DateRangePicker } from "react-date-range";
// ------------------REDUX---------------------
import moment from "moment";

import { Button, Spinner, Col } from "reactstrap";

// ASSETS
import iconDown from "../../../assets/icon/back.png";
// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "../Download/XLS/DownloadAllDetailSummarySurvey";

import _ from "lodash";
import API from "../../../services";
import "./style.css";

const ModalDetail = (props) => {
  const [data, setData] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // const [dataTitle, setDataTitle] = useState("");
  const [dataProvince, setDataProvince] = useState("all");
  // const [listProvince, setListProvince] = useState([]);

  const [dropdownOrg, setDropdownOrg] = useState(false);
  const toggleDropdownOrg = () => setDropdownOrg((prevState) => !prevState);

  // const handleChange = (date) => {
  //   setState([
  //     {
  //       startDate: date[0].startDate,
  //       endDate: date[0].endDate,
  //       key: "selection",
  //     },
  //   ]);
  // };

  const getDataDetail = () => {
    const param = {
      start: "01/01/2000",
      end: "01/01/2100",
      title: props.title,
      date: props.date,
      province: dataProvince === "all" ? "" : dataProvince,
    };
    setShowDownload(true);
    setLoading(true);
    API.getDataDemografiSurveyDetail(param)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          // console.log(res.data.data);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((e) => {
        setData(null);
        setLoading(false);
      });
  };

  useEffect(() => {
    setData([]);
    setShowDownload(false);
    setLoading(false);
    setDataProvince("all");
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.refresh]);

  // let sortData = data ? _.sortBy(res.data.data, ["obj", "price"]) : "";

  return (
    <MDBContainer>
      <MDBModal
        // id="modal-content-detail"
        isOpen={props.modalDetail}
        toggle={props.toggleDetail}
        size="md"
        // className="modal-lg"
        centered={true}>
        <MDBCard>
          <div className="w-100" style={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              onClick={props.toggleDetail}
              style={{ cursor: "pointer" }}
              className="mdi mdi-window-close m-2"></span>
          </div>
          <MDBCardTitle>
            <div className="text-center">DOWNLOAD DETAIL PENGUNJUNG MENGISI SURVEY</div>
          </MDBCardTitle>
          <MDBCardBody>
            <div className="text-center">
              {/* <div>Pilih Range Tanggal:</div>
              <div>
                <DateRangePicker
                  className="mx-auto"
                  ranges={state}
                  onChange={(item) => handleChange([item.selection])}
                  showSelectionPreview={true}
                  direction="horizontal"
                  months={2}
                  maxDate={moment(new Date()).toDate()}
                  minDate={moment("01/01/2020").toDate()}
                />
              </div>
              <div style={{ fontWeight: "bold" }}>
                {moment(state[0].startDate).format("DD-MM-YYYY")} s/d{" "}
                {moment(state[0].endDate ?? "").format("DD-MM-YYYY")}
              </div> */}

              <div className="mx-5 mt-3 mb-4" style={{ paddingLeft: "3px", paddingRight: "3px" }}>
                <div style={{ fontWeight: "bold" }} className="mb-2">
                  Pilih Provinsi
                </div>
                {/* <div className="my-5 mx-5"></div> */}
                <ButtonDropdown
                  // disabled={dataTitle == ""}
                  style={{ width: "100%" }}
                  isOpen={dropdownOrg}
                  toggle={toggleDropdownOrg}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#F8F9FA",
                      borderRadius: "20px",
                      borderColor: "#2C3280",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      // borderColor: dataTitle == "" ? "#dbdbdb" : "#2C3280",
                    }}>
                    <div
                      style={{
                        color: "#2C3280",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <span>{dataProvince === "all" ? "SEMUA PROVINSI" : dataProvince}</span>
                      {/* <img style={{ height: "24px" }} src={iconDown} /> */}
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      maxHeight: "300px",
                      minWidth: "100%",
                      overflowY: "auto",
                      textAlign: "center",
                    }}>
                    <DropdownItem
                      onClick={(e) => {
                        setDataProvince("all");
                      }}>
                      SEMUA PROVINSI
                    </DropdownItem>
                    {props.listProvince === null ? (
                      <DropdownItem>Terjadi Kesalahan Server</DropdownItem>
                    ) : (
                      props.listProvince.map((e, i) => (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setDataProvince(e);
                          }}>
                          {e}
                        </DropdownItem>
                      ))
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              <div className="my-2">
                <Button
                  onClick={getDataDetail}
                  style={{
                    borderRadius: "18px",
                    backgroundColor: "#2245f5",
                    padding: "2px 5px 2px 5px",
                    borderColor: "black",
                  }}>
                  Cari Data
                </Button>
              </div>
              {!showDownload ? (
                ""
              ) : loading ? (
                <div className="text-center">
                  <Spinner size="sm" type="grow" />
                  <div>Mohon ditunggu...</div>
                </div>
              ) : data ? (
                <div>
                  <div>
                    <div
                      className="my-3"
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className="buttonDownloadAllDemografi"
                        table="DownloadTableXLSSurveyHasilDemografiDetail"
                        filename={`summary-hasil-survey ${moment(
                          new Date()
                        ).format("DD-MM-YYYY")}`}
                        sheet="Detail Demografi Survey"
                        buttonText="Download"
                      />
                    </div>
                    <DownloadXLS
                      id="DownloadTableXLSSurveyHasilDemografiDetail"
                      className="DownloadTableXLS"
                      data={data}
                      date={props.date}
                      title={props.title}
                      province={dataProvince}
                    />
                  </div>
                </div>
              ) : (
                <div className="text-center">Terjadi Kesalahan Saat Download</div>
              )}
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalDetail;
