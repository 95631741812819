import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Skeleton from "react-loading-skeleton";

// STYLE
import "./style.scss";
import "./style.css";

// import API from "../../../../services";

const TableDFPResolusi = (props) => {
  const [rows, setRows] = useState([]);
  // const [loading, setLoading] = useState(true);

  const getData = () => {
    // setLoading(true);
    if (props.data) {
      setRows(
        props.data.map((res, i) => ({
          rank: i + 1,
          resolusi: res.name,
          jumlah: res.count.toLocaleString(),
        }))
        .sort(function (a, b) {
          return a - b;
        })
      );
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1500);
    } else {
      setRows(null);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1500);
    }
  };

  useEffect(() => {
    !props.loading && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.loading]);

  const data = {
    columns: [
      {
        label: "Rank",
        field: "rank",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Resolusi",
        field: "resolusi",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Jumlah",
        field: "jumlah",
        sort: "disabled",
        width: 200,
      },
    ],
    rows:
      rows.length === 0
        ? [
            {
              rank: "",
              resolusi: "",
              jumlah: "",
            },
          ]
        : rows,
  };

  return (
    <React.Fragment>
      <div style={{ height: "430px", overflowY: "auto", overflowX: "hidden" }}>
        {rows.length === 0 ? (
          <div>
            <h1>
              <Skeleton />
            </h1>
            <Skeleton count={10} />
          </div>
        ) : rows == null ? (
          <div> TERJADI KESALAHAN</div>
        ) : (
          <MDBDataTable
            className="text-center"
            responsive
            striped
            bordered
            searching={false}
            displayEntries={false}
            info={false}
            entries={10} //How much data that you want to show in 1 table
            disableRetreatAfterSorting //Show red Warning after use
            data={data}
            noBottomColumns={true}
            paging={false}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TableDFPResolusi;
