import Loadable from "react-loadable";
import Loading from "../../components/Loading";

const HasilSurveyDefaultAsync = Loadable({
  loader: () =>
    import("../../pages/HasilSurveyDefault" /* webpackChunkName: "HasilSurveyDefaultAsync" */),
  loading: Loading,
});

export default HasilSurveyDefaultAsync;
