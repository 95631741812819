import Loadable from "react-loadable";
import Loading from "../../../../../components/Loading";

const DeviceFingerPrintAsync = Loadable({
  loader: () =>
    import(
      "../../../../../pages/BusinessIntelligence/Admin/Measures/DeviceFingerprint" /* webpackChunkName: "DeviceFingerPrintAsync" */
    ),
  loading: Loading,
});

export default DeviceFingerPrintAsync;
