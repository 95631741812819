import React, { useEffect, useState, useRef } from "react";
import { MDBDataTable, MDBModalHeader, MDBNavLink } from "mdbreact";
import { Row, Col, Card, CardBody, Alert, Modal, ModalBody, Button } from "reactstrap";
import Skeleton from "react-loading-skeleton";

// STYLE
import "./style.scss";
import "./style.css";
import style from "../style.module.css";

import Filter from "../../../components/Filter/filter";

// import API from "../../../../services";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLSLokasiKabupaten from "../Download/XLS/DownloadKabKotLocISP";

const TableLokasiKabupaten = (props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState("");

  const filterData = (item) => {
    // console.log("yg bkl ke lempar", item)
    // props.handleData(item);
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search]);

  const searchRef = useRef();

  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);

  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      const index = [data.kab_kot, data.location, data.ip];

      if (
        Filter.byIndex({
          index: index,
          search: search,
          // date: tanggal,
        })
      ) {
        return data;
      }
    });
  }

  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };

  const getData = () => {
    setLoading(true);
    if (props.data) {
      setRows(
        props.data.map((res) => ({
          kab_kot: res.kabupaten_kota,
          location: res.nama_lokasi_baru,
          // ai: res.ai,
          ip: res.ip,
        }))
      );
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } else {
      setRows(null);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  useEffect(() => {
    !props.loading && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.loading]);

  const data = {
    columns: [
      {
        label: "Kabupaten/Kota",
        field: "kab_kot",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Nama Lokasi",
        field: "location",
        sort: "disabled",
        width: 200,
      },
      // {
      //   label: "Jumlah Lokasi AI Terintegrasi",
      //   field: "ai",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Jumlah IP Terintegrasi",
        field: "ip",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: items,
  };

  return (
    <React.Fragment>
      <Card style={{ paddingBottom: "30px", paddingTop: "20px" }}>
        <CardBody>
          <Row
            style={{
              paddingBottom: "15px",
              paddingTop: "15px",
            }}>
            <CardBody
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span className={style.titleMenuBI}>Tabel Kabupaten/Kota Dan Lokasi</span>

              {/* DOWNLOAD XLS */}
              <>
                <Modal
                  size="md"
                  isOpen={modalDownloadXLS}
                  toggle={toggleDownloadXLS}
                  centered={true}>
                  <MDBModalHeader toggle={toggleDownloadXLS}>
                    <span className={style.headText}>Download XLS</span>
                  </MDBModalHeader>
                  <ModalBody>
                    <center className="container-popup">
                      <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                      <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                        Batal
                      </Button>
                      <Button
                        color=""
                        className={style.btn_confirm_xls}
                        onClick={toggleDownloadXLS}>
                        <ReactHTMLTableToExcel
                          id="TableXLSLokasi"
                          className={style.DownloadTableXLS}
                          table="DownloadTableXLSLokasiKabupaten"
                          filename={`daftar_kabkot_lokasi_${props.date}_${props.province}`}
                          sheet="lokasi"
                          buttonText="Download"
                        />
                      </Button>
                    </center>
                  </ModalBody>
                </Modal>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <MDBNavLink to="#" className={style.textDownload}>
                    Download
                  </MDBNavLink>
                  <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                    XLS
                  </MDBNavLink>
                </div>
              </>
              <DownloadXLSLokasiKabupaten
                id="DownloadTableXLSLokasiKabupaten"
                className="DownloadTableXLS"
                data={items}
                date={props.date}
                prov={props.province}
              />
            </CardBody>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }} className="my-2">
            <Col xs="12" lg="4">
              <input
                placeholder="Cari..."
                ref={searchRef}
                className="form-control"
                onChange={handleSearch}
                type="text"
              />
            </Col>
          </Row>
          {loading ? (
            <div>
              <h1>
                <Skeleton />
              </h1>
              <Skeleton count={10} />
            </div>
          ) : rows === null ? (
            <Alert>Terjadi Kesalahan</Alert>
          ) : (
            <MDBDataTable
              className="text-center"
              responsive
              striped
              bordered
              searching={false}
              displayEntries={false}
              // info={false}
              entries={10} //How much data that you want to show in 1 table
              disableRetreatAfterSorting //Show red Warning after use
              data={data}
              noBottomColumns={true}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TableLokasiKabupaten;
