import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import DownWhite from "../../../assets/images/backwhite.png";
// -------------------COMPONENT--------------------
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Header from "../../../components/VerticalLayout/Header";
import TableKontenSupport from "../../../components/SupportCenter/Table/TableKontenSupport";
import TableArchieveKontenVideo from "../../../components/SupportCenter/Table/TableArchieveKonten/Video";
import TableArchieveKontenBanner from "../../../components/SupportCenter/Table/TableArchieveKonten/Banner";
import TableArchieveKontenBerita from "../../../components/SupportCenter/Table/TableArchieveKonten/Berita";

import { exportPDF } from "../../../components/SupportCenter/Download/Library/PDF/DownloadPDF";
import DownloadXLS from "../../../components/SupportCenter/Download/Library/XLS/DownloadXLS";
// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function KontenSupport(props) {
  let role = window.localStorage.getItem("role");
  //handle data xls
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  //handle data when change tab
  const handleData = (dat) => {
    setData(dat);
  };

  const handleData2 = (dat) => {
    setData2(dat);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const [activeSubTab1, setActiveSubTab1] = useState("1");
  const [activeSubTab2, setActiveSubTab2] = useState("1");
  const [idContent, setIdContent] = useState("");

  // const [title, setTitle] = useState("")
  // useEffect(()=>{})

  //handle download pdf
  const handleDownloadPDF = () => {
    exportPDF(data, data2, activeTab, activeSubTab1, activeSubTab2);
  };

  const tabStyle = {
    color: "#c42127",
  };
  const subTabStyle = {
    color: "#c42127",
    // borderBottom: "1px solid #c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };
  useEffect(() => {
    let notifContentType = props.dataNotif.data;
    // console.log("notifContentType", notifContentType);
    if (notifContentType !== "empty") {
      if (
        notifContentType.data.notif_type == "tidak lolos kurasi" ||
        notifContentType.data.notif_type == "pending" ||
        notifContentType.data.notif_type == "cancelled" ||
        notifContentType.data.notif_type == "declined"
      ) {
        switch (notifContentType.data.content_type) {
          case "video":
            setActiveTab("2");
            setActiveSubTab2("1");
            break;
          case "banner":
            setActiveTab("2");
            setActiveSubTab2("2");
            break;
          case "news":
            setActiveTab("2");
            setActiveSubTab2("3");
            break;
          default:
            setActiveTab("2");
            setActiveSubTab2("1");
            break;
        }
      } else {
        switch (notifContentType.data.content_type) {
          case "video":
            setActiveTab("1");
            setActiveSubTab1("1");
            break;
          case "banner":
            setActiveTab("1");
            setActiveSubTab1("2");
            break;
          case "news":
            setActiveTab("1");
            setActiveSubTab1("3");
            break;
          default:
            setActiveTab("1");
            setActiveSubTab1("1");
            break;
        }
      }
      setIdContent(
        notifContentType
          ? notifContentType.data
            ? notifContentType.data.content
              ? notifContentType.data.content._id
                ? notifContentType.data.content._id
                : "-"
              : "-"
            : "-"
          : "-"
      );
    }
  }, [props.dataNotif.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="Konten" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Table" /> */}
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12" style={{ marginTop: 60 }}>
                    <MDBNav classicTabs className="wrapperNav">
                      <div className="wrapperTab">
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "1" ? tabStyle : {}}
                            className="tabMenu waves-light"
                            active={activeTab === "1"}
                            onClick={() => setActiveTab("1")}>
                            KONTEN
                          </MDBNavLink>
                          <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "2" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "2"}
                            onClick={() => setActiveTab("2")}>
                            ARCHIVED
                          </MDBNavLink>
                          <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                      </div>

                      {/* <div className="wrapperAction">
                        <MDBNavLink to="#" className="textDownload">
                          Download
                        </MDBNavLink>
                        <ReactHTMLTableToExcel
                          id="TableXLS"
                          className="DownloadTableXLS"
                          table="DownloadTableXLS"
                          filename={`Conten_Report_Library_${role}`}
                          sheet="users"
                          buttonText="XLS"
                        />
                        <MDBNavLink onClick={handleDownloadPDF} link to="#">
                          PDF
                        </MDBNavLink>
                      </div>
                      <DownloadXLS
                        id="DownloadTableXLS"
                        className="DownloadTableXLS"
                        data={data}
                        data2={data2}
                        activeTab={activeTab}
                        activeSubTab1={activeSubTab1}
                        activeSubTab2={activeSubTab2}
                      /> */}
                      <div
                        className="wrapperDropdownResponsive"
                        style={{
                          width: "25%",
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "0.75%",
                        }}>
                        <MDBDropdown
                          style={{ width: "100% !important" }}
                          className="dropdown-download-doc">
                          <MDBDropdownToggle
                            caret
                            color="primary"
                            className="dropdown-download font-weight-bold">
                            <div className="wrapper-selected">Download</div>
                            <div>
                              <img src={DownWhite} alt="dropdown-icons" />
                            </div>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu className="dropdown-menus dropdown-show">
                            <MDBDropdownItem
                              style={{ color: "#556ee6" }}
                              className="text-center"
                              onClick={handleDownloadPDF}>
                              PDF
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                              <ReactHTMLTableToExcel
                                id="TableXLS"
                                className="DownloadTableXLSDropdown"
                                table="DownloadTableXLS"
                                filename={`Conten_Report_${role}`}
                                sheet="users"
                                buttonText="XLS"
                              />
                            </MDBDropdownItem>
                            <DownloadXLS
                              id="DownloadTableXLS"
                              className="DownloadTableXLS"
                              data={data}
                              data2={data2}
                              activeTab={activeTab}
                              activeSubTab1={activeSubTab1}
                              activeSubTab2={activeSubTab2}
                            />
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                    </MDBNav>

                    <MDBTabContent className="card" activeItem={activeTab}>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "1" && (
                          <Col className="col-12">
                            <MDBNav classicTabs className="wrapperNav">
                              <div className="wrapperTab">
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab1 === "1" ? subTabStyle : {}}
                                    className="tabMenu waves-light"
                                    active={activeSubTab1 === "1"}
                                    onClick={() => setActiveSubTab1("1")}>
                                    VIDEO
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab1 === "2" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab1 === "2"}
                                    onClick={() => setActiveSubTab1("2")}>
                                    BANNER
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab1 === "3" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab1 === "3"}
                                    onClick={() => setActiveSubTab1("3")}>
                                    BERITA
                                  </MDBNavLink>
                                </MDBNavItem>
                              </div>
                            </MDBNav>

                            <MDBTabContent
                              style={{ marginTop: 60 }}
                              className="card"
                              activeItem={activeSubTab1}>
                              <MDBTabPane tabId={activeSubTab1}>
                                {activeSubTab1 === "1" && (
                                  <TableKontenSupport
                                    idContent={idContent}
                                    handleData={handleData}
                                    activeTab={activeSubTab1}
                                  />
                                )}
                                {activeSubTab1 === "2" && (
                                  <TableKontenSupport
                                    idContent={idContent}
                                    handleData={handleData}
                                    activeTab={activeSubTab1}
                                  />
                                )}
                                {activeSubTab1 === "3" && (
                                  <TableKontenSupport
                                    idContent={idContent}
                                    handleData={handleData}
                                    activeTab={activeSubTab1}
                                  />
                                )}
                              </MDBTabPane>
                            </MDBTabContent>
                          </Col>
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "2" && (
                          <Col className="col-12">
                            <MDBNav classicTabs className="wrapperNav">
                              <div className="wrapperTab">
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab2 === "1" ? subTabStyle : {}}
                                    className="tabMenu waves-light"
                                    active={activeSubTab2 === "1"}
                                    onClick={() => setActiveSubTab2("1")}>
                                    VIDEO
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab2 === "2" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab2 === "2"}
                                    onClick={() => setActiveSubTab2("2")}>
                                    BANNER
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTab2 === "3" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTab2 === "3"}
                                    onClick={() => setActiveSubTab2("3")}>
                                    BERITA
                                  </MDBNavLink>
                                </MDBNavItem>
                              </div>
                            </MDBNav>

                            <MDBTabContent className="card" activeItem={activeSubTab2}>
                              <MDBTabPane tabId={activeSubTab2}>
                                {activeSubTab2 === "1" && (
                                  <TableArchieveKontenVideo
                                    idContent={idContent}
                                    handleData={handleData2}
                                    activeTab={activeSubTab2}
                                  />
                                )}
                                {activeSubTab2 === "2" && (
                                  <TableArchieveKontenBanner
                                    idContent={idContent}
                                    handleData={handleData2}
                                    activeTab={activeSubTab2}
                                  />
                                )}
                                {activeSubTab2 === "3" && (
                                  <TableArchieveKontenBerita
                                    idContent={idContent}
                                    handleData={handleData2}
                                    activeTab={activeSubTab2}
                                  />
                                )}
                              </MDBTabPane>
                            </MDBTabContent>
                          </Col>
                        )}
                      </MDBTabPane>
                    </MDBTabContent>
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(KontenSupport));
