import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLSVideoUB" className="DownloadTableXLS">
      <thead>Data Rata - Rata User Behavior Konten Video Tanggal {props.date}</thead>
      <thead>
        <tr>
          <th>Jumlah Klik Card Video</th>
          <th>Jumlah Klik Pop Up Video</th>
          <th>Jumlah Play Video</th>
          <th>Total Pengunjung Memilih Kualitas Video 360p</th>
          <th>Total Pengunjung Memilih Kualitas Video 240p</th>
          <th>Total Pengunjung Memilih Kualitas Video 144p</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>{data ? (data.card ? data.card.Video ?? 0 : 0) : 0}</td>
                <td>{data ? (data.popup ? data.popup.Video ?? 0 : 0) : 0}</td>
                <td>{data ? data.play_video ?? 0 : 0}</td>
                <td>{data ? (data.quality ? data.quality["360"] ?? 0 : 0) : 0}</td>
                <td>{data ? (data.quality ? data.quality["240"] ?? 0 : 0) : 0}</td>
                <td>{data ? (data.quality ? data.quality["144"] ?? 0 : 0) : 0}</td>
              </tr>
            </>
          ) : (
            <>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </>
          )
        ) : (
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
