import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";

const PreviewContent = ({
  ModalPreviewContent,
  toggleModalPreviewContent,
  dataModalPreviewContent,
  activeTab,
}) => {
  return (
    <MDBContainer>
      <MDBModal
        size="lg"
        isOpen={ModalPreviewContent}
        toggle={toggleModalPreviewContent}
        centered={true}>
        <MDBModalHeader centered toggle={toggleModalPreviewContent}>
          {dataModalPreviewContent.title}
        </MDBModalHeader>
        <MDBModalBody>
          {activeTab == "video" ? (
            <div className="player-wrapper">
              <div className="wrapperVideo">
                <iframe
                  className="video"
                  title={dataModalPreviewContent.title ?? "-"}
                  width="100%"
                  height="500"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                  src={
                    dataModalPreviewContent.type === "youtube"
                      ? `//www.youtube.com/embed/${
                          typeof dataModalPreviewContent.url === "undefined"
                            ? ""
                            : dataModalPreviewContent.url
                        }`
                      : `${process.env.REACT_APP_BE_URL}/${
                          typeof dataModalPreviewContent.url === "undefined"
                            ? ""
                            : dataModalPreviewContent.url
                        }`
                  }></iframe>
              </div>
            </div>
          ) : activeTab == "banner" ? (
            <div className="image-wrapper">
              <img
                onerror="this.onerror=null;this.src='http://example.com/existent-image.jpg';"
                alt={dataModalPreviewContent.title ?? "-"}
                src={`${process.env.REACT_APP_BE_URL}/${dataModalPreviewContent.url}`}
                className="banner"></img>
            </div>
          ) : (
            <></>
          )}
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default PreviewContent;
