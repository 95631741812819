import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const UbahJadwalContentPartnerAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/UbahJadwal/ContentPartner" /* webpackChunkName: "UbahJadwalContentPartnerAsync" */
    ),
  loading: Loading,
});

export default UbahJadwalContentPartnerAsync;
