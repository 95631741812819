import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDF = (dataTable, activeTab) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title;
  switch (activeTab) {
    case "1":
      title = `Konten Video ${role}`;
      break;
    case "2":
      title = `Konten Banner ${role}`;
      break;
    case "3":
      title = `Konten Berita ${role}`;
      break;

    default:
      break;
  }
  const headers = [
    [
      "No",
      "Judul",
      "Organisasi",
      "Kategori",
      "Kategori Lokasi",
      "Provinsi",
      "Tanggal Tayang",
      "Jam Tayang",
      "Preview",
    ],
  ];
  const data = dataTable.map((data, index) => {
    return [
      index + 1,
      data.title ? data.title ?? "-" : data.details.title ?? "-",
      data.requested_by.organization.name ?? "-",
      data.category ?? "-",
      Array.isArray(data.location) && data.location.length
        ? data.location
        : "Tidak ada kategori lokasi",
      data.list_provinsi.map((prov) => prov.name).toString() ?? "-",
      data.proposed_date
        ? `${moment(data.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")}\n s/d \n${moment(
            data.proposed_date.slice(-1),
            "DD-MM-YYYY"
          ).format("DD-MM-YYYY")}`
        : "-",
      data.proposed_time
        ? `${data.proposed_time[0][0]} - ${data.proposed_time[0][data.proposed_time[0].length - 1]}`
        : "-",
    ];
  });
  let a = 0;
  let content = {
    startY: 50,
    head: headers,
    body: data,
    didDrawCell: async function (dataCell) {
      if (dataCell.column.index === headers[0].length - 1 && dataCell.cell.section === "body") {
        var diw = dataCell.cell.width - dataCell.cell.padding("horizontal");
        var dih = dataCell.cell.height - dataCell.cell.padding("vertical");
        if (dataCell.row.index < dataTable.length) {
          if (dataTable[dataCell.row.index]) {
            var img = new Image();
            img.src =
              activeTab === "1"
                ? `${process.env.REACT_APP_SELF_URL}` +
                  `/${dataTable[dataCell.row.index].details.thumbnail}`
                : `${process.env.REACT_APP_SELF_URL}` +
                  `/${dataTable[dataCell.row.index].details.url}`;
            var poll = setInterval(function () {
              if (img.naturalWidth) {
                clearInterval(poll);
              }
            }, 10);
            doc.addImage(
              dataTable[dataCell.row.index]
                ? img
                  ? img
                  : "https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg"
                : "https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg",
              dataCell.cell.x,
              dataCell.cell.y + 5,
              dih > diw ? diw : 0 / dih,
              dih > diw ? 0 / diw : dih
            );
          }
          a = a + 1;
        }
      }
    },
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`Content_Report_${role}.pdf`);
};
