import React from "react";
import { Row, Card, Col, CardBody } from "reactstrap";
import style from "./style.module.css";

// ASSETS
import video from "../../assets/images/video.png";
import banner from "../../assets/images/banner.png";
import berita from "../../assets/images/berita.png";
import Skleton from "react-loading-skeleton";

export default function CardSummaryTrafikKonten(props) {
  return (
    <div className="container-fluid">
      {
        <Row>
          <Col
            lg="2"
            style={{
              paddingBottom: "15px",
              paddingTop: "15px",
            }}>
            <CardBody className="px-0">
              <div className={style.titleCardBI} style={{ color: "transparent", height: "50px" }}>
                KONTEN VIDEO
              </div>
              <div className={`${style.titleCardTotal}`}>
                <div>Total Konten</div>
                <div className="my-3">Total Impresi</div>
                <div>Total Klik</div>
              </div>
            </CardBody>
          </Col>
          <Col
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
            className="mx-2">
            <CardBody className=" text-center">
              <div className={style.titleCardBI} style={{ color: "#8CC6C9", height: "50px" }}>
                <img className="mr-2" style={{ width: "30px" }} src={video} alt="video" />
                KONTEN VIDEO
              </div>
              <div className={`${style.valueSummary}`}>
                <div>
                  {!props.loading ? (
                    props.data ? (
                      props.data.video ? (
                        props.data.video.count.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
                <div className="my-3">
                  {!props.loading ? (
                    props.data ? (
                      props.data.video ? (
                        props.data.video.impress.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
                <div>
                  {!props.loading ? (
                    props.data ? (
                      props.data.video ? (
                        props.data.video.click.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Col>
          <Col
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
            className="mx-2">
            <CardBody className=" text-center">
              <div className={style.titleCardBI} style={{ color: "#E2B674", height: "50px" }}>
                <img className="mr-2" style={{ width: "30px" }} src={banner} alt="banner" />
                KONTEN BANNER
              </div>
              <div className={`${style.valueSummary}`}>
                <div>
                  {!props.loading ? (
                    props.data ? (
                      props.data.banner ? (
                        props.data.banner.count.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
                <div className="my-3">
                  {!props.loading ? (
                    props.data ? (
                      props.data.banner ? (
                        props.data.banner.impress.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
                <div>
                  {!props.loading ? (
                    props.data ? (
                      props.data.banner ? (
                        props.data.banner.click.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Col>
          <Col
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
            className="mx-2">
            <CardBody className=" text-center">
              <div className={style.titleCardBI} style={{ color: "#C18ECE", height: "50px" }}>
                <img className="mr-2" style={{ width: "30px" }} src={berita} alt="berita" />
                KONTEN BERITA
              </div>
              <div className={`${style.valueSummary}`}>
                <div>
                  {!props.loading ? (
                    props.data ? (
                      props.data.news ? (
                        props.data.news.count.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
                <div className="my-3">
                  {!props.loading ? (
                    props.data ? (
                      props.data.news ? (
                        props.data.news.impress.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
                <div>
                  {!props.loading ? (
                    props.data ? (
                      props.data.news ? (
                        props.data.news.click.toLocaleString() ?? 0
                      ) : (
                        0
                      )
                    ) : (
                      0
                    )
                  ) : (
                    <div>
                      <Skleton />
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Col>
        </Row>
      }
    </div>
  );
}
