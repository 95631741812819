import React from "react";

import { Row, Card, Col, Spinner } from "reactstrap";

// import "tui-chart/dist/tui-chart.css";
import { ColumnChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "./style.module.css";
import "./toastui.scss";

import Pdf from "react-to-pdf";
import Swal from "sweetalert2";

// import API from "../../../../services";
var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  // title: {
  //     color: '#8791af',
  // },
  xAxis: {
    // title: {
    //     color: '#8791af'
    // },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#1E1BB7"],
  },
};

export default function CardPersebaranOS(props) {
  const downloadRef = React.createRef();

  const handlePDFDownload = (fn) => {
    Swal.fire({
      title: "Apakah kamu ingin mendownload halaman ini?",
      text: "Data PDF akan di download",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2c3280",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        fn();
      }
    });
  };
  const renderColumnChart = (param) => {
    const _width =
      window.screen.width > 993
        ? window.screen.width * 0.475
        : window.screen.width < 400
        ? window.screen.width * 0.77
        : window.screen.width * 0.4;
    const data = {
      categories: param
        ? param.browser_type.length !== 0
          ? param.browser_type.map((res) => {
              return res.name;
            }) ?? ""
          : ""
        : "",
      series: [
        {
          name: "Jumlah Pengguna",
          // data: param ? param.Agribisnis ?? 100 : 100,
          data: param
            ? param.browser_type.length !== 0
              ? param.browser_type.map((res) => {
                  return res.count;
                }) ?? 0
              : 0
            : 0,
        },
      ],
    };

    const options = {
      chart: {
        width: _width,
        height: _width * 0.5 + 75,
        // title: 'Jumlah',
        // format: "1,000",
      },
      yAxis: {
        title: "Jumlah",
        min: 0,
        // max: props.data
        // ? Math.max(
        //     ...param.browser_type.map((res) => {
        //       return res.count;
        //     })
        //   ) + 100
        // : 100,
      },
      xAxis: {
        title: "Browser",
      },
      legend: {
        align: "bottom",
        // visible: false,
        showCheckbox: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Persebaran_Browser_Device_Fingerprint",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("themeBrowser", theme);
    options.theme = "themeBrowser";

    return (
      <React.Fragment>
        <ColumnChart data={data} options={options} />
      </React.Fragment>
    );
  };

  const options = {
    orientation: "landscape",
    // unit: 'in',
    // format: [4,2]
  };

  return (
    <div>
      <Row style={{ minHeight: "450px" }}>
        <Col md="12">
          <Card body style={{ boxShadow: "none" }}>
            <div className="p-3">
              <Row
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className={`${style.titleCardBI}`}>PERSEBARAN BROWSER</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div>Download</div>
                  <div className="mx-3">
                    <Pdf
                      targetRef={downloadRef}
                      filename="dfp-os.pdf"
                      options={options}
                      scale={0.7}
                      x={6}
                      y={20}>
                      {({ toPdf }) => (
                        <a href="#" onClick={() => handlePDFDownload(toPdf)}>
                          PDF
                        </a>
                      )}
                    </Pdf>
                  </div>
                </div>
              </Row>
              <div ref={downloadRef}>
                <Row>
                  <Col md="12" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="mt-1 mx-auto">
                      {props.loading ? (
                        <div
                          className="text-center"
                          style={{ marginBottom: "150px", marginTop: "150px" }}>
                          <Spinner color="primary" />
                        </div>
                      ) : props.data && props.data.length !== 0 ? (
                        renderColumnChart(props.data)
                      ) : (
                        <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                          <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
