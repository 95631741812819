import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "./style.css";
import styles from "./style.module.css";
// -------------------COMPONENT--------------------
import Header from "../../components/VerticalLayout/Header";

import { exportPDF } from "../../components/Admin/Download/KontenStatistik/PDF/DownloadPDF";
import DownloadXLS from "./Download/DownloadXLS";
// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// COMPONENTS
import TableVideo from "./Table/TableVideo";
import TableBanner from "./Table/TableBanner";
import TableBerita from "./Table/TableBerita";
import Filter from "./Filter";

function KontenReport(props) {
  let role = window.localStorage.getItem("role");
  //handle data xls
  const [data, setData] = useState([]);
  const [idContent, setIdContent] = useState("");
  const [categoryContent, setCategoryContent] = useState("0");

  const [loading, setLoading] = useState(true);
  const [showTableVideo, setShowTableVideo] = useState(false);

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };
  //refresh activeTab
  useEffect(() => {}, [activeTab]);

  //handle download pdf
  const handleDownloadPDF = () => {
    exportPDF(data, activeTab);
  };

  const tabStyle = {
    color: "#c42127",
  };
  const customStyle = {
    // borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };
  useEffect(() => {
    let notifContentType = props.dataNotif.data;
    if (notifContentType !== "empty") {
      switch (notifContentType.data.content_type) {
        case "vidio":
          handleChangeTab("1");
          break;
        case "banner":
          handleChangeTab("2");
          break;
        case "news":
          handleChangeTab("3");
          break;
        default:
          handleChangeTab("1");
          break;
      }
      setIdContent(notifContentType?.data?.content?._id ?? "");
      setCategoryContent(notifContentType?.data?.content?.category ?? "");
    }
  }, [props.dataNotif.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header
            title="KONTEN REPORT"
            className="KontenDefaultHeader"
            // btn={true}
            // btntype="createcontentdefault"
            // btntext="Buat Konten Default"
          />
        </header>
        <main>
          <div className="container-fluid">
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12">
                    <MDBNav classicTabs className="wrapperNav">
                      <div className={styles.wrapperTab}>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "1" ? tabStyle : {}}
                            className="tabMenu waves-light"
                            active={activeTab === "1"}
                            onClick={() => handleChangeTab("1")}>
                            VIDEO
                          </MDBNavLink>
                          <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "2" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "2"}
                            onClick={() => handleChangeTab("2")}>
                            BANNER
                          </MDBNavLink>
                          <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "3" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "3"}
                            onClick={() => handleChangeTab("3")}>
                            BERITA
                          </MDBNavLink>
                          <hr style={activeTab === "3" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                      </div>
                    </MDBNav>
                    <MDBTabContent className="card mt-3" activeItem={activeTab}>
                      <MDBTabPane tabId="1">
                        {activeTab === "1" && (
                          <TableVideo
                            activeTab={activeTab}
                            // setData={setData}
                            // dataReport={data}
                            // loading={loading}
                          />
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId="2">
                        {activeTab === "2" && (
                          <TableBanner
                            activeTab={activeTab}
                            // setData={setData}
                            // dataReport={data}
                            // loading={loading}
                          />
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId="3">
                        {activeTab === "3" && (
                          <TableBerita
                            activeTab={activeTab}
                            // setData={setData}
                            // dataReport={data}
                            // loading={loading}
                          />
                        )}
                      </MDBTabPane>
                    </MDBTabContent>
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(KontenReport));
