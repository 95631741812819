import Loadable from "react-loadable";
import Loading from "../../../../../components/Loading";

const PersebaranPengunjungAsync = Loadable({
  loader: () =>
    import(
      "../../../../../pages/BusinessIntelligence/Admin/Measures/PersebaranPengunjung" /* webpackChunkName: "PersebaranPengunjungAsync" */
    ),
  loading: Loading,
});

export default PersebaranPengunjungAsync;
