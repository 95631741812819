import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const KontenKuratorAsync = Loadable({
  loader: () =>
    import("../../../pages/Konten/Kurator" /* webpackChunkName: "KontenKuratorAsync" */),
  loading: Loading,
});

export default KontenKuratorAsync;
