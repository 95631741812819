import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBCardBody,
  MDBModalBody,
  MDBCardTitle,
  MDBCard,
  MDBDataTable,
} from "mdbreact";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
// ------------------REDUX---------------------
import moment from "moment";

import { Button, Spinner, Col } from "reactstrap";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "../Download/XLS/DownloadAllDetailDemografi";

import _ from "lodash";
import API from "../../../services";
import "./style.css";

const ModalDetail = (props) => {
  const [data, setData] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleChange = (date) => {
    setState([
      {
        startDate: date[0].startDate,
        endDate: date[0].endDate,
        key: "selection",
      },
    ]);
  };

  const getDataDetail = () => {
    const param = {
      start: moment(state[0].startDate).format("DD-MM-YYYY"),
      end: moment(state[0].endDate).format("DD-MM-YYYY"),
    };
    setShowDownload(true);
    setLoading(true);
    API.getDataDemografiSurveyDetail(param)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((e) => {
        setData(null);
        setLoading(false);
      });
  };

  return (
    <MDBContainer>
      <MDBModal
        // id="modal-content-detail"
        isOpen={props.modalDetail}
        toggle={props.toggleDetail}
        size="lg"
        className="modal-lg"
        centered={true}>
        <MDBCard>
          <div className="w-100" style={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              onClick={props.toggleDetail}
              style={{ cursor: "pointer" }}
              className="mdi mdi-window-close m-2"></span>
          </div>
          <MDBCardTitle>
            <div className="text-center">DOWNLOAD DETAIL DEMOGRAFI SURVEI</div>
          </MDBCardTitle>
          <MDBCardBody>
            <div className="text-center">
              <div>Pilih Range Tanggal:</div>
              <div>
                <DateRangePicker
                  className="mx-auto"
                  ranges={state}
                  onChange={(item) => handleChange([item.selection])}
                  showSelectionPreview={true}
                  direction="horizontal"
                  months={2}
                  maxDate={moment(new Date()).toDate()}
                  minDate={moment("01/01/2020").toDate()}
                />
              </div>
              <div style={{ fontWeight: "bold" }}>
                {moment(state[0].startDate).format("DD-MM-YYYY")} s/d{" "}
                {moment(state[0].endDate ?? "").format("DD-MM-YYYY")}
              </div>
              <div className="my-2">
                <Button
                  onClick={getDataDetail}
                  style={{
                    borderRadius: "18px",
                    backgroundColor: "#2245f5",
                    padding: "2px 5px 2px 5px",
                    borderColor: "black",
                  }}>
                  Cari Data
                </Button>
              </div>
              {!showDownload ? (
                ""
              ) : loading ? (
                <div className="text-center">
                  <Spinner size ="sm" type="grow" />
                  <div>Mohon ditunggu...</div>
                </div>
              ) : data ? (
                <div>
                  <div>
                    <div
                      className="my-3"
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className="buttonDownloadAllDemografi"
                        table="DownloadTableXLSSurveyDemografiDetail"
                        filename={`download-all-detail-demografi ${moment(new Date()).format(
                          "DD-MM-YYYY"
                        )}`}
                        sheet="Detail Demografi Survey"
                        buttonText="Download"
                      />
                    </div>
                    <DownloadXLS
                      id="DownloadTableXLSSurveyDemografiDetail"
                      className="DownloadTableXLS"
                      data={data}
                      date={`${moment(state[0].startDate).format("DD-MM-YYYY")} s/d ${moment(
                        state[0].endDate ?? ""
                      ).format("DD-MM-YYYY")}`}
                    />
                  </div>
                </div>
              ) : (
                <div className="text-center">Terjadi Kesalahan Saat Download</div>
              )}
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalDetail;
