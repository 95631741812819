import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";

// Reactstrap
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import menuDropdown
import NotificationKurator from "../Notification/Kurator/NotificationDropdown";
import NotificationContentPartner from "../Notification/ContentPartner/NotificationDropdown";

import NotificationSuperAdmin from "../Notification/SuperAdmin/NotificationDropdown";
import NotificationAdmin from "../Notification/Admin/NotificationDropdown";
import NotificationSupport from "../Notification/Support/NotificationDropdown";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//i18n
import { withNamespaces } from "react-i18next";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";
import "./style.css";
//asset
import IconCreate from "../../assets/icon/IconCreate.svg";
import IconBack from "../../assets/icon/IconBack.svg";
import { useHistory, Link, useLocation } from "react-router-dom";
import Post from "../../services/Post";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Header = (props) => {
  let history = useHistory();
  const [notif, setNotif] = useState("");
  const [dataNotif, setDataNotif] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [notifNotRead, setNotifNotRead] = useState([]);

  const toggleAdmin = () => setOpen(!dropdownOpen);
  const toggle = () => setIsOpen(!isOpen);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // let data = window.localStorage.getItem("kateg").split(",");
  let userId = window.localStorage.getItem("idUser");
  const role = window.localStorage.getItem("role");

  const query = useQuery();
  const tab = query.get("tab");
  const konten = query.get("konten");

  const onNotif = (message) => {
    const notification = (message) => {
      new Notification("Bakti Online", {
        body: message,
        icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRUcUCuXMald5MexzWYz8GwZAu-vqSr0JZOFw&usqp=CAU",
      });
    };
    if (!("Notification" in window)) {
      // console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      notification(message);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((requestPermission) => {
        if (requestPermission === "granted") {
          notification(message);
        }
      });
    }
  };

  const getNotif = () => {
    Post("notif/get", { id: userId })
      .then((result) => {
        setDataNotif(result.data.data);
        if (result.data.data.length !== 0) {
          setNotifNotRead(result.data.data.filter((data) => data.read === false));
        }
      })
      .catch((err) => {
        setDataNotif([]);
      });
  };

  useEffect(() => {
    // console.log("notif1");
    getNotif();
  }, [dataNotif.length]);

  useEffect(() => {
    getNotif();
    // const socket = io(process.env.REACT_APP_BE_URL);
    const socket = io(process.env.REACT_APP_BE_URL, {
      transports: ["websocket", "polling", "flashsocket"],
    });
    // data.map((kateg) => {
    //   socket.on(`${kateg}`, (message) => {
    //     setNotif(message);
    //     onNotif(message.message);
    //     setDataNotif([]);
    //   });
    // });
    socket.on(`${userId}`, (message) => {
      setNotif(message);
      onNotif(message.message);
      setDataNotif([]);
    });
    return () => socket.disconnect();
  }, []);

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
    // alert("ini akan jadi dropdown menu");
  }

  const HEADER_STATES = {
    create: (
      <Button
        color=""
        onClick={() => history.push("/content-partner/buat-konten")}
        className="btnCreate btn btn-rounded">
        <img src={IconCreate}></img> Buat Content
      </Button>
    ),
    back: (
      <Button onClick={() => history.goBack()} color="" className="d-flex">
        <img src={IconBack} className="m-auto"></img>{" "}
        <h3 className="ml-2 my-auto"> {props.btntext} </h3>
      </Button>
    ),
    backStatistic: (
      <Button
        onClick={() => history.push(`/admin/konten-statistik/?tab=${tab}&konten=${konten}`)}
        color=""
        className="d-flex">
        <img src={IconBack} className="m-auto"></img>{" "}
        <h3 className="ml-2 my-auto"> {props.btntext} </h3>
      </Button>
    ),
    backSurvey: (
      <Button onClick={() => history.push("/admin/survey")} color="" className="d-flex">
        <img src={IconBack} className="m-auto"></img>{" "}
        <h3 className="ml-2 my-auto">{props.btntext} </h3>
      </Button>
    ),
    backSurveyDefault: (
      <Button onClick={() => history.push("/admin/survey/2")} color="" className="d-flex">
        <img src={IconBack} className="m-auto"></img>{" "}
        <h3 className="ml-2 my-auto">{props.btntext} </h3>
      </Button>
    ),
    backCpAnalytics: (
      <Button
        onClick={() => {
          //history.push(`/admin/cp-analitics-detail/?tab=${tab}&konten=${konten}`);
          //history.push(`/admin/cp-analitics-detail/?tab=${tab}&konten=${konten}`);
          history.goBack();
        }}
        color=""
        className="d-flex">
        <img src={IconBack} className="m-auto"></img>{" "}
        <h3 className="ml-2 my-auto"> {props.btntext} </h3>
      </Button>
    ),
    tayang: (
      <Button
        onClick={() => history.push("/admin/detail-konten-preview")}
        color=""
        className="btnCreate btn btn-rounded">
        {props.btntext}
      </Button>
    ),
    createpengguna: (
      <Button
        onClick={() => history.push("/admin/tambah-pengguna")}
        color=""
        className="btnCreate btn btn-rounded">
        <img src={IconCreate} className="m-auto"></img> {props.btntext}
      </Button>
    ),
    createcontentdefault: (
      <Button
        onClick={() => history.push("/admin/konten-default-create")}
        color=""
        className="btnCreate btn btn-rounded">
        <img src={IconCreate} className="m-auto"></img> {props.btntext}
      </Button>
    ),
    createcontentadmin: (
      <Button
        color=""
        onClick={() => history.push("/admin/konten-create")}
        className="btnCreate btn btn-rounded">
        <img src={IconCreate}></img> Buat Konten
      </Button>
    ),
    createSurvey: (
      <Button
        color=""
        onClick={() => history.push("/admin/create/survey")}
        className="btnCreate btn btn-rounded">
        <img src={IconCreate}></img> Buat Survei
      </Button>
    ),
    createSurveyDefault: (
      <Button
        color=""
        onClick={() => history.push("/admin/create/survey_default")}
        className="btnCreate btn btn-rounded w-130">
        <img src={IconCreate}></img> Buat Survei Default
      </Button>
    ),
    createTicket: (
      <Button
        color=""
        onClick={() => history.push("/support-center/buat-ticket")}
        className="btnCreate btn btn-rounded w-130">
        <img src={IconCreate}></img> Buat Tiket
      </Button>
    ),
  };

  const getRole = window.localStorage.getItem("role");

  return (
    <React.Fragment>
      <div className="ml-2 mr-2 mt-2 mb-3">
        <Navbar light expand="sm" className="costume-header">
          {getRole !== "SuperAdmin" ? (
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars"></i>
            </button>
          ) : (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleAdmin}
              style={{ outline: "none" }}
              className="d-inline-block">
              <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button">
                {/* <i className="fa fa-fw fa-bars" style={{ color: "#000", marginLeft: "50%" }}></i> */}
                <span className="d-inline-block ml-2 mr-1 font-weight-bold">MENU</span>
              </DropdownToggle>
              <DropdownMenu leftMenu style={{ marginLeft: "40%" }}>
                <DropdownItem style={{ marginLeft: "1px" }}>
                  <Link to="/superadmin/persetujuan-konten">Persetujuan Konten</Link>
                </DropdownItem>
                <DropdownItem style={{ marginLeft: "1px" }}>
                  <Link to="/superadmin/konten">Konten</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/superadmin/registrasi-admin">Registrasi Admin</Link>
                  {/* <i className="font-size-16 align-middle"></i> */}
                </DropdownItem>
                <DropdownItem>
                  <Link to="/superadmin/dashboard-admin">Dashboard Admin</Link>
                </DropdownItem>
                <DropdownItem tag="a" href="auth-lock-screen">
                  <Link to="/superadmin/business-intelligence-PersebaranPengunjung">
                    Business Intelligence
                  </Link>
                  {/* <i className="font-size-16 align-middle"></i> */}
                </DropdownItem>
                <DropdownItem tag="a" href="auth-lock-screen">
                  <Link to="/superadmin/log-history">Log History</Link>
                  {/* <i className="font-size-16 align-middle"></i> */}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}

          <NavbarToggler onClick={toggle} style={{ border: "none" }} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mx-3" navbar>
              {props.btn ? HEADER_STATES[`${props.btntype}`] : <></>}
            </Nav>
            <Nav className="mx-auto" navbar>
              {<h3 className="titleMobile">{props.title}</h3>}
            </Nav>
            <div className="d-flex wrapperNotifMobile">
              {/* <Button className="bg-transparent border-0" onClick={() => onNotif()}>
                {" "}
                <span style={{ color: "black" }}>Test Notif</span>{" "}
              </Button> */}

              {/* {role == "Kurator" ? (
                <NotificationKurator data={dataNotif} />
              ) : (
                  <NotificationContentPartner data={dataNotif} modalRating={props.modalRating} />
                )} */}

              {role == "SuperAdmin" ? (
                <NotificationSuperAdmin
                  data={dataNotif}
                  notifNotRead={notifNotRead}
                  getNotif={getNotif}
                />
              ) : (
                ""
              )}

              {role == "Admin" ? (
                <NotificationAdmin
                  data={dataNotif}
                  notifNotRead={notifNotRead}
                  getNotif={getNotif}
                />
              ) : (
                ""
              )}

              {role == "Support Center" ? (
                <NotificationSupport
                  data={dataNotif}
                  notifNotRead={notifNotRead}
                  getNotif={getNotif}
                />
              ) : (
                ""
              )}

              <ProfileMenu />
            </div>
          </Collapse>
        </Navbar>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withNamespaces()(Header));
