import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, Row } from "reactstrap";

import "./style.css";
import API from "../../../../../services";
import BarChart from "./BarChart.js";
import moment from "moment";

function CardBarChart(props) {
  const [loadBar, setLoadBar] = useState(true);
  const [loadIssue, setLoadIssue] = useState(true);
  const [dataIssue, setDataIssue] = useState([]);
  const [dataBar, setDataBar] = useState("");

  const fetchAPIBar = () => {
    API.getDataBar()
      .then((res) => {
        if (res !== undefined) {
          setLoadBar(false);
          setDataBar(res.data.data);
          console.log("res.data.data", res.data.data);
        } else {
          setLoadBar(false);
          setDataBar([]);
        }
      })
      .catch((err) => {
        props.setError("Gagal Memuat Data");
        setLoadBar(false);
      });
  };
  const fetchAPIIssue = () => {
    API.getNewestIssue()
      .then((res) => {
        if (res !== undefined) {
          setLoadIssue(false);
          setDataIssue(res.data.data);
        } else {
          setLoadIssue(false);
          setDataIssue([]);
        }
      })
      .catch((err) => {
        props.setError("Gagal Memuat Data");
        setLoadIssue(false);
      });
  };
  useEffect(() => {
    fetchAPIBar();
    fetchAPIIssue();
  }, []);
  return (
    <div className="container-fluid my-5">
      <Row>
        <Col md="12" xl="6">
          <Card>
            <h3 className="m-4">JUMLAH ISSUE HARIAN</h3>
            <div className="d-flex justify-content-center w-100">
              {loadBar ? (
                <Skeleton count={10} />
              ) : dataBar !== undefined ? (
                <BarChart
                  data={dataBar ? dataBar : undefined}
                  dataMax={Math.max(...Object.values(dataBar))}
                  width={window.screen.width / 2.4}
                />
              ) : (
                "Tidak Ada Data"
              )}
            </div>
          </Card>
        </Col>
        <Col md="12" xl="6">
          <Card>
            <h3 className="mt-4 ml-4 mb-3">ISSUE TERBARU</h3>
            <CardBody style={{ height: "400px", overflowY: "auto", backgroundColor: "#f5f5f5" }}>
              {loadIssue ? (
                <Skeleton count={10} />
              ) : (
                dataIssue.map((data) => (
                  <Row>
                    <Col md="12" className="py-2">
                      <Row className="d-flex justify-content-center">
                        <Col md="12" className="title-issue">
                          {data ? (data.ticketNum ? data.ticketNum : "-") : "-"}
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <Col md="6" className="content-issue">
                          {data ? (data.message ? data.message : "-") : "-"}
                        </Col>
                        <Col md="6" className="date-issue">
                          {data
                            ? data.tanggal_pembuatan
                              ? moment(data.tanggal_pembuatan).format("DD-MM-YYYY")
                              : "-"
                            : "-"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CardBarChart;
