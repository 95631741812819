import React, { useMemo, useEffect, useState } from "react";
import { Row, Col, Container, Card } from "reactstrap";
import _ from "lodash";
import StatistiKontenCP from "./chart";
import Skeleton from "react-loading-skeleton";
const TotalData = (props) => {
  let { impressData, dates } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    totalImpress: "",
    totalKlik: "",
    impresiMaks: "",
    klikMaks: "",
    jumlahPenggunaUnik: "",
  });
  const SkeletonCircle = () => {
    return <Skeleton circle={true} height={100} width={100} />;
  };

  console.log("impress data", impressData);
  useEffect(() => {
    if (impressData && impressData.length !== 0) {
      setData((prevState) => ({
        ...prevState,
        totalImpress: impressData[0].total_impress ? impressData[0].total_impress : "0",
        totalKlik: impressData[0].total_clicked ? impressData[0].total_clicked : "0",
        impresiMaks: impressData[0].impress
          ? Object.entries(impressData[0].impress).length !== 0
            ? Math.max(...Object.values(impressData[0].impress))
            : "0"
          : "0",
        // klikMaks: impressData[0].clicked ? Math.max(...Object.values(impressData[0].clicked)) : "0",
        klikMaks: impressData[0].clicked
          ? Object.entries(impressData[0].clicked).length !== 0
            ? Math.max(...Object.values(impressData[0].clicked))
            : "0"
          : "0",

        jumlahPenggunaUnik: impressData[0].unique_dfp ? impressData[0].unique_dfp : "0",
      }));
      setLoading(false);
    } else {
      setLoading(true);
      setData({
        totalImpress: "0",
        totalKlik: "0",
        impresiMaks: "0",
        klikMaks: "0",
        jumlahPenggunaUnik: "0",
      });
    }
  }, [impressData, props.loadingFilter, props.provinsi]);

  return (
    <Row>
      <Container>
        <Row className="mt-5 mb-5">
          <Col className="wrapper-total border-right border-light" lg="2" md="3">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                <div className="title-total">TOTAL IMPRESI</div>

                <div className="total-content">
                  {loading && props.loadingFilter ? <SkeletonCircle /> : data.totalImpress ?? "0"}
                </div>
              </div>
            </Row>
          </Col>
          <Col className="wrapper-kurasi border-right border-light" lg="2" md="3">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                <div className="title-total">TOTAL KLIK</div>

                <div className="total-content">
                  {loading && props.loadingFilter ? <SkeletonCircle /> : data.totalKlik ?? "0"}
                </div>
              </div>
            </Row>
          </Col>
          <Col className="wrapper-kurasi border-right border-light" lg="3" md="3">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                <div className="title-total">IMPRESI MAKSIMUM</div>

                <div className="total-content">
                  {loading && props.loadingFilter ? <SkeletonCircle /> : data.impresiMaks ?? "0"}
                </div>
              </div>
            </Row>
          </Col>
          <Col className="wrapper-kurasi border-right border-light" lg="2" md="3">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                <div className="title-total">KLIK MAKSIMUM</div>

                <div className="total-content">
                  {loading && props.loadingFilter ? <SkeletonCircle /> : data.klikMaks ?? "0"}
                </div>
              </div>
            </Row>
          </Col>
          <Col className="wrapper-sudah" lg="3" md="3">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                <div className="title-total">JUMLAH PENGGUNA UNIK</div>

                <div className="total-content">
                  {loading && props.loadingFilter ? (
                    <SkeletonCircle />
                  ) : (
                    data.jumlahPenggunaUnik ?? "0"
                  )}
                </div>
              </div>
            </Row>
          </Col>
        </Row>

        <Card>
          {/* {loading && props.loadingFilter ? <SkeletonCircle /> : data.jumlahPenggunaUnik} */}
          <StatistiKontenCP impressData={impressData} dates={dates} />
        </Card>
      </Container>
    </Row>
  );
};

export default TotalData;
