import React, { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";
import Header from "../../components/VerticalLayout/Header";
import ModalTambahProvSurvei from "../../components/Admin/Popup/TambahProvinsi";
import API from "../../services";
import "./style.css";
import _ from "lodash";
import { FilterJumlahSurvei, FilterMaxPilihan } from "./dropdownFilter";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

function BuatSurveyDefault(props) {
  const history = useHistory();
  const [namaSurvey, setNamaSurvey] = useState("");
  const [showProvinsiTambahan, setshowProvinsiTambahan] = useState([]);
  const [namaLokasi, setNamaLokasi] = useState([]);
  const [bool, setBool] = useState(false);
  const [message, setMessage] = useState("");
  //success alert
  const [successSubmitted, setSuccessSubmitted] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [optionLength, setOptionLength] = useState([]);

  //modalTambahProvinsi
  const [modalTambahProvinsi, setModalTambahProvinsi] = useState(false);
  const toggleModalTambahProvinsi = () => {
    setModalTambahProvinsi(!modalTambahProvinsi);
  };
  const handleSelectedProvince = (province) => {
    setNamaLokasi(province);
    setshowProvinsiTambahan(province);
  };

  const [errorMessage, setErrorMessage] = useState("");

  //clear
  const handleClear = () => {
    setNamaSurvey("");
    setshowProvinsiTambahan("");
    setJp(0);
    setInputList([]);
    setOptionLength([]);
  };

  ///Handle for Pertanyaan
  const [inputList, setInputList] = useState([]);
  const [jp, setJp] = useState("0");

  //add number pertanyaan
  const handleAdd = (val) => {
    let isValBigger = val > parseInt(jp); // is val more than jp
    setJp(val.toString()); // for option length
    if (!Array.isArray(inputList) && !inputList.length) {
      // if question empty
      setInputList(
        _.times(val, () => {
          return { pertanyaan: "", tipePertanyaan: "essay", jawaban: [""], maxPilihan: "1" };
        })
      );
    } else if (isValBigger) {
      // if val > jp
      let countNewQuestion = val - parseInt(jp);
      let newQuestion = _.times(countNewQuestion, () => {
        return { pertanyaan: "", tipePertanyaan: "essay", jawaban: [""], maxPilihan: "1" };
      });
      setInputList(inputList.concat(newQuestion));
    } else {
      // if jp > val
      setInputList(inputList.slice(0, val));
    }
    setBool(!bool);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleChoice = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    const listUpdate = inputList;
    list[index][name] = value;
    //if tipe pertanyaan checkbox min 2
    if (value === "checkbox") {
      list[index]["maxPilihan"] = 2;
      console.log("[...inputList[index].jawaban", [...inputList[index].jawaban, ""]);
      list[index]["jawaban"] = [...inputList[index].jawaban, ""];
    } else {
      const listClear = [""];
      listUpdate[index].jawaban = listClear;
    }
    //if tipepertanyaan change

    setInputList(listUpdate);

    setInputList(list);
  };

  //handle max answer choices
  const handleMax = (val, index, type) => {
    const list = [...inputList];
    if (type == "checkbox") {
      list[index].maxPilihan = val.toString();
    } else {
      list[index].maxPilihan = "1";
    }
    setInputList(list);
  };

  ///Handle for Jawaban
  const handleInputChangeJawab = (e, index, i) => {
    const listUpdate = inputList;
    const { value } = e.target;
    const listInsert = [...inputList[i].jawaban];
    listInsert[index] = value;
    listUpdate[i].jawaban = listInsert;
    setBool(!bool);
    setInputList(listUpdate);
  };

  const handleRemoveClickJawab = (index, i) => {
    const listDelete = inputList;
    const list = [...inputList[i].jawaban];
    list.splice(index, 1);
    listDelete[i].jawaban = list;
    listDelete[i].maxPilihan = list.length;
    setInputList(listDelete);
    setBool(!bool);
  };

  const handleAddClickJawab = (index, i) => {
    if (index > 5) return;
    const listUpdate = inputList;
    const listAdd = [...inputList[i].jawaban, ""];
    listUpdate[i].jawaban = listAdd;
    setBool(!bool);
    setInputList(listUpdate);
  };

  useEffect(() => {
    let lngthEachQuestion = inputList.map((data) => data.jawaban.length.toString());
    setOptionLength(lngthEachQuestion);
  }, [inputList, bool]);

  const handleAddSurvey = () => {
    let idUser = window.localStorage.getItem("idUser");
    let questionList = _.map(inputList, "pertanyaan").flat();
    let answerList = _.map(inputList, "jawaban").flat();
    let maxList = _.map(inputList, "maxPilihan").flat();
    let listTypeQuestion = _.map(inputList, "tipePertanyaan").flat();

    let param = {
      questionLength: jp,
      option: answerList,
      optionLength: optionLength,
      question: questionList,
      type: listTypeQuestion,
      userId: idUser,
      max: maxList,
      templateName: namaSurvey,
    };

    try {
      if (param.questionLength == param.question.length) {
        if (param.question.some((e) => !e)) {
          throw Error("Pertanyaan tidak boleh kosong");
        }
        if (
          param.option.length !==
          param.optionLength.reduce((acc, curr) => acc + parseInt(curr, 10), 0)
        ) {
          throw Error("Option tidak sesuai");
        }
        let x = [...param.option];
        for (let [index, value] of param.type.entries()) {
          let n = x.splice(0, parseInt(param.optionLength[index]));
          if (value != "essay" && n.some((e) => !e)) {
            throw Error("Jawaban tidak boleh kosong");
          }
        }
      }

      API.createSurveyDefault(param)
        .then((res) => {
          setModalLoading(true);
          if (res.data.success) {
            new Promise(
              () => {
                setSuccessSubmitted("success");
                setMessage(res.data.data);
              },
              setTimeout(() => {
                history.push("/admin/survey/2");
              }, 3000)
            );
          } else {
            setErrorMessage("Survey gagal dibuat");
            new Promise(
              () => {
                setSuccessSubmitted("fail");
              },
              setTimeout(() => {
                setModalLoading(false);
              }, 3000)
            );
          }
        })
        .catch((e) => {
          setModalLoading(true);
          setErrorMessage("Survey gagal dibuat");
          new Promise(
            () => {
              setSuccessSubmitted("fail");
            },
            setTimeout(() => {
              setModalLoading(false);
            }, 3000)
          );
        });
    } catch (error) {
      setErrorMessage(error.message);
      setModalLoading(true);
      setSuccessSubmitted("fail");
      setTimeout(() => {
        setModalLoading(false);
      }, 3000);
    }
  };

  return (
    <>
      {modalLoading ? (
        successSubmitted == "success" ? (
          <SweetAlert
            title={`Survey Default ${message.name} Berhasil Dibuat`}
            success
            showConfirm={false}
            onConfirm={""}
          />
        ) : successSubmitted == "fail" ? (
          <SweetAlert title={errorMessage} error showConfirm={false} onConfirm={""} />
        ) : (
          <SweetAlert
            showConfirm={false}
            onConfirm={""}
            title="Mohon Tunggu"
            style={{
              padding: "3em",
              borderRadius: "20px",
            }}>
            <Spinner
              color="danger"
              style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
              type="grow"
            />
            <div>Survey Default sedang dibuat</div>
          </SweetAlert>
        )
      ) : null}
      <div className="page-content">
        <header>
          <Header
            title="Buat Survei Default"
            btn={true}
            btntype="backSurveyDefault"
            btntext="Survei Default"
          />
        </header>
        <main>
          <div className="container-fluid" style={{ paddingBottom: "100px" }}>
            <Row>
              <Col
                xs={{ size: "6", offset: "3" }}
                style={{ paddingBottom: "10px", paddingTop: "20px" }}>
                <Label for="titleArticle">Nama Survei</Label>
                <Input
                  type="text"
                  name="text"
                  id="judul"
                  placeholder="Nama Survei"
                  className="inputWrapper"
                  value={namaSurvey}
                  onChange={(e) => setNamaSurvey(e.target.value)}
                />
              </Col>
              <Col xs={{ size: "6", offset: "3" }} style={{ paddingBottom: "10px" }}>
                <Label for="titleArticle">Jumlah Pertanyaan</Label>
                <FilterJumlahSurvei
                  type={"Pilih Jumlah Survei"}
                  filter={jp}
                  clicked={handleAdd}
                  list={Array.from({ length: 10 }, (_, i) => i + 1)}
                />
              </Col>
            </Row>
            {inputList.map((x, i) => {
              return (
                <div key={i}>
                  <Row>
                    <Col
                      xs={{ size: "1", offset: "1" }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        color: "darkblue",
                      }}>
                      <div>{i + 1}</div>
                    </Col>
                    <Col xs={{ size: "9" }} style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                      <Input
                        name="pertanyaan"
                        value={x.pertanyaan}
                        onChange={(e) => handleInputChange(e, i)}
                        placeholder="Pertanyaan"
                        className="inputWrapper"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={{ size: "1", offset: "1" }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        color: "darkblue",
                      }}>
                      <div></div>
                    </Col>
                    <Col xs={{ size: "7" }}>
                      <div>
                        {x.tipePertanyaan === "essay" ? (
                          <div></div>
                        ) : x.tipePertanyaan === "radio" ? (
                          inputList[i].jawaban.map((value, index) => {
                            return (
                              <div key={index}>
                                <Row>
                                  <Col
                                    xs="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <span className="mdi mdi-18px mdi-radiobox-blank"></span>
                                  </Col>
                                  <Col xs="11">
                                    <Input
                                      name="jawaban"
                                      value={value}
                                      onChange={(e) => handleInputChangeJawab(e, index, i)}
                                      placeholder="Jawaban"
                                      className="inputWrapper"
                                    />
                                  </Col>
                                </Row>
                                <div className="btn-box">
                                  <Row>
                                    <Col xs={{ size: "5", offset: "1" }}>
                                      {inputList[i].jawaban.length - 1 === index && (
                                        <Button
                                          color=""
                                          className="buttonJawabanAdd"
                                          style={{ color: "darkblue" }}
                                          onClick={() => handleAddClickJawab(index, i)}>
                                          Tambah Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                    <Col xs="6" style={{ textAlign: "right" }}>
                                      {inputList[i].jawaban.length !== 1 && (
                                        <Button
                                          color=""
                                          className="buttonJawabanRemove"
                                          onClick={() => handleRemoveClickJawab(index, i)}>
                                          Hapus Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            );
                          })
                        ) : x.tipePertanyaan === "checkbox" ? (
                          inputList[i].jawaban.map((value, index) => {
                            return (
                              <div key={index}>
                                <Row>
                                  <Col
                                    xs="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <span className="mdi mdi-18px mdi-checkbox-blank-outline"></span>
                                  </Col>
                                  <Col xs="11">
                                    <Input
                                      name="jawaban"
                                      value={value}
                                      onChange={(e) => handleInputChangeJawab(e, index, i)}
                                      placeholder="Jawaban"
                                      className="inputWrapper"
                                    />
                                  </Col>
                                </Row>
                                <div className="btn-box">
                                  <Row>
                                    <Col xs={{ size: "5", offset: "1" }}>
                                      {inputList[i].jawaban.length - 1 === index && (
                                        <Button
                                          color=""
                                          className="buttonJawabanAdd"
                                          style={{ color: "darkblue" }}
                                          onClick={() => handleAddClickJawab(index, i)}>
                                          Tambah Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                    <Col xs="6" style={{ textAlign: "right" }}>
                                      {inputList[i].jawaban.length !== 1 && (
                                        <Button
                                          color=""
                                          className="buttonJawabanRemove"
                                          onClick={() => handleRemoveClickJawab(index, i)}>
                                          Hapus Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          inputList[i].jawaban.map((value, index) => {
                            return (
                              <div key={index}>
                                <Input
                                  name="jawaban"
                                  value={value}
                                  onChange={(e) => handleInputChangeJawab(e, index, i)}
                                  placeholder="Jawaban"
                                  className="inputWrapper"
                                />
                                <div className="btn-box">
                                  <Row>
                                    <Col xs="6">
                                      {inputList[i].jawaban.length - 1 === index && (
                                        <Button
                                          color=""
                                          className="buttonJawabanAdd"
                                          style={{ color: "darkblue" }}
                                          onClick={() => handleAddClickJawab(index, i)}>
                                          Tambah Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                    <Col xs="6" style={{ textAlign: "right" }}>
                                      {inputList[i].jawaban.length !== 1 && (
                                        <Button
                                          color=""
                                          className="buttonJawabanRemove"
                                          onClick={() => handleRemoveClickJawab(index, i)}>
                                          Hapus Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </Col>
                    <Col xs="2">
                      <Col xs="12">
                        <Label>Jenis Jawaban</Label>
                        <Input
                          name="tipePertanyaan"
                          onChange={(e) => handleChoice(e, i)}
                          type="select"
                          placeholder="Choose Type"
                          className="inputWrapper"
                          style={{ paddingRight: "10px", color: "darkblue" }}>
                          <option value="essay">Essay</option>
                          <option value="radio">Pilihan Ganda</option>
                          <option value="checkbox">Checkbox</option>
                          <option value="dropdown">Dropdown</option>
                        </Input>
                      </Col>
                      {x.tipePertanyaan == "checkbox" ? (
                        <Col className="mt-1" xs="12">
                          <Label>Max PIlihan</Label>
                          <FilterMaxPilihan
                            indexSurvei={i}
                            typeSurvei={x.tipePertanyaan ? x.tipePertanyaan : ""}
                            type={"Maks Pilihan"}
                            filter={x.maxPilihan}
                            clicked={handleMax}
                            list={Array.from(
                              {
                                length:
                                  inputList[i][`jawaban`] && inputList[i][`jawaban`].length
                                    ? inputList[i][`jawaban`].length
                                    : 1,
                              },
                              (_, i) => i + 1
                            )}
                          />
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })}
            {inputList.length === 0 ? (
              <div></div>
            ) : (
              <Row style={{ paddingTop: "21px" }}>
                <Col xs={{ size: "2", offset: "4" }}>
                  <Button onClick={handleClear} color="" className="buttonClearSurvei">
                    Clear
                  </Button>
                </Col>
                <Col xs="2">
                  <Button
                    color=""
                    onClick={() => {
                      handleAddSurvey();
                    }}
                    className="buttonBuatSurvei">
                    Buat Survei Default
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </main>
      </div>
    </>
  );
}
export default BuatSurveyDefault;
