import React, { useState } from 'react';
import styles from "../../assets/css/login.module.css"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
// import { connect } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import API from '../../services';

import { useDispatch } from "react-redux"

const Login = (props) => {
    const dispatch = useDispatch()
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorUsername, setErrorUsername] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setOpen] = useState(false);
    const [alertFail, setAlertFail] = useState("")
    const [error, setError] = useState("");
    const [noMatch, setNoMatch] = useState("");
    const [visible, setVisible] = useState(true);
    const history = useHistory();


    //alert
    const [alertLogin, setAlertLogin] = useState(false);
    //notif
    const [notifLogin, setNotifLogin] = useState(false);

    // handleValidSubmit
    function handleValidSubmit() {
        if (username === "") {
            setErrorUsername("Username Tidak Boleh Kosong")
        }
        if (password === "") {
            setErrorPassword("Password Tidak Boleh Kosong")
        }

        if (username !== "" && password !== "") {
            API.postLogin({
                username: username,
                password: password
            }).then(function (response) {
                    // console.log(response.data.data.profilePicture.image)
                if (response.data.success === true) {
                    getLocalStorage(response.data)
                    history.push('/')
                } if (response.data.success === false) {
                    setAlertLogin(false);
                    setNotifLogin(true);
                    setAlertFail(response.data.err)
                    setTimeout(() => {
                        setNotifLogin(false);
                    }, 3000);
                }
                setErrorUsername("")
                setErrorPassword("")
                setNoMatch("")
            }).catch((err) => {
                return err
            })
        }
    }

    const getLocalStorage = (val) => {
        window.localStorage.setItem("role", val.data.organization.role.description);
        window.localStorage.setItem('users', val.data.username)
        window.localStorage.setItem('idUser', val.data._id)
        window.localStorage.setItem('org', val.data.organization._id)
        window.localStorage.setItem('orgpt', val.data.organization.name)
        window.localStorage.setItem('email', val.data.email)
        window.localStorage.setItem('imageUser', val.data.profilePicture.image)
        window.localStorage.setItem('notifUnclicked', true)

        let oldNotifGet = localStorage.getItem('oldNotif')
        let oldNotif = oldNotifGet !== null ? oldNotifGet.split(",") : null
        if(oldNotif !== null){
            if(oldNotif[1] !== val.data._id){
                localStorage.removeItem('oldNotif')
            }
        }
    }
    return (
        <div className={styles.bgWrapp}>
            <Row className="justify-content-center" style={{ paddingTop: "10%", paddingBottom: "28%" }}>
                <Container>
                    <Col md={10} lg={8} xl={6} style={{ marginLeft: "24%" }}>
                        <Alert
                            color={alertLogin ? "success" : "danger"}
                            isOpen={notifLogin}
                            style={{
                                width: "100%",
                                borderRadius: "6px",
                                textAlign: "center",
                                padding: "5px",
                                marginLeft: "0%",
                                marginTop: "10%",
                            }}>
                            {alertLogin ? `` : `Username atau Password Salah`}
                        </Alert>
                        <Card className="overflow-hidden">
                            <div className="" style={{ backgroundColor: "#fff" }}>
                                <Row>
                                    <Col className="col-12">
                                        <div className="text-black p-4 text-center">
                                            <h5 className="text-black">Selamat Datang !</h5>
                                            <p>ICMS Bakti Kominfo.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-0">
                                <div className="p-2">

                                    <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                                        {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}
                                        <div className="form-group">
                                            <AvField
                                                name="username"
                                                id="Usernmae"
                                                onChange={(e) => { setUsername(e.target.value) }}

                                                className="form-control"
                                                placeholder="Username"
                                                type="text"
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <AvField
                                                onChange={(e) => { setPassword(e.target.value) }}
                                                name="password"
                                                id="password"
                                                type="password"
                                                required
                                                placeholder="Password"
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <button className="btn btn-block waves-effect waves-light" type="submit" style={{ backgroundColor: "#00438B", color: "#fff" }}>Log In</button>
                                        </div>
                                        <div className="mt-4 mb-4 text-center">
                                            <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                        </div>
                                    </AvForm>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Container>
            </Row>
        </div>
    );
}

export default withRouter((Login));