import React from "react";

import { Row, Card, Col, Spinner } from "reactstrap";

// import "tui-chart/dist/tui-chart.css";
import { PieChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "./style.module.css";
import "./toastui.scss";

// import API from "../../../../services";

var theme = {
  // series: {
  series: {
    colors: ["#7697de", "#F0908E", "#55BA59"],
    label: {
      color: "#fff",
      fontFamily: "sans-serif",
    },
  },
  // },
};

export default function CardChart(props) {
  const DonutChartToast = (param) => {
    const data = {
      categories: ["Kualitas Video Dipilih"],
      series: [
        {
          name: "360p",
          data: param ? (param.quality ? param.quality["360"] ?? 0 : 0) : 0,
        },
        {
          name: "240p",
          data: param ? (param.quality ? param.quality["240"] ?? 0 : 0) : 0,
        },
        {
          name: "144p",
          data: param ? (param.quality ? param.quality["144"] ?? 0 : 0) : 0,
        },
      ],
    };

    const options = {
      chart: {
        width: 300,
        height: 350,
        format: function (value, chartType, areaType, valuetype, legendName) {
          if (areaType === "makingSeriesLabel") {
            // formatting at series area
            value = legendName;
          }

          return value;
        },
      },
      series: {
        radiusRange: ["40%", "100%"],
        showLabel: true,
        labelFilter: function (labelInfo) {
          return labelInfo.ratio > 0.3; // Less than 5% does not expose.
        },
      },
      tooltip: {
        suffix: "",
      },
      legend: {
        align: "bottom",
        visible: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_UB_Kualitas_Video",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("kualitasTheme", theme);
    options.theme = "kualitasTheme";

    return (
      <React.Fragment>
        <PieChart data={data} options={options} theme={theme} />
      </React.Fragment>
    );
  };



  return (
    <>
      <Row className="mt-5">
        <Col lg="12">
          <Card body style={{ boxShadow: "none", marginBottom: "0px" }}>
            <div className="p-3">
              <Row>
                {" "}
                <div className={`${style.titleCardBI}`}>KUALITAS VIDEO DIPILIH</div>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mt-1 mx-auto">
                    <div className="d-flex justify-content-center align-items-center">
                      {props.loading ? (
                        <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                          <Spinner color="primary" />
                        </div>
                      ) : props.data && props.data.length !== 0 ? (
                        props.data.quality ? (
                          props.data.quality['144'] == 0 &&
                          props.data.quality['240'] == 0 &&
                          props.data.quality['360'] == 0 ? (
                            <div style={{ marginTop: "120px" }}>
                              <span className={style.titleCardBI} style={{ fontSize: "35px" }}>
                                0
                              </span>
                            </div>
                          ) : (
                            DonutChartToast(props.data)
                          )
                        ) : (
                          <div> 0 </div>
                        )
                      ) : (
                        <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                          <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg="6" style={{ display: "flex", alignItems: "center" }}>
                  <Row className="p-4">
                    <Col
                      xs="12"
                      style={{
                        fontWeight: "normal",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                      className={style.titleKualitasVideo}>
                      <div>
                        <span
                          className="mdi mdi-checkbox-blank-circle mr-3"
                          style={{ color: "#7697de" }}
                        />
                        <span>360p</span>
                      </div>
                      <div>
                        {props.loading ? (
                          <div>
                            <Spinner color="primary" />
                          </div>
                        ) : (
                          <span className={style.valueKualitasVideo}>
                            {props.data
                              ? props.data.quality
                                ? props.data.quality["360"] ?? 0
                                : 0
                              : 0}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col
                      xs="12"
                      style={{
                        fontWeight: "normal",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                      className={style.titleKualitasVideo}>
                      <div>
                        <span
                          className="mdi mdi-checkbox-blank-circle mr-3"
                          style={{ color: "#F0908E" }}
                        />
                        <span>240p</span>
                      </div>
                      <div>
                        {props.loading ? (
                          <div>
                            <Spinner color="primary" />
                          </div>
                        ) : (
                          <span className={style.valueKualitasVideo}>
                            {props.data
                              ? props.data.quality
                                ? props.data.quality["240"] ?? 0
                                : 0
                              : 0}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col
                      xs="12"
                      style={{
                        fontWeight: "normal",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                      className={style.titleKualitasVideo}>
                      <div>
                        <span
                          className="mdi mdi-checkbox-blank-circle mr-3"
                          style={{ color: "#55BA59" }}
                        />
                        <span>144p</span>
                      </div>
                      <div>
                        {props.loading ? (
                          <div>
                            <Spinner color="primary" />
                          </div>
                        ) : (
                          <span className={style.valueKualitasVideo}>
                            {props.data
                              ? props.data.quality
                                ? props.data.quality["144"] ?? 0
                                : 0
                              : 0}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Col
                  lg="3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <div className="text-center p-4" style={{ borderLeft: "1px solid #C0C0C0" }}>
                    <Row>
                      <Col xs="12">
                        <span className={style.titleKualitasVideo}>RATA-RATA WAKTU PLAY VIDEO</span>
                      </Col>
                    </Row>
                    <Row>
                      {props.loading ? (
                        <Col xs="12" style={{ marginTop: "20px" }}>
                          <Spinner color="primary" />
                        </Col>
                      ) : (
                        <Col xs="12" className={style.valuePendidikan}>
                          {props.data ? props.data.education.sd_sederajat ?? 0 : 0}
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col> */}
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
