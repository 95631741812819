import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


// users
// import user1 from '../../../assets/images/users/avatar-1.jpg';
// import logout from '../../../assets/icon/iconBack.svg';

const ProfileMenu = (props) => {
    let role = window.localStorage.getItem("role")

    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);

    const [username, setusername] = useState(
        window.localStorage.getItem("users")
    );
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const history = useHistory();

    // const role = window.localStorage.getItem("users");
    const imageUser = window.localStorage.getItem("imageUser")

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.displayName);
            }
            else if ((process.env.REACT_APP_DEFAULTAUTH === 'fake') || (process.env.REACT_APP_DEFAULTAUTH === 'jwt')) {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.username);
            }
        }
    }, [props.success]);

    const logout = () => {
        // history.push('/homepage');
        window.localStorage.removeItem('authUser')
        window.localStorage.removeItem('users')
        let oldNotif = localStorage.getItem('oldNotif')
        window.localStorage.clear()
        localStorage.setItem("oldNotif", oldNotif)
        window.location.href = '/login'
        history.push('/login');
    }
    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    <span className="d-none d-xl-inline-block ml-2 mr-1">{username}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    <img className="rounded-circle header-profile-user" src={`${process.env.REACT_APP_BE_URL}/${imageUser}`} alt="Header Avatar" />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem style={{marginLeft : "2px"}}> 
                        <Link to={
                            role == "Artikel Admin"? 
                            "/adminartikel/profile"
                            : role == "SuperAdmin"?
                            "/superadmin/profile"
                            : role == "Admin"?
                            "/admin/profile"
                            : role == "Support Center"?
                            "/support-center/profile"
                            : ""
                        } >
                            <i className="bx bx-user font-size-16 align-middle" style={{marginRight : "6px"}}></i>
                            {props.t('Profile')}  
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    {/* <Link className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{props.t('Logout')}</span>
                    </Link> */}
                    <Button color="" style={{marginLeft : "12px"}} onClick={logout}> <i className="bx bx-log-out-circle font-size-16 align-middle" style={{marginRight : "2px"}}></i> Logout</Button>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { error, success } = state.Profile;
    return { error, success };
}

export default withRouter(connect(mapStatetoProps, {})(withNamespaces()(ProfileMenu)));

