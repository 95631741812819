import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  let judul = "Site Checker Bulk";

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`Site Checker Bulk ${moment(Date()).format("DD MMMM YYYY HH:mm:ss")}`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>IP Address</th>
          <th>Subnet IP Terhubung</th>
          <th>Nama ISP</th>

          <th>Lokasi AI</th>
          <th>Provinsi</th>
          <th>Kabupaten / Kota</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Tanggal Integrasi</th>
          <th>Tanggal Terakhir Akses</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{data.ip_address}</td>
            <td>{data.ipAlternatif}</td>
            <td>{data.namaIsp}</td>
            <td>{data.namaLokasi}</td>
            <td>{data.provinsi}</td>
            <td>{data.kabupaten_kota}</td>
            <td>{data.latitude}</td>
            <td>{data.longitude}</td>
            <td>
              {data.tanggalIntegrasi === "-"
                ? "-"
                : `${moment(data.tanggalIntegrasi).format("DD MMMM YYYY")}\n${moment(
                    data.tanggalIntegrasi
                  ).format("HH:mm:ss")}`}
            </td>
            <td>
              {data.tanggalTerakhirAkses === "-"
                ? "-"
                : `${moment(data.tanggalTerakhirAkses).format("DD MMMM YYYY")}\n${moment(
                    data.tanggalTerakhirAkses
                  ).format("HH:mm:ss")}`}
            </td>
            <td>{data.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
