import React from 'react'
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import './style.css';

function PopupBanner(props) {
    let b = Object.keys(props.data).length === 0 
            ? "" 
            : typeof props.data.banner.content_id === 'undefined' 
                ? props.data.banner 
                : props.data.banner.content_id
    return (
        <MDBContainer>
            <MDBModal size="lg" isOpen={props.modalBanner} toggle={props.toggleBanner} centered={true}>
                <MDBModalHeader toggle={props.toggleBanner}>Banner</MDBModalHeader>
                <MDBModalBody>
                    <h5 style={{textAlign: 'center'}}>{b.category}</h5>
                    <div className='image-wrapper'>
                        <img src={`${process.env.REACT_APP_BE_URL}/${b.details}`} className='image-container' alt="Banner" />
                    </div>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    );
};
export default PopupBanner;