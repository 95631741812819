import React, { useState, useMemo } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from "../../../assets/images/back.png";

export const FilterProvince = (props) => {
  const { filter, clicked } = props;
  //memoize props list
  let listProvince = useMemo(() => {
    return props.list;
  }, [props.list]);
  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle caret color="primary" className="dropdown-rekomendasi">
          <div>{filter.value === "" ? `Pilih ${props.type}` : filter.value}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu style={{ height: "85px" }} className="dropdown-menus disabled browser-default">
          {listProvince.map((fc, i) => (
            <MDBDropdownItem
              active={filter.value === fc}
              onClick={() => clicked(fc.name, fc._id, props.type)}
              key={i}>
              {fc.name}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

export const FilterCategory = (props) => {
  const { filter, clicked } = props;
  let listCategory = useMemo(() => {
    return props.list;
  }, [props.list]);
  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle caret color="primary" className="dropdown-rekomendasi">
          <div>{filter === "" ? `Pilih ${props.type}` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus disabled browser-default">
          {listCategory.map((fc, i) => (
            <MDBDropdownItem
              active={filter === fc}
              onClick={() => clicked(fc, "", props.type)}
              key={i}>
              {fc}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

