import React, { useMemo, useState } from "react";
import AsyncSelect from "react-select/async";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from "../../assets/images/back.png";
import { useEffect } from "react";
import API from "../../services";
export const FilterIP = (props) => {
  const { filter, clicked, location } = props;
  //memoize props list
  let listIP = useMemo(() => {
    return props.list;
  }, [props.list]);
  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle disabled={location == ""} color="primary" className="dropdown-kategori">
          <div className="wrapperDropdown">{filter === "" ? `Pilih ${props.type}` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus disabled browser-default">
          {listIP.map((fc, i) => (
            <MDBDropdownItem active={filter.value === fc} onClick={() => clicked(fc)} key={i}>
              {fc}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

export const FilterLocation = (props) => {
  const { clicked, list } = props;
  const options = list.map((option) => ({ value: option, label: option, color: "#696969" }));

  const customStyle = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "#2c3280" : "",
      backgroundColor: state.isSelected ? "#696969" : "",
      textAlign: "left",
      cursor: "pointer",
    }),
    control: (base) => ({
      ...base,
      border: 0,
      background: "#F8F9FA",
      borderRadius: "20px",
      boxShadow: "none",
    }),
  };
  const handleLocation = (selectedLocation) => {
    clicked(selectedLocation.value);
  };
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };
  const filterColors = (inputValue) => {
    return options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  return (
    <div className="wrapperDropdownKurator">
      <AsyncSelect
        styles={customStyle}
        classNamePrefix="select2-selection"
        className="dropdownKategori"
        placeholder="Pilih Lokasi"
        cacheOptions
        loadOptions={loadOptions}
        options={options}
        onChange={handleLocation}
      />
    </div>
  );
};
