import React, { useEffect, useState, useRef } from "react";
import { MDBModal } from "mdbreact";
import { Button } from "reactstrap";
import API from "../../../../../services";
import style from "./style.css";

const ProvinceModal = (props) => {
  const [provinceList, setProvinceList] = useState([]);

  const getProvinceList = () => {
    API.getAllProvince()
      .then((res) => {
        setProvinceList(res.data.data);
      })
      .catch((err) => {
        console.log("err => ", err);
      });
  };

  // provinceItem = { name: "ACEH" }
  const setSelectedProvince = (provinceItem, isChecked) => {
    var newSelected = props.selectedProvince;

    if (isChecked) {
      newSelected.push(provinceItem);

      props.setSelectedAll(false);

      props.setSelectedProvince(newSelected);
    } else {
      const provinceIndex = newSelected.findIndex((item) => {
        return item.name === provinceItem.name;
      });

      newSelected.splice(provinceIndex, 1);

      if (newSelected.length === 0) {
        props.setSelectedAll(true);
      }

      props.setSelectedProvince(newSelected);
    }

    console.log(props.selectedProvince);
  };

  const checkedProvince = (provinceName) => {
    var newSelected = props.selectedProvince;

    const provinceIndex = newSelected.findIndex((item) => {
      return item.name === provinceName;
    });

    if (provinceIndex > -1) {
      return true;
    }
    return false;
  };

  const handleClickOk = () => {
    props.handleSearch();
    props.toggleModalProvince();
  };

  const handleCheckAllProvince = (isChecked) => {
    if (isChecked) {
      document.querySelectorAll("input[type=checkbox]").forEach((el) => (el.checked = false));
    }
  };

  return (
    <MDBModal
      id="modalProvince"
      isOpen={props.modalProvince}
      toggle={props.toggleModalProvince}
      size="lg"
      showModal={() => {
        getProvinceList();
      }}
      hideModal={() => {}}>
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleEdit" style={{ color: "#2c3280" }}>
              PROVINCE LIST
            </p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={props.toggleModalProvince}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="row">
          <div className="container" style={{ margin: 10 }}>
            <div className={`col-md-3 float-left ${style.myBorder}`}>
              <div>
                <label htmlFor="all">
                  <input
                    style={{ outline: "1px solid grey" }}
                    type="checkbox"
                    id="all"
                    defaultChecked={props.selectedAll}
                    checked={props.selectedAll}
                    onClick={(event) => {
                      props.setSelectedAll(!props.selectedAll);
                      props.setSelectedProvince([]);
                      handleCheckAllProvince(event.target.checked);
                    }}></input>
                  <span style={{ marginLeft: 10, color: "#2c3280" }}>SEMUA PROVINSI</span>
                </label>
              </div>
              {provinceList.length > 0
                ? provinceList
                    .filter((item, index) => {
                      return 0 <= index && index <= 10;
                    })
                    .map((item, index) => {
                      return (
                        <div className={style.itemProvince}>
                          <label htmlFor={item.name}>
                            <input
                              style={{ outline: "1px solid grey" }}
                              type="checkbox"
                              id={item.name}
                              defaultChecked={checkedProvince(item.name)}
                              onClick={(event) => {
                                const p = { name: item.name };
                                setSelectedProvince(p, event.target.checked);
                              }}></input>

                            <span style={{ marginLeft: 10, color: "#2c3280" }}>
                              {index + 1}. {item.name}
                            </span>
                          </label>
                        </div>
                      );
                    })
                : ""}
            </div>
            <div className={`col-md-5 float-left ${style.myBorder}`}>
              {provinceList.length > 0
                ? provinceList
                    .filter((item, index) => {
                      return 11 <= index && index <= 22;
                    })
                    .map((item, index) => {
                      return (
                        <div className={style.itemProvince}>
                          <label htmlFor={item.name}>
                            <input
                              style={{ outline: "1px solid grey" }}
                              type="checkbox"
                              id={item.name}
                              defaultChecked={checkedProvince(item.name)}
                              onClick={(event) => {
                                const p = { name: item.name };
                                setSelectedProvince(p, event.target.checked);
                              }}></input>
                            <span style={{ marginLeft: 10, color: "#2c3280" }}>
                              {index + 12}. {item.name}
                            </span>
                          </label>
                        </div>
                      );
                    })
                : ""}
            </div>
            <div className={`col-md-4 float-left ${style.myBorder}`}>
              {provinceList.length > 0
                ? provinceList
                    .filter((item, index) => {
                      return 23 <= index && index <= 34;
                    })
                    .map((item, index) => {
                      return (
                        <div className={style.itemProvince}>
                          <label htmlFor={item.name}>
                            <input
                              style={{ outline: "1px solid grey" }}
                              type="checkbox"
                              id={item.name}
                              defaultChecked={checkedProvince(item.name)}
                              onClick={(event) => {
                                const p = { name: item.name };
                                setSelectedProvince(p, event.target.checked);
                              }}></input>
                            <span style={{ marginLeft: 10, color: "#2c3280" }}>
                              {index + 24}. {item.name}
                            </span>
                          </label>
                        </div>
                      );
                    })
                : ""}
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }} className="text-center">
          <Button style={{ backgroundColor: "#2c3280" }} onClick={handleClickOk}>
            Ok
          </Button>
        </div>
      </div>
    </MDBModal>
  );
};

export default ProvinceModal;
