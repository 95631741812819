import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Alert, Button } from "reactstrap";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../components/Filter/filter";
import FilterReport from "../Filter";
import _ from "lodash";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import DownWhite from "../../../assets/images/backwhite.png";
// ------------------Component----------------
import ModalDetail from "../Modal/DetailKontenReportVideo";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "../style.css";

import { exportPDF } from "../../../components/Admin/Download/KontenStatistik/PDF/DownloadPDF";
import DownloadXLS from "../Download/DownloadXLS";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import { detailData, setTabDetail } from "../../../store/actions";
// import ConfirmationCancel from "../../ConfirmationCancel"

const TableStatVideo = (props) => {
  const searchRef = useRef();
  const [lokasi, setLokasi] = useState("Semua Lokasi");
  const [idProvince, setIdProvince] = useState("");
  const [province, setProvince] = useState("");
  const [date, setDate] = useState("");

  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  //handle data xls
  const [dataVideo, setDataVideo] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showTableVideo, setShowTableVideo] = useState(false);

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  // console.log("data", dataVideo);
  // console.log("dataDetail", dataDetail);

  // set download data
  useEffect(() => {
    // props.setData(rows);
    !loading &&
      dataVideo &&
      dataVideo.length !== 0 &&
      setRows(
        dataVideo.map((e, i) => ({
          no: i + 1,
          tanggal: e.date ?? "-",
          total_impresi: e.impress ?? "-",
          total_klik: e.click ?? "-",
          total_pengguna_unik: e.unique_dfp ?? "-",
          ...(lokasi !== "Semua Lokasi" && {
            detail:
              e.impress !== "Data tidak ditemukan"
                ? [
                    <Button
                      style={{
                        backgroundColor: "#2C3280",
                        border: "1px solid #2C3280",
                        borderRadius: "15px",
                        padding: "2px 5px 2px 5px",
                      }}
                      onClick={() => {
                        setDate(e.date);
                        toggleModalPreviewDetail();
                      }}>
                      Lihat Detail
                    </Button>,
                  ]
                : "",
          }),
        }))
      );
  }, [dataVideo, loading]);

  //modal preview detail
  const [modalPreviewDetail, setModalPreviewDetail] = useState(false);
  const [dataModalPreviewDetail, setDataModalPreviewDetail] = useState({});
  const toggleModalPreviewDetail = () => {
    setModalPreviewDetail(!modalPreviewDetail);
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tanggal",
        field: "tanggal",
        sort: "asc",
        width: 100,
      },
      {
        label: "Total Impresi",
        field: "total_impresi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Klik",
        field: "total_klik",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Pengguna Unik",
        field: "total_pengguna_unik",
        sort: "asc",
        width: 150,
      },
      {
        label: "",
        field: "detail",
        sort: "disabled",
        // width: 150,
      },
    ],

    rows: !rows
      ? [
          {
            provinsi: [
              <Row key="a" className="justify-content-md-center">
                Terjadi Kesalahan
              </Row>,
            ],
          },
        ]
      : rows,
  };

  const handleDownloadPDF = () => {
    exportPDF(dataVideo, props.activeTab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* MODAL */}
          {dataDetail && (
            <ModalDetail
              modal={modalPreviewDetail}
              toggle={toggleModalPreviewDetail}
              activeTab={props.activeTab}
              idProvince={idProvince}
              province={province}
              date={date}
              lokasi={lokasi}
              data={dataVideo}
              dataDetail={dataDetail}
            />
          )}
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  {/* FILTER */}
                  <FilterReport
                    setData={setDataVideo}
                    setDataDetail={setDataDetail}
                    data={dataVideo}
                    setShowTableVideo={setShowTableVideo}
                    setLoading={setLoading}
                    activeTab={props.activeTab}
                    setLokasi={setLokasi}
                    setIdProvince={setIdProvince}
                    setProvince={setProvince}
                    setError={setError}
                  />
                  {showTableVideo ? (
                    loading ? (
                      <div>
                        <h1>
                          <Skeleton />
                        </h1>
                        <Skeleton count={10} />
                      </div>
                    ) : error ? (
                      <div className="text-center">
                        <Alert color="danger">Mohon Maaf Terjadi Kesalahan</Alert>
                      </div>
                    ) : dataVideo && dataVideo.length !== 0 ? (
                      <>
                        {/* XLS DAN PDF DOWNLOAD */}
                        {/* <div
                          style={{
                            justifyContent: "flex-end",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <div className="wrapperAction">
                            <MDBNavLink to="#" className="textDownload">
                              Download
                            </MDBNavLink>
                            <ReactHTMLTableToExcel
                              id="TableXLS"
                              className="DownloadTableXLS"
                              table="DownloadTableXLS"
                              filename={`Conten_Report`}
                              sheet="users"
                              buttonText="XLS"
                            />
                          </div>
                        </div>
                        <DownloadXLS
                          id="DownloadTableXLS"
                          className="DownloadTableXLS"
                          data={rows}
                          activeTab={props.activeTab}
                        /> */}
                        <div
                          className="wrapperDropdownResponsive"
                          style={{
                            width: "25%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginLeft: "auto",
                            marginRight: "0.75%",
                            marginBottom: "2%",
                          }}>
                          <MDBDropdown
                            style={{ width: "100% !important" }}
                            className="dropdown-download-doc">
                            <MDBDropdownToggle
                              caret
                              color="primary"
                              className="dropdown-download font-weight-bold">
                              <div className="wrapper-selected">Download</div>
                              <div>
                                <img src={DownWhite} alt="dropdown-icons" />
                              </div>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className="dropdown-menus dropdown-show">
                              <MDBDropdownItem>
                                <ReactHTMLTableToExcel
                                  id="TableXLS"
                                  className="DownloadTableXLSDropdown"
                                  table="DownloadTableXLS"
                                  filename={`Conten_Report`}
                                  sheet="users"
                                  buttonText="XLS"
                                />
                              </MDBDropdownItem>
                              <DownloadXLS
                                id="DownloadTableXLS"
                                className="DownloadTableXLS"
                                data={rows}
                                activeTab={props.activeTab}
                              />
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </div>
                        <MDBDataTable
                          id="tableKontenReport"
                          noBottomColumns={true}
                          className="text-center"
                          responsive
                          disableRetreatAfterSorting
                          striped
                          bordered
                          data={data}
                          displayEntries={false}
                          entriesOptions={[5, 10, 15, 20]}
                          pagesAmount={5}
                          entries={10}
                          searching={false}
                        />
                      </>
                    ) : (
                      <div className="text-center mt-5">Terjadi Kesalahan</div>
                    )
                  ) : (
                    <div className="text-center mt-5">Silahkan Isi Filter Terlebih Dahulu</div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

// const mapStateToProps = (state) => {
//   const { data } = state.dataReducer;
//   return { data };
// };

// export default connect(mapStateToProps, { detailData, setTabDetail })(TableStatVideo);
export default TableStatVideo;
