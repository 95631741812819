import React, { useState } from "react";
import { MDBModal } from "mdbreact";
import "./style.css";

// ------------------API-----------------------
import API from "../../../../../services";

const ModalLolos = ({ modalLolos, toggleModalLolos, dataModalLolos, handleAlert, fetchData }) => {
  return (
    <MDBModal isOpen={modalLolos} toggle={toggleModalLolos} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleApproved">LOLOS KURASI</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalLolos}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">{dataModalLolos.title ?? "-"}</h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleApproved">Konten ini Lolos Kurasi pada tanggal</h4>
            <h4 className="subTitleApproved d-block">{dataModalLolos.tgl ?? "-"}</h4>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalLolos;
