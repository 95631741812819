// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_headText__1vfwq {\n\ttext-align: center !important;\n\tmargin-left: 30px !important;\n\tcolor: #C42127 !important;\n}\n\n.style_title__3cT6- {\n\tcolor: #333;\n\tfont-weight: bold;\n\ttext-transform: uppercase;\n}\n\n.style_sub_title__18oYk {\n\tcolor: #777;\n}\n\n.style_text__5sFqQ {\n\tcolor: #777;\n\tfont-weight: bold;\n\tmargin-bottom: 50px;\n}\n\n.style_input_alasan__2q3WF {\n\ttext-align: center;\n\theight: 150px;\n\twidth: 50%;\n\tdisplay: flex;\n\tbackground-color: #f4f5ff;\n\tborder: none !important;\n\tmargin-bottom: 6% !important;\n}\n\n.style_btn_cancel__3Qlq0 {\n\tcursor: pointer;\n}\n\n.style_wrapper__3MSIS {\n\tmargin-bottom: 50px;\n\tdisplay: block !important;\n}\n\n.style_btn_confirm__2vpxY {\n\tbackground-color: #e15050 !important;\n\tpadding: 10px 40px !important;\n\tcolor: #fff !important;\n\tcursor: pointer;\n\tfont-size: 18px !important;\n\tmargin-bottom: 10px !important;\n}\n\n\n", ""]);
// Exports
exports.locals = {
	"headText": "style_headText__1vfwq",
	"title": "style_title__3cT6-",
	"sub_title": "style_sub_title__18oYk",
	"text": "style_text__5sFqQ",
	"input_alasan": "style_input_alasan__2q3WF",
	"btn_cancel": "style_btn_cancel__3Qlq0",
	"wrapper": "style_wrapper__3MSIS",
	"btn_confirm": "style_btn_confirm__2vpxY"
};
module.exports = exports;
