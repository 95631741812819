import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLSDFP" className="DownloadTableXLS">
      <thead>Data Device Fingerprint</thead>
      <thead>
        <tr>
          <th>Provinsi</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Jumlah</th>
        </tr>
      </thead>
      <tbody>
        {data !== undefined &&
          data.brand_type.map((e, index) => (
            <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{e.name ? e.name ?? "-" : "-"}</td>
              <td>{e.count ? e.count ?? "-" : "-"}</td>
            </tr>
          ))}
      </tbody>
      <thead>Data Device Fingerprint</thead>
      <thead>
        <tr>
          <th>Provinsi</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Jumlah</th>
        </tr>
      </thead>
      <tbody>
        {data !== undefined &&
          data.brand_type.map((e, index) => (
            <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{e.name ? e.name ?? "-" : "-"}</td>
              <td>{e.count ? e.count ?? "-" : "-"}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
