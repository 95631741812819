import moment from "moment";
import _ from "lodash";

export function generateDocXLS(data) {
  try {
    let dataTable = [];
    // buat rumus
    dataTable.push(["No", "Nama", "Lokasi", "Tanggal", "Jam", "Rating", "Komentar"]);
    // for (let index = 0; index < data.data.length; index++) {
    // 	const element = data.data[index];

    for (let index = 0; index < data.length; index++) {
      dataTable.push([
        index + 1,
        data[index].name ? data[index].name : "-",
        data[index].location,
        moment(data[index].tanggal_pembuatan).format("DD-MM-YYYY"),
        moment(data[index].tanggal_pembuatan).format("hh:mm:ss"),
        data[index].rating,
        data[index].comment,
      ]);
    }
    return dataTable;
  } catch (error) {
    console.log(error);
  }
}

const DocumentExcel = (props) => {
  return "";
};

export default DocumentExcel;
