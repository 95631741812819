import React, { useState, useEffect } from "react";
import { MDBModal, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./style.css";
import moment from "moment";
// ------------------API-----------------------
import API from "../../../../../../services";
import _ from "lodash";
import { Row, Container, FormGroup, Label, Input } from "reactstrap";
import ModalDetailPreview from "../../PreviewDetail"

const ModalDetailPenggunaCP = ({
  modalDetailPengguna,
  toggleModalDetailPengguna,
  dataModalDetailPengguna,
}) => {
  //preview ktp
  const [modalDetailPreview, setModalDetailPreview] = useState(false)
  const [dataModalDetailPreview, setDataModalDetailPreview] = useState({})
  const handleModalDetail = (title,url) => {
    toggleModalDetailPengguna()
    setModalDetailPreview(true)
    setDataModalDetailPreview({
      title: title,
      url: url
    })
  }
  let data = dataModalDetailPengguna !== undefined ? dataModalDetailPengguna.data : [];
  let assetKTP = data?
      data.doc_penunjang
        ? data.doc_penunjang.length !== 0
          ? data.doc_penunjang[0].fileKtp
            ? data.doc_penunjang[0].fileKtp.length !== 0
              ? `${process.env.REACT_APP_BE_URL}/${data.doc_penunjang[0].fileKtp[0].path}` ??
                ""
              : ""
            : ""
          : ""
        : ""
      : ""
  let assetSuratTugas =  data? 
      data.doc_penunjang
        ? data.doc_penunjang.length !== 0
          ? data.doc_penunjang[0].suratTugas
            ? data.doc_penunjang[0].suratTugas.length !== 0
              ? `${process.env.REACT_APP_BE_URL}/${data.doc_penunjang[0].suratTugas[0].path}` ??
                ""
              : ""
            : ""
          : ""
        : ""
      : ""
  let assetSuratPernyataan = data ? 
      data.doc_penunjang
        ? data.doc_penunjang.length !== 0
          ? data.doc_penunjang[0].suratPernyataan
            ? data.doc_penunjang[0].suratPernyataan.length !== 0
              ? `${process.env.REACT_APP_BE_URL}/${data.doc_penunjang[0].suratPernyataan[0].path}` ??
                ""
              : ""
            : ""
          : ""
        : ""
      : ""
  let assetSwafoto = data? data.doc_penunjang
  ? data.doc_penunjang.length !== 0
    ? data.doc_penunjang[0].fileSelfie
      ? data.doc_penunjang[0].fileSelfie.length !== 0
        ? `${process.env.REACT_APP_BE_URL}/${data.doc_penunjang[0].fileSelfie[0].path}` ??
          ""
        : ""
      : ""
    : ""
  : ""
  : ""
  if (data !== undefined)
    return (
      <>
      <ModalDetailPreview
      dataModalDetailPreview={dataModalDetailPreview}
      modalDetailPreview={modalDetailPreview}
      toggleModalDetailPreview={() => setModalDetailPreview(!modalDetailPreview)}
      />

      <MDBModal
        id="ModalDetailPengguna"
        isOpen={modalDetailPengguna}
        toggle={toggleModalDetailPengguna}
        size="lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="w-100 text-center">
              <p className="topTitleEdit">DETAIL PENGGUNA</p>
            </div>
            <button
              type="button"
              className="close pull-right"
              aria-label="Close"
              onClick={toggleModalDetailPengguna}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
            <div className="w-40 mx-auto wrapperSubTitleVerif">
              {/* <h4 className="subTitle">{data.title.length == 0 ? data.details.title : data.title}</h4> */}
            </div>
            <Container>
              <FormGroup>
                <Label for="Username">Username</Label>
                <Input
                  type="text"
                  name="username"
                  id="Username"
                  value={data.username}
                  className="inputWrapper"
                />
              </FormGroup>
              <FormGroup>
                <Label for="Email">Email</Label>
                <Input
                  type="text"
                  name="Email"
                  id="Email"
                  value={data.email}
                  className="inputWrapper"
                />
              </FormGroup>
              <FormGroup>
                <Label for="Nama Lengkap">Nama Lengkap</Label>
                <Input
                  type="text"
                  name="Nama Lengkap"
                  id="Nama Lengkap"
                  value={data.name}
                  className="inputWrapper"
                />
              </FormGroup>
              <FormGroup>
                <Label for="Nomor Telepon">Nomor Telepon</Label>
                <Input
                  type="text"
                  name="Nomor Telepon"
                  id="Nomor Telepon"
                  value={data.phone}
                  className="inputWrapper"
                />
              </FormGroup>
              <Row>
                <div className="col-lg-6">
                  <p>Surat Tugas Dari Instansi</p>
                  <div
                    style={{
                      width: "100%",
                      height: "100px",
                      // backgroundColor: "black",
                    }}>
                    <iframe
                      allowFullScreen="allowfullscreen"
                      style={{ maxHeight: "100%", maxWidth: "100%" }}
                      src={assetSuratTugas}
                      alt="surat tugas"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <p>KTP</p>
                  <div
                    onClick={() => {
                      handleModalDetail("KTP",assetKTP)
                    
                    }}
                    style={{
                      width: "100%",
                      height: "100px",
                      // backgroundColor: "black",
                    }}>
                    <img
                      style={{ maxHeight: "100%", maxWidth: "100%" }}
                      src={assetKTP}
                      alt="ktp"
                    />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-lg-6">
                  <p>Surat Pernyataan</p>
                  <div
                    style={{
                      width: "100%",
                      height: "100px",
                      // backgroundColor: "black",
                    }}>
                    {" "}
                    <iframe
                      allowFullScreen="allowfullscreen"
                      style={{ maxHeight: "100%", maxWidth: "100%" }}
                      src={assetSuratPernyataan}
                      alt="surat pernyataan"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <p>Swafoto ktp</p>
                  <div
                  onClick={() => {
                    handleModalDetail("Swafoto KTP",assetSwafoto)
                  }}
                    style={{
                      width: "100%",
                      height: "100px",
                      // backgroundColor: "black",
                    }}>
                    <img
                      style={{ maxHeight: "100%", maxWidth: "100%" }}
                      src={assetSwafoto}
                      alt="swafoto"
                    />
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </MDBModal>
      </>
    );
  else {
    return <></>;
  }
};

export default ModalDetailPenggunaCP;
