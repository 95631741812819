import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLSDemografi" className="DownloadTableXLS">
      <thead>{props.title}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Provinsi</th>
          <th>Kabupaten/Kota</th>
          <th>Total Kunjungan</th>
          <th>Total Kunjungan Laki-Laki</th>
          <th>Total Kunjungan Perempuan</th>
          <th>Total Kunjungan SD</th>
          <th>Total Kunjungan SMP Sederajat</th>
          <th>Total Kunjungan SMA Sederajat</th>
          <th>Total Kunjungan Pendidikan Tinggi Sederajat</th>
          <th>Total Kunjungan Berstatus Menikah</th>
          <th>Total Kunjungan Berstatus Lajang</th>
          <th>Usia 0-10 Tahun</th>
          <th>Usia 11-20 Tahun</th>
          <th>Usia 21-30 Tahun</th>
          <th>Usia 31-40 Tahun</th>
          <th>Usia 41-50 Tahun</th>
          <th>Usia 51-60 Tahun</th>
          <th>Usia 61-70 Tahun</th>
          <th>Usia 71-80 Tahun</th>
          <th>Usia 81-90 Tahun</th>
          <th>Usia Lebih dari 91 Tahun</th>
        </tr>
      </thead>
      <tbody>
        {data !== undefined &&
          data.map((e, index) => (
            <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>{index + 1}</td>
              <td>{e.provinsi ? e.provinsi ?? "-" : "-"}</td>
              <td>{e.kab_kota ? e.kab_kota ?? "-" : "-"}</td>
              <td>{e.gender ? e.gender.pria + e.gender.wanita ?? "-" : "-"}</td>
              <td>{e.gender ? e.gender.pria ?? "-" : "-"}</td>
              <td>{e.gender ? e.gender.wanita ?? "-" : "-"}</td>
              <td>{e.education ? e.education.sd_sederajat ?? "-" : "-"}</td>
              <td>{e.education ? e.education.smp_sederajat ?? "-" : "-"}</td>
              <td>{e.education ? e.education.sma_sederajat ?? "-" : "-"}</td>
              <td>{e.education ? e.education.pendidikan_tinggi_sederajat ?? "-" : "-"}</td>
              <td>{e.status ? e.status.menikah ?? "-" : "-"}</td>
              <td>{e.status ? e.status.lajang ?? "-" : "-"}</td>
              <td>{e.age ? e.age["1"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["2"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["3"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["4"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["5"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["6"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["7"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["8"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["9"] ?? "-" : "-"}</td>
              <td>{e.age ? e.age["10"] ?? "-" : "-"}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
