import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const DetailKontenContentPartnerAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/DetailKonten/ContentPartner" /* webpackChunkName: "DetailKontenContentPartnerAsync" */
    ),
  loading: Loading,
});

export default DetailKontenContentPartnerAsync;
