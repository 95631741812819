import React, { useEffect, useState, createRef, useRef } from "react";
import { findDOMNode } from "react-dom";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import "./style.css";
import { Container, Button, Row } from "reactstrap";
// ------------------API---------------------
import API from "../../../../../services";

// ------------------REDUX---------------------
import { connect } from "react-redux";
import { listJam } from "./const";
import { useHistory } from "react-router-dom";

const ModalJamTayang = (props) => {
  // console.log("props buatkonten", props.province);
  const [availableTime, setAvailableTime] = useState([]);
  const history = useHistory();
  let jam = [];
  const handleToggleBackground = (e, val, i) => {
    let counter1 = 1;
    let counter2 = 2;

    let durasi = parseInt(props.durasi);
    if (durasi == 1) {
      let timeAdded = [val];
      if (timeAdded.length == 1 && i <= listJam.length - 1 && availableTime.includes(val)) {
        jam.push(timeAdded[0]);
      } else {
        jam.push(null);
      }
    } else if (durasi == 2) {
      //if selected was in disable times
      let timeAdded = [...new Set([val, listJam[i + counter1]])];
      if (
        timeAdded.length == 2 &&
        i + counter1 <= listJam.length - 1 &&
        availableTime.includes(listJam[i + counter1])
      ) {
        jam.push(timeAdded[0], timeAdded[1]);
      } else {
        jam.push(null);
      }
    } else if (durasi == 3) {
      let timeAdded = [...new Set([val, listJam[i + counter1], listJam[i + counter2]])];
      if (
        timeAdded.length == 3 &&
        i + counter1 <= listJam.length - 1 &&
        availableTime.includes(listJam[i + counter1]) &&
        availableTime.includes(listJam[i + counter2])
      ) {
        jam.push(timeAdded[0], timeAdded[1], timeAdded[2]);
      } else {
        jam.push(null);
      }
    }
    handleSelected(jam);
  };
  const handleSelected = (jam) => {
    props.selectedJamTayang(jam);
  };
  const getTime = () => {
    let isEmergency = props.emergency ? "1" : "";
    let param = {
      start: props.startDate,
      end: props.endDate,
      content: props.activeTab,
      province: props.province,
      emergency: isEmergency,
      location: props.location
    };
    API.postCheckTimeAvailable(param)
      .then((res) => {
        if (res.data.success) {
          setAvailableTime(res.data.data);
        }
      })
      .catch((e) => {
        // console.log("jam tayang",e)
        history.push("/pages-500");
      });
  };

  useEffect(() => {
    if (props.startDate && props.endDate && props.province && props.location) {
      getTime();
    }
  }, [props.startDate, props.endDate, props.province, props.emergency, props.location]);

  return (
    <MDBContainer>
      <MDBModal
        size="lg"
        isOpen={props.modalJamTayang}
        toggle={props.toggleModalJamTayang}
        centered={true}>
        <MDBModalHeader centered toggle={props.toggleModalJamTayang}>
          Jam Tayang
        </MDBModalHeader>
        <MDBModalBody>
          <div className="mx-auto w-100 container text-center">
            <p className="textBlue">{props.selectedDate}</p>
            <p className="textBlue">{`${props.durasi} jam`}</p>
          </div>
          <Row>
            <Container>
              <div className="wrapperJamTayang mb-4">
                {listJam.map((val, i) => {
                  return (
                    <Button
                      id={`btn${i}`}
                      disabled={!availableTime.includes(val)}
                      className={
                        props.jamTayang.includes(val)
                          ? "textChecked btnJam"
                          : !availableTime.includes(val)
                          ? "textDisabled btnJam"
                          : "textUnchecked btnJam"
                      }
                      data-color="#E5E5E5"
                      onClick={(e) => handleToggleBackground(e, val, i)}
                      outline>
                      {val}
                    </Button>
                  );
                })}
              </div>
            </Container>
          </Row>
          <Row className="mt-2">
            <Button
              color=""
              onClick={props.toggleModalJamTayang}
              className="btn btn-rounded btnCekJam col-3 col-offset-2 mt-2 mx-auto">
              OK
            </Button>
          </Row>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
const mapStatetoProps = (state) => {
  const { dataAjukan } = state.dataReducer;
  return { dataAjukan };
};
export default connect(mapStatetoProps, null)(ModalJamTayang);
