import React, { useState, useEffect } from 'react'
import styles from '../../Assets/css/artikel.module.css'
import '../../Assets/css/mdbstyle.css'

import Header from '../../components/Header/Header'
import {
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Input,
    Button,
    Form,
    NavLink
} from "reactstrap";

import { Link, useLocation } from "react-router-dom";

import Loupe from '../../Assets/image/loupe.svg'
import Sent from '../../Assets/image/sent.svg'
import TD from '../../Assets/image/TD.svg'
import TU from '../../Assets/image/TU.svg'
import FBW from '../../Assets/image/IconFBWhite.svg'
import TWW from '../../Assets/image/IconTwitterWhite.svg'

import dummy from './dummy.json'

function ArtikelPage(props) {

    const location = useLocation();
	const idGet = location.pathname
        .substr(location.pathname.lastIndexOf("/") + 1)
        .replace("-", " ")
        .toUpperCase();

    const [data, setData] = useState({})
    const [data1, setData1] = useState({})
    const [data2, setData2] = useState({})
    
    async function fetchData() {
        let indexData = dummy.data.findIndex(
            (dataDummy) => dataDummy.id === idGet
        );
        setData(dummy.data[indexData])
        setData1(dummy.data[indexData === 0 ? 1 : indexData === 1 ? 0 : 0])
        setData2(dummy.data[indexData === 0 ? 2 : indexData === 1 ? 2 : 1])
    }

    useEffect(() => {
        fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idGet]);

    return (
        <div>
            <Header/>
            <div className={styles.lastSec}>
                <Container>
                    <Row className={styles.paddingTope}>
                        <Col xs="12" md="8">
                            <Card style={{padding:'20px'}}>
                                <CardTitle>{data.judul}</CardTitle>
                                <CardBody style={{padding:'0px'}}>
                                    <Row>
                                        <Col>
                                            <div className={styles.imageWrapper}>
                                                <a href={data.image} target="_blank" rel="noopener noreferrer">  
                                                    <img className={styles.imageMain} src={data.image}></img>
                                                </a>
                                            </div> 
                                        </Col>
                                    </Row>
                                    <p className={styles.displayLinebreak}>
                                        {data.content}
                                    </p>

                                    <p className={styles.displayLinebreak} style={{fontSize:'10px'}}>{`Source : ${data.source} \n Image Source :  ${data.image_source}`}</p>

                                    <Container className={styles.sectBorder}/>
                                    
                                    <Row>
                                        <Col>
                                            <div style={{marginTop:'10px', textAlign:'left'}}>
                                                Apakah anda menyukai artikel ini
                                                <a>
                                                    <img style={{margin:"0px", marginLeft:'10px'}} src={TU} alt="TU"/>
                                                </a>
                                                <a>
                                                    <img style={{margin:"0px", marginLeft:'10px'}} src={TD} alt="TD"/>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div style={{marginTop:'10px', textAlign:'left', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                                Bagikan artikel ini melalui :
                                                <a>
                                                    <img style={{margin:"0px", marginLeft:'10px'}} src={FBW} alt="FBW"/>
                                                </a>
                                                <a>
                                                    <img style={{margin:"0px", marginLeft:'10px'}} src={TWW} alt="TWW"/>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
            
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="12" md="4">
                            <Row>
                                <Col xs="10" sm="10" md="9" lg="10" style={{paddingRight:'0px'}}>
                                    <Form>
                                        <Input name="search" placeholder="Cari"></Input>
                                    </Form>  
                                </Col>
                                <Col xs="2" sm="2" md="3" lg="2" style={{paddingLeft:'0px'}}>
                                    <Button className={styles.buttonSearchArti}><img style={{margin:"0px"}} src={Loupe} alt="Loupe"/></Button>
                                </Col>
                            </Row>
                            <Card style={{padding:'20px'}}>
                                <CardTitle>Artikel Lainnya</CardTitle>
                                <Container className={styles.sectBorder}/>
                                <Row>
                                    <Col>
                                        <div className={styles.imageWrapper}>
                                            <a href={data1.image} target="_blank" rel="noopener noreferrer">  
                                                <img className={styles.imageSub} src={data1.image}></img>
                                            </a>
                                        </div> 
                                    </Col>
                                </Row>
                                <CardTitle className={styles.cardTitle}>{data1.judul}</CardTitle>
                                <CardBody style={{padding:'0px'}}>
                                    <p className={styles.cardSubtitle}>
                                        {data1.content}
                                    </p>
                                </CardBody>
                                <Link key={data1.id} to={`${data1.id}`} style={{padding:'0px'}}>  
                                    <Button color="primary" style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>Baca Lebih</Button>
                                </Link> 
                                <Container className={styles.sectBorder} style={{marginTop:'10px'}}/>
                                <Row>
                                    <Col>
                                        <div className={styles.imageWrapper}>
                                            <a href={data2.image} target="_blank" rel="noopener noreferrer">  
                                                <img className={styles.imageSub} src={data2.image}></img>
                                            </a>
                                        </div> 
                                    </Col>
                                </Row>
                                <CardTitle className={styles.cardTitle}>{data2.judul}</CardTitle>
                                <CardBody style={{padding:'0px'}}>
                                    <p className={styles.cardSubtitle}>
                                        {data2.content}
                                    </p>
                                </CardBody>
                                <Link key={data2.id} to={`${data2.id}`} style={{padding:'0px'}}>  
                                    <Button color="primary" style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>Baca Lebih</Button>
                                </Link>  
                            </Card>
                            <Card style={{padding:'20px'}}>
                                <CardTitle>Email Newsletter</CardTitle>
                                <CardBody style={{padding:'0px'}}>
                                    <p>Dapatkan informasi terbaru artikel dari kami secara GRATIS</p>
                                    <Row>
                                        <Col xs="10" sm="10" md="9" lg="10" style={{paddingRight:'0px'}}>
                                            <Form>
                                                <Input name="emailInput" placeholder="Masukkan Email"></Input>
                                            </Form>  
                                        </Col>
                                        <Col xs="2" sm="2" md="3" lg="2" style={{paddingLeft:'0px'}}>
                                            <Button className={styles.buttonSearchArti}><img style={{margin:"0px"}} src={Sent} alt="Sent"/></Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <section style={{height:'150px', paddingTop: '50px'}}>
                        <Container className={styles.sectBorder}>
                            <div style={{marginTop:'10px', color:'#00438B', textAlign:'left'}}>Copyright 2020 All Rights Reserved</div>
                        </Container>
                    </section>
                </Container>
            </div>
        </div>
    )
}

export default ArtikelPage