import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const BusinessIntelligenceAdminAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/BusinessIntelligence/Admin/LogHistory" /* webpackChunkName: "BusinessIntelligenceAdmin" */
    ),
  loading: Loading,
});

export default BusinessIntelligenceAdminAsync;
