import React from "react";
import {
  Card,
  Table,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  CardImg,
  CardBody,
} from "reactstrap";
import moment from "moment";
import Player from "react-player";
import "./style.css";
import { url } from "../../../../services/Config";

export default function BacaPopup(props) {
  const { modal, toggle, data } = props;
  // let urlRead = data ? data.mostRead ? allData.mostRead ? allData.mostRead.details ? allData.mostRead.details.image : "" : "" : "" : "";
  let urlRead = data
    ? data.details
      ? data.details.image !== undefined
        ? data.details.image
        : ""
      : ""
    : "";
  let imgRead = `${process.env.REACT_APP_BE_URL + "/" + urlRead}`;

  const typeContent = props.typeContent ? props.typeContent : "Artikel";

  const urlVideo =
    data?.tipeVideo === "youtube"
      ? "https://www.youtube.com/embed/" + data.link
      : process.env.REACT_APP_BE_URL + data.localDetails?.[2].url;

  const textBaca = typeContent === "video" ? "LIHAT" : "BACA";

  return (
    <div>
      <Modal size="xl" isOpen={modal} backdrop={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {textBaca} {typeContent.toUpperCase()}
        </ModalHeader>
        <ModalBody className="text-center">
          <Container>
            <h3 style={{ color: "#393939" }}>
              {/* {data !== undefined && data.judul} */}
              {typeContent}
            </h3>
            <hr width={180} />
            <div>
              <Table borderless responsive>
                <thead>
                  <tr>
                    <th>Judul</th>
                    <th>Tanggal Dibuat</th>
                    <th>Tanggal Posting</th>
                    {typeContent !== "Video" ? <th>Jumlah Pembaca</th> : <th>Jumlah Viewer</th>}
                    <th>Status</th>
                    <th>Like</th>
                    <th>Dislike</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.judul}</td>
                    <td>
                      {moment(data !== undefined && data.tanggal_pembuatan).format("DD/MM/YYYY")}
                    </td>

                    <td>
                      {data !== undefined && moment(data.tanggal_posting).format("DD/MM/YYYY")}
                    </td>

                    {typeContent !== "Video" ? <td>{data.jumlahPembaca}</td> : <td>{data.play}</td>}
                    <td>{data !== undefined && data.status}</td>
                    <td>{data.like}</td>
                    <td>{data.dislike}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div>
              {typeContent !== "video" ? (
                <CardImg className="img-fluid" src={imgRead} alt="Article" />
              ) : (
                <div align="center">
                  <Player
                    onClick={() => {}}
                    controls
                    volume
                    pip
                    width="500px"
                    height="300px"
                    wrapper="div"
                    playIcon={
                      <i className="fas fa-play-circle fa-4x" style={{ color: "#00438B80" }} />
                    }
                    light={`${process.env.REACT_APP_BE_URL}/${data?.details?.thumbnail}`}
                    url={`${urlVideo}`}
                  />
                </div>
              )}
              {/* <div className="bg-article" style={{ backgroundImage: `url(${imgRead})` }}>
                            </div> */}
            </div>
            <div className="text-left mt-2 mb-2">
              <p>Sinopsis</p>
            </div>
            <div className="mb-2">
              <Card style={{ minHeight: "120px", overflow: "scroll" }}>
                <CardBody
                  className="text-left"
                  dangerouslySetInnerHTML={{ __html: data.body }}></CardBody>
              </Card>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}
