import React, { useEffect, useState } from "react";
import { Row, CardBody, Button, Col, Alert, Modal, ModalBody } from "reactstrap";
import moment from "moment";
import { MDBModalHeader, MDBNavLink } from "mdbreact";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLSUB from "./Download/XLS/DownloadCardUserBehavior";

// COMPONENT
import CardPieChart from "./CardPieChart";
import CardUserBehavior from "./CardUserBehavior";
import CardKontenVideo from "./CardKontenVideo";
import CardKontenBanner from "./CardKontenBanner";
import CardKontenNews from "./CardKontenNews";
// import CardPeakHour from "./CardPeakHour";
// import TrafikKonten from "./TrafikKonten/Tab";
// import CardDemografi from "./CardDemografi/CardDemografi";
// import CardPieChartDemografi from "./CardDemografi/CardPieChart";
// import CardPersebaranUmur from "./CardDemografi/CardPersebaranUmur";

// MODAL
import ModalCalendar from "./Calendar/CalendarRange";
import ModalSave from "../../components/Admin/Popup/BI/LogHistory/SaveFilter";

// STYLE CSS
import style from "./style.module.css";

import carbon_save from "../../assets/icon/carbon_save.png";
import cal from "../../assets/icon/calendar.png";

// API
import API from "../../services";

// REDUX
import { useSelector } from "react-redux";

export default function Index() {
  // DATA REDUX
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  const [data, setData] = useState([]);
  // MODAL DOWNLOAD
  const [modalDownloadXLSUB, setModalDownloadXLSUB] = useState(false);
  const toggleDownloadXLSUB = () => {
    setModalDownloadXLSUB(!modalDownloadXLSUB);
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  //calendar modal
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  // MODAL SAVE
  const [modalSave, setModalSave] = useState(false);
  const toggleModalSave = () => {
    setModalSave(!modalSave);
  };

  // TANGGAL DEFAULT
  var currentDate = new Date();
  const [endDate, setEndDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.end_date
      : moment(currentDate).subtract(1, "days").format("DD/MM/YYYY")
  );

  const start = currentDate.setMonth(currentDate.getMonth() - 1);
  const [startDate, setStartDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.start_date
      : moment(start).format("DD/MM/YYYY")
  );

  const getData = () => {
    setLoading(true);
    setError(false);
    const param = {
      start: startDate,
      end: endDate,
    };

    const fnCheckData = (data) => {
      if (
        Object.values(data.card).filter((e) => e !== 0).length === 0 &&
        Object.values(data.time_behaviour).filter((e) => e !== 0).length === 0 &&
        Object.values(data.popup).filter((e) => e !== 0).length === 0 &&
        Object.values(data.quality).filter((e) => e !== 0).length === 0 &&
        data.play_video === 0
      ) {
        return (
          resetBtn === "" &&
          alert(`Tidak ada data ditemukan pada filter tanggal ${startDate} - ${endDate}`)
        );
      }
    };

    API.getDataUserBehaviour(param)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
          setTimeout(() => {
            fnCheckData(res.data.data);
          }, 500);
        }
      })
      .catch((e) => {
        setData(null);
        setTimeout(() => {
          alert(`Terjadi kesalahan pada filter tanggal ${startDate} - ${endDate}`);
        }, 500);
        setError(true);
        setLoading(false);
      });
  };

  const [resetBtn, setResetBtn] = useState("");

  useEffect(() => {
    getData();
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [startDate, endDate]);

  const resetFilter = () => {
    setEndDate("12/12/1990");
    setStartDate("12/12/1990");
    setResetBtn("Pilih Tanggal");
  };

  return (
    <div className="container-fluid" style={{ marginBottom: "100px" }}>
      <ModalCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        setResetBtn={setResetBtn}
        // selectedDate={handleSelectedDate}
      />
      <ModalSave
        modal={modalSave}
        toggle={toggleModalSave}
        data={{ start_date: startDate, end_date: endDate, measure: "behavior" }}
      />
      <Row
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}>
        <CardBody
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <span className={style.titleMenuBI}>USER BEHAVIOR</span>
          <div className="float-right">
            <Button
              style={{
                backgroundColor: "#2C3280",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
              }}
              onClick={toggleModalSave}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <img src={carbon_save} className="mr-2" />
                <span>Save</span>
              </div>
            </Button>
          </div>
        </CardBody>
      </Row>
      {/* FILTER TANGGAL DATE RANGE PICKER */}
      <Row className="my-3 justify-content-end">
        <Col sm="12" md="5" lg="3" className="mx-2">
          <Row>
            <Button
              onClick={toggleModalCalendar}
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                minWidth: "100%",
              }}>
              {resetBtn ? (
                <div
                  style={{
                    color: "#2C3280",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span className="mr-1">{resetBtn}</span>
                </div>
              ) : (
                <div
                  style={{
                    color: "#2C3280",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span className="mr-1">
                    {startDate} - {endDate}
                  </span>
                  <img src={cal} />
                </div>
              )}
            </Button>
          </Row>
        </Col>
        <Col lg="1" className="mx-2">
          <Row>
            <Button
              disabled={resetBtn !== ""}
              onClick={resetFilter}
              style={{
                backgroundColor: "#C42127",
                borderRadius: "20px",
                borderColor: "#C42127",
              }}>
              Reset
            </Button>
          </Row>
        </Col>
      </Row>
      {/* CARD COMPONENTS */}
      {error ? (
        <Alert color="danger" className="text-center">
          Terjadi Kesalahan
        </Alert>
      ) : (
        <>
          <div>
            <Modal
              size="md"
              isOpen={modalDownloadXLSUB}
              toggle={toggleDownloadXLSUB}
              centered={true}>
              <MDBModalHeader toggle={toggleDownloadXLSUB}>
                <span className={style.headText}>Download XLS</span>
              </MDBModalHeader>
              <ModalBody>
                <center className="container-popup">
                  <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                  <Button color="" className={style.btn_reject} onClick={toggleDownloadXLSUB}>
                    Batal
                  </Button>
                  <Button color="" className={style.btn_confirm_xls} onClick={toggleDownloadXLSUB}>
                    <ReactHTMLTableToExcel
                      id="TableXLS"
                      className={style.DownloadTableXLS}
                      table="DownloadTableXLSUB"
                      filename={`user-behavior ${moment(new Date()).format("DD-MM-YYYY")}`}
                      sheet="users"
                      buttonText="Download"
                    />
                  </Button>
                </center>
              </ModalBody>
            </Modal>
            {loading || resetBtn ? (
              ""
            ) : (
              <Col
                className="my-3"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}>
                <MDBNavLink to="#" className={style.textDownload}>
                  Download
                </MDBNavLink>
                <MDBNavLink onClick={toggleDownloadXLSUB} link to="#">
                  XLS
                </MDBNavLink>
              </Col>
            )}
            <DownloadXLSUB
              id="DownloadTableXLSUB"
              className="DownloadTableXLS"
              data={data}
              date={`${startDate} - ${endDate}`}
            />
          </div>
          <CardUserBehavior loading={loading} data={data} date={`${startDate} - ${endDate}`} />
          <CardPieChart loading={loading} data={data} date={`${startDate} - ${endDate}`} />
          <CardKontenVideo loading={loading} data={data} date={`${startDate} - ${endDate}`} />
          <CardKontenBanner loading={loading} data={data} date={`${startDate} - ${endDate}`} />
          <CardKontenNews loading={loading} data={data} date={`${startDate} - ${endDate}`} />
          {/* <CardPeakHour loading={loading} data={data} />
          <TrafikKonten /> */}
        </>
      )}
    </div>
  );
}
