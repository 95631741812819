import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const KontenRevisiContentPartnerAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/KontenRevisi/ContentPartner" /* webpackChunkName: "KontenRevisiContentPartnerAsync" */
    ),
  loading: Loading,
});

export default KontenRevisiContentPartnerAsync;
