import React from 'react';

import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes, kuratorRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from './helpers/AuthType/fakeBackend';
import Homepages from './Homepages/Pages/homepage';
import AppKurator from './App/AppKurator';
import AppContentPartner from './App/AppContentPartner';
import AppSuperAdmin from './App/AppSuperAdmin';
import Pages404 from './pages/Utility/pages-404';
import ScrollToTop from './ScrollToTop';
import AppSupport from './App/AppSupport';

// Activating fake backend
fakeBackend();

const firebaseConfig = {
	apiKey: process.env.REACT_APP_APIKEY,
	authDomain: process.env.REACT_APP_AUTHDOMAIN,
	databaseURL: process.env.REACT_APP_DATABASEURL,
	projectId: process.env.REACT_APP_PROJECTID,
	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_APPID,
	measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

const Entry = () => <Redirect to="/app" />;
const NotFound = () => <Pages404 />;

function PublicRoute({ component: Component, ...rest }) {
	// console.log("sampe")
	const role = window.localStorage.getItem("role"); // comment to disable auth
	const { path } = { ...rest };

	return (
		<Route
			{...rest}
			render={(props) => {
				if (role) {
					return <Redirect to={"/app"} />;
				} else {
					return <Component {...props} />;
				}
			}}
		/>
	);
}

function EntryRoute({ props: props, component: Component, ...rest }) {
	let role = window.localStorage.getItem("role"); // comment to disable auth
	return (
		<Route
			{...rest}
			render={(props) => {
				let role = window.localStorage.getItem("role");
				console.log(role);
				if (role) {
					role = role.replace(/\s+/g, "").toString();
					switch (role) {
						case "Kurator":
							return <AppKurator {...props} />;
							break;
						case "Content Partner":
							return <AppContentPartner {...props} />;
							break;
						case "SuperAdmin":
							return <AppSuperAdmin {...props} />;
							break;
						case "SupportCenter":
							return <AppSupport {...props} />;
							break;
						default:
							break;
						// code block
					}
				} else {
					return (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: props.location },
							}}
						/>
					);
				}
			}}
		/>
	);
}


const App = (props) => {
	function getLayout() {
		let layoutCls = VerticalLayout;

		switch (props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

	const Layout = getLayout();

	const NonAuthmiddleware = ({
		component: Component,
		layout: Layout
	}) => (
			<Route
				render={props => {
					return (
						<Layout>
							<Component {...props} />
						</Layout>
					);
				}}
			/>
		);

	return (
		<React.Fragment>
			<Router>
				<ScrollToTop/>
				<Switch>
					{authRoutes.map((route, idx) => (
						<NonAuthmiddleware
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
						/>
					))}

					{userRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={Layout}
							component={route.component}
							key={idx}
						/>
					))}

				</Switch>
			</Router>
		</React.Fragment>

	);
}


const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
