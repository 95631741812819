import React, { useState } from "react";

import {
  Alert,
  Col,
  Modal,
  ModalBody,
  Button,
  Row,
  FormGroup,
  Input,
  Label,
  Spinner,
  FormText,
} from "reactstrap";
import { inputValidator } from "../../../../Common/validator";
import styles from "./styles.module.css";
import { MDBModalHeader, MDBContainer, MDBNav, MDBTabContent, MDBTabPane } from "mdbreact";
import Header from "../../../../../components/VerticalLayout/Header";
import { useHistory } from "react-router-dom";
import API from "../../../../../services";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//icon
import IconGreenCheckList from "../../../../../assets/icon/IconGreenChecklist.svg";
import IconRedCheckList from "../../../../../assets/icon/IconRedChecklist.svg";

function PopupConfirmation() {
  const [nameOrganisasi, setNameOrganisasi] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  // const [role, setRole] = useState("5f73fe4028751d590d835268");
  // Error
  const [errorNamaOrganisasi, setErrorNamaOrganisasi] = useState("");
  const [errorUsername, setErrorUsername] = useState("");
  const [errorNamaLengkap, setErrorNamaLengkap] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [resMessage, setResMessage] = useState("");

  const [errorUsernameAPI, setErrorUsernameAPI] = useState(true);
  const [errorEmailAPI, setErrorEmailAPI] = useState(true);
  const [errorOrganisasiAPI, setErrorOrganisasiAPI] = useState(true);

  //form
  const [successSubmitted, setSuccessSubmitted] = useState("");
  const [loading, setLoading] = useState(false);

  // const regexp = new RegExp(/^[0-9\b]+$/);

  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault()
    if (nameOrganisasi === "") {
      setErrorNamaOrganisasi("Nama Organisasi Tidak Boleh Kosong");
    } else {
      setErrorNamaOrganisasi("");
    }
    if (username === "") {
      setErrorUsername("Username Tidak Boleh Kosong");
    } else {
      setErrorUsername("");
    }
    if (namaLengkap === "") {
      setErrorNamaLengkap("Nama Lengkap Tidak Boleh Kosong");
    } else {
      setErrorNamaLengkap("");
    }
    if (email === "") {
      setErrorEmail("Email Tidak Boleh Kosong");
    } else {
      setErrorEmail("");
    }
    if (password === "") {
      setErrorPassword("Password Tidak Boleh Kosong");
    } else {
      setErrorPassword("");
    }
    if (phone === "") {
      setErrorPhone("Nomor Telepon Tidak Boleh Kosong");
    } else {
      setErrorPhone("");
    }
    if (password !== passwordConfirm && passwordConfirm !== password) {
      setErrorConfirmPassword("Password anda tidak sesuai");
    } else {
      setErrorConfirmPassword("");
    }

    if ( nameOrganisasi !== "" && nameOrganisasi === nameOrganisasi && username !== "" && namaLengkap !== "" && email !== "" && password !== "" && phone !== "" && phone.length > 9 && password === passwordConfirm) {
      setLoading(true);
      API.checkUser({
        username: username,
        email: email,
        organization: nameOrganisasi,
      })
        .then((value) => {
          console.log('cek API',value);
          let res = value.data.data;
          setErrorUsernameAPI(res.username);
          setErrorEmailAPI(res.email);
          setErrorOrganisasiAPI(res.organization);
          if (res.username && res.email && res.organization) {
            API.postRegistrasiAdmin({
              username: username,
              password: password,
              organization_name: nameOrganisasi,
              email: email,
              phone: phone,
              nama: namaLengkap,
            })
              .then((res) => {
                setLoading(true);
                if (res.data.success === true) {
                  new Promise(
                    () => {
                      setSuccessSubmitted("success");
                    },
                    setTimeout(() => {
                      history.push("/superadmin/registrasi-admin");
                    }, 3000)
                  );
                } if (res.data.success === false) {
                  new Promise(
                    () => {
                      setResMessage(res.data.err);
                      // alert('cek1')
                      // setSuccessSubmitted("fail");
                    },
                    setTimeout(() => {
                      setLoading(false);
                    }, 3000)
                  );
                }
              })
              .catch((err) => {
                new Promise(
                  () => {
                    setResMessage(err);
                    // setSuccessSubmitted("fail");
                    alert('gagal1')
                  },
                  setTimeout(() => {
                    setLoading(false);
                  }, 3000)
                );
              });
          } else {
            // alert('cek2')
            // setSuccessSubmitted("fail");
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          }
        })
        .catch((err) => {
          new Promise(
            () => {
              setResMessage(err);
              setSuccessSubmitted("fail");
              // alert('gagal2')
            },
            setTimeout(() => {
              setLoading(false);
            }, 3000)
          );
        });
    } else {
      console.log("Input empty");
    }
  }

  // const onChangeFileUpload = (e) => {
  // 	setUploadFile(e.target.files[0]);
  // };
  // const regexp = new RegExp(`\+?([ -]?\d+)+|\(\d+\)([ -]\d+)`);

  const hadleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };
  return (
    <>
      {loading ? (
        successSubmitted == "success" ? (
          <SweetAlert title="User Berhasil Dibuat" showConfirm={false} onConfirm={""}>
            <img src={IconGreenCheckList}></img>
          </SweetAlert>
        ) : successSubmitted == "fail" && typeof resMessage !== undefined ? (
          // <PopoverDuplicate res={resMessage} />
          <SweetAlert error showConfirm={false} onConfirm={""}>
            <p>User Tidak Berhasil Dibuat</p>
            {resMessage !== "" || typeof resMessage !== undefined ? (
              <ul>
                {resMessage.includes("username") ? (
                  <li className="text-left">Username tidak boleh sama</li>
                ) : resMessage.includes("email") ? (
                  <li className="text-left">Email tidak boleh sama</li>
                ) : resMessage.includes("organization") ? (
                  <li className="text-left">Nama Organisasi tidak boleh sama</li>
                ) : (
                  <></>
                )}
              </ul>
            ) : (
              <></>
            )}
          </SweetAlert>
        ) : (
          <SweetAlert
            showConfirm={false}
            onConfirm={""}
            title="Mohon Tunggu"
            style={{
              padding: "3em",
              borderRadius: "20px",
            }}>
            <Spinner
              color="danger"
              style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
              type="grow"
            />
            <div>User {username} sedang dibuat</div>
          </SweetAlert>
        )
      ) : (
        <></>
      )}
      <div className="page-content">
        <header>
          <Header title="Tambah Admin" className="SuperAdminHeader" />
        </header>
        <main>
          <div className="container-fluid">
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12">
                    <div
                      className="container-popup"
                      style={{
                        borderRadius: "10px",
                        padding: "30px 0 10px 0",
                        backgroundColor: "rgba(158,207,250,0.01)",
                      }}>
                      <div className={styles.wrapField}>
                        <label className={styles.lblInput}>Nama Organisasi</label>
                        {/* <FormGroup style={{display: "flex", justifyContent: "center"}}> */}
                        <Input
                          className={` ${
                            errorNamaOrganisasi !== ""
                              ? styles.inputError
                              : errorOrganisasiAPI
                              ? styles.input
                              : styles.inputError
                          }`}
                          Label="Nama Organisasi"
                          type="text"
                          name="nama"
                          onChange={(e) => {
                            setNameOrganisasi(e.target.value);
                          }}
                          onKeyPress={hadleKeyPress}
                        />
                        <FormText className="ml-2 formTextError" color="#c42127">
                          {errorOrganisasiAPI
                            ? errorNamaOrganisasi
                            : "Nama Organisasi telah terpakai"}
                        </FormText>
                        {/* </FormGroup> */}
                        {/* <FormGroup style={{display: "flex", justifyContent: "center"}}> */}
                        <label className={styles.lblInput}>Username</label>
                        <Input
                          className={` ${
                            errorUsername !== ""
                              ? styles.inputError
                              : errorUsernameAPI
                              ? styles.input
                              : styles.inputError
                          }`}
                          type="text"
                          name="username"
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          // placeholder="Username"
                          onKeyPress={hadleKeyPress}
                        />
                        <FormText className="ml-2 formTextError" color="#c42127">
                          {errorUsernameAPI ? errorUsername : "Username telah terpakai"}
                        </FormText>
                        {/* </FormGroup> */}
                        {/* <FormGroup style={{display: "flex", justifyContent: "center"}}> */}
                        <label className={styles.lblInput}>Email</label>
                        <Input
                          className={` ${
                            errorEmail !== ""
                              ? styles.inputError
                              : errorEmailAPI
                              ? styles.input
                              : styles.inputError
                          }`}
                          type="text"
                          name="email"
                          onChange={(e) => {
                            let validEmail = inputValidator("email", e.target.value);
                            if (validEmail) {
                              setErrorEmail("");
                            } else {
                              setErrorEmail("Format email tidak valid");
                            }
                            setEmail(e.target.value);
                          }}
                          onKeyPress={hadleKeyPress}
                        />
                        <FormText className="ml-2 formTextError" color="#c42127">
                          {errorEmailAPI ? errorEmail : "Email telah terpakai"}
                        </FormText>

                        {/* </FormGroup> */}
                        {/* <FormGroup style={{display: "flex", justifyContent: "center"}}> */}
                        <label className={styles.lblInput}>Nama Lengkap</label>
                        <Input
                          className={` ${
                            errorNamaLengkap !== "" ? styles.inputError : styles.input
                          }`}
                          type="text"
                          name="fullname"
                          onChange={(e) => {
                            setNamaLengkap(e.target.value);
                          }}
                          onKeyPress={hadleKeyPress}
                        />
                        {errorNamaLengkap.length > 0 && (
                          <FormText className="ml-2 formTextError" color="#c42127">
                            {errorNamaLengkap}
                          </FormText>
                        )}
                        {/* </FormGroup> */}
                        {/* <FormGroup style={{display: "flex", justifyContent: "center"}}> */}
                        <label className={styles.lblInput}>Nomor Handphone</label>
                        <Input
                          className={` ${errorPhone !== "" ? styles.inputError : styles.input}`}
                          type="tel"
                          name="phone"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          // value={regexp}
                          onChange={(e) => {
                            let validPhone = inputValidator("phone", e.target.value);
                            if (validPhone) {
                              setErrorPhone("");
                            } else {
                              setErrorPhone("Format Telpon(08xxxxxxxxx)");
                            }
                            setPhone(e.target.value);
                          }}
                          required
                          onKeyPress={hadleKeyPress}
                        />
                        {errorPhone.length > 9 && (
                          <FormText className="ml-2 formTextError" color="#c42127">
                            {errorPhone ? errorPhone : "Format Telpon(08xxxxxxxxx)"}
                          </FormText>
                        )}
                        {/* </FormGroup> */}
                        {/* <FormGroup style={{display: "flex", justifyContent: "center"}}> */}
                        <label className={styles.lblInput}>Kata Sandi</label>
                        <Input
                          className={` ${errorPassword !== "" ? styles.inputError : styles.input}`}
                          type="password"
                          name="password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          onKeyPress={hadleKeyPress}
                        />
                        {errorPassword.length > 0 && (
                          <FormText className="ml-2 formTextError" color="#c42127">
                            {errorPassword}
                          </FormText>
                        )}
                        {/* </FormGroup> */}
                        {/* <FormGroup style={{display: "flex", justifyContent: "center"}}> */}
                        <label className={styles.lblInput}>Ulang Kata Sandi</label>
                        <Input
                          className={` ${
                            errorConfirmPassword !== "" ? styles.inputError : styles.input
                          }`}
                          type="password"
                          name="passwordconfirm"
                          onChange={(e) => {
                            setPasswordConfirm(e.target.value);
                          }}
                          onKeyPress={hadleKeyPress}
                        />
                        {errorConfirmPassword.length > 0 && (
                          <FormText className="ml-2 formTextError" color="#c42127">
                            {errorConfirmPassword}
                          </FormText>
                        )}
                        {/* </FormGroup> */}
                        <Button
                          color=""
                          type="submit"
                          className={styles.btn_confirm}
                          disabled={
                            namaLengkap === "" ||
                            !(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)) ||
                            !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) ||
                            !(/^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/.test(phone)) ||
                            nameOrganisasi === "" ||
                            // username === "" ||
                            !(/^[A-Za-z0-9]+$/.test(username)) ||
                            password === "" ? true : false ||
                            passwordConfirm === "" ? true : false
                          }
                          onClick={onSubmit}>
                          {loading ? <Spinner /> : `Daftarkan`}
                        </Button>
                      </div>
                    </div>
                    {/* </MDBTabContent> */}
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </>
  );
}

export default PopupConfirmation;

{
  /* <Modal
				size="lg"
				isOpen={modalTambahPengguna}
				toggle={toggleTambahPengguna}
				centered={true}
			>
				<MDBModalHeader toggle={toggleTambahPengguna} >
					<span className={styles.headText}> TAMBAH ADMIN </span>
				</MDBModalHeader>
				<ModalBody>
					<center className="container-popup">
						<FormGroup>
							<Label>Nama Organisasi</Label>
							<Input
								className={styles.input}
								type="text"
								name="nama"
								onChange={(e) => {
									setNameOrganisasi(e.target.value);
								}}
								placeholder="Nama Organisasi"
								onKeyPress={hadleKeyPress}
							/>
							{errorName.length > 0 && (
								<span className={styles.styleError}>{errorName}</span>
							)}
						</FormGroup>
						<FormGroup>
							<Input
								className={styles.input}
								type="text"
								name="username"
								onChange={(e) => {
									setUsername(e.target.value);
								}}
								placeholder="Username"
								onKeyPress={hadleKeyPress}
							/>
							{errorUsername.length > 0 && (
								<span className={styles.styleError}>{errorUsername}</span>
							)}
						</FormGroup>
						<FormGroup>
							<Input
								className={styles.input}
								type="text"
								name="email"
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								placeholder="Email"
								onKeyPress={hadleKeyPress}
							/>
							{errorEmail.length > 0 && (
								<span className={styles.styleError}>{errorEmail}</span>
							)}
						</FormGroup>
						<FormGroup>
							<Input
								className={styles.input}
								type="text"
								name="fullname"
								onChange={(e) => {
									setNamaLengkap(e.target.value);
								}}
								placeholder="Nama Lengkap"
								onKeyPress={hadleKeyPress}
							/>
						</FormGroup>
						<FormGroup>
							<Input
								className={styles.input}
								type="tel"
								name="phone"
								pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
								// value={regexp}
								onChange={(e) => {
									setPhone(e.target.value);
								}}
								placeholder="No. Hp"
								required
								onKeyPress={hadleKeyPress}
							/>
							{errorEmail.length > 0 && (
								<span className={styles.styleError}>{errorEmail}</span>
							)}
						</FormGroup>
						<FormGroup>
							<Input
								className={styles.input}
								type="password"
								name="password"
								onChange={(e) => {
									setPassword(e.target.value);
								}}
								placeholder="Kata Sandi"
								onKeyPress={hadleKeyPress}
							/>
							{errorPassword.length > 0 && (
								<span className={styles.styleError}>{errorPassword}</span>
							)}
						</FormGroup>
						<FormGroup>
							<Input
								className={styles.input}
								type="password"
								name="passwordconfirm"
								onChange={(e) => {
									setPasswordConfirm(e.target.value);
								}}
								placeholder="Ulangi Kata Sandi"
								onKeyPress={hadleKeyPress}
							/>
							{errorConfirmPassword.length > 0 && (
								<span className={styles.styleError}>
									{errorConfirmPassword}
								</span>
							)}
						</FormGroup>
						<Button
							color=""
							type="submit"
							className={styles.btn_confirm}
							onClick={onSubmit}
							disabled={
									nameOrganisasi === "" ||
									namaLengkap === "" ||
								  // email === "" ||
									!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) ||
									phone === "" ||
								  // username === "" ||
									!(/^[A-Za-z0-9]+$/.test(username)) ||
									password === ""
									? true
									: false
							}
						>
							{loading ? <Spinner /> : `Daftarkan`}
						</Button>
					</center>
				</ModalBody>
			</Modal> */
}
