import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Header from "../../../components/VerticalLayout/Header";
import "./style.css";
import Down from '../../../assets/images/back.png';
import { Col, Row, FormGroup, Input, Container, Alert, Card } from "reactstrap";

import people from "../../../assets/images/partner.png";
import curate from "../../../assets/images/curate.png";
import curated from "../../../assets/images/curated.png";
import TableStatistikDetail from "../../../components/Admin/Table/TableStatistik";
import TableStatPersonal from "../../../components/Admin/Table/TableStatPersonal";

import CardHome from "../../../components/Card/kurator/CardHome/CardHome";
import Chart from "../../../pages/Charts/KontenPartnerAnalis/chart"
import API from "../../../services";

export default function Home() {
  const [error, setError] = useState("");
  const [visible, setVisible] = useState(true);
  const [resCategory, setResCategory] = useState([]);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);

  const onDismiss = () => setVisible(false);

  // untuk loading skeleton
  const skeletonCostume = <Skeleton circle={true} height={50} width={50} />;

  // data category
  // let data = window.localStorage.getItem("kateg").split(",");
  // let dataSelect = window.localStorage.getItem("selectKateg");

  const getHome = isSubscribed => {
    // setCategory(dataSelect);
    // if (category !== "") {
    //   API.getHome(category)
    //     .catch(err => {
    //       setLoading(false);
    //       return setError("Data Ketegori Tidak ada");
    //     })
    //     .then(res => {
    //       if (res !== undefined) {
    //         setLoading(false);
    //         setResCategory(res.data.data);
    //       }
    //     });
    // } else {
    //   setLoading(true);
    // }
  };

  // useEffect(() => {
  //   let isSubscribed = true;
  //   getHome(isSubscribed);
  //   return () => (isSubscribed = false);
  // }, [category]);

  const handleClick = (filt) => {
    setCategory(filt);
    // window.localStorage.setItem("selectKateg", filt);
  }


  return (
    <div>
      <Header title="DETAIL STATISTIK KONTEN" />
      {/* <TableStatPersonal /> */}
      {/* <Container> */}
        {/* {error == "" ? (
          ""
        ) : (
            <Alert color="danger" isOpen={visible} toggle={onDismiss}>
              {error}
            </Alert>
          )} */}
      {/* </Container> */}
      <div className="wrap-dropdown">
        <Row className="mt-2 mb-2" style={{width: "50%"}}>
          <Col lg="8" md="3">
            <MDBDropdown className="dropdown-width">
              <MDBDropdownToggle caret color='primary' className="dropdown-kategori font-weight-bold">
                <div className="wrapper-selected">PROVINSI</div>
                <div><img src={Down} alt="dropdown-icons" /></div>
              </MDBDropdownToggle>
              <MDBDropdownMenu  className='dropdown-menus'>
                {/* {allGetData.map((fc, i) => (
                  <MDBDropdownItem style={{color:"#2C3280"}} className="text-center" active={allGetData === fc} onClick={() => handleClick(fc)} key={i}>
                    {`Provinsi ${fc.toUpperCase()}`}
                  </MDBDropdownItem>
                ))} */}
                {/* {allGetData.list_provinsi.map((kateg, i) => (
                  <MDBDropdownItem style={{color:"#2C3280"}} className="text-center" active={allGetData.list_provinsi === kateg} key={i}>
                    {`Provinsi ${kateg.toUpperCase()}`}
                  </MDBDropdownItem>
                ))} */}
              </MDBDropdownMenu>
            </MDBDropdown>
          </Col>
          <Col>
            <button className="btnPenayangan">Detail Penayangan</button>
            <button className="btnHistory">History</button>
          </Col>
        </Row>
      </div>
      <Container>
        <Row className="mt-5 mb-5">
          <Col className="wrapper-total border-right border-light" lg="2" md="3">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                {/* <div>
                  <img src={people} alt="people" />
                </div> */}
                <div className="title-total">TOTAL IMPRESI</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                    <div className="total-content">
                      {/* {resCategory.totalCP == undefined ? "0" : resCategory.totalCP} */}
                    </div>
                  )}
              </div>
            </Row>
          </Col>
          <Col className="wrapper-kurasi border-right border-light" lg="2" md="3" >
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                {/* <div>
                  <img src={curate} alt="curate" />
                </div> */}
                <div className="title-total">TOTAL KLIK</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                    <div className="total-content">
                      {/* {resCategory.content_belumKurasi == undefined
                        ? "0"
                        : resCategory.content_belumKurasi} */}
                    </div>
                  )}
              </div>
            </Row>
          </Col>
          <Col className="wrapper-kurasi border-right border-light" lg="3" md="3" >
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                {/* <div>
                  <img src={curate} alt="curate" />
                </div> */}
                <div className="title-total">IMPRESI MAKSIMUM</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                    <div className="total-content">
                      {/* {resCategory.content_belumKurasi == undefined
                        ? "0"
                        : resCategory.content_belumKurasi} */}
                    </div>
                  )}
              </div>
            </Row>
          </Col>
          <Col className="wrapper-kurasi border-right border-light" lg="2" md="3" >
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                {/* <div>
                  <img src={curate} alt="curate" />
                </div> */}
                <div className="title-total">KLIK MAKSIMUM</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                    <div className="total-content">
                      {/* {resCategory.content_belumKurasi == undefined
                        ? "0"
                        : resCategory.content_belumKurasi} */}
                    </div>
                  )}
              </div>
            </Row>
          </Col>
          <Col className="wrapper-sudah" lg="3"  md="3">
            <Row className="d-flex justify-content-center">
              <div className="wrap-content-partner">
                {/* <div>
                  <img src={curated} alt="curated" />
                </div> */}
                <div className="title-total">JUMLAH PENGGUNA UNIK</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                    <div className="total-content">
                      {/* {resCategory.content_sudahKurasi == undefined
                        ? "0"
                        : resCategory.content_sudahKurasi} */}
                    </div>
                  )}
              </div>
            </Row>
          </Col>
        </Row>
        {/* {loading == true ? (
          <Row className="justify-content-center mb-5">
            <Col md="8">
              <Skeleton count={3} />
            </Col>
          </Row>
        ) : (
            // <CardHome data={resCategory} />
            <Card>
              <Chart />
              <TableStatistikDetail />
            </Card>
            
          )} */}
          <Card>
              <Chart />
              <TableStatistikDetail />
            </Card>
      </Container>
    </div>
  );
}
