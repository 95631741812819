import React, { useEffect } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
import logo from "../../assets/images/BAKTI.png";
import { Button } from "reactstrap";

import iconA from "../../assets/icon/BAKTI-ICON.png";
//redux
import { connect, useDispatch } from "react-redux";
import { setDataLogHistory } from "../../store/data/actions";
//action
import { changeSidebarType, toggleLeftmenu } from "../../store/actions";

const SidebarContent = (props) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleResetReduxLogHistory = () => {
    dispatch(setDataLogHistory({}));
  };

  // Function for toggling when clicking A logo when on icon mode sidebar
  const toggleSidebar = () => {
    // console.log("asdasdadasds");
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  };

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        // console.log(matchingMenuItem);
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  const role = window.localStorage.getItem("role");
  // const role = "contentpartner"

  function activateParentDropdown(item) {
    // Change color to RED indicating active for any sidebar selected (nested and no nested)
    item.classList.add("active");

    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        // When nested sidebar clicked, thsi is to show the parent <>
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        // For double nested sidebar
        if (parent3) {
          // Change color to red indicating active for the nested sidebar selected
          parent3.classList.add("mm-active"); // li
          console.log(parent3.childNodes);

          // Change color to red indicating active for the Parent before nested
          parent3.childNodes[0].classList.add("mm-active");
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  }

  const urlBI = window.location.pathname;

  return (
    <React.Fragment>
      {role === "Admin" ? (
        <div id="sidebar-menu">
          {props.type === "default" ? (
            <div className="d-flex justify-content-center mt-2 mb-4">
              {props.type === "default" ? <img src={logo} alt="logo" /> : ""}
            </div>
          ) : (
            <div className="d-flex justify-content-center mr-2 mt-2 mb-4">
              <img className="imageIcon" onClick={toggleSidebar} src={iconA} alt="iconA" />
              {/* <Button
                color=""
                className="toggleMenu"
                onClick={toggleSidebar}
                style={{ backgroundColor: "#2c3280 !important" }}>
                {" "}
                <i className={isMobile ? "bx bx-arrow-to-left" : "bx bx-arrow-to-right"}></i>
              </Button> */}
            </div>
          )}
          <ul className="metismenu list-unstyled " id="side-menu">
            <li>
              <Link to="/admin/home" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("HOME")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/pengguna" className=" waves-effect">
                <i className="bx bx-copy"></i>
                <span>{props.t("PENGGUNA")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-line-chart-down"></i>
                <span>{props.t("HOMEPAGE")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/admin/artikel">{props.t("ARTIKEL")}</Link>
                </li>
                <li>
                  <Link to="/admin/video">{props.t("VIDEO")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-show-alt"></i>
                <span>{props.t("KONTEN")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/admin/konten">{props.t("KONTEN")}</Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/konten-create">{props.t("KONTEN CREATE")}</Link>
                </li>
                <li>
                  <Link to="/admin/konten-statistik">{props.t("STATISTIK KONTEN")}</Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/konten-statistik-detail">
                    {props.t("STATISTIK KONTEN DETAIL")}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/konten-default">{props.t("KONTEN DEFAULT")}</Link>
                </li>
                <li>
                  <Link to="/admin/konten-tayang">{props.t("KONTEN TAYANG")}</Link>
                </li>
                <li>
                  <Link to="/admin/konten-preview">{props.t("KONTEN PREVIEW")}</Link>
                </li>
                <li>
                  <Link to="/admin/konten-report">{props.t("KONTEN REPORT")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-line-chart-down"></i>
                <span>{props.t("SITE ANALYSIS")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/admin/statistik-site">{props.t("SITE STATISTIK")}</Link>
                </li>
                <li>
                  <Link to="/admin/unique-device">{props.t("UNIQUE DEVICE")}</Link>
                </li>
                <li>
                  <Link to="/admin/site-checker">{props.t("SITE CHECKER")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/admin/survey" className=" waves-effect">
                <i className="bx bx-file"></i>
                <span>{props.t("SURVEI")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/cp-analitics" className=" waves-effect">
                <i className="bx bx-group"></i>
                <span>{props.t("KONTEN PARTNER ANALISIS")}</span>
              </Link>
            </li>
            <li style={{ display: "none" }}>
              <Link to="/admin/cp-analitics-detail">{props.t("CP DETAIL")}</Link>
            </li>
            <li style={{ display: "none" }}>
              <Link to="/admin/cp-analitics-detail-statistik-konten">
                {props.t("STATISTIK KONTEN")}
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-stats"></i>
                <span>{props.t("BUSINESS INTELLIGENCE")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li onClick={handleResetReduxLogHistory}>
                  <Link to="/admin/business-intelligence-PersebaranPengunjung">
                    <div
                      style={
                        urlBI.includes("business-intelligence")
                          ? { color: "#c42127", fontWeight: "bold" }
                          : { color: "#2c3280", fontWeight: "normal" }
                      }>
                      DASHBOARD BUSINESS INTELLIGENCE
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/log-history">{props.t("LOG HISTORY")}</Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/business-intelligence-PersebaranPengunjung">
                    {props.t("Persebaran Pengunjung")}
                  </Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/business-intelligence-DemografiPengunjung">
                    {props.t("Demografi Pengunjung")}
                  </Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/business-intelligence-TrafficKonten">
                    {props.t("Trafik Konten")}
                  </Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/business-intelligence-SummaryTrafficKonten">
                    {props.t("Summary Trafik Konten")}
                  </Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/business-intelligence-ISP">{props.t("ISP")}</Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/business-intelligence-DeviceFingerPrint">
                    {props.t("Device Fingerprint")}
                  </Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/business-intelligence-Survey">{props.t("Survey")}</Link>
                </li>
                <li style={{ display: "none" }}>
                  <Link to="/admin/business-intelligence-UserBehavior">
                    {props.t("User Behavior")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-file"></i>
                <span>{props.t("BANNER")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/admin/banner-landing-page">
                    {props.t("TAMBAH BANNERT TEXT")}
                  </Link>
                </li>
              </ul>
            </li> */}
            {isMobile ? (
              <>
                <li>
                  <Link to="#" onClick={() => history.goBack()} className=" waves-effect">
                    <i className="bx bx-arrow-back"></i>
                    <span>{props.leftSideBarType === "default" ? `` : ` Back`}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/cp-analitics" className=" waves-effect">
                    <i className="bx bx-show-alt"></i>
                    <span>{props.t("CP ANALYSIS")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      ) : role === "SuperAdmin" ? (
        <div id="sidebar-menu">
          {props.type === "default" ? (
            <div className="d-flex justify-content-center mt-2 mb-4">
              {props.type === "default" ? <img src={logo} alt="logo" /> : ""}
            </div>
          ) : (
            <div className="d-flex justify-content-center mr-2 mt-2 mb-4">
              <img className="imageIcon" onClick={toggleSidebar} src={iconA} alt="iconA" />
              {/* <Button
                color=""
                className="toggleMenu"
                onClick={toggleSidebar}
                style={{ backgroundColor: "#2c3280 !important" }}>
                {" "}
                <i className={isMobile ? "bx bx-arrow-to-left" : "bx bx-arrow-to-right"}></i>
              </Button> */}
            </div>
          )}
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/content-partner/home" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("HOME")}</span>
              </Link>
            </li>
            <li>
              <Link to="/content-partner/konten" className=" waves-effect">
                <i className="bx bx-copy"></i>
                <span>{props.t("PENGGUNA")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-line-chart-down"></i>
                <span>{props.t("HOMEPAGE")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/admin/artikel">{props.t("ARTIKEL")}</Link>
                </li>
                <li>
                  <Link to="/admin/video">{props.t("VIDEO")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/content-partner/konten-preview" className=" waves-effect">
                <i className="bx bx-show-alt"></i>
                <span>{props.t("KONTEN PREVIEW")}</span>
              </Link>
            </li>

            {/* <li>
            <Link to="/content-partner/buat-konten" className=" waves-effect">
              <i className="bx bx-chevron-right-square"></i>
              <span>{props.t("BUAT KONTEN")}</span>
            </Link>
          </li> */}
            <li>
              <Link to="/admin/statistik-site" className=" waves-effect">
                <i className="bx bx-show-alt"></i>
                <span>{props.t("SITE ANALYSIS")}</span>
              </Link>
            </li>

            {isMobile ? (
              <li>
                <Link to="#" onClick={() => history.goBack()} className=" waves-effect">
                  <i className="bx bx-arrow-back"></i>
                  <span>{props.leftSideBarType === "default" ? `` : ` Back`}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      ) : role === "Support Center" ? (
        <div id="sidebar-menu">
          {props.type === "default" ? (
            <div className="d-flex justify-content-center mt-2 mb-4">
              {props.type === "default" ? <img src={logo} alt="logo" /> : ""}
            </div>
          ) : (
            <div className="d-flex justify-content-center mr-2 mt-2 mb-4">
              <img className="imageIcon" onClick={toggleSidebar} src={iconA} alt="iconA" />
              {/* <Button
                color=""
                className="toggleMenu"
                onClick={toggleSidebar}
                style={{ backgroundColor: "#2c3280 !important" }}>
                {" "}
                <i className={isMobile ? "bx bx-arrow-to-left" : "bx bx-arrow-to-right"}></i>
              </Button> */}
            </div>
          )}
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/content-partner/home" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("HOME")}</span>
              </Link>
            </li>
            <li>
              <Link to="/support-center/pengguna" className=" waves-effect">
                <i className="bx bx-group"></i>
                <span>{props.t("PENGGUNA")}</span>
              </Link>
            </li>

            <li>
              <Link to="/support-center/konten" className=" waves-effect">
                <i className="bx bx-copy"></i>
                <span>{props.t("KONTEN")}</span>
              </Link>
            </li>
            <li>
              <Link to="/support-center/konten-tayang" className=" waves-effect">
                <i className="bx bx-caret-right-square"></i>
                <span>{props.t("KONTEN TAYANG")}</span>
              </Link>
            </li>
            <li>
              <Link to="/support-center/ticket" className=" waves-effect">
                <i class="bx bxs-coupon"></i>
                <span>{props.t("TIKET")}</span>
              </Link>
            </li>
            <li>
              <Link to="/support-center/site-checker" className=" waves-effect">
                <i class="bx bx-check-square"></i>
                <span>{props.t("SITE CHECKER")}</span>
              </Link>
            </li>
            <li>
              <Link to="/support-center/survey-kepuasan" className=" waves-effect">
                <i class="bx bx-star"></i>
                <span>{props.t("SURVEY KEPUASAN PELANGGAN")}</span>
              </Link>
            </li>
            <li>
              {/* update link*/}
              <a href={`https://icms-api.baktikominfo.id/faq/`} className=" waves-effect">
                <i class="bx bx-error-circle"></i>
                <span>{props.t("FAQ")}</span>
              </a>
            </li>

            {isMobile ? (
              <li>
                <Link to="#" onClick={() => history.goBack()} className=" waves-effect">
                  <i className="bx bx-arrow-back"></i>
                  <span>{props.leftSideBarType === "default" ? `` : ` Back`}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      ) : role === "Artikel Admin" ? (
        <div id="sidebar-menu">
          {props.type === "default" ? (
            <div className="d-flex justify-content-center mt-2 mb-4">
              {props.type === "default" ? <img src={logo} alt="logo" /> : ""}
            </div>
          ) : (
            <div className="d-flex justify-content-center mr-2 mt-2 mb-4">
              <img className="imageIcon" onClick={toggleSidebar} src={iconA} alt="iconA" />
              {/* <Button
                color=""
                className="toggleMenu"
                onClick={toggleSidebar}
                style={{ backgroundColor: "#2c3280 !important" }}>
                {" "}
                <i className={isMobile ? "bx bx-arrow-to-left" : "bx bx-arrow-to-right"}></i>
              </Button> */}
            </div>
          )}
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-line-chart-down"></i>
                <span>{props.t("HOMEPAGE")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/adminartikel/artikel">{props.t("ARTIKEL")}</Link>
                </li>
                <li>
                  <Link to="/adminartikel/video">{props.t("VIDEO")}</Link>
                </li>
              </ul>
            </li>

            {isMobile ? (
              <li>
                <Link to="#" onClick={() => history.goBack()} className=" waves-effect">
                  <i className="bx bx-arrow-back"></i>
                  <span>{props.leftSideBarType === "default" ? `` : ` Back`}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { leftMenu, leftSideBarType } = state.Layout;
  return { leftMenu, leftSideBarType };
};
export default connect(mapStatetoProps, { toggleLeftmenu, changeSidebarType })(
  withRouter(withNamespaces()(SidebarContent))
);
