import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

// import PersetujuanKontenSuperAdmin from "../pages/PersetujuanKonten/SuperAdmin";
// import RegistrasiAdmin from "../pages/RegistrasiAdmin/SuperAdmin";
// import superAdminProfile from "../pages/Authentication/superAdminProfile";
// import HomeAdmin from "../pages/Home/Admin";
// import BusinessIntelligence from "../pages/BusinessIntelligence/Admin";
// import PesebaranPengunjung from "../pages/BusinessIntelligence/Admin/Measures/PersebaranPengunjung";
// import DemografiPengunjung from "../pages/BusinessIntelligence/Admin/Measures/DemografiPengunjung";
import TrafficKonten from "../pages/BusinessIntelligence/Admin/Measures/TrafficKonten";
import SummaryTrafficKonten from "../pages/BusinessIntelligence/Admin/Measures/SummaryTrafficKonten";
import ISP from "../pages/BusinessIntelligence/Admin/Measures/ISP";
import DeviceFingerPrint from "../pages/BusinessIntelligence/Admin/Measures/DeviceFingerprint";
import Survey from "../pages/BusinessIntelligence/Admin/Measures/Survey";
import UserBehavior from "../pages/BusinessIntelligence/Admin/Measures/UserBehavior";
import VisitorJourney from "../pages/BusinessIntelligence/Admin/Measures/VisitorJourney";
import TambahPengguna from "../components/SuperAdmin/Tables/RegistrasiAdmin/TambahPengguna";
import LogHistory from "../pages/BusinessIntelligence/Admin/LogHistory";
import KontenSuperAdmin from "../pages/Konten/SuperAdmin";

import PersetujuanKontenSuperAdmin from "../pagesAsync/PersetujuanKontenAsync/SuperAdminAsync";
import superAdminProfile from "../pagesAsync/AuthenticationAsync/SuperAdminProfileAsync";
import RegistrasiAdmin from "../pagesAsync/RegistrasiAdminAsync/SuperAdminAsync";
import HomeAdmin from "../pagesAsync/HomeAsync/AdminAsync";
import BusinessIntelligence from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync";
import PesebaranPengunjung from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/PersebaranPengunjungAsync";
import DemografiPengunjung from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/DemografiPengunjungAsync";

//500 error
import Pages500 from "../pages/Utility/pages-500";
const AppSuperAdmin = (props) => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${props.match.url}`}
        to={`${props.match.url}superadmin/persetujuan-konten`}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/persetujuan-konten`}
        component={PersetujuanKontenSuperAdmin}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/registrasi-admin`}
        component={RegistrasiAdmin}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/tambah-pengguna`}
        component={TambahPengguna}
      />
      <Route exact path={`${props.match.url}superadmin/dashboard-admin`} component={HomeAdmin} />
      <Route exact path={`${props.match.url}superadmin/profile`} component={superAdminProfile} />
      {/* BI */}
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence`}
        component={BusinessIntelligence}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-PersebaranPengunjung`}
        component={PesebaranPengunjung}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-DemografiPengunjung`}
        component={DemografiPengunjung}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-TrafficKonten`}
        component={TrafficKonten}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-SummaryTrafficKonten`}
        component={SummaryTrafficKonten}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-ISP`}
        component={ISP}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-DeviceFingerPrint`}
        component={DeviceFingerPrint}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-Survey`}
        component={Survey}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-UserBehavior`}
        component={UserBehavior}
      />
      <Route
        exact
        path={`${props.match.url}superadmin/business-intelligence-VisitorJourney`}
        component={VisitorJourney}
      />
      <Route exact path={`${props.match.url}superadmin/konten`} component={KontenSuperAdmin} />
      <Route exact path={`${props.match.url}superadmin/log-history`} component={LogHistory} />
      <Route exact path={`${props.match.url}page-500`} component={Pages500} />
      <Redirect to="/" />
    </Switch>
  );
};

export default AppSuperAdmin;
