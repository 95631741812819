import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const KontenTayangKuratorAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/KontenTayang/Kurator" /* webpackChunkName: "KontenTayangKuratorAsync" */
    ),
  loading: Loading,
});

export default KontenTayangKuratorAsync;
