import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import Filter from "../../../../Filter/filter";
import moment from "moment";

// ------------------API-----------------------
import API from "../../../../../services";

// -------------------ASSET--------------------
//img
import ImgNews from "../../../../../assets/images/ImgNews.png";
//css
import "./style.scss";
//icon
import IconEmergency from "../../../../../assets/icon/IconEmergency.svg";

// ------------------Component----------------
import ModalPreviewEmergencyDoc from "../../../Popup/PersetujuanKonten/PreviewEmergencyDoc";
import ModalLolos from "../../../../../components/Admin/Popup/Konten/Lolos";
import ModalTidakLolos from "../../../../../components/Admin/Popup/Konten/TidakLolos";

const TableKontenSABerita = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [notif, setNotif] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [refresh, setRefresh] = useState(0);
  //alert
  const [alert, setAlert] = useState("");

  const fetchAPIBerita = () => {
    API.getContentSABerita(props.konten)
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setRows(res.data.data);
        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(res.data.data);
        } else {
          setRows(result);
          document.getElementById("TablePersetujuanKontenSABerita").rows[1].style.transition =
            "0.8s";
          document.getElementById("TablePersetujuanKontenSABerita").rows[1].style.backgroundColor =
            "#e6f3ff";
        }
      })
      .catch((e) => {
        history.push("/pages-500");
        setRows(null);
      });
  };
  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    if (props.categoryContent !== "0") {
      fetchAPIBerita(props.categoryContent);
    } else {
      fetchAPIBerita();
    }
  }, [refresh, props.idContent, props.categoryContent, props.konten]);
  //throw data to parent for download
  const filterData = (item) => {
    props.handleData(item);
  };
  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      const status =
        data.emergency !== undefined && data.emergency !== ""
          ? "Emergency"
          : data.status_kurator[data.status_kurator.length - 1].status == "Tidak Lolos Kurasi"
          ? "Tidak Lolos Kurasi"
          : data.status_kurator[data.status_kurator.length - 1].status == "Lolos Kurasi"
          ? "Lolos Kurasi"
          : "";

      const index = [
        data.title,
        data.requested_by.organization.name,
        data.list_provinsi.map((prov) => prov.name).toString(),
        data.category,
        data.proposed_duration,
        moment(data.tanggal_pembuatan, "DD-MM-YYYY").format("DD-MM-YYYY").toString(),
        data.proposed_date
          .map((date) => moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"))
          .toString(),
        status,
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }
  //modal preview
  const [modalKonten, setModalKonten] = useState(false);
  const [dataModalKonten, setDataModalKonten] = useState({});
  const toggleModalKonten = () => {
    setModalKonten(!modalKonten);
  };
  const handleModalKonten = (url, title) => {
    setDataModalKonten({
      url: url,
      title: title,
    });
    toggleModalKonten();
  };

  //modal doc
  const [modalDoc, setModalDoc] = useState(false);
  const [dataModalDoc, setDataModalDoc] = useState({});
  const toggleModalDoc = () => {
    setModalDoc(!modalDoc);
  };
  const handleModalDoc = (title, data) => {
    setDataModalDoc({
      url: data.emergency,
      title: title,
    });
    toggleModalDoc();
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };
  //modal lolos
  const [modalLolos, setModalLolos] = useState(false);
  const [dataModalLolos, setDataModalLolos] = useState({});
  const toggleModalLolos = () => {
    setModalLolos(!modalLolos);
  };
  const handleModalLolos = (it) => {
    setDataModalLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      activeTab: props.activeTab,
    });
    toggleModalLolos();
  };
  //modal Tidak Lolos
  const [modalTidakLolos, setModalTidakLolos] = useState(false);
  const [dataModalTidakLolos, setDataModalTidakLolos] = useState({});
  const toggleModalTidakLolos = () => {
    setModalTidakLolos(!modalTidakLolos);
  };
  const handleModalTidakLolos = (it) => {
    setDataModalTidakLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      comment: it.comment,
      activeTab: props.activeTab,
      commentAdmin: it.commentAdmin,
      kategoriTolak: it.kategoriTolak,
    });
    toggleModalTidakLolos();
  };

  const getStatus = (it) => {
    let status = it.status;
    let statusKurator = it.status_kurator[it.status_kurator.length - 1].status;
    let emergency = it.emergency;

    if (status === "submitted") {
      if (typeof emergency !== undefined) {
        return (
          <div className="d-flex pointer" onClick={() => handleModalDoc(it.details.title, it)}>
            <img src={IconEmergency}></img>
            <label
              onClick={() => handleModalDoc(it.details.title, it)}
              key={it._id}
              className="mx-auto py-1 pointer"
              style={{ color: "#C42127" }}>
              Emergency
            </label>
          </div>
        );
      } else {
        switch (statusKurator) {
          case "Tidak Lolos Kurasi":
            return (
              <label
                onClick={() => handleModalTidakLolos(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#C42127" }}>
                Tidak Lolos Kurasi
              </label>
            );
            break;
          case "Lolos Kurasi":
            return (
              <label
                onClick={() => handleModalLolos(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#55BA59" }}>
                Lolos Kurasi
              </label>
            );
            break;
          case "Revisi":
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                Revisi
              </label>
            );
            break;
          case "Sudah Revisi":
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                Sudah Revisi
              </label>
            );
            break;
          default:
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                {statusKurator}
              </label>
            );
            break;
        }
      }
    } else {
      switch (status) {
        case "declined":
          return (
            <label
              onClick={() => handleModalTidakLolos(it)}
              key={it._id}
              className="mx-auto py-1"
              style={{ color: "#c42127" }}>
              Ditolak
            </label>
          );
          break;
        case "approved":
          return (
            <label
              onClick={() => handleModalLolos(it)}
              key={it._id}
              className="mx-auto py-1"
              style={{ color: "#55BA59" }}>
              Disetujui
            </label>
          );
          break;
        case "scheduled":
          return (
            <label
              // onClick={() => handleModalLolos(it)}
              key={it._id}
              className="mx-auto py-1">
              Tayang
            </label>
          );
          break;

        default:
          return (
            //todo
            <label
              // onClick={() => handleModalLolos(it)}
              // style={{ color: "#c42127" }}
              key={it._id}
              className="mx-auto py-1">
              {status}
            </label>
          );
          break;
      }
    }
  };
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 200,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Pengajuan",
        field: "tanggal_pengajuan",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Tayang",
        field: "tanggal_tayang",
        sort: "asc",
        width: 150,
      },
      {
        label: "Hasil Kurasi",
        field: "hasil_kurasi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 150,
      },
    ],
    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, index) => ({
            no: index + 1,
            judul: it.title ?? "-",
            organisasi: it.requested_by.organization.name ?? "-",
            provinsi:
              it.list_provinsi.length > 2
                ? `${it ? (it.list_provinsi[0] ? it.list_provinsi[0].name : "-") : "-"}, ${
                    it ? (it.list_provinsi[1] ? it.list_provinsi[1].name : "-") : "-"
                  }, ${it ? (it.list_provinsi[2] ? it.list_provinsi[2].name : "-") : "-"}...`
                : it.list_provinsi.length > 1
                ? `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"},${
                    it.list_provinsi[1] ? it.list_provinsi[1].name : "-"
                  }`
                : `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"}`,
            kategori: it.category ?? "-",
            tanggal_pengajuan: `${moment(it.tanggal_pembuatan).format("YYYYMMDD")}
            ${moment(it.tanggal_pembuatan).format("DD-MM-YYYY")}`,
            tanggal_tayang:
              it.proposed_date.length > 0
                ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format("YYYYMMDD")}
                ${moment(it.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")}\n s/d \n${moment(
                    it.proposed_date.slice(-1),
                    "DD-MM-YYYY"
                  ).format("DD-MM-YYYY")} `
                : "-",
            hasil_kurasi: getStatus(it),
            preview: [
              <a key={index} href={it.link ?? "-"} target="_blank">
                <center>
                  <img
                    alt={it.title ?? "-"}
                    className="wrapperImg"
                    src={`${process.env.REACT_APP_BE_URL}/${
                      typeof it.details === "undefined" ? "-" : it.details.url ?? "-"
                    }`}></img>
                </center>
              </a>,
            ],
          })),
  };

  return (
    <React.Fragment>
      <ModalPreviewEmergencyDoc
        dataModalDoc={dataModalDoc}
        modalDoc={modalDoc}
        toggleModalDoc={toggleModalDoc}
      />
      <ModalLolos
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        dataModalLolos={dataModalLolos}
        modalLolos={modalLolos}
        toggleModalLolos={toggleModalLolos}
      />
      <ModalTidakLolos
        dataModalTidakLolos={dataModalTidakLolos}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalTidakLolos={modalTidakLolos}
        toggleModalTidakLolos={toggleModalTidakLolos}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  <Container>
                    <Alert color={notif ? "success" : "danger"} isOpen={isSuccessSubmitted}>
                      {message}
                    </Alert>
                  </Container>
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : rows.length === 0 && isEmpty ? (
                    <div className="justify-content-md-center">Tidak Ada Data</div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="TablePersetujuanKontenSABerita"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableKontenSABerita;
