import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import Filter from "../../../../Filter/filter";
// import FilterCalendar from "../../../../Filter/filterCalendar";
import ReactPaginate from "react-paginate";

// ------------------REDUX---------------------
import { connect } from "react-redux";
import { detailData } from "../../../../../store/actions";
// ------------------API-----------------------
import API from "../../../../../services";
import ProvinceModal from "./../modals/ProvinceModal";

// -------------------ASSET--------------------
//css
import "./style.scss";

const TableBehaviorSite = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  // const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  //calendar
  // const [sDate, setStartDate] = useState("");
  // const [eDate, setEndDate] = useState("");
  // const [selectedDate, setSelectedDate] = useState("");
  const [modalProvince, setModalProvince] = useState(false);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageDisplay, setPageDisplay] = useState(0);

  const [selectedProvince, setSelectedProvince] = useState([]);
  const [selectedAll, setSelectedAll] = useState(true);

  var items = rows;

  const toggleModalProvince = () => {
    setModalProvince(!modalProvince);
  };

  // let enumerateDaysBetweenDates = (startDate, endDate) => {
  //   let now = startDate.clone(),
  //     dates = [];

  //   while (now.isSameOrBefore(endDate)) {
  //     dates.push(now.format("DD-MM-YYYY"));
  //     now.add(1, "days");
  //   }

  //   return dates;
  // };

  // const handleSelectedDate = (date) => {
  //   let startDate = moment(date[0].startDate);
  //   let endDate = moment(date[0].endDate);
  //   //throw data to check availability
  //   setStartDate(startDate.format("DD-MM-YYYY"));
  //   setEndDate(endDate.format("DD-MM-YYYY"));
  //   //data for
  //   setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
  //   setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  // };

  // const [tanggal, setTanggal] = useState("");
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  const fetchAPIVideo = async (params) => {
    setLoading(true);
    setPage(params.page ?? 1);

    await API.getBehaviourSite(params)
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);

          setRows([]);
          setLoading(false);
        } else {
          setPageCount(res.data.count);
          setPageDisplay(10);
          setRows(res.data.data);
          setLoading(false);
        }

        return API.getBehaviourSite({
          provinsi: params.provinsi,
          search: params.search,
          limit: 0,
          skip: 0,
        });
      })
      .then((res) => {
        props.handleData([]);
        if (res.data.data.length > 0) {
          props.handleData(res.data.data);
        }

        setLoading(false);
      })
      .catch((e) => {
        //history.push("/pages-500");
        setRows([]);
        setLoading(false);
      });
  };

  const handlePageClick = (data) => {
    //console.log("data from handle ==> ", data);
    //const searchValue = searchRef.current.value;
    setPage(data.selected + 1);
  };

  useEffect(() => {
    const params = { limit: 10 };

    const searchValue = searchRef.current.value;

    if (page) {
      params.page = page;
      params.skip = (page - 1) * 10;
    }

    if (searchValue) {
      params.search = searchValue;
      //props.setSearch(searchValue);
    }

    if (selectedProvince.length > 0) {
      params.provinsi = selectedProvince
        .map((item) => {
          return item.name;
        })
        .join();
      //props.setProvince(params.provinsi);
    }

    fetchAPIVideo(params);
  }, [page]);

  // useEffect(() => {
  //   const params = { limit: 10 };
  //   fetchAPIVideo(params);
  // }, []);

  //set search data based on input value
  const handleSearch = () => {
    const params = { limit: 10 };

    const searchValue = searchRef.current.value;

    params.page = 1;
    params.skip = 0;

    if (searchValue) {
      params.search = searchValue;
    }

    if (selectedProvince.length > 0) {
      params.provinsi = selectedProvince
        .map((item) => {
          return item.name;
        })
        .join();
    }

    // props.setSearch(searchValue);
    // props.setProvince(params.provinsi);

    fetchAPIVideo(params);
    // setLoading(true);
    // setPage(1);

    // setTimeout(function () {
    //   setLoading(false);
    // }, 3000);
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Nama Lokasi",
        field: "location",
        sort: "asc",
        width: 150,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kabupaten",
        field: "kabupaten",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total DFP Unique",
        field: "device",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Kunjungan",
        field: "visit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Integrasi",
        field: "integrate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Terakhir Diakses",
        field: "akses",
        sort: "asc",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              location: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, index) => ({
            no: index + (page - 1) * 10 + 1,
            location: it.name ?? "-",
            provinsi: it.provinsi ?? "-",
            kabupaten: it.kabupaten_kota ?? "-",
            device: it.uniqdfp ?? "-",
            visit: it.jumlah ?? "-",
            integrate: it
              ? it.first
                ? `
          ${moment(it.first.tanggal_pembuatan).format("DD-MM-YYYY")}
          `
                : "-"
              : "-",
            akses: it
              ? it.last
                ? `
          ${moment(it.last.tanggal_pembuatan).format("DD-MM-YYYY")}
          `
                : "-"
              : "-",
          })),
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="text-bold"></div>
            </Col>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="text-center" style={{ width: "100%", textAlign: "center" }}>
                    {loading ? <Spinner color="primary" /> : ""}
                  </div>
                  <div className="d-flex ml-auto w-25 my-3">
                    <Button
                      type="button"
                      style={{ backgroundColor: "#2c3280" }}
                      onClick={toggleModalProvince}
                      title={JSON.stringify(selectedProvince.map((item) => item.name))}>
                      Provinsi
                    </Button>
                    <input
                      style={{ margin: "0 5px 0 5px" }}
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      type="text"
                    />
                    <Button
                      className="btn btn-sm"
                      onClick={handleSearch}
                      style={{ margin: "0 5px 0 5px", backgroundColor: "#2c3280" }}
                      value={search}
                      disabled={loading}>
                      <i class="fa fa-search"></i>
                    </Button>
                  </div>
                  {rows.length === 0 && !isEmpty && loading ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center"> Data tidak ditemukan </div>
                  ) : (
                    <>
                      <div style={{ whiteSpace: "pre-line" }}>
                        <MDBDataTable
                          id="tableBehaviourSite"
                          noBottomColumns={true}
                          className="text-center"
                          responsive
                          disableRetreatAfterSorting
                          striped
                          bordered
                          data={data}
                          displayEntries={false}
                          pagesAmount={5}
                          entries={10}
                          searching={false}
                          paging={false}
                        />
                        <div className="float-left">
                          <span>
                            Showing {(page - 1) * 10 + 1} to{" "}
                            {(page - 1) * 10 + 10 > pageCount ? pageCount : (page - 1) * 10 + 10} of{" "}
                            {pageCount}
                          </span>
                        </div>
                        <div className="float-right">
                          <ReactPaginate
                            pageCount={Math.ceil(pageCount / pageDisplay)}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            initialPage={page - 1} // INDEX
                            forcePage={page - 1}
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"></ReactPaginate>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ProvinceModal
          toggleModalProvince={toggleModalProvince}
          modalProvince={modalProvince}
          setSelectedProvince={setSelectedProvince}
          selectedProvince={selectedProvince}
          selectedAll={selectedAll}
          setSelectedAll={setSelectedAll}
          handleSearch={handleSearch}></ProvinceModal>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStatetoProps, { detailData })(TableBehaviorSite);
