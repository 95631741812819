import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
// todo
// import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

import "./datatables.scss";
import noImage from "../../../../assets/images/noImage.jpg";
// todo use this component if filter not using api
// import Filter from "../../../Filter/filter";
import FilterCalendar from "../../../Filter/filterCalendar";
import FilterButton from "./dropdownFilter.js";
import PopupVideo from "../../Popup/KontenTayang/PopupVideo";
import PopupBanner from "../../Popup/KontenTayang/PopupBanner";
import News from "../../../../assets/images/news_bakti.png";
import API from "../../../../services";
import { lisKategoriLokasi, listProvinsi } from "../../../Common/const";

const KontenTayangTable = (props) => {
  let history = useHistory();
  const [modalVideo, setModalVideo] = useState(false);
  const [modalBanner, setModalBanner] = useState(false);
  const [dataVideo, setDataVideo] = useState({});
  const [dataBanner, setDataBanner] = useState({});
  const [rows, setRows] = useState([]);
  const [filterProvinsi, setFilterProvinsi] = useState("");
  const [filterKatLokasi, setFilterKatLokasi] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //if date empty
  const [isEmpty, setIsEmpty] = useState(false);

  let toggleVideo = (i) => {
    setDataVideo(i);
    setModalVideo(!modalVideo);
  };

  let toggleBanner = (i) => {
    setDataBanner(i);
    setModalBanner(!modalBanner);
  };

  //Create Data for Row
  function createData(no, jamtayang, provinsi, video, banner, berita) {
    return { no, jamtayang, provinsi, video, banner, berita };
  }

  async function fetchKontenTayang() {
    let no = 1;
    setIsLoading(true);
    let inputAvailable = filterProvinsi !== "" && tanggal !== "" && filterKatLokasi !== "";
    API.postKontenTayang({
      provinsi: filterProvinsi.toUpperCase(),
      tanggal: tanggal,
      kategori: filterKatLokasi,
    })
      .then((res) => {
        if (res.data.data.length !== 0 && inputAvailable) {
          let rowsData = [];
          let json = res.data;

          for (let i of json.data) {
            //check content availibility
            let vidObj = typeof i.video;
            let bannerObj = typeof i.banner;
            let newsObj = typeof i.news;
            console.log("type", vidObj, bannerObj, newsObj);
            //video by user & default
            let vidData = typeof i.video?._id !== "undefined" && i.video;
            let srcBannerImg;
            if (typeof i.banner?._id !== "undefined" && typeof i.banner.details !== "undefined") {
              if (typeof i.banner.details.url !== "undefined") {
                srcBannerImg = i.banner.details.url;
              } else {
                srcBannerImg = i.banner.details;
              }
            }

            let video = "";
            if (vidObj !== "undefined") {
              video =
                /* eslint-disable */
                [
                  <a key={i.video?._id} onClick={() => toggleVideo(i)}>
                    {/* todo  */}
                    {/* {vidData.type === "youtube" ? ( */}
                    <img
                      src={`${process.env.REACT_APP_BE_URL}/${vidData.details.thumbnail}`}
                      className="img-thumbnail"
                      // src={`${a.details.url}/0.jpg`}
                      alt={vidData.url}
                      onError={(e) => {
                        e.target.src = News; // If Error
                      }}
                    />
                    {/* ) : (
                    <center>
                      <div className="img-thumbnail">
                        <ReactPlayer
                          url={`${process.env.REACT_APP_BE_URL}/${vidData.details.url}`}
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </center>
                  )} */}
                  </a>,
                ];
            } else {
              video = [<React.Fragment />];
            }

            let banner = "";
            if (bannerObj !== "undefined") {
              banner =
                /* eslint-disable */
                [
                  <a key={i.banner?._id} onClick={() => toggleBanner(i)}>
                    <img
                      className="img-thumbnail"
                      src={`${process.env.REACT_APP_BE_URL}/${srcBannerImg}`}
                      alt={srcBannerImg}
                      onError={(e) => {
                        e.target.src = News; //If Error
                      }}
                    />
                  </a>,
                ];
            } else {
              banner = [<React.Fragment />];
            }

            let news = "";
            if (newsObj !== "undefined") {
              news = [
                <a key={i.news?._id} href={i.news.link} target="_blank" rel="noopener noreferrer">
                  <img
                    onError={(e) => {
                      e.target.src = noImage;
                    }}
                    className="img-thumbnail"
                    //src condition by user & default
                    src={`${process.env.REACT_APP_BE_URL}/${i.news.details.url}`}
                    href={i.news.link}
                    alt={i.news.link}></img>
                </a>,
              ];
            } else {
              news = [<React.Fragment />];
            }

            rowsData.push(
              createData(no, i.approved_time_start, i.approved_provinsi, video, banner, news)
            );
            no++;
          }
          setIsLoading(false);
          if (rowsData.length === 0) {
            setIsEmpty(true);
          } else {
            setIsEmpty(false);
          }
          setRows(rowsData);
        } else {
          setRows([
            {
              jamtayang: "Tidak ada data",
              durasi: "",
              video: "",
              banner: "",
              berita: "",
            },
          ]);
        }
      })
      .catch((err) => {
        console.log("err", err);
        history.push("/pages-500");
        setRows([
          {
            jamtayang: "Terjadi Kesalahan",
            durasi: "",
            video: "",
            banner: "",
            berita: "",
          },
        ]);
      });
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Jam Tayang",
        field: "jamtayang",
        sort: "asc",
        width: 200,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Video",
        field: "video",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Banner",
        field: "banner",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Berita",
        field: "berita",
        sort: "disabled",
        width: 200,
      },
    ],
    rows:
      rows.length === 0
        ? [
            {
              no: "",
              jamtayang: "",
              provinsi: "Tidak ada data",
              video: "",
              banner: "",
              berita: "",
            },
          ]
        : rows,
  };

  const filterData = (filt, type) => {
    switch (type) {
      case "provinsi":
        setFilterProvinsi(filt);
        break;
      case "kategori lokasi":
        setFilterKatLokasi(filt);
        break;

      default:
        break;
    }
    setRows([]); // Clear table
  };
  //filter tanggal
  const [tanggal, setTanggal] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);

    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };
  //filter konten tayang by date
  useEffect(() => {
    let inputAvailable = filterProvinsi !== "" && tanggal !== "" && filterKatLokasi !== "";
    if (inputAvailable) {
      fetchKontenTayang();
    }
  }, [tanggal, filterProvinsi, filterKatLokasi]);

  return (
    <React.Fragment>
      <PopupVideo
        modalVid={modalVideo}
        toggleVid={() => toggleVideo(dataVideo)}
        data={dataVideo}></PopupVideo>
      <PopupBanner
        modalBanner={modalBanner}
        toggleBanner={() => toggleBanner(dataBanner)}
        data={dataBanner}></PopupBanner>
      <FilterCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        selectedDate={handleSelectedDate}
      />

      <Card style={{ boxShadow: "0px 0px 10px #D3D3D3" }}>
        <Container>
          <Col className="d-flex flex-wrap" md={{ size: 12 }} lg={{ size: 12 }}>
            <Col
              className="my-1"
              lg={{ size: 4 }}
              style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
              <Input
                id="inputRef"
                max={new Date().toISOString().split("T")[0]}
                type="date"
                color=""
                className="inputWrapper btnTanggal"
                value={selectedDate}
                onClick={(e) => {
                  document.getElementById("inputRef").addEventListener("change", (e) => {
                    setSelectedDate(e.target.value);
                    setTanggal(moment(e.target.value).format("DD-MM-YYYY"));
                  });
                }}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  setTanggal(moment(e.target.value).format("DD-MM-YYYY"));
                }}
              />
              {/* todo
              <Button
                color=""
                onClick={toggleModalCalendar}
                className="inputWrapper btnTanggal"
                style={{ marginRight: "10px" }}>
                <p className="selectedDate">
                  {selectedDate}
                  <span class="mdi mdi-18px mdi-calendar-range" style={{ float: "right" }} />
                </p>
              </Button> */}
            </Col>

            <Col className="my-1" lg={{ size: 4 }}>
              <FilterButton
                filter={filterProvinsi}
                clicked={filterData}
                type={"provinsi"}
                listDropdown={listProvinsi}
              />
            </Col>
            <Col className="my-1" lg={{ size: 4 }}>
              <FilterButton
                filter={filterKatLokasi}
                clicked={filterData}
                type={"kategori lokasi"}
                listDropdown={lisKategoriLokasi}
              />
            </Col>
          </Col>
        </Container>
      </Card>

      <Card
        style={{ boxShadow: "0px 0px 10px #D3D3D3", paddingBottom: "30px", paddingTop: "20px" }}>
        <CardBody>
          {isLoading ? (
            <div>
              <h1>
                <Skeleton />
              </h1>
              <Skeleton count={10} />
            </div>
          ) : isEmpty ? (
            <Col className="center" lg="12">
              <h5>Data Tidak ditemukan</h5>
            </Col>
          ) : (
            <MDBDataTable
              className="text-center"
              responsive
              striped
              bordered
              searching={false}
              displayEntries={false}
              info={false}
              entries={10} //How much data that you want to show in 1 table
              disableRetreatAfterSorting //Show red Warning after use
              data={data}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default KontenTayangTable;
