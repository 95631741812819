import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../../Filter/filter";
// ------------------API-----------------------
import API from "../../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconCommentPurple from "../../../../../assets/icon/IconCommentPurple.svg";
import IconCommentRed from "../../../../../assets/icon/IconCommentRed.svg";
import IconDecline from "../../../../../assets/icon/IconDecline.svg";
import IconCheck from "../../../../../assets/icon/IconCheck.svg";
import IconInfo from "../../../../../assets/icon/IconInfo.svg";
import IconGreenCheckList from "../../../../../assets/icon/IconGreenChecklist.svg";
import IconRedCheckList from "../../../../../assets/icon/IconRedChecklist.svg";
import IconPDF from "../../../../../assets/icon/IconPDF.svg";
import IconDelete from "../../../../../assets/icon/IconDelete.svg";

// ------------------Component----------------
import ModalPreviewVideo from "../../../Popup/Pengguna/PreviewVideo";
import ModalVerif from "../../../Popup/Pengguna/Verification";
import ModalDetailPenggunaCA from "../../../Popup/Pengguna/DetailPengguna/ContentAdmin";
import ModalPreviewDoc from "../../../Popup/Pengguna/PreviewDoc";
import ModalDetailSupport from "../../../Popup/Pengguna/DetailPengguna/Support";
import ModalDetailKurator from "../../../Popup/Pengguna/DetailPengguna/Kurator";
import ModalNonAktif from "../../../Popup/Pengguna/NonAktif";
import ModalDecline from "../../../Popup/Pengguna/DeclineUser";
import ModalDetailPreview from "../../../Popup/Pengguna/PreviewDetail";
// ------------------Redux----------------
import { connect } from "react-redux";
import noImage from "../../../../../assets/images/noImage.jpg";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import FilterCalendar from "../../../../Filter/filterCalendar";
//lodash
import _ from "lodash";

const TablePersetujuanPengguna = (props) => {
  let getRoleStorage = window.localStorage.getItem("role");
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);

  const [sDate, setStartDate] = useState("");
  const [eDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tanggal, setTanggal] = useState("");

  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //throw data to check availability
    setStartDate(startDate.format("DD-MM-YYYY"));
    setEndDate(endDate.format("DD-MM-YYYY"));
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };

  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };
  //alert
  const [alert, setAlert] = useState("");
  const fetchAPIVideo = () => {
    setLoading(true);
    API.getUserRequested()
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setLoading(false);

        const onListData = res.data.data;

        props.handleData(onListData);

        let result =
          props.idNotif && props.idNotif.length
            ? onListData.filter((o) => {
                return o._id !== props.idNotif;
              })
            : [];
        let filter = onListData
          .filter((o) => {
            return o._id == props.idNotif;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(onListData);
        } else {
          setRows(result);
          document.getElementById("TablePersetujuanPengguna").rows[1].style.transition = "0.8s";
          document.getElementById("TablePersetujuanPengguna").rows[1].style.backgroundColor =
            "#e6f3ff";
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    if (props.categoryContent !== "0") {
      fetchAPIVideo(props.categoryContent);
    } else {
      fetchAPIVideo();
    }
  }, [refresh, props.idNotif, props.categoryContent]);
  //throw data to parent for download
  const filterData = (item) => {
    // console.log("yg bkl ke lempar", item)
    props.handleData(item);
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "" && tanggal === "") return data;
      const index = [
        data.username,
        data.name,
        data.email,
        data.organization ? data.organization.name : "-",
        data.organization? data.organization.role? data.organization.role.description : "-": "-",
        `${moment(data.doc_penunjang.tanggal_pembuatan).format("DD-MM-YYYY")}`,
        data.status === "approved"
          ? "Aktif"
          : data.status === "requested"
          ? "Tidak Aktif"
          : data.status === "confirmation"
          ? "Belum Dikonfirmasi"
          : "",
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
          date: tanggal,
        })
      ) {
        return data;
      }
    });
  }

  //modal preview
  const [modalKonten, setModalKonten] = useState(false);
  const [dataModalKonten, setDataModalKonten] = useState({});
  const toggleModalKonten = () => {
    setModalKonten(!modalKonten);
  };
  const handleModalKonten = (url, title, type) => {
    setDataModalKonten({
      url: url,
      title: title,
      type: type,
    });
    toggleModalKonten();
  };
  //modal verif
  const [modalVerif, setModalVerif] = useState(false);
  const [dataModalVerif, setDataModalVerif] = useState({});
  const toggleModalVerif = () => {
    setModalVerif(!modalVerif);
  };
  const handleModalVerif = (it) => {
    setDataModalVerif({
      user: it.username,
      id: it._id,
      activeTab: props.activeTab,
    });
    toggleModalVerif();
  };

  //modal doc
  const [modalDoc, setModalDoc] = useState(false);
  const [dataModalDoc, setDataModalDoc] = useState({});
  const toggleModalDoc = () => {
    setModalDoc(!modalDoc);
  };
  const handleModalDoc = (it, docType) => {
    setDataModalDoc({
      url: it.doc_penunjang,
      title: "dummy",
      docType: docType,
    });
    toggleModalDoc();
  };

  //modal nonaktif
  const [modalNonAktif, setModalNonAktif] = useState(false);
  const [dataModalNonAktif, setDataModalNonAktif] = useState({});
  const toggleModalNonAktif = () => {
    setModalNonAktif(!modalNonAktif);
  };
  const handleModalNonAktif = (it) => {
    setDataModalNonAktif({
      data: it,
    });
    toggleModalNonAktif();
  };
  //modaldecline
  const [modalDecline, setModalDecline] = useState(false);
  const [dataModalDecline, setDataModalDecline] = useState({});
  const toggleModalDecline = (it) => {
    setModalDecline(!modalDecline);
  };
  const handleModalDecline = (it) => {
    setDataModalDecline({
      id: it._id,
      data: it,
    });
    toggleModalDecline();
  };
  //modal detail Pengguna
  const [modalDetailPenggunaCA, setModalDetailPenggunaCA] = useState(false);
  const [dataModalDetailPenggunaCA, setDataModalDetailPenggunaCA] = useState({});
  const toggleModalDetailPenggunaCA = () => {
    setModalDetailPenggunaCA(!modalDetailPenggunaCA);
  };
  //modal detail support
  const [modalDetailSupport, setModalDetailSupport] = useState(false);
  const [dataModalDetailSupport, setDataModalDetailSupport] = useState({});
  const toggleModalDetailSupport = () => {
    setModalDetailSupport(!modalDetailSupport);
  };
  //modal detail kurator
  const [modalDetailKurator, setModalDetailKurator] = useState(false);
  const [dataModalDetailKurator, setDataModalDetailKurator] = useState({});
  const toggleModalDetailKurator = () => {
    setModalDetailKurator(!modalDetailKurator);
  };
  const handleModalDetailPengguna = (it, role) => {
    switch (role) {
      case "Admin":
        setDataModalDetailPenggunaCA({
          data: it,
        });
        toggleModalDetailPenggunaCA();
        break;
      case "Content Partner":
        setDataModalDetailPenggunaCA({
          data: it,
        });
        toggleModalDetailPenggunaCA();
        break;
      case "Content Admin":
        setDataModalDetailPenggunaCA({
          data: it,
        });
        toggleModalDetailPenggunaCA();
        break;
      case "Kurator":
        setDataModalDetailKurator({
          data: it,
        });
        toggleModalDetailKurator();
        break;
      case "support":
        setDataModalDetailSupport({
          data: it,
        });
        toggleModalDetailSupport();
        break;

      default:
        break;
    }
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  const ButtonAction = ({ it }) => {
    const role = it
      ? it.organization
        ? it.organization.role
          ? it.organization.role.description
          : ""
        : ""
      : "";

    return (
      <Row className="d-flex justify-content-lg-around flex-nowrap">
        {getRoleStorage == "Support Center" ? (
          <></>
        ) : (
          <>
            <Col xs="4" className="mr-2">
              <img
                className="btnAction "
                src={IconCheck}
                onClick={() => handleModalVerif(it)}></img>
            </Col>
            <Col xs="4" className="mr-2">
              <img
                className="btnAction"
                src={IconDecline}
                onClick={() => handleModalDecline(it)}></img>
            </Col>
          </>
        )}

        <Col xs="4">
          <img
            className="btnAction"
            src={IconInfo}
            onClick={() => handleModalDetailPengguna(it, role)}></img>
        </Col>
      </Row>
    );
  };

  //column dok penunjang
  const DokPenunjang = ({ it }) => {
    return (
      <div className="wrapperBtnAction">
        <div className="d-block">
          <img
            className="btnAction"
            src={IconPDF}
            onClick={() => handleModalDoc(it, "Surat Tugas")}></img>
          <p style={{ fontSize: "10px", width: "37px" }}>Surat Tugas</p>
        </div>
        <div className="d-block">
          <img
            className="btnAction"
            src={IconPDF}
            onClick={() => handleModalDoc(it, "Surat PP")}></img>
          <p style={{ fontSize: "10px", width: "37px" }}>Surat PP</p>
        </div>
        <div className="d-block">
          <img
            className="btnAction"
            src={IconPDF}
            onClick={() => handleModalDoc(it, "Surat KTP")}></img>
          <p style={{ fontSize: "10px", width: "37px" }}>KTP</p>
        </div>
        <div className="d-block">
          <img
            className="btnAction"
            src={IconPDF}
            onClick={() => handleModalDoc(it, "Swafoto KTP")}></img>
          <p style={{ fontSize: "10px", width: "41px" }}>Swafoto KTP</p>
        </div>
      </div>
    );
  };
  //preview ktp
  const [modalDetailPreview, setModalDetailPreview] = useState(false);
  const [dataModalDetailPreview, setDataModalDetailPreview] = useState({});
  const handleModalDetailPreview = (title, url) => {
    setModalDetailPreview(true);
    setDataModalDetailPreview({
      title: title,
      url: url,
    });
  };
  //preview ktp
  const PreviewKTP = ({ it }) => {
    let assetKTP =
      it.doc_penunjang.fileKtp.length &&
      `${process.env.REACT_APP_BE_URL}/${it.doc_penunjang.fileKtp[0].path}`;
    return (
      <div className="wrapperBtnAction">
        <div className="d-block">
          <img
            onClick={(e) => e.target.src == assetKTP && handleModalDetailPreview("KTP", assetKTP)}
            style={{
              width: "82px",
              height: "60px",
              objectFit: "cover",
            }}
            className="btnAction"
            onError={(e) => {
              e.target.src = noImage;
            }}
            src={assetKTP}></img>
        </div>
      </div>
    );
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 200,
      },
      {
        label: "Nama",
        field: "nama",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Peran",
        field: "peran",
        sort: "asc",
        width: 150,
      },

      {
        label: "Waktu Registrasi",
        field: "waktu_registrasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "No Telefon",
        field: "p",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Dokumen Penunjang",
        field: "dok_penunjang",
        sort: "disabled",
        width: 150,
      },
      {
        label: "KTP",
        field: "ktp",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Aksi",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              waktu_registrasi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, index) => ({
            no: index + 1,
            username: it.username ?? "-",
            nama: it.name ?? "-",
            email: it.email ?? "-",
            organisasi: it ? (it.organization ? it.organization.name ?? "-" : "-") : "-",
            peran: it
              ? it.organization
                ? it.organization.role
                  ? it.organization.role.description ?? "-"
                  : "-"
                : "-"
              : "-",
            waktu_registrasi: it
              ? it.doc_penunjang
                ? it.doc_penunjang.tanggal_pembuatan
                  ? `${moment(it.doc_penunjang.tanggal_pembuatan).format("YYYYMMDD")}
            ${moment(it.doc_penunjang.tanggal_pembuatan).format("DD-MM-YYYY")}
            `
                  : "-"
                : "-"
              : "-",
            p: it.phone,
            dok_penunjang: <DokPenunjang it={it ? it : ""} />,
            ktp: <PreviewKTP it={it ? it : ""} />,
            aksi: <ButtonAction it={it ? it : ""} />,
          })),
  };

  return (
    <React.Fragment>
      {alert == "successVerif" ? (
        <SweetAlert
          timeout={3}
          title="User Berhasil di Setujui"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alert == "successDecline" ? (
        <SweetAlert
          timeout={3}
          title="User Berhasil di Tolak"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconRedCheckList}></img>
        </SweetAlert>
      ) : alert == "failedFetch" ? (
        <SweetAlert
          timeout={3}
          error
          title="User tidak berhasil di kurasi"
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : (
        ""
      )}
      <ModalDetailPreview
        dataModalDetailPreview={dataModalDetailPreview}
        modalDetailPreview={modalDetailPreview}
        toggleModalDetailPreview={() => setModalDetailPreview(!modalDetailPreview)}
      />
      <ModalPreviewDoc
        dataModalDoc={dataModalDoc}
        modalDoc={modalDoc}
        toggleModalDoc={toggleModalDoc}
      />
      <ModalPreviewVideo
        dataModalKonten={dataModalKonten}
        modalKonten={modalKonten}
        toggleModalKonten={toggleModalKonten}
      />
      <ModalVerif
        dataModalVerif={dataModalVerif}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalVerif={modalVerif}
        toggleModalVerif={toggleModalVerif}
      />

      <ModalDetailPenggunaCA
        dataModalDetailPengguna={dataModalDetailPenggunaCA}
        modalDetailPengguna={modalDetailPenggunaCA}
        toggleModalDetailPengguna={toggleModalDetailPenggunaCA}
      />
      <ModalDetailSupport
        dataModalDetailPengguna={dataModalDetailSupport}
        modalDetailPengguna={modalDetailSupport}
        toggleModalDetailPengguna={toggleModalDetailSupport}
      />
      <ModalDetailKurator
        dataModalDetailPengguna={dataModalDetailKurator}
        modalDetailPengguna={modalDetailKurator}
        toggleModalDetailPengguna={toggleModalDetailKurator}
      />
      <ModalNonAktif
        dataModalNonAktif={dataModalNonAktif}
        modalNonAktif={modalNonAktif}
        toggleModalNonAktif={toggleModalNonAktif}
      />
      <ModalDecline
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        dataModalDecline={dataModalDecline}
        modalDecline={modalDecline}
        toggleModalDecline={toggleModalDecline}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-50 my-3">
                    <Button
                      color=""
                      onClick={toggleModalCalendar}
                      id="tanggal"
                      className="inputWrapper btnTanggal"
                      style={{ marginRight: "10px" }}>
                      <p className="selectedDate">
                        {selectedDate}
                        <span class="mdi mdi-18px mdi-calendar-range" style={{ float: "right" }} />
                      </p>
                    </Button>
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>

                  <FilterCalendar
                    modalCalendar={modalCalendar}
                    toggleModalCalendar={toggleModalCalendar}
                    selectedDate={handleSelectedDate}
                  />

                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">Tidak Ada Data</div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="TablePersetujuanPengguna"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TablePersetujuanPengguna);
