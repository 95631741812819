import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import img from "../../../../../";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  let judul;
  switch (props.activeTab) {
    case "1":
      judul = "KONTEN VIDEO";
      break;
    case "2":
      judul = "KONTEN BANNER";
      break;
    case "3":
      judul = "KONTEN BERITA";
      break;
    default:
      break;
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`DATA ${judul} ${role}`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Judul</th>
          <th>Organisasi</th>
          <th>Kategori Lokasi</th>
          <th>Kategori</th>
          <th>Durasi</th>
          <th>Tanggal Tayang</th>
          <th>Jam Tayang</th>
          <th>Provinsi</th>
          <th>Status</th>
          <th style={{ width: "300px" }}>Preview</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index} style={{ height: "200px", textAlign: "center", verticalAlign: "middle" }}>
            <td>{index + 1}</td>
            <td>{data.title ? data?.title ?? "-" : data?.details?.title ?? "-"}</td>
            <td>{data?.requested_by?.organization.name ?? "-"}</td>
            <td>
              {Array.isArray(data.location) && data.location.length
                ? data?.location ?? "Tidak ada kategori lokasi"
                : "Tidak ada kategori lokasi"}
            </td>
            <td>{data?.category ?? "-"}</td>
            <td>{data?.proposed_duration + "Jam" ?? "-"}</td>
            <td>
              {data.proposed_date && data.proposed_date.length > 0
                ? `${moment(data.proposed_date[0], "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}\n s/d \n${moment(data.proposed_date.slice(-1), "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )} `
                : "-"}
            </td>
            <td>{data.proposed_time ? `${data?.proposed_time[0][0]}` ?? "" : "-"}</td>
            <td>
              {data.list_provinsi.length !== 0 ? data?.list_provinsi.map((e) => `${e.name}, ` ?? "-") : "-"}
            </td>
            <td>
              {data.status === "submitted" &&
              data.status_kurator[data.status_kurator.length - 1].status === "Lolos Kurasi"
                ? "disetujui"
                : data.status == "scheduled"
                ? "Tayang"
                : data.status == "declined"
                ? "ditolak"
                : data.status == "Terjadwal"
                ? "Terjadwal"
                : data.status == "submitted"
                ? "Baru"
                : ""}
            </td>
            <td>
              <img
                src={
                  props.activeTab === "1"
                    ? `${process.env.REACT_APP_SELF_URL}/${data.details.thumbnail}`
                    : `${process.env.REACT_APP_SELF_URL}/${data.details.url}`
                }
                height="100"
                style={{
                  objectFit: "cover",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}></img>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
