import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const RegistrasiAdminAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/RegistrasiAdmin/SuperAdmin" /* webpackChunkName: "RegistrasiAdminAsync" */
    ),
  loading: Loading,
});

export default RegistrasiAdminAsync;
