import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Skeleton from "react-loading-skeleton";

// STYLE
import "./style.scss";
import "./style.css";
import { Alert, Row } from "reactstrap";
// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLSISP from "../Download/DownloadXLSOrgProvJam";

// import API from "../../../../services";

const TableOrgProvJam = (props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // let toggleVideo = (i) => {
  //   setDataVideo(i);
  //   setModalVideo(!modalVideo);
  // };

  // let toggleBanner = (i) => {
  //   setDataBanner(i);
  //   setModalBanner(!modalBanner);
  // };

  //Create Data for Row
  // function createData(jamtayang, provinsi, video, banner, berita) {
  //   return { jamtayang, provinsi, video, banner, berita };
  // }

  // const filterData =
  //   props.data &&
  //   props.data.table.filter((e) => {
  //     return e.province == props.prov;
  //   });

  const getData = () => {
    setLoading(true);
    if (props.data) {
      setRows(
        props.data &&
          props.data.map((res) => ({
            jam: res.time,
            total_impresi: res.total_impress,
            maks_impresi: res.max_impressi,
            total_klik: res.total_clicked,
            maks_klik: res.max_clicked,
            total_dfp_unik: res.unique_dfp,
          }))
      );
      setTimeout(() => {
        setLoading(false);
      }, 100);
    } else {
      setRows(null);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = {
    columns: [
      {
        label: "Jam Tayang",
        field: "jam",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Impresi",
        field: "total_impresi",
        sort: "asc",
        width: 200,
      },
      {
        label: "Maks Impresi",
        field: "maks_impresi",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Klik",
        field: "total_klik",
        sort: "asc",
        width: 200,
      },
      {
        label: "Maks Klik",
        field: "maks_klik",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total DFP Unik",
        field: "total_dfp_unik",
        sort: "asc",
        width: 200,
      },
    ],
    rows:
      rows.length === 0
        ? [
            {
              jam: "",
              total_impresi: "",
              maks_impresi: "",
              total_klik: "",
              maks_klik: "",
              total_dfp_unik: "",
            },
          ]
        : rows,
  };

  return (
    <React.Fragment>
      {/* <Card style={{ paddingBottom: "30px", paddingTop: "20px" }}>
        <CardBody> */}
      <Row
        style={{
          paddingBottom: "15px",
          paddingTop: "15px",
          display:"flex",
          justifyContent:"flex-end"
        }}>
        {/* DOWNLOAD XLS */}
        <div>
          <span className="mr-2">Download</span>
          <ReactHTMLTableToExcel
            id="TableXLSISP"
            className="DownloadTableXLS"
            table="DownloadTableXLSTraffikOrgProvJam"
            filename="XLS_Traffik_Org_Prov_Jam"
            sheet="users"
            buttonText="XLS"
          />
        </div>
        <DownloadXLSISP
          id="DownloadTableXLSTraffikOrgProvJam"
          className="DownloadTableXLS"
          data={rows}
        />
      </Row>
      {loading ? (
        <div>
          <h1>
            <Skeleton />
          </h1>
          <Skeleton count={10} />
        </div>
      ) : rows === null ? (
        <Alert>Terjadi Kesalahan</Alert>
      ) : (
        <MDBDataTable
          className="text-center"
          responsive
          striped
          bordered
          searching={false}
          displayEntries={false}
          info={false}
          entries={10} //How much data that you want to show in 1 table
          disableRetreatAfterSorting //Show red Warning after use
          data={data}
          noBottomColumns={true}
        />
      )}
      {/* </CardBody>
      </Card> */}
    </React.Fragment>
  );
};

export default TableOrgProvJam;
