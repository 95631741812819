import React, { useMemo, useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

const StatistiKontenCP = (props) => {
  let { impressData, dates } = props;
  const [loading, setLoading] = useState(false);
  const [sortedDataByDate, setSortedDataByDate] = useState({
    impress: {},
    clicked: {},
    dailyDfp: {},
  });
  const SkeletonLoading = () => {
    return <Skeleton count={10} />;
  };
  let dateCat = impressData
    ? impressData[0]
      ? impressData[0].impress
        ? Object.keys(impressData[0].impress)
        : [0]
      : [0]
    : [0];
  function sortAssocObject(list) {
    var sortable = [];
    for (var key in list) {
      sortable.push([key, list[key]]);
    }

    sortable.sort(function (a, b) {
      return (
        moment(a[1], "DD-MM-YYYY").format("YYYYMMDD") -
        moment(b[1], "DD-MM-YYYY").format("YYYYMMDD")
      );
    });

    var orderedList = {};
    for (var idx in sortable) {
      orderedList[sortable[idx][0]] = sortable[idx][1];
    }
    return orderedList;
  }
  useEffect(() => {
    let impress =
      impressData &&
      impressData[0] &&
      impressData[0].impress &&
      sortAssocObject(impressData[0].impress);
    let clicked =
      impressData &&
      impressData[0] &&
      impressData[0].clicked &&
      sortAssocObject(impressData[0].clicked);
    let daily_unique_dfp =
      impressData &&
      impressData[0] &&
      impressData[0].daily_unique_dfp &&
      sortAssocObject(impressData[0].daily_unique_dfp);

    setSortedDataByDate((prevState) => ({
      ...prevState,
      impress: impress,
      clicked: clicked,
      dailyDfp: daily_unique_dfp,
    }));
  }, [impressData]);

  let impresi = sortedDataByDate
    ? sortedDataByDate.impress
      ? Object.values(sortedDataByDate.impress)
      : [0]
    : [0];
  let clicked = sortedDataByDate
    ? sortedDataByDate.clicked
      ? Object.values(sortedDataByDate.clicked)
      : [0]
    : [0];

  let dailyDfp = sortedDataByDate
    ? sortedDataByDate.daily_unique_dfp
      ? Object.values(sortedDataByDate.daily_unique_dfp)
      : [0]
    : [0];
  const options = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
    },
    xAxis: {
      type: "category",
      data: dates,
      axisLable: {
        color: "#ffffff",
      },
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLable: {
        color: "#ffffff",
      },
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    },
    series: [
      {
        data: impresi,
        type: "line",
        name: "Impresi",
      },
      {
        name: "Klik",
        data: clicked,
        type: "line",
      },
      {
        name: "Pengguna Unik",
        data: dailyDfp,
        type: "line",
      },
    ],
    colors: ["#f46a6a", "#34c38f", "#556ee6"],
    textStyle: {
      color: ["#74788d"],
    },
  };

  return (
    <Row className="mt-5 mb-5 d-flex justify-content-center">
      <Col lg={8}>
        <Card>
          <CardBody>
            {loading ? (
              <SkeletonLoading />
            ) : (
              <>
                <ReactEcharts style={{ height: "380px" }} option={options} />
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default StatistiKontenCP;
