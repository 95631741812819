import React from "react";

import { Modal, ModalBody, Button, Row, Spinner } from "reactstrap";
import styles from "./style.module.css";
import { MDBModalHeader } from "mdbreact";

function ModalAktifkanSurveyDefault(props) {
	return (
		<>
			<Modal
				size="lg"
				isOpen={props.modal}
				toggle={props.toggle}
				centered={true}
			>
				<MDBModalHeader toggle={props.toggle}>
					<span className={styles.headText}> Aktifkan Survey </span>
				</MDBModalHeader>
				<ModalBody>
					<center className="container-popup">
						{/* <Row className={`justify-content-center ${styles.wrapper}`}>
                            <h3 className={styles.title}>Verifikasi Pengguna</h3>
                        </Row> */}

						<Row className={`justify-content-center ${styles.wrapper}`}>
							<h4 className={styles.title}>{typeof props.data !== 'undefined' ? props.data.name : "-"}</h4>
							{/* <h5 className={styles.sub_title}>{data.type}</h5> */}
						</Row>

						<div className={styles.text}>Yakin mengaktifkan survey default ini?</div>
						{/* <Button color="" className={styles.btn_cancel} onClick={toggleVerify}>Tidak</Button> */}
						{
							props.isLoading ?
							<Spinner color="success"/> :
							<Button
								color=""
								className={styles.btn_confirm}
								onClick={props.onClickButton}
							>
								Aktifkan
							</Button>
						}
					</center>
				</ModalBody>
			</Modal>
		</>
	);
}

export default ModalAktifkanSurveyDefault;
