import React, { useState, useEffect } from "react";
import { MDBModal, MDBCardBody, MDBCardTitle, MDBCard } from "mdbreact";
import Swal from "sweetalert2";

import API from "../../../../../../services";

// import "./style.css";
import moment from "moment";
import style from "./style.module.css";
import { Button, Input } from "reactstrap";
// ------------------API-----------------------

const ModalSaveFilter = (props) => {
  const [title, setTitle] = useState("");
  const idUser = window.localStorage.getItem("idUser");

  const handleSave = () => {
    if (title === "") {
      alert("Judul Tidak Boleh Kosong");
    } else {
      //   setData(
      //     `judul: ${title} start: ${props.data.start_date} end: ${props.data.end_date} id prov: ${props.data.province} userId: ${idUser}`
      //   );
      const param = Object.assign(props.data, {
        title: title,
        user: idUser,
      });
      props.toggle();
      Swal.fire({
        position: "center",
        icon: "warning",
        title: `Mohon Tunggu`,
        showConfirmButton: false,
      });
      API.postSaveLogHistory(param)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `Data berhasil disimpan`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Data tidak berhasil disimpan`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `Data tidak berhasil disimpan`,
            showConfirmButton: false,
            timer: 1500,
          });
          // props.toggle();
        });
    }
  };
  return (
    <MDBModal
      id="ModalSaveFilter"
      isOpen={props.modal}
      toggle={props.toggle}
      size="lg"
      centered={true}>
      <MDBCard className="p-2">
        <MDBCardTitle>
          <div className={`${style.titleMenuBI} my-2`}>DATA TITLE</div>
        </MDBCardTitle>
        <MDBCardBody>
          <div className="text-center my-5">Tulis Judul Data yang akan disimpan</div>
          <div style={{ display: "flex", justifyContent: "center" }} className="mt-4">
            <Input
              className={style.inputSaveLog}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Tulis Disini ..."></Input>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }} className="mt-4">
            <Button className={`${style.buttonSaveLog}`} onClick={handleSave}>
              Simpan
            </Button>
          </div>
        </MDBCardBody>
      </MDBCard>
    </MDBModal>
  );
};

export default ModalSaveFilter;
