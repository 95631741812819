import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  let judul;
  if (props.activeTab === "1") {
    switch (props.activeSubTab1) {
      case "1":
        judul = "KONTEN VIDEO DISETUJUI";
        break;
      case "2":
        judul = "KONTEN BANNER DISETUJUI";
        break;
      case "3":
        judul = "KONTEN BERITA DISETUJUI";
        break;
      default:
        break;
    }
  } else if (props.activeTab === "2") {
    switch (props.activeSubTab2) {
      case "1":
        judul = "KONTEN VIDEO TIDAK DISETUJUI";
        break;
      case "2":
        judul = "KONTEN BANNER TIDAK DISETUJUI";
        break;
      case "3":
        judul = "KONTEN BERITA TIDAK DISETUJUI";
        break;
      default:
        break;
    }
  } else {
    switch (props.activeSubTab3) {
      case "1":
        judul = "KONTEN VIDEO TIDAK LOLOS KURASI";
        break;
      case "2":
        judul = "KONTEN BANNER TIDAK LOLOS KURASI";
        break;
      case "3":
        judul = "KONTEN BERITA TIDAK LOLOS KURASI";
        break;
      default:
        break;
    }
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`DATA ${judul} ${role}`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Judul</th>
          <th>Organisasi</th>
          <th>Provinsi</th>
          <th>Kategori</th>
          <th>Tanggal Pengajuan</th>
          <th>Tanggal Tayang</th>
          {props.activeTab !== "1" && (
            <th>Kategori {props.activeTab === "2" ? "Tidak Disetujui" : "Tidak Lolos Kurasi"}</th>
          )}
          <th>Hasil Kurasi</th>
          <th style={{ width: "300px" }}>Preview</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index} style={{ height: "200px", textAlign: "center", verticalAlign: "middle" }}>
            <td>{index + 1}</td>
            <td>{data.title ? data.title ?? "-" : data.details.title ?? "-"}</td>
            <td>{data.requested_by.organization.name ?? "-"}</td>
            <td>{data.list_provinsi.map((prov) => prov.name).toString() ?? "-"}</td>
            <td>{data.category ?? "-"}</td>
            <td>{moment(data.tanggal_pembuatan).format("DD-MM-YYYY")}</td>
            <td>
              {data.proposed_date.length > 0
                ? `${moment(data.proposed_date[0], "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}\n s/d \n${moment(data.proposed_date.slice(-1), "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )} `
                : "-"}
            </td>
            {props.activeTab !== "1" && <td>{data.kategoriTolak ? data.kategoriTolak : "-"}</td>}
            <td>
              {data.emergency !== undefined && data.emergency !== "" ? (
                <label key={data._id} className="mx-auto py-1" style={{ color: "#C42127" }}>
                  Emergency
                </label>
              ) : data.status_kurator[data.status_kurator.length - 1].status ==
                "Tidak Lolos Kurasi" ? (
                <label key={data._id} className="mx-auto py-1" style={{ color: "#C42127" }}>
                  Tidak Lolos Kurasi
                </label>
              ) : data.status_kurator[data.status_kurator.length - 1].status == "Lolos Kurasi" ? (
                <label key={data._id} className="mx-auto py-1" style={{ color: "#55BA59" }}>
                  Lolos Kurasi
                </label>
              ) : (
                <>{data.status_kurator[data.status_kurator.length - 1].status}</>
              )}
            </td>
            {/* REACT_APP_SELF_URL */}
            {/* REACT_APP_BE_URL */}
            <td>
              <img
                src={
                  typeof data.details === "undefined"
                    ? "-"
                    : props.activeTab === "1" && props.activeSubTab1 === "1"
                    ? `${process.env.REACT_APP_SELF_URL}/${data.details.thumbnail}`
                    : props.activeTab === "2" && props.activeSubTab2 === "1"
                    ? `${process.env.REACT_APP_SELF_URL}/${data.details.thumbnail}`
                    : props.activeTab === "3" && props.activeSubTab3 === "1"
                    ? `${process.env.REACT_APP_SELF_URL}/${data.details.thumbnail}`
                    : `${process.env.REACT_APP_SELF_URL}/${data.details.url}`
                }
                height="100"
                style={{
                  objectFit: "cover",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}></img>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
