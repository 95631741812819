import React, { useEffect, useState, createRef } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import "./style.css";
import { Container, Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import { listProvinsi } from "./const";
// ------------------API---------------------
import API from "../../../../../services";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const ModalTambahProvinsi = (props) => {
  const history = useHistory();
  const [select, setSelect] = useState([props.listSelected.join(",")]);
  const [availableProvince, setAvailableProvince] = useState([]);

  const handleChangeRadio = (e, id) => {
    if (e.target.checked) {
      setSelect([...select, e.target.value]);
      props.selectedProvince([...select, e.target.value]);
    } else {
      let filter;
      filter = select.filter((it) => it !== e.target.value);
      setSelect(filter);
      props.selectedProvince(filter);
    }
  };

  const getProvince = () => {
    // console.log("props.province", props.province)
    let isEmergency = props.emergency ? "1" : "";
    let param = {
      start: props.startDate,
      end: props.endDate,
      content: props.activeTab,
      province: props.province,
      time: props.time,
      emergency: isEmergency,
    };
    API.postProvinceAvailable(param)
      .then((res) => {
        if (res.data.success) {
          setAvailableProvince(res.data.data);
        }
      })
      .catch((e) => {
        // console.log("prov", e)
        history.push("/pages-500");
      });
  };

  useEffect(() => {
    if (props.startDate && props.endDate && props.province && props.time) {
      getProvince();
    }
  }, [props.durasi, props.startDate, props.endDate, props.province, props.emergency, props.time]);

  return (
    <MDBContainer>
      <MDBModal
        size="md"
        isOpen={props.modalTambahProvinsi}
        toggle={props.toggleModalTambahProvinsi}
        centered={true}>
        <MDBModalHeader centered toggle={props.toggleModalTambahProvinsi}>
          Tambah Provinsi
        </MDBModalHeader>
        <MDBModalBody>
          <div className="mx-auto w-100 container text-center">
            <p className="textBlue">{props.selectedDate}</p>
            <p className="textBlue">{`${props.durasi} jam`}</p>
          </div>
          <Container>
            <Row
              className="col-lg-10 mx-auto col-offset-2"
              style={{
                backgroundColor: "#f7f7f7",
                height: "200px",
                width: "100%",
                overflowX: "auto",
              }}>
              <Col
                md="5"
                xs="5"
                lg="5"
                style={{ height: "200px", padding: "5px", overflowY: "hidden", overflowX: "auto" }}>
                <div
                  id="group1"
                  className="custom-control custom-checkbox mb-2"
                  style={{ overflowY: "scroll", height: "200px" }}>
                  {listProvinsi.map((list, i) => (
                    <div>
                      <input
                        type="checkbox"
                        checked={select.includes(list.name)}
                        disabled={!_.map(availableProvince, "name").includes(list.name)}
                        className="custom-control-input input-mini"
                        name={`${list.name}${i}`}
                        value={`${list.name}`}
                        onChange={(e) => handleChangeRadio(e, list._id)}
                        id={i}></input>
                      <Label
                        className={
                          !_.map(availableProvince, "name").includes(list.name)
                            ? "custom-control-label textCheckboxDisabled"
                            : "custom-control-label textCheckbox"
                        }
                        for={i}>{`${list.name}`}</Label>
                    </div>
                  ))}
                </div>
              </Col>
              <Col lg="2" md="2" xs="2">
                <div className="v3"></div>
              </Col>
              <Col
                lg="5"
                md="5"
                xs="5"
                style={{ marginLeft: "0 !important", height: "200px", overflowY: "hidden" }}>
                <div style={{ height: "190px", overflow: "scroll" }}>
                  {select.map((e) => (
                    <Label className="textCheckbox d-block">{e}</Label>
                  ))}
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Button
                color=""
                onClick={props.toggleModalTambahProvinsi}
                className="btn btn-rounded btnCekJam col-3 col-offset-2 mt-2 mx-auto">
                OK
              </Button>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
const mapStatetoProps = (state) => {
  const { dataAjukan } = state.dataReducer;
  return { dataAjukan };
};
export default connect(mapStatetoProps, null)(ModalTambahProvinsi);
