import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import "./style.css";
// import { addDays, addMonths, differenceInDays, differenceInMonths, getDate } from "date-fns";
import { Container, Button, Row, Alert, Col } from "reactstrap";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
// ------------------REDUX---------------------
// import { connect } from "react-redux";
import moment from "moment";

const ModalCalendar = (props) => {
  // const selectionRange = {
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: "selection",
  // };

  const [state, setState] = useState(moment(props.defaultDate, "DD/MM/YYYY").toDate());

  // const handleSelect = (ranges) => {
  //   // console.log(ranges);
  // };

  const handleChange = (date) => {
    //check date first value
    // let firstState = [
    //   {
    //     startDate: new Date(),
    //     endDate: new Date(),
    //     key: "selection",
    //   },
    // ];
    // if (state == firstState) {
    //   setState([
    //     {
    //       startDate: date[0].startDate,
    //       endDate: date[0].endDate,
    //       key: "selection",
    //     },
    //   ]);
    // } else {

    setState(date);
    // console.log(date);
    // }
  };

  const handleSubmit = () => {
    // if (moment(state).format("YYYY") == 2020 && props.dataType == "device") {
    //   alert(`Tidak ada data ${props.titleData} di tahun ${moment(state).format("YYYY")}`);
    // } else if (moment(state).format("YYYY") != 2020 && props.dataType == "dfp") {
    //   alert(`Tidak ada data ${props.titleData} di tahun ${moment(state).format("YYYY")}`);
    // } else {
    props.setStartDate(moment(state).format("DD/MM/YYYY"));
    props.setEndDate(moment(state).format("DD/MM/YYYY"));
    // }
    // props.setYear(moment(state).format("YYYY"));
    props.toggleModalCalendar();
  };

  return (
    <MDBContainer>
      <MDBModal
        className="DatePickerCustom"
        size="lg"
        isOpen={props.modalCalendar}
        toggle={props.toggleModalCalendar}
        centered={true}>
        <MDBModalHeader className="text-center displayContent">
          <Container>
            <Row className="col-lg-12">
              <Col className="col-lg-4 mx-auto">Pilih Tanggal</Col>
            </Row>
          </Container>
        </MDBModalHeader>
        <MDBModalBody>
          <Container className="containerCalendar">
            <Row className="rowCalendar">
              {/* <DateRangePicker
                className="mx-auto"
                onChange={(item) => handleChange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                direction="horizontal"
                minDate={new Date()}
                months={2}
                ranges={state}
                direction="horizontal"
              /> */}
              <Calendar
                className="mx-auto"
                // ranges={state}
                // onChange={(item) => handleChange([item.selection])}
                onChange={handleChange}
                maxDate={moment(new Date()).subtract(1, "days").toDate()}
                minDate={moment("01/01/2020").toDate()}
                date={state}
              />
            </Row>
            <Row>
              <Button
                color=""
                onClick={handleSubmit}
                className="btn btn-rounded btnCekJam mx-auto px-5">
                OK
              </Button>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalCalendar;
