import Loadable from "react-loadable";
import Loading from "../../../../../components/Loading";

const SurveyAsync = Loadable({
  loader: () =>
    import(
      "../../../../../pages/BusinessIntelligence/Admin/Measures/Survey" /* webpackChunkName: "SurveyAsync" */
    ),
  loading: Loading,
});

export default SurveyAsync;
