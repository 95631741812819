import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  let title;
  switch (props.activeTab) {
    case "1":
      title = `Persetujuan Pengguna`;
      break;
    case "2":
      title = props.activeSubTabKelola === "1" ? `Kelola Pengguna Content Partner` : props.activeSubTabKelola === "2" ? "Kelola Pengguna Content Admin" : props.activeSubTabKelola === "3" ? "Kelola Pengguna Kurator" : "Kelola Pengguna Support";
      break;
    default:
      break;
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
      <thead>{`DATA ${title} `}</thead>
      <thead>
        {
          props.activeTab === "1" ?
          <tr>
            <th>No</th>
            <th>Username</th>
            <th>Nama</th>
            <th>Email</th>
            <th>Organisasi</th>
            <th>Peran</th>
            <th>Waktu Registrasi</th>
            <th>No Telefon</th>
            <th style={{width:'300px'}}>KTP</th>
          </tr> : 
          <tr>
            <th>No</th>
            <th>Username</th>
            <th>Nama</th>
            <th>Email</th>
            <th>Organisasi</th>
            <th>Waktu Registrasi</th>
            <th>No Telefon</th>
            <th>Status</th>
          </tr>
        }
      </thead>
      <tbody>
        {
          props.activeTab === "1" ? 
            props.data.map((data, index) => {
              return (
                <tr key={index} style={{height:'200px', textAlign:'center', verticalAlign:'middle'}}>
                  <td>{index + 1}</td>
                  <td>{data.username ?? "-"}</td>
                  <td>{data.name ?? "-"}</td>
                  <td>{data.email ?? "-"}</td>
                  <td>{data.organization ? data.organization.name : "-"}</td>
                  <td>{data.organization ? data.organization.role.description : "-"}</td>
                  <td>
                    {moment(data.tanggal_pembuatan).format("DD-MM-YYYY")}
                  </td>
                  <td>{`'${data.phone}`}</td>
                  <td>
                    <img src=
                    {
                      `${process.env.REACT_APP_SELF_URL}/${
                        data.doc_penunjang.fileKtp[0].path
                      }`
                    } 
                    height="100" style={{objectFit:'cover', display:'block', marginLeft:'auto', marginRight:'auto'}}></img>
                  </td>
                </tr>
              );
            }) :
            props.data2.map((data, index) => {
              let status = data.status.toLowerCase().replace(/ /g, "")
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.username ?? "-"}</td>
                  <td>{data.name ?? "-"}</td>
                  <td>{data.email ?? "-"}</td>
                  <td>{data.organization ? data.organization.name : "-"}</td>
                  <td>
                    {moment(data.tanggal_pembuatan).format("DD-MM-YYYY")}
                  </td>
                  <td>{`'${data.phone}`}</td>
                  <td>{status === "nonaktif" ? "Non Aktif" : status === "dormant" ? "Dormant" : status === "declined" ? "Ditolak" : status === "confirmation" ? "Belum Dikonfirmasi" : "Aktif"}</td>
                </tr>
              );
            })
        }
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
