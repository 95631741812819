import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Skeleton from "react-loading-skeleton";
import { Row } from "reactstrap";

// STYLE
import "./style.scss";
import "./style.css";
import style from "../../style.module.css";

// import API from "../../../../services";

const TableDFPBrand = (props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (props.data.count_province) {
      setRows(props.data.count_province);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.count_province]);

  // const data = {
  //   columns: [
  //     {
  //       label: "Provinsi",
  //       field: "provinsi",
  //       sort: "asc",
  //       // width: 200,
  //     },
  //     {
  //       label: "Jumlah Pengunjung Mengisi Survey",
  //       field: "jumlah",
  //       sort: "asc",
  //       // width: 200,
  //     },
  //   ],
  //   rows: [
  //     {
  //       provinsi: "Jakarta",
  //       jumlah: "30",
  //     },
  //     {
  //       provinsi: "Jawa Barat",
  //       jumlah: "20",
  //     },
  //     {
  //       provinsi: "Kalimantan Barat",
  //       jumlah: "55",
  //     },
  //   ],
  // };
  const data = {
    columns: [
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 200,
      },
      {
        label: "Jumlah Pengunjung Mengisi Survey",
        field: "jumlah",
        sort: "asc",
        width: 200,
      },
    ],
    rows:
      rows.length === 0
        ? [
            {
              provinsi: "Tidak ada data",
              jumlah: "0",
            },
          ]
        : rows.map((res) => ({ provinsi: res.province, jumlah: res.count })),
  };

  return (
    <React.Fragment>
      <div style={{ minHeight: "400px", overflowY: "auto", overflowX: "hidden", }}>
        {loading ? (
          <div>
            <h1>
              <Skeleton />
            </h1>
            <Skeleton count={10} />
          </div>
        ) : rows == null ? (
          <div> TERJADI KESALAHAN</div>
        ) : (
          <>
            <Row style={{ justifyContent: "center", display: "flex" }}>
              <div className={`${style.titleCardBI} mb-3`}>
                PERSEBARAN JUMLAH PENGISI SURVEI PER PROVINSI
              </div>
            </Row>

            <MDBDataTable
              className="text-center"
              responsive
              striped
              bordered
              searching={false}
              displayEntries={false}
              // info={false}
              entries={10} //How much data that you want to show in 1 table
              data={data}
              noBottomColumns={true}
              paginationLabel={["<", ">"]}
              infoLabel={["Menampilkan", "-", "dari", "data"]}
              pagesAmount={3}
              // paging={false}
              // paginationLabel={}
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default TableDFPBrand;
