import React, { useEffect, useState, useRef } from "react";
import { Row, CardBody, Button, Col, Card, Modal, ModalBody } from "reactstrap";
import moment from "moment";
import { MDBModalHeader, MDBNavLink } from "mdbreact";

// COMPONENTS
import CardSummary from "./CardSummaryTrafikKonten";
import CardTrafikProvinsi from "./CardTrafikProvinsi";
import CardPeakHour from "./CardPeakHour";
import CardDemografi from "./CardDemografi/CardDemografi";
import CardChartJenjangPendidikan from "./CardDemografi/CardChartJenjangPendidikan";
import CardChartStatusPerkawinan from "./CardDemografi/CardChartStatusPerkawinan";
import CardPersebaranUmur from "./CardDemografi/CardPersebaranUmur";
import ModalSave from "../../components/Admin/Popup/BI/LogHistory/SaveFilter";

// STYLE CSS
import style from "./style.module.css";
import carbon_save from "../../assets/icon/carbon_save.png";

//API
import API from "../../services";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLSSummary from "./Download/XLS/DownloadCardSummary";
import DownloadXLSTrafikDemografi from "./Download/XLS/DownloadCardTrafikDemografi";

export default function SummaryTrafikKonten() {
  const [dataSummary, setDataSummary] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [dataDemografi, setDataDemografi] = useState([]);
  const [loadingDemografi, setLoadingDemografi] = useState(true);

  // MODAL DOWNLOAD
  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);
  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  // MODAL SAVE
  const [modalSave, setModalSave] = useState(false);
  const toggleModalSave = () => {
    setModalSave(!modalSave);
  };
  const [dataSave, setDataSave] = useState({});

  const getSummaryData = () => {
    API.getSummaryKonten()
      .then((res) => {
        if (res.data.success) {
          setDataSummary(res.data.data);
        } else {
          setDataSummary(null);
        }
        setLoadingSummary(false);
      })
      .catch((e) => {
        setDataSummary(null);
        setLoadingSummary(false);
      });

    API.getSummaryDemografi()
      .then((res) => {
        if (res.data.success) {
          setDataDemografi(res.data.data);
        } else {
          setDataDemografi(null);
        }
        setLoadingDemografi(false);
      })
      .catch((e) => {
        setDataDemografi(null);
        setLoadingDemografi(false);
      });
  };

  useEffect(() => {
    getSummaryData();
  }, []);

  return (
    <div className="container-fluid" style={{ marginBottom: "70px" }}>
      <ModalSave
        modal={modalSave}
        toggle={toggleModalSave}
        data={Object.assign(dataSave, {
          measure: "summary_trafik",
        })}
      />
      <Row
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}>
        <CardBody
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <span className={style.titleMenuBI}>SUMMARY TRAFIK KONTEN</span>
          <div>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col>
                <Button
                  style={{
                    backgroundColor: "#2C3280",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingBottom: "3px",
                    paddingTop: "3px",
                    minWidth: "100%",
                  }}
                  onClick={() => {
                    toggleModalSave();
                  }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <img src={carbon_save} className="mr-2" />
                    <span>Save</span>
                  </div>
                </Button>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Row>
      <div className="my-5">
        <div>
          <Modal size="md" isOpen={modalDownloadXLS} toggle={toggleDownloadXLS} centered={true}>
            <MDBModalHeader toggle={toggleDownloadXLS}>
              <span className={style.headText}>Download XLS</span>
            </MDBModalHeader>
            <ModalBody>
              <center className="container-popup">
                <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                  Batal
                </Button>
                <Button color="" className={style.btn_confirm_xls} onClick={toggleDownloadXLS}>
                  <ReactHTMLTableToExcel
                    id="TableXLS"
                    className={style.DownloadTableXLS}
                    table="DownloadTableXLSSummary"
                    filename={`summary-traffik ${moment(new Date()).format("DD-MM-YYYY")}`}
                    sheet="users"
                    buttonText="Download"
                  />
                </Button>
              </center>
            </ModalBody>
          </Modal>
          {loadingSummary ? (
            ""
          ) : (
            <Col
              className="my-3"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}>
              <MDBNavLink to="#" className={style.textDownload}>
                Download
              </MDBNavLink>
              <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                XLS
              </MDBNavLink>
            </Col>
          )}
          <DownloadXLSSummary
            id="DownloadTableXLSSummary"
            className="DownloadTableXLS"
            data={dataSummary}
          />
        </div>
        <CardSummary data={dataSummary} loading={loadingSummary} />
      </div>
      <div className="my-3">
        <CardTrafikProvinsi setDataSave={setDataSave} dataSave={dataSave} />
      </div>
      <div className="my-3">
        <CardPeakHour setDataSave={setDataSave} dataSave={dataSave} />
      </div>

      {/* DEMOGRAFI */}
      <div className="container-fluid" style={{ marginBottom: "100px", marginTop: "150px" }}>
        <Card style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
          <CardBody>
            <div className={`${style.titleMenuBI} my-4`}>DEMOGRAFI BEHAVIOR</div>
            <div>
              <Modal size="md" isOpen={modalDownloadXLS} toggle={toggleDownloadXLS} centered={true}>
                <MDBModalHeader toggle={toggleDownloadXLS}>
                  <span className={style.headText}>Download XLS</span>
                </MDBModalHeader>
                <ModalBody>
                  <center className="container-popup">
                    <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                    <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                      Batal
                    </Button>
                    <Button color="" className={style.btn_confirm_xls} onClick={toggleDownloadXLS}>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className={style.DownloadTableXLS}
                        table="DownloadTableXLSTrafikDemografi"
                        filename={`summary-traffik-demografi ${moment(new Date()).format(
                          "DD-MM-YYYY"
                        )}`}
                        sheet="users"
                        buttonText="Download"
                      />
                    </Button>
                  </center>
                </ModalBody>
              </Modal>
              {loadingDemografi ? (
                ""
              ) : (
                <Col
                  className="my-1"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}>
                  <MDBNavLink to="#" className={style.textDownload}>
                    Download
                  </MDBNavLink>
                  <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                    XLS
                  </MDBNavLink>
                </Col>
              )}
              <DownloadXLSTrafikDemografi
                id="DownloadTableXLSTrafikDemografi"
                className="DownloadTableXLS"
                data={dataDemografi}
                // content={content}
                // org={org}
                // prov={prov}
              />
            </div>
            <div className="mx-3">
              <div className="my-3">
                <CardDemografi loading={loadingDemografi} data={dataDemografi} />
              </div>
              <div>
                <CardChartJenjangPendidikan
                  loading={loadingDemografi}
                  data={dataDemografi}
                  setDataSave={setDataSave}
                  dataSave={dataSave}
                />
              </div>
              <div style={{ marginTop: "200px" }}>
                <CardChartStatusPerkawinan
                  loading={loadingDemografi}
                  data={dataDemografi}
                  setDataSave={setDataSave}
                  dataSave={dataSave}
                />
              </div>
              <div style={{ marginTop: "200px", marginBottom: "200px" }}>
                <CardPersebaranUmur
                  loading={loadingDemografi}
                  data={dataDemografi}
                  setDataSave={setDataSave}
                  dataSave={dataSave}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
