import React, { useEffect } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import "./style.css";
import { Container, Row } from "reactstrap";
import IconEmergency from "../../../../../assets/icon/IconEmergency.svg";
import moment from "moment";
// ------------------REDUX---------------------
import { connect } from "react-redux";

const ModalDetailTayang = (props) => {
  let it = props.data.data ? props.data.data : false;
  return (
    <MDBContainer>
      <MDBModal
        size="lg"
        isOpen={props.modalDetailTayang}
        toggle={props.toggleModalDetailTayang}
        centered={true}>
        <MDBModalHeader centered toggle={props.toggleModalDetailTayang}>
          Detail Penayangan
        </MDBModalHeader>
        <MDBModalBody>
          <Row>
            <Container>
              <MDBTable striped>
                <MDBTableHead>
                  <tr className="text-center">
                    <th>Tanggal Tayang</th>
                    <th>Jam Tayang</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr className="text-center">
                    <td>
                      {it
                        ? it.proposed_date.length > 0
                          ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format(
                              "DD-MM-YYYY"
                            )} - ${moment(it.proposed_date.slice(-1), "DD-MM-YYYY").format(
                              "DD-MM-YYYY"
                            )} `
                          : "-"
                        : "-"}
                    </td>
                    <td>
                      {it
                        ? it.proposed_time.length > 0
                          ? `${it.proposed_time[0][0]} - ${
                              it.proposed_time[0][it.proposed_time[0].length - 1]
                            } `
                          : "-"
                        : "-"}
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </Container>
          </Row>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};
export default connect(mapStatetoProps, null)(ModalDetailTayang);
