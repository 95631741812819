import React, { useEffect, useState } from "react";
import { Row, CardBody, Button, Col, ModalBody, Modal } from "reactstrap";
import moment from "moment";
import { MDBModalHeader, MDBNavLink } from "mdbreact";

// COMPONENT
import CardMap from "./Map/CardMap";
import TableKabKot from "./Tabel/TableKabKot";
import TableISP from "./Tabel/TableISP";
import TableLokasi from "./Tabel/TableLokasi";
import TableLokasiKabupaten from "./Tabel/TableLokasiKabupaten";
import CardISP from "./CardISP";

// MODAL
import ModalCalendar from "./Calendar/index";
import ModalSave from "../../components/Admin/Popup/BI/LogHistory/SaveFilter";

// STYLE CSS
import style from "./style.module.css";
import cal from "../../assets/icon/calendar.png";
import carbon_save from "../../assets/icon/carbon_save.png";

// API
import API from "../../services";

// REDUX
import { useSelector } from "react-redux";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "./Download/XLS/DownloadCardISP";

export default function Index() {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  // MODAL DOWNLOAD
  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);
  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  // console.log("dataTable", dataTable);

  // DEFAULT ID PROV DKI JAKARTA
  const [idProvince, setIdProvince] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.province
      : "5c073c0b2f1e2050e0c6bf64"
  );
  const [provinceXLS, setProvinceXLS] = useState("DKI JAKARTA");

  const [loading, setLoading] = useState(true);

  // LOADING PROVINCE DATA TABLE
  const [loadingTable, setLoadingTable] = useState(true);

  //calendar modal
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };
  // TANGGAL DEFAULT
  var currentDate = new Date();
  const [endDate, setEndDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.end_date
      : moment(currentDate).subtract(1, "days").format("DD/MM/YYYY")
  );

  const [startDate, setStartDate] = useState("01/01/2020");
  const [resetBtn, setResetBtn] = useState(false);

  const resetFilter = () => {
    setResetBtn(true);
    setEndDate("01/01/2000");
    setIdProvince("5c073c0b2f1e2050e0c6bf64");
  };

  const getDataISP = () => {
    setLoading(true);
    API.getBIISP()
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setData(null);
        setLoading(false);
      });
  };
  // useEffect(() => {

  // }, [prov, props.idProvince, props.endDate]);
  const getDataISPTable = () => {
    const param = {
      start: "01/01/2020",
      end: endDate,
      province: idProvince,
    };
    setLoadingTable(true);
    API.getBIISPData(param)
      .then((res) => {
        if (res.data.success) {
          setDataTable(res.data.data);
          setTimeout(() => {
            if (res.data.data) {
              // if (Object.entries(res.data.data).length !== 0) {
              if (res.data.data.total_ai === 0 && res.data.data.total_isp === 0) {
                alert(`Tidak ada data pada periode cutt off ${endDate} di provinsi tersebut`);
                // }
              }
            }
          }, 500);
          setLoadingTable(false);
        }
      })
      .catch((e) => {
        setDataTable(null);
        setTimeout(() => {
          // if (Object.entries(res.data.data).length !== 0) {
          alert(`Terjadi Kesalahan data pada periode cutt off ${endDate} di provinsi tersebut`);
          // }
        }, 500);
        setLoadingTable(false);
      });
  };

  const [modalSave, setModalSave] = useState(false);
  const toggleModalSave = () => {
    setModalSave(!modalSave);
  };

  // CHECK REDUX DATA
  useEffect(() => {
    getDataISP();
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, []);

  useEffect(() => {
    // CHECK DATA IN REDUX
    getDataISPTable();
  }, [idProvince, startDate, endDate]);

  return (
    <div className="container-fluid" style={{ marginBottom: "100px" }}>
      <ModalCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        setStartDate={setStartDate}
        defaultDate={endDate}
        setEndDate={setEndDate}
        setResetBtn={setResetBtn}
      />
      <ModalSave
        modal={modalSave}
        toggle={toggleModalSave}
        data={{ start_date: "01/01/2020", end_date: endDate, province: idProvince, measure: "isp" }}
      />
      <Row
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}>
        <CardBody
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <span className={style.titleMenuBI}>INTERNET SERVICE PROVIDER</span>
          <div className="float-right">
            <Button
              style={{
                backgroundColor: "#2C3280",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingBottom: "3px",
                paddingTop: "3px",
              }}
              onClick={toggleModalSave}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <img src={carbon_save} className="mr-2" />
                <span>Save</span>
              </div>
            </Button>
          </div>
        </CardBody>
        <div>
          <Modal size="md" isOpen={modalDownloadXLS} toggle={toggleDownloadXLS} centered={true}>
            <MDBModalHeader toggle={toggleDownloadXLS}>
              <span className={style.headText}>Download XLS</span>
            </MDBModalHeader>
            <ModalBody>
              <center className="container-popup">
                <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                  Batal
                </Button>
                <Button color="" className={style.btn_confirm_xls} onClick={toggleDownloadXLS}>
                  <ReactHTMLTableToExcel
                    id="TableXLS"
                    className={style.DownloadTableXLS}
                    table="DownloadCardISP"
                    filename={`Business-Intelligence-ISP-${moment(new Date()).format("DDMMYYYY")}`}
                    sheet="users"
                    buttonText="Download"
                  />
                </Button>
              </center>
            </ModalBody>
          </Modal>
          {loading ? (
            ""
          ) : (
            <Col
              className="mt-1"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}>
              <MDBNavLink to="#" className={style.textDownload}>
                Download
              </MDBNavLink>
              <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                XLS
              </MDBNavLink>
            </Col>
          )}
          <DownloadXLS id="DownloadCardISP" className="DownloadTableXLS" data={data} />
        </div>
      </Row>
      <CardISP loading={loading} data={data} />
      <Row className="mb-3 justify-content-end" style={{ marginTop: "70px" }}>
        <Col
          sm="12"
          md="5"
          lg="3"
          className="mx-2"
          style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
          <span style={{ color: "#2C3280", fontWeight: "bold", fontSize: "15px" }}>
            Pilih Periode Cut Off
          </span>
        </Col>
        <Col sm="12" md="5" lg="3" className="mx-2">
          <Row>
            <Button
              onClick={toggleModalCalendar}
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                minWidth: "100%",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span className="mr-1">{resetBtn ? "Pilih Tanggal" : endDate}</span>
                <img src={cal} />
              </div>
            </Button>
          </Row>
        </Col>
        <Col lg="1" className="mx-2">
          <Row>
            <Button
              disabled={resetBtn && provinceXLS === ""}
              onClick={resetFilter}
              style={{
                backgroundColor: "#C42127",
                borderRadius: "20px",
                borderColor: "#C42127",
              }}>
              Reset
            </Button>
          </Row>
        </Col>
      </Row>
      {/* MAP CARD */}
      {/* {resetBtn ? (
        ""
      ) : ( */}
      <>
        <CardMap
          loading={loadingTable}
          data={dataTable}
          setIdProvince={setIdProvince}
          idProvince={idProvince}
          setProvinceXLS={setProvinceXLS}
          resetBtn={resetBtn}
          endDate={endDate}
        />
        {dataTable && (
          <>
            <TableKabKot
              loading={loadingTable}
              data={dataTable.kab_kota}
              date={endDate}
              province={provinceXLS}
            />
            <TableISP
              loading={loadingTable}
              data={dataTable.isp}
              date={endDate}
              province={provinceXLS}
            />
            <TableLokasi
              loading={loadingTable}
              data={dataTable.location}
              date={endDate}
              province={provinceXLS}
            />
            <TableLokasiKabupaten
              loading={loadingTable}
              data={dataTable.location_name}
              date={endDate}
              province={provinceXLS}
            />
          </>
        )}
      </>
      {/* )} */}
    </div>
  );
}
