import React from "react";
import { Row, Col, Spinner } from "reactstrap";

// STYLE
import style from "./style.module.css";

// ASSETS
import clockIcon from "../../assets/images/bi_clock.svg";
import surveiIcon from "../../assets/images/survei.svg";
import kontenIcon from "../../assets/images/konten.svg";
import starIcon from "../../assets/images/star_bi.svg";

export default function CardUserBehavior(props) {
  return (
    <div className="mb-5">
      <Col lg="12" style={{ minHeight: "100%" }}>
        <Row className="text-center h-100">
          <Col md="12" lg="3">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={clockIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>RATA-RATA DURASI DI LANDING PAGE</div>
            </Col>
            {props.loading ? (
              <div className="my-1">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueSurvey}>
                {props.data
                  ? props.data.time_behaviour
                    ? Number.isInteger(props.data.time_behaviour.session_time)
                      ? props.data.time_behaviour.session_time
                      : props.data.time_behaviour.session_time.toFixed(2)
                    : 0 ?? 0
                  : 0}
                <span style={{ fontSize: "10px" }}>Detik</span>
              </Col>
            )}
          </Col>

          <Col md="12" lg="3">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={surveiIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>RATA-RATA DURASI ISI SURVEY</div>
            </Col>
            {props.loading ? (
              <div className="my-1">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueSurvey}>
                {props.data
                  ? props.data.time_behaviour
                    ? Number.isInteger(props.data.time_behaviour.survey_time)
                      ? props.data.time_behaviour.survey_time
                      : props.data.time_behaviour.survey_time.toFixed(2)
                    : 0 ?? 0
                  : 0}
                <span style={{ fontSize: "10px" }}>Detik</span>
              </Col>
            )}
          </Col>

          <Col md="12" lg="3">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={kontenIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>RATA-RATA DURASI BERADA DI LP KONTEN</div>
            </Col>
            {props.loading ? (
              <div className="my-1">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueSurvey}>
                {props.data
                  ? props.data.time_behaviour
                    ? Number.isInteger(props.data.time_behaviour.landing_page_time)
                      ? props.data.time_behaviour.landing_page_time
                      : props.data.time_behaviour.landing_page_time.toFixed(2)
                    : 0 ?? 0
                  : 0}
                <span style={{ fontSize: "10px" }}>Detik</span>
              </Col>
            )}
          </Col>

          <Col md="12" lg="3">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={starIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>RATA-RATA DURASI MENGISI RATING</div>
            </Col>
            {props.loading ? (
              <div className="my-1">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueSurvey}>
                {props.data
                  ? props.data.time_behaviour
                    ? Number.isInteger(props.data.time_behaviour.rating_time)
                      ? props.data.time_behaviour.rating_time
                      : props.data.time_behaviour.rating_time.toFixed(2)
                    : 0 ?? 0
                  : 0}
                <span style={{ fontSize: "10px" }}>Detik</span>
              </Col>
            )}
          </Col>
        </Row>
      </Col>
    </div>
  );
}
