import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const TicketSupportCenter = Loadable({
  loader: () =>
    import("../../../pages/Ticket/SupportCenter" /* webpackChunkName: "TicketSupportCenter" */),
  loading: Loading,
});

export default TicketSupportCenter;
