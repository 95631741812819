import React, { useState } from "react";
import { MDBModal } from "mdbreact";
import "./style.css";
// ------------------API-----------------------
import API from "../../../../../services";
const ModalTidakDisetujui = ({
  modalTidakDisetujui,
  toggleModalTidakDisetujui,
  dataModalTidakDisetujui,
  handleAlert,
  fetchData,
}) => {
  const [comment, setComment] = useState("");
  let idTidakDisetujui;
  let path;
  let idKurator = window.localStorage.getItem("idUser");
  if (dataModalTidakDisetujui.activeTab == "1") {
    idTidakDisetujui = dataModalTidakDisetujui.idVid;
    path = "content/video/changeStatusKurasi";
  } else if (dataModalTidakDisetujui.activeTab == "2") {
    idTidakDisetujui = dataModalTidakDisetujui.idBanner;
    path = "content/banner/changeStatusKurasi";
  } else {
    idTidakDisetujui = dataModalTidakDisetujui.idNews;
    path = "content/news/changeStatusKurasi";
  }

  return (
    <MDBModal isOpen={modalTidakDisetujui} toggle={toggleModalTidakDisetujui} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleDecline">TIDAK DISETUJUI</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalTidakDisetujui}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">
              {dataModalTidakDisetujui
                ? dataModalTidakDisetujui.data
                  ? dataModalTidakDisetujui.data.details
                    ? dataModalTidakDisetujui.data.details.title
                    : ""
                  : ""
                : ""}
              {dataModalTidakDisetujui
                ? dataModalTidakDisetujui.data
                  ? dataModalTidakDisetujui.data
                    ? dataModalTidakDisetujui.data.title
                    : ""
                  : ""
                : ""}
            </h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleDecline">Konten ini tidak disetujui</h4>
          </div>

          <div className="form-group wrapperTextAreaDecline">
            <textarea
              value="Konten Tidak Disetujui"
              className="form-control textAreaDecline"
              disabled
              rows="5"></textarea>
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalTidakDisetujui;
