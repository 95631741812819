import React, { useMemo, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import _ from "lodash";
import StatistiKontenCP from "../../../../components/ContentPartner/Chart/DetailKonten";
import Skeleton from "react-loading-skeleton";
const CardKontenCP = (props) => {
  let { impressData, dates } = props;
  const SkeletonCircle = () => {
    return <Skeleton circle={true} height={100} width={100} />;
  };
  useEffect(() => {}, [impressData]);
  let totalImpressi = impressData ? (
    impressData[0] ? (
      impressData[0].total_impress ? (
        impressData[0].total_impress
      ) : (
        "0"
      )
    ) : (
      <SkeletonCircle />
    )
  ) : (
    <SkeletonCircle />
  );
  let totalKlik = impressData ? (
    impressData[0] ? (
      impressData[0].total_clicked ? (
        impressData[0].total_clicked
      ) : (
        "0"
      )
    ) : (
      <SkeletonCircle />
    )
  ) : (
    <SkeletonCircle />
  );
  let impressiMaks = impressData ? (
    impressData[0] ? (
      _.size(impressData[0].impress) ? (
        Math.max(...Object.values(impressData[0].impress))
      ) : (
        "0"
      )
    ) : (
      <SkeletonCircle />
    )
  ) : (
    <SkeletonCircle />
  );
  let klikMaks = impressData ? (
    impressData[0] ? (
      _.size(impressData[0].clicked) ? (
        Math.max(...Object.values(impressData[0].clicked))
      ) : (
        "0"
      )
    ) : (
      <SkeletonCircle />
    )
  ) : (
    <SkeletonCircle />
  );
  let uniqueDfp = impressData ? (
    impressData[0] ? (
      _.size(impressData[0].unique_dfp) ? (
        Math.max(...Object.values(impressData[0].unique_dfp))
      ) : (
        "0"
      )
    ) : (
      <SkeletonCircle />
    )
  ) : (
    <SkeletonCircle />
  );

  return (
    <Row>
      <Container>
        <Row className="mt-5 mb-5">
          <Col md={{ size: "2" }} className="wrapperCol1">
            <Row className="d-flex justify-content-center">
              <div className="wrapContentImpresi">
                <div className="align-items-center">
                  <div className="title-total">Total Impresi</div>
                  <div className="total-content">
                    {totalImpressi ? totalImpressi : <SkeletonCircle />}
                  </div>
                </div>
                <div className="v2"></div>
              </div>
            </Row>
          </Col>
          <Col md={{ size: "2" }}>
            <Row className="d-flex justify-content-center">
              <div className="wrapContentImpresi">
                <div>
                  <div className="title-total">Total Klik</div>
                  <div className="total-content">{totalKlik ? totalKlik : <SkeletonCircle />}</div>
                </div>
                <div className="v2"></div>
              </div>
            </Row>
          </Col>
          <Col md={{ size: "2" }}>
            <Row className="d-flex justify-content-center">
              <div className="wrapContentImpresi">
                <div>
                  <div className="title-total">Impresi Maksimum</div>
                  <div className="total-content">
                    {impressiMaks ? impressiMaks : <SkeletonCircle />}
                  </div>
                </div>
                <div className="v2"></div>
              </div>
            </Row>
          </Col>
          <Col md={{ size: "2" }}>
            <Row className="d-flex justify-content-center">
              <div className="wrapContentImpresi">
                <div>
                  <div className="title-total">Klik Maksimum</div>
                  <div className="total-content">{klikMaks ? klikMaks : <SkeletonCircle />}</div>
                </div>
                <div className="v2"></div>
              </div>
            </Row>
          </Col>
          <Col md={{ size: "2" }}>
            <Row className="d-flex justify-content-center">
              <div className="wrapContentImpresi wrapperCol5">
                <div>
                  <div className="title-total">Jumlah Pengguna Unik</div>
                  <div className="total-content">{uniqueDfp ? uniqueDfp : <SkeletonCircle />}</div>
                </div>
              </div>
            </Row>
          </Col>
          <Col md={{ size: "2", offset: 2 }}></Col>
        </Row>
        <StatistiKontenCP impressData={impressData} dates={dates} />
      </Container>
    </Row>
  );
};

export default CardKontenCP;
