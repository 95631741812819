import React, { useState } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from "../../../assets/images/back.png";

function FilterButton(props) {
  const { filter, clicked } = props;
  const [filterContain, setFilterContain] = useState(props.list);

  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle caret color="primary" className="dropdown-rekomendasi">
          <div>{filter === "" ? `Pilih ${props.type}` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus">
          {filterContain.map((fc, i) => (
            <MDBDropdownItem active={filter === fc.name} onClick={() => clicked(fc.name)} key={i}>
              {fc.name}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
}
export default FilterButton;
