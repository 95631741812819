import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// import HomeCp from '../pages/Home/ContentPartner/index';
//content patner konten
// import Profilecp from "../pages/Authentication/cp-profile";
// import KontenContentPartner from "../pages/Konten/ContentPartner";
// import BuatKontenContentPartner from "../pages/BuatKonten/ContentPartner";
// import DetailKontenContentPartner from "../pages/DetailKonten/ContentPartner";
// import LibraryContentPartner from "../pages/LibraryKonten/ContentPartner";
// import KontenRevisi from "../pages/KontenRevisi/ContentPartner";
// import AjukanKembaliContentPartner from "../pages/AjukanKembali/ContentPartner";
// import UbahJadwalKontenContentPartner from "../pages/UbahJadwal/ContentPartner";
// import DetailKontenPreviewTayang from "../pages/DetailKontenPreview/ContentPartner";
// import UpdateKontenRevisi from "../pages/UpdateKontenRevisi/ContentPartner";

//ASYNC
import HomeCp from "../pagesAsync/AuthenticationAsync/SuperAdminProfileAsync";
import Profilecp from "../pagesAsync/AuthenticationAsync/CpProfileAsync";
import KontenContentPartner from "../pagesAsync/KontenAsync/ContentPartnerAsync";
import BuatKontenContentPartner from "../pagesAsync/BuatKontenAsync/ContentPartnerAsync";
import DetailKontenContentPartner from "../pagesAsync/DetailKontenAsync/ContentPartnerAsync";
import LibraryContentPartner from "../pagesAsync/LibraryKontenAsync/ContentPartnerAsync";
import KontenRevisi from "../pagesAsync/KontenRevisiAsync/ContentPartnerAsync";
import AjukanKembaliContentPartner from "../pagesAsync/AjukanKembaliAsync/ContentPartnerAsync";
import UbahJadwalKontenContentPartner from "../pagesAsync/UbahJadwalAsync/ContentPartnerAsync";
import DetailKontenPreviewTayang from "../pagesAsync/DetailKontenPreviewAsync/ContentPartnerAsync";
import UpdateKontenRevisi from "../pagesAsync/UpdateKontenRevisiAsync/ContentPartnerAsync";

const AppContentPartner = (props) => {
  return (
    <div>
      <Switch>
        <Redirect exact from={`${props.match.url}`} to={`${props.match.url}content-partner/home`} />
        <Route exeact path={`${props.match.url}content-partner/home`} component={HomeCp} />
        <Route exeact path={`${props.match.url}content-partner/profile`} component={Profilecp} />
        <Route
          exeact
          path={`${props.match.url}content-partner/konten`}
          component={KontenContentPartner}
        />
        <Route
          exeact
          path={`${props.match.url}content-partner/buat-konten`}
          component={BuatKontenContentPartner}
        />
        <Route
          exeact
          path={`${props.match.url}content-partner/detail-konten`}
          component={DetailKontenContentPartner}
        />
        <Route
          exeact
          path={`${props.match.url}content-partner/library-konten`}
          component={LibraryContentPartner}
        />
        <Route
          exeact
          path={`${props.match.url}content-partner/konten-revisi`}
          component={KontenRevisi}
        />
        <Route
          exeact
          path={`${props.match.url}content-partner/ajukan-kembali`}
          component={AjukanKembaliContentPartner}
        />
        <Route
          exeact
          path={`${props.match.url}content-partner/ubah-jadwal`}
          component={UbahJadwalKontenContentPartner}
        />
        <Route
          exeact
          path={`${props.match.url}content-partner/detail-konten-preview`}
          component={DetailKontenPreviewTayang}
        />
        <Route
          exeact
          path={`${props.match.url}content-partner/update-kontenrevisi`}
          component={UpdateKontenRevisi}
        />

        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default AppContentPartner;
