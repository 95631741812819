import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import style from "./style.module.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import DownWhite from "../../assets/images/backwhite.png";
import CPAnalisisTable from "../../components/Admin/Table/CPAnalisisTable";
import Header from "../../components/VerticalLayout/Header";
import { exportPDF } from "../../components/ContentPartner/Download/LibraryKonten/PDF/DownloadPDF";
import DownloadXLS from "../../components/ContentPartner/Download/LibraryKonten/XLS/DownloadXLS";
import TableKontenLibrary from "../../components/Admin/Table/KontenLibrary";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CPAnalisis = () => {
  let role = window.localStorage.getItem("role");
  //handle data xls
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const bool = Boolean;
  //handle data when change tab
  const handleData = (data) => {
    setData(data);
  };

  const handleData2 = (data2) => {
    setData2(data2);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const [activeSubTab2, setActiveSubTab2] = useState("1");
  const [idContent, setIdContent] = useState("");
  const [categoryContent, setCategoryContent] = useState("0");

  //handle download pdf
  const handleDownloadPDF = () => {
    exportPDF(data, data2, activeTab, activeSubTab2, activeTab === "1");
  };

  const query = useQuery();

  //refresh activeTab
  useEffect(() => {
    console.log(" ===> ", query.get("tab"));
    if (query.get("tab") === "video") {
      setActiveTab("1");
    } else if (query.get("tab") === "banner") {
      setActiveTab("2");
    } else if (query.get("tab") === "news") {
      setActiveTab("3");
    }
  }, [activeTab]);

  const tabStyle = {
    color: "#c42127",
  };
  const subTabStyle = {
    color: "#c42127",
    // borderBottom: "1px solid #c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };

  return (
    <React.Fragment>
      <header>
        <Header title="Content Partner Analisis" btn={true} btntype="back" btntext="Kembali" />
      </header>
      <main>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Table" /> */}
          <MDBContainer fluid={true}>
            <div className="classic-tabs">
              <Row>
                <Col className="col-12">
                  <MDBNav classicTabs className="wrapperNav">
                    <div className={style.wrapperTab}>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to="#"
                          style={activeTab === "1" ? tabStyle : {}}
                          className="tabMenu waves-light"
                          active={activeTab === "1"}
                          onClick={() => setActiveTab("1")}>
                          CONTENT PARTNER
                        </MDBNavLink>
                        <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to="#"
                          style={activeTab === "2" ? tabStyle : {}}
                          className="tabMenu"
                          active={activeTab === "2"}
                          onClick={() => setActiveTab("2")}>
                          CONTENT LIBRARY
                        </MDBNavLink>
                        <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                      </MDBNavItem>
                    </div>

                    {/* <div className="wrapperAction">
                      <MDBNavLink to="#" className="textDownload">
                        Download
                      </MDBNavLink>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className="DownloadTableXLS"
                        table="DownloadTableXLS"
                        filename={`Conten_Report_Library_${role}`}
                        sheet="users"
                        buttonText="XLS"
                      />
                      <MDBNavLink onClick={handleDownloadPDF} link to="#">
                        PDF
                      </MDBNavLink>
                    </div>
                    <DownloadXLS
                      id="DownloadTableXLS"
                      className="DownloadTableXLS"
                      data={data}
                      data2={data2}
                      activeTab={activeTab}
                      activeSubTab2={activeSubTab2}
                    /> */}
                    <div
                      style={{
                        width: "25%",
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "0.75%",
                      }}>
                      <MDBDropdown
                        style={{ width: "100% !important" }}
                        className="dropdown-download-doc">
                        <MDBDropdownToggle
                          caret
                          color="primary"
                          className="dropdown-download font-weight-bold">
                          <div className="wrapper-selected">Download</div>
                          <div>
                            <img src={DownWhite} alt="dropdown-icons" />
                          </div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-menus dropdown-show">
                          <MDBDropdownItem
                            style={{ color: "#556ee6" }}
                            className="text-center"
                            onClick={handleDownloadPDF}>
                            PDF
                          </MDBDropdownItem>
                          <MDBDropdownItem>
                            <ReactHTMLTableToExcel
                              id="TableXLS"
                              className="DownloadTableXLSDropdown"
                              table="DownloadTableXLS"
                              filename={`Conten_Report_Library_${role}`}
                              sheet="users"
                              buttonText="XLS"
                            />
                          </MDBDropdownItem>
                          <DownloadXLS
                            id="DownloadTableXLS"
                            className="DownloadTableXLS"
                            data={data}
                            data2={data2}
                            activeTab={activeTab}
                            activeSubTab2={activeSubTab2}
                          />
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </div>
                  </MDBNav>

                  <MDBTabContent className="card" activeItem={activeTab}>
                    <MDBTabPane tabId={activeTab}>
                      {activeTab === "1" && (
                        <Col className="col-12">
                          <MDBTabContent className="card">
                            <MDBTabPane>
                              <CPAnalisisTable handleData={handleData} />
                            </MDBTabPane>
                          </MDBTabContent>
                        </Col>
                      )}
                    </MDBTabPane>
                    <MDBTabPane tabId={activeTab}>
                      {activeTab === "2" && (
                        <Col className="col-12">
                          <MDBNav classicTabs className="wrapperNav">
                            <div className="wrapperTab">
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to="#"
                                  style={activeSubTab2 === "1" ? subTabStyle : {}}
                                  className="tabMenu waves-light"
                                  active={activeSubTab2 === "1"}
                                  onClick={() => setActiveSubTab2("1")}>
                                  VIDEO
                                </MDBNavLink>
                              </MDBNavItem>
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to="#"
                                  style={activeSubTab2 === "2" ? subTabStyle : {}}
                                  className="tabMenu"
                                  active={activeSubTab2 === "2"}
                                  onClick={() => setActiveSubTab2("2")}>
                                  BANNER
                                </MDBNavLink>
                              </MDBNavItem>
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to="#"
                                  style={activeSubTab2 === "3" ? subTabStyle : {}}
                                  className="tabMenu"
                                  active={activeSubTab2 === "3"}
                                  onClick={() => setActiveSubTab2("3")}>
                                  BERITA
                                </MDBNavLink>
                              </MDBNavItem>
                            </div>
                          </MDBNav>

                          <MDBTabContent className="card" activeItem={activeSubTab2}>
                            <MDBTabPane tabId={activeSubTab2}>
                              {activeSubTab2 === "1" && (
                                <TableKontenLibrary
                                  handleData={handleData2}
                                  activeTab={activeSubTab2}
                                  idContent={idContent}
                                />
                              )}
                              {activeSubTab2 === "2" && (
                                <TableKontenLibrary
                                  handleData={handleData2}
                                  activeTab={activeSubTab2}
                                  idContent={idContent}
                                />
                              )}
                              {activeSubTab2 === "3" && (
                                <TableKontenLibrary
                                  handleData={handleData2}
                                  activeTab={activeSubTab2}
                                  idContent={idContent}
                                />
                              )}
                            </MDBTabPane>
                          </MDBTabContent>
                        </Col>
                      )}
                    </MDBTabPane>
                  </MDBTabContent>
                </Col>
              </Row>
            </div>
          </MDBContainer>
        </div>
      </main>
    </React.Fragment>
  );
};

export default CPAnalisis;
