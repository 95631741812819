import React, { useEffect, useState } from "react";
import { Row, CardBody, Modal, ModalBody, Button, Col, Alert, Spinner } from "reactstrap";
import { MDBModalHeader, MDBNavLink } from "mdbreact";
import moment from "moment";

// COMPONENT
import CardDataDeviceFingerprint from "./CardDataFingerprint";
import CardPersebaranTipePerangkat from "./CardPersebaranTipePerangkat";
import CardPersebaranOS from "./CardPersebaranOS";
import CardPersebaranBrandModel from "./CardPersebaranBrandModel";
import CardBrowser from "./CardBrowser";
import CardPersebaranresolusiLayar from "./CardPersebaranresolusiLayar";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "./Download/XLS/DownloadDFP";

import Swal from "sweetalert2";

import Pdf from "react-to-pdf";

// STYLE CSS
import style from "./style.module.css";

// API
import API from "../../services";

export default function Index() {
  const [dataDFP, setDataDFP] = useState([]);

  const [loading, setLoading] = useState(true);

  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);

  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  const getDataDFP = () => {
    setLoading(true);

    API.getBIDFP()
      .then((res) => {
        if (res.data.success) {
          setDataDFP(res.data.data);
          // setDataDFP({
          //   brand_type: [],
          //   browser: 46,
          //   browser_type: [],
          //   device_type: [],
          //   dfp: 180523,
          //   mac: 1024,
          //   os: 12,
          //   os_type: [],
          //   resolution: [],
          //   visit: 8637612,
          // });
          setLoading(false);
        }
      })
      .catch((e) => {
        setDataDFP(null);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDataDFP();
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, []);

  const downloadRefCard = React.createRef();
  const downloadRefTipe = React.createRef();
  const downloadRefOS = React.createRef();
  const downloadRefBrand = React.createRef();
  const downloadRefBrowser = React.createRef();
  const downloadRefResolusi = React.createRef();

  const handlePDFDownload = (fn) => {
    Swal.fire({
      title: "Apakah kamu ingin mendownload halaman ini?",
      text: "Data PDF akan di download",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2c3280",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        fn();
      }
    });
  };

  const optionsPDF = {
    orientation: "landscape",
    // unit: 'in',
    // format: [4,2]
  };

  return (
    <div className="container-fluid" style={{ marginBottom: "100px" }}>
      <Row
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}>
        <CardBody
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <span className={style.titleMenuBI}>DEVICE FINGERPRINT</span>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div>Download</div>
            <div className="mx-3">
              <Pdf
                targetRef={downloadRefCard}
                filename="dfp-card.pdf"
                options={optionsPDF}
                scale={0.7}
                x={5}
                y={5}>
                {({ toPdf }) => (
                  <a href="#" onClick={() => handlePDFDownload(toPdf)}>
                    PDF
                  </a>
                )}
              </Pdf>
            </div>
          </div>
        </CardBody>
      </Row>
      {loading ? (
        <div className="text-center" style={{ marginTop: "30vh" }}>
          <Spinner type="grow" />
        </div>
      ) : dataDFP ? (
        <>
          {/* CARD */}
          <div ref={downloadRefCard} className="my-1">
            <CardDataDeviceFingerprint loading={loading} data={dataDFP} />
          </div>

          {/* TIPE PERANGKAT */}
          <div className="my-1">
            <CardPersebaranTipePerangkat loading={loading} data={dataDFP} />
          </div>
          <div className="my-1">
            <CardPersebaranOS loading={loading} data={dataDFP} />
          </div>
          <div className="my-1">
            <CardPersebaranBrandModel loading={loading} data={dataDFP} />
          </div>
          <div className="my-1">
            <CardBrowser loading={loading} data={dataDFP} />
          </div>
          <div className="my-1">
            <CardPersebaranresolusiLayar loading={loading} data={dataDFP} />
          </div>
        </>
      ) : (
        <Alert className="text-center mx-5 my-5" color="danger">
          Mohon Maaf, terjadi kesalahan pada server. Silahkan dicoba beberapa saat lagi.
        </Alert>
      )}
    </div>
  );
}
