import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../Filter/filter";
import _ from "lodash";
// ------------------API-----------------------
import API from "../../../../services";
// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconCheck from "../../../../assets/icon/IconCheck.svg";
import IconDecline from "../../../../assets/icon/IconDecline.svg";
import IconEdit from "../../../../assets/icon/IconEdit.svg";
import IconGreenCheckList from "../../../../assets/icon/IconGreenChecklist.svg";
import IconInfo from "../../../../assets/icon/IconInfo.svg";
import DetailKonten from "../../../../components/SuperAdmin/Popup/PersetujuanKonten/DetailKonten";
import ModalActived from "../../Popup/Survey/ActivedContent";
// ------------------Redux----------------

import { connect } from "react-redux";
import noImage from "../../../../assets/images/noImage.jpg";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import ModalAktifkanSurveyDefault from "../../Popup/SurveyDefault/Aktifkan/AktifkanSurvey";
import ModalNonAktifkanSurveyDefault from "../../Popup/SurveyDefault/NonAktifkan/NonAktifkanSurvey";

const TableSurveyDefault = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  //alert
  const [alert, setAlert] = useState("");
  //fetch api
  const fetchAPI = () => {
    setLoading(true);
    API.getSurveyDefault()
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        props.handleData(res.data.data);
        setRows(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
        // history.push("/pages-500");
        setRows(null);
      });
  };

  const hasilSurveyKlik = (it) => {
    history.push({
      pathname: `/admin/result/survey_default`,
      state: { title: it.name, _id: it._id },
    });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };

  //first rendering
  useEffect(() => {
    fetchAPI();
  }, [refresh, props.idContent]);

  useEffect(() => {
    filterData(items);
  }, [search, props.activeTab]);

  const filterData = (item) => {
    props.handleData(item);
  };

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      const index = [data.name, data.questions.length, data.status];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }

  const [isLoading, setIsLoading] = useState(false);
  const [dataModal, setDataModal] = useState("");
  const onClickAktif = () => {
    setIsLoading(true);
    API.changeSurveyStatus({
      idSurveyDefault: dataModal._id,
    })
      .then(() => {
        setRows([]);
        setRefresh(!refresh);
        setIsLoading(false);
        setModalAktifkan(false);
        setModalNonAktifkan(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [modalAktifkan, setModalAktifkan] = useState(false);
  const toggleAktifkan = (data) => {
    setModalAktifkan(!modalAktifkan);
    setDataModal(data);
  };

  const [modalNonAktifkan, setModalNonAktifkan] = useState(false);
  const toggleNonAktifkan = (data) => {
    setModalNonAktifkan(!modalNonAktifkan);
    setDataModal(data);
  };

  //button aksi
  const ButtonAction = (props) => {
    return (
      <>
        <Row>
          <Col xs="6">
            {props.it.status === "Non Aktif" ? (
              <Button
                onClick={() => toggleAktifkan(props.it)}
                className="btn-rounded btnAktifkanSurvey">
                Aktifkan
              </Button>
            ) : (
              <Button
                onClick={() => toggleNonAktifkan(props.it)}
                className="btn-rounded btnNonAktifkanSurvey">
                Non Aktifkan
              </Button>
            )}
          </Col>
          <Col xs="6">
            <Button
              outline
              onClick={() => hasilSurveyKlik(props.it ? props.it : "-")}
              className="btn-rounded btnResultSurvey">
              Lihat Hasil
            </Button>
          </Col>
        </Row>
      </>
    );
  };
  //data on table
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Nama Survei",
        field: "Nama_Survei",
        sort: "asc",
        width: 500,
      },
      {
        label: "Jumlah Pertanyaan",
        field: "Pertanyaan",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "stats",
        sort: "asc",
        width: 150,
      },
      {
        label: "",
        field: "Aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, i) => ({
            no: i + 1,
            Nama_Survei: it.name,
            Pertanyaan: it.questions.length ?? "-",
            stats: it.status,
            Aksi: <ButtonAction it={it ? it : ""} />,
          })),
  };
  return (
    <React.Fragment>
      <ModalNonAktifkanSurveyDefault
        onClickButton={() => onClickAktif()}
        data={dataModal}
        modal={modalNonAktifkan}
        toggle={() => toggleNonAktifkan()}
        isLoading={isLoading}
      />
      <ModalAktifkanSurveyDefault
        onClickButton={() => onClickAktif()}
        data={dataModal}
        modal={modalAktifkan}
        toggle={() => toggleAktifkan()}
        isLoading={isLoading}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">Tidak ada survei</div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                        onSort={{ column: "Nama_Survei" }}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TableSurveyDefault);
