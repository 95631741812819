export const drawGeoPoint = (mapref, dataTitikInternet, name, popup, type, currArea) => {
  // console.log("helper", dataTitikInternet);
  // console.log("helper", dataTitikInternet.filter(e=>e.count !== 0));

  //   count: 0
  // id: "5c04de5e1143c12c8bdbc9c5"
  // latitude: "-8.5819"
  // longitude: "115.1771"
  // name: "KAB. BADUNG"
  // province: "BALI"

  if (dataTitikInternet.length !== 0) {
    if (dataTitikInternet.filter((e) => e.count !== 0).length === 0) {
      setTimeout(() => {
        alert(
          `Tidak ada data pada filter ${type} ${
            currArea === "prov"
              ? "Semua Provinsi"
              : `Provinsi ${dataTitikInternet[0].province}`
          } di tanggal tersebut`
        );
      }, 500);
    }
  }

  const newArray = [];
  dataTitikInternet.map((e) => {
    newArray.push(e.count);
  });
  if (newArray[0] == undefined) {
    newArray.shift();
  }
  const maxTotal = Math.max(...newArray);

  // const total= {Object.values(data).reduce((total, val) => {
  //   var a = val.total ? val.total : 0;
  //   total = total + a;
  //   return total;
  // }, 0)}
  const popupInfo = (e, param) => {
    switch (param) {
      case "prov":
        return `
        <h6>Provinsi</h6>
        <h5>${e.name}</h5>
        <h6>Total ${type}</h6>
        <h5>${e.count.toLocaleString()}</h5>
        `;
        break;
      case "kab":
        return `
        <h6>Provinsi</h6>
        <h5>${e.province}</h5>
        <h6>Kabupaten/Kota</h6>
        <h5>${e.name}</h5>
        <h6>Total ${type}</h6>
        <h5>${e.count.toLocaleString()}</h5>
        `;
        break;
      case "kec":
        return `
        <h6>Provinsi</h6>
        <h5>${e.name}</h5>
        <h6>Kabupaten/Kota</h6>
        <h5>${e.name}</h5>
        <h6>Kecamatan</h6>
        <h5>${e.name}</h5>
        <h6>Total ${type}</h6>
        <h5>${e.count.toLocaleString()}</h5>
        `;
        break;
      case "desa":
        return `
        <h6>Provinsi</h6>
        <h5>${e.name}</h5>
        <h6>Kabupaten/Kota</h6>
        <h5>${e.name}</h5>
        <h6>Kecamatan</h6>
        <h5>${e.name}</h5>
        <h6>Desa</h6>
        <h5>${e.name}</h5>
        <h6>Total ${type}</h6>
        <h5>${e.count.toLocaleString()}</h5>
        `;
        break;

      default:
        break;
    }
  };

  const radiusCircle = (i) => {
    if (i.count > 1 && i.count <= maxTotal * 0.1) {
      return "sm";
    } else if (i.count > maxTotal * 0.1 && i.count <= maxTotal * 0.25) {
      return "md";
    } else if (i.count > maxTotal * 0.25 && i.count <= maxTotal * 0.5) {
      return "lg";
    } else if (i.count > maxTotal * 0.5 && i.count <= maxTotal * 0.75) {
      return "xl";
    } else if (i.count > maxTotal * 0.75 && i.count <= maxTotal) {
      return "xxl";
    } else {
      return "nol";
    }
  };

  var geojson = {
    type: "FeatureCollection",
    name: "merged",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: dataTitikInternet.map((e, i) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [e.longitude, e.latitude],
      },
      properties: {
        description: popupInfo(e, currArea),
        count: radiusCircle(e),
      },
    })),
  };

  mapref.addSource(`${name}Source`, {
    type: "geojson",
    data: geojson,
  });

  mapref.addLayer({
    id: `${name}Layer`,
    type: "circle",
    source: `${name}Source`,
    layout: {
      visibility: "visible",
    },
    paint: {
      "circle-radius": [
        "match",
        ["get", "count"],
        "sm",
        5,
        "md",
        7,
        "lg",
        12,
        "xl",
        16,
        "xxl",
        19,
        0,
      ],
      // "circle-radius": 8,
      "circle-color": currArea === "prov" ? "#CD1717" : currArea === "kab" ? "#E22FB0" : "blue",
      // "circle-color": [
      //   "match",
      //   ["get", "count"],
      //   "md",
      //   "red",
      //   "lg",
      //   "blue",
      //   "xl",
      //   "yellow",
      //   "xxl",
      //   "orange",
      //   "nol",
      //   "pink",
      //   /* other */ "black",
      // ],
    },
  });
  let hoveredStateId = null;

  mapref.on("mouseenter", `${name}Layer`, function (e) {
    // Change the cursor style as a UI indicator.
    mapref.getCanvas().style.cursor = "pointer";

    var coordinates = e.features[0].geometry.coordinates.slice();
    var description = e.features[0].properties.description;

    // Ensure that if the mapref is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(description).addTo(mapref);
  });

  mapref.on("mouseleave", `${name}Layer`, function () {
    mapref.getCanvas().style.cursor = "";
    popup.remove();
  });
};

export const removeDummyPoint = (mapRef) => {
  // Indonesia long lat center
  const target = [118.0148634, -2.548926];
  mapRef.current.state.map.removeLayer("LayerDummy");
  mapRef.current.state.map.removeSource("SourceDummy");
  mapRef.current.state.map.flyTo({
    center: target,
    zoom: 3.7,
    // essential: true,
  });
};
