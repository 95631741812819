import React, {useState} from 'react'
import { Row, Col } from "reactstrap";
import DemografiPengunjungPage from '../../../../components/DemografiPengunjung'
import Header from "../../../../components/VerticalLayout/Header";
import SideMenuBI from "../../../../components/SideMenuBI";

export default function DemografiPengunjung() {
    const [menu, setMenu] = React.useState("Persebaran Pengunjung");
    const changeMainSectionView = (item) => setMenu(item);
    const [markerData, setMarkerData] = useState([
        { name: "JAKARTA", longitude: "121", latitude: "-2" },
    ])
    return (
        <div className="page-content">
            <header>
                <Header title="Business Intelligence" className="SuperAdminHeader" />
            </header>
            <main>
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Table" /> */}
                    <Row>
                    <Col md="2">
                        <SideMenuBI active={menu} action={changeMainSectionView} />
                    </Col>
                    <Col md="10">
                        {/* <MainSectionViewer item={menu} /> */}
                        <div style={{height:'100%', width:'100%'}}>
                            <DemografiPengunjungPage />
                        </div>
                    </Col>
                    </Row>
                </div>
            </main>
        </div>
    )
}


// import React, {useState} from 'react'
// import Map from '../../../../components/MapContext/Map'

// export default function DemografiPengunjung() {
//     const [markerData, setMarkerData] = useState([
//         { name: "JAKARTA", longitude: "121", latitude: "-2" },
//       ])
//     return (
//         <div style={{height:'100%', width:'100%'}}>
//             <h2>Demografi Pengunjung</h2>
// >>>>>>> dev-radit