import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  // Form,
  // FormGroup,
  // Label,
  // Input,
  // FormText,
  Row,
  Col,
  // Button,
  // CardTitle,
  // CardText,
} from "reactstrap";
import "./style-article.css";
import Skeleton from "react-loading-skeleton";
import API from "../../../services";

function ArticleTotal(props) {
  const { data, loading } = props;
  const [homepage, setHomepage] = useState({});

  const [countNews, setCountNews] = useState([]);
  const [countItem, setCountItem] = useState(0);
  const [emailNewsLetter, setEmailNewsLetter] = useState(0);
  const [countLike, setCountLike] = useState(0);
  const [countDislike, setCountDislike] = useState(0);
  const [countPlay, setCountPlay] = useState(0);
  // untuk loading skeleton
  const skeletonCostume = <Skeleton height={70} />;

  //  ========== ARTIKEL ==================
  const getTotalVisited = () => {
    API.getVisitHomepage()
      .then((response) => {
        setHomepage(response.data.data);
      })
      .catch();
  };

  const getCountNews = () => {
    API.getCountNews()
      .then((response) => {
        setCountNews(response.data);
      })
      .catch
      // console.log("sas")
      ();
  };

  // =========== END ARTIKEL ==============

  const getDashboardVideo = () => {
    API.getVideoDashboard()
      .then((res) => {
        setCountItem(res.data.data.count);
        setCountLike(res.data.data.like);
        setCountDislike(res.data.data.dislike);
        setCountPlay(res.data.data.play);
      })
      .catch();
  };

  useEffect(() => {
    if (props.typeContent !== "video") {
      getCountNews();
      getTotalVisited();
    } else {
      getDashboardVideo();
      //alert(data.count + " - " + data.like + " - " + data.dislike + " - " + data.play);
    }
  }, []);

  var { typeContent } = props;

  typeContent = typeContent
    ? typeContent.charAt(0).toUpperCase() + typeContent.slice(1)
    : "Artikel";

  var totalPost = data?.data?.totalArticle;
  var totalNewsletter = countNews?.data;
  var totalLike = data?.data?.totalLike;
  var totalDislike = data?.data?.totalDislike;
  var totalPlay = 0;

  if (typeContent === "Video") {
    totalPost = countItem;
    totalNewsletter = emailNewsLetter;
    totalLike = countLike;
    totalDislike = countDislike;
    totalPlay = countPlay;
  }

  const textBaca = typeContent === "Video" ? "LIHAT" : "BACA";

  return (
    <div>
      <Container>
        {loading == true ? (
          <div className="mt-2 mb-2">{skeletonCostume}</div>
        ) : (
          <Row xs="4">
            <Col lg="2" md="12" md="12" sm="12" xs="12">
              <Card style={{ height: "120px" }}>
                <CardBody
                  className="card-article"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div className="wrap-content">
                    <div className="title-content">
                      <p>Total {typeContent}</p>
                    </div>
                    <div className="content-total-artikel">
                      <p>{totalPost}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="12" md="12" sm="12" xs="12">
              <Card style={{ height: "120px" }}>
                <CardBody
                  className="card-article"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div className="wrap-content">
                    <div className="title-content">
                      <p>Total Email Newsletter</p>
                    </div>
                    <div className="content-total-artikel">
                      <p>{totalNewsletter} </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="2" md="12" md="12" sm="12" xs="12">
              <Card style={{ height: "120px" }}>
                <CardBody
                  className="card-article"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div className="wrap-content">
                    <div className="title-content">
                      <p>Total Like</p>
                    </div>
                    <div className="content-total-like">
                      <p>{totalLike}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="2" md="12" md="12" sm="12" xs="12">
              <Card style={{ height: "120px" }}>
                <CardBody
                  className="card-article"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div className="wrap-content">
                    <div className="title-content">
                      <p>Total Dislike</p>
                    </div>
                    <div className="content-total-dislike">
                      <p>{totalDislike}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="12" md="12" sm="12" xs="12">
              <Card style={{ height: "120px" }}>
                <CardBody
                  className="card-article"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div className="wrap-content">
                    <div className="title-content">
                      <p>Total Kunjungan Homepage</p>
                    </div>
                    <div className="content-total-artikel">
                      <p>{homepage?.hits ?? 0}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
export default ArticleTotal;
