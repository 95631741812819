import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";

const ModalPreviewEmergencyDoc = ({ modalDoc, toggleModalDoc, dataModalDoc }) => {
  return (
    <MDBContainer>
      <MDBModal size="lg" isOpen={modalDoc} toggle={toggleModalDoc} centered={true}>
        <MDBModalHeader centered toggle={toggleModalDoc}>
          {dataModalDoc.title}
        </MDBModalHeader>
        <MDBModalBody>
          <iframe
            src={`${process.env.REACT_APP_BE_URL}/${dataModalDoc.url}`}
            target="_top"
            className="iframe_doc"
            frameborder="0"
            allowFullScreen></iframe>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalPreviewEmergencyDoc;
