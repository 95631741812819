export const inputValidator = (type, val) => {
  // let regPhone = /^(^\+62|62|^08)(\d{9,10})$/;
  let regPhone = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/;
  let regEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  switch (type) {
    case "phone":
      return regPhone.test(val);
      break;
    case "email":
      return regEmail.test(val);
      break;

    default:
      break;
  }
};
