// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/bg-login.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body, html {\n    height: 100%;\n    margin: 0;\n}\n.login_bgWrapp__2Kr8-{\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat no-repeat;\n    height: 100%; \n\n  /* Center and scale the image nicely */\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}", ""]);
// Exports
exports.locals = {
	"bgWrapp": "login_bgWrapp__2Kr8-"
};
module.exports = exports;
