import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const UniqueDeviceAdminAsync = Loadable({
  loader: () =>
    import("../../../pages/UniqueDevice/Admin" /* webpackChunkName: "UniqueDeviceAdminAsync" */),
  loading: Loading,
});

export default UniqueDeviceAdminAsync;
