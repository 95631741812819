import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const LibraryContentPartnerAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/LibraryKonten/ContentPartner" /* webpackChunkName: "LibraryContentPartnerAsync" */
    ),
  loading: Loading,
});

export default LibraryContentPartnerAsync;
