import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";

const ModalPreviewBanner = ({ modalKonten, toggleModalKonten, dataModalKonten }) => {
  // console.log("dataModalKonten.url", dataModalKonten.url.url)
  return (
    <MDBContainer>
      <MDBModal size="lg" isOpen={modalKonten} toggle={toggleModalKonten} centered="true">
        <MDBModalHeader centered toggle={toggleModalKonten}>
          {dataModalKonten.title}
        </MDBModalHeader>
        <MDBModalBody>
          <div className="image-wrapper">
            <img
              onerror="this.onerror=null;this.src='http://example.com/existent-image.jpg';"
              alt={dataModalKonten.title ?? "-"}
              src={
                typeof dataModalKonten.url !== undefined
                  ? `${process.env.REACT_APP_BE_URL}/${
                      dataModalKonten ? (dataModalKonten.url ? dataModalKonten.url.url : "-") : "-"
                    }`
                  : ""
              }
              className="banner"></img>
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalPreviewBanner;
