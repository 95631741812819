import React, { useEffect, useState, createRef, useMemo } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import "./style.css";
import { Container, Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import { listProvinsi } from "./const";
// ------------------API---------------------
import API from "../../../../../services";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const ModalTambahProvinsi = (props) => {
  let province = useMemo(() => {
    return props.province;
  }, [props.province]);
  const history = useHistory();
  const [selectAllProv, setSelectAllProv] = useState([]);
  const [select, setSelect] = useState([props.listSelected.join(",")]);
  const [availableProvince, setAvailableProvince] = useState([]);
  const [isSelectAllProvince, setIsSelectAllProvince] = useState(false);

  const handleChangeRadio = (e, all) => {
    if (e.target.checked) {
      if (all == true) {
        setIsSelectAllProvince(true);
        setSelect(_.map(listProvinsi, "name"));
        props.selectedProvince(_.map(listProvinsi, "name"));
      } else {
        setIsSelectAllProvince(false);
        setSelect([...select, e.target.value]);
        props.selectedProvince([...select, e.target.value]);
      }
    } else {
      let filterProvName;
      if (all) {
        setIsSelectAllProvince(false);
        props.selectedProvince([]);
        setSelect([]);
      } else {
        setIsSelectAllProvince(false);
        filterProvName = select.filter((it) => it !== e.target.value);
        setSelect(filterProvName);
        props.selectedProvince(filterProvName);
      }
    }
  };

  return (
    <MDBContainer>
      <MDBModal
        size="md"
        isOpen={props.modalTambahProvinsi}
        toggle={props.toggleModalTambahProvinsi}
        centered={true}>
        <MDBModalHeader centered toggle={props.toggleModalTambahProvinsi}>
          Provinsi konten default
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <Row
              className="col-lg-10 mx-auto col-offset-2"
              style={{
                backgroundColor: "#f7f7f7",
                width: "100%",
                overflowX: "auto",
              }}>
              <Col className="" style={{ padding: "5px", overflowY: "hidden", overflowX: "auto" }}>
                <div
                  id="groupAllProvinsi"
                  className="custom-control custom-checkbox mb-2"
                  style={{ overflowY: "scroll" }}>
                  <input
                    type="checkbox"
                    checked={isSelectAllProvince}
                    className="custom-control-input input-mini"
                    onChange={(e) => handleChangeRadio(e, true)}></input>
                  <Label className={"custom-control-label textCheckbox"}>
                    Pilih Semua Provinsi
                  </Label>
                </div>
              </Col>
            </Row>
            <Row
              className="col-lg-10 mx-auto col-offset-2"
              style={{
                backgroundColor: "#f7f7f7",
                height: "200px",
                width: "100%",
                overflowX: "auto",
              }}>
              <Col
                md="5"
                xs="5"
                lg="5"
                style={{ height: "200px", padding: "5px", overflowY: "hidden", overflowX: "auto" }}>
                <div
                  id="group1"
                  className="custom-control custom-checkbox mb-2"
                  style={{ overflowY: "scroll", height: "200px" }}>
                  {listProvinsi.map((list, i) => {
                    if (list._id !== province) {
                      return (
                        <div>
                          <input
                            type="checkbox"
                            checked={select.includes(list.name)}
                            className="custom-control-input input-mini"
                            name={`${list.name}${i}`}
                            value={`${list.name}`}
                            onChange={(e) => handleChangeRadio(e, false)}
                            id={i}></input>
                          <Label
                            className={"custom-control-label textCheckbox"}
                            for={i}>{`${list.name}`}</Label>
                        </div>
                      );
                    }
                  })}
                </div>
              </Col>
              <Col lg="2" md="2" xs="2">
                <div className="v3"></div>
              </Col>
              <Col
                lg="5"
                md="5"
                xs="5"
                style={{ marginLeft: "0 !important", height: "200px", overflowY: "hidden" }}>
                <div style={{ height: "190px", overflow: "scroll" }}>
                  {select.map((e) => (
                    <Label className="textCheckbox d-block">{e}</Label>
                  ))}
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Button
                color=""
                onClick={props.toggleModalTambahProvinsi}
                className="btn btn-rounded btnCekJam col-3 col-offset-2 mt-2 mx-auto">
                OK
              </Button>
            </Row>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
const mapStatetoProps = (state) => {
  const { dataAjukan } = state.dataReducer;
  return { dataAjukan };
};
export default connect(mapStatetoProps, null)(ModalTambahProvinsi);
