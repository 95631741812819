import Loadable from "react-loadable";
import Loading from "../../../../../components/Loading";

const VisitorJourneyAsync = Loadable({
  loader: () =>
    import(
      "../../../../../pages/BusinessIntelligence/Admin/Measures/VisitorJourney" /* webpackChunkName: "VisitorJourneyAsync" */
    ),
  loading: Loading,
});

export default VisitorJourneyAsync;
