import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLSNewsUB" className="DownloadTableXLS">
      <thead>Data Rata - Rata User Behavior Konten News Tanggal {props.date}</thead>
      <thead>
        <tr>
          <th>Jumlah Klik Card Berita</th>
          <th>Jumlah Klik Pop Up Berita</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>{data ? (data.card ? data.card.News ?? 0 : 0) : 0}</td>
                <td>{data ? (data.popup ? data.popup.News ?? 0 : 0) : 0}</td>
              </tr>
            </>
          ) : (
            <>
              <td>-</td>
              <td>-</td>
            </>
          )
        ) : (
          <>
            <td>-</td>
            <td>-</td>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
