import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import ReactPaginate from "react-paginate";
import { Row, Col, Card, CardBody, Container, Button, Spinner } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import _ from "lodash";

// ------------------API-----------------------
import API from "../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
import "../../../../assets/css/style.css";
//icon

// ------------------Component----------------
import star from "../../../../assets/images/Star4.png";
import starwhite from "../../../../assets/images/Star10.png";
import Filter from "../../../Filter/filter";
import FilterCalendar from "../../../Filter/filterCalendar";
// ------------------Redux----------------

import { connect } from "react-redux";

const TableSurveyContentPartner = (props) => {
  let getRoleStorage = window.localStorage.getItem("role");
  let history = useHistory();
  const searchRef = useRef();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageDisplay, setPageDisplay] = useState(10);

  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ratingAverage, setRatingAverage] = useState("");

  const [clear, setClear] = useState(true);

  const fetchAPI = (params, withParams = true) => {
    setLoading(true);
    API.getSurveyKepuasanCP(page, params, withParams)
      .then((res) => {
        if (res?.data?.count === 0) {
          setIsEmpty(true);
        } else {
          setPageCount(res.data.count);
          setPageDisplay(10);
        }
        setLoading(false);
        setRatingAverage(res?.data?.data?.average);
        props.handleData(res?.data?.data?.ratingCP);
        setRows(res?.data?.data?.ratingCP);
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    // const searchValue = searchRef.current.value;
    // setSearch(searchValue);
    setClear(!clear);
  };

  //if post modal success, then refresh data
  useEffect(() => {
    if (props.categoryContent !== "0") {
      let params = {};

      if (selectedDate) {
        params.date = selectedDate;
      } else {
        // params.year = year;
        // params.month = month;
      }

      if (search) {
        params.search = search;
      }

      fetchAPI(params);
      //fetchAPI(props.categoryContent);
    } else {
      fetchAPI();
    }
  }, [page, props.idContent, props.categoryContent, clear]);

  useEffect(() => {
    filterData();
  }, [clear]);

  //throw data to parent for download
  const filterData = (item) => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);

    let params = {};

    if (selectedDate) {
      params.date = selectedDate;
    }

    if (searchValue) {
      params.search = searchValue;
    }

    fetchAPI(params);
  };

  //if search input change
  // useEffect(() => {
  //   filterData(items);
  // }, [search]);

  //modal search by date
  const [selectedDate, setSelectedDate] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [modalCalendar, setModalCalendar] = useState(false);

  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  //make array for date search
  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  //handle selected date range
  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };

  // //filtering data
  var items = [];
  if (rows && rows?.length > 0) {
    items = rows;
    //   items = rows?.filter((data) => {
    //     if (search === "" && tanggal === "") return data;
    //     const index = [
    //       data.rating_by.name,
    //       data.rating_by.organization.name,
    //       moment(data.tanggal_pembuatan).format("DD-MM-YYYY"),
    //       data.organization ? data.organization.name : "-",
    //       data.rating,
    //       data.comment,
    //     ];
    //     if (
    //       Filter.byIndex({
    //         index: index,
    //         search: search,
    //         date: tanggal,
    //       })
    //     ) {
    //       return data;
    //     }
    //   });
  }

  const Rating = ({ rating }) => {
    let countStarWhite = 5 - rating;
    return (
      <Col className="mx-auto">
        {_.times(rating, () => (
          <img className="mr-1" src={star} />
        ))}
        {_.times(countStarWhite, () => (
          <img className="mr-1" src={starwhite} />
        ))}
      </Col>
    );
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  const handlePageClick = (data) => {
    //console.log("data from handle ==> ", data);
    //const searchValue = searchRef.current.value;
    setPage(data.selected + 1);
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Nama",
        field: "nama",
        sort: "asc",
        width: 200,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type Content",
        field: "type_content",
        sort: "asc",
        width: 150,
      },
      {
        label: "Title Content",
        field: "title_content",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal",
        field: "tanggal",
        sort: "asc",
        width: 100,
      },
      {
        label: `Rating`,
        field: "rating",
        sort: "asc",
        width: 200,
      },
      {
        label: "Komentar",
        field: "komentar",
        sort: "asc",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              waktu_registrasi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.length !== 0
        ? items?.map((it, index) => ({
            no: index + 1,
            nama: it ? (it.rating_by ? it.rating_by.name : "-") : "-",
            organisasi: it
              ? it.rating_by
                ? it.rating_by.organization
                  ? it.rating_by.organization.name
                  : "-"
                : "-"
              : "-",
            type_content: it ? it.typeContent : "-",
            title_content: it ? it?.idContent?.details?.title : "-",
            tanggal: it
              ? it.tanggal_pembuatan
                ? `${moment(it.tanggal_pembuatan).format("YYYYMMDD")}
            ${moment(it.tanggal_pembuatan).format("DD-MM-YYYY")}`
                : "-"
              : "-",
            rating: <Rating rating={it ? it.rating : "-"} />,
            komentar: it ? (it.comment ? it.comment : "-") : "-",
          }))
        : [],
  };

  return (
    <React.Fragment>
      <FilterCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        selectedDate={handleSelectedDate}
      />
      <Row>
        <Col className="col-12">
          <Card className="wrapperCard">
            <CardBody>
              <Row className="my-3">
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className="text-bold">
                    <span className="textBlackBold">Rata-Rata Rating Kepuasan</span>
                    <span className="textBlueBold ml-1">
                      {loading ? <Spinner color="primary" /> : ratingAverage}
                    </span>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className="d-flex ml-auto w-100">
                    <Button
                      color=""
                      onClick={toggleModalCalendar}
                      id="tanggal"
                      className="inputWrapper btnTanggal"
                      style={{ marginRight: "10px" }}>
                      <p className="selectedDate">
                        {selectedDate}
                        <span class="mdi mdi-18px mdi-calendar-range" style={{ float: "right" }} />
                      </p>
                    </Button>
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      type="text"
                    />
                    <Button
                      className=" btn-primary btn-sm"
                      style={{ margin: "0 10px 0 10px" }}
                      onClick={handleSearch}>
                      Search
                    </Button>
                    <Button
                      className=" btn-secondary btn-sm"
                      onClick={() => {
                        setSearch("");
                        setTanggal("");
                        setSelectedDate("");
                        setIsEmpty(false);
                        searchRef.current.value = "";
                        setClear(!clear);
                      }}>
                      Clear
                    </Button>
                  </div>
                </Col>
              </Row>

              {rows.length === 0 && !isEmpty ? (
                <div>
                  <h1>
                    <Skeleton />
                  </h1>
                  <Skeleton count={10} />
                </div>
              ) : isEmpty ? (
                <div className="justify-content-md-center">Tidak Ada Data</div>
              ) : (
                <div style={{ whiteSpace: "pre-line" }}>
                  <MDBDataTable
                    id="TableSurveyContentPartner"
                    noBottomColumns={true}
                    className="text-center"
                    responsive
                    disableRetreatAfterSorting
                    striped
                    bordered
                    data={data}
                    displayEntries={false}
                    pagesAmount={5}
                    entries={10}
                    searching={false}
                    paging={false}
                  />

                  <div className="float-right">
                    <ReactPaginate
                      pageCount={Math.ceil(pageCount / pageDisplay)}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={2}
                      initialPage={page - 1} // INDEX
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      activeClassName="active"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"></ReactPaginate>
                  </div>
                  <div className="clearfix"></div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TableSurveyContentPartner);
