import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";
import { MDBNav, MDBNavItem, MDBNavLink, MDBSpinner } from "mdbreact";
import { Link, useHistory, withRouter } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// -------------------Service------------------------
import API from "../../../services";
// -------------------ASSET------------------------
//css
import "./style.css";
import IconUpload from "../../../assets/icon/IconUpload.svg";
import IconUploadToggle from "../../../assets/icon/IconUploadToggle.svg";
import IconDownload from "../../../assets/icon/IconDownload.svg";
import IconDownloadToggle from "../../../assets/icon/IconDownloadToggle.svg";
import IconEmergency from "../../../assets/icon/IconEmergency.svg";
//doc
import FORMAT_EMERGENCY from "./TemplateEmergency.docx";
import ModalDescEmergency from "../../../components/Admin/Popup/BuatKonten/ModalDescEmergency";
// -------------------COMPONENT--------------------
import ModalCalendar from "../../../components/Admin/Popup/BuatKonten/Calendar";
import ModalJamTayang from "../../../components/Admin/Popup/BuatKonten/JamTayang";
import ModalTambahProvinsi from "../../../components/Admin/Popup/BuatKonten/TambahProvinsi";
import Header from "../../../components/VerticalLayout/Header";
import { listKategori } from "./const";
import { FilterCategory } from "./dropdownFilter";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//popover
import PopoverComponent from "../../../components/Admin/popover";

function BuatKontenAdmin(props) {
  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
  //modal loading
  const [modalLoading, setModalLoading] = useState(false);
  //notif
  const [successSubmitted, setSuccessSubmitted] = useState("");
  const [namaSurvey, setNamaSurvey] = useState("");
  const [jenisSurvey, setJenisSurvey] = useState("");

  const handleClear = () => {};

  const handleSubmitContent = async () => {};

  const handleThumbnail = (e) => {};

  const handleLink = (e) => {};

  //modal desc
  const [modalDesc, setModalDesc] = useState(false);

  const toggleModalDesc = () => {
    setModalDesc(!modalDesc);
  };

  const filterData = useCallback(
    (filt) => {
      setJenisSurvey(filt);
    },
    [jenisSurvey]
  );

  return (
    <React.Fragment>
      {/* <ModalDescEmergency modalDesc={modalDesc} toggleModalDesc={toggleModalDesc} /> */}
      {modalLoading ? (
        successSubmitted == "success" ? (
          <SweetAlert title="Konten Berhasil Dibuat" success showConfirm={false} onConfirm={""}>
            {namaSurvey}
          </SweetAlert>
        ) : successSubmitted == "fail" ? (
          <SweetAlert title="Konten Tidak Berhasil Dibuat" error showConfirm={false} onConfirm={""}>
            {namaSurvey}
          </SweetAlert>
        ) : (
          <SweetAlert
            showConfirm={false}
            onConfirm={""}
            title="Mohon Tunggu"
            style={{
              padding: "3em",
              borderRadius: "20px",
            }}>
            <Spinner
              color="danger"
              style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
              type="grow"
            />
            <div>Konten {namaSurvey} sedang dibuat</div>
          </SweetAlert>
        )
      ) : null}
      <div className="page-content">
        <header>
          <Header title="Preview Survey" btn={true} btntype="back" btntext="SURVEY DEFAULT" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <MDBContainer > */}
            <div className="classic-tabs">
              <Row style={{ overflowY: "auto" }}>
                <Col className="col-12">
                  <Row>
                    <Col className="col-lg-3 mx-auto" id="formKonten">
                      <Row className="rowHeader">
                        <p>Provinsi</p>
                        <p>: Provinsi</p>
                      </Row>
                      <Row className="rowHeader">
                        <p>Tanggal</p>
                        <p>: Tanggal</p>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-lg-12">
                      <Row className="w-100 mx-auto">
                        <Form className="w-100 col-lg-6 mx-auto">
                          <FormGroup>
                            <Label for="nama_survei">Nama Survei</Label>
                            <Input
                              type="text"
                              name="text"
                              id="nama_survei"
                              value={namaSurvey}
                              onChange={(e) => setNamaSurvey(e.target.value)}
                              className="inputWrapper"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Row className="d-flex mx-auto">
                              <Label className="nomorSurvey mr-2">2</Label>
                              <Label for="nama_survei">pertanyaan1</Label>
                            </Row>

                            <Row className="wrapperFilterChoose d-flex mx-auto">
                              <Label className="mr-4 col-lg-6">
                                <Input type="text" name="isian" />
                              </Label>
                              <Label className="col-lg-4 ml-2">
                                <FilterCategory
                                  type={"Kategori"}
                                  filter={jenisSurvey}
                                  clicked={filterData}
                                  list={listKategori}
                                />
                              </Label>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row className="d-flex mx-auto">
                              <Label className="nomorSurvey mr-2">2</Label>
                              <Label for="nama_survei">
                                Habitasse eu aliquam ultricies eros sed?
                              </Label>
                            </Row>

                            <Row className="wrapperFilterChoose d-flex ">
                              <Label className="wrapperRadioButton">
                                <Label check>
                                  <Input type="radio" name="radio1" /> Option one is this and
                                  that—be sure to include why it's great
                                </Label>
                              </Label>

                              <Label className="col-lg-4 positionFilterRadio">
                                <FilterCategory
                                  type={"Kategori"}
                                  filter={jenisSurvey}
                                  clicked={filterData}
                                  list={listKategori}
                                />
                              </Label>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Row className="d-flex mx-auto">
                              <Label className="nomorSurvey mr-2">3</Label>
                              <Label for="nama_survei">pertanyaan3</Label>
                            </Row>

                            <Row className="wrapperFilterChoose d-flex ml-4">
                              <Label className="mr-4">
                                <Label check className="d-flex">
                                  <Input
                                    type="checkbox"
                                    name="checkbox"
                                    className="wrapperCheckbox"
                                  />
                                  Option one is this and that—be sure to include why it's great
                                </Label>
                              </Label>
                              <Label className="col-lg-4 mr-2">
                                <FilterCategory
                                  type={"Kategori"}
                                  filter={jenisSurvey}
                                  clicked={filterData}
                                  list={listKategori}
                                />
                              </Label>
                            </Row>
                          </FormGroup>

                          <div className="wrapperBtnCreate">
                            <Button className="btnClear" onClick={handleClear} color="">
                              Clear
                            </Button>
                            <Button
                              className="btnAskSubmit btn btn-rounded"
                              onClick={handleSubmitContent}
                              color="">
                              {modalLoading ? <>Loading...</> : <>Buat Survei</>}
                            </Button>
                          </div>
                          <div className="wrapperKonten"></div>
                        </Form>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* </MDBContainer> */}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

export default BuatKontenAdmin;
