import React, { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";
import { addDays, endOfWeek, startOfWeek } from "date-fns";
import Header from "../../components/VerticalLayout/Header";
import ModalTambahProvSurvei from "../../components/Admin/Popup/TambahProvinsi";
import API from "../../services";
import "./style.css";
import WeekPicker from "./WeekPicker/WeekPicker";
import moment from "moment";
import CalendarIcon from "../../assets/icon/IconCalendar.svg";
import _ from "lodash";
import { FilterJumlahSurvei, FilterMaxPilihan } from "./dropdownFilter";
import { listProvinsi } from "./const";
import ModalCalendar from "../../components/Admin/Shared/Popup/Calendar";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import Skeleton from "react-loading-skeleton";
function DuplikatSurveyAdmin(props) {
  const dataSurvey = props.location.state;
  const idsProv = listProvinsi.filter((prov) => dataSurvey.proposed_provinsi.includes(prov.name));
  const mapProv = _.map(idsProv, "_id");
  const history = useHistory();
  const [namaSurvey, setNamaSurvey] = useState(dataSurvey._id);
  const [showProvinsiTambahan, setshowProvinsiTambahan] = useState(dataSurvey.proposed_provinsi);
  const [namaLokasi, setNamaLokasi] = useState(dataSurvey.proposed_provinsi);
  const [modalTgl, setModalTgl] = useState(false);
  const [bool, setBool] = useState(false);
  const [message, setMessage] = useState("");
  const [loadDate, setLoadDate] = useState(false);
  //success alert
  const [successSubmitted, setSuccessSubmitted] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [optionLength, setOptionLength] = useState([]);

  //modalTambahProvinsi
  const [modalTambahProvinsi, setModalTambahProvinsi] = useState(false);
  const toggleModalTambahProvinsi = () => {
    setModalTambahProvinsi(!modalTambahProvinsi);
  };
  const [idProvince, setIdProvince] = useState(mapProv);
  const handleSelectedProvince = (province, id) => {
    setIdProvince(id);
    setNamaLokasi(province);
    setshowProvinsiTambahan(province);
  };

  const [dateSelected, setDateSelected] = useState(
    moment(dataSurvey.proposed_date[0], "DD-MM-YYYY").utc()
  );
  const [selectedDate, setSelectedDate] = useState(
    `${dataSurvey.proposed_date[0][0]} - ${
      dataSurvey.proposed_date[0][dataSurvey.proposed_date[0].length - 1]
    }`
  );
  const [tanggal, setTanggal] = useState(dataSurvey.proposed_date);

  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };
  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //throw data to check availability
    // setStartDate(startDate.format("DD-MM-YYYY"));
    // setEndDate(endDate.format("DD-MM-YYYY"));
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };
  // const handleSelectedDate = (date) => {
  //   let startDate = moment(addDays(startOfWeek(date), 1));
  //   let endDate = moment(addDays(endOfWeek(date), 1));
  //   //data for
  //   setSelectedDate(`${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`);
  //   setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  // };
  //clear
  const handleClear = () => {
    setNamaSurvey("");
    setTanggal("");
    setDateSelected(moment.utc(new Date()));
    setSelectedDate("");
    setNamaLokasi([]);
    setshowProvinsiTambahan("");
  };

  ///Handle for Pertanyaan
  const [loading, setLoading] = useState(true);
  const [inputList, setInputList] = useState([]);
  const [jp, setJp] = useState(dataSurvey.questions.length);
  useEffect(() => {
    if (dataSurvey.questions.length > 0) {
      setLoading(false);
    }
  }, [dataSurvey]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleChoice = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    const listUpdate = inputList;
    const listClear = [""];
    listUpdate[index].jawaban = listClear;
    setInputList(listUpdate);
    setInputList(list);
  };
  //handle max answer choices
  const handleMax = (val, index, type) => {
    const list = [...inputList];
    if (type == "checkbox") {
      list[index].maxPilihan = val.toString();
    } else {
      list[index].maxPilihan = "1";
    }
    setInputList(list);
  };

  ///Handle for Jawaban
  const handleInputChangeJawab = (e, index, i) => {
    const listUpdate = inputList;
    const { value } = e.target;
    const listInsert = [...inputList[i].jawaban];
    listInsert[index] = value;
    listUpdate[i].jawaban = listInsert;
    setBool(!bool);
    setInputList(listUpdate);
  };
  const handleRemoveClickJawab = (index, i) => {
    const listDelete = inputList;
    const list = [...inputList[i].jawaban];
    list.splice(index, 1);
    listDelete[i].jawaban = list;
    setInputList(listDelete);
    setBool(!bool);
  };

  const handleAddClickJawab = (index, i) => {
    if (index > 5) return;
    const listUpdate = inputList;
    const listAdd = [...inputList[i].jawaban, ""];
    listUpdate[i].jawaban = listAdd;
    setBool(!bool);
    setInputList(listUpdate);
  };
  // useEffect(() => {
  //   handleSelectedDate(dateSelected._d);
  // }, [dateSelected]);

  useEffect(() => {
    let lngthEachQuestion = inputList.map((data) => data.jawaban.length.toString());
    setOptionLength(lngthEachQuestion);
  }, [inputList, bool]);

  const handleAddSurvey = () => {
    let idUser = window.localStorage.getItem("idUser");
    let questionList = _.chain(dataSurvey.questions)
      .map((x) => x.question)
      .value();
    let answerList = _.chain(dataSurvey.questions)
      .map((x) => x.option)
      .value();
    let maxList = _.chain(dataSurvey.questions)
      .map((x) => x.max)
      .value();
    let listTypeQuestion = _.chain(dataSurvey.questions)
      .map((x) => {
        return x.type ? x.type : x.type === "dropdown" ? "dropdown" : "essay";
      })
      .value();
    let optionLength = _.chain(dataSurvey.questions)
      .map((x) => x.option.length.toString())
      .value();
    let param = {
      tanggal: tanggal,
      questionLength: jp,
      option: answerList.flat(),
      optionLength: optionLength.flat(),
      question: questionList,
      type: listTypeQuestion,
      lokasi: namaLokasi,
      userId: idUser,
      max: maxList,
      templateName: namaSurvey,
    };
    API.postAddSurvey(param)
      .then((res) => {
        setModalLoading(true);
        if (res.data.success) {
          setModalLoading(true);
          new Promise(
            () => {
              setSuccessSubmitted("success");
              setMessage(res.data.data);
            },
            setTimeout(() => {
              history.push("/admin/survey");
            }, 3000)
          );
        } else {
          setModalLoading(true);
          new Promise(
            () => {
              setSuccessSubmitted("fail");
            },
            setTimeout(() => {
              setModalLoading(false);
            }, 3000)
          );
        }
      })
      .catch((e) => {
        setModalLoading(true);
        new Promise(
          () => {
            setSuccessSubmitted("fail");
          },
          setTimeout(() => {
            setModalLoading(false);
          }, 3000)
        );
      });
  };

  //calendar modal
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };
  const handleNamaSurvey = (e) => {
    setNamaSurvey(e.target.value);
  };
  const FormDuplicate = () => {
    let questionsLength = dataSurvey.questions.length;
    return (
      <>
        <Row>
          <Col
            xs={{ size: "6", offset: "3" }}
            style={{ paddingBottom: "10px", paddingTop: "20px" }}>
            <Label for="titleArticle">Nama Survei</Label>
            <Input
              autoFocus
              type="text"
              name="text"
              id="judul"
              placeholder="Nama Survei"
              className="inputWrapper"
              value={namaSurvey}
              onChange={(e) => handleNamaSurvey(e)}
            />
          </Col>

          <Col xs={{ size: "6", offset: "3" }} style={{ paddingBottom: "10px" }}>
            <Label for="titleArticle">Provinsi</Label>
            <Button
              color=""
              onClick={toggleModalTambahProvinsi}
              id="provinsi"
              className="inputWrapper btnTambahProvAdmin">
              <p
                className={
                  showProvinsiTambahan.length === 0
                    ? "selectedDatePlaceholder"
                    : "selectedDateAdmin"
                }>
                {showProvinsiTambahan.length !== 0
                  ? showProvinsiTambahan.id !== ""
                    ? showProvinsiTambahan.sort().join(", ")
                    : ""
                  : "Pilih Provinsi"}
              </p>
            </Button>
            <ModalTambahProvSurvei
              isLoadDate={setLoadDate}
              existProv={showProvinsiTambahan}
              modalTambahProvinsi={modalTambahProvinsi}
              toggleModalTambahProvinsi={toggleModalTambahProvinsi}
              selectedProvince={handleSelectedProvince}
            />
          </Col>
          <Col xs={{ size: "6", offset: "3" }} style={{ paddingBottom: "10px" }}>
            <Label for="titleArticle">Tanggal Survei Tayang</Label>
            <Button
              color=""
              onClick={toggleModalCalendar}
              id="tanggal"
              className="inputWrapper btnTanggalAdmin">
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <p
                  className={dateSelected === "" ? "selectedDatePlaceholder" : "selectedDateAdmin"}>
                  {dateSelected === "" ? "Pilih Tanggal" : selectedDate}
                </p>

                {/* <WeekPicker
                  provSelected={idProvince}
                  selected={dateSelected._d}
                  initialDate={dataSurvey.proposed_date[0]}
                  focusing={modalTgl}
                  setModal={setModalTgl}
                  setDate={setDateSelected}
                /> */}
                {!loadDate ? <img src={CalendarIcon} /> : <span>Loading...</span>}
              </div>
            </Button>
            <ModalCalendar
              provSelected={mapProv}
              modalCalendar={modalCalendar}
              toggleModalCalendar={toggleModalCalendar}
              selectedDate={handleSelectedDate}
            />
          </Col>
          <Col xs={{ size: "6", offset: "3" }} style={{ paddingBottom: "10px" }}>
            <Label for="titleArticle">Jumlah Pertanyaan</Label>
            <FilterJumlahSurvei
              type={"Pilih Jumlah Survei"}
              filter={questionsLength}
              clicked={() => {
                return;
              }}
              list={Array.from({ length: questionsLength }, (_, i) => i + 1)}
            />
          </Col>
        </Row>
        {dataSurvey.questions.map((x, i) => {
          return (
            <div key={i}>
              <Row>
                <Col
                  xs={{ size: "1", offset: "1" }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    color: "darkblue",
                  }}>
                  <div>{i + 1}</div>
                </Col>
                <Col xs={{ size: "9" }} style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                  <Input
                    disabled={true}
                    name="pertanyaan"
                    value={x.question}
                    onChange={(e) => handleInputChange(e, i)}
                    placeholder="Pertanyaan"
                    className="inputWrapper"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ size: "1", offset: "1" }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    color: "darkblue",
                  }}>
                  <div></div>
                </Col>
                <Col xs={{ size: "7" }}>
                  <div>
                    {x.type === "essay" ? (
                      <div></div>
                    ) : x.type === "radio" ? (
                      x.option.map((value, index) => {
                        return (
                          <div key={index} className="btn-box  mt-2">
                            <Row>
                              <Col
                                xs="1"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <span className="mdi mdi-18px mdi-radiobox-blank"></span>
                              </Col>
                              <Col xs="11">
                                <Input
                                  disabled={true}
                                  name="jawaban"
                                  value={value}
                                  onChange={(e) => handleInputChangeJawab(e, index, i)}
                                  placeholder="Jawaban"
                                  className="inputWrapper"
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      })
                    ) : x.type === "checkbox" ? (
                      x.option.map((value, index) => {
                        return (
                          <div key={index} className="btn-box  mt-2">
                            <Row>
                              <Col
                                xs="1"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <span className="mdi mdi-18px mdi-checkbox-blank-outline"></span>
                              </Col>
                              <Col xs="11">
                                <Input
                                  disabled={true}
                                  name="jawaban"
                                  value={value}
                                  onChange={(e) => handleInputChangeJawab(e, index, i)}
                                  placeholder="Jawaban"
                                  className="inputWrapper"
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      })
                    ) : (
                      x.option.map((value, index) => {
                        return (
                          <div key={index} className="btn-box mt-2">
                            <Input
                              disabled={true}
                              name="jawaban"
                              value={value}
                              onChange={(e) => handleInputChangeJawab(e, index, i)}
                              placeholder="Jawaban"
                              className="inputWrapper"
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                </Col>
                <Col xs="2" className=" mt-2">
                  <Col xs="12">
                    <Input
                      disabled={true}
                      name="type"
                      onChange={(e) => handleChoice(e, i)}
                      type="select"
                      placeholder="Choose Type"
                      className="inputWrapper"
                      value={x.type}
                      style={{ paddingRight: "10px", color: "darkblue" }}>
                      <option value="essay">Essay</option>
                      <option value="radio">Pilihan Ganda</option>
                      <option value="checkbox">Checkbox</option>
                      <option value="dropdown">Dropdown</option>
                    </Input>
                  </Col>
                  {x.type == "checkbox" ? (
                    <Col className="mt-1" xs="12">
                      <FilterMaxPilihan
                        indexSurvei={i}
                        typeSurvei={x.type ? x.type : ""}
                        type={"Maks Pilihan"}
                        filter={x.max}
                        clicked={handleMax}
                        list={Array.from({ length: x.max }, (_, i) => i + 1)}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
        <Row style={{ paddingTop: "21px" }}>
          <Col xs={{ size: "2", offset: "4" }}>
            <Button onClick={handleClear} color="" className="buttonClearSurvei">
              Clear
            </Button>
          </Col>
          <Col xs="2">
            <Button
              color=""
              onClick={() => {
                handleAddSurvey();
              }}
              className="buttonBuatSurvei">
              Duplikat Survei
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {modalLoading ? (
        successSubmitted == "success" ? (
          <SweetAlert title={message} success showConfirm={false} onConfirm={""} />
        ) : successSubmitted == "fail" ? (
          <SweetAlert
            title="Survey Tidak Berhasil Dibuat"
            error
            showConfirm={false}
            onConfirm={""}
          />
        ) : (
          <SweetAlert
            showConfirm={false}
            onConfirm={""}
            title="Mohon Tunggu"
            style={{
              padding: "3em",
              borderRadius: "20px",
            }}>
            <Spinner
              color="danger"
              style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
              type="grow"
            />
            <div>Survey sedang dibuat</div>
          </SweetAlert>
        )
      ) : null}
      <div className="page-content">
        <header>
          <Header title="Duplikat Survei" btn={true} btntype="backSurvey" btntext="Survei" />
        </header>
        <main>
          <div className="container-fluid" style={{ paddingBottom: "100px" }}>
            {loading ? <Skeleton count={10} /> : <FormDuplicate />}
          </div>
        </main>
      </div>
    </>
  );
}
// const mapStateToProps = () => {
//     return{}
// }

// export default connect(mapStateToProps,{setDummySurvey})(DuplikatSurveyAdmin);
export default DuplikatSurveyAdmin;
