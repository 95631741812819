import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const BuatTicketSupportCenterAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/BuatTiket/SupportCenter" /* webpackChunkName: "BuatTicketSupportCenterAsync" */
    ),
  loading: Loading,
});

export default BuatTicketSupportCenterAsync;
