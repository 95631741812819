import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardTitle, CardBody, Input, Form, Button, Spinner } from "reactstrap";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import DownWhite from "../../assets/images/backwhite.png";
import { MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import "./style.css";
import Skeleton from "react-loading-skeleton";
import API from "../../services";
import Header from "../../components/VerticalLayout/Header";
import moment from "moment";
import localization from "moment/locale/id";
// import XLSX from "xlsx";
import IconUpload from "../../assets/icon/IconUpload.svg";
import IconDownload from "../../assets/icon/IconDownload.svg";
import TableSiteChecker from "../../components/Admin/Table/TableSiteChecker";
import FormatBulk from "../../assets/Format_Download_Excel_Site_Checker.xlsx";
import DownloadXLS from "../../components/Admin/Download/SiteCheckerBulk/DownloadXLS/XLS";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

moment.updateLocale("id", localization);

const SiteChecker = (props) => {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [fourth, setFourth] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emptyInput, setEmptyInput] = useState(false);
  const [dataIP, setDataIP] = useState([]);
  const [dataSiteStats, setDataSiteStats] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [notIntegrated, setNotIntegrated] = useState(false);
  const [dataIPAlter, setDataIPAlter] = useState("");
  const [status, setStatus] = useState("");
  const username = localStorage.getItem("users");

  function dataSite(tanggalAwal, waktuAwal, tanggalAkhir, waktuAkhir) {
    return { tanggalAwal, waktuAwal, tanggalAkhir, waktuAkhir };
  }

  const hiddenFileInput = useRef(null);
  const hiddenDownloadFile = useRef(null);

  const [loading, setLoading] = useState(false);
  const [dataBulk, setDataBulk] = useState([]);

  const onChangeFileUpload = (e) => {
    setLoading(true);
    const bodyFormData = new FormData();

    bodyFormData.append("username", username);
    bodyFormData.append("file", e.target.files[0]);

    API.postBulkSC(bodyFormData)
      .then((values) => {
        setLoading(false);
        setDataBulk(values.data.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleClickDownload = (event) => {
    hiddenDownloadFile.current.click();
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFormInput = (e) => {
    let { value, max, name } = e.target;
    if (value === "0") {
      value = "0";
    } else if (!Number(value)) {
      return;
    } else {
      value = Math.min(Number(max), Number(value));
    }
    if (name === "first") {
      setFirst(value);
    } else if (name === "second") {
      setSecond(value);
    } else if (name === "third") {
      setThird(value);
    } else if (name === "fourth") {
      setFourth(value);
    }
  };

  const onKeyDown = (e) => {
    // This would have the value set regardless of hitting backspace
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (e.target.value.length == 1) {
        if (e.target.name === "first") {
          setFirst("");
        } else if (e.target.name === "second") {
          setSecond("");
        } else if (e.target.name === "third") {
          setThird("");
        } else if (e.target.name === "fourth") {
          setFourth("");
        }
      }
    }
  };

  const onButtonClick = () => {
    if (first === "" || second === "" || third === "" || fourth === "") {
      setEmptyInput(true);
    } else {
      setEmptyInput(false);
      setIsLoading(true);
      API.postSiteChecker({ ipAddress: `${first}.${second}.${third}.${fourth}` })
        .then((res) => {
          const json = res.data;
          let dataSiteVal = [];

          json.isp == "0" ? setNotFound(true) : setNotFound(false);
          if (typeof json.hasil_siteStats[0] === "undefined") {
            dataSiteVal.push(dataSite("", "", "", ""));
            setDataSiteStats(dataSiteVal);
            setNotIntegrated(true);
          } else {
            setNotIntegrated(false);
            const ta = moment(json.hasil_siteStats[0].tanggal_pertama_akses).format("DD MMMM YYYY");
            const wa = moment(json.hasil_siteStats[0].tanggal_pertama_akses).format("HH:mm:ss");
            const tw = moment(json.hasil_siteStats[0].tanggal_terakhir_akses).format(
              "DD MMMM YYYY"
            );
            const ww = moment(json.hasil_siteStats[0].tanggal_terakhir_akses).format("HH:mm:ss");
            dataSiteVal = dataSite(ta, wa, tw, ww);
          }
          setDataSiteStats(dataSiteVal);
          setDataIPAlter(json.ipAlternatif);
          setDataIP(json.isp);
          setIsLoading(false);
          setStatus(json.status);
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  const clearTable = () => {
    setDataBulk([]);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };
  const tabStyle = {
    color: "#c42127",
  };
  const subTabStyle = {
    color: "#c42127",
    // borderBottom: "1px solid #c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };

  return (
    <>
      <div className="page-content">
        <Header title="Site Checker" />
        <div className="container-fluid" style={{ paddingBottom: "100px" }}>
          <Row>
            <Col sm="12" md={{ size: 8, offset: 2 }}>
              {/* Input Cardnya */}
              <center>
                <MDBNav
                  classicTabs
                  style={{
                    flexWrap: "wrap",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <div className="wrapperTab">
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        style={activeTab === "1" ? tabStyle : {}}
                        className="tabMenu waves-light"
                        active={activeTab === "1"}
                        onClick={() => handleChangeTab("1")}>
                        SINGLE SEARCH
                      </MDBNavLink>
                      <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        style={activeTab === "2" ? tabStyle : {}}
                        className="tabMenu"
                        active={activeTab === "2"}
                        onClick={() => handleChangeTab("2")}>
                        BULK SEARCH
                      </MDBNavLink>
                      <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                    </MDBNavItem>
                  </div>
                </MDBNav>
              </center>
              <Card style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "5px" }}>
                {activeTab === "1" ? (
                  <div>
                    <CardTitle style={{ textAlign: "center", paddingTop: "20px" }}>
                      Cari IP Address
                    </CardTitle>
                    <CardBody>
                      <Form>
                        <div className="input-container-sitecheck">
                          <Row>
                            <div className="space-empty"></div>
                            <Col xs="2" sm="2" md="2" style={{ padding: "0px" }}>
                              <Input
                                className="input-ip"
                                maxLength="3"
                                name="first"
                                onChange={handleFormInput}
                                type="text"
                                max="255"
                                value={first}
                                onKeyDown={onKeyDown}></Input>
                            </Col>
                            <Col xs="1" sm="1" md="1" className="divider-sitecheck">
                              <div className="divider-sitecheck">|</div>
                            </Col>
                            <Col xs="2" sm="2" md="2" style={{ padding: "0px" }}>
                              <Input
                                className="input-ip"
                                maxLength="3"
                                name="second"
                                onChange={handleFormInput}
                                type="text"
                                max="255"
                                value={second}
                                onKeyDown={onKeyDown}></Input>
                            </Col>
                            <Col xs="1" sm="1" md="1" className="divider-sitecheck">
                              <div className="divider-sitecheck">|</div>
                            </Col>
                            <Col xs="2" sm="2" md="2" style={{ padding: "0px" }}>
                              <Input
                                className="input-ip"
                                maxLength="3"
                                name="third"
                                onChange={handleFormInput}
                                type="text"
                                max="255"
                                value={third}
                                onKeyDown={onKeyDown}></Input>
                            </Col>
                            <Col xs="1" sm="1" md="1" className="divider-sitecheck">
                              <div>|</div>
                            </Col>
                            <Col xs="2" sm="2" md="2" style={{ padding: "0px" }}>
                              <Input
                                className="input-ip"
                                maxLength="3"
                                name="fourth"
                                onChange={handleFormInput}
                                type="text"
                                max="255"
                                value={fourth}
                                onKeyDown={onKeyDown}></Input>
                            </Col>
                            <div className="space-empty"></div>
                          </Row>
                        </div>
                      </Form>
                      <Col
                        xs="12"
                        md={{ size: 4, offset: 4 }}
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        <Button className="button-sitecheck" onClick={onButtonClick}>
                          Cari
                        </Button>
                        {emptyInput ? (
                          <div style={{ color: "red", textAlign: "center", paddingTop: "10px" }}>
                            Mohon untuk mengisi IP terlebih dahulu
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Col>
                    </CardBody>
                  </div>
                ) : (
                  <div>
                    <Row>
                      <Col xs="6">
                        <CardTitle style={{ textAlign: "center", paddingTop: "20px" }}>
                          Download Format Excel
                        </CardTitle>
                        <CardBody>
                          <Col xs="12" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            <Button
                              color=""
                              onClick={handleClickDownload}
                              className="btnStyleSiteDownload btnSiteChecker btn btn-rounded"
                              style={{ backgroundColor: "white" }}>
                              <img
                                className="IconDownload"
                                src={IconDownload}
                                style={{ paddingRight: "10px" }}></img>
                              {`${"    "}`}Format
                            </Button>
                            <a
                              ref={hiddenDownloadFile}
                              href={FormatBulk}
                              download="Template_Upload_Bulk_Site_Checker.xlsx"
                              target="_blank"
                            />
                          </Col>
                        </CardBody>
                      </Col>
                      <Col xs="6">
                        <CardTitle style={{ textAlign: "center", paddingTop: "20px" }}>
                          Upload File
                        </CardTitle>
                        <CardBody>
                          <Col xs="12" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <Spinner
                                  color=""
                                  style={{ color: "#2c3280", marginRight: "10px" }}
                                />
                                Mengunggah File...
                              </div>
                            ) : (
                              <div>
                                <Button
                                  color=""
                                  className="btnStyleSiteUpload btnSiteChecker btn btn-rounded"
                                  onClick={handleClick}>
                                  <img className="IconUpload" src={IconUpload}></img>
                                  {`${"    "}`}Upload
                                </Button>
                                <input
                                  type="file"
                                  ref={hiddenFileInput}
                                  onChange={onChangeFileUpload}
                                  style={{ display: "none" }}
                                  accept={".xlsx, .xls, .xlsm"}
                                />
                              </div>
                            )}
                          </Col>
                        </CardBody>
                      </Col>
                    </Row>
                  </div>
                )}
              </Card>
            </Col>

            {/* Isiannya */}
            {activeTab === "1" ? (
              isLoading ? (
                <Col xs="12" sm="6" md={{ size: 8, offset: 2 }}>
                  <Skeleton count={11} />
                </Col>
              ) : notFound ? (
                <Col xs="10" sm="5" md={{ size: 8, offset: 2 }}>
                  <center>IP tidak ditemukan</center>
                </Col>
              ) : (
                <Col
                  lg={{ size: "8", offset: "4" }}
                  xs={{ size: 12 }}
                  sm={{ size: 8, offset: 2 }}
                  md={{ size: 5, offset: 4 }}>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      IP Address
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataIP.ip_address === "undefined" ? "No Data" : dataIP.ip_address}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Subnet IP Terhubung
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {dataIP.length === 0 ? "No Data" : dataIPAlter}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Nama ISP
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataIP.nama_isp === "undefined" ? "No Data" : dataIP.nama_isp}
                    </Col>
                  </Row>
                  <br />
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Provinsi
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataIP.provinsi === "undefined" ? "No Data" : dataIP.provinsi}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Kabupaten/Kota
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataIP.kabupaten_kota === "undefined"
                        ? "No Data"
                        : dataIP.kabupaten_kota}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Nama Lokasi AI
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataIP.nama_lokasi_baru === "undefined"
                        ? "No Data"
                        : dataIP.nama_lokasi_baru}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Longitude Latitude
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataIP.longitude === "undefined" ||
                      typeof dataIP.latitude === "undefined"
                        ? "No Data"
                        : [dataIP.longitude, dataIP.latitude].join(" ")}
                    </Col>
                  </Row>
                  <br />
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Tanggal Integrasi
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataSiteStats.tanggalAwal === "undefined" ||
                      dataSiteStats.tanggalAwal === ""
                        ? "No Data"
                        : dataSiteStats.tanggalAwal}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Waktu Integrasi
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataSiteStats.waktuAwal === "undefined" ||
                      dataSiteStats.waktuAwal === ""
                        ? "No Data"
                        : dataSiteStats.waktuAwal}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Tanggal Terakhir Akses
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataSiteStats.tanggalAkhir === "undefined" ||
                      dataSiteStats.tanggalAkhir === ""
                        ? "No Data"
                        : dataSiteStats.tanggalAkhir}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Waktu Terakhir Akses
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {typeof dataSiteStats.waktuAkhir === "undefined" ||
                      dataSiteStats.waktuAkhir === ""
                        ? "No Data"
                        : dataSiteStats.waktuAkhir}
                    </Col>
                  </Row>
                  <br />
                  <Row className="rowData">
                    <Col lg="3" xs="5" md="5" className="the-title text-left">
                      Status
                    </Col>
                    <Col lg="1" xs="2" md="2" className="dualpoint text-left">
                      :
                    </Col>
                    <Col lg="3" xs="5" md="5" className="the-value text-left">
                      {dataIP.length === 0 ? "No Data" : status}
                    </Col>
                  </Row>
                </Col>
              )
            ) : (
              <div>
                {dataBulk.length === 0 ? (
                  <div></div>
                ) : (
                  <div
                    className="wrapperAction"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "3%",
                      paddingLeft: "3%",
                      paddingBottom: "10px",
                    }}>
                    <div>
                      <Button className="button-sitecheck" onClick={clearTable}>
                        Clear Table
                      </Button>
                    </div>
                    <div
                      className="wrapperDropdownResponsive"
                      style={{
                        width: "25%",
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "0.75%",
                      }}>
                      <MDBDropdown
                        style={{ width: "100% !important" }}
                        className="dropdown-download-doc">
                        <MDBDropdownToggle
                          caret
                          color="primary"
                          className="dropdown-download font-weight-bold">
                          <div className="wrapper-selected">Download</div>
                          <div>
                            <img src={DownWhite} alt="dropdown-icons" />
                          </div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-menus dropdown-show">
                          <MDBDropdownItem>
                            <ReactHTMLTableToExcel
                              id="TableXLS"
                              className="DownloadTableXLSDropdown"
                              table="DownloadTableXLS"
                              filename={`Site_Checker_Bulk_${moment(Date()).format(
                                "DD_MMMM_YYYY_HH:mm:ss"
                              )}`}
                              sheet="users"
                              buttonText="XLS"
                            />
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </div>
                  </div>
                )}
                <DownloadXLS
                  id="DownloadTableXLS"
                  className="DownloadTableXLS"
                  data={dataBulk}
                  activeTab={activeTab}
                />
                <TableSiteChecker dataBulk={dataBulk} />
              </div>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default SiteChecker;
