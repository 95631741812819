import React, { useState } from "react";

// COMPONENT
import FilterByJudul from "./FilterByJudul";
import CardGender from "./CardGender";
import CardJumlahPengunjung from "./CardJumlahPengunjung";
import CardPieChart from "./CardPieChart";
import CardPersebaranUmur from "./CardPersebaranUmur";
import TableJumlahPengisiPerProv from "../Tabel/SummaryHasilSurvey";

import { Col, Modal, ModalBody, Button, Spinner } from "reactstrap";
import { MDBModalHeader, MDBNavLink } from "mdbreact";
import moment from "moment";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "../Download/XLS/DownloadDetailSummary";
import DownloadXLSProv from "../Download/XLS/DownloadDetailSummaryProvinsi";

import style from "../style.module.css";

// MODAL DOWNLOAD DETAIL
import ModalDownloadAllDetail from "../Modal/ModalDownloadDetailSummarySurvey";

import "../styles.css";

export default function DemografiPengunjungSurvey(props) {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [listProvince, setListProvince] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showComponent, setShowComponent] = useState(false);

  // MODAL DOWNLOAD
  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);
  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  const [refresh, setRefresh] = useState(false);
  const fnRefresh = () => {
    setRefresh(!refresh);
  };
  // MODAL DOWNLOAD
  const [modalDownloadXLSProv, setModalDownloadXLSProv] = useState(false);
  const toggleDownloadXLSProv = () => {
    setModalDownloadXLSProv(!modalDownloadXLSProv);
  };

  // MODAL DOWNLOAD ALL DETAIL DEMOGRAFI SURVEI
  const [modalDownloadXLSAllDetailSurvei, setModalDownloadXLSAllDetailSurvei] = useState(false);
  const toggleDownloadXLSAllDetailSurvei = () => {
    setModalDownloadXLSAllDetailSurvei(!modalDownloadXLSAllDetailSurvei);
    fnRefresh();
  };

  // console.log("list provinsi nihhh", listProvince);

  return (
    <div className="container-fluid" style={{ marginBottom: "100px" }}>
      {/* MODAL DOWNLOAD DETAIL */}
      <ModalDownloadAllDetail
        toggleDetail={toggleDownloadXLSAllDetailSurvei}
        modalDetail={modalDownloadXLSAllDetailSurvei}
        date={date}
        title={title}
        listProvince={listProvince}
        refresh={refresh}
      />
      <FilterByJudul
        setData={setData}
        setLoading={setLoading}
        setShowComponent={setShowComponent}
        activeTab={props.activeTab}
        showComponent={showComponent}
        setDataSave={props.setDataSave}
        setTitle={setTitle}
        setDate={setDate}
        setListProvince={setListProvince}
      />
      {showComponent ? (
        loading ? (
          <div className="d-flex justify-content-center mt-5">
            {" "}
            <Spinner type="grow" />{" "}
          </div>
        ) : data !== null ? (
          <>
            <div>
              <Modal size="md" isOpen={modalDownloadXLS} toggle={toggleDownloadXLS} centered={true}>
                <MDBModalHeader toggle={toggleDownloadXLS}>
                  <span className={style.headText}>Download XLS</span>
                </MDBModalHeader>
                <ModalBody>
                  <center className="container-popup">
                    <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                    <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                      Batal
                    </Button>
                    <Button color="" className={style.btn_confirm_xls} onClick={toggleDownloadXLS}>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className={style.DownloadTableXLS}
                        table="DownloadXLSSummary"
                        filename={`summary-survey ${moment(new Date()).format("DD-MM-YYYY")}`}
                        sheet="summary survey"
                        buttonText="Download"
                      />
                    </Button>
                  </center>
                </ModalBody>
              </Modal>
              <Col
                className="my-1"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}>
                <MDBNavLink to="#" className={style.textDownload}>
                  Download
                </MDBNavLink>
                <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                  XLS
                </MDBNavLink>
                <Button
                  style={{ backgroundColor: "#2c3280", borderRadius: "15px" }}
                  onClick={toggleDownloadXLSAllDetailSurvei}>
                  Detail Pengunjung Mengisi Survey
                </Button>
              </Col>
              <DownloadXLS
                id="DownloadXLSSummary"
                className="DownloadTableXLS"
                data={data}
                title={title}
              />
            </div>
            <CardJumlahPengunjung loading={loading} data={data} />
            <CardGender loading={loading} data={data} />
            <CardPieChart loading={loading} data={data} />
            <CardPersebaranUmur loading={loading} data={data} />
            <div>
              <Modal
                size="md"
                isOpen={modalDownloadXLSProv}
                toggle={toggleDownloadXLSProv}
                centered={true}>
                <MDBModalHeader toggle={toggleDownloadXLSProv}>
                  <span className={style.headText}>Download XLS</span>
                </MDBModalHeader>
                <ModalBody>
                  <center className="container-popup">
                    <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                    <Button color="" className={style.btn_reject} onClick={toggleDownloadXLSProv}>
                      Batal
                    </Button>
                    <Button
                      color=""
                      className={style.btn_confirm_xls}
                      onClick={toggleDownloadXLSProv}>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className={style.DownloadTableXLS}
                        table="DownloadTableXLSSurveySummaryProv"
                        filename={`summary-survey-provinsi ${moment(new Date()).format(
                          "DD-MM-YYYY"
                        )}`}
                        sheet="summary survey provinsi"
                        buttonText="Download"
                      />
                    </Button>
                  </center>
                </ModalBody>
              </Modal>
              <Col
                className="mt-5"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}>
                <MDBNavLink to="#" className={style.textDownload}>
                  Download
                </MDBNavLink>
                <MDBNavLink onClick={toggleDownloadXLSProv} link to="#">
                  XLS
                </MDBNavLink>
              </Col>
              <DownloadXLSProv
                id="DownloadTableXLSSurveySummaryProv"
                className="DownloadTableXLS"
                data={data}
                title={title}
              />
            </div>
            <TableJumlahPengisiPerProv loading={loading} data={data} />
          </>
        ) : (
          <div> Tidak ada data</div>
        )
      ) : loading ? (
        <div className="d-flex justify-content-center mt-5">
          {" "}
          <Spinner type="grow" />{" "}
        </div>
      ) : (
        <div> Pilih Judul Survey yang ingin di tampilkan </div>
      )}
    </div>
  );
}
