import React, { useState, useRef } from 'react'
import styles from '../Assets/css/hubungi.module.css'
import Header from '../components/Header/Header'
import Email from '../Assets/image/IconEmail.svg'
import Phone from '../Assets/image/IconPhone.svg'
import WA from '../Assets/image/IconWA.svg'

import { Row, Col, Input, Form, Button, Container } from "reactstrap";

function HubungiPages() {
    const myRef = useRef(null)
    const executeScroll = () => {
        // console.log(myRef)
        myRef.current.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'});
    }

    const [nama, setNama] = useState('')
    const [tel, setTel] = useState('')
    const [email, setEmail] = useState('')
    const [pesan, setPesan] = useState('')
    const [emptyInput, setEmptyInput] = useState(false)

    const handleFormInput = (e) => {
        let { value, name } = e.target;
        const re = /^[0-9\b]+$/;
        if (name === 'nama') {
            setNama(value)
        } else if (name === 'tel'){
            if(value === '' || re.test(value)){
                setTel(value)
            }
        } else if (name === 'email'){
            setEmail(value)
        } else if (name === 'pesan'){
            setPesan(value)
        }
    }

    const onButtonClick = () => {
        if(nama === '' || tel === '' || email === '' || pesan === ''){
            setEmptyInput(true)
        }else{
            setEmptyInput(false)
            // console.log(nama + "\n" + tel + "\n" + email + "\n" + pesan)
        }
    }
    return (
        <div style={{backgroundColor:"white"}}>
            <div>
                <Header />
                <div className={styles.banner}>
                    <section>
                        <div className={styles.theText}>
                            <Row>
                                <Col xs="12" sm="12" md={{ size: "8", offset: "2" }}>
                                    <span className={styles.subText}>Hubungi Kami</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md={{ size: "8", offset: "2" }}>
                                    <span className={styles.secText}>Consectetur adipiscing elit. Sed eget eros vel dui ultrices vulputate non vel mi. Suspendisse potenti. Mauris pharetra non lacus et euismod.</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md={{ size: "8", offset: "2" }}>
                                    <Row>
                                        <Col xs="12" sm="4" md="4" style={{ paddingTop: "20px" }}>
                                            <Button onClick={executeScroll} className={styles.buttonHubungi}><img className={styles.iconImageHubungi} src={Email} alt="Email" />Bantuan Melalui Email</Button>
                                        </Col>
                                        <Col xs="6" sm="4" md="4" style={{ paddingTop: "20px" }}>
                                            <Button className={styles.buttonHubungi} href="tel:081221220840" target="_blank"><img className={styles.iconImageHubungi} src={Phone} alt="Phone" />081221220840</Button>
                                        </Col>
                                        <Col xs="6" sm="4" md="4" style={{ paddingTop: "20px" }}>
                                            <Button className={styles.buttonHubungi} href="https://wa.me/081221220840" target="_blank"><img className={styles.iconImageHubungi} src={WA} alt="WA" />081221220840</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
                <div className={styles.lastSec}>
                    <section>
                        <Row>
                            <Col xs="12" sm="12" md={{ size: "10", offset: "1" }} style={{padding:"0px"}}>
                                <div className={styles.secTit} ref={myRef}>Silahkan Kirim Pertanyaan Anda</div>
                                <Form>
                                    <Input className={styles.inputHubungi} name='nama' type='text' placeholder='Nama' onChange={handleFormInput}></Input>
                                    <Input className={styles.inputHubungi} name='tel' type='tel' placeholder='Nomor Telepon' onChange={handleFormInput} value={tel}></Input>
                                    <Input className={styles.inputHubungi} name='email' type='text' placeholder='Email' onChange={handleFormInput}></Input>
                                    <Input className={styles.inputHubungiArea} name='pesan' type='textarea' placeholder='Pesan' onChange={handleFormInput}></Input>
                                </Form>
                                <Button className={styles.buttonKirimHubungi} onClick={onButtonClick}>Kirim</Button>
                                {
                                    emptyInput 
                                    ? <div style={{color:'red', textAlign:'center', paddingTop:'20px', marginBottom:"20px"}}>Mohon untuk melengkapi form terlebih dahulu</div>
                                    : <div style={{paddingBottom: "60px"}}></div>
                                }
                            </Col>
                        </Row>
                    </section>

                    <section style={{height:'100px'}}>
                        <Container className={styles.sectBorder}>
                        <div style={{marginTop:'10px', color:'#00438B', textAlign:'left'}}>Copyright 2020 All Rights Reserved</div>
                        </Container>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default HubungiPages