import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  let judul;
  switch (props.activeTab) {
    case "1":
      judul = "Data Unique Device";
      break;
    case "2":
      judul = "Data Site AI";
      break;
    case "3":
      judul = "Data Behaviour Site";
      break;
    default:
      break;
  }

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLS3" className="DownloadTableXLS">
      <thead>{`${judul}`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Nama Lokasi</th>
          <th>Provinsi</th>
          <th>Kabupaten</th>
          <th>Total DFP Unique</th>
          <th>Total Kunjungan</th>
          <th>Tanggal Integrasi</th>
          <th>Terakhir Diakses</th>
        </tr>
      </thead>
      <tbody>
        {props.data &&
          props.data.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data ? data.name : "-"}</td>

                <td>{data ? data.provinsi : "-"}</td>
                <td>{data ? data.kabupaten_kota : "-"}</td>
                <td>{data ? data.uniqdfp : "-"}</td>
                <td>{data ? data.jumlah : "-"}</td>
                <td>
                  {data
                    ? data.first
                      ? moment(data.first.tanggal_pembuatan, "YYYY-MM-DD").format("DD-MM-YYYY")
                      : "-"
                    : "-"}
                </td>
                <td>
                  {data
                    ? data.last
                      ? moment(data.last.tanggal_pembuatan, "YYYY-MM-DD").format("DD-MM-YYYY")
                      : "-"
                    : "-"}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
