import React, { useEffect, useState, useRef } from "react";
import { Container, Card, CardBody, Button, Row, Col } from "reactstrap";
import { MDBDataTable, MDBNavLink } from "mdbreact";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import ReactPaginate from "react-paginate";

import DownWhite from "../../../assets/images/backwhite.png";
import API from "../../../services";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import PostingPopup from "../Popup/Article/PostingPopup";
import StatusPopup from "../Popup/Article/StatusPopup";
import DeletePopup from "../Popup/Article/DeletePopup";
import BacaPopup from "../Popup/Article/BacaPopup";
import { useHistory } from "react-router-dom";
import IconCreate from "../../../assets/icon/IconCreate.svg";
import { withRouter } from "react-router";
import _ from "lodash";
import "./style.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { exportPDF } from "../Download/Article/DownloadPDF/PDF";
import DownloadXLS from "../Download/Article/DownloadXLS/XLS";
import Filter from "../../../components/Filter/filter";

function TableArticle(props) {
  let roleFromRouter = props
    ? props.location
      ? props.location.state
        ? props.location.state.from
          ? props.location.state.from
          : "-"
        : "-"
      : "-"
    : "-";
  let role = window.localStorage.getItem("role");

  const history = useHistory();
  const [dataRows, setDataRows] = useState([]);
  const [toModalPosting, setToModalPosting] = useState([]);
  const [toModalStatus, setToModalStatus] = useState([]);
  const [toModalDelete, setToModalDelete] = useState([]);
  const [toModalBaca, setToModalBaca] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalPosting, setModalPosting] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalBaca, setModalBaca] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageDisplay, setPageDisplay] = useState(10);

  const searchRef = useRef();

  useEffect(() => { }, [search]);

  //search
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };

  const handleDownloadPDF = () => {
    exportPDF(dataRows);
  };

  const togglePosting = (val) => {
    setToModalPosting(val);
    setModalPosting(!modalPosting);
  };

  const toggleStatus = (val) => {
    setToModalStatus(val);
    setModalStatus(!modalStatus);
  };

  const refreshPage = () => {
    setRefresh(!refresh);
    setLoading(false);
  };

  const toggleDelete = (val) => {
    setToModalDelete(val);
    setModalDelete(!modalDelete);
  };

  const toggleBaca = (val) => {
    setToModalBaca(val);
    setModalBaca(!modalBaca);
  };

  const getTableArticle = () => {
    API.getTableArticel()
      .then((response) => {
        // console.log(response.data)
        if (response.data.success === true) {
          var res = response.data.data;
          // console.log(res)
          setLoading(true);
          setDataRows(res);
        }
      })
      .catch(setLoading(true));
  };

  const getTableVideo = (params) => {
    API.getAllVideo(page, params)
      .then((response) => {
        // console.log(response.data)
        if (response.data.success === true) {
          var res = response.data.data;

          // console.log(res)
          setLoading(true);
          setDataRows(res);
          setPageCount(response.data.count);
          // setPageDisplay(10);
        }
      })
      .catch(setLoading(true));
  };

  const handleEdit = (val) => {
    // roleFromRouter === "adminartikel"
    if (props.typeContent !== "video") {
      role === "Artikel Admin"
        ? history.push({
          pathname: "/adminartikel/edit-article",
          state: val,
        })
        : history.push({
          pathname: "/admin/edit-article",
          state: val,
        });
    } else {
      role === "Artikel Admin"
        ? history.push({
          pathname: "/adminartikel/edit-video",
          state: val,
        })
        : history.push({
          pathname: "/admin/edit-video",
          state: val,
        });
    }

    // history.push({
    //   pathname: "/admin/edit-article",
    //   state: val
    // })
  };

  const handlePageClick = (data) => {
    //console.log("data from handle ==> ", data);
    //const searchValue = searchRef.current.value;
    setPage(data.selected + 1);
  };

  useEffect(() => {
    if (props.typeContent !== "video") {
      getTableArticle();
    } else {
      getTableVideo();
    }
    // return () => (isSubscribed = false);
  }, [refresh, page]);

  var { typeContent } = props;
  typeContent = typeContent
    ? typeContent.charAt(0).toUpperCase() + typeContent.slice(1)
    : "Artikel";

  const textBaca = typeContent !== "Video" ? "Baca" : "Lihat";

  var items = [];

  if (typeContent !== "Video") {
    if (dataRows.length > 0) {
      items = dataRows.filter((val) => {
        if (search === "") return val;
        const index = [
          val.judul,
          // tanggalDibuat: moment(val.tanggal_pembuatan).format('DD/MM/YYYY'),
          _.get(val, "tanggal_pembuatan", "-") !== "-"
            ? moment(val.tanggal_pembuatan).format("DD-MM-YYYY")
            : "-",
          _.get(val, "tanggal_posting", "-") !== "-"
            ? moment(val.tanggal_posting).format("DD-MM-YYYY")
            : "-",
          val.jumlahPembaca,
          val.status,
          val.like,
          val.dislike,
          val.shareFacebook ?? "0",
          val.shareTwitter ?? "0",
        ];
        if (
          Filter.byIndex({
            index: index,
            search: search,
          })
        ) {
          return val;
        }
      });
    }

    items = items.map((val, i) => ({
      no: i + 1,
      judul: val.judul,
      // tanggalDibuat: moment(val.tanggal_pembuatan).format('DD/MM/YYYY'),
      tanggalDibuat:
        _.get(val, "tanggal_pembuatan", "-") !== "-"
          ? moment(val.tanggal_pembuatan).format("DD-MM-YYYY")
          : "-",
      tanggalPosting:
        _.get(val, "tanggal_posting", "-") !== "-"
          ? moment(val.tanggal_posting).format("DD-MM-YYYY")
          : "-",
      jumlahPembaca: val.jumlahPembaca,
      status:
        val.status == "Non Aktif" ? (
          <p style={{ color: "#C42127" }}>{val.status}</p>
        ) : (
          <p style={{ color: "#2C3280" }}>{val.status}</p>
        ),
      like: val.like,
      dislike: val.dislike,
      facebook: val.shareFacebook ?? "0",
      twitter: val.shareTwitter ?? "0",
      action: [
        <Row xs="3" key={i}>
          <Col lg={{ size: 8, offset: 1 }} md="12" sm="12" xs="12" style={{ marginBottom: "10px" }}>
            {val.status == "Non Aktif" ? (
              <Button
                onClick={() => togglePosting(val)}
                style={{ backgroundColor: "#2C3280", width: "100%" }}>
                Posting
              </Button>
            ) : (
              <Button
                onClick={() => toggleBaca(val)}
                style={{ backgroundColor: "#55BA59", width: "100%" }}>
                {textBaca}
              </Button>
            )}
          </Col>
          <Col lg={{ size: 8, offset: 1 }} md="12" sm="12" xs="12">
            <Button
              onClick={() => handleEdit(val)}
              style={{ backgroundColor: "#E5BA4E", width: "100%", color: "#000" }}>
              Edit
            </Button>{" "}
          </Col>
          <Col lg={{ size: 8, offset: 1 }} md="12" sm="12" xs="12" style={{ marginTop: "10px" }}>
            {val.status === "Non Aktif" ? (
              <Button
                onClick={() => toggleDelete(val)}
                style={{ backgroundColor: "#DBDBDB", width: "100%" }}>
                Hapus
              </Button>
            ) : (
              <Button
                onClick={() => toggleStatus(val)}
                style={{ backgroundColor: "#C42127", width: "100%" }}>
                Non Aktif
              </Button>
            )}
          </Col>
        </Row>,
      ],
    }));

  } else {
    if (dataRows.length > 0) {
      items = dataRows.filter((val) => {
        if (search === "") return val;
        const index = [
          val.judul,
          // tanggalDibuat: moment(val.tanggal_pembuatan).format('DD/MM/YYYY'),
          _.get(val, "tanggal_pembuatan", "-") !== "-"
            ? moment(val.tanggal_pembuatan).format("DD-MM-YYYY")
            : "-",
          _.get(val, "tanggal_posting", "-") !== "-"
            ? moment(val.tanggal_posting).format("DD-MM-YYYY")
            : "-",
          // val.jumlahPembaca,
          val.status,
          val.like,
          val.dislike,
          val.shareFacebook ?? "0",
          val.shareTwitter ?? "0",
        ];
        if (
          Filter.byIndex({
            index: index,
            search: search,
          })
        ) {
          return val;
        }
      });
    }

    items = items.map((val, i) => ({
      no: i + 1,
      judul: val.judul,
      tipeVideo: val.tipeVideo,
      link: val.link,
      body: val.body,
      tanggalDibuat: moment(val.tanggal_pembuatan).format('DD/MM/YYYY'),
      tanggalDibuat:
        _.get(val, "tanggal_pembuatan", "-") !== "-"
          ? moment(val.tanggal_pembuatan).format("DD-MM-YYYY")
          : "-",
      tanggalPosting:
        _.get(val, "tanggal_posting", "-") !== "-"
          ? moment(val.tanggal_posting).format("DD-MM-YYYY")
          : "-",
      jumlahPembaca: val.jumlahPembaca,
      like: val.like,
      dislike: val.dislike,
      play: val.play,
      status:
        val.status == "Non Aktif" ? (
          <p style={{ color: "#C42127" }}>{val.status}</p>
        ) : (
          <p style={{ color: "#2C3280" }}>{val.status}</p>
        ),

      action: [
        <Row xs="3" key={i}>
          <Col lg={{ size: 8, offset: 1 }} md="12" sm="12" xs="12" style={{ marginBottom: "10px" }}>
            {val.status == "Non Aktif" ? (
              <Button
                onClick={() => togglePosting(val)}
                style={{ backgroundColor: "#2C3280", width: "100%" }}>
                Posting
              </Button>
            ) : (
              <Button
                onClick={() => toggleBaca(val)}
                style={{ backgroundColor: "#55BA59", width: "100%" }}>
                {textBaca}
              </Button>
            )}
          </Col>
          <Col lg={{ size: 8, offset: 1 }} md="12" sm="12" xs="12">
            <Button
              onClick={() => handleEdit(val)}
              style={{ backgroundColor: "#E5BA4E", width: "100%", color: "#000" }}>
              Edit
            </Button>{" "}
          </Col>
          <Col lg={{ size: 8, offset: 1 }} md="12" sm="12" xs="12" style={{ marginTop: "10px" }}>
            {val.status === "Non Aktif" ? (
              <Button
                onClick={() => toggleDelete(val)}
                style={{ backgroundColor: "#DBDBDB", width: "100%" }}>
                Hapus
              </Button>
            ) : (
              <Button
                onClick={() => toggleStatus(val)}
                style={{ backgroundColor: "#C42127", width: "100%" }}>
                Non Aktif
              </Button>
            )}
          </Col>
        </Row>,
      ],
    }));
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Tanggal Dibuat",
        field: "tanggalDibuat",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Tanggal Posting",
        field: "tanggalPosting",
        sort: "disabled",
        width: 200,
      },
      {
        label: typeContent !== "Video" ? "Jumlah Pembaca" : "Jumlah Played",
        field: typeContent !== "Video" ? "jumlahPembaca" : "play",
        style: { textAlign: "center" },
        sort: "disabled",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 200,
      },
      {
        label: [<span className="mdi mdi-thumb-up mdi-24px" style={{ color: "#55BA59" }}></span>],
        field: "like",
        sort: "disabled",
        width: 200,
      },
      {
        label: [<span className="mdi mdi-thumb-down mdi-24px" style={{ color: "#C42127" }}></span>],
        field: "dislike",
        sort: "disabled",
        width: 200,
      },
      {
        label: [<span className="mdi mdi-facebook mdi-24px" style={{ color: "black" }}></span>],
        field: "facebook",
        sort: "disabled",
        width: 200,
      },
      {
        label: [<span className="mdi mdi-twitter mdi-24px" style={{ color: "black" }}></span>],
        field: "twitter",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: items,
  };

  return (
    <div>
      <Container>
        <div>
          {loading == false ? (
            <div className="mt-2 mb-2">
              <Skeleton height={100} />{" "}
            </div>
          ) : (
            <Card
              style={{
                boxShadow: "0px 0px 10px #D3D3D3",
                paddingBottom: "30px",
                paddingTop: "20px",
              }}>
              <CardBody>
                <div className="row justify-content-between mb-2">
                  <h4 className="col-4">Daftar {typeContent} </h4>
                  <div className="col-4 text-right">
                    <Button
                      className="btnCreate btn btn-rounded"
                      onClick={() => {
                        if (role === "Artikel Admin") {
                          if (typeContent !== "Video") {
                            history.push("/adminartikel/create-article");
                          } else if (typeContent === "Video") {
                            history.push("/adminartikel/create-video");
                          }
                        } else {
                          if (typeContent !== "Video") {
                            history.push("/admin/create-article");
                          } else if (typeContent === "Video") {
                            history.push("/admin/create-video");
                          }
                        }
                      }}>
                      <img src={IconCreate} className="m-auto"></img> Buat {typeContent}
                    </Button>
                  </div>
                </div>
                {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  <MDBNavLink to="#" className="textDownload">
                    Download
                  </MDBNavLink>
                  <ReactHTMLTableToExcel
                    id="TableXLS"
                    className="DownloadTableXLS"
                    table="DownloadTableXLS"
                    filename={`Article`}
                    sheet="users"
                    buttonText="XLS"
                  />
                  <MDBNavLink onClick={handleDownloadPDF} link to="#">
                    PDF
                  </MDBNavLink>
                </div>
                <DownloadXLS id="DownloadTableXLS" className="DownloadTableXLS" data={dataRows} /> */}
                <div className="row justify-content-between mb-2">
                  <div className="d-flex ml-0 w-25">
                    <input
                      style={{ height: "4v" }}
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control inputWrapper"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>

                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "0.75%",
                    }}>
                    <MDBDropdown
                      style={{ width: "100% !important" }}
                      className="dropdown-download-doc">
                      <MDBDropdownToggle
                        caret
                        color="primary"
                        className="dropdown-download font-weight-bold">
                        <div className="wrapper-selected">Download</div>
                        <div>
                          <img src={DownWhite} alt="dropdown-icons" />
                        </div>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="dropdown-menus dropdown-show">
                        <MDBDropdownItem
                          style={{ color: "#556ee6" }}
                          className="text-center"
                          onClick={handleDownloadPDF}>
                          PDF
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                          <ReactHTMLTableToExcel
                            id="TableXLS"
                            className="DownloadTableXLSDropdown"
                            table="DownloadTableXLS"
                            filename={`Article`}
                            sheet="users"
                            buttonText="XLS"
                          />
                        </MDBDropdownItem>
                        <DownloadXLS
                          id="DownloadTableXLS"
                          className="DownloadTableXLS"
                          data={dataRows}
                        />
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </div>
                </div>
                {typeContent !== "Video" ? (
                  <MDBDataTable
                    id="tableArticle"
                    responsive
                    striped
                    searching={false}
                    displayEntries={false}
                    noBottomColumns={true}
                    entries={8}
                    data={data}
                    info={true}
                  />
                ) : (
                  ""
                )}
                {typeContent === "Video" ? (
                  <>
                    <MDBDataTable
                      id="tableArticle"
                      responsive
                      striped
                      searching={false}
                      displayEntries={false}
                      noBottomColumns={true}
                      entries={8}
                      data={data}
                      info={true}
                      disableRetreatAfterSorting
                      paging={false}
                    />
                    <div className="float-right">
                      <ReactPaginate
                        pageCount={Math.ceil(pageCount / pageDisplay)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        initialPage={page - 1} // INDEX
                        forcePage={page - 1}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        activeClassName="active"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"></ReactPaginate>
                    </div>
                    <div className="clearfix"></div>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          )}
        </div>
        <PostingPopup
          typeContent={props.typeContent}
          modal={modalPosting}
          toggle={togglePosting}
          data={toModalPosting}
        />
        <StatusPopup
          typeContent={props.typeContent}
          modal={modalStatus}
          toggle={toggleStatus}
          data={toModalStatus}
          refreshPage={() => refreshPage()}
        />
        <DeletePopup
          modal={modalDelete}
          typeContent={props.typeContent}
          toggle={toggleDelete}
          data={toModalDelete}
        />
        <BacaPopup
          typeContent={props.typeContent}
          modal={modalBaca}
          toggle={toggleBaca}
          data={toModalBaca}
        />
      </Container>
    </div>
  );
}

export default withRouter(TableArticle);
