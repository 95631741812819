import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Filter from "../../../../Filter/filter";

// ------------------REDUX---------------------
import { connect } from "react-redux";
import { detailData, setTabDetail } from "../../../../../store/actions";
// ------------------API-----------------------
import API from "../../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconCheck from "../../../../../assets/icon/IconCheck.svg";
import IconEmergency from "../../../../../assets/icon/IconEmergency.svg";
import noImage from "../../../../../assets/images/noImage.jpg";

// ------------------Component----------------
import ModalPreviewVideo from "../../../../ContentPartner/Popup/PreviewVideo";
import ModalPreviewEmergencyDoc from "../../../../ContentPartner/Popup/Konten/PreviewEmergencyDoc";

//dummy
import axios from "../../../../../config/api";
import dummy from "../../../../../data/dummy.json";
//lodash
import _ from "lodash";

const TableKontenContentPatnerVideo = (props) => {
  const [isReload] = useState(
    props
      ? props.location
        ? props.location.state
          ? props.location.state.reload
            ? props.location.state.reload
            : ""
          : ""
        : ""
      : ""
  );

  let history = useHistory();
  const searchRef = useRef();
  const [notif, setNotif] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsNotif, setRowsNotif] = useState([]);
  const [search, setSearch] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);

  const fetchAPIVideo = (notif) => {
    let id = window.localStorage.getItem("idUser");
    let param = {
      id: id,
      type: "content",
    };

    API.postKontenVideoCP(param)
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }

        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(res.data.data);
        } else {
          setRows(result);
          document.getElementById("tableVideoKontenCP").rows[1].style.transition = "0.8s";
          document.getElementById("tableVideoKontenCP").rows[1].style.backgroundColor = "#e6f3ff";
        }
      })
      .catch((e) => {
        history.push("/pages-500");
        setRows(null);
      });
  };

  useEffect(() => {
    // fetchAPIVideo(true);
    setRows(props.dataKonten);
  }, [props]);

  useEffect(() => {
    if (isReload == true) {
      fetchAPIVideo();
    }
  }, [isReload]);
  useEffect(() => {
    filterData(items);
  }, [search]);

  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      let statusKurator = data
        ? data.status_kurator
          ? data.status_kurator.length !== 0
            ? data.status_kurator[data.status_kurator.length - 1].status
              ? data.status_kurator[data.status_kurator.length - 1].status
              : "-"
            : "-"
          : "-"
        : "-";
      let status = data.status;
      let emergency = data.emergency;
      const statusSearched =
        status && status === "submitted"
          ? emergency
            ? "emergency"
            : statusKurator === "Lolos Kurasi"
            ? "Lolos Kurasi"
            : statusKurator === "Tidak Lolos Kurasi"
            ? "Tidak Lolos Kurasi"
            : statusKurator === "Revisi"
            ? "Revisi"
            : statusKurator === "Sudah Revisi"
            ? "Sudah Revisi"
            : statusKurator
          : status === "declined"
          ? "Ditolak"
          : status === "approved"
          ? "Disetujui"
          : status === "scheduled"
          ? "Tayang"
          : data.status == "declined"
          ? "ditolak"
          : data.status == "Terjadwal"
          ? "Terjadwal"
          : data.status == "submitted"
          ? "Baru"
          : data.status == "canceled"
          ? "Dibatalkan"
          : data.status == "pending"
          ? "pending"
          : data.status;

      const index = [
        data ? (data.details ? data.details.title : data.title) : "-",
        data.category,
        // data.proposed_duration,
        // data.list_provinsi.map((prov) => prov.name).toString(),
        data.proposed_date
          .map((date) => moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"))
          .toString(),
        statusSearched,
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }

  // const items =
  //   rows && rows.length > 0
  //     ? rows.filter((data) => {
  //         if (search === "") return data;
  //         else if (
  //           (data.details.title ? data.details.title : data.title)
  //             .toLowerCase()
  //             .includes(search.toLowerCase()) ||
  //           data.category.toLowerCase().includes(search.toLowerCase()) ||
  //           data.proposed_duration.toLowerCase().includes(search.toLowerCase()) ||
  //           data.list_provinsi
  //             .map((prov) => prov.name)
  //             .toString()
  //             .toLowerCase()
  //             .includes(search.toLowerCase()) ||
  //           data.proposed_date
  //             .map((date) => moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"))
  //             .toString()
  //             .toLowerCase()
  //             .includes(search.toLowerCase()) ||
  //           (data.status == "submitted"
  //             ? "submitted"
  //             : data.status == "approved"
  //             ? "approved"
  //             : data.status == "scheduled"
  //             ? "tayang"
  //             : data.status == "emergency"
  //             ? "lolos kurasi"
  //             : "-"
  //           )
  //             .toLowerCase()
  //             .includes(search.toLowerCase())
  //         ) {
  //           return data;
  //         }
  //       })
  //     : "";

  const filterData = (item) => {
    props.handleData(item);
  };
  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };

  //modal preview
  const [modalKonten, setModalKonten] = useState(false);
  const [dataModalKonten, setDataModalKonten] = useState({});
  const toggleModalKonten = () => {
    setModalKonten(!modalKonten);
  };
  const handleModalKonten = (title, data) => {
    setDataModalKonten({
      url: data.details.url,
      title: title,
      type: data.type,
    });
    toggleModalKonten();
  };

  //modal doc
  const [modalDoc, setModalDoc] = useState(false);
  const [dataModalDoc, setDataModalDoc] = useState({});
  const toggleModalDoc = () => {
    setModalDoc(!modalDoc);
  };
  const handleModalDoc = (title, data) => {
    setDataModalDoc({
      url: data.emergency,
      title: title,
    });
    toggleModalDoc();
  };

  const handleDetail = (data) => {
    // dispatch(detailData(data));
    props.setTabDetail("video");
    props.detailData(data);
    history.push("/admin/cp-analitics-detail-statistik-konten?tab=video&konten=cpanalytics");
  };
  const ButtonAction = ({ title, data, status }) => {
    if (typeof data.emergency !== "undefined" && data.status == "submitted") {
      return (
        <>
          <UncontrolledTooltip placement="bottom" target="TooltipEmergency">
            Detail Konten Emergency
          </UncontrolledTooltip>
          <img
            id="TooltipEmergency"
            className="btnAction"
            src={IconEmergency}
            onClick={() => handleModalDoc(title, data)}></img>
        </>
      );
    } else if (data.status == "scheduled") {
      return (
        <Button onClick={() => handleDetail(data)} className="btn-rounded btnDetail">
          Statistik
        </Button>
      );
    } else {
      return <></>;
    }
  };

  const getStatus = (it) => {
    let status = it?.status;
    let statusKurator = it?.status_kurator[it?.status_kurator.length - 1]?.status;
    let emergency = it?.emergency;

    if (status === "submitted") {
      if (typeof emergency !== "undefined") {
        return (
          <div className="d-flex pointer" onClick={() => handleModalDoc(it.details.title, it)}>
            <img src={IconEmergency}></img>
            <label
              // onClick={() => handleModalDoc(it.details.title, it)}
              key={it._id}
              className="mx-auto py-1 pointer"
              style={{ color: "#C42127" }}>
              Emergency
            </label>
          </div>
        );
      } else {
        switch (statusKurator) {
          case "Tidak Lolos Kurasi":
            return (
              <label
                // onClick={() => handleModalTidakLolos(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#C42127" }}>
                Tidak Lolos Kurasi
              </label>
            );
            break;
          case "Lolos Kurasi":
            return (
              <label
                // onClick={() => handleModalLolos(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#55BA59" }}>
                Lolos Kurasi
              </label>
            );
            break;
          case "Revisi":
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                Revisi
              </label>
            );
            break;
          case "Sudah Revisi":
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                Sudah Revisi
              </label>
            );
            break;
          default:
            return (
              <label
                // onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                {statusKurator}
              </label>
            );
            break;
        }
      }
    } else {
      switch (status) {
        case "declined":
          return (
            <label
              // onClick={() => handleModalTidakLolos(it)}
              key={it._id}
              className="mx-auto py-1"
              style={{ color: "#c42127" }}>
              Ditolak
            </label>
          );
          break;
        case "approved":
          return (
            <label
              // onClick={() => handleModalLolos(it)}
              key={it._id}
              className="mx-auto py-1"
              style={{ color: "#c42127" }}>
              Disetujui
            </label>
          );
          break;
        case "scheduled":
          return (
            <label
              // onClick={() => handleModalLolos(it)}
              key={it._id}
              className="mx-auto py-1">
              Tayang
            </label>
          );
          break;
        case "canceled":
          return (
            <label
              // onClick={() => handleModalLolos(it)}
              style={{ color: "#c42127" }}
              key={it._id}
              className="mx-auto py-1">
              Dibatalkan
            </label>
          );
          break;

        default:
          return (
            //todo
            <label
              // onClick={() => handleModalLolos(it)}
              // style={{ color: "#c42127" }}
              key={it._id}
              className="mx-auto py-1">
              {_.startCase(_.toLower(status))}
            </label>
          );
          break;
      }
    }
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 200,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Durasi",
      //   field: "durasi",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Provinsi",
      //   field: "provinsi",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Tanggal Tayang",
        field: "tanggal_tayang",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 150,
      },
      {
        label: "",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              tanggal_tayang: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, index) => ({
            no: index + 1,
            judul: it.details.title ? it.details.title ?? "-" : it.title ?? "-",
            kategori: it.category ?? "-",
            // durasi: it.proposed_duration ?? "-",
            // provinsi:
            //   it.list_provinsi.length > 2
            //     ? `${it ? (it.list_provinsi[0] ? it.list_provinsi[0].name : "-") : "-"}, ${
            //         it ? (it.list_provinsi[1] ? it.list_provinsi[1].name : "-") : "-"
            //       }, ${it ? (it.list_provinsi[2] ? it.list_provinsi[2].name : "-") : "-"}...`
            //     : it.list_provinsi.length > 1
            //     ? `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"},${
            //         it.list_provinsi[1] ? it.list_provinsi[1].name : "-"
            //       }`
            //     : `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"}`,
            tanggal_tayang:
              it.proposed_date.length > 0
                ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}\n s/d \n${moment(it.proposed_date.slice(-1), "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )} `
                : "-",
            status: getStatus(it),
            preview: [
              <a key={index} onClick={() => handleModalKonten(it.details.title, it)}>
                <center style={{ width: "fit-content" }}>
                  <img
                    alt={it.details.title}
                    className="wrapperImgYT"
                    src={
                      it.details.thumbnail !== ""
                        ? `${process.env.REACT_APP_BE_URL}/${
                            it.details.thumbnail ? it.details.thumbnail : ""
                          }`
                        : noImage
                    }></img>
                </center>
              </a>,
            ],
            aksi: <ButtonAction title={it.details.title} data={it} status={it.status} />,
          })),
  };

  return (
    <React.Fragment>
      <ModalPreviewVideo
        dataModalKonten={dataModalKonten}
        modalKonten={modalKonten}
        toggleModalKonten={toggleModalKonten}
      />
      <ModalPreviewEmergencyDoc
        dataModalDoc={dataModalDoc}
        modalDoc={modalDoc}
        toggleModalDoc={toggleModalDoc}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  {props.loading ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : rows.length === 0 ? (
                    <div className="justify-content-md-center text-center">Tidak Ada Data</div>
                  ) : (
                    <MDBDataTable
                      id="tableVideoKontenCP"
                      noBottomColumns={true}
                      className="text-center"
                      responsive
                      disableRetreatAfterSorting
                      striped
                      bordered
                      data={data}
                      displayEntries={false}
                      pagesAmount={5}
                      entries={10}
                      searching={false}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStatetoProps, { detailData, setTabDetail })(
  TableKontenContentPatnerVideo
);
