import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../../Filter/filter";
// ------------------API-----------------------
import API from "../../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconCheck from "../../../../../assets/icon/IconCheck.svg";
import IconDecline from "../../../../../assets/icon/IconDecline.svg";
import IconInfo from "../../../../../assets/icon/IconInfo.svg";
import IconEmergency from "../../../../../assets/icon/IconEmergency.svg";
import IconGreenCheckList from "../../../../../assets/icon/IconGreenChecklist.svg";
import IconRedCheckList from "../../../../../assets/icon/IconRedChecklist.svg";
// ------------------Component----------------
import ModalPreviewVideo from "../../../Popup/PersetujuanKonten/PreviewVideo";
import ModalVerif from "../../../Popup/PersetujuanKonten/Verification";
import ModalDecline from "../../../Popup/PersetujuanKonten/Decline";
import DetailKonten from "../../../Popup/PersetujuanKonten/DetailKonten";
import ModalPreviewEmergencyDoc from "../../../Popup/PersetujuanKonten/PreviewEmergencyDoc";
import ModalLolos from "../../../../../components/Admin/Popup/Konten/Lolos";
import ModalTidakLolos from "../../../../../components/Admin/Popup/Konten/TidakLolos";
// ------------------Redux----------------

import { connect } from "react-redux";
import noImage from "../../../../../assets/images/noImage.jpg";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

const TablePersetujuanKontenSAVideo = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  //alert
  const [alert, setAlert] = useState("");
  const fetchAPIVideo = () => {
    setLoading(true);
    API.getPersetujuanKontenVideo()
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setLoading(false);
        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(res.data.data);
        } else {
          setRows(result);
          document.getElementById("TablePersetujuanKontenSAVideo1").rows[1].style.transition =
            "0.8s";
          document.getElementById("TablePersetujuanKontenSAVideo1").rows[1].style.backgroundColor =
            "#e6f3ff";
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    if (props.categoryContent !== "0") {
      fetchAPIVideo(props.categoryContent);
    } else {
      fetchAPIVideo();
    }
  }, [refresh, props.idContent, props.categoryContent]);
  //throw data to parent for download
  const filterData = (item) => {
    // console.log("yg bkl ke lempar", item)
    props.handleData(item);
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "") return data;
      const status =
        data.emergency !== undefined && data.emergency !== ""
          ? "Emergency"
          : data.status_kurator[data.status_kurator.length - 1].status == "Tidak Lolos Kurasi"
          ? "Tidak Lolos Kurasi"
          : data.status_kurator[data.status_kurator.length - 1].status == "Lolos Kurasi"
          ? "Lolos Kurasi"
          : "";

      const index = [
        data.details.title ? data.details.title : data.title,
        data.requested_by.organization.name,
        data.list_provinsi.map((prov) => prov.name).toString(),
        data.category,
        data.location && data.location.join(),
        data.proposed_duration,
        moment(data.tanggal_pembuatan, "DD-MM-YYYY").format("DD-MM-YYYY").toString(),
        data.proposed_date
          .map((date) => moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"))
          .toString(),
        status,
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
        })
      ) {
        return data;
      }
    });
  }

  //modal preview
  const [modalKonten, setModalKonten] = useState(false);
  const [dataModalKonten, setDataModalKonten] = useState({});
  const toggleModalKonten = () => {
    setModalKonten(!modalKonten);
  };
  const handleModalKonten = (url, title, type) => {
    setDataModalKonten({
      url: url,
      title: title,
      type: type,
    });
    toggleModalKonten();
  };
  //modal verif
  const [modalVerif, setModalVerif] = useState(false);
  const [dataModalVerif, setDataModalVerif] = useState({});
  const toggleModalVerif = () => {
    setModalVerif(!modalVerif);
  };
  const handleModalVerif = (title, id, it) => {
    setDataModalVerif({
      title: title,
      id: id,
      activeTab: props.activeTab,
    });
    toggleModalVerif();
  };

  //modal doc
  const [modalDoc, setModalDoc] = useState(false);
  const [dataModalDoc, setDataModalDoc] = useState({});
  const toggleModalDoc = () => {
    setModalDoc(!modalDoc);
  };
  const handleModalDoc = (title, data) => {
    setDataModalDoc({
      url: data.emergency,
      title: title,
    });
    toggleModalDoc();
  };

  //modal decline
  const [modalDecline, setModalDecline] = useState(false);
  const [dataModalDecline, setDataModalDecline] = useState({});
  const toggleModalDecline = () => {
    setModalDecline(!modalDecline);
  };
  const handleModalDecline = (title, id, it) => {
    setDataModalDecline({
      title: title,
      id: id,
      activeTab: props.activeTab,
    });
    toggleModalDecline();
  };

  //modal detail konten
  const [ModalDetailKonten, setModalDetailKonten] = useState(false);
  const [dataModalDetailKonten, setDataModalDetailKonten] = useState({});
  const toggleModalDetailKonten = () => {
    setModalDetailKonten(!ModalDetailKonten);
  };
  const handleModalDetailKonten = (it) => {
    setDataModalDetailKonten({
      data: it,
    });
    toggleModalDetailKonten();
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };
  //modal lolos
  const [modalLolos, setModalLolos] = useState(false);
  const [dataModalLolos, setDataModalLolos] = useState({});
  const toggleModalLolos = () => {
    setModalLolos(!modalLolos);
  };
  const handleModalLolos = (it) => {
    setDataModalLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      activeTab: props.activeTab,
    });
    toggleModalLolos();
  };
  //modal Tidak Lolos
  const [modalTidakLolos, setModalTidakLolos] = useState(false);
  const [dataModalTidakLolos, setDataModalTidakLolos] = useState({});
  const toggleModalTidakLolos = () => {
    setModalTidakLolos(!modalTidakLolos);
  };
  const handleModalTidakLolos = (it) => {
    setDataModalTidakLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      comment: it.comment,
      activeTab: props.activeTab,
    });
    toggleModalTidakLolos();
  };

  const [toggle, setToggle] = useState({
    TooltipSetujui: false,
    TooltipTolak: false,
    TooltipDetail: false,
  });
  const toggleTooltip = (tooltip) => {
    switch (tooltip) {
      case "TooltipSetujui":
        setToggle((prev) => ({
          ...prev,
          TooltipSetujui: !toggle.TooltipSetujui,
        }));
        break;
      case "TooltipTolak":
        setToggle((prev) => ({
          ...prev,
          TooltipTolak: !toggle.TooltipTolak,
        }));
        break;
      case "TooltipDetail":
        setToggle((prev) => ({
          ...prev,
          TooltipDetail: !toggle.TooltipDetail,
        }));
        break;
    }
  };

  const ButtonAction = ({ title, id, it }) => {
    return (
      <div className="wrapperBtnAction">
        <UncontrolledTooltip placement="bottom" target="TooltipSetujui">
          Setujui Konten
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target="TooltipTolak">
          Tolak Konten
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target="TooltipDetail">
          Detail Konten
        </UncontrolledTooltip>
        {it.emergency ? (
          <>
            <img
              id="TooltipSetujui"
              className="btnAction"
              src={IconCheck}
              onClick={() => handleModalVerif(title, id, it)}></img>

            <img
              id="TooltipTolak"
              className="btnAction"
              src={IconDecline}
              onClick={() => handleModalDecline(title, id, it)}></img>

            {/* <img
              className="btnAction"
              src={IconInfo}
              onClick={() => handleModalDetailKonten(it)}
            ></img> */}
          </>
        ) : it.status_kurator[it.status_kurator.length - 1].status !== "Tidak Lolos Kurasi" ? (
          <>
            <img
              id="TooltipSetujui"
              className="btnAction"
              src={IconCheck}
              onClick={() => handleModalVerif(title, id, it)}></img>
            <img
              id="TooltipTolak"
              className="btnAction"
              src={IconDecline}
              onClick={() => handleModalDecline(title, id, it)}></img>
          </>
        ) : (
          ""
        )}
        <img
          id="TooltipDetail"
          className="btnAction"
          src={IconInfo}
          onClick={() => handleModalDetailKonten(it)}></img>

        {/* {it.status_kurator[it.status_kurator.length - 1].status !== "Tidak Lolos Kurasi" ? (
          <>
            <img
              className="btnAction"
              src={IconCheck}
              onClick={() => handleModalVerif(title, id, it)}></img>
            <img
              className="btnAction"
              src={IconDecline}
              onClick={() => handleModalDecline(title, id, it)}></img>
          </>
        ) : (
            ""
          )}

        <img className="btnAction" src={IconInfo} onClick={() => handleModalDetailKonten(it)}></img> */}
      </div>
    );
  };

  // const ButtonAction = ({ title, id, it }) => {
  //   return (
  //     <div className="wrapperBtnAction">
  //       {it.status === "approved" ? (
  //         <img
  //           className="btnAction"
  //           src={IconDecline}
  //           // onClick={() => handleDecline(title, id, it)}
  //           onClick={() => handleNonAktif(it)}
  //         >
  //         </img>
  //       ) : (
  //         <img
  //           className="btnAction"
  //           src={IconCheck}
  //           // onClick={() => handleVerif(title, id, it)}
  //           onClick={() => handleAktif(it)}
  //         >
  //         </img>
  //       )}
  //       {/* <img
  //         className="btnAction "
  //         src={IconInfo}
  //         onClick={handleDelete}
  //       >
  //       </img> */}
  //       <i className="bx bx-trash"
  //         style={{fontSize : "20px"}}
  //         color=""
  //         onClick={() => handleDelete(it)}
  // 			></i>
  //     </div>
  //   );
  // };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 200,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori Lokasi",
        field: "kategorilokasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Pengajuan",
        field: "tanggal_pengajuan",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Tayang",
        field: "tanggal_tayang",
        sort: "asc",
        width: 150,
      },
      {
        label: "Hasil Kurasi",
        field: "hasil_kurasi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Aksi",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, index) => ({
            no: index + 1,
            judul: it ? (it.details ? (it.details.title ? it.details.title : "-") : "-") : "-",
            organisasi: it
              ? it.requested_by
                ? it.requested_by.organization
                  ? it.requested_by.organization.name
                    ? it.requested_by.organization.name
                    : "-"
                  : "-"
                : "-"
              : "-",
            provinsi:
              it.list_provinsi.length > 2
                ? `${it ? (it.list_provinsi[0] ? it.list_provinsi[0].name : "-") : "-"}, ${
                    it ? (it.list_provinsi[1] ? it.list_provinsi[1].name : "-") : "-"
                  }, ${it ? (it.list_provinsi[2] ? it.list_provinsi[2].name : "-") : "-"}...`
                : it.list_provinsi.length > 1
                ? `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"},${
                    it.list_provinsi[1] ? it.list_provinsi[1].name : "-"
                  }`
                : `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"}`,
            kategori: it ? (it.category ? it.category : "-") : "-",
            kategorilokasi: it.location
              ? it.location.length > 2
                ? `${it ? (it.location[0] ? it.location[0] : "-") : "-"}, ${
                    it ? (it.location[1] ? it.location[1] : "-") : "-"
                  }, ${it ? (it.location[2] ? it.location[2] : "-") : "-"}...`
                : it.location.length > 1
                ? `${it.location[0] ? it.location[0] : "-"},${
                    it.location[1] ? it.location[1] : "-"
                  }`
                : `${it.location[0] ? it.location[0] : "-"}`
              : "-",
            tanggal_pengajuan: `${moment(it.tanggal_pembuatan).format("YYYYMMDD")}
            ${moment(it.tanggal_pembuatan).format("DD-MM-YYYY")}`,
            tanggal_tayang:
              it.proposed_date.length > 0
                ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format("YYYYMMDD")}
                  ${moment(it.proposed_date[0], "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}\n s/d \n${moment(it.proposed_date.slice(-1), "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )} `
                : "-",
            hasil_kurasi:
              it.emergency !== undefined && it.emergency !== "" ? (
                <div
                  id="TooltipEmergency"
                  className="d-flex pointer"
                  onClick={() => handleModalDoc(it.details.title, it)}>
                  <UncontrolledTooltip placement="bottom" target="TooltipEmergency">
                    Detail Konten Emergency
                  </UncontrolledTooltip>
                  <img src={IconEmergency}></img>
                  <label
                    onClick={() => handleModalDoc(it.details.title, it)}
                    key={it._id}
                    className="mx-auto py-1 pointer"
                    style={{ color: "#C42127" }}>
                    Emergency
                  </label>
                </div>
              ) : it.status_kurator[it.status_kurator.length - 1].status == "Tidak Lolos Kurasi" ? (
                <label
                  key={it._id}
                  className="mx-auto py-1"
                  style={{ color: "#C42127" }}
                  onClick={() => handleModalTidakLolos(it)}>
                  Tidak Lolos Kurasi
                </label>
              ) : it.status_kurator[it.status_kurator.length - 1].status == "Lolos Kurasi" ? (
                <label
                  key={it._id}
                  className="mx-auto py-1"
                  style={{ color: "#55BA59" }}
                  onClick={() => handleModalLolos(it)}>
                  Lolos Kurasi
                </label>
              ) : (
                <>{it.status_kurator[it.status_kurator.length - 1].status}</>
              ),

            preview: [
              <a
                key={index}
                onClick={() =>
                  handleModalKonten(it.details.url ?? "", it.details.title ?? "", it.type ?? "")
                }>
                <center style={{ width: "fit-content" }}>
                  <img
                    alt={it.details.title}
                    className="wrapperImgYT"
                    src={
                      it.details.thumbnail !== ""
                        ? `${process.env.REACT_APP_BE_URL}/${
                            it.details.thumbnail ? it.details.thumbnail : ""
                          }`
                        : noImage
                    }></img>
                </center>
              </a>,
            ],
            aksi: (
              <ButtonAction title={it.details.title} id={it ? it._id : "-"} it={it ? it : ""} />
            ),
          })),
  };

  return (
    <React.Fragment>
      {alert === "success" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil Disetujui"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alert === "fail" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil Ditolak"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconRedCheckList}></img>
        </SweetAlert>
      ) : alert === "failedApprove" ? (
        <SweetAlert
          error
          timeout={3}
          title="Konten Tidak Berhasil Disetujui"
          showConfirm={false}
          onConfirm={handleFetch}>
          {/* <img src={IconRedCheckList}></img> */}
        </SweetAlert>
      ) : alert === "failedDecline" ? (
        <SweetAlert
          error
          timeout={3}
          title="Konten Tidak Berhasil Ditolak"
          showConfirm={false}
          onConfirm={handleFetch}>
          {/* <img src={IconRedCheckList}></img> */}
        </SweetAlert>
      ) : alert === "failedFetch" ? (
        <SweetAlert
          timeout={3}
          error
          title="Konten Tidak Berhasil Dikurasi"
          showConfirm={false}
          onConfirm={handleFetch}>
          {/* <img src={IconRedCheckList}></img> */}
        </SweetAlert>
      ) : (
        " "
      )}

      <ModalPreviewEmergencyDoc
        dataModalDoc={dataModalDoc}
        modalDoc={modalDoc}
        toggleModalDoc={toggleModalDoc}
      />
      <ModalPreviewVideo
        dataModalKonten={dataModalKonten}
        modalKonten={modalKonten}
        toggleModalKonten={toggleModalKonten}
      />
      <ModalVerif
        dataModalVerif={dataModalVerif}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalVerif={modalVerif}
        toggleModalVerif={toggleModalVerif}
      />
      <ModalDecline
        dataModalDecline={dataModalDecline}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalDecline={modalDecline}
        toggleModalDecline={toggleModalDecline}
      />
      <DetailKonten
        dataModalDetailKonten={dataModalDetailKonten}
        ModalDetailKonten={ModalDetailKonten}
        toggleModalDetailKonten={toggleModalDetailKonten}
      />

      <ModalLolos
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        dataModalLolos={dataModalLolos}
        modalLolos={modalLolos}
        toggleModalLolos={toggleModalLolos}
      />
      <ModalTidakLolos
        dataModalTidakLolos={dataModalTidakLolos}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalTidakLolos={modalTidakLolos}
        toggleModalTidakLolos={toggleModalTidakLolos}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>

                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">Tidak Ada Data</div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="TablePersetujuanKontenSAVideo1"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TablePersetujuanKontenSAVideo);
