import React from "react";
import { CardBody, Card, Row, Button, Col, Spinner } from "reactstrap";

// STYLE
import style from "./style.module.css";

// ASSETS
import userIcon from "../../assets/images/user.svg";
import surveiIcon from "../../assets/images/survei.svg";
import faqIcon from "../../assets/images/faq.svg";

export default function CardSurvey(props) {
  return (
    <div className="my-5">
      <Col lg="12" style={{ minHeight: "100%" }}>
        <Row className="text-center h-100">
          <Col md="12" lg="4">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={surveiIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>TOTAL SURVEY DIBUAT</div>
            </Col>
            {props.loading ? (
              <div className="my-3">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueSurvey}>
                {" "}
                {props.data
                  ? Object.values(props.data).length === 0
                    ? 0
                    : (props.data.survey ?? 0).toLocaleString()
                  : 0}
              </Col>
            )}
          </Col>

          <Col md="12" lg="4">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={userIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>PENGUNJUNG MENGISI SURVEY</div>
            </Col>
            {props.loading ? (
              <div className="my-3">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueSurvey}>
                {" "}
                {props.data
                  ? Object.values(props.data).length === 0
                    ? 0
                    : ( (props?.data?.gender?.pria + props?.data?.gender?.wanita) ?? 0).toLocaleString()
                  : 0}
              </Col>
            )}
          </Col>

          <Col md="12" lg="4">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={faqIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>TOTAL PERTANYAAN SURVEY</div>
            </Col>
            {props.loading ? (
              <div className="my-3">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueSurvey}>
                {" "}
                {props.data
                  ? Object.values(props.data).length === 0
                    ? 0
                    : (props.data.question ?? 0).toLocaleString()
                  : 0}
              </Col>
            )}
          </Col>
        </Row>
      </Col>
    </div>
  );
}
