import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const UserProfileAsync = Loadable({
  loader: () =>
    import("../../../pages/Authentication/user-profile" /* webpackChunkName: "UserProfileAsync" */),
  loading: Loading,
});

export default UserProfileAsync;
