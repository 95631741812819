import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "./style.css";
import DownWhite from "../../../assets/images/backwhite.png";
// -------------------COMPONENT--------------------
//Import Breadcrumb
import Header from "../../../components/VerticalLayout/Header";
import TableTicket from "../../../components/SupportCenter/Table/TableTicket";

import { exportPDF } from "../../../components/SupportCenter/Download/Ticket/PDF/DownloadPDF";
import DownloadXLS from "../../../components/SupportCenter/Download/Ticket/XLS/DownloadXLS";
// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function TicketPage(props) {
  let role = window.localStorage.getItem("role");
  //handle data xls
  const [data, setData] = useState([]);
  const [idContent, setIdContent] = useState("");

  //handle data from api from download
  const handleData = (vid) => {
    console.log("handle data => ", vid);
    setData(vid);
  };

  //handle download pdf
  const handleDownloadPDF = () => {
    exportPDF(data);
  };

  useEffect(() => {
    let notifContentType = props.dataNotif.data;
    if (notifContentType !== "empty") {
      setIdContent(notifContentType.data.content._id);
    }
  }, [props.dataNotif.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="TIKET" btn={true} btntype="createTicket" className="SuperAdminHeader" />
        </header>
        <main>
          <div className="container-fluid">
            <MDBContainer fluid={true}>
              <Row>
                <Col className="col-12 ">
                  <MDBNav classicTabs className="wrapperNav justify-content-end">
                    {/* <div className="wrapperAction">
                      <MDBNavLink to="#" className="textDownload">
                        Download
                      </MDBNavLink>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className="DownloadTableXLS"
                        table="DownloadTableXLS"
                        filename={`Ticket_Report_${role}`}
                        sheet="users"
                        buttonText="XLS"
                      />
                      <MDBNavLink onClick={handleDownloadPDF} link to="#">
                        PDF
                      </MDBNavLink>
                    </div>
                    <DownloadXLS id="DownloadTableXLS" className="DownloadTableXLS" data={data} /> */}
                    <div
                      style={{
                        width: "25%",
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "0.75%",
                      }}>
                      <MDBDropdown
                        style={{ width: "100% !important" }}
                        className="dropdown-download-doc">
                        <MDBDropdownToggle
                          caret
                          color="primary"
                          className="dropdown-download font-weight-bold">
                          <div className="wrapper-selected">Download</div>
                          <div>
                            <img src={DownWhite} alt="dropdown-icons" />
                          </div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-menus dropdown-show">
                          <MDBDropdownItem
                            style={{ color: "#556ee6" }}
                            className="text-center"
                            onClick={handleDownloadPDF}>
                            PDF
                          </MDBDropdownItem>
                          <MDBDropdownItem>
                            <ReactHTMLTableToExcel
                              id="TableXLS"
                              className="DownloadTableXLSDropdown"
                              table="DownloadTableXLS"
                              filename={`Ticket_Report_${role}`}
                              sheet="users"
                              buttonText="XLS"
                            />
                          </MDBDropdownItem>
                          <DownloadXLS
                            id="DownloadTableXLS"
                            className="DownloadTableXLS"
                            data={data}
                          />
                        </MDBDropdownMenu>
                      </MDBDropdown>
                    </div>
                  </MDBNav>
                  <MDBTabContent className="card">
                    <MDBTabPane>
                      <Col className="col-12">
                        <TableTicket idContent={idContent} handleData={handleData} />
                      </Col>
                    </MDBTabPane>
                  </MDBTabContent>
                </Col>
              </Row>
              {/* </div> */}
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(TicketPage));
