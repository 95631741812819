import React, { useState, useRef } from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import Rating from "react-star-ratings";
import API from "../../../../services";

import "./style.css";

function PopupRating(props) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const commentRef = useRef();
  const idUser = window.localStorage.getItem("idUser");

  const giveRating = (rat) => {
    setRating(rat);
  };


  const handleSubmitRating = () => {
    const comm = commentRef.current.value;
    // setComment(comm)
    const param = {
      userId: idUser,
      rating: rating,
      comment: comm,
      typeContent: props.data.type,
      idContent: props.data.id
    };
    // console.log(param)
    API.postRatingKonten(param)
    .then((res) => {
      if (res.data.success) {
        props.toggleRating()
        // props.handleAlert.setNotif(true);
        // props.handleAlert.setAlert(true);
        // props.handleAlert.setMessage(`Anda memberikan rating: ${rating} dan komentar ${comm}`);
        // setTimeout(() => {
        //   props.handleAlert.setAlert(false);
        // }, 5000);
        alert(`Anda memberikan rating: ${rating} dan komentar ${comm}`)
        // fetchData();
      } else {
        props.toggleRating()
        // props.handleAlert.setNotif(true);
        // props.handleAlert.setAlert(false);
        // props.handleAlert.setMessage(res.data.err);
        // setTimeout(() => {
        //   props.handleAlert.setAlert(false);
        // }, 5000);
        alert(res.data.err)
        // fetchData();
      }
    })
    .catch((e) => {
      props.toggleRating()
      // props.handleAlert.setNotif(true);
      // props.handleAlert.setAlert(false);
      // props.handleAlert.setMessage("error");
      // setTimeout(() => {
      //   props.handleAlert.setAlert(false);
      // }, 5000);
      alert(e)
      // fetchData();
    });
  };

  return (
    <MDBContainer>
      <MDBModal isOpen={props.modalRating} toggle={props.toggleRating} size="lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close pull-right"
              aria-label="Close"
              onClick={props.toggleRating}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
            <div className="w-40 mx-auto wrapperSubTitleRating">
              <h4 className="subTitleRating">
                Bagaimana Pendapat Anda, tentang Layanan BAKTI ONLINE?
              </h4>
            </div>

            <div className="mx-auto mb-5">
              <Rating
                rating={rating}
                starRatedColor="#2c3280"
                starHoverColor="#2c3280"
                changeRating={(e) => giveRating(e)}
                numberOfStars={5}
                name="rating"
              />
            </div>

            <div className="form-group wrapperTextAreaRating">
              <textarea
                ref={commentRef}
                className="form-control textAreaRating"
                placeholder="Tulis Komentar Anda disini"
                rows="5"
              />
            </div>

            <div className="mx-auto wrapperBtnVerification">
              <button
                type="button"
                onClick={handleSubmitRating}
                className="w-100 btn btnSubmitRating btn-rounded">
                Lolos
              </button>
            </div>
          </div>
        </div>
      </MDBModal>
    </MDBContainer>
  );
}
export default PopupRating;
