import React, { useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import Pdf from "react-to-pdf";

// COMPONENTS
import Map from "../Map/CardMapOrgProv";
import FilterOrgProvDemografi from "./Filter/FilterOrgProvDemografi";
import CardGender from "./CardDemografi/CardGender";
import CardPieChart from "./CardDemografi/CardPieChart";
import CardPersebaranUmur from "./CardDemografi/CardPersebaranUmur";

export default function CardOrgProv(props) {
  const [totalImpressiCheckBox, setTotalImpressiCheckBox] = useState(true);
  const [maksImpressiCheckBox, setMaksImpressiCheckBox] = useState(true);
  const [totalKlikCheckBox, setTotalKlikCheckBox] = useState(true);
  const [maksKlikCheckBox, setMaksKlikCheckBox] = useState(true);
  const [totalDFPCheckBox, setTotalDFPCheckBox] = useState(true);
  const [totalDeviceCheckBox, setTotalDeviceCheckBox] = useState(true);

  const [dataMap, setDataMap] = useState([]);
  const [data, setData] = useState([]);
  const [loadingMap, setLoadingMap] = useState(true);
  const [loading, setLoading] = useState(true);

  const [prov, setProv] = useState("");
  const [idProvince, setIdProvince] = useState("");

  const [showComponent, setShowComponent] = useState(false);
  const downloadRef = React.createRef();

  const handlePDFDownload = (fn) => {
    Swal.fire({
      title: "Apakah kamu ingin mendownload halaman ini?",
      text: "Data PDF akan di download",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2c3280",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        fn();
      }
    });
  };

  const options = {
    orientation: "landscape",
    // unit: 'in',
    // format: [4,2]
  };

  return (
    <div>
      <FilterOrgProvDemografi
        setDataMap={setDataMap}
        setData={setData}
        setLoading={setLoading}
        loading={loading}
        setLoadingMap={setLoadingMap}
        setShowComponent={setShowComponent}
        province={prov}
        idProvince={idProvince}
        setDataSave={props.setDataSave}
      />
      {showComponent ? (
        loading ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
            <Spinner type="grow" color="danger" />
          </div>
        ) : data === null || dataMap === null ? (
          <div>Tidak Ada Data</div>
        ) : (
          <>
            <Row className="float-right">
              <div>Download</div>
              <div className="mx-3">
                <Pdf
                  targetRef={downloadRef}
                  filename="dfp-os.pdf"
                  options={options}
                  scale={0.8}
                  x={3}
                  y={20}>
                  {({ toPdf }) => (
                    <a href="#" onClick={() => handlePDFDownload(toPdf)}>
                      PDF
                    </a>
                  )}
                </Pdf>
              </div>
            </Row>
            {loadingMap ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "200px",
                  marginBottom: "200px",
                }}>
                <Spinner type="grow" color="danger" />
              </div>
            ) : (
              <div ref={downloadRef}>
                <Row>
                  <Col lg="10">
                    <Map
                      data={dataMap}
                      loading={loadingMap}
                      setProvince={setProv}
                      setIdProvince={setIdProvince}
                      totalImpressiCheckBox={totalImpressiCheckBox}
                      maksImpressiCheckBox={maksImpressiCheckBox}
                      totalKlikCheckBox={totalKlikCheckBox}
                      maksKlikCheckBox={maksKlikCheckBox}
                      totalDFPCheckBox={totalDFPCheckBox}
                      totalDeviceCheckBox={totalDeviceCheckBox}
                    />
                  </Col>
                  <Col
                    lg="2"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div>
                      <Row className="my-2">
                        <input
                          className="mr-2"
                          // ref={radioEmergency}
                          onChange={(e) => setTotalImpressiCheckBox(e.target.checked)}
                          // value={totalImpressiCheckBox}
                          checked={totalImpressiCheckBox}
                          type="checkbox"
                          style={{ outline: "none" }}
                          id="cb1"
                        />
                        <span>Total Impressi</span>
                      </Row>
                      <Row className="my-2">
                        <input
                          className="mr-2"
                          // ref={radioEmergency}
                          onChange={(e) => setMaksImpressiCheckBox(e.target.checked)}
                          // value={isEmergency}
                          checked={maksImpressiCheckBox}
                          type="checkbox"
                          style={{ outline: "none" }}
                          id="cb2"
                        />
                        <span>Maks Impressi</span>
                      </Row>
                      <Row className="my-2">
                        <input
                          className="mr-2"
                          // ref={radioEmergency}
                          onChange={(e) => setTotalKlikCheckBox(e.target.checked)}
                          // value={isEmergency}
                          checked={totalKlikCheckBox}
                          type="checkbox"
                          style={{ outline: "none" }}
                          id="cb3"
                        />
                        <span>Total Klik</span>
                      </Row>
                      <Row className="my-2">
                        <input
                          className="mr-2"
                          // ref={radioEmergency}
                          onChange={(e) => setMaksKlikCheckBox(e.target.checked)}
                          // value={isEmergency}
                          checked={maksKlikCheckBox}
                          type="checkbox"
                          style={{ outline: "none" }}
                          id="cb4"
                        />
                        <span>Maks Klik</span>
                      </Row>
                      <Row className="my-2">
                        <input
                          className="mr-2"
                          // ref={radioEmergency}
                          onChange={(e) => setTotalDFPCheckBox(e.target.checked)}
                          // value={isEmergency}
                          checked={totalDFPCheckBox}
                          type="checkbox"
                          style={{ outline: "none" }}
                          id="cb5"
                        />
                        <span>Total DFP Unik</span>
                      </Row>
                      {/* <Row className="my-2">
                    <input
                      className="mr-2"
                      // ref={radioEmergency}
                      onChange={(e) => setTotalDeviceCheckBox(e.target.checked)}
                      // value={isEmergency}
                      checked={totalDeviceCheckBox}
                      type="checkbox"
                      style={{ outline: "none" }}
                      id="cb6"
                    />
                    <span>Total Device Unik</span>
                  </Row> */}
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            <div className="container-fluid" style={{ marginBottom: "100px" }}>
              <CardGender loading={loading} data={data} />
              <CardPieChart loading={loading} data={data} />
              <CardPersebaranUmur loading={loading} data={data} />
            </div>
          </>
        )
      ) : (
        "Silahkan isi Filter Data terlebih dahulu Untuk menampilkan Data"
      )}
    </div>
  );
}
