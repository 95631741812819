import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  Form,
  Input,
  Container,
  Alert,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "./style.css";
import Header from "../../../components/VerticalLayout/Header";
import { FilterButtonProvinsi } from "./dropdownProv";
import FilterButtonTime from "./dropdownTime";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { TwitterTweetEmbed } from "react-twitter-embed";

import FB from "../../../assets/icon/IconFacebook.svg";
import IG from "../../../assets/icon/IconIG.svg";
import Twitter from "../../../assets/icon/IconTwitter.svg";
import BAKTI from "../../../assets/images/logoBakti.svg";
import News from "../../../assets/images/news_bakti.png";

import API from "../../../services";
import Swal from "sweetalert2";

const DetailKontenPreviewTayang = (props) => {
  const [tanggal, setTanggal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [listProvinsi, setListProvinsi] = useState([]);
  const [provinsi, setProvinsi] = useState({ id: "", value: "" });
  const [today, setToday] = useState("");
  const [filterTime, setFilterTime] = useState("");
  const [kategoriLokasi, setKategoriLokasi] = useState("");
  const [data, setData] = useState({});
  const [twitters, setTwitters] = useState([]);
  const [selectedTweet, setSelectedTweet] = useState();

  const filterDataTime = (filt) => {
    setFilterTime(filt);
  };

  const getToday = () => {
    var todays = new Date();
    var dd = todays.getDate();
    var mm = todays.getMonth.length === 0 ? "0" + (todays.getMonth() + 1) : todays.getMonth() + 1; //January is 0!
    var yyyy = todays.getFullYear();
    setToday(yyyy + "-" + mm + "-" + dd);
  };

  const filterData = useCallback(
    (filt, id) => {
      setProvinsi({
        id: id,
        value: filt,
      });
    },
    [provinsi]
  );

  const handleFormInput = (e) => {
    let { value, name } = e.target;
    if (name === "tanggal") {
      setTanggal(value);
    }
  };

  useEffect(() => {
    getToday();
    API.getKontenTwitter()
      .then((res) => {
        setTwitters(res.data.data);
        const index = Math.floor(Math.random() * 4);
        //alert(res.data.data[index]?.entities.media[0].id);
        // setSelectedTweet(res.data.data[1]?.id_str);
        setSelectedTweet(<TwitterTweetEmbed tweetId={res.data.data[index]?.id_str} />);
        //alert(res.data.data[0]?.id_str);
      })
      .catch((err) => {
        console.log('getKontenTwitter', err);
      });
    API.getAllProvince()
      .then((res) => {
        if (res.data.success) {
          setListProvinsi(res.data.data);
        }
      })
      .catch(() => {
        history.push("/pages-500");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onButtonClick = () => {
    // alert("hello world");

    let tanggalPost = moment(tanggal).format("DD-MM-YYYY");

    if (tanggal === "" || filterTime === "" || provinsi.id === "") {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      API.postPreviewKontenTayang({
        province: provinsi.id,
        date: tanggalPost,
        time: filterTime,
        location: kategoriLokasi.toUpperCase(),
      })
        .then((res) => {
          console.log(res.data.data);
          if (res.data.success) {
            setIsLoading(false);
            setData(res.data.data);
          } else {
            history.push("/pages-500");
          }
        })
        .catch(() => {
          history.push("/pages-500");
        });
    }
  };

  const LiveView = () => {
    if (!provinsi.value || !kategoriLokasi) {
      var mError = "";

      if (!provinsi.value) {
        mError += "<div> - Provinsi</div>";
      }

      if (!kategoriLokasi) {
        mError += "<div> - Kategori lokasi</div>";
      }

      Swal.fire({
        title: "Error!",
        html: `<div>anda harus memilih:  ${mError} </div>`,
        icon: "error",
      });
      return false;
    }

    var province = provinsi.value
      .split(" ")
      .map((item) => {
        return item[0].toUpperCase() + item.substring(1).toLocaleLowerCase();
      })
      .join("");

    var url = process.env.REACT_APP_LP_URL + province + "/" + kategoriLokasi;

    window.open(url, "_blank").focus();
  };

  const user = window.localStorage.getItem("users");
  return (
    <>
      <div className="page-content">
        <Header title="Preview Konten Tayang" btntext="Konten Preview" />
        <div className="container-fluid">
          <Row>
            <Col sm="12" md={{ size: 8, offset: 2 }}>
              {/* Input Cardnya */}
              <Card style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "5px" }}>
                <Container style={{ textAlign: "center", paddingTop: "20px" }}>
                  <Row className="mx-auto col-lg-6">
                    Content Preview menampilkan preview konten yang sudah dan sedang tayang.
                  </Row>
                </Container>
                <CardTitle style={{ textAlign: "center", paddingTop: "20px" }}>
                  Pilih Provinsi dan Jadwal
                </CardTitle>
                <CardBody>
                  <Form>
                    <FilterButtonProvinsi
                      type={"Provinsi"}
                      filter={provinsi}
                      clicked={filterData}
                      list={listProvinsi}
                    />
                    {/* <Input className="inputDetailKP" name="provinsi" placeholder="Provinsi" onChange={handleFormInput}/> */}
                    <Row style={{ marginTop: "15px" }}>
                      <Col xs="6">
                        <Input
                          className="inputDetailKP"
                          name="tanggal"
                          placeholder="Tanggal"
                          type="date"
                          max={today}
                          onChange={handleFormInput}
                        />
                      </Col>
                      <Col xs="6">
                        <FilterButtonTime filter={filterTime} clicked={filterDataTime} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "15px" }}>
                      <Col xs={12}>
                        <Input
                          type="select"
                          className="form-control inputWrapper"
                          onChange={(e) => {
                            setKategoriLokasi(e.target.value);
                          }}
                          defaultValue={kategoriLokasi}>
                          <option value="">-- Pilih kategori Lokasi --</option>
                          <option value="Pendidikan"> PENDIDIKAN </option>
                          <option value="pelayananKesehatan">PELAYANAN KESEHATAN </option>
                          <option value="KantorPemerintahan">KANTOR PEMERINTAHAN </option>
                          <option value="LokasiPublik">LOKASI PUBLIK </option>
                          <option value="PusatKegiatanMasyarakat">
                            PUSAT KEGIATAN MASYARAKAT{" "}
                          </option>
                          <option value="PelayananUsaha">PELAYANAN USAHA </option>
                          <option value="LokasiWisata">LOKASI WISATA </option>
                          <option value="TempatIbadah">TEMPAT IBADAH </option>
                          <option value="PertahananKeamanan">PERTAHANAN & KEAMANAN</option>
                        </Input>
                      </Col>
                    </Row>
                  </Form>
                  <Col
                    sm="4"
                    md={{ size: 4, offset: 4 }}
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <Button className="buttonDetailKP" onClick={onButtonClick}>
                      Lihat
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="4" md={{ size: 8, offset: 2 }}>
              <Button className="btn btn-rounded btnHistory mb-2" onClick={LiveView}>
                Klik Disini Untuk menampilkan Live View
              </Button>
            </Col>
          </Row>
          <div
            style={{
              paddingLeft: "0%",
              paddingRight: "0%",
              paddingTop: "0%",
              marginBottom: "3%",
              paddingBottom: "75px",
              border: "1px solid grey",
            }}>
            {isLoading ? (
              <Skeleton count={10} />
            ) : (
              <div>
                <Row className="headerPreviewA" style={{ marginBottom: "10px" }}>
                  <Col xs="3" md="8" className="copyrightCPP">
                    <Row>
                      <Col xs="12" md="3" className="navbarTextCPP">
                        <img className="logoBaktiCPE" src={BAKTI} alt="BAKTI" />
                      </Col>
                      <Col xs="0" md="9" className="navbarTextCPPR">
                        Anda akan Terkoneksi Dengan Internet Setelah 30 Detik
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="9" md="4" className="containerHeaderCPPE">
                    <Row>
                      <Col xs="12" md="12" className="navbarTextCPPE">
                        Hallo, user | Hubungi Kami
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "3%" }}>
                  {typeof data.video === "undefined" ? (
                    // <div className="img-container-preview" style={{ marginBottom: "10px" }}>

                    // </div>
                    <iframe
                      style={{ width: "100%", height: "500px" }}
                      className="videoContentPartner"
                      title="-"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      src={`https://www.youtube.com/embed/eRebU60SxZE`}></iframe>
                  ) : typeof data.video._id === "undefined" ? (
                    <iframe
                      style={{ width: "100%", height: "500px" }}
                      className="videoContentPartner"
                      title="-"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      src={`https://www.youtube.com/embed/eRebU60SxZE`}></iframe>
                  ) : (
                    <div
                      className="embed-responsive embed-responsive-16by9"
                      style={{ marginBottom: "10px" }}>
                      <iframe
                        className="videoContentPartner"
                        title="-"
                        allowFullScreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen"
                        msallowfullscreen="msallowfullscreen"
                        oallowfullscreen="oallowfullscreen"
                        webkitallowfullscreen="webkitallowfullscreen"
                        src={
                          data.video.type === "youtube"
                            ? `https://www.youtube.com/embed/${
                                typeof data.video.url === "undefined"
                                  ? data.video.details.url
                                  : data.video.url
                              }`
                            : `${process.env.REACT_APP_BE_URL}/${
                                typeof data.video.url === "undefined"
                                  ? data.video.details.url
                                  : data.video.url
                              }`
                        }></iframe>
                    </div>
                  )}
                  <Row>
                    <Col xs="12" md="6">
                      {typeof data.banner === "undefined" ? (
                        <div className="img-container-preview" style={{ marginBottom: "10px" }}>
                          <img
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            alt={"-"}
                            src="https://admin-staging.devlabs.id//assets/upload/adm2baktionline/banner/thumbnails-20210617-7fba35f7c374a6308fe303be11e78925.jpeg"></img>
                        </div>
                      ) : typeof data.banner._id === "undefined" ? (
                        <div className="img-container-preview" style={{ marginBottom: "10px" }}>
                          <img
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            alt={"-"}
                            src="https://admin-staging.devlabs.id//assets/upload/adm2baktionline/banner/thumbnails-20210617-7fba35f7c374a6308fe303be11e78925.jpeg"></img>
                        </div>
                      ) : (
                        <div className="img-container-preview" style={{ marginBottom: "10px" }}>
                          <a
                            href={`${process.env.REACT_APP_BE_URL}/${
                              data.banner.details.url
                                ? data.banner.details.url
                                : data.banner.details
                            }`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <img
                              style={{ width: "100%", height: "100%", objectFit: "contain" }}
                              alt={"-"}
                              src={`${process.env.REACT_APP_BE_URL}/${
                                data.banner.details.url
                                  ? data.banner.details.url
                                  : data.banner.details
                              }`}></img>
                          </a>
                        </div>
                      )}
                      <h5 style={{ margin: "0px", paddingTop: "10px" }}>Banner</h5>
                      <p>Informasi Kominfo</p>
                    </Col>
                    <Col xs="12" md="6">
                      {typeof data.news === "undefined" ? (
                        <a href="https://www.baktikominfo.id/id/informasi/siaran-pers/bakti_kominfo_dan_ruangguru_selenggarakan_pelatihan_dan_beasiswa_peningkatan_kompetensi_guru_dan_siswa_di_kabupaten_asmat_dan_kabupaten_ende-1214">
                          <div className="img-container-preview" style={{ marginBottom: "10px" }}>
                            <img
                              style={{ width: "100%", height: "100%", objectFit: "contain" }}
                              src="https://www.baktikominfo.id/assets/uploads_md/itf_asmat_1.jpg"></img>
                          </div>
                        </a>
                      ) : typeof data.news._id === "undefined" ? (
                        <a href="https://www.baktikominfo.id/id/informasi/siaran-pers/bakti_kominfo_dan_ruangguru_selenggarakan_pelatihan_dan_beasiswa_peningkatan_kompetensi_guru_dan_siswa_di_kabupaten_asmat_dan_kabupaten_ende-1214">
                          <div className="img-container-preview" style={{ marginBottom: "10px" }}>
                            <img
                              style={{ width: "100%", height: "100%", objectFit: "contain" }}
                              src="https://www.baktikominfo.id/assets/uploads_md/itf_asmat_1.jpg"></img>
                          </div>
                        </a>
                      ) : (
                        <div className="img-container-preview" style={{ marginBottom: "10px" }}>
                          <a href={data.news.link} target="_blank" rel="noopener noreferrer">
                            <img
                              style={{ width: "100%", height: "100%", objectFit: "contain" }}
                              alt={data.title ?? "-"}
                              src={`${process.env.REACT_APP_BE_URL}/${
                                data.news.details.url ?? "-"
                              }`}></img>
                          </a>
                        </div>
                      )}
                      <h5 style={{ margin: "0px", paddingTop: "10px" }}>Berita</h5>
                      <p>Berita terbaru 2022</p>
                    </Col>
                    {/* <Col xs="12" md="4">
                      <div
                        className="img-container-preview"
                        style={{ overflow: "scroll", overflowX: "hidden" }}>
                        {selectedTweet}
                      </div>
                      <h5 style={{ margin: "0px", paddingTop: "10px" }}>Twitter</h5>
                      <p>Tweet terbaru 2022</p>
                    </Col> */}
                  </Row>
                  <Row
                    style={{
                      borderTop: "1px solid black",
                      marginLeft: "0px",
                      marginRight: "0px",
                      marginBottom: "10px",
                    }}
                  />
                  <Row>
                    <Col xs="12" md="6" className="copyrightCPP">
                      Copyright 2020 All Rights Reserved
                    </Col>
                    <br />
                    <Col xs="12" md="6" className="mediaSosialKamiCP">
                      Media Sosial Kami
                      <img style={{ marginLeft: "5px" }} src={FB} alt="FB" />
                      <img style={{ marginLeft: "5px" }} src={IG} alt="IG" />
                      <img style={{ marginLeft: "5px" }} src={Twitter} alt="Twitter" />
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailKontenPreviewTayang;
