import React, { useState, useEffect, Fragment, useCallback } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, Button, Container, CardBody, CardTitle } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
// ------------------CHART------------------------
import ReactApexChart from "react-apexcharts";
import "./style.css";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import { detailData } from "../../../store/actions";

// ------------------API-----------------------
import API from "../../../services";

// -------------------ASSET--------------------
//css
import "./datatables.scss";
import "./style.css";
//icon
import IconDown from "../../../assets/images/back.png";

// ------------------Component----------------
import Header from "../../../components/VerticalLayout/Header";
import ModalHistory from "../../../components/ContentPartner/Popup/Konten/History";
import ModalDetailTayang from "../../../components/ContentPartner/Popup/Konten/DetailTayang";
import DropdownFilter from "./dropdownFilter";
import TotalData from "../../../components/Card/DetailKonten/index";
import _ from "lodash";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DetailKontenAdmin = (props) => {
  const [data, setData] = useState([]);
  const [impressData, setImpressData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState();
  const [listProvince, setListProvince] = useState([]);
  const [firstProvinsi, setFirstProvinsi] = useState("");
  const [provinsi, setProvinsi] = useState("");
  let it = props.data.data ?? {};

  const query = useQuery();

  const fetchAPIImpress = () => {
    setImpressData([]);
    let paramImpress = {
      id: it._id,
      type: "impress",
    };

    API.postImpressandClickedContentCP(props.tabDetail, paramImpress)
      .then((res) => {
        if (res.data.success) {
          // setFirstProvinsi(res.data.data.graph[0].province);
          // setListProvince(_.map(res.data.data.graph, "province"));
          setData(res.data.data);
          // filterDataChartByProvince(res.data.data.graph);
          // filterDataTableByProvince(res.data.data.table);
          setLoading(false);
        } else {
          alert("false");
          setImpressData(["tidakAdaData"]);
          setLoading(false);
        }
      })
      .catch((e) => {
        alert("data impresi tidak bisa di dapat");
        setImpressData(["tidakAdaData"]);
        setLoading(false);
      });
  };

  const filterDataChartByProvince = (arrData) => {
    if (provinsi === "" && firstProvinsi !== "") {
      let result = arrData.filter((it) => firstProvinsi == it.province);
      setImpressData(result);
    } else {
      let result = arrData.filter((it) => provinsi == it.province);

      setImpressData(result);
    }
  };

  const filterDataTableByProvince = (arrData) => {
    if (provinsi === "" && firstProvinsi !== "") {
      let result = arrData.filter((it) => firstProvinsi == it.province);
      setDataTable(result);
    } else {
      let result = arrData.filter((it) => provinsi == it.province);

      setDataTable(result);
    }
  };

  useEffect(() => {
    fetchAPIImpress();
  }, [firstProvinsi, provinsi]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        if (data.length !== 0) {
          filterDataChartByProvince(data.graph);
          filterDataTableByProvince(data.table);
        }
      }
    }
  }, [provinsi, firstProvinsi, loading]);

  useEffect(() => {
    if (props.data.data) {
      if (props.data.data.list_provinsi) {
        setListProvince(props.data.data.list_provinsi.map((e) => e.name));
        setFirstProvinsi(props.data.data.list_provinsi.map((e) => e.name)[0]);
      }
    }
  }, []);

  //modal history
  const [modalHistory, setModalHistory] = useState(false);
  const toggleModalHistory = () => {
    setModalHistory(!modalHistory);
  };
  //modalDetailTayang
  const [modalDetailTayang, setModalDetailTayang] = useState(false);
  const toggleModalDetailTayang = () => {
    setModalDetailTayang(!modalDetailTayang);
  };
  //onchange filter provinsi
  const filterDataDropdown = (filt) => {
    setFirstProvinsi("");
    setProvinsi(filt);

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  //data table
  const [stateArrDFP, setStateArrDFP] = useState([]);

  //data for table top
  const DataDetail = {
    columns: [
      {
        label: "Judul",
        field: "judul",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "disabled",
        width: 150,
      },

      {
        label: "Durasi",
        field: "durasi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Tanggal Tayang",
        field: "tanggal_tayang",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Jam",
        field: "jam",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      Object.keys(it).length === 0
        ? [
            {
              tanggal_tayang: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : [
            {
              judul: it.title ? it.title ?? "-" : it.details.title ?? "-",
              organisasi: it.requested_by.organization.name ?? "-",
              kategori: it.category ?? "-",
              durasi: it.proposed_duration ?? "-",

              tanggal_tayang:
                it.proposed_date.length > 0
                  ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")} - ${moment(
                      it.proposed_date.slice(-1),
                      "DD-MM-YYYY"
                    ).format("DD-MM-YYYY")} `
                  : "-",
              jam:
                it.proposed_time.length > 0
                  ? `${it.proposed_time[0][0]} - ${it.proposed_time[0][1]} `
                  : "-",
              status: it ? (
                it.status ? (
                  <span
                    className={
                      it.status == "approved" || it.status == "emergency"
                        ? "approved"
                        : it.status == "scheduled"
                        ? "scheduled"
                        : it.status == "declined"
                        ? "declined"
                        : ""
                    }>
                    {it.status == "approved" || it.status == "emergency"
                      ? "Lolos Kurasi"
                      : it.status == "scheduled"
                      ? "Tayang"
                      : it.status == "declined"
                      ? "Tidak Lolos"
                      : ""}
                  </span>
                ) : (
                  "-"
                )
              ) : (
                "-"
              ),
            },
          ],
  };

  //data for table bottom
  const DataLokasi = {
    columns: [
      {
        label: "Kabupaten",
        field: "kabupaten",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Nama Lokasi",
        field: "nama_lokasi",
        sort: "disabled",
        width: 150,
      },

      {
        label: "Total Impresi",
        field: "total_impresi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Maks Impresi",
        field: "maks_impresi",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Total Klik",
        field: "total_klik",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Maks Klik",
        field: "maks_klik",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Pengguna Unik",
        field: "pengguna_unik",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      dataTable &&
      dataTable.map((it, index) => ({
        kabupaten: it.kabupaten_kota ?? "-",
        nama_lokasi: it.location ?? "-",
        total_impresi: it.total_impress ?? "-",
        maks_impresi: it.max_impress ?? "-",
        total_klik: it.total_clicked ?? "-",
        maks_klik: it.max_clicked ?? "-",
        pengguna_unik: it.unique_dfp ?? "-",
      })),
  };

  var btntype = "back";

  if (query.get("konten") === "cpanalytics") {
    btntype = "backCpAnalytics";
  }

  return (
    <React.Fragment>
      <ModalHistory modalHistory={modalHistory} toggleModalHistory={toggleModalHistory} />
      <ModalDetailTayang
        modalDetailTayang={modalDetailTayang}
        toggleModalDetailTayang={toggleModalDetailTayang}
      />
      <div className="page-content">
        <header>
          <Header title="Konten" btn={true} btntype={btntype} btntext="Konten" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <MDBContainer > */}
            {Object.keys(it).length === 0 ? (
              <div className="mt-5 mb-5 d-flex justify-content-center">
                Silahkan pilih konten terlebih dahulu
              </div>
            ) : (
              <Card className="mx-auto" style={{ boxShadow: "0px 0px 10px #D3D3D3" }}>
                <div className="classic-tabs">
                  <Row style={{ overflowY: "auto" }}>
                    <Col className="col-12">
                      <MDBDataTable
                        id="tableVid"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        paging={false}
                        striped
                        bordered
                        data={DataDetail}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                      />
                    </Col>
                  </Row>
                  <Container>
                    <Row>
                      <Col className="col-lg-5 mx-auto">
                        <DropdownFilter
                          type={"Provinsi"}
                          filter={provinsi !== "" ? provinsi : firstProvinsi}
                          clicked={filterDataDropdown}
                          list={listProvince ? listProvince : []}
                        />
                      </Col>

                      <Col className="col-lg-3 colDetailActions">
                        <Row className="mx-auto w-100 wrapperBtnDetailActions">
                          <Button
                            onClick={() => toggleModalDetailTayang()}
                            className="btn btn-rounded btnHistory mb-2">
                            Detail Penayangan
                          </Button>
                          <Button
                            onClick={() => toggleModalHistory()}
                            className="btn btn-rounded btnHistory">
                            History
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                  <TotalData
                    impressData={impressData}
                    dates={it.proposed_date}
                    loadingFilter={loading}
                    provinsi={provinsi}
                  />
                  <Card className="px-3 py-2">
                    <Row style={{ overflowY: "auto" }}>
                      <Col className="col-12">
                        {Object.keys(it).length === 0 ? (
                          <></>
                        ) : loading ? (
                          <div>
                            <Skeleton count="3" />
                          </div>
                        ) : (
                          <MDBDataTable
                            id="tableVid"
                            noBottomColumns={true}
                            className="text-center"
                            responsive
                            striped
                            bordered
                            data={DataLokasi}
                            displayEntries={false}
                            pagesAmount={5}
                            entries={10}
                            searching={false}
                          />
                        )}
                      </Col>
                    </Row>
                  </Card>
                  <div style={{ height: "100px" }}></div>
                </div>
              </Card>
            )}

            {/* </MDBContainer> */}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  const { tabDetail } = state.Layout;
  return { data, tabDetail };
};

export default connect(mapStatetoProps)(DetailKontenAdmin);
