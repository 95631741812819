import React, { useState, useEffect } from "react";
import { Container, Card, CardBody, Button, Row, Col } from "reactstrap";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import "./style.css";

const TableSiteChecker = (props) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(props.dataBulk);
    // console.log(props.dataBulk)
  }, [props.dataBulk]);

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "IP Address",
        field: "ip",
        sort: "asc",
        width: 100,
      },
      {
        label: "Subnet IP Terhubung",
        field: "sip",
        sort: "asc",
        width: 100,
      },
      {
        label: "Nama ISP",
        field: "isp",
        sort: "asc",
        width: 100,
      },
      {
        label: "Lokasi AI",
        field: "lokasiAI",
        sort: "asc",
        width: 100,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 100,
      },
      {
        label: "Kabupaten",
        field: "kabupaten_kota",
        sort: "asc",
        width: 100,
      },
      {
        label: "Latitude",
        field: "lat",
        sort: "asc",
        width: 100,
      },
      {
        label: "Longitude",
        field: "lng",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tanggal Integrasi",
        field: "ti",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tanggal Terakhir Akses",
        field: "tta",
        sort: "asc",
        width: 100,
      },

      {
        label: "Status",
        field: "stats",
        sort: "asc",
        width: 100,
      },
    ],
    rows:
      rows.length === 0
        ? [
            {
              ip: "",
              isp: "",
              lokasiAI: "",
              provinsi: "",
              kabupaten_kota: "",
              lat: "",
              lng: "",
              ti: "",
              tta: "",

              stats: "",
            },
          ]
        : rows.map((it, index) => ({
            no: index + 1,
            ip: it.ip_address,
            sip: it.ipAlternatif,
            isp: it.namaIsp,
            lokasiAI: it.namaLokasi,
            provinsi: it.provinsi,
            kabupaten_kota: it.kabupaten_kota,
            lat: it.latitude,
            lng: it.longitude,
            ti:
              it.tanggalIntegrasi === "-"
                ? "-"
                : `${moment(it.tanggalIntegrasi).format("DD MMMM YYYY")}\n${moment(
                    it.tanggalIntegrasi
                  ).format("HH:mm:ss")}`,
            tta:
              it.tanggalTerakhirAkses === "-"
                ? "-"
                : `${moment(it.tanggalTerakhirAkses).format("DD MMMM YYYY")}\n${moment(
                    it.tanggalTerakhirAkses
                  ).format("HH:mm:ss")}`,

            stats: it.status,
          })),
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  {
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="tableSiteChecker"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        info={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                        paging={rows.length === 0 ? false : true}
                      />
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default TableSiteChecker;
