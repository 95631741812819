import React, { useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";

// COMPONENTS
import Chart from "../Chart/ChartOrgKategDurasi";
import Filter from "./Filter/FilterOrgKatDurasi";

import Pdf from "react-to-pdf";

export default function CardOrgProv(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [prov, setProv] = useState("");

  const [typeData, setTypeData] = useState("Video");
  const [valueData, setValueData] = useState("");

  const [showComponent, setShowComponent] = useState(false);

  const downloadRef = React.createRef();

  const handlePDFDownload = (fn) => {
    Swal.fire({
      title: "Apakah kamu ingin mendownload halaman ini?",
      text: "Data PDF akan di download",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2c3280",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        fn();
      }
    });
  };

  const options = {
    orientation: "landscape",
    // unit: 'in',
    // format: [4,2]
  };

  return (
    <div>
      <Filter
        setData={setData}
        setLoading={setLoading}
        setShowComponent={setShowComponent}
        setValueData={setValueData}
        setTypeData={setTypeData}
        setDataSave={props.setDataSave}
      />
      {showComponent ? (
        loading ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
            <Spinner type="grow" color="danger" />
          </div>
        ) : data === null ? (
          <div>Tidak Ada Data</div>
        ) : (
          <>
            <Row className="float-right">
              <div>Download</div>
              <div className="mx-3">
                <Pdf
                  targetRef={downloadRef}
                  filename="dfp-os.pdf"
                  options={options}
                  scale={0.8}
                  x={3}
                  y={20}>
                  {({ toPdf }) => (
                    <a href="#" onClick={() => handlePDFDownload(toPdf)}>
                      PDF
                    </a>
                  )}
                </Pdf>
              </div>
            </Row>
            <div ref={downloadRef}>
              <Row>
                <Col lg="12" style={{ display: "flex", justifyContent: "center" }}>
                  <Chart
                    data={data}
                    loading={loading}
                    setProvince={setProv}
                    setIdProvince={props.setIdProvince}
                    valueData={valueData}
                    typeData={typeData}
                  />
                </Col>
              </Row>
            </div>
          </>
        )
      ) : (
        "Silahkan isi Filter Data terlebih dahulu Untuk menampilkan Data"
      )}
    </div>
  );
}
