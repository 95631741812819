import React, { useState, useEffect } from 'react';
import Header from '../../components/VerticalLayout/Header';
import CardCreateArticle from '../../components/Admin/Card/CardCreateArticle';

export default function CreateArticle() {

    return (
        <div>
            <Header title="Buat Artikel" btn={true} btntype={`back`} btntext={`Artikel`} />
            <CardCreateArticle />
        </div>
    )
}
