import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

// import HomeSp from "../pages/Home/Support";
// import UserProfile from "../pages/Authentication/user-profile";
// import PenggunaAdmin from "../pages/Pengguna/Admin";
// import SiteChecker from "../pages/SiteChecker";
// import SurveyKepuasanSummary from "../pages/SurveyKepuasanSummary/SupportCenter";
// import KontenTayangAdmin from "../pages/KontenTayang/Admin";
// import TicketPage from "../pages/Ticket/SupportCenter";
// import BuatTicket from "../pages/BuatTiket/SupportCenter";
// import KontenSupport from "../pages/Konten/Support";
// import SiteAnalysis from "../pages/SiteAnalysis/Admin";
//500 error
import Pages500 from "../pages/Utility/pages-500";

import HomeSp from "../pagesAsync/HomeAsync/SupportAsync";
import UserProfile from "../pagesAsync/AuthenticationAsync/UserProfileAsync";
import PenggunaAdmin from "../pagesAsync/PenggunaAdminAsync";
import SiteChecker from "../pagesAsync/SiteCheckerAsync";
import SurveyKepuasanSummary from "../pagesAsync/SurveyKepuasanSummaryAsync/SupportCenterAsync";
import KontenTayangAdmin from "../pagesAsync/KontenTayangAsync/AdminAsync";
import TicketPage from "../pagesAsync/TicketAsync/SupportCenterAsync";
import BuatTicket from "../pagesAsync/BuatTicket/SupportCenterAsync";
import KontenSupport from "../pagesAsync/KontenAsync/SupportCenterAsync";
import SiteAnalysis from "../pagesAsync/SiteAnalysisAsync/AdminAsync";

const AppSupport = (props) => {
  return (
    <Switch>
      <Redirect exact from={`${props.match.url}`} to={`${props.match.url}support-center/home`} />
      <Route exact path={`${props.match.url}support-center/home`} component={HomeSp} />
      <Route exact path={`${props.match.url}support-center/profile`} component={UserProfile} />
      <Route exact path={`${props.match.url}support-center/pengguna`} component={PenggunaAdmin} />
      <Route exact path={`${props.match.url}support-center/konten`} component={KontenSupport} />
      <Route
        exact
        path={`${props.match.url}support-center/detail-konten`}
        component={SiteAnalysis}
      />
      <Route
        exact
        path={`${props.match.url}support-center/konten-tayang`}
        component={KontenTayangAdmin}
      />
      <Route exact path={`${props.match.url}support-center/site-checker`} component={SiteChecker} />
      <Route
        exact
        path={`${props.match.url}support-center/survey-kepuasan`}
        component={SurveyKepuasanSummary}
      />
      <Route exact path={`${props.match.url}support-center/ticket`} component={TicketPage} />
      <Route exact path={`${props.match.url}support-center/buat-ticket`} component={BuatTicket} />
      <Route exact path={`${props.match.url}page-500`} component={Pages500} />
      <Redirect to="/" />
    </Switch>
  );
};

export default AppSupport;
