import React, { useEffect, useState } from "react";

import { Row, Card, Col, Spinner } from "reactstrap";

// import "tui-chart/dist/tui-chart.css";
import { ColumnChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "../style.module.css";
import "../toastui.scss";
import FilterJenisKonten from "./FilterJenisKonten";

import { useSelector } from "react-redux";

// import API from "../../../../services";
var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  // title: {
  //     color: '#8791af',
  // },
  xAxis: {
    // title: {
    //     color: '#8791af'
    // },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#2C3280"],
  },
};

export default function CardPersebaranOS(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.konten_pendidikan
      : "Video"
  );

  useEffect(() => {
    props.setDataSave(
      Object.assign(props.dataSave, {
        konten_pendidikan: type,
      })
    );
  }, [type]);

  const renderColumnChart = (param) => {
    const _width = window.screen.width / 1.8;

    const data = {
      categories: ["SD Sederajat", "SMP Sederajat", "SMA Sederajat", "Pendidikan Tinggi"],
      series: [
        {
          name: "Jumlah Pengunjung",
          // data: param ? param.Agribisnis ?? 100 : 100,
          data: param ? Object.values(param.education ?? [0]) : [0],
          // data: [20, 12, 56, 78, 43, 67, 19, 20, 12, 49],
        },
      ],
    };

    const options = {
      chart: {
        width: _width,
        height: _width * 0.5,
        // title: 'Jumlah',
        // format: "1,000",
      },
      yAxis: {
        // title: "Jumlah",
        min: 0,
        max: Math.max(...Object.values(param ? param.education ?? 0 : 0)) + 10,
        title: "Jumlah",
      },
      xAxis: {
        title: "Jenjang Pendidikan",
      },
      legend: {
        align: "bottom",
        // visible: false,
        showCheckbox: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Jenjang_Pendidikan_Summary",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("JenjangPendidikanTheme", theme);
    options.theme = "JenjangPendidikanTheme";

    return (
      <React.Fragment>
        <ColumnChart data={data} options={options} />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Row style={{ height: "450px" }} className="my-3">
        <Col md="12">
          <div className="p-3">
            <Row className={style.wrapperTitleDemografi}>
              <div className={`${style.titleCardBI}`}>JENJANG PENDIDIKAN</div>
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div className="font-weight-bold mr-3">Jenis Konten </div>
                <div className={`${style.titleCardBI}`}>
                  <FilterJenisKonten
                    loading={loading}
                    setLoading={setLoading}
                    setDataType={setType}
                    dataType={type}
                  />
                </div>
              </div>
            </Row>
            <Row className="my-5">
              <Col md="12" style={{ display: "flex", justifyContent: "center" }}>
                <div className="mt-1 mx-auto">
                  {props.loading ? (
                    <div
                      className="text-center"
                      style={{ marginBottom: "150px", marginTop: "120px" }}>
                      <Spinner color="primary" type="grow" />
                    </div>
                  ) : props.data && Object.values(props.data).length !== 0 ? (
                    renderColumnChart(
                      type == "Video"
                        ? props.data.video
                        : type == "Berita"
                        ? props.data.news
                        : props.data.banner
                    )
                  ) : (
                    <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                      <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
