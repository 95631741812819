import React, { useState, useEffect } from "react";
import {
  Spinner,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Button,
  Input,
  ButtonDropdown,
} from "reactstrap";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import API from "../../services";

// ASSETS
import iconDown from "../../assets/icon/back.png";
import { useSelector } from "react-redux";

// STYLES
import "./styles.css";

// DOWNLOAD XLS
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import DownloadXLS from "./Download/XLS/DownloadDetailDemografi";

export default function FilterOrgProv(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);
  const [dropdownOrg, setDropdownOrg] = useState(false);
  const toggleDropdownOrg = () => setDropdownOrg((prevState) => !prevState);

  // const [pilihOrganization, setPilihOrganization] = useState("Pilih Organisasi");

  const [listTitle, setListTitle] = useState([]);
  const [listProvince, setListProvince] = useState([]);

  const [dataTitle, setDataTitle] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.filter.condition == "2"
      ? dataReduxLogHistory.data.filter.survey_title
      : ""
  );
  const [dataProvince, setDataProvince] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.filter.condition == "2"
      ? dataReduxLogHistory.data.filter.province
      : "all"
  );
  const [dataDate, setDataDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.filter.condition == "2"
      ? dataReduxLogHistory.data.filter.start_date
      : "Tanggal"
  );

  // const [isChange, setIsChange] = useState(false);
  // const toggleModalChange = () => {
  //   setIsChange(!isChange);
  // };
  // useEffect(() => {
  //   setDataProvince("all");
  // }, [isChange]);

  const getListTitle = () => {
    API.getFilterSurvey()
      .then((res) => {
        if (res.data.success) {
          setListTitle(res.data.data);
          getListProvince(res.data.data);
        } else {
          setListTitle([]);
        }
      })
      .catch((e) => {
        setListTitle(null);
      });
  };

  const onChangeTitle = (e) => {
    setDataTitle(e.title);
    setListProvince(e.province);
    setDataDate(e.date);
    setDataProvince("all");
    // setDataProvince(e.province[0]);
  };

  const getListProvince = (param) => {
    const titleFilter = param.filter((res) => {
      return res.title == dataTitle;
    });
    titleFilter.length !== 0 && setListProvince(titleFilter[0].province);
  };

  const onChangeClearTitle = () => {
    setDataTitle("");
    setListProvince([]);
    setDataDate("Tanggal");
    setDataProvince("all");
  };

  useEffect(() => {
    if (props.activeTab === "2") {
      props.setDataSave({
        province: dataProvince,
        survey_title: dataTitle,
        start_date: dataDate,
        measure: "survey",
        condition: "2",
      });
    }
    props.setDataProv(dataProvince);

    getListTitle();
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [dataTitle, dataProvince, props.activeTab]);

  const getData = () => {
    if (dataTitle == "") {
      return alert("Judul belum dipilih");
    } else {
      props.setShowComponent(false);
      props.setData([]);
      props.setLoading(true);
      const param = {
        date: dataDate,
        province: dataProvince,
        title: dataTitle,
      };
      API.getDataStatistikSurvey(param)
        .then((res) => {
          props.setData(res.data.data);
          props.setShowComponent(true);
          props.setLoading(false);
        })
        .catch((e) => {
          props.setData(null);
          props.setShowComponent(true);
          props.setLoading(false);
        });
    }
  };

  const resetFilter = () => {
    props.setShowComponent(false);
    props.setData([]);
    props.setLoading(false);
    setDataProvince("all");
    onChangeClearTitle();
  };

  useEffect(() => {
    if (
      Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.measure === "survey" &&
      dataReduxLogHistory.data.filter.condition == "2"
    ) {
      getData();
    }
  }, []);

  return (
    <>
      <Row className="py-2 mb-2">
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <div>Judul Survei</div>
          <Autocomplete
            // classes={style.dropdownAutocomplete}
            id="combo-box-demo"
            options={listTitle !== null ? listTitle : []}
            defaultValue={{ title: dataTitle }}
            value={{ title: dataTitle }}
            getOptionLabel={(option) => {
              return option.title;
            }}
            onChange={(e, value) => {
              value !== null ? onChangeTitle(value) : onChangeClearTitle();
            }}
            renderOption={(option) => (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    {option.title}
                    {
                      <span className="ml-2" style={{ fontSize: "10px" }}>
                        {option.date}
                      </span>
                    }
                  </div>
                </div>
              </React.Fragment>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // value={dataTitle}
                placeholder="Cari Judul Survei"
              />
            )}
            placeholder="Cari Judul Survei"
          />
        </Col>
        <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <div>Pilih Provinsi</div>
          <ButtonDropdown
            disabled={dataTitle == ""}
            style={{ width: "100%" }}
            isOpen={dropdownOrg}
            toggle={toggleDropdownOrg}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderColor: dataTitle == "" ? "#dbdbdb" : "#2C3280",
              }}>
              <div
                style={{
                  color: dataTitle == "" ? "#cccccc" : "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{dataProvince === "all" ? "SEMUA PROVINSI" : dataProvince}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setDataProvince("all");
                }}>
                SEMUA PROVINSI
              </DropdownItem>
              {listProvince === null ? (
                <DropdownItem>Terjadi Kesalahan Server</DropdownItem>
              ) : (
                listProvince.map((e, i) => (
                  <DropdownItem
                    key={i}
                    onClick={() => {
                      setDataProvince(e);
                    }}>
                    {e}
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        {/* <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <div>Tanggal Awal Survei</div>
          <ButtonDropdown
            style={{ width: "100%" }}
            disabled={true}
            isOpen={dropdown}
            toggle={toggleDropdown}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: dataTitle == "" ? "grey" : "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{dataDate}</span>
                <img style={{ height: "24px" }} src={iconDown} />
              </div>
            </DropdownToggle>
            <DropdownMenu
              style={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
              }}>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Video");
                }}>
                Video
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Banner");
                }}>
                Banner
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setDataType("Berita");
                }}>
                Berita
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col> */}
        <Col lg="1" style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {" "}
          <div style={{ color: "transparent" }}>-</div>
          <Button
            style={{
              backgroundColor: "#2C3280",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "100%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={getData}>
            <span style={{ fontSize: "11px" }}>Cari</span>
          </Button>
        </Col>
        <Col lg="1" style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {" "}
          <div style={{ color: "transparent" }}>-</div>
          <Button
            disabled={dataTitle == "" && dataProvince == "all" && !props.showComponent}
            style={{
              backgroundColor: "#C42127",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "100%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={resetFilter}>
            <span style={{ fontSize: "11px" }}>Reset</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}
