import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
//dropdown
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// -------------------ASSET------------------------
//css
import "./style.css";
import style from "./style.module.css";
import DownWhite from "../../../assets/images/backwhite.png";
// -------------------COMPONENT--------------------
//Import Breadcrumb
import Header from "../../../components/VerticalLayout/Header";
import TablePersetujuanPengguna from "../../../components/Admin/Tables/Pengguna/PersetujuanPengguna";
import TableKelolaPengguna from "../../../components/Admin/Tables/Pengguna/KelolaPengguna";

import { exportPDF } from "../../../components/Admin/Download/Pengguna/PDF/DownloadPDF";
import DownloadXLS from "../../../components/Admin/Download/Pengguna/XLS/DownloadXLS";
// -------------------REDUX--------------------
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function PenggunaAdmin(props) {
  let role = window.localStorage.getItem("role");
  //handle data xls
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [idNotif, setIdNotif] = useState("");
  const [activeSubTabKelola, setActiveSubTabKelola] = useState("1");
  const [dataSelect, setDataSelect] = useState("");

  //handle data when change tab
  const handleDataVideo = (vid) => {
    setData(vid);
  };

  const handleData2 = (data) => {
    setData2(data);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  //FileName
  const [titleDownload, setTitleDownload] = useState("");
  useEffect(() => {
    if (activeTab === "1") {
      setTitleDownload(`Persetujuan_Pengguna_${role}`);
    } else if (activeTab === "2" && activeSubTabKelola === "1") {
      setTitleDownload(`Kelola_Pengguna_Content_Partner_${role}`);
    } else if (activeTab === "2" && activeSubTabKelola === "2") {
      setTitleDownload(`Kelola_Pengguna_Content_Admin_${role}`);
    } else if (activeTab === "2" && activeSubTabKelola === "3") {
      setTitleDownload(`Kelola_Pengguna_Kurator_${role}`);
    } else if (activeTab === "2" && activeSubTabKelola === "4") {
      setTitleDownload(`Kelola_Pengguna_Support_${role}`);
    }
  }, [activeTab, activeSubTabKelola]);

  //handle download pdf
  const handleDownloadPDF = () => {
    exportPDF(data, data2, activeTab, activeSubTabKelola, titleDownload);
  };

  const tabStyle = {
    color: "#c42127",
  };
  const subTabStyle = {
    color: "#c42127",
    // borderBottom: "1px solid #c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };
  useEffect(() => {
    let notifContentType = props.dataNotif.data;
    if (notifContentType !== "empty") {
      let notifType = notifContentType.data.notif_type?.toLowerCase().replace(/\s+/g, "");
      if (notifType.includes("requestreactivate")) {
        //tab kelola pengguna
        setActiveTab("2");
        //tab content partner
        setActiveSubTabKelola("1");
      } else if (notifType.includes("register")) {
        setActiveTab("1");
      }
      setIdNotif(notifContentType.data.from);
    }
  }, [props.dataNotif.data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header
            title="Pengguna"
            className="SuperAdminHeader"
            btn={role == "Support Center" ? false : true}
            btntype="createpengguna"
            btntext="Tambah Pengguna"
          />
        </header>
        <main>
          <div className="container-fluid">
            {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Table" /> */}
            <MDBContainer fluid={true}>
              <div className="classic-tabs">
                <Row>
                  <Col className="col-12">
                    <MDBNav classicTabs className="wrapperNav">
                      <div className={style.wrapperTab}>
                        <MDBNavItem style={{ float: "left" }}>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "1" ? tabStyle : {}}
                            className="tabMenu waves-light"
                            active={activeTab === "1"}
                            onClick={() => handleChangeTab("1")}>
                            PERSETUJUAN
                          </MDBNavLink>
                          <hr style={activeTab === "1" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                        <MDBNavItem style={{ float: "left" }}>
                          <MDBNavLink
                            link
                            to="#"
                            style={activeTab === "2" ? tabStyle : {}}
                            className="tabMenu"
                            active={activeTab === "2"}
                            onClick={() => handleChangeTab("2")}>
                            KELOLA PENGGUNA
                          </MDBNavLink>
                          <hr style={activeTab === "2" ? customStyle : { display: "none" }} />
                        </MDBNavItem>
                      </div>
                      {/* <div className="wrapperAction">
                        <MDBNavLink to="#" className="textDownload">
                          Download
                        </MDBNavLink>
                        <ReactHTMLTableToExcel
                          id="TableXLS"
                          className="DownloadTableXLS"
                          table="DownloadTableXLS"
                          filename={titleDownload}
                          sheet="users"
                          buttonText="XLS"
                        />
                        <MDBNavLink onClick={handleDownloadPDF} link to="#">
                          PDF
                        </MDBNavLink>
                      </div>
                      <DownloadXLS
                        id="DownloadTableXLS"
                        className="DownloadTableXLS"
                        data={data}
                        data2={data2}
                        activeTab={activeTab}
                        activeSubTabKelola={activeSubTabKelola}
                      /> */}
                      <div className="wrapperDropdownDownload">
                        <MDBDropdown
                          style={{ width: "100% !important" }}
                          className="dropdown-download-doc">
                          <MDBDropdownToggle
                            caret
                            color="primary"
                            className="dropdown-download font-weight-bold">
                            <div className="wrapper-selected">Download</div>
                            <div>
                              <img src={DownWhite} alt="dropdown-icons" />
                            </div>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu className="dropdown-menus dropdown-show">
                            <MDBDropdownItem
                              style={{ color: "#556ee6" }}
                              className="text-center"
                              onClick={handleDownloadPDF}>
                              PDF
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                              <ReactHTMLTableToExcel
                                id="TableXLS"
                                className="DownloadTableXLSDropdown"
                                table="DownloadTableXLS"
                                filename={titleDownload}
                                sheet="users"
                                buttonText="XLS"
                              />
                            </MDBDropdownItem>
                            <DownloadXLS
                              id="DownloadTableXLS"
                              className="DownloadTableXLS"
                              data={data}
                              data2={data2}
                              activeTab={activeTab}
                              activeSubTabKelola={activeSubTabKelola}
                            />
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </div>
                    </MDBNav>

                    <MDBTabContent className="card" activeItem={activeTab}>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "1" && (
                          <Col className="col-12">
                            <TablePersetujuanPengguna
                              idNotif={idNotif}
                              handleData={handleDataVideo}
                              activeTab={activeTab}
                            />
                          </Col>
                        )}
                      </MDBTabPane>
                      <MDBTabPane tabId={activeTab}>
                        {activeTab === "2" && (
                          <Col className="col-12">
                            <MDBNav classicTabs className="wrapperNav">
                              <div className="wrapperTab">
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTabKelola === "1" ? subTabStyle : {}}
                                    className="tabMenu waves-light"
                                    active={activeSubTabKelola === "1"}
                                    onClick={() => setActiveSubTabKelola("1")}>
                                    Content Partner
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTabKelola === "2" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTabKelola === "2"}
                                    onClick={() => setActiveSubTabKelola("2")}>
                                    Content Admin
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTabKelola === "3" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={activeSubTabKelola === "3"}
                                    onClick={() => setActiveSubTabKelola("3")}>
                                    Kurator
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink
                                    link
                                    to="#"
                                    style={activeSubTabKelola === "4" ? subTabStyle : {}}
                                    className="tabMenu"
                                    active={setActiveSubTabKelola === "4"}
                                    onClick={() => setActiveSubTabKelola("4")}>
                                    Support
                                  </MDBNavLink>
                                </MDBNavItem>
                              </div>
                            </MDBNav>

                            <MDBTabContent
                              className="card"
                              activeItem={setActiveSubTabKelola}
                              style={{ marginTop: 30 }}>
                              <MDBTabPane tabId={setActiveSubTabKelola}>
                                {activeSubTabKelola === "1" && (
                                  <TableKelolaPengguna
                                    idNotif={idNotif}
                                    handleData={handleData2}
                                    activeTab={activeTab}
                                    role={"contentpartner"}
                                  />
                                )}
                                {activeSubTabKelola === "2" && (
                                  <TableKelolaPengguna
                                    idNotif={idNotif}
                                    handleData={handleData2}
                                    activeTab={activeTab}
                                    role={"contentadmin"}
                                  />
                                )}
                                {activeSubTabKelola === "3" && (
                                  <TableKelolaPengguna
                                    idNotif={idNotif}
                                    handleData={handleData2}
                                    activeTab={activeTab}
                                    role={"kurator"}
                                  />
                                )}
                                {activeSubTabKelola === "4" && (
                                  <TableKelolaPengguna
                                    idNotif={idNotif}
                                    handleData={handleData2}
                                    activeTab={activeTab}
                                    role={"support"}
                                  />
                                )}
                              </MDBTabPane>
                            </MDBTabContent>
                          </Col>
                        )}
                      </MDBTabPane>
                    </MDBTabContent>
                  </Col>
                </Row>
              </div>
            </MDBContainer>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(PenggunaAdmin));
