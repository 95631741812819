import React, { useMemo } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from "../../../../../assets/images/back.png";

const FilterDeclinedCat = (props) => {
  const { filter, clicked } = props;
  //memoize props list
  let listProvince = useMemo(() => {
    return props.list;
  }, [props.list]);
  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle color="primary" className="dropdown-kategori">
          <div className="wrapperDropdown">{filter === "" ? `Pilih ${props.type}` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus disabled browser-default">
          {listProvince.map((fc, i) => (
            <MDBDropdownItem active={filter === fc} onClick={() => clicked(fc, props.type)} key={i}>
              {fc}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

export default FilterDeclinedCat;
