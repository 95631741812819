import React from 'react'
import styles from '../../Assets/css/homepages.module.css'
import '../../Assets/css/mdbstyle.css'
import FrameBanner from '../../Assets/image/Frame.png'
import {
    Row,
    Col,
} from "reactstrap";
import {
    MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBRow, MDBCol
} from "mdbreact";

function HomeBanner(props) {
    return (
        <div className={styles.banner}>
            <section>
                <div className={styles.theText}>
                    <Row>
                        <Col xs="12" sm="12" md="6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <MDBContainer>
                                <MDBCarousel className="z-depth-1" activeItem={1} length={4} slide={true} showControls={false} showIndicators={true} multiItem>
                                    <MDBCarouselInner>
                                        <MDBRow>
                                            <MDBCarouselItem itemId="1">
                                                <MDBCol className={styles.bannerSec}>
                                                    <span className={styles.subText}>#FaktaBAKTI Tahukah Kamu?</span>
                                                    <span className={styles.secText}>BAKTI adalah satu-satunya platform komunikasi dua arah berbasis internet yang menjangkau masyarakat di daerah-daerah pelosok atau daerah 3T di seluruh Indonesia</span>
                                                </MDBCol>
                                            </MDBCarouselItem>
                                            <MDBCarouselItem itemId="2">
                                                <MDBCol className={styles.bannerSec}>
                                                    <span className={styles.subText}>#BersamaBAKTI Kita Cerdaskan Pelosok!</span>
                                                    <span className={styles.secText}>"Pendidikan adalah Senjata Paling Ampuh yang Bisa Anda Gunakan untuk Mengubah Dunia" Nelson Mandela</span>
                                                </MDBCol>
                                            </MDBCarouselItem>
                                            <MDBCarouselItem itemId="3">
                                                <MDBCol className={styles.bannerSec}>
                                                    <span className={styles.subText}>#DonasiKonten Positif Yuk!</span>
                                                    <span className={styles.secText}>Yuk Gabung Jadi Content Partner. #BersamaBAKTI Kita Sajikan Konten-Konten Digital Positif untuk Perkaya Wawasan dan Literasi Masyarakat di Pelosok Indonesia</span>
                                                </MDBCol>
                                            </MDBCarouselItem>
                                            <MDBCarouselItem itemId="4">
                                                <MDBCol className={styles.bannerSec}>
                                                    <span className={styles.subText}>#BersamaBAKTI Kita Upgrade SDM Pelosok!</span>
                                                    <span className={styles.secText}>#BersamaBAKTI Kita Wujudkan Masyarakat yang Merdeka Sinyal dan Melek Digitalisasi Hingga ke Seluruh Pelosok Indonesia. #YukBisaYuk Kita Tingkatkan Kualitas Sumber Daya Manusia Melalui Akses Internet </span>
                                                </MDBCol>
                                            </MDBCarouselItem>
                                        </MDBRow>
                                    </MDBCarouselInner>
                                </MDBCarousel>
                            </MDBContainer>
                        </Col>
                        <Col xs="0" sm="0" md="6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img className={styles.subImages} src={FrameBanner} />
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    )
}

export default HomeBanner