import React, { useState, useMemo } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from "../../../assets/images/back.png";

export const FilterCategory = (props) => {
  const { filter, clicked } = props;
  let listCategory = useMemo(() => {
    return props.list;
  }, [props.list]);
  return (
    <div className="w-100 ">
      <MDBDropdown>
        <MDBDropdownToggle color="primary" className="dropdown-rekomendasi">
          <div className="wrapperDropdown">{filter === "" ? `Pilih ${props.type}` : filter}</div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus disabled browser-default">
          {listCategory.map((fc, i) => (
            <MDBDropdownItem
              active={filter === fc}
              onClick={() => clicked(fc, "", props.type)}
              key={i}>
              {fc}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

export const FilterDuration = (props) => {
  const { filter, clicked } = props;
  let listDuration = useMemo(() => {
    return props.list;
  }, [props.list]);
  return (
    <div>
      <MDBDropdown>
        <MDBDropdownToggle color="primary" className="dropdown-rekomendasi">
          <div className="wrapperDropdown">
            {filter === "" ? `Pilih ${props.type}` : `${filter} jam`}
          </div>
          <div>
            <img src={Down} alt="dropdown-icons" />
          </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdown-menus">
          {listDuration.map((fc, i) => (
            <MDBDropdownItem
              active={filter === fc}
              onClick={() => clicked(fc, "", props.type)}
              key={i}>
              {`${fc} jam`}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};
