import React, { useEffect } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import "./style.css";
import { Container } from "reactstrap";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import moment from "moment";

const ModalHistory = (props) => {
  let it = props.data.data ? props.data.data : false;

  return (
    <MDBContainer>
      <MDBModal
        size="lg"
        isOpen={props.modalHistory}
        toggle={props.toggleModalHistory}
        centered={true}>
        <MDBModalHeader centered toggle={props.toggleModalHistory}>
          History
        </MDBModalHeader>
        <MDBModalBody>
          <Container>
            <MDBTable striped>
              <MDBTableHead>
                <tr className="text-center">
                  <th>Status</th>
                  <th>Tanggal</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {it
                  ? it.status_kurator.length > 0
                    ? it.status_kurator.map((e) => (
                        <tr className="text-center">
                          <td>{`${e.status}`}</td>
                          <td>{`${moment(e.date).format("DD-MM-YYYY")}`}</td>
                        </tr>
                      ))
                    : ""
                  : ""}
              </MDBTableBody>
            </MDBTable>
          </Container>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};
export default connect(mapStatetoProps, null)(ModalHistory);
