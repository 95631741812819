import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const AjukanKembaliContentPartnerAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/AjukanKembali/ContentPartner" /* webpackChunkName: "AjukanKembaliContentPartnerAsync" */
    ),
  loading: Loading,
});

export default AjukanKembaliContentPartnerAsync;
