import React from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
const PopoverComponent = ({ popoverOpen, text, target, setPopoverOpen }) => {
  return (
    <Popover
      placement="right"
      isOpen={popoverOpen}
      target={target}
      onClick={() => setPopoverOpen(false)}>
      <PopoverHeader style={{ color: "red", fontWeight: "bold" }}>!!!!!</PopoverHeader>
      <PopoverBody>{text}</PopoverBody>
    </Popover>
  );
};

export default PopoverComponent;
