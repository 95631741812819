import React, { useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormGroup,
  FormText,
  Button,
} from "reactstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import { useHistory } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SweetAlert from "react-bootstrap-sweetalert";
import IconGreenCheckList from "../../../assets/icon/IconGreenChecklist.svg";
import API from "../../../services";

export default function CardEditArticle(props) {
  const { data } = props;

  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);
  const [title, setTitle] = useState(data?.judul);
  const [imgArticle, setImgArticle] = useState("");
  const [valueSinopsis, setValueSinopsis] = useState(data?.body);
  const [typeVideo, setTypeVideo] = useState(data?.tipeVideo);
  const [link, setLink] = useState("https://youtube.com/watch?v=" + data?.link);
  const [videoFile, setVideoFile] = useState("");

  const [errorT, setErrorT] = useState(false);
  const [errorI, setErrorI] = useState(false);
  const [errorIM, setErrorIM] = useState("Terjadi Kesalahan");
  const [errorV, setErrorV] = useState(false);

  const users = window.localStorage.getItem("users");
  const Idusers = window.localStorage.getItem("idUser");
  let role = window.localStorage.getItem("role");

  const handleFormTitleArticle = (e) => {
    let { value } = e.target;
    setTitle(value);
  };

  const handleFormImgArticle = (e) => {
    let value = e.target.files[0];
    setImgArticle(value);
  };

  const handleOnChange = (e, editor) => {
    const data = editor.getData();
    setValueSinopsis(data);
  };

  const handleCloseAlert = () => {
    setTimeout(() => {
      setModal(false);
    }, 1500);
  };

  const handleCloseAlertFailed = () => {
    setTimeout(() => {
      setModalFailed(false);
    }, 1500);
  };

  const redirectAfter = (role) => {
    // Redirect after

    if (props.typeContent !== "Video") {
      if (role === "Artikel Admin") {
        setTimeout(() => {
          history.push("/adminartikel/artikel");
        }, 2500);
      } else {
        // Admin
        setTimeout(() => {
          history.push("/admin/artikel");
        }, 2500);
      }
    } else {
      if (role === "Artikel Admin") {
        setTimeout(() => {
          history.push("/adminartikel/video");
        }, 2500);
      } else {
        // Admin
        setTimeout(() => {
          history.push("/admin/video");
        }, 2500);
      }
    }
  };

  const handleFormVideo = (e) => {
    let value = e.target.files[0];
    let maxfilesize = 30 * 1024 * 1024; //100 MB
    let filesize = value.size;
    if (filesize > maxfilesize) {
      setVideoFile("");
      setErrorI(true);
      setErrorIM("Besar file Video melebihi batas maksimal");
    } else {
      setVideoFile(value);
      setErrorI(false);
    }
  };

  const handleChangeVideoType = (type) => {
    setTypeVideo(type);
  };

  const handleChangeLink = (link) => {
    setLink(link);
  };

  const handleEdit = (val) => {
    if (link) {
      var video_id = ytv(link);
    }

    const bodyFormData = new FormData();

    bodyFormData.append("username", users);
    // bodyFormData.append("userId", Idusers);

    if (typeContent === "Video") {
      if (imgArticle) {
        bodyFormData.append("thumbnail", imgArticle);
      }

      if (typeVideo === "local") {
        bodyFormData.append("file", videoFile);
      } else {
        bodyFormData.append("link", video_id);
      }
    } else {
      bodyFormData.append("file", imgArticle);
    }

    bodyFormData.append("judul", title);
    if (typeContent === "Video") {
      bodyFormData.append("body", valueSinopsis);
    } else {
      bodyFormData.append("idArticle", val._id)
      bodyFormData.append("body", valueSinopsis);
    }

    if (typeContent === "Video") {
      bodyFormData.append("tipeVideo", typeVideo);
    }

    for (var pair of bodyFormData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    if (typeContent !== "Video") {
      API.postEditArticle(bodyFormData)
        .then((response) => {
          if (response.data.success === true) {
            console.log(response.data);
            setModal(!modal);
            handleCloseAlert();
          } else {
            setModalFailed(!modalFailed);
            handleCloseAlertFailed();
          }

          redirectAfter(role);
        })
        .catch((e) => {
          return e;
        });
    } else {
      API.putEditVideo(bodyFormData, val._id)
        .then((response) => {
          if (response.data === "OK") {
            setModal(!modal);
            handleCloseAlert();
          } else {
            setModalFailed(!modalFailed);
            handleCloseAlertFailed();
          }

          // console.log("response ==> ", response);

          //redirectAfter(role);
        })
        .catch((e) => {
          return e;
        });
    }
  };

  const ytv = (link) => {
    console.log("link", link);

    var video_id = link.split("v=")[1];

    console.log("video_id", video_id);
    var ampersandPosition = video_id.indexOf("&");
    if (ampersandPosition != -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }

    return video_id;
  };

  let ckeditorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",

        "superscript",
        "subscript",
        "|",
        "link",

        "|",
        "bulletedList",
        "numberedList",
        "|",
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "",
  };

  var { typeContent } = props;
  typeContent = typeContent
    ? typeContent.charAt(0).toUpperCase() + typeContent.slice(1)
    : "Artikel";

  return (
    <div>
      <Container>
        <Card>
          <CardBody>
            <Form>
              <FormGroup>
                <Label for="titleArticle">Judul {typeContent}</Label>
                <Input
                  type="text"
                  name={`title${typeContent}`}
                  defaultValue={title}
                  onChange={handleFormTitleArticle}
                  id={`title${typeContent}`}
                  placeholder={`Tulis Judul ${typeContent}`}
                />
              </FormGroup>
              <FormGroup>
                <Label for="thumbnailImg">Thumbnail Gambar {typeContent}</Label>
                <Input
                  type="file"
                  accept=".jpg"
                  name="thumbnailImg"
                  onChange={handleFormImgArticle}
                  id="thumbnailImg"
                />
                <FormText color="muted">Format JPEG maksimal size 500kb</FormText>
              </FormGroup>
              {typeContent === "Video" ? (
                <FormGroup>
                  <Label for=""> Type Video </Label>
                  <Input
                    type="select"
                    className="col-md-4"
                    defaultValue={typeVideo}
                    value={typeVideo}
                    onChange={(event) => handleChangeVideoType(event.target.value)}>
                    <option value="local"> Local </option>
                    <option value="youtube"> Youtube </option>
                  </Input>
                </FormGroup>
              ) : (
                ""
              )}

              {typeContent === "Video" ? (
                <FormGroup>
                  <Label>Video {typeVideo === "youtube" ? "Link" : "File"}</Label>
                  {typeVideo === "local" ? (
                    <Input type="file" name="file" id="file" onChange={handleFormVideo} />
                  ) : (
                    ""
                  )}
                  {typeVideo === "youtube" ? (
                    <Input
                      type="text"
                      name="link"
                      id="link"
                      defaultValue={link}
                      onChange={(event) => handleChangeLink(event.target.value)}
                    />
                  ) : (
                    ""
                  )}
                </FormGroup>
              ) : (
                ""
              )}
              <FormGroup>
                <CKEditor
                  editor={ClassicEditor}
                  onChange={handleOnChange}
                  data={data?.body}
                  config={ckeditorConfig}
                />
              </FormGroup>
              <FormGroup row className="justify-content-center">
                <Button style={{ backgroundColor: "#2C3280" }} onClick={() => handleEdit(data)}>
                  Edit {typeContent}
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <SweetAlert show={modal} showConfirm={false}>
          <img src={IconGreenCheckList} alt="checklist" />
          <h4>Data Update Berhasil</h4>
        </SweetAlert>
        <SweetAlert danger show={modalFailed} showConfirm={false} title="Edit Gagal!"></SweetAlert>
      </Container>
    </div>
  );
}
