import React, { useEffect } from "react";
import { MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
const Navigation = (props) => {
  useEffect(() => {}, [props]);
  return (
    <MDBNav classicTabs className="wrapperNav">
      <div className="wrapperTabCreate mx-auto">
        <MDBNavItem>
          <MDBNavLink
            link
            to="#"
            style={props.activeTab === "video" ? props.tabStyle : {}}
            className="tabMenu waves-light"
            active={props.activeTab === "video"}
            onClick={() => props.handleChangeTab("video")}>
            VIDEO
          </MDBNavLink>
          <hr style={props.activeTab === "video" ? props.customStyle : { display: "none" }} />
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink
            link
            to="#"
            style={props.activeTab === "banner" ? props.tabStyle : {}}
            className="tabMenu"
            active={props.activeTab === "banner"}
            onClick={() => props.handleChangeTab("banner")}>
            BANNER
          </MDBNavLink>
          <hr style={props.activeTab === "banner" ? props.customStyle : { display: "none" }} />
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink
            link
            to="#"
            style={props.activeTab === "news" ? props.tabStyle : {}}
            className="tabMenu"
            active={props.activeTab === "news"}
            onClick={() => props.handleChangeTab("news")}>
            BERITA
          </MDBNavLink>
          <hr style={props.activeTab === "news" ? props.customStyle : { display: "none" }} />
        </MDBNavItem>
      </div>
    </MDBNav>
  );
};
export default Navigation;
