import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import _ from "lodash";
// ------------------API-----------------------
import API from "../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconRevise from "../../../../assets/icon/IconRevise.svg";
import IconFlag from "../../../../assets/icon/IconFlag.svg";

// ------------------Component----------------
import ModalBalasEmail from "../../Popup/ModalBalasEmail";
import ModalDetailPertanyaan from "../../Popup/ModalDetailPertanyaan";
import Filter from "../../../Filter/filter";
import FilterCalendar from "../../../Filter/filterCalendar";

// ------------------Redux----------------

import { connect } from "react-redux";

const TableTicket = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");
  const [tanggal, setTanggal] = useState("");

  const fetchAPIVideo = () => {
    setLoading(true);
    API.getAllTicket()
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }

        // setLoading(false);
        props.handleData(res.data.data);

        const onListData = res.data.data;

        props.handleData(onListData);

        let result =
          props.idContent && props.idContent.length
            ? onListData.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = onListData
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(onListData);
        } else {
          setRows(result);
          document.getElementById("TableTicket").rows[1].style.transition = "0.8s";
          document.getElementById("TableTicket").rows[1].style.backgroundColor = "#e6f3ff";
        }
        //still caused bug when click on pagination
        // let statusColumn = document
        //   .getElementById("TableTicket")
        //   .querySelectorAll("td:nth-child(9)");
        // let color = ["green", "red"];
        // statusColumn.forEach((el) => {
        //   color.map((color) => el.classList.add(color));
        // });
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;

    console.log("searchValue ==> ", searchValue);

    setSearch(searchValue);
  };

  //if post modal success, then refresh data
  useEffect(() => {
    if (props.categoryContent !== "0") {
      fetchAPIVideo(props.categoryContent);
    } else {
      fetchAPIVideo();
    }
  }, [refresh, props.idContent, props.categoryContent]);

  //throw data to parent for download
  const filterData = (item) => {
    props.handleData(item);
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search, tanggal]);

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "" && tanggal === "") return data;
      let status;
      switch (data.status) {
        case "baru":
          status = "Baru";
          break;
        case "on progress":
          status = "On Progress";
          break;
        case "closed":
          status = "Closed";
          break;
        default:
          status = data.status;
          break;
      }

      const index = [
        data.ticketNum,
        data.name ?? "-",
        data.email,
        data.type,
        data.kategori,
        data.level,
        status,
        //todo openAt is the correct date
        data ? (data.open ? `${moment(data.open.openAt).format("DD-MM-YYYY")}` : "") : "",
        data ? (data.closed ? `${moment(data.closed.closedAt).format("DD-MM-YYYY")}` : "") : "",
        data ? (data.closed ? data.closed.closedType : "") : "",
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
          date: tanggal,
        })
      ) {
        return data;
      }
    });
  }

  // const sortByStatus = _.flatten(_.partition(items, { status: "Baru" }));

  //modal balas email
  const [modalBalasEmail, setModalBalasEmail] = useState(false);
  const [dataModalBalasEmail, setDataModalBalasEmail] = useState({});
  const toggleModalBalasEmail = () => {
    setModalBalasEmail(!modalBalasEmail);
  };
  const handleModalBalasEmail = (it) => {
    setDataModalBalasEmail({
      data: it,
    });
    toggleModalBalasEmail();
  };
  //modal detail pertanyaan
  const [modalDetailPertanyaan, setModalDetailPertanyaan] = useState(false);
  const [dataModalDetailPertanyaan, setDataModalDetailPertanyaan] = useState({});
  const toggleModalDetailPertanyaan = () => {
    setModalDetailPertanyaan(!modalDetailPertanyaan);
  };
  const handleModalDetailPertanyaan = (it) => {
    setDataModalDetailPertanyaan({
      data: it,
    });
    toggleModalDetailPertanyaan();
  };
  //modal search by date
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  //make array for date search
  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  //handle selected date range
  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };

  //data status
  const Status = ({ status }) => {
    return (
      <div
        className={
          status == "baru"
            ? "baru"
            : status == "on progress"
            ? "onProgress"
            : status == "closed"
            ? "closedStatus"
            : ""
        }>
        {status == "baru"
          ? "Baru"
          : status == "on progress"
          ? "On Progress"
          : status == "closed"
          ? "Closed"
          : ""}
      </div>
    );
  };

  //data aksi
  const ButtonAksi = ({ it }) => {
    return (
      <Row className="d-flex flex-wrap">
        <div className="mr-1">
          <Button
            className="btn-rounded btnDetail btnDetailCostum"
            onClick={() => handleModalDetailPertanyaan(it)}>
            Detail
          </Button>
        </div>
        {it.type == "Email" && it.status !== "Closed" ? (
          <div className="d-flex align-items-center mr-2">
            <>
              <img
                className="btnAction"
                src={IconRevise}
                onClick={() => handleModalBalasEmail(it)}></img>
            </>
          </div>
        ) : (
          <></>
        )}
        {it.level == "2" ? (
          <div className="d-flex align-items-center mr-1">
            <>
              <img className="btnAction" src={IconFlag}></img>
            </>
          </div>
        ) : (
          <></>
        )}
      </Row>
    );
  };

  const getStatus = (it) => {
    let statusSort = `${it.status.toLowerCase()}`;
    let statusComponent = <Status status={it.status.toLowerCase()} />;
    return [statusComponent];
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 80,
      },
      {
        label: "No. Tiket",
        field: "notiket",
        sort: "asc",
        width: 100,
      },
      {
        label: "Nama/Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 200,
      },
      {
        label: "No Handphone",
        field: "phone",
        sort: "asc",
        width: 150,
      },
      {
        label: "Jenis",
        field: "jenis",
        sort: "asc",
        width: 100,
      },
      {
        label: "Sumber",
        field: "sumber",
        sort: "asc",
        width: 100,
      },
      {
        label: `Kategori`,
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      {
        label: "Level",
        field: "level",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tanggal Masuk",
        field: "tanggal_masuk",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Closed",
        field: "tanggal_closed",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kepuasan",
        field: "p",
        sort: "disabled",
        width: 100,
      },

      {
        label: "",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              waktu_registrasi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.length !== 0
        ? items.map((it, index) => ({
            no: index + 1,
            notiket: it.ticketNum ?? "-",
            organisasi: it.name ? it.name : it.email ? it.email : "-",
            phone: it.phone ?? "-",
            jenis: it ? (it.type ? it.type ?? "-" : "-") : "-",
            sumber: it ? (it.origin ? it.origin ?? "-" : "-") : "-",
            kategori: it ? (it.kategori ? it.kategori ?? "-" : "-") : "-",
            level: it ? (it.level ? it.level : "-") : "-",
            tanggal_masuk: it
              ? it.open
                ? it.open.openAt
                  ? ` ${moment(it.open.openAt).format("YYYYMMDD")}
           ${moment(it.open.openAt).format("DD-MM-YYYY")}`
                  : "-"
                : "-"
              : "-",
            tanggal_closed: it
              ? it.closed
                ? it.closed.closedAt
                  ? ` ${moment(it.closed.closedAt).format("YYYYMMDD")}
                  ${moment(it.closed.closedAt).format("DD-MM-YYYY")}
                  `
                  : "-"
                : "-"
              : "-",
            status: it ? (it.status ? getStatus(it).map((el) => el) : "-") : "-",
            p: it ? (it.closed ? it.closed.closedType : "-") : "-",
            aksi: <ButtonAksi it={it} />,
          }))
        : [],
  };

  return (
    <React.Fragment>
      <FilterCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        selectedDate={handleSelectedDate}
      />
      <ModalBalasEmail
        dataModalBalasEmail={dataModalBalasEmail}
        modalBalasEmail={modalBalasEmail}
        toggleModalBalasEmail={toggleModalBalasEmail}
      />
      <ModalDetailPertanyaan
        dataModalDetailPertanyaan={dataModalDetailPertanyaan}
        modalDetailPertanyaan={modalDetailPertanyaan}
        toggleModalDetailPertanyaan={toggleModalDetailPertanyaan}
      />
      <Row>
        <Col className="col-12">
          <Card className="wrapperCard">
            <CardBody>
              <div className="d-flex ml-auto w-50 my-3">
                <Button
                  color=""
                  onClick={toggleModalCalendar}
                  id="tanggal"
                  className="inputWrapper btnTanggal"
                  style={{ marginRight: "10px" }}>
                  <p className="selectedDate">
                    {selectedDate}
                    <span class="mdi mdi-18px mdi-calendar-range" style={{ float: "right" }} />
                  </p>
                </Button>
                <input
                  placeholder="Cari..."
                  ref={searchRef}
                  className="form-control"
                  onChange={handleSearch}
                  type="text"
                />
              </div>

              {rows.length === 0 && !isEmpty ? (
                <div>
                  <h1>
                    <Skeleton />
                  </h1>
                  <Skeleton count={10} />
                </div>
              ) : isEmpty ? (
                <div className="justify-content-md-center">Tidak Ada Data</div>
              ) : (
                <div style={{ whiteSpace: "pre-line" }}>
                  <MDBDataTable
                    id="TableTicket"
                    noBottomColumns={true}
                    className="text-center"
                    disableRetreatAfterSorting
                    striped
                    bordered
                    scrollX
                    data={data}
                    displayEntries={false}
                    pagesAmount={5}
                    entries={10}
                    searching={false}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TableTicket);
