import React, { useState } from 'react'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

import Down from '../../../assets/images/back.png';
import './style.css'

function FilterButtonTime(props) {
    const { filter, clicked } = props;
    const [filterContain ] = useState([
        "00.00",
        "01.00",
        "02.00",
        "03.00",
        "04.00",
        "05.00",
        "06.00",
        "07.00",
        "08.00",
        "09.00",
        "10.00",
        "11.00",
        "12.00",
        "13.00",
        "14.00",
        "15.00",
        "16.00",
        "17.00",
        "18.00",
        "19.00",
        "20.00",
        "21.00",
        "22.00",
        "23.00",
    ]);

    return (
        <div>
            <MDBDropdown>
                <MDBDropdownToggle caret color='primary' className="dropdown-rekomendasi">
                    <div>{filter === '' ? 'Pilih Jam' : filter}</div>
                    <div><img src={Down} alt="dropdown-icons"/></div>
                </MDBDropdownToggle>
                <MDBDropdownMenu className='dropdown-menus'>
                    {filterContain.map((fc, i) => (
                        <MDBDropdownItem active={filter === fc} onClick={() => clicked(fc)} key={i}>
                            {fc}
                        </MDBDropdownItem>
                    ))}
                </MDBDropdownMenu>
            </MDBDropdown>
        </div>
    )
}
export default FilterButtonTime;