import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, CardBody } from "reactstrap";

export default function CardDetailChart(props) {
  return (
    <>
      <Card style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)", height: "230px" }}>
        <CardBody style={{ height: "50%" }}>
          <h4 className="m-4" style={{ fontWeight: "bold" }}>
            TOTAL IMPRESI KONTEN
          </h4>
          <Row className="my-3">
            <Col md="4">
              <Row
                className="d-flex justify-content-center"
                style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
                <div>
                  <div className="title-card" style={{ color: "#8CC6C9" }}>
                    VIDEO
                  </div>
                  <div id="wrapper-impressVid" className="value-detail-admin my-4">
                    {!props.loading
                      ? props.data
                        ? props.data[6]
                          ? props.data[6].totalImpresiVideo.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
            </Col>
            <Col md="4">
              <Row
                className="d-flex justify-content-center"
                style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
                <div>
                  <div className="title-card" style={{ color: "#E2B674" }}>
                    BANNER
                  </div>
                  <div id="wrapper-impressBanner" className="value-detail-admin my-4">
                    {!props.loading
                      ? props.data
                        ? props.data[4]
                          ? props.data[4].totalImpresiBanner.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
            </Col>
            <Col md="4">
              <Row className="d-flex justify-content-center">
                <div>
                  <div className="title-card" style={{ color: "#C18ECE" }}>
                    BERITA
                  </div>
                  <div id="wrapper-impressNews" className="value-detail-admin my-4">
                    {!props.loading
                      ? props.data
                        ? props.data[5]
                          ? props.data[5].totalImpresiNews.toLocaleString() ?? 0
                          : 0
                        : 0
                      : ""}
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)", height: "230px" }}>
        <CardBody>
          <h4 className="m-4" style={{ fontWeight: "bold" }}>
            TOTAL TITIK AKSES INTERNET TERINTEGRASI
          </h4>
          <Row className="my-3">
            <div
              id="wrapper-totalIntegrated"
              className="value-detail my-5 mx-auto"
              style={{ width: "100px" }}>
              {!props.loading
                ? props.data
                  ? props.data[11]
                    ? props.data[11].totalIntegrasi.toLocaleString() ?? 0
                    : 0
                  : 0
                : ""}
            </div>
          </Row>
        </CardBody>
      </Card>

      {/* icms lite integration */}
      {/* <Card style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)", height: "230px" }}>
        <CardBody>
          <h4 className="m-4" style={{ fontWeight: "bold" }}>
            TOTAL TITIK TERINTEGRASI ICMS LITE
          </h4>
          <Row className="my-3">
            <div
              id="wrapper-totalIntegrated"
              className="value-detail my-5 mx-auto"
              style={{ width: "100px" }}>
              {!props.loading ? props.data?.[12]?.totalIntegrasiLpLite?.total?.toLocaleString() || 0 : ""}
            </div>
          </Row>
        </CardBody>
      </Card> */}
    </>
  );
}
