import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const SuperAdminProfileAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/Authentication/superAdminProfile" /* webpackChunkName: "SuperAdminProfileAsync" */
    ),
  loading: Loading,
});

export default SuperAdminProfileAsync;
