import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Container, Alert, Button } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Filter from "../../../../Filter/filter";
import ReactPaginate from "react-paginate";
import ProvinceModal from "./../modals/ProvinceModal";
// ------------------Download---------------------
// import { exportPDF } from "../../../../../components/Admin/Download/DataSiteAI/PDF/DownloadPDF";
// import DownloadXLS from "../../../../../components/Admin/Download/DataSiteAI/XLS/DownloadXLS";
// ------------------REDUX---------------------
import { connect } from "react-redux";
import { detailData } from "../../../../../store/actions";
// ------------------API-----------------------
import API from "../../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
// import ModalDataAI from "../"

const TableDataSiteAI = (props) => {
  let history = useHistory();

  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalProvince, setModalProvince] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState([]);
  const [selectedAll, setSelectedAll] = useState(true);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageDisplay, setPageDisplay] = useState(0);

  var items = rows;

  const fetchAPIVideo = async (params) => {
    setPage(params.page ?? 1);
    setLoading(true);

    await API.getDataSiteAI(params)
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
          setRows([]);
          setLoading(false);
        } else {
          setIsEmpty(false);
          setPageCount(res.data.count);
          setPageDisplay(10);
          setRows(res.data.data);

          setLoading(false);
        }

        return API.getDataSiteAI({
          provinsi: params.provinsi,
          search: params.search,
          limit: 0,
          skip: 0,
        });
      })
      .then((res) => {
        props.handleData([]);
        if (res.data.data.length > 0) {
          props.handleData(res.data.data);
        }

        setLoading(false);
      })
      .catch((e) => {
        //history.push("/pages-500");
        setRows([]);
        alert(e.message);
        setLoading(false);
      });
  };

  const handlePageClick = (data) => {
    //console.log("data from handle ==> ", data);
    //const searchValue = searchRef.current.value;
    setPage(data.selected + 1);
  };

  useEffect(() => {
    const params = { limit: 10 };

    const searchValue = searchRef.current.value;

    if (page) {
      params.page = page;
      params.skip = (page - 1) * 10;
    }

    if (searchValue) {
      params.search = searchValue;
      // props.setSearch(searchValue);
    }

    if (selectedProvince.length > 0) {
      params.provinsi = selectedProvince
        .map((item) => {
          return item.name;
        })
        .join();
      // props.setProvince(params.provinsi);
    }

    fetchAPIVideo(params);
  }, [page]);

  // useEffect(() => {
  //   const params = { limit: 10 };
  //   fetchAPIVideo(params);
  // }, []);

  //modal DataAI
  const [modalDataAI, setModalDataAI] = useState(false);
  const [dataModalDataAI, setDataModalDataAI] = useState({});
  const toggleModalDataAI = () => {
    setModalDataAI(!modalDataAI);
  };
  // const handleModalDetailSiteAI = (title, data) => {
  //   setDataModalDataAI({
  //     url: data.emergency,
  //     title: title,
  //   });
  //   toggleModalDataAI();
  // };

  // const filterData = (item) => {
  //   props.handleData(item);
  // };
  //set search data based on input value
  const handleSearch = () => {
    const params = { limit: 10 };

    const searchValue = searchRef.current.value;

    params.page = 1;
    params.skip = 0;

    if (searchValue) {
      params.search = searchValue;
    }

    if (selectedProvince.length > 0) {
      params.provinsi = selectedProvince
        .map((item) => {
          return item.name;
        })
        .join();
    }

    // props.setSearch(searchValue);
    // props.setProvince(params.provinsi);

    fetchAPIVideo(params);
    // setLoading(true);
    // setPage(1);

    // setTimeout(function () {
    //   setLoading(false);
    // }, 3000);
  };

  const toggleModalProvince = () => {
    setModalProvince(!modalProvince);
  };

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Nama Lokasi AI",
        field: "location",
        sort: "asc",
        width: 150,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kabupaten",
        field: "kabupaten",
        sort: "asc",
        width: 150,
      },
      {
        label: "Default Bandwidth",
        field: "bandwidth",
        sort: "asc",
        width: 150,
      },
      {
        label: "IP Address",
        field: "ip",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "",
      //   field: "aksi",
      //   sort: "disabled",
      //   width: 150,
      // },
    ],

    rows:
      rows === null
        ? [
            {
              location: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, index) => ({
            no: index + (page - 1) * 10 + 1,
            location: it ? (it.name ? it.name : "-") : "-",
            provinsi: it ? (it.province ? it.province : "-") : "-",
            kabupaten: it ? (it.kab_kota ? it.kab_kota : "-") : "-",
            bandwidth: it ? (it.bandwidth ? it.bandwidth : "-") : "-",
            ip: it
              ? it.ip
                ? it.ip.length > 2
                  ? `${it ? (it.ip[0] ? it.ip[0] : "-") : "-"}, ${
                      it ? (it.ip[1] ? it.ip[1] : "-") : "-"
                    }, ${it ? (it.ip[2] ? it.ip[2] : "-") : "-"}...`
                  : it.ip.length > 1
                  ? `${it.ip[0] ? it.ip[0] : "-"},${it.ip[1] ? it.ip[1] : "-"}`
                  : `${it.ip[0] ? it.ip[0] : "-"}`
                : "-"
              : "-",
            // aksi: (
            //   <div>
            //     <Button onClick={() => handleModalDetailSiteAI} className="btn-rounded btnDetail">
            //       Detail
            //     </Button>
            //   </div>
            // ),
          })),
  };

  return (
    <React.Fragment>
      {/* <ModalDataAI
        dataModalDataAI={dataModalDataAI}
        modalDataAI={modalDataAI}
        toggleModalDataAI={toggleModalDataAI}
      /> */}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="text-center" style={{ width: "100%", textAlign: "center" }}>
                    {loading ? <Spinner color="primary" /> : ""}
                  </div>
                  <div className="d-flex ml-auto w-25 my-3">
                    <Button
                      type="button"
                      style={{ backgroundColor: "#2c3280" }}
                      onClick={toggleModalProvince}>
                      Provinsi
                    </Button>
                    <input
                      style={{ margin: "0 5px 0 5px" }}
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      type="text"
                    />
                    <Button
                      className="btn btn-primary btn-sm"
                      onClick={handleSearch}
                      style={{ margin: "0 5px 0 5px", backgroundColor: "#2c3280" }}
                      value={search}
                      disabled={loading}>
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  {rows.length === 0 && !isEmpty && loading ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">Data tidak ditemukan</div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="tableDataSiteAI"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                        paging={false}
                      />
                      <div className="float-left">
                        <span>
                          Showing {(page - 1) * 10 + 1} to{" "}
                          {(page - 1) * 10 + 10 > pageCount ? pageCount : (page - 1) * 10 + 10} of{" "}
                          {pageCount}
                        </span>
                      </div>
                      <div className="float-right">
                        <ReactPaginate
                          pageCount={Math.ceil(pageCount / pageDisplay)}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          initialPage={page - 1} // INDEX
                          forcePage={page - 1}
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          activeClassName="active"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"></ReactPaginate>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ProvinceModal
          toggleModalProvince={toggleModalProvince}
          modalProvince={modalProvince}
          setSelectedProvince={setSelectedProvince}
          selectedProvince={selectedProvince}
          selectedAll={selectedAll}
          setSelectedAll={setSelectedAll}
          handleSearch={handleSearch}></ProvinceModal>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStatetoProps, { detailData })(TableDataSiteAI);
