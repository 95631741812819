import React, { useEffect } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLSLokasi" className="DownloadTableXLS">
      <thead>{`DAFTAR KATEGORI LOKASI DI PROVINSI ${props.prov}`}</thead>
      <thead>
        <tr>{`PERIODE CUT OFF TANGGAL 01/01/2020 - ${props.date}`}</tr>
      </thead>
      <thead>
        <tr>
          <th>Kategori Lokasi</th>
          <th>Jumlah Lokasi AI Terintegrasi</th>
          <th>Jumlah IP Terintegrasi</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => (
          <tr key={index} style={{ textAlign: "center", verticalAlign: "middle" }}>
            <td>{data.location ?? "-"}</td>
            <td>{data.ai ?? "-"}</td>
            <td>{data.ip ?? "-"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
