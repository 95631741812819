import React, { useEffect, useState } from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";

function PopupBanner(props) {
  let bannerData =
    Object.keys(props.data).length === 0
      ? ""
      : typeof props.data.banner._id !== "undefined" && props.data.banner;

  let bannerCategory = bannerData.category ? bannerData.category : "-";
  let [srcImg, setSrcImg] = useState("");
  //content by user ?

  useEffect(() => {
    if (bannerData !== "" && typeof bannerData.details !== "undefined") {
      if (typeof bannerData.details.url !== "undefined") {
        setSrcImg(bannerData.details.url);
      } else {
        setSrcImg(bannerData.details);
      }
    }
  }, [bannerData]);

  return (
    <MDBContainer>
      <MDBModal size="lg" isOpen={props.modalBanner} toggle={props.toggleBanner} centered={true}>
        <MDBModalHeader toggle={props.toggleBanner}>Banner</MDBModalHeader>
        <MDBModalBody>
          <h5 style={{ textAlign: "center" }}>{bannerCategory}</h5>
          <div className="image-wrapper">
            <img
              src={`${process.env.REACT_APP_BE_URL}/${srcImg}`}
              className="image-container"
              alt="Banner"
            />
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
}
export default PopupBanner;
