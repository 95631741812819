import React, { useState, useEffect } from "react";
import { MDBModal } from "mdbreact";
import { Spinner, Row, Container, Col, FormText } from "reactstrap";

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "./style.css";
import Swal from "sweetalert2";
// ------------------API-----------------------
import API from "../../../../services";
// --------------COMPONENT--------------
import ReplyMessage from "../../../../components/SupportCenter/Popup/ReplyMessage/ReplyMessage";
const ModalBalasEmail = ({ modalBalasEmail, toggleModalBalasEmail, dataModalBalasEmail }) => {
  const [detailTicket, setDetailTicket] = useState({});
  const getDetailEmail = () => {
    setDetailTicket(dataModalBalasEmail ? dataModalBalasEmail.data : "");
  };
  useEffect(() => {
    if (Object.keys(dataModalBalasEmail).length !== 0) getDetailEmail();
  }, [dataModalBalasEmail]);
  const [message, setMessage] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  //modal loading
  const [modalLoading, setModalLoading] = useState(false);
  const [successSubmitted, setSuccessSubmitted] = useState("");
  //error
  const [error, setError] = useState({
    message: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false)

  const onHandleSubmit = () => {

    setDisableSubmit(true)

    if (message === "") {
      setError((prev) => ({
        ...prev,
        message: "Kolom jawaban tidak boleh kosong",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        message: "",
      }));
      let idSupport = window.localStorage.getItem("idUser");
      let param = {
        id: dataModalBalasEmail.data._id,
        idSupport: idSupport,
        message: message,
      };
      // check last answer

      API.postReplyEmail(param)
        .then((res) => {
          setModalLoading(true);
          setDisableSubmit(false)
          if (res.data.success) {
          
            setMessage("");
            setIsRefresh(!isRefresh);
            Swal.fire({
              position: "center",
              icon: "success",
              title: `Berhasil Balas Ticket`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Tidak Berhasil Balas Ticket`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `Tidak Berhasil Balas Ticket`,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };
  return (
    <React.Fragment>
      <MDBModal isOpen={modalBalasEmail} toggle={toggleModalBalasEmail} size="lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="w-100 text-center">
              <p className="topTitleDelete">Tiket #{detailTicket ? detailTicket.ticketNum : ""}</p>
            </div>
            <button
              type="button"
              className="close pull-right"
              aria-label="Close"
              onClick={toggleModalBalasEmail}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
            <div className="w-60 mb-2">
              <h4 className="subTitleDecline"> {detailTicket ? detailTicket.message : ""}</h4>
            </div>
            <Col lg="8" className="mb-1 mt-1">
              <Row>
                <Container className="textBoxReply">
                  <ReplyMessage isRefresh={isRefresh} data={detailTicket} />
                </Container>
              </Row>
            </Col>

            <div className="wrapperTextAreaDecline mb-1">
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Tulis Jawaban Disini..."
                value={message}
                className="form-control textAreaDecline requiredField mb-1"
                rows="1"></textarea>
              
              {/* <div dangerouslySetInnerHTML={{__html:message}}></div> */}
              {/* <CKEditor
                  editor={ClassicEditor}
                  onChange={(e, editor) => {
                    const data = editor.getData()
                    setMessage(data)
                  }}
                  data={message}
                  
                /> */}
              {
                <FormText className="formTextError" color="#c42127">
                  {error.message}
                </FormText>
              }
            </div>
            <div className="mx-auto wrapperBtnVerification">
              <button
                disabled={disableSubmit}
                type="button"
                onClick={onHandleSubmit}
                className="w-100 btn btnDelete btn-rounded">
                Submit
              </button>
            </div>
          </div>
        </div>
      </MDBModal>
    </React.Fragment>
  );
};

export default ModalBalasEmail;
