import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../../components/Filter/filter";
import _ from "lodash";
import IconEmergency from "../../../../assets/icon/IconEmergency.svg";
// ------------------API-----------------------
import API from "../../../../services";

// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconGreenCheckList from "../../../../assets/icon/IconGreenChecklist.svg";
import IconInfo from "../../../../assets/icon/IconInfo.svg";

// ------------------Component----------------
import ModalActived from "../../../../components/Admin/Popup/KontenDefault/ActivedContent";
import ModalPreviewContent from "../../../../components/Admin/Popup/KontenDefault/PreviewContent";
import DetailKonten from "../../../../components/Admin/Popup/Konten/DetailKonten";
import ModalLolos from "../../../../components/Admin/Popup/Konten/Lolos";
import ModalTidakLolos from "../../../../components/Admin/Popup/Konten/TidakLolos";
import PopupDetailRevisi from "../../../../components/Kurator/Popup/ReviewKonten/PopupDetailRevisi";
import FilterCalendar from "../../../Filter/filterCalendar";
import ModalPreviewEmergencyDoc from "../../../../components/Admin/Popup/Konten/PreviewEmergencyDoc";

// ------------------Redux----------------

import { connect } from "react-redux";
import noImage from "../../../../assets/images/noImage.jpg";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
const TableKontenAdmin = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sDate, setStartDate] = useState("");
  const [eDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tanggal, setTanggal] = useState("");

  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //throw data to check availability
    setStartDate(startDate.format("DD-MM-YYYY"));
    setEndDate(endDate.format("DD-MM-YYYY"));
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };

  //notif
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [notif, setNotif] = useState(false);
  //alert
  const [alert, setAlert] = useState("");
  let typeContent = "";
  switch (props.activeTab) {
    case "1":
      typeContent = "video";
      break;
    case "2":
      typeContent = "banner";
      break;
    case "3":
      typeContent = "news";
      break;
    default:
      break;
  }
  //modal doc
  const [modalDoc, setModalDoc] = useState(false);
  const [dataModalDoc, setDataModalDoc] = useState({});
  const toggleModalDoc = () => {
    setModalDoc(!modalDoc);
  };
  const handleModalDoc = (title, data) => {
    setDataModalDoc({
      url: data.emergency,
      title: title,
    });
    toggleModalDoc();
  };
  const fetchAPI = () => {
    setLoading(true);
    API.getKontenAdmin(typeContent)
      .then((res) => {
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setLoading(false);
        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(res.data.data);
        } else {
          setRows(result);
          document.getElementById("TableKontenAdmin").rows[1].style.transition = "0.8s";
          document.getElementById("TableKontenAdmin").rows[1].style.backgroundColor = "#e6f3ff";
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    fetchAPI();
  }, [refresh, props.idContent]);
  //throw data to parent for download
  const filterData = (item) => {
    // console.log("yg bkl ke lempar", item)
    props.handleData(item);
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search, props.activeTab]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      if (search === "" && tanggal === "") return data;
      let statusKurator = data.status_kurator[data.status_kurator.length - 1].status;
      let status = data.status;
      let emergency = data.emergency;
      const statusSearched =
        status === "submitted"
          ? emergency
            ? "emergency"
            : statusKurator === "Lolos Kurasi"
            ? "Lolos Kurasi"
            : statusKurator === "Tidak Lolos Kurasi"
            ? "Tidak Lolos Kurasi"
            : statusKurator === "Revisi"
            ? "Revisi"
            : statusKurator === "Sudah Revisi"
            ? "Sudah Revisi"
            : statusKurator
          : status === "declined"
          ? "Ditolak"
          : status === "approved"
          ? "Disetujui"
          : status === "scheduled"
          ? "Tayang"
          : data.status == "declined"
          ? "ditolak"
          : data.status == "Terjadwal"
          ? "Terjadwal"
          : data.status == "submitted"
          ? "Baru"
          : data.status == "canceled"
          ? "Dibatalkan"
          : "";
      const index = [
        data.details.title ? data.details.title : data.title,
        data.category,
        data.proposed_duration,
        data.list_provinsi.map((prov) => prov.name).toString(),
        data.location && data.location.join(),
        data.proposed_date
          .map((date) => moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"))
          .toString(),
        statusSearched,
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
          date: tanggal,
        })
      ) {
        return data;
      }
    });
  }

  //modal detail
  const [modalDetail, setModalDetail] = useState(false);
  const [dataModalDetail, setDataModalDetail] = useState({});
  const toggleModalDetail = () => {
    setModalDetail(!modalDetail);
  };

  const handleModalActivedContent = (data) => {
    setDataModalDetail({
      data: data,
      title:
        props.activeTab == "3"
          ? typeof data.title !== undefined
            ? data.title
            : "-"
          : typeof data.details.title !== undefined
          ? data.details.title
          : "-",
      param: {
        idContent: data._id,
        idProvince: data.proposed_provinsi._id,
      },
    });
    toggleModalDetail();
  };
  //modal preview video
  const [modalPreviewContent, setModalPreviewContent] = useState(false);
  const [dataModalPreviewContent, setDataModalPreviewContent] = useState({});
  const toggleModalPreviewContent = () => {
    setModalPreviewContent(!modalPreviewContent);
  };
  const handleModalPreviewContent = (data) => {
    setDataModalPreviewContent({
      title:
        props.activeTab == "3"
          ? data.title
            ? data.title
            : "-"
          : data.details.title
          ? data.details.title
          : "-",
      url: data.details.url,
      type: data.type ?? "-",
    });
    toggleModalPreviewContent();
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  //modal detail konten
  const [ModalDetailKonten, setModalDetailKonten] = useState(false);
  const [dataModalDetailKonten, setDataModalDetailKonten] = useState({});
  const toggleModalDetailKonten = () => {
    setModalDetailKonten(!ModalDetailKonten);
  };
  const handleModalDetailKonten = (it) => {
    setDataModalDetailKonten({
      data: it,
    });
    toggleModalDetailKonten();
  };
  //modal lolos
  const [modalLolos, setModalLolos] = useState(false);
  const [dataModalLolos, setDataModalLolos] = useState({});
  const toggleModalLolos = () => {
    setModalLolos(!modalLolos);
  };
  const handleModalLolos = (it) => {
    setDataModalLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      activeTab: props.activeTab,
    });
    toggleModalLolos();
  };
  //modal Revisi
  const [modalRevisi, setModalRevisi] = useState(false);
  const [dataModalRevisi, setDataModalRevisi] = useState({});
  const toggleModalRevisi = () => {
    setModalRevisi(!modalRevisi);
  };
  const handleModalRevisi = (it) => {
    setDataModalRevisi({
      title: props.activeTab == "3" ? it.title : it.details.title,
      detail: it ? (it.comment ? it.comment : "-") : "-",
      listProv: it ? (it.list_provinsi ? it.list_provinsi : "-") : "-",
      jamTayang: it ? (it.proposed_time ? it.proposed_time : "-") : "-",
      tglTayang: it ? (it.proposed_date ? it.proposed_date : "-") : "-",
    });
    toggleModalRevisi();
  };

  //modal Tidak Lolos
  const [modalTidakLolos, setModalTidakLolos] = useState(false);
  const [dataModalTidakLolos, setDataModalTidakLolos] = useState({});
  const toggleModalTidakLolos = () => {
    setModalTidakLolos(!modalTidakLolos);
  };
  const handleModalTidakLolos = (it) => {
    setDataModalTidakLolos({
      title: props.activeTab == "3" ? it.title : it.details.title,
      tgl: moment(it.status_kurator.slice(-1).pop().date).format("DD-MM-YYYY"),
      comment: it.comment,
      activeTab: props.activeTab,
    });
    toggleModalTidakLolos();
  };

  const ButtonAction = ({ it }) => {
    if (it.emergency) {
      return (
        <>
          <UncontrolledTooltip placement="bottom" target="TooltipEmergency">
            Detail Konten Emergency
          </UncontrolledTooltip>
          <img
            id="TooltipEmergency"
            className="btnAction"
            src={IconEmergency}
            onClick={() =>
              handleModalDoc(it.details.title ? it.details.title : it.title, it)
            }></img>
        </>
      );
    } else {
      return (
        <>
          <UncontrolledTooltip placement="bottom" target="TooltipDetail">
            Detail Konten
          </UncontrolledTooltip>
          <img
            id="TooltipDetail"
            className="btnAction"
            src={IconInfo}
            onClick={() => handleModalDetailKonten(it)}></img>
        </>
      );
    }
  };

  const PreviewComponent = ({ it }) => {
    return (
      <center style={{ width: "fit-content" }}>
        {!it.tanggal_pembuatan.includes("2019") ? (
          props.activeTab !== "3" ? (
            <img
              alt={props.activeTab == "3" ? it.title : it.details.title}
              className="wrapperImgYT"
              onClick={() => handleModalPreviewContent(it)}
              src={
                it.details
                  ? it.details.thumbnail
                    ? `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.thumbnail : ""}`
                    : `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.url : ""}`
                  : "-"
              }></img>
          ) : (
            <a href={it.link ?? "-"} target="_blank">
              <center>
                <img
                  alt={it.title ?? "-"}
                  className="wrapperImg"
                  src={`${process.env.REACT_APP_BE_URL}/${it.details.url ?? "-"}`}></img>
              </center>
            </a>
          )
        ) : (
          <img
            alt={props.activeTab == "3" ? it.title : it.details.title}
            className="wrapperImgYT"
            src={noImage}></img>
        )}
      </center>
    );
  };

  const getStatus = (it) => {
    let status = it.status;
    let statusKurator = it.status_kurator[it.status_kurator.length - 1].status;
    let emergency = it.emergency;

    if (status === "submitted") {
      if (typeof emergency !== "undefined") {
        return (
          <div className="d-flex pointer" onClick={() => handleModalDoc(it.details.title, it)}>
            <img src={IconEmergency}></img>
            <label
              onClick={() => handleModalDoc(it.details.title, it)}
              key={it._id}
              className="mx-auto py-1 pointer"
              style={{ color: "#C42127" }}>
              Emergency
            </label>
          </div>
        );
      } else {
        switch (statusKurator) {
          case "Tidak Lolos Kurasi":
            return (
              <label
                onClick={() => handleModalTidakLolos(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#C42127" }}>
                Tidak Lolos Kurasi
              </label>
            );
            break;
          case "Lolos Kurasi":
            return (
              <label
                onClick={() => handleModalLolos(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#55BA59" }}>
                Lolos Kurasi
              </label>
            );
            break;
          case "Revisi":
            return (
              <label
                onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                Revisi
              </label>
            );
            break;
          case "Sudah Revisi":
            return (
              <label
                onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                Sudah Revisi
              </label>
            );
            break;
          default:
            return (
              <label
                onClick={() => handleModalRevisi(it)}
                key={it._id}
                className="mx-auto py-1"
                style={{ color: "#360966" }}>
                {statusKurator}
              </label>
            );
            break;
        }
      }
    } else {
      switch (status) {
        case "declined":
          return (
            <label
              onClick={() => handleModalTidakLolos(it)}
              key={it._id}
              className="mx-auto py-1"
              style={{ color: "#c42127" }}>
              Ditolak
            </label>
          );
          break;
        case "approved":
          return (
            <label
              onClick={() => handleModalLolos(it)}
              key={it._id}
              className="mx-auto py-1"
              style={{ color: "#c42127" }}>
              Disetujui
            </label>
          );
          break;
        case "scheduled":
          return (
            <label
              // onClick={() => handleModalLolos(it)}
              key={it._id}
              className="mx-auto py-1">
              Tayang
            </label>
          );
          break;

        default:
          return (
            //todo
            <label
              // onClick={() => handleModalLolos(it)}
              // style={{ color: "#c42127" }}
              key={it._id}
              className="mx-auto py-1">
              {status}
            </label>
          );
          break;
      }
    }
  };

  // let widthOfDate = document.qu("")

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 500,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori Lokasi",
        field: "kategorilokasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Durasi",
        field: "durasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Tayang",
        field: "tanggal_tayang",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Aksi",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.map((it, i) => ({
            no: i + 1,
            judul: it.details.title ? it.details.title : it.title,
            kategori: it.category ?? "-",
            kategorilokasi: it.location
              ? it.location.length > 2
                ? `${it ? (it.location[0] ? it.location[0] : "-") : "-"}, ${
                    it ? (it.location[1] ? it.location[1] : "-") : "-"
                  }, ${it ? (it.location[2] ? it.location[2] : "-") : "-"}...`
                : it.location.length > 1
                ? `${it.location[0] ? it.location[0] : "-"},${
                    it.location[1] ? it.location[1] : "-"
                  }`
                : `${it.location[0] ? it.location[0] : "-"}`
              : "-",
            durasi: it.proposed_duration ?? "-",
            provinsi:
              it.list_provinsi.length > 2
                ? `${it ? (it.list_provinsi[0] ? it.list_provinsi[0].name : "-") : "-"}, ${
                    it ? (it.list_provinsi[1] ? it.list_provinsi[1].name : "-") : "-"
                  }, ${it ? (it.list_provinsi[2] ? it.list_provinsi[2].name : "-") : "-"}...`
                : it.list_provinsi.length > 1
                ? `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"},${
                    it.list_provinsi[1] ? it.list_provinsi[1].name : "-"
                  }`
                : `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"}`,
            tanggal_tayang:
              it.proposed_date.length > 0
                ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format("YYYYMMDD")} 
                  ${moment(it.proposed_date[0], "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}\n s/d \n${moment(it.proposed_date.slice(-1), "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}`
                : "-",
            status: getStatus(it),
            preview: [<PreviewComponent it={it} />],
            aksi: <ButtonAction it={it ? it : ""} />,
          })),
  };
  // module.export = {
  //   var : "aaaaaa"
  // }
  return (
    <React.Fragment>
      <ModalPreviewEmergencyDoc
        dataModalDoc={dataModalDoc}
        modalDoc={modalDoc}
        toggleModalDoc={toggleModalDoc}
      />
      {alert == "successActivedContent" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil di Aktifkan"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alert == "failedActivedContent" ? (
        <SweetAlert
          timeout={3}
          title={"Konten Tidak Berhasil di Aktifkan"}
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : alert == "failedFetch" ? (
        <SweetAlert
          timeout={3}
          error
          title="User tidak berhasil di kurasi"
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : (
        ""
      )}
      <PopupDetailRevisi
        title={dataModalRevisi.title}
        detail={dataModalRevisi.detail}
        modal4={modalRevisi}
        toggleRevisi={() => toggleModalRevisi()}
        statRevisi={"Revisi"}
        listProv={dataModalRevisi.listProv}
        jamTayang={dataModalRevisi.jamTayang}
        tglTayang={dataModalRevisi.tglTayang}
      />
      <ModalPreviewContent
        ModalPreviewContent={modalPreviewContent}
        dataModalPreviewContent={dataModalPreviewContent}
        toggleModalPreviewContent={toggleModalPreviewContent}
        fetchData={handleFetch}
        activeTab={typeContent}
      />
      <DetailKonten
        dataModalDetailKonten={dataModalDetailKonten}
        ModalDetailKonten={ModalDetailKonten}
        toggleModalDetailKonten={toggleModalDetailKonten}
      />
      <ModalLolos
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        dataModalLolos={dataModalLolos}
        modalLolos={modalLolos}
        toggleModalLolos={toggleModalLolos}
      />
      <ModalTidakLolos
        dataModalTidakLolos={dataModalTidakLolos}
        handleAlert={{
          setAlert: setAlert,
        }}
        fetchData={handleFetch}
        modalTidakLolos={modalTidakLolos}
        toggleModalTidakLolos={toggleModalTidakLolos}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-50 my-3">
                    <Button
                      color=""
                      onClick={toggleModalCalendar}
                      id="tanggal"
                      className="inputWrapper btnTanggal"
                      style={{ marginRight: "10px" }}>
                      <p className="selectedDate">
                        {selectedDate}
                        <span class="mdi mdi-18px mdi-calendar-range" style={{ float: "right" }} />
                      </p>
                    </Button>
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  <FilterCalendar
                    modalCalendar={modalCalendar}
                    toggleModalCalendar={toggleModalCalendar}
                    selectedDate={handleSelectedDate}
                  />
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">
                      Tidak ada konten yang dapat di aktifkan
                    </div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="TableKontenAdmin"
                        noBottomColumns={true}
                        className="text-center"
                        responsive={true}
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                        onSort={{ column: "judul" }}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, null)(TableKontenAdmin);
