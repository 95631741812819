import React, { useState, useEffect } from "react";
// import axios from "axios";
import {
  Button,
  Row,
  Col,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  Spinner,
  CardBody,
  Modal,
  ModalBody,
} from "reactstrap";
import moment from "moment";
import { MDBModalHeader, MDBNavLink } from "mdbreact";
import _ from "lodash";

// MODAL
import ModalCalendar from "../../components/Admin/Popup/BI/Calendar";
import ModalSave from "../../components/Admin/Popup/BI/LogHistory/SaveFilter";
import ModalHelper from "./Popup/helper";

import cal from "../../assets/icon/calendar.png";
import iconDown from "../../assets/icon/back.png";
import carbon_save from "../../assets/icon/carbon_save.png";

// Styling
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-daterangepicker/daterangepicker.css";
import style from "./style.module.css";
import "./style.css";

// ASSETS
import helperIcon from "../../assets/icon/help_center-24px.svg";

// Components
// import Maps from "../Map";
import MapContext from "../MapContext/MapHooks";
// import DownloadPDF from "../../../components/Download/PDFPersebaran";
// import DownloadXLS from "../../../components/Download/XLSPersebaran";

//Dummy Data JSON
import API from "../../services";

// REDUX
import { useSelector } from "react-redux";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "./Download/XLS/DownloadPersebaranPengunjung";

function FilterPersebaranPengunjung() {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  const [dataTitik, setDataTitik] = useState([]);
  const [loading, setLoading] = useState(false);

  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);

  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  const [allProvinsi, setAllProvinsi] = useState("allProv");
  const [allKabupaten, setAllKabupaten] = useState("allKab");
  const [allKecamatan, setAllKecamatan] = useState("allKec");
  const [allDesa, setAllDesa] = useState("allDesa");

  const [idProv, setIdProv] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.province
      : ""
  );
  const [idKab, setIdKab] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.kab_kot
      : ""
  );
  const [idKec, setIdKec] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.kecamatan
      : ""
  );
  const [idDesa, setIdDesa] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.kelurahan
      : ""
  );

  const [kabDropdown, setKabDropdown] = useState(true);
  const [kecDropdown, setKecDropdown] = useState(true);
  const [desaDropdown, setDesaDropdown] = useState(true);

  const [titleProv, setTitleProv] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_provinsi
      : "Semua Provinsi"
  );
  const [titleKab, setTitleKab] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_kab_kot
      : "Semua Kabupaten"
  );
  const [titleKec, setTitleKec] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_kecamatan
      : "Semua Kecamatan"
  );
  const [titleDesa, setTitleDesa] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_kelurahan
      : "Semua Desa"
  );

  const [titleData, setTitleData] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.content_title
      : "Kunjungan"
  );
  const [titleDate, setTitleDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_date
      : "Daily"
  );

  const [dataType, setDataType] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.data_type
      : "visit"
  );

  const [cond, setCond] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.condition
      : "prov"
  );
  // const [cond, setCond] = useState(
  //  "prov"
  // );

  const [centerCoordinat, setCenterCoordinat] = useState([118.0148634, -2.548926]);
  const [zoomCoordinat, setZoomCoordinat] = useState([3.5]);

  // DROPDOWN
  const [dropdownOpenProv, setDropdownOpenProv] = useState(false);
  const toggleProv = () => setDropdownOpenProv((prevState) => !prevState);
  const [dropdownOpenKK, setDropdownOpenKK] = useState(false);
  const toggleKK = () => setDropdownOpenKK((prevState) => !prevState);
  const [dropdownOpenKec, setDropdownOpenKec] = useState(false);
  const toggleKec = () => setDropdownOpenKec((prevState) => !prevState);
  const [dropdownOpenDesa, setDropdownOpenDesa] = useState(false);
  const toggleDesa = () => setDropdownOpenDesa((prevState) => !prevState);

  const [dropdownOpenData, setDropdownOpenData] = useState(false);
  const toggleData = () => setDropdownOpenData((prevState) => !prevState);

  const [dropdownOpenDownload, setDropdownOpenDownload] = useState(false);
  const toggleDownload = () => setDropdownOpenDownload((prevState) => !prevState);

  const [dropdownOpenDate, setDropdownOpenDate] = useState(false);
  const toggleDate = () => setDropdownOpenDate((prevState) => !prevState);

  const [dropdownOpenDateAkumulasi, setDropdownOpenDateAkumulasi] = useState(false);
  const toggleDateAkumulasi = () => setDropdownOpenDateAkumulasi((prevState) => !prevState);

  const [dropdownOpenYear, setDropdownOpenYear] = useState(false);
  const toggleYear = () => setDropdownOpenYear((prevState) => !prevState);

  const [dropdownWeek, setDropdownWeek] = useState(false);
  const toggleWeek = () => setDropdownWeek((prevState) => !prevState);

  //calendar modal
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };
  const [modalSave, setModalSave] = useState(false);
  const toggleModalSave = () => {
    setModalSave(!modalSave);
  };
  // TANGGAL DEFAULT
  var currentDate = new Date();

  const [endDate, setEndDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.end_date
      : moment(currentDate).subtract(1, "days").format("DD/MM/YYYY")
  );

  // const start = currentDate.setDate(currentDate.getDate() - 1);
  const [startDate, setStartDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.start_date
      : moment(currentDate).subtract(1, "days").format("DD/MM/YYYY")
  );

  // const [dateYear, setDateYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0 ? dataReduxLogHistory.data.filter.month : ""
  );
  const [year, setYear] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.year
      : currentDate.getFullYear()
  );

  var firstDay = new Date(year, currentDate.getMonth() - currentDate.getMonth(), 1);

  const getListProv = () => {
    // setLoading(true);
    const paramProv = {
      type: dataType,
      start: startDate,
      end: endDate,
    };

    API.getBIVisitor(paramProv)
      .then((res) => {
        if (res.data.success) {
          setAllProvinsi(res.data.data);
          // setDataTitik(res.data.data);
          // console.log("data prov", res.data.data);
          // setCenterCoordinat([118.0148634, -2.548926]);
          // setZoomCoordinat([3.5]);
          setLoading(false);
        }
      })
      .catch((e) => {
        setAllProvinsi("allProv");
        setLoading(false);
      });
  };
  const handleSubmitDataFilter = () => {
    if (startDate == "" || endDate == "") {
      return alert("Filter Belum Lengkap");
    } else {
      setLoading(true);
      const paramProv = {
        type: dataType,
        start: startDate,
        end: endDate,
      };

      const paramKab = {
        type: dataType,
        start: startDate,
        end: endDate,
        province: idProv,
      };

      if (cond === "prov") {
        API.getBIVisitor(paramProv)
          .then((res) => {
            if (res.data.success) {
              setAllProvinsi(res.data.data);
              setDataTitik(res.data.data);
              setCenterCoordinat([118.0148634, -2.548926]);
              setZoomCoordinat([3.5]);
            } else {
              setAllProvinsi("allProv");
            }
            setLoading(false);
          })
          .catch((e) => {
            setAllProvinsi("allProv");
            setDataTitik(null);
            setLoading(false);
          });
      } else if (cond === "kab") {
        API.getBIVisitor(paramKab)
          .then((res) => {
            if (res.data.success) {
              setDataTitik(res.data.data);
              setCenterCoordinat([res.data.data[0].longitude, res.data.data[0].latitude]);
              setZoomCoordinat([7]);
              setLoading(false);
            }
          })
          .catch((e) => {
            setDataTitik(null);
            setLoading(false);
          });
      } else if (cond === "kec") {
        // API.getBIVisitor(paramKab)
        //   .then((res) => {
        //     if (res.data.success) {
        //       setDataTitik(res.data.data);
        //       console.log("data kab", res.data.data);
        //       setCenterCoordinat([res.data.data[0].longitude, res.data.data[0].latitude]);
        //       setZoomCoordinat([6]);
        setLoading(false);
        alert(
          "Untuk saat ini belum ada API Kecamatan, untuk menampilkan data titik semua kecamatan"
        );
        //     }
        //   })
        //   .catch((e) => {
        //     setDataTitik([]);
        //     setLoading(false);
        //   });
      } else if (cond === "desa") {
        // API.getBIVisitor(paramKab)
        //   .then((res) => {
        //     if (res.data.success) {
        //       setDataTitik(res.data.data);
        //       console.log("data kab", res.data.data);
        //       setCenterCoordinat([res.data.data[0].longitude, res.data.data[0].latitude]);
        //       setZoomCoordinat([6]);
        setLoading(false);
        alert("Untuk saat ini belum ada API Desa, untuk menampilkan data titik semua desa");
        //     }
        //   })
        //   .catch((e) => {
        //     setDataTitik([]);
        //     setLoading(false);
        //   });
      }
    }
  };

  // GET DROPDOWN LOKASI

  const getLocation = () => {
    if (idProv === "allProv" || idProv === "") {
      setKabDropdown(true);
      setKecDropdown(true);
      setDesaDropdown(true);
      setCond("prov");
      setIdKab("");
      setIdKec("");
      setIdDesa("");
    } else if (idKab === "allKab" || idKab === "") {
      setKecDropdown(true);
      setDesaDropdown(true);
      setCond("kab");
      setIdKec("");
      setIdDesa("");
    } else if (idKec === "allKec" || idKec === "") {
      setCond("kec");
      setDesaDropdown(true);
      setIdDesa("");
    } else if (idDesa === "allDesa" || idDesa === "") {
      setCond("desa");
    }

    if (idProv !== "" && idProv !== "allProv" && idKab === "" && idKec === "" && idDesa === "") {
      // console.log("dropdown kab id prov", idProv);
      API.getKab({ id: idProv })
        .then((res) => {
          setAllKabupaten(res.data.data);
          setCond("kab");
        })
        .catch((e) => {
          setAllKabupaten("allKab");
        });
    } else if (idKab !== "" && idKab !== "allKab" && idKec === "" && idDesa === "") {
      // console.log("dropdown kec id kab", idKab);
      API.getKec({ id: idKab })
        .then((res) => {
          setAllKecamatan(res.data.data);
          setCond("kec");
        })
        .catch((e) => {
          setAllKecamatan("allKec");
        });
    } else if (idKec !== "" && idKec !== "allKec" && idDesa === "") {
      // console.log("dropdown desa id kec", idKec);
      API.getDesa({ id: idKec })
        .then((res) => {
          setAllDesa(res.data.data);
          setCond("desa");
        })
        .catch((e) => {
          setAllDesa("allDesa");
        });
    }
  };

  useEffect(() => {
    // setTimeout(() => setIsReady(true), 1);
    getLocation();
  }, [idProv, idKab, idKec]);

  const handleDropdownProv = (e, id) => {
    if (id === "allProv") {
      setTitleKab("Semua Kabupaten");
      setTitleKec("Semua Kecamatan");
      setTitleDesa("Semua Desa");
      setIdKab("");
      setIdKec("");
      setIdDesa("");
      setKabDropdown(true);
    } else {
      setIdKab("");
      setIdKec("");
      setIdDesa("");
      setTitleKab("Semua Kabupaten");
      setTitleKec("Semua Kecamatan");
      setTitleDesa("Semua Desa");
      setKabDropdown(false);
      setKecDropdown(true);
      setDesaDropdown(true);
    }
    setIdProv(id);
    setTitleProv(e);
  };

  const handleDropdownKK = (e, id) => {
    if (id === "allKab") {
      setTitleKec("Semua Kecamatan");
      setTitleDesa("Semua Desa");
      setIdKec("");
      setIdDesa("");
      setKecDropdown(true);
    } else {
      setKecDropdown(false);
      setIdKec("");
      setIdDesa("");
      setTitleKec("Semua Kecamatan");
      setTitleDesa("Semua Desa");
      setDesaDropdown(true);
    }
    setIdKab(id);
    setTitleKab(e);
  };

  const handleDropdownKec = (e, id) => {
    if (id === "allKec") {
      setTitleDesa("Semua Desa");
      setIdDesa("");
      setDesaDropdown(true);
    } else {
      setDesaDropdown(false);
      setIdDesa("");
      setTitleDesa("Semua Desa");
    }
    setIdKec(id);
    setTitleKec(e);
  };
  const handleDropdownDesa = (e, id) => {
    setIdDesa(id);
    setTitleDesa(e);
  };
  const handleDropdownDateRedux = (param) => {
    switch (param) {
      case "daily":
        setDaily(true);
        setTitleDate("Daily");
        break;
      case "weekly":
        setWeekly(true);
        setTitleDate("Weekly");
        break;
      case "monthly":
        setMonthly(true);
        setTitleDate("Monthly");
        break;
      case "akumulasi":
        setAkumulasi(true);
        setTitleDate("Akumulasi");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getListProv();
    if (
      Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.measure === "persebaran"
    ) {
      setLoading(true);
      handleDropdownDateRedux(dataReduxLogHistory.data.filter.title_date.toLowerCase());
    }
    handleSubmitDataFilter();
  }, []);

  const renderDropdown = (param) => {
    switch (param) {
      case "prov":
        if (allProvinsi !== "allProv") {
          return allProvinsi.map((i) => (
            <DropdownItem
              key={i._id}
              onClick={(e) => {
                // handleDropdown(e.currentTarget.textContent, i._id);
                handleDropdownProv(i.name, i._id);
              }}>
              {i.name}
            </DropdownItem>
          ));
        }
        break;
      case "kk":
        if (allKabupaten !== "allKab") {
          return allKabupaten.map((i, index) => (
            <DropdownItem
              key={index}
              onClick={(e) => {
                handleDropdownKK(i.name, i._id);
              }}>
              {i.name}
            </DropdownItem>
          ));
        }
        break;
      case "kec":
        if (allKecamatan !== "allKec") {
          return allKecamatan.map((i, index) => (
            <DropdownItem
              key={index}
              onClick={(e) => {
                handleDropdownKec(i.name, i._id);
              }}>
              {i.name}
            </DropdownItem>
          ));
        }
        break;
      case "desa":
        if (allDesa !== "allDesa") {
          return allDesa.map((i) => (
            <DropdownItem
              onClick={(e) => {
                handleDropdownDesa(i.name, i._id);
              }}>
              {i.name}
            </DropdownItem>
          ));
        }
        break;

      default:
        break;
    }
  };

  const getDropdownData = (param, type) => {
    setTitleData(type);
    setTitleProv("Semua Provinsi");
    switch (param) {
      case "visit":
        setDataType("visit");
        break;
      case "dfp":
        setDataType("dfp");
        break;
      case "device":
        setDataType("device");
        break;

      default:
        break;
    }
  };

  const [daily, setDaily] = useState(true);
  const [weekly, setWeekly] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [akumulasi, setAkumulasi] = useState(false);

  const [titleYear, setTitleYear] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.year
      : currentDate.getFullYear()
  );
  const [titleMonth, setTitleMonth] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_month
      : "Pilih Bulan"
  );

  const [titleWeek, setTitleWeek] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.title_week
      : "Pilih Minggu"
  );

  const handleDropdownDate = (param) => {
    setDaily(false);
    setWeekly(false);
    setMonthly(false);
    setAkumulasi(false);
    // setTitleYear("Pilih Tahun");
    setTitleMonth("Pilih Bulan");
    setTitleWeek("Pilih Minggu");
    setStartDate("");
    setEndDate("");
    switch (param) {
      case "daily":
        setDaily(true);
        setTitleDate("Daily");
        setStartDate(moment(currentDate).subtract(1, "days").format("DD/MM/YYYY"));
        setEndDate(moment(currentDate).subtract(1, "days").format("DD/MM/YYYY"));
        break;
      case "weekly":
        setWeekly(true);
        setTitleDate("Weekly");
        break;
      case "monthly":
        setMonthly(true);
        setTitleDate("Monthly");
        break;
      case "akumulasi":
        setAkumulasi(true);
        setTitleDate("Akumulasi");
        break;
      default:
        break;
    }
  };
  const handleSelectMonth = (e, i) => {
    setTitleMonth(e.currentTarget.textContent);
    if (akumulasi) {
      setStartDate(moment(firstDay).format("DD/MM/YYYY"));
      setEndDate(moment(new Date(year, i + 1, 0)).format("DD/MM/YYYY"));
    } else if (monthly) {
      setStartDate(moment(new Date(year, i, 1)).format("DD/MM/YYYY"));
      setEndDate(moment(new Date(year, i + 1, 0)).format("DD/MM/YYYY"));
    } else {
      setMonth(i + 1);
      setTitleWeek("Pilih Minggu");
    }
  };

  const handleSelectYear = (e, i) => {
    if (e.currentTarget.textContent == 2020 && dataType == "device") {
      alert(`Tidak ada data ${titleData} di tahun ${e.currentTarget.textContent}`);
    } else if (e.currentTarget.textContent != 2020 && dataType == "dfp") {
      alert(`Tidak ada data ${titleData} di tahun ${e.currentTarget.textContent}`);
    } else {
      setTitleYear(e.currentTarget.textContent);
      setYear(e.currentTarget.textContent);
      setTitleMonth("Pilih Bulan");
    }
  };

  const listYear = _.range(2020, currentDate.getFullYear() + 1);
  // const listYear = _.range(2020, currentDate.getFullYear() + 5);

  const renderDropdownWeek = () => {
    const start = moment(`01/${month}/${year}`, "DD/MM/YYYY").startOf("month");
    const end = moment(start).endOf("month");

    var current = start;

    const listWeek = [];

    while (current.isSameOrBefore(end)) {
      var curr = moment(current).startOf("isoWeek");
      var currEnd = moment(current).endOf("isoWeek");

      if (curr.month() == start.month()) {
        listWeek.push(`${curr.format("DD MMM YY")} - ${currEnd.format("DD MMM YY")}`);
      }
      current = currEnd.add(1, "days");
    }

    return listWeek;
  };

  const handleSelectWeek = (e) => {
    const date = e.split("-");
    setTitleWeek(e);
    setStartDate(moment(date[0], "DD MMM YY").format("DD/MM/YYYY"));
    setEndDate(moment(date[1], "DD MMM YY").format("DD/MM/YYYY"));
  };

  const resetFilter = () => {
    setTitleProv("Semua Provinsi");
    setTitleKab("Semua Kabupaten");
    setTitleKec("Semua Kecamatan");
    setTitleDesa("Semua Desa");
    setCond("prov");

    setIdProv("");
    setIdKab("");
    setIdKec("");
    setIdDesa("");

    // RESET DATE
    setDaily(true);
    setWeekly(false);
    setMonthly(false);
    setAkumulasi(false);

    setTitleYear("Pilih Tahun");
    setTitleMonth("Pilih Bulan");
    setWeekly("Pilih Minggu");

    setKabDropdown(true);
    setKecDropdown(true);
    setDesaDropdown(true);

    setTitleData("Kunjungan");
    setTitleDate("Daily");
    setDataType("visit");

    handleDropdownDate("daily");
  };

  // MODAL HELPER
  const [modalHelper, setModalHelper] = useState(false);
  const toggleModalHelper = () => {
    setModalHelper(!modalHelper);
  };

  return (
    <>
      <ModalCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setYear={setYear}
        setDataType={setDataType}
        setTitleData={setTitleData}
        dataType={dataType}
        titleData={titleData}
        defaultDate={startDate}
        // selectedDate={handleSelectedDate}
      />
      <ModalSave
        modal={modalSave}
        toggle={toggleModalSave}
        data={{
          data_type: dataType,
          content_title: titleData,
          title_date: titleDate,
          province: idProv,
          kab_kot: idKab,
          kecamatan: idKec,
          kelurahan: idDesa,
          year: titleYear,
          month: month,
          title_month: titleMonth,
          title_week: titleWeek,
          start_date: startDate,
          end_date: endDate,
          condition: cond,
          measure: "persebaran",
          title_provinsi: titleProv,
          title_kab_kot: titleKab,
          title_kecamatan: titleKec,
          title_kelurahan: titleDesa,
        }}
      />
      <ModalHelper modal={modalHelper} toggle={toggleModalHelper} />
      <Row
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}>
        <CardBody
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <span className={style.titleMenuBI}>PERSEBARAN PENGUNJUNG</span>
          <div>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col key={1}>
                <Button
                  key={2}
                  style={{
                    backgroundColor: "#2C3280",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingBottom: "3px",
                    paddingTop: "3px",
                    minWidth: "100%",
                  }}
                  onClick={toggleModalSave}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <img src={carbon_save} className="mr-2" />
                    <span>Save</span>
                  </div>
                </Button>
              </Col>
              <>
                <Modal
                  size="md"
                  isOpen={modalDownloadXLS}
                  toggle={toggleDownloadXLS}
                  centered={true}>
                  <MDBModalHeader toggle={toggleDownloadXLS}>
                    <span className={style.headText}>Download XLS</span>
                  </MDBModalHeader>
                  <ModalBody>
                    <center className="container-popup">
                      <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                      <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                        Batal
                      </Button>
                      <Button
                        color=""
                        className={style.btn_confirm_xls}
                        onClick={toggleDownloadXLS}>
                        <ReactHTMLTableToExcel
                          id="TableXLS"
                          className={style.DownloadTableXLS}
                          table="DownloadTableXLSPersebaran"
                          filename={`Persebaran_Pengunjung_${moment(Date())
                            .format("DDMMYYYY")
                            .toString()}`}
                          sheet="persebaranPengunjung"
                          buttonText="Download"
                        />
                      </Button>
                    </center>
                  </ModalBody>
                </Modal>
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                  key={1223}>
                  {/* <MDBNavLink onClick={toggleDownloadXLS} link to="#">
                    XLS
                  </MDBNavLink> */}
                  <ButtonDropdown
                    style={{ width: "100%" }}
                    isOpen={dropdownOpenDownload}
                    toggle={toggleDownload}>
                    <DropdownToggle
                      style={{
                        backgroundColor: "#F8F9FA",
                        borderRadius: "20px",
                        borderColor: "#2C3280",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}>
                      <div
                        style={{
                          color: "#2C3280",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}>
                        <span>Download</span>
                        <img src={iconDown} />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu
                      style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                      <DropdownItem onClick={toggleDownloadXLS}> Download XLS</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </Col>
                <DownloadXLS
                  id="DownloadTableXLSPersebaran"
                  className="DownloadTableXLS"
                  data={dataTitik}
                  cond={cond}
                  startDate={startDate}
                  endDate={endDate}
                  titleData={titleData}
                  titleDate={titleDate}
                />
              </>
            </Row>
          </div>
        </CardBody>
      </Row>
      <div
        className="p-3"
        style={{
          height: "100%",
          width: "100%",
          borderRadius: 10,
          marginBottom: "100px",
        }}>
        <Row
          style={{
            display: "flex",
            // alignItems: "center",
            fontSize: "16px",
            fontWeight: "bold",
            // cursor: "pointer",
          }}
          className="my-1">
          <span>Panduan Pencarian Klik Disini!</span>
          <div style={{ display: "flex" }} className="tooltipBI ml-2">
            <img
              src={helperIcon}
              onClick={toggleModalHelper}
              className="hoverHelper"
              style={{ width: "19px" }}
            />
          </div>
        </Row>
        <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>Pilih Data:</div>
        </Row>
        <Row className="mb-3">
          <Col>
            <ButtonDropdown style={{ width: "100%" }} isOpen={dropdownOpenData} toggle={toggleData}>
              <DropdownToggle
                style={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: "20px",
                  borderColor: "#2C3280",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}>
                <div
                  style={{
                    color: "#2C3280",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span>{titleData}</span>
                  <img src={iconDown} />
                </div>
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                {year == 2020 ? (
                  <>
                    <DropdownItem
                      onClick={(e) => {
                        getDropdownData("visit", "Kunjungan");
                      }}>
                      Kunjungan
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        getDropdownData("dfp", "DFP");
                      }}>
                      DFP
                    </DropdownItem>
                    {/* <DropdownItem
                      onClick={(e) => {
                        getDropdownData("device", "Device Unik");
                      }}>
                      Device Unik
                    </DropdownItem> */}
                  </>
                ) : (
                  <>
                    {" "}
                    <DropdownItem
                      onClick={(e) => {
                        getDropdownData("visit", "Kunjungan");
                      }}>
                      Kunjungan
                    </DropdownItem>
                    {/* <DropdownItem
                      onClick={(e) => {
                        getDropdownData("dfp", "DFP Unik");
                      }}>
                      DFP Unik
                    </DropdownItem> */}
                    <DropdownItem
                      onClick={(e) => {
                        getDropdownData("device", "Device Unik");
                      }}>
                      Device Unik
                    </DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </Col>

          <Col style={{ display: "flex", alignItems: "end" }}>
            <ButtonDropdown style={{ width: "100%" }} isOpen={dropdownOpenDate} toggle={toggleDate}>
              <DropdownToggle
                style={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: "20px",
                  borderColor: "#2C3280",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}>
                <div
                  style={{
                    color: "#2C3280",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <span>{titleDate}</span>
                  <img src={iconDown} />
                </div>
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                <DropdownItem
                  onClick={(e) => {
                    handleDropdownDate("daily");
                  }}>
                  Daily
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    handleDropdownDate("weekly");
                  }}>
                  Weekly
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    handleDropdownDate("monthly");
                  }}>
                  Monthly
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    handleDropdownDate("akumulasi");
                  }}>
                  Akumulasi
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>

          {akumulasi || monthly || weekly ? (
            <>
              <Col style={{ display: "flex", alignItems: "end" }}>
                <ButtonDropdown
                  style={{ width: "100%" }}
                  isOpen={dropdownOpenYear}
                  toggle={toggleYear}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#F8F9FA",
                      borderRadius: "20px",
                      borderColor: "#2C3280",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}>
                    <div
                      style={{
                        color: "#2C3280",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      <span>{titleYear}</span>
                      <img src={iconDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                    {listYear.map((e, i) => (
                      <DropdownItem
                        key={i}
                        onClick={(a) => {
                          handleSelectYear(a, i);
                        }}>
                        {e}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
              <Col style={{ display: "flex", alignItems: "end" }}>
                <ButtonDropdown
                  style={{ width: "100%" }}
                  isOpen={dropdownOpenDateAkumulasi}
                  toggle={toggleDateAkumulasi}>
                  <DropdownToggle
                    disabled={titleYear == "Pilih Tahun"}
                    style={{
                      backgroundColor: "#F8F9FA",
                      borderRadius: "20px",
                      borderColor: "#2C3280",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}>
                    <div
                      style={{
                        color: "#2C3280",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      <span>{titleMonth}</span>
                      <img src={iconDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                    {moment
                      .months()
                      .slice(
                        0,
                        titleYear == currentDate.getFullYear() ? currentDate.getMonth() : 12
                      ).length !== 0 ? (
                      moment
                        .months()
                        .slice(
                          0,
                          titleYear == currentDate.getFullYear() ? currentDate.getMonth() : 12
                        )
                        .map((e, i) => (
                          <DropdownItem
                            key={i}
                            onClick={(a) => {
                              handleSelectMonth(a, i);
                            }}>
                            {e}
                          </DropdownItem>
                        ))
                    ) : (
                      <div className="px-3">Tidak Ada Data</div>
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>

              {weekly && (
                <Col style={{ display: "flex", alignItems: "end" }}>
                  <ButtonDropdown
                    style={{ width: "100%" }}
                    isOpen={dropdownWeek}
                    toggle={toggleWeek}>
                    <DropdownToggle
                      disabled={titleMonth == "Pilih Bulan"}
                      style={{
                        backgroundColor: "#F8F9FA",
                        borderRadius: "20px",
                        borderColor: "#2C3280",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}>
                      <div
                        style={{
                          color: "#2C3280",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}>
                        <span>{titleWeek}</span>
                        <img src={iconDown} />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu
                      style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                      {renderDropdownWeek().map((res, i) => (
                        <DropdownItem
                          key={i}
                          onClick={(a) => {
                            handleSelectWeek(res);
                          }}>
                          {res}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </Col>
              )}
            </>
          ) : (
            <Col style={{ display: "flex", alignItems: "end" }}>
              <Button
                onClick={toggleModalCalendar}
                style={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: "20px",
                  borderColor: "#2C3280",
                }}>
                <div
                  style={{
                    color: "#2C3280",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  {daily ? (
                    <span className="mx-5">{startDate}</span>
                  ) : (
                    <span className="mr-5">
                      {startDate} - {endDate}
                    </span>
                  )}
                  <img src={cal} />
                </div>
              </Button>
            </Col>
          )}

          {/* <div className="d-flex justify-content-center mx-auto" style={{ width: "50%" }}>
            <Button
              onClick={toggleModalCalendar}
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span className="mr-5">
                  {startDate} - {endDate}
                </span>
                <img src={cal} />
              </div>
            </Button>
          </div> */}

          {/* <div className="col-8">
            <Row className={styles.wrapper_download}>
              <Button className={styles.saveBtn} onClick={() => alert("Save Log History")}>
                Save
              </Button>
              <div className={styles.border_save}>
                <div className={styles.wrapper_download}>
                  <span className={styles.downloadBtn}>Download</span>
                  <Button className={styles.downloadBtn}>
                    {isReady && (
                        <DownloadXLS dataPlaces={type} location={markerData}/>
                      )}
                    XLS
                  </Button>
                  <Button className={styles.downloadBtn}>
                    {isReady && (
                        <DownloadPDF dataPlaces={type} location={markerData}/>
                      )}
                    PDF
                  </Button>
                </div>
              </div>
            </Row>
          </div> */}
        </Row>

        <div className="font-weight-bold text-center my-1" style={{ fontSize: "20px" }}>
          <label className="">TOTAL PERSEBARAN PENGUNJUNG LANDING PAGE BAKTI ONLINE</label>
        </div>

        {/* <Row>
          <div className="d-flex justify-content-center mx-auto" style={{ width: "50%" }}>
            <span className="mr-2" style={{ alignSelf: "center" }}>
              Tanggal
            </span>
            <Button
              onClick={toggleModalCalendar}
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
              }}>
              <div
                style={{
                  color: "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span className="mr-5">
                  {startDate} - {endDate}
                </span>
                <img src={cal} />
              </div>
            </Button>
          </div>
        </Row> */}

        <Row className="mx-2 my-2 justify-content-center">
          <Col>
            <Row>
              <span>Provinsi</span>
            </Row>
            <Row>
              <ButtonDropdown
                style={{ width: "95%" }}
                isOpen={dropdownOpenProv}
                toggle={toggleProv}>
                <DropdownToggle
                  // onClick={() => {
                  //   setTitleKab("Semua Kabupaten");
                  //   setTitleKec("Semua Kecamatan");
                  //   setTitleDesa("Semua Desa");
                  //   setIdProv("allProv");
                  // }}
                  style={{
                    backgroundColor: "#F8F9FA",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}>
                  <div
                    style={{
                      color: "#2C3280",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <span>{titleProv}</span>
                    <img src={iconDown} />
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                  <DropdownItem
                    onClick={(e) => {
                      handleDropdownProv("Semua Provinsi", "allProv");
                    }}>
                    Semua Provinsi
                  </DropdownItem>
                  {renderDropdown("prov")}
                </DropdownMenu>
              </ButtonDropdown>
            </Row>
          </Col>
          <Col>
            <Row>
              <span>Kabupaten/Kota</span>
            </Row>
            <Row>
              <ButtonDropdown
                disabled={kabDropdown}
                style={{ width: "95%" }}
                isOpen={dropdownOpenKK}
                toggle={toggleKK}>
                <DropdownToggle
                  // onClick={() => {
                  //   setTitleKec("Semua Kecamatan");
                  //   setTitleDesa("Semua Desa");
                  //   setIdKab("allKab");
                  // }}
                  style={{
                    backgroundColor: "#F8F9FA",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}>
                  <div
                    style={{
                      color: kabDropdown ? "#A6A6A6" : "#2C3280",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <span>{titleKab}</span>
                    <img src={iconDown} />
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                  <DropdownItem
                    onClick={(e) => {
                      handleDropdownKK("Semua Kabupaten", "allKab");
                    }}>
                    Semua Kabupaten
                  </DropdownItem>
                  {allProvinsi === "allProv" ? "" : renderDropdown("kk")}
                </DropdownMenu>
              </ButtonDropdown>
            </Row>
          </Col>
          <Col>
            <Row>
              <span>Kecamatan</span>
            </Row>
            <Row>
              <ButtonDropdown
                disabled={kecDropdown}
                style={{ width: "95%" }}
                isOpen={dropdownOpenKec}
                toggle={toggleKec}>
                <DropdownToggle
                  // onClick={() => {
                  //   setTitleDesa("Semua Desa");
                  //   setIdKec("allKec");
                  // }}
                  style={{
                    backgroundColor: "#F8F9FA",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}>
                  <div
                    style={{
                      color: kecDropdown ? "#A6A6A6" : "#2C3280",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <span>{titleKec}</span>
                    <img src={iconDown} />
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                  <DropdownItem
                    onClick={(e) => {
                      handleDropdownKec("Semua Kecamatan", "allKec");
                    }}>
                    Semua Kecamatan
                  </DropdownItem>
                  {allKabupaten === "allKab" ? "" : renderDropdown("kec")}
                </DropdownMenu>
              </ButtonDropdown>
            </Row>
          </Col>
          <Col>
            <Row>
              <span>Kelurahan/Desa</span>
            </Row>
            <Row>
              <ButtonDropdown
                disabled={desaDropdown}
                style={{ width: "95%" }}
                isOpen={dropdownOpenDesa}
                toggle={toggleDesa}>
                <DropdownToggle
                  style={{
                    backgroundColor: "#F8F9FA",
                    borderRadius: "20px",
                    borderColor: "#2C3280",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}>
                  <div
                    style={{
                      color: desaDropdown ? "#A6A6A6" : "#2C3280",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <span>{titleDesa}</span>
                    <img src={iconDown} />
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: "300px", minWidth: "100%", overflowY: "auto" }}>
                  <DropdownItem
                    onClick={(e) => {
                      handleDropdownDesa("Semua Desa", "allDesa");
                    }}>
                    Semua Desa
                  </DropdownItem>
                  {allDesa === "allDesa" ? "" : renderDropdown("desa")}
                </DropdownMenu>
              </ButtonDropdown>
            </Row>
          </Col>
        </Row>
        <Row className="my-2" style={{ display: "flex", justifyContent: "center" }}>
          <Col md="2">
            <Row>
              <Button
                style={{
                  backgroundColor: "#2C3280",
                  borderRadius: "20px",
                  borderColor: "#2C3280",
                  minWidth: "100%",
                }}
                onClick={handleSubmitDataFilter}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <i className="mdi mdi-magnify mr-2" />
                  <span>Search</span>
                </div>
              </Button>
            </Row>
          </Col>
          <Col md="2">
            <Row>
              <Button
                style={{
                  backgroundColor: "#C42127",
                  borderRadius: "20px",
                  borderColor: "#C42127",
                  minWidth: "100%",
                  marginLeft: "8px",
                }}
                onClick={resetFilter}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <span>Reset</span>
                </div>
              </Button>
            </Row>
          </Col>
        </Row>
        {/* <Row className="mx-2 my-2 justify-content-center"></Row> */}
        <div
          style={{
            height: "58vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {loading ? (
            <div>
              <Spinner type="grow" color="primary" />
            </div>
          ) : dataTitik ? (
            <MapContext
              data={dataTitik}
              type={titleData}
              cond={cond}
              zoom={zoomCoordinat}
              center={centerCoordinat}
              loading={loading}
            />
          ) : (
            <div> Terjadi Kesalahan </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FilterPersebaranPengunjung;
