import Loadable from "react-loadable";
import Loading from "../../../../../components/Loading";

const UserBehaviorAsync = Loadable({
  loader: () =>
    import(
      "../../../../../pages/BusinessIntelligence/Admin/Measures/UserBehavior" /* webpackChunkName: "UserBehaviorAsync" */
    ),
  loading: Loading,
});

export default UserBehaviorAsync;
