import Loadable from "react-loadable";
import Loading from "../../../components/Loading";

const SurveyKepuasanSupportCenterAsync = Loadable({
  loader: () =>
    import(
      "../../../pages/SurveyKepuasanSummary/SupportCenter" /* webpackChunkName: "SurveyKepuasanSupportCenterAsync" */
    ),
  loading: Loading,
});

export default SurveyKepuasanSupportCenterAsync;
