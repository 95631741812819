import React from "react";

import { Row, Card, Col, Spinner } from "reactstrap";

// import "tui-chart/dist/tui-chart.css";
import { LineChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "./style.module.css";
import "./toastui.scss";

// import API from "../../../../services";
var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  // title: {
  //     color: '#8791af',
  // },
  xAxis: {
    // title: {
    //     color: '#8791af'
    // },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#2C3280", "#C42127"],
  },
};

export default function CardPersebaranOS(props) {
  const renderColumnChart = (param) => {
    const _width = window.screen.width / 1.6;
    const data = {
      categories: [
        "00.00",
        "01.00",
        "02.00",
        "03.00",
        "04.00",
        "05.00",
        "06.00",
        "07.00",
        "08.00",
        "09.00",
        "10.00",
        "11.00",
        "12.00",
        "13.00",
        "14.00",
        "15.00",
        "16.00",
        "17.00",
        "18.00",
        "19.00",
        "20.00",
        "21.00",
        "22.00",
        "23.00",
      ],
      series: [
        {
          name: "Total Impresi",
          // data: param ? param.Agribisnis ?? 100 : 100,
          data: props.data
            ? props.data.length !== 0
              ? props.data.map((e) => e.total_impress)
              : []
            : [],
        },
        {
          name: "Total Klik",
          // data: param ? param.Agribisnis ?? 100 : 100,
          data: props.data
            ? props.data.length !== 0
              ? props.data.map((e) => e.total_clicked)
              : []
            : [],
        },
      ],
    };

    const options = {
      chart: {
        width: _width,
        height: _width * 0.3,
        // title: 'Jumlah',
        // format: "1,000",
      },
      yAxis: {
        title: "Jumlah",
        min: 0,
        // max: props.data !== undefined ? 100 : 100,
      },
      xAxis: {
        title: "Jam Tayang",
      },
      legend: {
        align: "bottom",
        // visible: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Organisasi_Prov_Jam",
      },
      series: {
        showDot: true,
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("themeOrgProvJam", theme);
    options.theme = "themeOrgProvJam";

    return (
      <React.Fragment>
        <LineChart data={data} options={options} />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Row style={{ height: "450px" }}>
        <Col md="12">
          <div className="p-3">
            <Row>
              {" "}
              {props.valueData === "" ? (
                <div className={`${style.titleCardBI}`} style={{ marginBottom: "50px" }}>
                  Trafik Konten {props.typeData} berdasarkan Jam Tayang
                </div>
              ) : (
                <div className={`${style.titleCardBI}`}>
                  Trafik Konten {props.typeData} Kategory {props.valueData} berdasarkan Jam Tayang
                </div>
              )}
            </Row>
          </div>
          <div className="p-3" style={{ display: "flex" }}>
            <Row>
              <Col md="12">
                <div className="mt-1 mx-auto" style={{ display: "flex", justifyContent: "center" }}>
                  {props.loading ? (
                    <div
                      className="text-center"
                      style={{ marginBottom: "150px", marginTop: "150px" }}>
                      <Spinner color="primary" />
                    </div>
                  ) : props.data && props.data.length !== 0 ? (
                    renderColumnChart(props.data)
                  ) : (
                    <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                      <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                    </div>
                  )}
                  {/* {renderColumnChart()} */}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
