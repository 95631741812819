import React from "react";
import { Switch, Redirect, Route, withRouter } from "react-router-dom";

// import Artikel from "../pages/Artikel";
// import EditArticle from "../pages/Artikel/EditArticle";
// import CreateArticle from "../pages/Artikel/CreateArticle";
// import superAdminProfile from "../pages/Authentication/superAdminProfile";
// 500 error
import Pages500 from "../pages/Utility/pages-500";
import Artikel from "../pagesAsync/ArtikelAsync";
import EditArticle from "../pagesAsync/EditArticleAsync";
import CreateArticle from "../pagesAsync/ArtikelAsync/CreateArtikelAsync";
import superAdminProfile from "../pagesAsync/AuthenticationAsync/SuperAdminProfileAsync";

import Video from "../pagesAsync/VideoAsync";
import EditVideo from "../pagesAsync/VideoAsync/EditVideoAsync";
import CreateVideo from "../pagesAsync/VideoAsync/CreateVideoAsync";

const AppAdminArtikel = (props) => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${props.match.url}`}
        to={{ pathname: `${props.match.url}adminartikel/artikel`, state: { from: "adminartikel" } }}
      />
      <Route exact path={`${props.match.url}adminartikel/artikel`} component={Artikel} />
      <Route
        exact
        path={`${props.match.url}adminartikel/edit-article`}
        component={EditArticle}
        state={`adminartikel`}
      />
      <Route
        exact
        path={`${props.match.url}adminartikel/create-article`}
        component={CreateArticle}
        state={`adminartikel`}
      />
      <Route exact path={`${props.match.url}adminartikel/profile`} component={superAdminProfile} />

      <Route
        exact
        path={`${props.match.url}adminartikel/video`}
        state={`adminartikel`}
        component={Video}></Route>

      <Route
        exact
        path={`${props.match.url}adminartikel/create-video`}
        component={CreateVideo}
        state={`adminartikel`}></Route>

      <Route
        exact
        path={`${props.match.url}adminartikel/edit-video`}
        state={`adminartikel`}
        component={EditVideo}></Route>

      <Route exact path={`${props.match.url}page-500`} component={Pages500} />
      <Redirect to="/" />
    </Switch>
  );
};

export default withRouter(AppAdminArtikel);
