import React, { useEffect } from "react";
import { Table } from "reactstrap";
import _ from "lodash";
const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  return (
    <Table
      style={{ display: "none", pageBreakAfter: "always" }}
      id="DownloadTableXLS"
      className="DownloadTableXLS">
      <thead>{`Data Hasil Survey`}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Pertanyaan</th>
          <th>Jawaban</th>
          <th>Jumlah Jawaban</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => {
          return (
            <tr
              key={index}
              style={{textAlign: "center", verticalAlign: "middle" }}>
              <td>{index + 1}</td>
              <td>{data ? (data.question ? data.question : "") : ""}</td>
              <td>
                {data ? data.jawaban.map((e) => <div>{`${e.jawaban} : ${e.count},`}<br/></div>) : ""}
              </td>
              <td>{data ? _.map(data.jawaban, "count").reduce((a, b) => a + b, 0) : ""}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
