import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col } from "reactstrap";
import Header from "../../components/VerticalLayout/Header";
import "./style.css";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import "tui-chart/dist/tui-chart.css";
import { PieChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import "./toastui.scss";
import API from "../../services";
import _ from "lodash";
//component
import HeaderXLS from "../../components/Admin/SurveyAdmin/XLS/HeaderXLS";

var theme = {
  // series: {
  series: {
    colors: ["#0062ff", "#ffbb00", "#ff7b00", "#30c8ff", "#e04343", "#a92bed"],
    label: {
      color: "#fff",
      fontFamily: "sans-serif",
    },
  },
  // },
};

function HasilSurvey(props) {
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataSurvey, setDataSurvey] = useState([]);
  let idsTemplate = props?.location?.state?.ids;
  let titleSurvey = props?.location?.state?._id;
  const fetchHasilSurveyAPI = () => {
    let param = {
      idTemplates: idsTemplate,
    };
    API.postHasilSurvey(param)
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setDataSurvey(res.data.data);
        } else {
          setLoading(false);
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (idsTemplate?.length) {
      fetchHasilSurveyAPI();
    } else {
      setIsEmpty(true);
    }
  }, [props.location.state]);
  const DonutChartToast = (question, answer) => {
    const listQuestions = Array.from(question);
    const listSeries = answer.map((it) => {
      return {
        name: it.jawaban,
        data: it.count,
      };
    });
    let answerArr = _.map(answer, "jawaban");
    const data = {
      categories: answerArr,
      series: listSeries,
    };

    const options = {
      chart: {
        width: 400,
        height: answerArr.length * 20 + 500,
        format: function (value, chartType, areaType, valuetype, legendName) {
          if (areaType === "makingSeriesLabel") {
            // formatting at series area
            value = value;
          }

          return value;
        },
      },
      series: {
        //   showLegend: true,
        labelAlign: "outer",
        radiusRange: ["40%", "100%"],
        showLabel: true,
        labelFilter: function (labelInfo) {
          return labelInfo.ratio > 0.3; // Less than 5% does not expose.
        },
      },
      tooltip: {
        suffix: "",
      },
      legend: {
        align: "bottom",
        visible: true,
      },
      chartExportMenu: {
        visible: false, // default is true.
      },
    };

    TuiChart.registerTheme("hasilSurveyTheme", theme);
    options.theme = "hasilSurveyTheme";

    return (
      <React.Fragment>
        <PieChart data={data} options={options} theme={theme} />
      </React.Fragment>
    );
  };

  const ResultView = () => {
    return dataSurvey.map((it, index) => (
      <div>
        <Row className="mt-5">
          <Col
            xs={{ size: "1", offset: "2" }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "flex-start",
              fontSize: "16px",
              color: "black",
            }}>
            <div>{index + 1 + " )."}</div>
          </Col>
          <Col xs={{ size: "8" }} style={{ fontSize: "16px", color: "black" }}>
            <div>{it ? it.question : "-"}</div>
          </Col>
        </Row>
        <Row>
          <Col lg="7">
            <center>{DonutChartToast(it.question, it.jawaban)}</center>
          </Col>
          <Col
            lg="5"
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <div style={{ minWidth: "80%" }}>
              <div
                style={{
                  minWidth: "100%",
                  maxHeight: "500px ",
                  overflow: "auto",
                }}>
                {it
                  ? it.jawaban.length !== 0
                    ? it.jawaban.map((e) => (
                        <div
                          className="my-2 row"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "15px",
                          }}>
                          <div
                            style={{
                              width: "70%",
                              marginLeft: "2%",
                              textAlign: "left",
                            }}>
                            <span className="resultAnswer">{e.jawaban}</span>
                          </div>
                          <div
                            style={{
                              width: "15.5%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <span style={{ fontWeight: "bold", marginRight: "1rem" }}>
                              {(
                                (e.count /
                                  it.jawaban.map((e) => e.count).reduce((a, b) => a + b, 0)) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "1rem",
                                textAlign: "left",
                              }}>
                              {e.count.toLocaleString()}
                            </span>
                          </div>
                        </div>
                      ))
                    : "-"
                  : "-"}
              </div>
              <div
                className="mt-3"
                style={{ minWidth: "100%", fontWeight: "bold", fontSize: "20px" }}>
                {it ? (
                  it.jawaban.length !== 0 ? (
                    <div
                      className="w-100"
                      style={{ display: "flex", justifyContent: "space-between" }}>
                      <span className="totalText">Total</span>
                      <span style={{ color: "#2c3280" }}>
                        {(
                          it.jawaban.map((e) => e.count).reduce((a, b) => a + b, 0) ?? "-"
                        ).toLocaleString()}
                      </span>
                    </div>
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    ));
  };

  return (
    <>
      <div className="page-content">
        <header>
          <Header title="Hasil Survei" btn={true} btntype="backSurvey" btntext="Survei" />
        </header>
        <main>
          <div className="container-fluid" style={{ paddingBottom: "100px" }}>
            {isEmpty ? (
              "Silahkan Pilih Survey Terlebih Dulu "
            ) : loading ? (
              <Skeleton count={10} />
            ) : (
              <>
                {dataSurvey && dataSurvey.length && (
                  <HeaderXLS data={dataSurvey} title={"Hasil Survey"} />
                )}
                <ResultView />
              </>
            )}
          </div>
        </main>
      </div>
    </>
  );
}

export default HasilSurvey;
