import React, { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";
import { addMonths } from "date-fns";
import Header from "../../components/VerticalLayout/Header";
import ModalTambahProvSurvei from "../../components/Admin/Popup/TambahProvinsi";
import API from "../../services";
import "./style.css";
// import WeekPicker from "../../components/Admin/Popup/WeekPicker/WeekPicker";
import moment from "moment";
// import CalendarIcon from "../../assets/icon/IconCalendar.svg";
import _ from "lodash";
import { FilterJumlahSurvei, FilterMaxPilihan } from "./dropdownFilter";
import ModalCalendar from "../../components/Admin/Shared/Popup/Calendar";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

function BuatSurveyAdmin(props) {
  const history = useHistory();
  const [namaSurvey, setNamaSurvey] = useState("");
  const [showProvinsiTambahan, setshowProvinsiTambahan] = useState([]);
  const [namaLokasi, setNamaLokasi] = useState([]);
  const [modalTgl, setModalTgl] = useState(false);
  const [bool, setBool] = useState(false);
  const [message, setMessage] = useState("");
  //success alert
  const [successSubmitted, setSuccessSubmitted] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [optionLength, setOptionLength] = useState([]);

  //modalTambahProvinsi
  const [modalTambahProvinsi, setModalTambahProvinsi] = useState(false);
  const toggleModalTambahProvinsi = () => {
    setModalTambahProvinsi(!modalTambahProvinsi);
  };
  const [idProvince, setIdProvince] = useState(undefined);
  const handleSelectedProvince = (province, id) => {
    setIdProvince(id);
    setNamaLokasi(province);
    setshowProvinsiTambahan(province);
  };

  const [dateSelected, setDateSelected] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tanggal, setTanggal] = useState("");

  const [loadDate, setLoadDate] = useState(true);

  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };
  // const handleSelectedDate = (date) => {
  //   let startDate = moment(addDays(startOfWeek(date), 1));
  //   let endDate = moment(addDays(endOfWeek(date), 1));
  //   //data for
  //   setSelectedDate(`${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`);
  //   setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  // };
  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //throw data to check availability
    // setStartDate(startDate.format("DD-MM-YYYY"));
    // setEndDate(endDate.format("DD-MM-YYYY"));
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };
  //clear
  const handleClear = () => {
    setNamaSurvey("");
    setTanggal("");
    setshowProvinsiTambahan("");
    setJp(0);
    setInputList([]);
    setOptionLength([]);
  };

  ///Handle for Pertanyaan
  const [inputList, setInputList] = useState([]);
  const [jp, setJp] = useState("0");
  //add number pertanyaan
  const handleAdd = (val) => {
    let isValBigger = val > parseInt(jp); // is val more than jp
    setJp(val.toString()); // for option length
    if (!Array.isArray(inputList) && !inputList.length) {
      // if question empty
      setInputList(
        _.times(val, () => {
          return { pertanyaan: "", tipePertanyaan: "essay", jawaban: [""], maxPilihan: "1" };
        })
      );
    } else if (isValBigger) {
      // if val > jp
      let countNewQuestion = val - parseInt(jp);
      let newQuestion = _.times(countNewQuestion, () => {
        return { pertanyaan: "", tipePertanyaan: "essay", jawaban: [""], maxPilihan: "1" };
      });
      setInputList(inputList.concat(newQuestion));
    } else {
      // if jp > val
      setInputList(inputList.slice(0, val));
    }
    setBool(!bool);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleChoice = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    const listUpdate = inputList;
    list[index][name] = value;
    //if tipe pertanyaan checkbox min 2
    if (value === "checkbox") {
      list[index]["maxPilihan"] = 2;
      list[index]["jawaban"] = [...inputList[index].jawaban, ""];
    } else {
      const listClear = [""];
      listUpdate[index].jawaban = listClear;
    }
    //if tipepertanyaan change

    setInputList(listUpdate);

    setInputList(list);
  };
  //handle max answer choices
  const handleMax = (val, index, type) => {
    const list = [...inputList];
    if (type == "checkbox") {
      list[index].maxPilihan = val.toString();
    } else {
      list[index].maxPilihan = "1";
    }
    setInputList(list);
  };

  ///Handle for Jawaban
  const handleInputChangeJawab = (e, index, i) => {
    const listUpdate = inputList;
    const { value } = e.target;
    const listInsert = [...inputList[i].jawaban];
    listInsert[index] = value;
    listUpdate[i].jawaban = listInsert;
    setBool(!bool);
    setInputList(listUpdate);
  };
  const handleRemoveClickJawab = (index, i) => {
    const listDelete = inputList;
    const list = [...inputList[i].jawaban];
    list.splice(index, 1);
    listDelete[i].jawaban = list;
    listDelete[i].maxPilihan = list.length;
    setInputList(listDelete);
    setBool(!bool);
  };

  const handleAddClickJawab = (index, i) => {
    if (index > 5) return;
    const listUpdate = inputList;
    const listAdd = [...inputList[i].jawaban, ""];
    listUpdate[i].jawaban = listAdd;
    setBool(!bool);
    setInputList(listUpdate);
  };

  useEffect(() => {
    let lngthEachQuestion = inputList.map((data) => data.jawaban.length.toString());
    setOptionLength(lngthEachQuestion);
  }, [inputList, bool]);

  const handleAddSurvey = () => {
    let idUser = window.localStorage.getItem("idUser");
    let questionList = _.map(inputList, "pertanyaan").flat();
    let answerList = _.map(inputList, "jawaban").flat();
    let maxList = _.map(inputList, "maxPilihan").flat();
    let listTypeQuestion = _.map(inputList, "tipePertanyaan").flat();

    let param = {
      tanggal: tanggal,
      questionLength: jp,
      option: answerList,
      optionLength: optionLength,
      question: questionList,
      type: listTypeQuestion,
      lokasi: namaLokasi,
      userId: idUser,
      max: maxList,
      templateName: namaSurvey,
    };
    API.postAddSurvey(param)
      .then((res) => {
        setModalLoading(true);
        if (res.data.success) {
          new Promise(
            () => {
              setSuccessSubmitted("success");
              setMessage(res.data.data);
            },
            setTimeout(() => {
              history.push("/admin/survey");
            }, 3000)
          );
        } else {
          new Promise(
            () => {
              setSuccessSubmitted("fail");
            },
            setTimeout(() => {
              setModalLoading(false);
            }, 3000)
          );
        }
      })
      .catch((e) => {
        setSuccessSubmitted("fail");
      });
  };

  const disabled = () => {
    let emptyQuestion = inputList.some((input) => input.pertanyaan === "");
    let emptyAnswer = inputList.some((input) => {
      if (input.tipePertanyaan == "radio" || input.tipePertanyaan == "checkbox") {
        if (input.jawaban[0] == "") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    return (
      emptyQuestion ||
      emptyAnswer ||
      namaSurvey === "" ||
      tanggal === "" ||
      showProvinsiTambahan.length === 0 ||
      jp === 0
    );
  };

  //calendar modal
  const [modalCalendar, setModalCalendar] = useState(false);
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };

  return (
    <>
      {modalLoading ? (
        successSubmitted == "success" ? (
          <SweetAlert title={message} success showConfirm={false} onConfirm={""} />
        ) : successSubmitted == "fail" ? (
          <SweetAlert
            title="Survey Tidak Berhasil Dibuat"
            error
            showConfirm={false}
            onConfirm={""}
          />
        ) : (
          <SweetAlert
            showConfirm={false}
            onConfirm={""}
            title="Mohon Tunggu"
            style={{
              padding: "3em",
              borderRadius: "20px",
            }}>
            <Spinner
              color="danger"
              style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
              type="grow"
            />
            <div>Survey sedang dibuat</div>
          </SweetAlert>
        )
      ) : null}
      <div className="page-content">
        <header>
          <Header title="Buat Survei" btn={true} btntype="backSurvey" btntext="Survei" />
        </header>
        <main>
          <div className="container-fluid" style={{ paddingBottom: "100px" }}>
            <Row>
              <Col
                xs={{ size: "6", offset: "3" }}
                style={{ paddingBottom: "10px", paddingTop: "20px" }}>
                <Label for="titleArticle">Nama Survei</Label>
                <Input
                  type="text"
                  name="text"
                  id="judul"
                  placeholder="Nama Survei"
                  className="inputWrapper"
                  value={namaSurvey}
                  onChange={(e) => setNamaSurvey(e.target.value)}
                />
              </Col>
              <Col xs={{ size: "6", offset: "3" }} style={{ paddingBottom: "10px" }}>
                <Label for="titleArticle">Provinsi</Label>
                <Button
                  color=""
                  onClick={toggleModalTambahProvinsi}
                  id="provinsi"
                  className="inputWrapper btnTambahProvAdmin">
                  <p
                    className={
                      showProvinsiTambahan.length === 0
                        ? "selectedDatePlaceholder"
                        : "selectedDateAdmin"
                    }>
                    {showProvinsiTambahan.length !== 0
                      ? showProvinsiTambahan.id !== ""
                        ? showProvinsiTambahan.sort().join(", ")
                        : ""
                      : "Pilih Provinsi"}
                  </p>
                </Button>
                <ModalTambahProvSurvei
                  isLoadDate={setLoadDate}
                  modalTambahProvinsi={modalTambahProvinsi}
                  toggleModalTambahProvinsi={toggleModalTambahProvinsi}
                  listSelected={showProvinsiTambahan}
                  selectedProvince={handleSelectedProvince}
                />
              </Col>
              <Col xs={{ size: "6", offset: "3" }} style={{ paddingBottom: "10px" }}>
                <Label for="titleArticle">Tanggal Survei Tayang</Label>
                <Button
                  color=""
                  onClick={toggleModalCalendar}
                  id="tanggal"
                  className="inputWrapper btnTanggal">
                  <p className="selectedDate">{selectedDate}</p>
                </Button>
                <ModalCalendar
                  provSelected={idProvince}
                  modalCalendar={modalCalendar}
                  toggleModalCalendar={toggleModalCalendar}
                  selectedDate={handleSelectedDate}
                />
              </Col>
              {/* <Col xs={{ size: "6", offset: "3" }} style={{ paddingBottom: "10px" }}>
                <Label for="titleArticle">Tanggal Survei Tayang</Label>
                <Button
                  disabled={_.isNil(idProvince) || loadDate}
                  color=""
                  onClick={() => {
                    setModalTgl(!modalTgl);
                  }}
                  id="tanggal"
                  className="inputWrapper btnTanggalAdmin">
                  <div
                    style={{
                      color: "#2C3280",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <p
                      className={
                        dateSelected === "" ? "selectedDatePlaceholder" : "selectedDateAdmin"
                      }>
                      {dateSelected === "" ? "Pilih Tanggal" : selectedDate}
                    </p>
                    <WeekPicker
                      isLoadDate={setLoadDate}
                      provSelected={idProvince}
                      focusing={modalTgl}
                      setModal={setModalTgl}
                      setDate={(e) => {
                        console.log("date", e);
                        setDateSelected(e);
                      }}
                    />
                    {!loadDate ? <img src={CalendarIcon} /> : <span>Loading...</span>}
                  </div>
                </Button>
              </Col> */}
              <Col xs={{ size: "6", offset: "3" }} style={{ paddingBottom: "10px" }}>
                <Label for="titleArticle">Jumlah Pertanyaan</Label>
                <FilterJumlahSurvei
                  type={"Pilih Jumlah Survei"}
                  filter={jp}
                  clicked={handleAdd}
                  list={Array.from({ length: 10 }, (_, i) => i + 1)}
                />
                {/* <Input
                  type="number"
                  max="10"
                  min="1"
                  name="number"
                  onKeyDown={(event) => {
                    event.preventDefault();
                  }}
                  id="countQ"
                  placeholder="Jumlah Pertanyaan"
                  className="inputWrapper"
                  onChange={(e) => e}
                /> */}
              </Col>
            </Row>
            {inputList.map((x, i) => {
              return (
                <div key={i}>
                  <Row>
                    <Col
                      xs={{ size: "1", offset: "1" }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        color: "darkblue",
                      }}>
                      <div>{i + 1}</div>
                    </Col>
                    <Col xs={{ size: "9" }} style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                      <Input
                        name="pertanyaan"
                        value={x.pertanyaan}
                        onChange={(e) => handleInputChange(e, i)}
                        placeholder="Pertanyaan"
                        className="inputWrapper"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={{ size: "1", offset: "1" }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        color: "darkblue",
                      }}>
                      <div></div>
                    </Col>
                    <Col xs={{ size: "7" }}>
                      <div>
                        {x.tipePertanyaan === "essay" ? (
                          <div></div>
                        ) : x.tipePertanyaan === "radio" ? (
                          inputList[i].jawaban.map((value, index) => {
                            return (
                              <div key={index}>
                                <Row>
                                  <Col
                                    xs="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <span className="mdi mdi-18px mdi-radiobox-blank"></span>
                                  </Col>
                                  <Col xs="11">
                                    <Input
                                      name="jawaban"
                                      value={value}
                                      onChange={(e) => handleInputChangeJawab(e, index, i)}
                                      placeholder="Jawaban"
                                      className="inputWrapper"
                                    />
                                  </Col>
                                </Row>
                                <div className="btn-box">
                                  <Row>
                                    <Col xs={{ size: "5", offset: "1" }}>
                                      {inputList[i].jawaban.length - 1 === index && (
                                        <Button
                                          color=""
                                          className="buttonJawabanAdd"
                                          style={{ color: "darkblue" }}
                                          onClick={() => handleAddClickJawab(index, i)}>
                                          Tambah Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                    <Col xs="6" style={{ textAlign: "right" }}>
                                      {inputList[i].jawaban.length !== 1 && (
                                        <Button
                                          color=""
                                          className="buttonJawabanRemove"
                                          onClick={() => handleRemoveClickJawab(index, i)}>
                                          Hapus Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            );
                          })
                        ) : x.tipePertanyaan === "checkbox" ? (
                          inputList[i].jawaban.map((value, index) => {
                            return (
                              <div key={index}>
                                <Row>
                                  <Col
                                    xs="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <span className="mdi mdi-18px mdi-checkbox-blank-outline"></span>
                                  </Col>
                                  <Col xs="11">
                                    <Input
                                      name="jawaban"
                                      value={value}
                                      onChange={(e) => handleInputChangeJawab(e, index, i)}
                                      placeholder="Jawaban"
                                      className="inputWrapper"
                                    />
                                  </Col>
                                </Row>
                                <div className="btn-box">
                                  <Row>
                                    <Col xs={{ size: "5", offset: "1" }}>
                                      {inputList[i].jawaban.length - 1 === index && (
                                        <Button
                                          color=""
                                          className="buttonJawabanAdd"
                                          style={{ color: "darkblue" }}
                                          onClick={() => handleAddClickJawab(index, i)}>
                                          Tambah Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                    <Col xs="6" style={{ textAlign: "right" }}>
                                      {inputList[i].jawaban.length !== 1 && (
                                        <Button
                                          color=""
                                          className="buttonJawabanRemove"
                                          onClick={() => handleRemoveClickJawab(index, i)}>
                                          Hapus Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          inputList[i].jawaban.map((value, index) => {
                            return (
                              <div key={index}>
                                <Input
                                  name="jawaban"
                                  value={value}
                                  onChange={(e) => handleInputChangeJawab(e, index, i)}
                                  placeholder="Jawaban"
                                  className="inputWrapper"
                                />
                                <div className="btn-box">
                                  <Row>
                                    <Col xs="6">
                                      {inputList[i].jawaban.length - 1 === index && (
                                        <Button
                                          color=""
                                          className="buttonJawabanAdd"
                                          style={{ color: "darkblue" }}
                                          onClick={() => handleAddClickJawab(index, i)}>
                                          Tambah Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                    <Col xs="6" style={{ textAlign: "right" }}>
                                      {inputList[i].jawaban.length !== 1 && (
                                        <Button
                                          color=""
                                          className="buttonJawabanRemove"
                                          onClick={() => handleRemoveClickJawab(index, i)}>
                                          Hapus Pilihan
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </Col>
                    <Col xs="2">
                      <Col xs="12">
                        <Label>Jenis Jawaban</Label>
                        <Input
                          name="tipePertanyaan"
                          onChange={(e) => handleChoice(e, i)}
                          type="select"
                          placeholder="Choose Type"
                          className="inputWrapper"
                          style={{ paddingRight: "10px", color: "darkblue" }}>
                          <option value="essay">Essay</option>
                          <option value="radio">Pilihan Ganda</option>
                          <option value="checkbox">Checkbox</option>
                          <option value="dropdown">Dropdown</option>
                        </Input>
                      </Col>
                      {x.tipePertanyaan == "checkbox" ? (
                        <Col className="mt-1" xs="12">
                          <Label>Max Pilihan</Label>
                          <FilterMaxPilihan
                            indexSurvei={i}
                            typeSurvei={x.tipePertanyaan ? x.tipePertanyaan : ""}
                            type={"Maks Pilihan"}
                            filter={x.maxPilihan}
                            clicked={handleMax}
                            list={_.range(
                              2,
                              inputList[i][`jawaban`].length && inputList[i][`jawaban`].length + 1
                            )}
                          />
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  {/* <Row className="d-flex justify-content-end">
                  
                  </Row> */}
                </div>
              );
            })}
            {inputList.length === 0 ? (
              <div></div>
            ) : (
              <Row style={{ paddingTop: "21px" }}>
                <Col xs={{ size: "2", offset: "4" }}>
                  <Button onClick={handleClear} color="" className="buttonClearSurvei">
                    Clear
                  </Button>
                </Col>
                <Col xs="2">
                  <Button
                    color=""
                    onClick={() => {
                      handleAddSurvey();
                    }}
                    disabled={disabled()}
                    className="buttonBuatSurvei">
                    Buat Survei
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </main>
      </div>
    </>
  );
}
// const mapStateToProps = () => {
//     return{}
// }

// export default connect(mapStateToProps,{setDummySurvey})(BuatSurveyAdmin);
export default BuatSurveyAdmin;
