import React from "react";

import { Row, Card, Col, Spinner } from "reactstrap";

// import "tui-chart/dist/tui-chart.css";
import { ColumnChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "./style.module.css";
import "./toastui.scss";

import Pdf from "react-to-pdf";
import Swal from "sweetalert2";

// import androidIcon from "../../assets/images/FingerprintBI/android_green.svg";
// import windowsIcon from "../../assets/images/FingerprintBI/windows_blue.svg";
// import iosIcon from "../../assets/images/FingerprintBI/ios_logo.svg";
// import chromeIcon from "../../assets/images/FingerprintBI/logos_chrome.svg";
// import linuxIcon from "../../assets/images/FingerprintBI/linux_logo.svg";
// import macosIcon from "../../assets/images/FingerprintBI/logos_macos.svg";

// import API from "../../../../services";
var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  // title: {
  //     color: '#8791af',
  // },
  xAxis: {
    // title: {
    //     color: '#8791af'
    // },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#1E1BB7"],
  },
};

export default function CardPersebaranOS(props) {
  const downloadRef = React.createRef();

  const handlePDFDownload = (fn) => {
    Swal.fire({
      title: "Apakah kamu ingin mendownload halaman ini?",
      text: "Data PDF akan di download",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2c3280",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        fn();
      }
    });
  };
  const renderColumnChart = (param) => {
    const _width =
      window.screen.width > 993
        ? window.screen.width * 0.25
        : window.screen.width < 400
        ? window.screen.width * 0.77
        : window.screen.width * 0.4;
    const data = {
      categories: param
        ? param.os_type.length !== 0
          ? param.os_type.map((res) => {
              return res.name;
            }) ?? ""
          : ""
        : "",
      series: [
        {
          name: "Jumlah Pengguna",
          // data: param ? param.Agribisnis ?? 100 : 100,
          data: param
            ? param.os_type.length !== 0
              ? param.os_type.map((res) => {
                  return res.count;
                }) ?? 0
              : 0
            : 0,
        },
      ],
    };

    const options = {
      chart: {
        width: _width,
        height: _width * 0.8 + 90,
        // title: 'Jumlah',
        // format: "1,000",
      },
      yAxis: {
        title: "Jumlah",
        min: 0,
        max: param
          ? param.os_type.length !== 0
            ? Math.max(
                ...param.os_type.map((res) => {
                  return res.count;
                })
              ) + 10
            : 10
          : 10,
      },
      xAxis: {
        title: "OS",
      },
      legend: {
        align: "bottom",
        // visible: false,
        showCheckbox: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Persebaran_OS_Device_Fingerprint",
      },
    };
    // untuk apply theme
    TuiChart.registerTheme("themePersebaranOS", theme);
    options.theme = "themePersebaranOS";

    return (
      <React.Fragment>
        <ColumnChart data={data} options={options} />
      </React.Fragment>
    );
  };

  const options = {
    orientation: "landscape",
    // unit: 'in',
    // format: [4,2]
  };

  return (
    <div>
      <Row style={{ minHeight: "500px" }}>
        <Col md="12">
          <Card body style={{ boxShadow: "none" }}>
            <div className="p-3">
              <Row
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className={`${style.titleCardBI}`}>PERSEBARAN OS</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div>Download</div>
                  <div className="mx-3">
                    <Pdf
                      targetRef={downloadRef}
                      filename="dfp-os.pdf"
                      options={options}
                      scale={0.8}
                      x={3}
                      y={20}>
                      {({ toPdf }) => (
                        <a href="#" onClick={() => handlePDFDownload(toPdf)}>
                          PDF
                        </a>
                      )}
                    </Pdf>
                  </div>
                </div>
              </Row>
              <div ref={downloadRef}>
                <Row>
                  <Col md="12" lg="6">
                    <div className="mt-1 mx-auto">
                      <div className="d-flex justify-content-center align-items-center">
                        {props.loading ? (
                          <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                            <Spinner color="primary" />
                          </div>
                        ) : props.data && props.data.length !== 0 ? (
                          props.data.os_type.length !== 0 ? (
                            renderColumnChart(props.data)
                          ) : (
                            <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                              <span className={style.titleCardBI}>Tidak Ada Data</span>
                            </div>
                          )
                        ) : (
                          <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                            <span className={style.titleCardBI}>Terjadi Kesalahan</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md="12" lg="6" style={{ minHeight: "100%" }}>
                    <Row className="my-3">
                      <div className={`${style.titleCardBI}`}>6 OS Terpopuler</div>
                    </Row>
                    <Row className="text-center" style={{ minHeight: "40%" }}>
                      {props.data
                        ? props.data.os_type
                          ? props.data.os_type.map((res, i) => (
                              <Col key={i} md="12" lg="4">
                                <div
                                  style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
                                  className="my-2">
                                  <Col
                                    xs="12"
                                    style={{
                                      height: "60px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}>
                                    <div style={{ fontSize: "15px", color: "#706F6F" }}>
                                      {res.name}
                                    </div>
                                  </Col>
                                  {props.loading ? (
                                    <div className="my-3">
                                      <Spinner color="primary" />
                                    </div>
                                  ) : (
                                    <Col
                                      xs="12"
                                      style={{
                                        height: "60px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      className={style.valuePersebaranOS}>
                                      {" "}
                                      {res.count.toLocaleString()}
                                    </Col>
                                  )}
                                </div>
                              </Col>
                            )) ?? ""
                          : ""
                        : ""}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
