import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, Button } from "reactstrap";
import { MDBModalHeader, MDBNavLink } from "mdbreact";
import moment from "moment";

// COMPONENT
import CardGender from "./CardGender";
import CardPieChart from "./CardPieChart";
import CardPersebaranUmur from "./CardPersebaranUmur";

import { useSelector } from "react-redux";

import style from "./style.module.css";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLS from "./Download/XLS/DownloadDetailDemografi";

import ModalDownloadAllDetail from "./Modal/ModalDownloadDetailDemografi";

export default function DemografiPengunjungSurvey(props) {
  // MODAL DOWNLOAD
  const [modalDownloadXLS, setModalDownloadXLS] = useState(false);
  const toggleDownloadXLS = () => {
    setModalDownloadXLS(!modalDownloadXLS);
  };

  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  const [dataDemografiSurvey, setDataDemografiSurvey] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.filter.condition == "1"
      ? dataReduxLogHistory.data.filter.survey_title
      : props.data
  );

  // MODAL DOWNLOAD ALL DETAIL DEMOGRAFI SURVEI
  const [modalDownloadXLSAllDetailSurvei, setModalDownloadXLSAllDetailSurvei] = useState(false);
  const toggleDownloadXLSAllDetailSurvei = () => {
    setModalDownloadXLSAllDetailSurvei(!modalDownloadXLSAllDetailSurvei);
  };

  useEffect(() => {
    if (props.activeTab === "1") {
      props.setDataSave({
        survey_title: props.data,
        measure: "survey",
        condition: "1",
      });
    }
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.activeTab]);

  // useEffect(() => {
  //   setDataDemografiSurvey(props.data);
  // }, []);

  return (
    <div className="container-fluid" style={{ marginBottom: "10px" }}>
      <ModalDownloadAllDetail
        toggleDetail={toggleDownloadXLSAllDetailSurvei}
        modalDetail={modalDownloadXLSAllDetailSurvei}
      />
      <div>
        <Modal size="md" isOpen={modalDownloadXLS} toggle={toggleDownloadXLS} centered={true}>
          <MDBModalHeader toggle={toggleDownloadXLS}>
            <span className={style.headText}>Download XLS</span>
          </MDBModalHeader>
          <ModalBody>
            <center className="container-popup">
              <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
              <Button color="" className={style.btn_reject} onClick={toggleDownloadXLS}>
                Batal
              </Button>
              <Button color="" className={style.btn_confirm_xls} onClick={toggleDownloadXLS}>
                <ReactHTMLTableToExcel
                  id="TableXLS"
                  className={style.DownloadTableXLS}
                  table="DownloadTableXLSSurveyDemografi"
                  filename={`demografi-survey ${moment(new Date()).format("DD-MM-YYYY")}`}
                  sheet="demografi survey"
                  buttonText="Download"
                />
              </Button>
            </center>
          </ModalBody>
        </Modal>
        <Col
          className="my-3"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}>
          <MDBNavLink to="#" className={style.textDownload}>
            Download
          </MDBNavLink>
          <MDBNavLink onClick={toggleDownloadXLS} link to="#">
            XLS
          </MDBNavLink>
          <Button
            style={{ backgroundColor: "#2c3280", borderRadius: "15px" }}
            onClick={toggleDownloadXLSAllDetailSurvei}>
            Detail Demografi Pengunjung
          </Button>
        </Col>
        <DownloadXLS
          id="DownloadTableXLSSurveyDemografi"
          className="DownloadTableXLS"
          data={dataDemografiSurvey}
        />
      </div>
      <CardGender loading={props.loading} data={dataDemografiSurvey} />
      <CardPieChart loading={props.loading} data={dataDemografiSurvey} />
      <CardPersebaranUmur loading={props.loading} data={dataDemografiSurvey} />
    </div>
  );
}
