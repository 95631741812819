import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, Spinner } from "reactstrap";
import star from "../../../../assets/images/Star4.png";
import starwhite from "../../../../assets/images/Star10.png";
import API from "../../../../services";
import Skeleton from "react-loading-skeleton";

export default function CardRating(props) {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadCPAverage, setLoadCPAverage] = useState(true);
  const [loadVisitorAverage, setLoadVisitorAverage] = useState(true);
  const [ratingCPAverage, setRatingCPAverage] = useState("");
  const [ratingVisitorAverage, setRatingVisitorAverage] = useState("");

  useEffect(() => {
    const fetchAPIAverage = () => {
      API.getAvgRating()
        .then((res) => {
          return res;
        })
        .then((res) => {
          if (res.data) {
            if (res.data.success) {
              setRatingCPAverage(res.data ? res.data?.averageCP : "");
              setRatingVisitorAverage(res.data ? res.data?.averageVisitor : "0");
            }
          }
        })
        .then(() => {
          setLoadVisitorAverage(false);
          setLoadCPAverage(false);
        })
        .catch((e) => {
          setLoadVisitorAverage(false);
          setLoadCPAverage(false);
        });
    };

    const fetchAPIRating = () => {
      API.getRating()
        .then((res) => {
          // console.log("res", res);
          if (res !== undefined) {
            let json = res.data.data;
            setLoading(false);
            setRatingCPAverage(res.data?.average ?? "");
            setData(json);
          } else {
            setLoading(false);
            setData([]);
          }
        })
        .catch((err) => {
          props.setError("Gagal Memuat Data");
          setLoading(false);
        });
    };
    fetchAPIAverage();
    fetchAPIRating();
  }, []);

  return (
    <Container className="text-center" style={{ marginBottom: "100px" }}>
      <Row>
        <Col lg="6">
          <Row>
            <h3 style={{ textAlign: "start" }}>RATING KEPUASAN PARTNER</h3>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="text-bold">
                <span className="textBlackBold">Rata-Rata Rating Kepuasan</span>
              </div>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <div className="text-bold">
                <span className="textBlueBold ml-1">
                  {loadCPAverage ? <Spinner color="primary" /> : ratingCPAverage}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingContentPartner ? (
                    `${data?.dataRatingContentPartner["5"]} Content Partner`
                  ) : (
                    "0 Content Partner"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={starwhite} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {" "}
                {data ? (
                  data.dataRatingContentPartner["4"] ? (
                    `${data.dataRatingContentPartner["4"]} Content Partner`
                  ) : (
                    "0 Content Partner"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingContentPartner["3"] ? (
                    `${data.dataRatingContentPartner["3"]} Content Partner`
                  ) : (
                    "0 Content Partner"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingContentPartner["2"] ? (
                    `${data.dataRatingContentPartner["2"]} Content Partner`
                  ) : (
                    "0 Content Partner"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingContentPartner["1"] ? (
                    `${data.dataRatingContentPartner["1"]} Content Partner`
                  ) : (
                    "0 Content Partner"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <h3 style={{ textAlign: "start" }}>RATING KEPUASAN VISITOR</h3>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="text-bold">
                <span className="textBlackBold">Rata-Rata Rating Kepuasan</span>
              </div>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <div className="text-bold">
                <span className="textBlueBold ml-1">
                  {loadVisitorAverage ? <Spinner color="primary" /> : ` ${ratingVisitorAverage}`}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingVisitor["5"] ? (
                    `${data.dataRatingVisitor["5"]} Visitor`
                  ) : (
                    "0 Visitor"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={starwhite} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingVisitor["4"] ? (
                    `${data.dataRatingVisitor["4"]} Visitor`
                  ) : (
                    "0 Visitor"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingVisitor["3"] ? (
                    `${data.dataRatingVisitor["3"]} Visitor`
                  ) : (
                    "0 Visitor"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={star} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingVisitor["2"] ? (
                    `${data.dataRatingVisitor["2"]} Visitor`
                  ) : (
                    "0 Visitor"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
          <Row className="my-2">
            <Col lg="6">
              <img className="mr-1" src={star} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
              <img className="mr-1" src={starwhite} />
            </Col>
            {loading ? (
              <Skeleton />
            ) : (
              <Col lg="6" className="d-flex align-items-center justify-content-center">
                {data ? (
                  data.dataRatingVisitor["1"] ? (
                    `${data.dataRatingVisitor["1"]} Visitor`
                  ) : (
                    "0 Visitor"
                  )
                ) : (
                  <Skeleton />
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
