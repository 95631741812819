import React, { useState, useEffect, useRef } from "react";
import { Container, Card, CardBody, Button, Row, Col } from "reactstrap";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import API from "../../../services";
import Filter from "../../../components/Filter/filter";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ModalKirimPesan from "../Popup/KirimPesan";
import "./style.css";

// sweetalert2
import Swal from "sweetalert2";

const CPAnalisiTable = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);

  const kontenOnClick = (e) => {
    history.push({
      pathname: `/admin/cp-analitics-detail`,
      state: e,
    });
  };

  useEffect(() => {
    API.getCPAnalitic().then((result) => {
      if (result.status == 200) {
        const data = result.data.data;
        props.handleData(data);
        // console.log("daataaaaa", data);
        setRows(
          data.map((e) => {
            return {
              id: e.id,
              name: e.name,
              organization: e.organization,
              register_date: moment(e.register_date).format("DD-MM-YYYY"),
              content_count:
                Object.values(e.content_count).length !== 0
                  ? e.content_count.news + e.content_count.banner + e.content_count.video ?? "-"
                  : "-",
              content_count_video:
                Object.values(e.content_count).length !== 0 ? e.content_count.video ?? "-" : "-",
              content_count_banner:
                Object.values(e.content_count).length !== 0 ? e.content_count.banner ?? "-" : "-",
              content_count_news:
                Object.values(e.content_count).length !== 0 ? e.content_count.news ?? "-" : "-",
              last_content_create:
                e.last_content_create != "-"
                  ? moment(e.last_content_create).format("DD-MM-YYYY")
                  : e.last_content_create,
              act: [
                <Row>
                  <Col xs="6">
                    <a onClick={() => handleModalKP(e)}>
                      <span class="mdi mdi-24px mdi-email" style={{ color: "darkblue" }} />
                    </a>
                  </Col>
                  <Col xs="6">
                    <Button
                      onClick={() => {
                        if (e.content_count !== 0) {
                          //alert(JSON.stringify(e));
                          kontenOnClick(e);
                        } else {
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `Tidak Ada Konten`,
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        }
                      }}
                      className="btn-rounded btnDetail">
                      Konten
                    </Button>
                  </Col>
                </Row>,
              ],
            };
          })
        );
      }
    });
    return () => {};
  }, []);

  //modal surat
  const [ModalSurat, setModalSurat] = useState(false);
  const [dataModalSurat, setDataModalSurat] = useState({});
  const toggleModalSurat = () => {
    setModalSurat(!ModalSurat);
  };
  const handleModalKP = (data) => {
    setDataModalSurat({
      id: data.id,
      organization: data.organization,
      name: data.name,
    });
    toggleModalSurat();
  };

  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };

  const filterData = (item) => {
    props.handleData(item);
  };
  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search, props.activeTab]);

  //filtering data
  var items = [];
  items = rows.filter((data) => {
    if (search === "") return data;
    const index = [
      data.id,
      data.name,
      data.organization,
      data.register,
      data.content_count,
      data.content_count_video,
      data.content_count_banner,
      data.content_count_news,
      data.last_content != "-" ? data.last_content : "-",
    ];
    if (
      Filter.byIndex({
        index: index,
        search: search,
        date: [],
      })
    ) {
      return data;
    }
  });

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 100,
      },
      {
        label: "Nama",
        field: "name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Organisasi",
        field: "organization",
        sort: "asc",
        width: 100,
      },
      {
        label: "Tanggal Registrasi",
        field: "register_date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Jumlah Konten",
        field: "content_count",
        sort: "asc",
        width: 100,
      },
      {
        label: "Total Video",
        field: "content_count_video",
        sort: "asc",
        width: 100,
      },
      {
        label: "Total Banner",
        field: "content_count_banner",
        sort: "asc",
        width: 100,
      },
      {
        label: "Total Berita",
        field: "content_count_news",
        sort: "asc",
        width: 100,
      },
      {
        label: "Terakhir Upload Konten",
        field: "last_content_create",
        sort: "asc",
        width: 100,
      },
      {
        label: "",
        field: "act",
        sort: "disabled",
        width: 500,
      },
    ],
    rows:
      rows.length != 0 &&
      items.map((it, i) => ({
        no: i + 1,
        id: it.id,
        name: it.name,
        organization: it.organization,
        register_date: `${moment(it.register_date).format("YYYYMMDD")} 
            ${it.register_date}`,
        content_count: it.content_count,
        content_count_video: it.content_count_video,
        content_count_banner: it.content_count_banner,
        content_count_news: it.content_count_news,
        last_content_create: `${moment(it.last_content_create).format("YYYYMMDD")}
             ${it.last_content_create}`,
        act: it.act,
      })),
  };

  return (
    <>
      <ModalKirimPesan
        dataModalKP={dataModalSurat}
        modalKP={ModalSurat}
        toggleModalKP={toggleModalSurat}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-25 my-3">
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">Semua Konten sudah di kurasi</div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="tableCPAnalisis"
                        noBottomColumns={true}
                        className="text-center fontSizeCPAnalisis"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CPAnalisiTable;
