import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDF = (dataTable, activeTab) => {
  console.log("activeTab", activeTab);
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title;
  let headers;
  let data;
  switch (activeTab) {
    case "3":
      title = `Data Unique Device`;
      headers = [
        ["No", "ID", "Terakhir Login", "Jumlah Visit", "OS", "Browser", "Model", "Resolusi"],
      ];
      data = dataTable.map((data, index) => {
        return [
          index + 1,
          data._id ?? "-",
          `${moment(data.lastLogin).format("DD-MM-YYYY")}` ?? "-",
          data.jumlahVisit ?? "-",
          data.os ?? "-",
          data.browser ?? "-",
          data.model ?? "-",
          data.resolution,
        ];
      });
      break;
    case "1":
      title = `Data Site AI`;
      headers = [
        ["No", "Nama Lokasi AI", "Provinsi", "Kabupaten", "Default Bandwidth", "IP Address"],
      ];
      data = dataTable.map((data, index) => {
        return [
          index + 1,
          data ? (data.name ? data.name : "-") : "-",
          data ? (data.province ? data.province : "-") : "-",
          data ? (data.kab_kota ? data.kab_kota : "-") : "-",
          data ? (data.bandwidth ? data.bandwidth : "-") : "-",
          data ? (data.ip ? data.ip.map((res) => `${res}\n`).join("") : "-") : "-",
        ];
      });
      break;
    case "2":
      title = `Data Behaviour Site`;
      headers = [
        [
          "No",
          "Nama Lokasi",
          "Provinsi",
          "Kabupaten",
          "Total DFP Unique",
          "Total Kunjungan",
          "Tanggal Integrasi",
          "Terakhir Diakses",
        ],
      ];
      data = dataTable.map((data, index) => {
        return [
          index + 1,
          data ? data.name : "-",
          data ? data.kabupaten_kota : "-",
          data ? data.provinsi : "-",
          data ? data.uniqdfp : "-",
          data ? data.jumlah : "-",
          data
            ? data.first
              ? moment(data.first.tanggal_pembuatan, "YYYY-MM-DD").format("DD-MM-YYYY").toString()
              : "-"
            : "-",
          data
            ? data.last
              ? moment(data.last.tanggal_pembuatan, "YYYY-MM-DD").format("DD-MM-YYYY").toString()
              : "-"
            : "-",
        ];
      });
      break;

    default:
      break;
  }

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${title}.pdf`);
};
