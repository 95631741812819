// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".artikel_lastSec__1Ka60 section{\n    position: relative;\n    z-index: 1;\n    text-align: center;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 20px;\n}\n\n.artikel_displayLinebreak__eX_kq {\n    white-space: pre-line;\n}\n\n.artikel_buttonSearchArti__4xI7H{\n    background-color: #00438B !important;\n    width: 100% !important;\n    margin-bottom: 20px !important;\n}\n\n.artikel_imageWrapper__3TC4S {\n    text-align: center;\n    margin-bottom: 20px;\n    margin-top: 10px;\n}\n\n.artikel_imageMain__3yYL1{\n    width: 100%;\n    object-fit: cover;\n    height: 25rem;\n}\n\n.artikel_imageSub__gALLH{\n    width: 100%;\n    object-fit: cover;\n    height: 15rem;\n}\n\n.artikel_sectBorder__1dn3T{\n    border-top: 1px solid #D0D0D0 !important;\n}\n\n.artikel_paddingTope__1INbJ{\n    padding-top: 125px;\n}\n\n@media (max-width: 576px) {\n    .artikel_paddingTope__1INbJ{\n        padding-top: 100px;\n    }\n}\n\n@media (max-width: 768px) {\n    .artikel_paddingTope__1INbJ{\n        padding-top: 150px;\n    }\n}\n\n@media (max-width: 981px) {\n    .artikel_paddingTope__1INbJ{\n        padding-top: 150px;\n    }\n}\n\n.artikel_cardTitle__1ec3g{\n    text-overflow: ellipsis !important; \n    overflow: hidden !important; \n    white-space: nowrap !important;\n    text-align: left;\n}\n\n.artikel_cardSubtitle__ew-BJ{\n    display: block;/* or inline-block */\n    text-overflow: ellipsis !important;\n    word-wrap: break-word !important;\n    overflow: hidden !important;\n    max-height: 4.5em;\n    line-height: 1.5em;\n    text-align: left;\n}", ""]);
// Exports
exports.locals = {
	"lastSec": "artikel_lastSec__1Ka60",
	"displayLinebreak": "artikel_displayLinebreak__eX_kq",
	"buttonSearchArti": "artikel_buttonSearchArti__4xI7H",
	"imageWrapper": "artikel_imageWrapper__3TC4S",
	"imageMain": "artikel_imageMain__3yYL1",
	"imageSub": "artikel_imageSub__gALLH",
	"sectBorder": "artikel_sectBorder__1dn3T",
	"paddingTope": "artikel_paddingTope__1INbJ",
	"cardTitle": "artikel_cardTitle__1ec3g",
	"cardSubtitle": "artikel_cardSubtitle__ew-BJ"
};
module.exports = exports;
