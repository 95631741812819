import React, { useEffect } from 'react';
import { Container, Card, CardBody, Row, Col, Button } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import API from '../../../services';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import DeleteNewsletter from '../Popup/Article/DeleteNewsletter';

export default function TableNewsletter() {
    const [dataRows, setDataRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalHapus, setModalHapus] = useState(false);
    const [toModalHapus, setToModalHapus] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const getTableNewsLetter = () => {
        API.getTableNewsLetter().then(response => {
            var res = response.data.data;
            setDataRows(res)
            setLoading(false)
        }).catch(
            setLoading(true)
        )
    }

    const handleHapus = (val) => {
        setToModalHapus(val);
        setModalHapus(!modalHapus);
    }

    const handleRefresh = () => {
        setRefresh(true)
        setRefresh(false)
    }

    useEffect((val) => {
        getTableNewsLetter()
    }, [refresh])

    const data = {
        columns: [
            {
                label: "No",
                field: "no",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Email",
                field: "email",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Tanggal Input Newsletter",
                field: "inputNewsletter",
                sort: "disabled",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                sort: "disabled",
                width: 200,
            },
        ],
        rows: dataRows.map((val, index) => ({
            no: index + 1,
            email: val.email,
            inputNewsletter: moment(val.tanggal_pembuatan).format('DD/MM/YYYY'),
            action: <Button onClick={() => handleHapus(val)} style={{ backgroundColor: "#C42127" }}>Hapus</Button>
        }))
    }
    return (
        <div>
            <Container>
                <div>
                    {
                        loading == true ? <div className="mt-2 mb-2"><Skeleton height={100} /></div> :
                            <Card style={{ boxShadow: "0px 0px 10px #D3D3D3", paddingBottom: "30px", paddingTop: "20px" }}>
                                <CardBody>
                                    <h4>
                                        Daftar Newsletter
                                    </h4>
                                    <MDBDataTable
                                        responsive
                                        striped
                                        searching={false}
                                        entries={5}
                                        displayEntries={false}
                                        data={data}
                                        noBottomColumns={true}
                                        info={true}
                                    />
                                </CardBody>
                            </Card>
                    }
                </div>
                <DeleteNewsletter modal={modalHapus} toggle={handleHapus} data={toModalHapus} refresh={handleRefresh} />
            </Container>
        </div>
    )
}
