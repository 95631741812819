import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";
import IconEmergency_38 from "../../../../../assets/icon/IconEmergency_38.svg";

const ModalDescEmergency = ({ modalDesc, toggleModalDesc }) => {
  return (
    <MDBContainer className="modalContainer">
      <MDBModal
        className="modalWrapper"
        size="lg"
        isOpen={modalDesc}
        toggle={toggleModalDesc}
        centered={true}>
        <MDBModalHeader centered toggle={toggleModalDesc}>
          <center>
            <p className="titleEmergencyDesc">EMERGENCY CONTENT</p>
            <img src={IconEmergency_38}></img>
          </center>
          <div className=" container wrapperContentEmergencyDesc">
            <p className="subTitleEmergencyDesc">Emergency Content</p>
            <p className="pEmergencyDesc">
              <ol>
                <li>
                  Konten mengenai force major / kondisi kahar , contoh: pandemi, bencana alam, dll.
                </li>
                <li>
                  Konten mengenai penyampaian informasi massal yang dinilai penting dan bersifat
                  urgent
                </li>
                <li>
                  Prioritas sistem blok slot jadwal tayang konten seperti tanggal, durasi, dan jam
                  tayang akan didahulukan dari konten reguler.
                </li>
                <li>Wajib melampirkan dokumen surat emergency</li>
              </ol>
            </p>
            <p className="subTitleEmergencyDesc">{`Syarat & Ketentuan`}</p>
            <p className="pEmergencyDesc">
              <ol>
                <li>Konten yang diajukan tidak mengandung SARA atau ujaran kebencian</li>
                <li>Wajib melampirkan dokumen emergency konten</li>
                <li>
                  Konten mengenai penyampaian informasi massal yang dinilai penting dan bersifat
                  urgent
                </li>
              </ol>
            </p>
            <p className="subTitleEmergencyDesc">Tata Cara</p>

            <div className="pEmergencyDesc">
              <ol>
                <li>Login sebagai Content Partner</li>

                <li>Klik menu konten</li>
                <li>Klik Buat Konten</li>
                <li>Klik checkbox Emergency Content</li>
                <li>Unduh Surat Emergency Content</li>
                <li>
                  Isi surat emergency beserta tanda tangan dan stempel, lalu simpan sebagai PDF
                </li>
                <li>Unggah surat emergency dalam bentuk pdf</li>

                <li>Input seluruh data untuk pembuatan konten</li>
                <li>Klik Ajukan Konten</li>
              </ol>
            </div>
          </div>
        </MDBModalHeader>
        <MDBModalBody></MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalDescEmergency;
