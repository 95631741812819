import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";
import IconEmergency_38 from "../../../../../assets/icon/IconEmergency_38.svg";

const ModalDescEmergency = ({ modalDesc, toggleModalDesc }) => {
  return (
    <MDBContainer className="modalContainer">
      <MDBModal
        className="modalWrapper"
        size="lg"
        isOpen={modalDesc}
        toggle={toggleModalDesc}
        centered={true}>
        <MDBModalHeader centered toggle={toggleModalDesc}>
          <center>
            <p className="titleEmergencyDesc">EMERGENCY CONTENT</p>
            <img src={IconEmergency_38}></img>
          </center>
          <div className=" container wrapperContentEmergencyDesc">
            <p className="subTitleEmergencyDesc">Emergency Content</p>
            <p className="pEmergencyDesc">
              Dolor sit amet, consectetur adipiscing elit. Quisque in id est porttitor in feugiat
              turpis vulputate. Scelerisque suspendisse lorem feugiat mauris. Dapibus sit sagittis,
              velit consequat egestas non ornare purus velit. At turpis placerat mauris commodo
              adipiscing malesuada elementum. Tincidunt erat amet consectetur integer nam at
              suspendisse. Nec neque vitae, integer volutpat, enim.
            </p>
            <p className="subTitleEmergencyDesc">{`Syarat & Ketentuan`}</p>
            <p className="pEmergencyDesc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non eu nunc vitae id ante
              sociis a et. Pretium diam faucibus ut convallis dignissim at leo. Euismod nibh aliquam
              nibh leo. Metus nunc vitae lectus proin. Rhoncus nunc aliquet posuere fermentum nec
              habitant tincidunt euismod. Massa aliquam ut quis justo, turpis mi. Scelerisque ac
              odio ut in. Nullam semper mauris mi, amet adipiscing. Neque proin suspendisse
              tincidunt vulputate.
            </p>
            <p className="subTitleEmergencyDesc">Tata Cara</p>

            <div className="pEmergencyDesc">
              <ul>
                <li>1. Login sebagai Content Partner</li>

                <li>2. Klik menu konten</li>
                <li>3. Klik Buat Konten</li>
                <li>4. Klik checkbox Emergency Content</li>
                <li>5. Unduh Surat Emergency Content</li>
                <li>
                  6. Isi surat emergency beserta tanda tangan dan stempel, lalu simpan sebagai PDF
                </li>
                <li>7. Unggah surat emergency dalam bentuk pdf</li>

                <li>8. Input seluruh data untuk pembuatan konten</li>
                <li>9. Klik Ajukan Konten</li>
              </ul>
            </div>
          </div>
        </MDBModalHeader>
        <MDBModalBody></MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalDescEmergency;
