import React from "react";
import { Row, Card, Col, CardBody } from "reactstrap";
import style from "../style.module.css";

// ASSETS
import video from "../../../assets/images/video.png";
import banner from "../../../assets/images/banner.png";
import berita from "../../../assets/images/berita.png";
import Skeleton from "react-loading-skeleton";

export default function CardSummaryTrafikKonten(props) {
  return (
    <div className="container-fluid">
      <div className={`${style.titleCardBI} my-1`}>JENIS KELAMIN</div>
      <div className="m-3">
        <Row>
          <Col
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
            className="mx-2">
            <CardBody>
              <div className={`${style.titleCardBI} text-center`} style={{ color: "#8CC6C9" }}>
                <img className="mr-2" style={{ width: "30px" }} src={video} alt="video" />
                KONTEN VIDEO
              </div>
              <div className={`${style.valueSummary} my-3`}>
                <div className={style.CardDemografi}>
                  <Row>
                    <Col>Laki - Laki</Col>
                    <Col className={style.valueDemografiKelamin}>
                      {props.loading ? (
                        <div>
                          <Skeleton />
                        </div>
                      ) : props.data ? (
                        props.data.video ? (
                          props.data.video.gender ? (
                            props.data.video.gender.pria.toLocaleString() ?? 0
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                </div>
                <div className={`${style.CardDemografi} my-2`}>
                  <Row>
                    <Col>Perempuan</Col>
                    <Col className={style.valueDemografiKelamin}>
                      {props.loading ? (
                        <div>
                          <Skeleton />
                        </div>
                      ) : props.data ? (
                        props.data.video ? (
                          props.data.video.gender ? (
                            props.data.video.gender.wanita.toLocaleString() ?? 0
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Col>
          <Col
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
            className="mx-2">
            <CardBody>
              <div className={`${style.titleCardBI} text-center`} style={{ color: "#E2B674" }}>
                <img className="mr-2" style={{ width: "30px" }} src={banner} alt="banner" />
                KONTEN BANNER
              </div>
              <div className={`${style.valueSummary} my-3`}>
                <div className={style.CardDemografi}>
                  <Row>
                    <Col>Laki - Laki</Col>
                    <Col className={style.valueDemografiKelamin}>
                      {props.loading ? (
                        <div>
                          <Skeleton />
                        </div>
                      ) : props.data ? (
                        props.data.banner ? (
                          props.data.banner.gender ? (
                            props.data.banner.gender.pria.toLocaleString() ?? 0
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                </div>
                <div className={`${style.CardDemografi} my-2`}>
                  <Row>
                    <Col>Perempuan</Col>
                    <Col className={style.valueDemografiKelamin}>
                      {props.loading ? (
                        <div>
                          <Skeleton />
                        </div>
                      ) : props.data ? (
                        props.data.banner ? (
                          props.data.banner.gender ? (
                            props.data.banner.gender.wanita.toLocaleString() ?? 0
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Col>
          <Col
            style={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
            className="mx-2">
            <CardBody>
              <div className={`${style.titleCardBI} text-center`} style={{ color: "#C18ECE" }}>
                <img className="mr-2" style={{ width: "30px" }} src={berita} alt="berita" />
                KONTEN BERITA
              </div>
              <div className={`${style.valueSummary} my-3`}>
                <div className={style.CardDemografi}>
                  <Row>
                    <Col>Laki - Laki</Col>
                    <Col className={style.valueDemografiKelamin}>
                      {props.loading ? (
                        <div>
                          <Skeleton />
                        </div>
                      ) : props.data ? (
                        props.data.news ? (
                          props.data.news.gender ? (
                            props.data.news.gender.pria.toLocaleString() ?? 0
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                </div>
                <div className={`${style.CardDemografi} my-2`}>
                  <Row>
                    <Col>Perempuan</Col>
                    <Col className={style.valueDemografiKelamin}>
                      {props.loading ? (
                        <div>
                          <Skeleton />
                        </div>
                      ) : props.data ? (
                        props.data.news ? (
                          props.data.news.gender ? (
                            props.data.news.gender.wanita.toLocaleString() ?? 0
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Col>
        </Row>
      </div>
    </div>
  );
}
