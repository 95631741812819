import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLSVisitor = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);
  let title;
  switch (props.activeTab) {
    case "cp":
      title = `Survey Kepuasan Content Partner`;
      break;
    case "visitor":
      title = `Survey Kepuasan Visitor`;
      break;
    default:
      break;
  }

  return (
    <Table style={{ display: "none" }} id={props.id} className={props.className}>
      <thead>{`Data ${title} `}</thead>
      <thead>
        <tr>
          <th>No</th>
          <th>Nama</th>
          <th>Lokasi</th>
          <th>Tanggal</th>
          <th>Rating</th>
          <th>Komentar</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{data.name ? data.name : "-"}</td>
              <td>{data.location ?? "-"}</td>
              <td>{moment(data.tanggal_pembuatan).format("DD-MM-YYYY") ?? "-"}</td>
              <td>{data.rating ? data.rating : "-"}</td>
              <td>{data.comment ? data.comment : "-"}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DownloadXLSVisitor;
