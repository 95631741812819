export const listProvinsi = [
  {
    _id: "5c048b737fddb13f493b0cbc",
    name: "ACEH",
  },
  {
    _id: "5c04de111143c12c8bdbc9c4",
    name: "BALI",
  },
  {
    _id: "5c06068b2f1e2050e0c6b038",
    name: "BANTEN",
  },
  {
    _id: "5c0637062f1e2050e0c6b6e1",
    name: "BENGKULU",
  },
  {
    _id: "5c065e5a2f1e2050e0c6bd55",
    name: "DI YOGYAKARTA",
  },
  {
    _id: "5c073c0b2f1e2050e0c6bf64",
    name: "DKI JAKARTA",
  },
  {
    _id: "5c0748772f1e2050e0c6c0a9",
    name: "GORONTALO",
  },
  {
    _id: "5c075a6c2f1e2050e0c6c3d9",
    name: "JAMBI",
  },
  {
    _id: "5c077c892f1e2050e0c6c50f",
    name: "JAWA BARAT",
  },
  {
    _id: "5c079ff62f1e2050e0c6cd4c",
    name: "JAWA TENGAH",
  },
  {
    _id: "5c07d086aea8f971c78f62d6",
    name: "JAWA TIMUR",
  },
  {
    _id: "5c07d0af2f1e2050e0c6d814",
    name: "KALIMANTAN BARAT",
  },
  {
    _id: "5c0882342f1e2050e0c6d8d1",
    name: "KALIMANTAN SELATAN",
  },
  {
    _id: "5c0885d32f1e2050e0c6d978",
    name: "KALIMANTAN TENGAH",
  },
  {
    _id: "5c0888a62f1e2050e0c6da0f",
    name: "KALIMANTAN TIMUR",
  },
  {
    _id: "5c088ac22f1e2050e0c6da81",
    name: "KALIMANTAN UTARA",
  },
  {
    _id: "5c088d0d2f1e2050e0c6dabc",
    name: "KEPULAUAN BANGKA BELITUNG",
  },
  {
    _id: "5c088e5c2f1e2050e0c6daf3",
    name: "KEPULAUAN RIAU",
  },
  {
    _id: "5c088faa2f1e2050e0c6db41",
    name: "LAMPUNG",
  },
  {
    _id: "5c089dcd2f1e2050e0c6dc5f",
    name: "MALUKU",
  },
  {
    _id: "5c0897752f1e2050e0c6dc35",
    name: "MALUKU UTARA",
  },
  {
    _id: "5c04a81b21fb6a653aaab2a5",
    name: "NUSA TENGGARA BARAT",
  },
  {
    _id: "5c08a8b82f1e2050e0c6dce1",
    name: "NUSA TENGGARA TIMUR",
  },
  {
    _id: "5c08b0452f1e2050e0c6df14",
    name: "PAPUA",
  },
  {
    _id: "5c08ad1f2f1e2050e0c6de2c",
    name: "PAPUA BARAT",
  },
  {
    _id: "5c08c3c92f1e2050e0c6e162",
    name: "RIAU",
  },
  {
    _id: "5c08cd192f1e2050e0c6e2d9",
    name: "SULAWESI BARAT",
  },
  {
    _id: "5c08cf2f2f1e2050e0c6e325",
    name: "SULAWESI SELATAN",
  },
  {
    _id: "5c08c8322f1e2050e0c6e21b",
    name: "SULAWESI TENGAH",
  },
  {
    _id: "5c08d80e2f1e2050e0c6e471",
    name: "SULAWESI TENGGARA",
  },
  {
    _id: "5c08dcf72f1e2050e0c6e55e",
    name: "SULAWESI UTARA",
  },
  {
    _id: "5c08d5e6aea8f971c78f662c",
    name: "SUMATERA BARAT",
  },
  {
    _id: "5c08dd62aea8f971c78f66f4",
    name: "SUMATERA SELATAN",
  },
  {
    _id: "5c08e3e72f1e2050e0c6e619",
    name: "SUMATERA UTARA",
  },
];

export const listDurasi = ["1", "2", "3"];
export const listKategori = [
  "Kesehatan",
  "Pariwisata",
  "Edukasi",
  "Berita",
  "Keterampilan Non Teknis",
  "Sains & Teknologi",
  "Olahraga",
  "Komunitas",
  "Agribisnis",
  "Kewirausahaan",
];
export const listJam = [
  "00.00",
  "01.00",
  "02.00",
  "03.00",
  "04.00",
  "05.00",
  "06.00",
  "07.00",
  "08.00",
  "09.00",
  "10.00",
  "11.00",
  "12.00",
  "13.00",
  "14.00",
  "15.00",
  "16.00",
  "17.00",
  "18.00",
  "19.00",
  "20.00",
  "21.00",
  "22.00",
  "23.00",
];
