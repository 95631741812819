import React, { Component } from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import classNames from "classnames";
import './style.css'
import API from "../../../services";

class WeekPicker extends Component {
  constructor(props) {
    super(props);
    let currSelected = moment(props.selected,"DD-MM-YYYY");
    const currentMoment = currSelected !== ""? currSelected : moment(props.initialDate,"DD-MM-YYYY");
    this.state = {
      focused: false,
      selectedWorkWeek: currentMoment.isoWeek(),
      selectedYear: currentMoment.year(),
      hoveredDays: this.calculateActiveWeek(currentMoment),
      workWeekMarginLeft: 0,
      dateBlock: []
    };
    this.handleIsDayBlocked = this.handleIsDayBlocked.bind(this);
  }

  getBlock(prov){
    API.postBlockDateSurvey({province: prov})
    .then((res)=>(
      res.data.success&&
      this.setState({
        dateBlock: res.data.data
      })
    ))
    .catch(e => console.log(e))
  }
  componentDidMount(){
    if(Array.isArray(this.props.provSelected) && this.props.provSelected.length){
      let selected =  this.props.provSelected 
      // console.log("selected", selected)

      this.getBlock(selected)
    }
  }
  componentDidUpdate(prevProps){
    if((Array.isArray(this.props.provSelected) && this.props.provSelected) &&(prevProps.provSelected !== this.props.provSelected)){
      let selected =  this.props.provSelected 
      // console.log("if selected", selected)
      this.getBlock(selected)
    }
  }

  openPicker = () => {
    if (!this.state.focused) {
      this.setState({
        focused: true
      });
    }
  };

  isDayHighlighted = date => {
    const { hoveredDays } = this.state;
    let isHighlighted = false;
    hoveredDays.forEach(hoveredDay => {
      const isDayOfMonthMatch = hoveredDay.date() === date.date();
      const isMonthMatch = hoveredDay.month() === date.month();
      const isYearMatch = hoveredDay.year() === date.year();
      if (isDayOfMonthMatch && isMonthMatch && isYearMatch) {
        isHighlighted = true;
      }
    });
    return isHighlighted;
  };

  calculateActiveWeek = date => {
    const mon = date.clone().startOf("isoweek");
    const tue = mon.clone().add(1, "d");
    const wed = mon.clone().add(2, "d");
    const thu = mon.clone().add(3, "d");
    const fri = mon.clone().add(4, "d");
    const sat = mon.clone().add(5, "d");
    const sun = mon.clone().add(6, "d");
    return [sun, mon, tue, wed, thu, fri, sat];
  };

  onDateHovered = date => {
    this.setState({
      hoveredDays: this.calculateActiveWeek(date)
    });
  };

  onDateChange = date => {
    const selectedYear = date.year();
    const selectedWorkWeek = date.isoWeek();
    this.setState({
      selectedYear,
      selectedWorkWeek,
      focused: false
    });
  };

  renderCalendarDay = date => {
    const dayClasses = classNames(
      "CalendarDay",
      "CalendarDay__default",
      "CalendarDay_1",
      "CalendarDay__default_2"
    );
    let style = {
      width: "39px",
      height: "38px"
    };
    if (date.day) {
      const dayOfMonth = date.day.date();
      const isHighlighted = this.isDayHighlighted(date.day);
      const isBlocked = this.handleIsDayBlocked(date.day);
      let style = {
        width: "39px",
        height: "38px",
        backgroundColor: isBlocked? "#ddd" : isHighlighted ? "#42a5f5" : "white",
        color: isBlocked? "white" : isHighlighted ? "white" : "black",
        cursor: isBlocked? "not-allowed" : "pointer"
      };
      return (
        <td
          style={style}

          className={dayClasses}
          onClick={
            !isBlocked? 
            () => {
              this.props.setDate(date.day)
              this.props.setModal(!this.props.focusing)
            }
            : 
            () => {}
          }
          onMouseEnter={() => this.onDateHovered(date.day)}
        >
          {dayOfMonth}
        </td>
      );
    } else {
      return <td style={style} className={dayClasses} />;
    }
  };

  previousWeek = () => {
    const { selectedWorkWeek, selectedYear } = this.state;
    let newSelectedWorkWeek = selectedWorkWeek - 1;
    let newSelectedYear = selectedYear;
    if (newSelectedWorkWeek === 0) {
      newSelectedWorkWeek = 52;
      newSelectedYear = selectedYear - 1;
    }
    const date = moment()
      .year(newSelectedYear)
      .isoWeek(newSelectedWorkWeek);
    this.setState({
      selectedYear: newSelectedYear,
      selectedWorkWeek: newSelectedWorkWeek,
      hoveredDays: this.calculateActiveWeek(date)
    });
  };

  nextWeek = () => {
    const { selectedWorkWeek, selectedYear } = this.state;
    let newSelectedWorkWeek = selectedWorkWeek + 1;
    let newSelectedYear = selectedYear;
    if (newSelectedWorkWeek === 53) {
      newSelectedWorkWeek = 1;
      newSelectedYear = selectedYear + 1;
    }
    const date = moment()
      .year(newSelectedYear)
      .isoWeek(newSelectedWorkWeek);
    this.setState({
      selectedYear: newSelectedYear,
      selectedWorkWeek: newSelectedWorkWeek,
      hoveredDays: this.calculateActiveWeek(date)
    });
  };

  handleIsDayBlocked = day => {
    let blockDates = this.state.dateBlock
    return blockDates.some(date => date == moment(day).format("DD-MM-YYYY") )
  }

  render() {
    const { focused, selectedWorkWeek, selectedYear } = this.state;
    const { openPicker, renderCalendarDay } = this;
    const date = moment()
      .year(selectedYear)
      .isoWeek(selectedWorkWeek);
    return (
      <SingleDatePicker
       // noBorder
       focused={this.props.focusing} // PropTypes.bool
       date={date}
       onFocusChange={({ focused }) => {this.setState({ focused })}} // PropTypes.func.isRequired
       id="single_date_picker" // PropTypes.string.isRequired,
       numberOfMonths={1}
       hideKeyboardShortcutsPanel={true}
      //  isOutsideRange={() => true}
       renderCalendarDay={renderCalendarDay}
      />
    );
  }
}

export default WeekPicker;
