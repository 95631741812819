import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";

import { MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import { Link, useHistory, withRouter } from "react-router-dom";
import _ from "lodash";
// -------------------Service------------------------
import API from "../../services";
// -------------------ASSET------------------------
//css
import "./style.css";
import IconUpload from "../../assets/icon/IconUpload.svg";
// -------------------COMPONENT--------------------
import { optionsKategori } from "./const";
import { FilterKuratorCategory } from "./dropdownFilter";
import Header from "../../components/VerticalLayout/Header";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { inputValidator } from "../../components/Common/validator";
import Swal from "sweetalert2";

function TambahPenggunaAdmin(props) {
  const [role, setRole] = useState("register");
  //form
  const [successSubmitted, setSuccessSubmitted] = useState("");
  const [loading, setLoading] = useState(false);
  //get user device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const history = useHistory();

  ///handle change tab
  const [activeTab, setActiveTab] = useState("contentpartner");

  //state form
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [nomorTelepon, setNomorTelepon] = useState("");
  const [kataSandi, setKataSandi] = useState("");
  const [ulangiKataSandi, setUlangiKataSandi] = useState("");
  const [kategoriKurator, setKategoriKurator] = useState("");
  const [suratTugasDariInstansi, setSuratTugasDariInstansi] = useState(null);
  const [nameSuratTugasDariInstansi, setNameSuratTugasDariInstansi] = useState("");
  const [suratPernyataanPertanggungjawaban, setSuratPernyataanPertanggungjawaban] = useState(null);
  const [
    nameSuratPernyataanPertanggungjawaban,
    setNameSuratPernyataanPertanggungjawaban,
  ] = useState("");
  const [KTP, setKTP] = useState(null);
  const [nameKTP, setNameKTP] = useState("");
  const [swafotoKTP, setSwafotoKTP] = useState(null);
  const [nameSwafotoKTP, setNameSwafotoKTP] = useState("");
  const [organisasi, setOrganisasi] = useState("");

  const [errorUsernameAPI, setErrorUsernameAPI] = useState(true);
  const [errorEmailAPI, setErrorEmailAPI] = useState("");
  const [errorOrganisasiAPI, setErrorOrganisasiAPI] = useState(true);

  //error state
  const [errorSuratTugasDariInstansi, setErrorSuratTugasDariInstansi] = useState("");
  const [
    errorSuratPernyataanPertanggungjawaban,
    setErrorsuratPernyataanPertanggungjawaban,
  ] = useState("");
  const [errorKTP, setErrorKTP] = useState("");
  const [errorSwafotoKTP, setErrorSwafotoKTP] = useState("");
  const [errorPassNotMatch, setErrorPassNotMatch] = useState("");
  const [errorTel, setErrorTel] = useState("");

  //ref
  // Surat Tugas Dari Instansi
  const hiddenPDFSuratTugasDariInstansi = useRef(null);

  const handleChangeUploadPDFSuratTugasDariInstansi = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      setErrorSuratTugasDariInstansi("");
      setNameSuratTugasDariInstansi(e.target.files[0].name);
      if (fileUploaded.size / 1024 / 1024 <= 10) {
        setSuratTugasDariInstansi(fileUploaded);
        setErrorSuratTugasDariInstansi("");
      } else {
        setErrorSuratTugasDariInstansi("Ukuran file tidak boleh melebihi 10MB");
      }
    }
  };
  //Surat Pernyataan
  const hiddenPDFSuratPernyataanPertanggungjawaban = useRef(null);

  const handleChangeUploadPDFSuratPernyataanPertanggungjawaban = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      setErrorsuratPernyataanPertanggungjawaban("");
      setNameSuratPernyataanPertanggungjawaban(e.target.files[0].name);

      if (fileUploaded.size / 1024 / 1024 <= 10) {
        setSuratPernyataanPertanggungjawaban(fileUploaded);
        setErrorsuratPernyataanPertanggungjawaban("");
      } else {
        setErrorsuratPernyataanPertanggungjawaban("Ukuran file tidak boleh melebihi 10MB");
      }
    }
  };

  //KTP
  const hiddenPDFKTP = useRef(null);

  const handleChangeUploadPDFKTP = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      setErrorKTP("");
      setNameKTP(e.target.files[0].name);
      if (fileUploaded.size / 1024 / 1024 <= 10) {
        setKTP(fileUploaded);
        setErrorKTP("");
      } else {
        setErrorKTP("Ukuran file tidak boleh melebihi 10MB");
      }
    }
  };

  //Swafoto KTP
  const hiddenPDFSwafotoKTP = useRef(null);

  const handleChangeUploadPDFSwafotoKTP = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      setErrorSwafotoKTP("");
      setNameSwafotoKTP(e.target.files[0].name);
      if (fileUploaded.size / 1024 / 1024 <= 10) {
        setSwafotoKTP(fileUploaded);
        setErrorSwafotoKTP("");
      } else {
        setErrorSwafotoKTP("Ukuran file tidak boleh melebihi 10MB");
      }
    }
  };

  const handleChangeTab = (tab) => {
    // console.log(tab);
    setActiveTab(tab);
    tab !== "contentpartner" ? setRole(`create${tab}`) : setRole(`register`);
    handleClear();
  };

  const tabStyle = {
    color: "#c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };
  const handleClear = () => {
    //input
    setUsername("");
    setEmail("");
    setNamaLengkap("");
    setNomorTelepon("");
    setKataSandi("");
    setUlangiKataSandi("");
    setSuratTugasDariInstansi(null);
    setNameSuratTugasDariInstansi("");
    setSuratPernyataanPertanggungjawaban(null);
    setNameSuratPernyataanPertanggungjawaban("");
    setKTP(null);
    setNameKTP("");
    setSwafotoKTP(null);
    setNameSwafotoKTP("");
    //error
    setErrorSuratTugasDariInstansi("");
    setErrorsuratPernyataanPertanggungjawaban("");
    setErrorKTP("");
    setErrorSwafotoKTP("");
    setOrganisasi("");
    setKategoriKurator([]);
    setErrorEmailAPI("");
    setErrorOrganisasiAPI(true);
    setErrorUsernameAPI(true);
    setErrorTel("");
  };
  let param = {};
  let formdata = new FormData();
  const disabled = () => {
    let res = true;
    if (activeTab === "contentpartner" || activeTab === "kurator") {
      res =
        suratTugasDariInstansi === null ||
        suratPernyataanPertanggungjawaban === null ||
        KTP === null ||
        swafotoKTP === null;
    } else {
      res = true;
    }
    return (
      errorTel !== "" ||
      errorOrganisasiAPI !== true ||
      errorUsernameAPI !== true ||
      errorEmailAPI !== "" ||
      kataSandi !== ulangiKataSandi ||
      username === "" ||
      email === "" ||
      namaLengkap === "" ||
      nomorTelepon === "" ||
      kataSandi === "" ||
      res
    );
  };
  const handleSubmitPengguna = (e) => {
    if (activeTab == "support" || activeTab == "contentadmin") {
      param = {
        username: username,
        password: kataSandi,
        organization_name: organisasi,
        email: email,
        phone: nomorTelepon,
        nama: namaLengkap,
      };
    } else if (activeTab == "kurator" || activeTab == "contentpartner") {
      formdata.append("username", username);
      formdata.append("password", kataSandi);
      formdata.append("organization_name", organisasi);
      formdata.append("email", email);
      formdata.append("phone", nomorTelepon);
      formdata.append("file", suratTugasDariInstansi);
      formdata.append("fileSuratPernyataan", suratPernyataanPertanggungjawaban);
      formdata.append("fileKTP", KTP);
      formdata.append("fileSelfie", swafotoKTP);
      if (activeTab == "kurator") {
        formdata.append("nama", namaLengkap);
        formdata.append("kategori", JSON.stringify(kategoriKurator));
      }
      if (activeTab == "contentpartner") {
        formdata.append("name", namaLengkap);
        formdata.append("role", "Content Partner");
      }
    }
    setLoading(true);
    API.checkUser({
      username: username,
      email: email,
      organization: organisasi,
    })
      .then((value) => {
        let res = value.data.data;
        if (!res.username) {
          setErrorUsernameAPI(res.username);
        }
        if (!res.email) {
          setErrorEmailAPI(res.email);
        }
        if (!res.organization) {
          setErrorOrganisasiAPI(res.organization);
        }
        if (res.username && res.email && res.organization) {
          API.postCreateUser(
            role,
            activeTab == "support" || activeTab == "contentadmin" ? param : formdata
          )
            .then((res) => {
              if (res.data.success) {
                setLoading(false);
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `User ${username} berhasil dibuat`,
                  showConfirmButton: false,
                  timer: 2500,
                });
                history.push("/admin/pengguna");
              } else {
                setLoading(false);
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `User ${username} Tidak berhasil dibuat`,
                  showConfirmButton: false,
                  timer: 2500,
                });
              }
            })
            .catch((e) => {
              setLoading(false);
              Swal.fire({
                position: "center",
                icon: "error",
                title: `User ${username} Tidak berhasil dibuat`,
              });
            });
        } else {
          setLoading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: `User ${username} Tidak berhasil dibuat`,
            html:
              `${res.username === false ? `- Username ${username} Duplikat </br>` : ``}` +
              `${res.email === false ? `- Email ${email}  Duplikat </br> ` : ``} </br>` +
              `${
                res.organization === false ? `- Organisasi ${organisasi} Duplikat </br>` : ``
              } </br>`,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          position: "center",
          icon: "error",
          title: `Terjadi Kesalahan`,
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };

  const handleUlangiPassword = (e) => {
    setUlangiKataSandi(e.target.value);
    if (e.target.value !== kataSandi) {
      setErrorPassNotMatch("Kata Sandi Tidak Sama");
    } else {
      setErrorPassNotMatch("");
    }
  };

  const filterData = (data) => {
    setKategoriKurator(_.map(data, "value"));
  };

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          showConfirm={false}
          onConfirm={""}
          title="Mohon Tunggu"
          style={{
            padding: "3em",
            borderRadius: "20px",
          }}>
          <Spinner
            color="danger"
            style={{ width: "5rem", height: "5rem", marginBottom: "10px" }}
            type="grow"
          />
          <div>User {username} sedang dibuat</div>
        </SweetAlert>
      )}
      <div className="page-content">
        <header>
          <Header title="Tambah Pengguna" btn={true} btntype="buatkonten" btntext="Konten" />
        </header>
        <main>
          <div className="container-fluid">
            {/* <MDBContainer > */}
            <div className="classic-tabs">
              <Row style={{ overflowY: "auto" }}>
                <Col className="col-12">
                  <Row>
                    <Col className="col-lg-6 mx-auto" id="formKonten">
                      <Card className="mx-auto" style={{ boxShadow: "0px 0px 10px #D3D3D3" }}>
                        <div className="classic-tabs">
                          <Row style={{ padding: "10px 0" }}>
                            <Col lg="12" className="mx-auto my-auto">
                              <MDBNav classicTabs className="wrapperNav">
                                <div className="wrapperTabCreate mx-auto">
                                  <MDBNavItem>
                                    <MDBNavLink
                                      link
                                      to="#"
                                      style={activeTab === "contentpartner" ? tabStyle : {}}
                                      className="tabMenu waves-light"
                                      active={activeTab === "contentpartner"}
                                      onClick={() => {
                                        handleChangeTab("contentpartner");
                                        handleClear();
                                      }}>
                                      Content Partner
                                    </MDBNavLink>
                                    <hr
                                      style={
                                        activeTab === "contentpartner"
                                          ? customStyle
                                          : { display: "none" }
                                      }
                                    />
                                  </MDBNavItem>
                                  <MDBNavItem>
                                    <MDBNavLink
                                      link
                                      to="#"
                                      style={activeTab === "contentadmin" ? tabStyle : {}}
                                      className="tabMenu"
                                      active={activeTab === "contentadmin"}
                                      onClick={() => {
                                        handleChangeTab("contentadmin");
                                        handleClear();
                                      }}>
                                      Content Admin
                                    </MDBNavLink>
                                    <hr
                                      style={
                                        activeTab === "contentadmin"
                                          ? customStyle
                                          : { display: "none" }
                                      }
                                    />
                                  </MDBNavItem>
                                  <MDBNavItem>
                                    <MDBNavLink
                                      link
                                      to="#"
                                      style={activeTab === "kurator" ? tabStyle : {}}
                                      className="tabMenu"
                                      active={activeTab === "kurator"}
                                      onClick={() => {
                                        handleChangeTab("kurator");
                                        handleClear();
                                      }}>
                                      Kurator
                                    </MDBNavLink>
                                    <hr
                                      style={
                                        activeTab === "kurator" ? customStyle : { display: "none" }
                                      }
                                    />
                                  </MDBNavItem>
                                  <MDBNavItem>
                                    <MDBNavLink
                                      link
                                      to="#"
                                      style={activeTab === "support" ? tabStyle : {}}
                                      className="tabMenu"
                                      active={activeTab === "support"}
                                      onClick={() => {
                                        handleChangeTab("support");
                                        handleClear();
                                      }}>
                                      Support
                                    </MDBNavLink>
                                    <hr
                                      style={
                                        activeTab === "support" ? customStyle : { display: "none" }
                                      }
                                    />
                                  </MDBNavItem>
                                </div>
                              </MDBNav>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  {activeTab == "support" || activeTab == "contentadmin" ? (
                    <Row>
                      <Col className="col-lg-6 mx-auto">
                        <Row className="w-85 mx-auto">
                          <Form className="w-100">
                            <FormGroup>
                              <Label for="Username">Username</Label>
                              <Input
                                type="text"
                                name="text"
                                id="usernameField"
                                value={username}
                                onChange={(e) => {
                                  setErrorUsernameAPI(true);
                                  setUsername(e.target.value);
                                }}
                                className="inputWrapper"
                              />
                              {errorUsernameAPI ? (
                                <div></div>
                              ) : (
                                <div
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    marginBottom: "15px",
                                    marginLeft: "8px",
                                    fontSize: "10px",
                                  }}>
                                  Username telah terpakai
                                </div>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="Email">Email</Label>
                              <Input
                                type="email"
                                name="text"
                                id="emailField"
                                value={email}
                                onChange={(e) => {
                                  let validEmail = inputValidator("email", e.target.value);
                                  if (validEmail) {
                                    setErrorEmailAPI("");
                                  } else {
                                    setErrorEmailAPI("Format email tidak valid");
                                  }
                                  setEmail(e.target.value);
                                }}
                                className="inputWrapper"
                              />
                              {errorEmailAPI === "" ? (
                                <div></div>
                              ) : (
                                <div
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    marginBottom: "15px",
                                    marginLeft: "8px",
                                    fontSize: "10px",
                                  }}>
                                  {errorEmailAPI ? errorEmailAPI : "Email telah terpakai"}
                                </div>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="NamaLengkap">Nama Lengkap</Label>
                              <Input
                                type="text"
                                name="text"
                                id="NamaLengkap"
                                value={namaLengkap}
                                onChange={(e) => setNamaLengkap(e.target.value)}
                                className="inputWrapper"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="NomorTelepon">Nomor Telepon</Label>
                              <Input
                                type="text"
                                name="text"
                                id="NomorTelepon"
                                value={nomorTelepon}
                                onChange={(e) => {
                                  let validPhone = inputValidator("phone", e.target.value);
                                  if (validPhone) {
                                    setErrorTel("");
                                  } else {
                                    setErrorTel("Format Telpon(08xxxxxxxxx)");
                                  }
                                  setNomorTelepon(e.target.value);
                                }}
                                className={errorTel !== "" ? "inputWrapperError" : "inputWrapper"}
                              />
                            </FormGroup>
                            {errorTel.length > 0 && (
                              <div
                                style={{
                                  color: "red",
                                  textAlign: "left",
                                  marginBottom: "15px",
                                  marginLeft: "8px",
                                  fontSize: "10px",
                                }}>
                                {errorTel ? errorTel : "Format Telpon(08xxxxxxxxx)"}
                              </div>
                            )}
                            <FormGroup>
                              <Label for="organisasi">Nama Organisasi</Label>
                              <FormText className="ml-2 formTextRequire">
                                Nama Instansi yang Anda Wakili
                                (Kementerian/Lembaga/Organisasi/Komunitas)
                              </FormText>
                              <Input
                                type="text"
                                name="text"
                                id="organizationField"
                                value={organisasi}
                                onChange={(e) => {
                                  setErrorOrganisasiAPI(true);
                                  setOrganisasi(e.target.value);
                                }}
                                className="inputWrapper"
                              />
                              {errorOrganisasiAPI ? (
                                <div></div>
                              ) : (
                                <div
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    marginBottom: "15px",
                                    marginLeft: "8px",
                                    fontSize: "10px",
                                  }}>
                                  Nama Organisasi telah terpakai
                                </div>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <Label for="KataSandi">Kata Sandi</Label>
                              <Input
                                type="password"
                                name="password"
                                id="KataSandi"
                                value={kataSandi}
                                onChange={(e) => setKataSandi(e.target.value)}
                                className="inputWrapper"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="UlangiKataSandi">Ulangi Kata Sandi</Label>
                              <Input
                                type="password"
                                name="password"
                                id="UlangiKataSandi"
                                value={ulangiKataSandi}
                                onChange={handleUlangiPassword}
                                className={
                                  errorPassNotMatch !== "" ? "inputWrapperError" : "inputWrapper"
                                }
                              />
                            </FormGroup>
                            {errorPassNotMatch == "" ? (
                              <></>
                            ) : (
                              <FormText className="ml-2 formTextError" color="#c42127">
                                Kata sandi tidak sama
                              </FormText>
                            )}

                            <div className="wrapperBtnCreate">
                              <Button className="btnClear" onClick={handleClear} color="">
                                Clear
                              </Button>
                              <Button
                                disabled={
                                  kataSandi !== ulangiKataSandi ||
                                  username == "" ||
                                  email == "" ||
                                  namaLengkap == "" ||
                                  nomorTelepon == "" ||
                                  kataSandi == "" ||
                                  errorTel !== "" ||
                                  errorEmailAPI !== ""
                                }
                                className="btnAskSubmit btn btn-rounded"
                                onClick={handleSubmitPengguna}
                                color="">
                                <>Daftar</>
                                {/* {loading ? <>Loading...</> : <>Ajukan Konten</>} */}
                              </Button>
                            </div>
                            <div className="wrapperKonten"></div>
                          </Form>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col className="col-lg-6 mx-auto">
                        <Row className="w-85 mx-auto">
                          <Form className="w-100">
                            <FormGroup>
                              <Label for="Username">Username</Label>
                              <Input
                                type="text"
                                name="text"
                                id="usernameField"
                                value={username}
                                onChange={(e) => {
                                  setErrorUsernameAPI(true);
                                  setUsername(e.target.value);
                                }}
                                className="inputWrapper"
                              />
                              {errorUsernameAPI ? (
                                <div></div>
                              ) : (
                                <div
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    marginBottom: "15px",
                                    marginLeft: "8px",
                                    fontSize: "10px",
                                  }}>
                                  Username telah terpakai
                                </div>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="Email">Email</Label>
                              <Input
                                type="email"
                                name="text"
                                id="emailField"
                                value={email}
                                onChange={(e) => {
                                  let validEmail = inputValidator("email", e.target.value);
                                  if (validEmail) {
                                    setErrorEmailAPI("");
                                  } else {
                                    setErrorEmailAPI("Format email tidak valid");
                                  }
                                  setEmail(e.target.value);
                                }}
                                className="inputWrapper"
                              />
                              {errorEmailAPI === "" ? (
                                <div></div>
                              ) : (
                                <div
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    marginBottom: "15px",
                                    marginLeft: "8px",
                                    fontSize: "10px",
                                  }}>
                                  {errorEmailAPI ? errorEmailAPI : "Email telah terpakai"}
                                </div>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="NamaLengkap">Nama Lengkap</Label>
                              <Input
                                type="text"
                                name="text"
                                id="NamaLengkap"
                                value={namaLengkap}
                                onChange={(e) => setNamaLengkap(e.target.value)}
                                className="inputWrapper"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="NomorTelepon">Nomor Telepon</Label>
                              <Input
                                type="text"
                                name="text"
                                id="NomorTelepon"
                                value={nomorTelepon}
                                onChange={(e) => {
                                  let validPhone = inputValidator("phone", e.target.value);
                                  if (validPhone) {
                                    setErrorTel("");
                                  } else {
                                    setErrorTel("Format Telpon(08xxxxxxxxx)");
                                  }
                                  setNomorTelepon(e.target.value);
                                }}
                                className={errorTel !== "" ? "inputWrapperError" : "inputWrapper"}
                              />
                            </FormGroup>
                            {errorTel.length > 0 && (
                              <div
                                style={{
                                  color: "red",
                                  textAlign: "left",
                                  marginBottom: "15px",
                                  marginLeft: "8px",
                                  fontSize: "10px",
                                }}>
                                {errorTel ? errorTel : "Format Telpon(08xxxxxxxxx)"}
                              </div>
                            )}
                            <FormGroup>
                              <Label for="organisasi"> Nama Organisasi</Label>
                              <FormText className="ml-2 formTextRequire">
                                Nama Instansi yang Anda Wakili
                                (Kementerian/Lembaga/Organisasi/Komunitas)
                              </FormText>
                              <Input
                                type="text"
                                name="text"
                                id="organizationField"
                                value={organisasi}
                                onChange={(e) => {
                                  setErrorOrganisasiAPI(true);
                                  setOrganisasi(e.target.value);
                                }}
                                className="inputWrapper"
                              />
                              {errorOrganisasiAPI ? (
                                <div></div>
                              ) : (
                                <div
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    marginBottom: "15px",
                                    marginLeft: "8px",
                                    fontSize: "10px",
                                  }}>
                                  Nama Organisasi telah terpakai
                                </div>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="KataSandi">Kata Sandi</Label>
                              <Input
                                type="password"
                                name="password"
                                id="KataSandi"
                                value={kataSandi}
                                onChange={(e) => setKataSandi(e.target.value)}
                                className="inputWrapper"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="UlangiKataSandi">Ulangi Kata Sandi</Label>
                              <Input
                                type="password"
                                name="password"
                                id="UlangiKataSandi"
                                value={ulangiKataSandi}
                                onChange={(e) => handleUlangiPassword(e)}
                                className={
                                  errorPassNotMatch !== "" ? "inputWrapperError" : "inputWrapper"
                                }
                              />
                            </FormGroup>
                            {errorPassNotMatch == "" ? (
                              <></>
                            ) : (
                              <FormText className="ml-2 formTextError" color="#c42127">
                                Kata sandi tidak sama
                              </FormText>
                            )}

                            {activeTab == "kurator" ? (
                              <FormGroup>
                                <Label for="kategori">Pilih Kategori Kurator</Label>
                                <FilterKuratorCategory
                                  type={"Kategori"}
                                  filter={kategoriKurator}
                                  clicked={filterData}
                                  list={optionsKategori}
                                />
                                <FormText className="ml-2 formTextRequire">
                                  Maksimal 3 Kategori
                                </FormText>
                              </FormGroup>
                            ) : (
                              <></>
                            )}

                            <FormGroup>
                              <div className="w-100">
                                <Button
                                  onClick={() => hiddenPDFSuratTugasDariInstansi.current.click()}
                                  color=""
                                  className={
                                    isMobile
                                      ? "btnAsk btnPositionNews btn btn-rounded"
                                      : "btnAsk btnPositionNews btn btn-rounded"
                                  }>
                                  <img className="IconUpload" src={IconUpload}></img>
                                  {`${"    "}`}Upload
                                </Button>
                                <div className="w-100">
                                  <Label for="ThumbnailVideo">
                                    Unggah Surat Tugas Dari Instansi
                                  </Label>

                                  <Input
                                    disabled={true}
                                    placeholder={
                                      nameSuratTugasDariInstansi !== ""
                                        ? nameSuratTugasDariInstansi
                                        : ""
                                    }
                                    id="upload"
                                    className="inputWrapper inputPosition"
                                  />
                                  <input
                                    type="file"
                                    id="newlocalFILE"
                                    accept={".pdf"}
                                    ref={hiddenPDFSuratTugasDariInstansi}
                                    onChange={handleChangeUploadPDFSuratTugasDariInstansi}
                                    style={{ display: "none" }}
                                  />
                                </div>

                                {errorSuratTugasDariInstansi !== "" ? (
                                  <FormText className="formTextError" color="#c42127">
                                    {errorSuratTugasDariInstansi}
                                  </FormText>
                                ) : (
                                  <FormText className="ml-2 formTextRequired" color="#c42127">
                                    PDF. maksimal 10 MB
                                  </FormText>
                                )}
                              </div>
                            </FormGroup>
                            {/* UNGGAH SURAT PENYATAAN suratPernyataanPertanggungjawaban */}
                            <FormGroup>
                              <div className="w-100">
                                <Button
                                  onClick={() =>
                                    hiddenPDFSuratPernyataanPertanggungjawaban.current.click()
                                  }
                                  color=""
                                  className={
                                    isMobile
                                      ? "btnAsk btnPositionNews btn btn-rounded"
                                      : "btnAsk btnPositionNews btn btn-rounded"
                                  }>
                                  <img className="IconUpload" src={IconUpload}></img>
                                  {`${"    "}`}Upload
                                </Button>
                                <div className="w-100">
                                  <Label for="ThumbnailVideo">
                                    Unggah Surat Pernyataan Pertanggungjawaban
                                  </Label>

                                  <Input
                                    disabled={true}
                                    placeholder={
                                      nameSuratPernyataanPertanggungjawaban !== ""
                                        ? `${nameSuratPernyataanPertanggungjawaban}`
                                        : ""
                                    }
                                    id="upload"
                                    className="inputWrapper inputPosition"
                                  />
                                  <input
                                    type="file"
                                    id="newlocalFILE"
                                    accept={".pdf"}
                                    ref={hiddenPDFSuratPernyataanPertanggungjawaban}
                                    onChange={
                                      handleChangeUploadPDFSuratPernyataanPertanggungjawaban
                                    }
                                    style={{ display: "none" }}
                                  />
                                </div>

                                {errorSuratPernyataanPertanggungjawaban !== "" ? (
                                  <FormText className="formTextError" color="#c42127">
                                    {errorSuratPernyataanPertanggungjawaban}
                                  </FormText>
                                ) : (
                                  <FormText className="ml-2 formTextRequired" color="#c42127">
                                    PDF. maksimal 10 MB
                                  </FormText>
                                )}
                              </div>
                            </FormGroup>
                            {/* Unggah KTP */}
                            <FormGroup>
                              <div className="w-100">
                                <Button
                                  onClick={() => hiddenPDFKTP.current.click()}
                                  color=""
                                  className={
                                    isMobile
                                      ? "btnAsk btnPositionNews btn btn-rounded"
                                      : "btnAsk btnPositionNews btn btn-rounded"
                                  }>
                                  <img className="IconUpload" src={IconUpload}></img>
                                  {`${"    "}`}Upload
                                </Button>
                                <div className="w-100">
                                  <Label for="ThumbnailVideo">Unggah KTP</Label>

                                  <Input
                                    disabled={true}
                                    placeholder={nameKTP !== "" ? `${nameKTP}` : ""}
                                    id="upload"
                                    className="inputWrapper inputPosition"
                                  />
                                  <input
                                    type="file"
                                    id="newlocalFILE"
                                    accept={".jpg"}
                                    ref={hiddenPDFKTP}
                                    onChange={handleChangeUploadPDFKTP}
                                    style={{ display: "none" }}
                                  />
                                </div>

                                {errorKTP !== "" ? (
                                  <FormText className="formTextError" color="#c42127">
                                    {errorKTP}
                                  </FormText>
                                ) : (
                                  <FormText className="ml-2 formTextRequired" color="#c42127">
                                    JPG. maksimal 10 MB
                                  </FormText>
                                )}
                              </div>
                            </FormGroup>
                            {/* Unggah Swafoto KTP */}
                            <FormGroup>
                              <div className="w-100">
                                <Button
                                  onClick={() => hiddenPDFSwafotoKTP.current.click()}
                                  color=""
                                  className={
                                    isMobile
                                      ? "btnAsk btnPositionNews btn btn-rounded"
                                      : "btnAsk btnPositionNews btn btn-rounded"
                                  }>
                                  <img className="IconUpload" src={IconUpload}></img>
                                  {`${"    "}`}Upload
                                </Button>
                                <div className="w-100">
                                  <Label for="ThumbnailVideo">Swafoto KTP</Label>

                                  <Input
                                    disabled={true}
                                    placeholder={nameSwafotoKTP !== "" ? `${nameSwafotoKTP}` : ""}
                                    id="upload"
                                    className="inputWrapper inputPosition"
                                  />
                                  <input
                                    type="file"
                                    id="newlocalFILE"
                                    accept={".jpg"}
                                    ref={hiddenPDFSwafotoKTP}
                                    onChange={handleChangeUploadPDFSwafotoKTP}
                                    style={{ display: "none" }}
                                  />
                                </div>

                                {errorSwafotoKTP !== "" ? (
                                  <FormText className="formTextError" color="#c42127">
                                    {errorSwafotoKTP}
                                  </FormText>
                                ) : (
                                  <FormText className="ml-2 formTextRequired" color="#c42127">
                                    JPG. maksimal 10 MB
                                  </FormText>
                                )}
                              </div>
                            </FormGroup>
                            <div className="wrapperBtnCreate">
                              <Button className="btnClear" onClick={handleClear} color="">
                                Clear
                              </Button>
                              <Button
                                disabled={disabled()}
                                className="btnAskSubmit btn btn-rounded"
                                onClick={handleSubmitPengguna}
                                color="">
                                <>Daftar</>
                                {/* {loading ? <>Loading...</> : <>Ajukan Konten</>} */}
                              </Button>
                            </div>
                            <div className="wrapperKonten"></div>
                          </Form>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
            {/* </MDBContainer> */}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

export default TambahPenggunaAdmin;
