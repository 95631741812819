import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Input, Label, Spinner } from 'reactstrap';
import { useHistory } from "react-router-dom";

import styles from '../../Assets/css/register.module.css'
import API from "../../../services";
// import StepOneRegist from './StepOneRegist';
// import StepTwoRegist from './StepTwoRegist';
// import StepThreeRegist from './StepThreeRegist';


export default function FormRegister() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)

    //Section 1
    const [nama, setNama] = useState(window.localStorage.getItem('namaAkunRegist') === null ? '' : window.localStorage.getItem('namaAkunRegist'))
    const [email, setEmail] = useState(window.localStorage.getItem('emailRegist') === null ? '' : window.localStorage.getItem('emailRegist'))
    const [namaLengkap, setNamaLengkap] = useState(window.localStorage.getItem('namaLengkapRegist') === null ? '' : window.localStorage.getItem('namaLengkapRegist'))
    const [tel, setTel] = useState(window.localStorage.getItem('telRegist') === null ? '' : window.localStorage.getItem('telRegist'))
    const [password, setPassword] = useState(window.localStorage.getItem('pasRegist') === null ? '' : window.localStorage.getItem('pasRegist'))
    const [repassword, setRePassword] = useState(window.localStorage.getItem('rePasRegist') === null ? '' : window.localStorage.getItem('rePasRegist'))
    const [instansi, setInstansi] = useState(window.localStorage.getItem('instansiRegist') === null ? '' : window.localStorage.getItem('instansiRegist'))
    const [errorPassword, setErrorPassword] = useState(false)

    const wrongPassword = () =>{
        setErrorPassword(true)
    }

    const handleFormInputSection1 = (e) => {
        let { value, name } = e.target;
        const re = /^[0-9\b]+$/;
        if (name === 'namaAkun') {
            setNama(value)
        } else if (name === 'email'){
            setEmail(value)
        } else if (name === 'namaLengkap'){
            setNamaLengkap(value)
        } else if (name === 'nomorTelepon'){
            if(value === '' || re.test(value)){
                setTel(value)
            }
        } else if (name === 'kataSandi'){
            setPassword(value)
        } else if (name === 'ulangiKataSandi'){
            setRePassword(value)
        } else if (name === 'namaInstansi'){
            setInstansi(value)
        } 
    }

    //Section II
    const [suratTugas, setSuratTugas] = useState('')
    const [suratPernyataan, setSuratPernyataan] = useState('')
    const [ktp, setKTP] = useState('')
    const [fotoKTP, setFotoKTP] = useState('')

    const [error1, setError1] = useState(false)
    const [error2, setError2] = useState(false)
    const [error3, setError3] = useState(false)
    const [error4, setError4] = useState(false)

    const handleFormInputSection2 = (e) => {
        let { name, files } = e.target;
        var FileSize = files[0].size / 1024 / 1024;
        if (name === 'suratTugas'){
            if(FileSize <= 10){
                setError1(false)
                setSuratTugas(files[0])
            }else{
                setError1(true)
                setSuratTugas('null')
            }
        } else if (name === 'suratPernyataan'){
            if(FileSize <= 10){
                setError2(false)
                setSuratPernyataan(files[0])
            } else{
                setError2(true)
                setSuratPernyataan('null')
            }
        } else if (name === 'ktp'){
            if(FileSize <= 10){
                setError3(false)
                setKTP(files[0])
            } else{
                setError3(true)
                setKTP('null')
            }
        } else if (name === 'fotoKTP'){
            if(FileSize <= 10){
                setError4(false)
                setFotoKTP(files[0])
            } else{
                setError4(true)
                setFotoKTP('null')
            }
        }
    }

    //Section III
    const [tac, setTac] = useState(window.localStorage.getItem('tacCheck') ?? false)
    const [emptyInput, setEmptyInput] = useState(false)
    const [tacChecked, setTacChecked] = useState(false)

    const handleFormInputSection3 = (e) => {
        let { name, checked } = e.target;
        if (name === 'tac'){
            setTac(checked)
        }
    }

    const onButtonClick = () => {
        var bodyFormData = new FormData();

        bodyFormData.append('username', nama);
        bodyFormData.append('password', password);
        bodyFormData.append('email', email);
        bodyFormData.append('name', namaLengkap);
        bodyFormData.append('phone', tel);
        bodyFormData.append('role', 'Content Partner');
        bodyFormData.append('file', suratTugas);
        bodyFormData.append('fileSuratPernyataan', suratPernyataan);
        bodyFormData.append('fileKTP', ktp);
        bodyFormData.append('fileSelfie', fotoKTP);
        bodyFormData.append('organization_name', instansi);

        //section 1
        let name = window.localStorage.getItem('namaAkunRegist')
        let emaile = window.localStorage.getItem('emailRegist')
        let namaLeng = window.localStorage.getItem('namaLengkapRegist')
        let tele = window.localStorage.getItem('telRegist')
        let pasRegist = window.localStorage.getItem("pasRegist")
        let rePasRegist = window.localStorage.getItem("rePasRegist")
        let instansie = window.localStorage.getItem("instansiRegist")

        //section 2
        // let suratTugas = window.localStorage.getItem('suratTugasRegist')
        // let suratPernyataan = window.localStorage.getItem('suratPernyataanRegist')
        // let ktp = window.localStorage.getItem('ktpRegist')
        // let fotoKTP = window.localStorage.getItem('fotoKTPRegist')

        //section 3
        let tacAccept = JSON.parse(window.localStorage.getItem('tacCheck'))
        
        if(JSON.parse(tacAccept) === false){
            setTacChecked(true)
        }else if( name === '' || 
            emaile === '' || 
            namaLeng === '' ||
            tele === '' ||
            pasRegist === '' ||
            rePasRegist === '' ||
            instansie === '' ||
            typeof suratTugas !== 'object' ||
            typeof suratPernyataan !== 'object' ||
            typeof ktp !== 'object' ||
            typeof fotoKTP !== 'object'){
            setTacChecked(false)
            setEmptyInput(true)
        }else{
            setTacChecked(false)
            setEmptyInput(false)
            setIsLoading(true)
            
            API.postRegister(bodyFormData).then((res) => {
                if(res.data.success === true){
                    history.push("/homepages");
                }else{
                    history.push("/pages500");
                }
            }).catch((err) => {
                history.push("/pages500");
            });

            //section 1
            window.localStorage.setItem('namaAkunRegist', '')
            window.localStorage.setItem('emailRegist', '')
            window.localStorage.setItem('namaLengkapRegist', '')
            window.localStorage.setItem('telRegist', '')
            window.localStorage.setItem("pasRegist", '')
            window.localStorage.setItem("rePasRegist", '')
            window.localStorage.setItem("instansiRegist", '')

            //section 2
            // window.localStorage.setItem("suratTugasRegist", '')
            // window.localStorage.setItem("suratPernyataanRegist", '')
            // window.localStorage.setItem("ktpRegist", '')
            // window.localStorage.setItem("fotoKTPRegist", '')

            //section 3
            window.localStorage.setItem('tacCheck', 'false')
        }
    }

    useEffect(() => {
        //Section I
        window.localStorage.setItem("namaAkunRegist", nama)
        window.localStorage.setItem("emailRegist", email)
        window.localStorage.setItem("namaLengkapRegist", namaLengkap)
        window.localStorage.setItem("telRegist", tel)
        window.localStorage.setItem("pasRegist", password)
        window.localStorage.setItem("rePasRegist", repassword)
        window.localStorage.setItem("instansiRegist", instansi)

        //Section II
        // window.localStorage.setItem("suratTugasRegist", suratTugas)
        // window.localStorage.setItem("suratPernyataanRegist", suratPernyataan)
        // window.localStorage.setItem("ktpRegist", ktp)
        // window.localStorage.setItem("fotoKTPRegist", fotoKTP)

        //Section III
        window.localStorage.setItem("tacCheck", tac)

        //section 1
        let name = window.localStorage.getItem('namaAkunRegist')
        let emaile = window.localStorage.getItem('emailRegist')
        let namaLeng = window.localStorage.getItem('namaLengkapRegist')
        let tele = window.localStorage.getItem('telRegist')
        let pasRegist = window.localStorage.getItem("pasRegist")
        let rePasRegist = window.localStorage.getItem("rePasRegist")
        let instansie = window.localStorage.getItem("instansiRegist")

        let tacAccept = JSON.parse(window.localStorage.getItem('tacCheck'))
        
        if(JSON.parse(tacAccept) === true){
            setTacChecked(false)
        }
        if( name === '' || 
            emaile === '' || 
            namaLeng === '' ||
            tele === '' ||
            pasRegist === '' ||
            rePasRegist === '' ||
            instansie === '' ||
            typeof suratTugas !== 'object' ||
            typeof suratPernyataan !== 'object' ||
            typeof ktp !== 'object' ||
            typeof fotoKTP !== 'object'){
        }else{
            setEmptyInput(false)
        }

    }, [nama, email, namaLengkap, tel, password, repassword, instansi, suratTugas, suratPernyataan, ktp, fotoKTP, tac]);

    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep(step + 1)
        setErrorPassword(false)
    }
    const prevStep = () => {
        if (step > 0) {
            setStep(step - 1)
            setErrorPassword(false)
        }
    }

    let renderForm;
    switch (step) {
        case 1:
            return (
                <Form>
                    <div className={styles.titleForm}>Username</div>
                    <Input className={styles.inputRegister} name='namaAkun' type='text' value={nama} onChange={handleFormInputSection1}></Input>
                    
                    <div className={styles.titleForm}>Email</div>
                    <div className={styles.subTitleForm}>Pastikan email anda valid</div>
                    <Input className={styles.inputRegister} name='email' type='email' value={email} onChange={handleFormInputSection1}></Input>
                    
                    <div className={styles.titleForm}>Nama Lengkap</div>
                    <Input className={styles.inputRegister} name='namaLengkap' type='text' value={namaLengkap} onChange={handleFormInputSection1}></Input>
                    
                    <div className={styles.titleForm}>Nomor Telepon</div>
                    <div className={styles.subTitleForm}>(08xxxxxxxxxxx)</div>
                    <Input className={styles.inputRegister} name='nomorTelepon' type='text' value={tel} onChange={handleFormInputSection1}></Input>
                    
                    <div className={styles.titleForm}>Password</div>
                    <Input className={styles.inputRegister} name='kataSandi' type='password' value={password} onChange={handleFormInputSection1}></Input>
                    
                    <div className={styles.titleForm}>Ulangi Password</div>
                    <Input className={styles.inputRegister} name='ulangiKataSandi' type='password' value={repassword} onChange={handleFormInputSection1}></Input>
                    
                    <div className={styles.titleForm}>Nama Organisasi</div>
                    <div className={styles.subTitleForm}>Nama Instansi yang Anda Wakili (Kementerian/Lembaga/Organisasi/Komunitas)</div>
                    <Input className={styles.inputRegister} name='namaInstansi' type='text' value={instansi} onChange={handleFormInputSection1}></Input> 
                    <Row className="justify-content-center">
                        {/* <Col md="auto">
                            <Button className={styles.buttonKirimRegister} onClick={prevStep}><i className="fas fa-chevron-left"></i> Kembali</Button>
                        </Col> */}
                        <Col xs="12" md="12">
                            <Button className={styles.buttonKirimRegister} onClick={ password === repassword ? nextStep : wrongPassword }>Selanjutnya <i className="fas fa-chevron-right"></i> </Button>
                        </Col>
                        {
                            errorPassword 
                            ? <div style={{color:'red', textAlign:'center', paddingTop:'10px', marginBottom:"90px"}}>Password dan Ulangi Password tidak sesuai</div>
                            : <div></div>
                        }
                    </Row>
                </Form>
            )
        case 2:
            return (
                <Form>
                    <div className={styles.titleForm}>Unggah Surat Tugas Dari Instansi</div>
                    <div className={styles.inputRegisterFile}>
                        <label style={{margin:'0px'}}>
                            <Input className={styles.inputRegister} name='suratTugas' type='file' accept="application/pdf" onChange={handleFormInputSection2} style={{display: 'none'}}></Input>
                            <a className={styles.inputRegChoose}>Pilih Berkas</a> 
                        </label>
                        {
                            error1 
                                ? <label style={{margin:'0px', paddingLeft:'10px', color:'red', paddingRight:'10px'}}>Maksimal File 10 MB</label>
                                : typeof suratTugas.name === 'undefined' || suratTugas === 'null'
                                    ? <label style={{margin:'0px', paddingLeft:'10px', color:'#9F9F9F', paddingRight:'10px'}}>Tidak ada berkas</label> 
                                    : <label style={{margin:'0px', paddingLeft:'10px', paddingRight:'10px'}}>{suratTugas.name}</label>
                        }
                    </div>
                    <div className={styles.subTitleFormFile}>PDF / Maks 10 MB</div>
        
                    <div className={styles.titleForm}>Unggah Surat Pernyataan Pertanggung Jawaban</div>
                    <div className={styles.inputRegisterFile}>
                    <label style={{margin:'0px'}}>
                        <Input className={styles.inputRegister} name='suratPernyataan' type='file' accept="application/pdf" onChange={handleFormInputSection2} style={{display: 'none'}}></Input>
                        <a className={styles.inputRegChoose}>Pilih Berkas</a> 
                        </label>
                        {
                            error2
                                ? <label style={{margin:'0px', paddingLeft:'10px', color:'red', paddingRight:'10px'}}>Maksimal File 10 MB</label>
                                : typeof suratPernyataan.name === 'undefined' || suratPernyataan === 'null'
                                    ? <label style={{margin:'0px', paddingLeft:'10px', color:'#9F9F9F', paddingRight:'10px'}}>Tidak ada berkas</label> 
                                    : <label style={{margin:'0px', paddingLeft:'10px', paddingRight:'10px'}}>{suratPernyataan.name}</label>
                        }
                    </div>
                    <div className={styles.subTitleFormFile}>PDF / Maks 10 MB</div>
        
                    <div className={styles.titleForm}>Unggah KTP</div>
                    <div className={styles.inputRegisterFile}>
                    <label style={{margin:'0px'}}>
                        <Input className={styles.inputRegister} name='ktp' type='file' accept=".png, .jpg, .jpeg" onChange={handleFormInputSection2} style={{display: 'none'}}></Input>
                        <a className={styles.inputRegChoose}>Pilih Berkas</a> 
                        </label>
                        {
                            error3
                                ? <label style={{margin:'0px', paddingLeft:'10px', color:'red', paddingRight:'10px'}}>Maksimal File 10 MB</label>
                                : typeof ktp.name === 'undefined' || ktp === 'null'
                                    ? <label style={{margin:'0px', paddingLeft:'10px', color:'#9F9F9F', paddingRight:'10px'}}>Tidak ada berkas</label> 
                                    : <label style={{margin:'0px', paddingLeft:'10px', paddingRight:'10px'}}>{ktp.name}</label>
                        }
                    </div>
                    <div className={styles.subTitleFormFile}>JPG / Maks 10 MB</div>
        
                    <div className={styles.titleForm}>Swafoto KTP</div>
                    <div className={styles.inputRegisterFile}>
                    <label style={{margin:'0px'}}>
                        <Input className={styles.inputRegister} name='fotoKTP' type='file' accept=".png, .jpg, .jpeg" onChange={handleFormInputSection2} style={{display: 'none'}}></Input>
                        <a className={styles.inputRegChoose}>Pilih Berkas</a> 
                        </label>
                        {
                            error4
                                ? <label style={{margin:'0px', paddingLeft:'10px', color:'red', paddingRight:'10px'}}>Maksimal File 10 MB</label>
                                : typeof fotoKTP.name === 'undefined' || fotoKTP === 'null'
                                    ? <label style={{margin:'0px', paddingLeft:'10px', color:'#9F9F9F', paddingRight:'10px'}}>Tidak ada berkas</label> 
                                    : <label style={{margin:'0px', paddingLeft:'10px', paddingRight:'10px'}}>{fotoKTP.name}</label>
                        }
                    </div>
                    <div className={styles.subTitleFormFile}>JPG / Maks 10 MB</div>
                        
                    <Row className="justify-content-center">
                        <Col xs="12" md="6">
                            <Button className={styles.buttonKirimRegister} onClick={prevStep}><i className="fas fa-chevron-left"></i> Kembali</Button>
                        </Col>
                        <Col xs="12" md="6">
                            <Button className={styles.buttonKirimRegister} onClick={nextStep}>Selanjutnya <i className="fas fa-chevron-right"></i> </Button>
                        </Col>
                    </Row>
                </Form>
            )
        case 3:
            return (
                <Form>
                    <div className={styles.titleForm} style={{textAlign:'center'}}>Syarat & Ketentuan</div>
                    <div className={styles.termAndCondition}>
                        <div className={styles.tacTitle}>Syarat - Syarat</div>
                        <div className={styles.tacSubTitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae risus eleifend, tincidunt mi in, imperdiet erat. Quisque finibus velit erat, eget aliquam tellus congue vel. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec vitae ipsum dignissim, iaculis libero vitae, lobortis nisi.</div>
                        <div className={styles.tacTitle}>Ketentuan 1</div>
                        <div className={styles.tacSubTitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae risus eleifend, tincidunt mi in, imperdiet erat. Quisque finibus velit erat, eget aliquam tellus congue vel. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec vitae ipsum dignissim, iaculis libero vitae, lobortis nisi.</div>
                        <div className={styles.tacTitle}>Ketentuan 2</div>
                        <div className={styles.tacSubTitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae risus eleifend, tincidunt mi in, imperdiet erat. Quisque finibus velit erat, eget aliquam tellus congue vel. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec vitae ipsum dignissim, iaculis libero vitae, lobortis nisi.</div>
                        <div className={styles.tacTitle}>Ketentuan 3</div>
                        <div className={styles.tacSubTitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae risus eleifend, tincidunt mi in, imperdiet erat. Quisque finibus velit erat, eget aliquam tellus congue vel. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec vitae ipsum dignissim, iaculis libero vitae, lobortis nisi.</div>
                        <div className={styles.tacTitle}>Ketentuan 4</div>
                        <div className={styles.tacSubTitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae risus eleifend, tincidunt mi in, imperdiet erat. Quisque finibus velit erat, eget aliquam tellus congue vel. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec vitae ipsum dignissim, iaculis libero vitae, lobortis nisi.</div>
                    </div>
                    <Label check style={{width:'100%', display:'flex', justifyContent:'flex-start', textAlign:'center', marginBottom:'20px'}}>
                        <Input style={{margin:'2.5px', outline:'white'}} name="tac" type="checkbox" onChange={handleFormInputSection3} checked={JSON.parse(tac)}/>
                        <a className={styles.setujuTAC}>Saya setuju dengan syarat dan ketentuan</a>
                    </Label>
                    {
                        isLoading
                        ?   <Row className="justify-content-center">
                                <Col>
                                    <Button className={styles.buttonKirimRegister} onClick={onButtonClick}><Spinner></Spinner></Button>
                                </Col>
                            </Row>
                        :   <Row className="justify-content-center">
                                <Col xs="12" md="6">
                                    <Button className={styles.buttonKirimRegister} onClick={prevStep}><i className="fas fa-chevron-left"></i> Kembali</Button>
                                </Col>
                                <Col xs="12" md="6">
                                    <Button className={styles.buttonKirimRegister} onClick={onButtonClick}>Kirim <i className="fas fa-chevron-right"></i> </Button>
                                </Col>
                                {
                                    tacChecked 
                                    ? <div style={{color:'red', textAlign:'center', paddingTop:'10px', marginBottom:"0px"}}>Mohon untuk menyetujui syarat dan ketentuan</div>
                                    : emptyInput 
                                        ? <div style={{color:'red', textAlign:'center', paddingTop:'10px', marginBottom:"0px"}}>Mohon untuk melengkapi form terlebih dahulu</div>
                                        : <div style={{paddingBottom: "0px"}}></div>
                                }
                            </Row>
                    }
                </Form>
            )
        default:
            break;
    }
    return (
        <div>
            {renderForm}
        </div>
    )
}