import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";

const PreviewContent = ({
  ModalPreviewContentActivated,
  toggleModalPreviewContentActivated,
  dataModalPreviewContentActivated,
  activeTab,
}) => {
  const source = dataModalPreviewContentActivated
    ? dataModalPreviewContentActivated.type === "youtube"
      ? `//www.youtube.com/embed/${
          typeof dataModalPreviewContentActivated.url === "undefined"
            ? ""
            : dataModalPreviewContentActivated.url
        }`
      : `${process.env.REACT_APP_SELF_URL}/${
          typeof dataModalPreviewContentActivated.url === "undefined"
            ? ""
            : dataModalPreviewContentActivated.url
        }`
    : "";

  return (
    <MDBContainer>
      <MDBModal
        size="lg"
        isOpen={ModalPreviewContentActivated}
        toggle={toggleModalPreviewContentActivated}
        centered={true}>
        <MDBModalHeader centered toggle={toggleModalPreviewContentActivated}>
          {dataModalPreviewContentActivated.title}
        </MDBModalHeader>
        <MDBModalBody>
          {activeTab == "video" ? (
            <div className="player-wrapper">
              <div className="wrapperVideo">
                <iframe
                  className="video"
                  title={dataModalPreviewContentActivated.title ?? "-"}
                  width="100%"
                  height="500"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                  src={source}></iframe>
              </div>
              {/* <h4>{source}</h4> */}
            </div>
          ) : (
            <div className="image-wrapper">
              <img
                onerror="this.onerror=null;this.src='http://example.com/existent-image.jpg';"
                alt={dataModalPreviewContentActivated.title ?? "-"}
                src={`${process.env.REACT_APP_SELF_URL}/${dataModalPreviewContentActivated.url}`}
                className="banner"></img>
              {/* <h4>asdasda</h4> */}
            </div>
          )}
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default PreviewContent;
