import React from "react";
import { MDBModal, MDBCardBody, MDBCardTitle, MDBCard } from "mdbreact";

// import style from "./style.module.css";

const helperPopup = (props) => {
  return (
    <MDBModal isOpen={props.modal} toggle={props.toggle} size="lg" centered={true}>
      <MDBCard className="p-2">
        <MDBCardTitle>
          <div className="text-center p-3">PANDUAN PENGISIAN FILTER PERSEBARAN PENGUNJUNG</div>
        </MDBCardTitle>
        <MDBCardBody>
          <div style={{ minHeight: "300px" }}>
            <div className="mx-3" style={{ fontWeight: "bold" }}>
              Filter Data Berdasarkan:
            </div>
            <ol>
              <li>Kunjungan</li>
              <li>Device Unik</li>
              {/* <li>DFP</li> */}
            </ol>

            <div className="mx-3" style={{ fontWeight: "bold" }}>
              Filter Tanggal Berdasarkan:
            </div>
            <ol>
              <li>
                Daily <p>Menampilkan Data Kunjungan Dalam 1 Hari</p>
              </li>
              <li>
                Weekly<p>Menampilkan Data Kunjungan Dalam 1 Minggu Dimulai Dari Hari Senin</p>
              </li>
              <li>
                Monthly
                <p>
                  Menampilkan Data Kunjungan Dalam 1 Bulan Dimulai Dari Tanggal 1 sampai Tanggal Terakhir
                  Dibulan Tersebut
                </p>
              </li>
              <li>
                Akumulasi
                <p>
                  Menampilkan Data Kunjungan Dalam 1 Tahun Dimulai Dari Tanggal 1 Bulan 1 sampai Tanggal
                  Terakhir Dibulan Yang Dipilih
                </p>
              </li>
            </ol>
          </div>
        </MDBCardBody>
      </MDBCard>
    </MDBModal>
  );
};

export default helperPopup;
