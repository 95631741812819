import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLSUB" className="DownloadTableXLS">
      <thead>Data Rata - Rata User Behavior Tanggal {props.date}</thead>
      <thead>
        <tr>
          <th>Rata - Rata Durasi di Landing Page</th>
          <th>Rata - Rata Durasi Isi Survey</th>
          <th>Rata - Rata Durasi di LP Konten</th>
          <th>Rata - Rata Durasi Mengisi Rating</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>
                  {data
                    ? data.time_behaviour
                      ? Number.isInteger(data.time_behaviour.session_time)
                        ? data.time_behaviour.session_time
                        : `${data.time_behaviour.session_time.toFixed(2)} Detik` ?? "-"
                      : "-"
                    : "-"}
                </td>
                <td>
                  {data
                    ? data.time_behaviour
                      ? Number.isInteger(data.time_behaviour.survey_time)
                        ? data.time_behaviour.survey_time
                        : `${data.time_behaviour.survey_time.toFixed(2)} Detik` ?? "-"
                      : "-"
                    : "-"}
                </td>
                <td>
                  {data
                    ? data.time_behaviour
                      ? Number.isInteger(data.time_behaviour.landing_page_time)
                        ? data.time_behaviour.landing_page_time
                        : `${data.time_behaviour.landing_page_time.toFixed(2)} Detik` ?? "-"
                      : "-"
                    : "-"}
                </td>
                <td>
                  {data
                    ? data.time_behaviour
                      ? Number.isInteger(data.time_behaviour.rating_time)
                        ? data.time_behaviour.rating_time
                        : `${data.time_behaviour.rating_time.toFixed(2)} Detik` ?? "-"
                      : "-"
                    : "-"}
                </td>
              </tr>
            </>
          ) : (
            <>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </>
          )
        ) : (
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </>
        )}
      </tbody>
      <thead>
        <tr>
          <th>Jenis Konten</th>
          <th>Perbandingan Klik Terbanyak Berdasarkan Konten</th>
          <th>Perbandingan Klik Popup Terbanyak Berdasarkan Konten</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr>
                <td>Video</td>
                <td>{data ? (data.card ? data.card.Video ?? "-" : "-") : "-"}</td>
                <td>{data ? (data.popup ? data.popup.Video ?? "-" : "-") : "-"}</td>
              </tr>
              <tr>
                <td>Banner</td>
                <td>{data ? (data.card ? data.card.Banner ?? "-" : "-") : "-"}</td>
                <td>{data ? (data.popup ? data.popup.Banner ?? "-" : "-") : "-"}</td>
              </tr>
              <tr>
                <td>Berita</td>
                <td>{data ? (data.card ? data.card.News ?? "-" : "-") : "-"}</td>
                <td>{data ? (data.popup ? data.popup.News ?? "-" : "-") : "-"}</td>
              </tr>
            </>
          ) : (
            <>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </>
          )
        ) : (
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
