import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDF = (dataTable, dataTable2, activeTab, activeSubTab2, analisis) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title;
  if (activeTab == "1") {
    title = "Konten Partner";
  } else {
    switch (activeSubTab2) {
      case "1":
        title = `Konten Video ${role}`;
        break;
      case "2":
        title = `Konten Banner ${role}`;
        break;
      case "3":
        title = `Konten Berita ${role}`;
        break;

      default:
        break;
    }
  }
  let headers;
  let data;
  if (activeTab == "1" || activeTab == "2") {
    headers = [
      [
        "No",
        "Judul",
        "Kategori",
        "Durasi",
        "Provinsi",
        "Tanggal Tayang",
        "Tanggal Pengajuan",
        "Status",
        "Preview",
      ],
    ];
    data = dataTable2.map((data, index) => {
      let statusKurator = data.status_kurator[data.status_kurator.length - 1].status;
      let status = data.status;
      let emergency = data.emergency;
      const statusSearched =
        status === "submitted"
          ? emergency
            ? "emergency"
            : statusKurator === "Lolos Kurasi"
            ? "Lolos Kurasi"
            : statusKurator === "Tidak Lolos Kurasi"
            ? "Tidak Lolos Kurasi"
            : statusKurator === "Revisi"
            ? "Revisi"
            : statusKurator === "Sudah Revisi"
            ? "Sudah Revisi"
            : statusKurator
          : status === "declined"
          ? "Ditolak"
          : status === "approved"
          ? "Disetujui"
          : status === "scheduled"
          ? "Tayang"
          : data.status == "declined"
          ? "ditolak"
          : data.status == "Terjadwal"
          ? "Terjadwal"
          : data.status == "submitted"
          ? "Baru"
          : data.status == "canceled"
          ? "Dibatalkan"
          : "";
      return [
        index + 1,
        data.title ? data.title ?? "-" : data.details.title ?? "-",
        data.category ?? "-",
        data.proposed_duration ?? "-",
        data.list_provinsi.length > 0
          ? data.list_provinsi.map((prov) => prov.name).toString()
          : "-",
        data.proposed_date.length > 0
          ? `${moment(data.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")}\n s/d \n${moment(
              data.proposed_date.slice(-1),
              "DD-MM-YYYY"
            ).format("DD-MM-YYYY")} `
          : "-",
        data.tanggal_pembuatan
          ? `${moment(data.tanggal_pembuatan,"DD-MM-YYYY").format("DD-MM-YYYY")}`
          : "-",
        statusSearched? statusSearched : "-",
        data.category ?? "-",
      ];
    });
  }
  if (analisis ?? false) {
    headers = [
      [
        "No",
        "Nama",
        "Organisasi",
        "Tanggal Registrasi",
        "Jumlah Konten",
        "Total Video",
        "Total Banner",
        "Total Berita",
        "Terakhir Upload Konten",
      ],
    ];
    data = dataTable.map((data, index) => {
      return [
        index + 1,
        data.name,
        data.organization,
        moment(data.register_date).format("DD-MM-YYYY"),
        Object.values(data.content_count).length !== 0
          ? data.content_count.news + data.content_count.banner + data.content_count.video ?? "-"
          : "-",
        Object.values(data.content_count).length !== 0 ? data.content_count.video ?? "-" : "-",
        Object.values(data.content_count).length !== 0 ? data.content_count.banner ?? "-" : "-",
        Object.values(data.content_count).length !== 0 ? data.content_count.news ?? "-" : "-",
        data.last_content_create !== "-"
          ? moment(data.last_content_create).format("DD-MM-YYYY")
          : "-",
      ];
    });
  }
  let a = 0;
  let content = {
    startY: 50,
    head: headers,
    body: data,
    didDrawCell: async function (dataCell) {
      if (activeTab === "2") {
        if (dataCell.column.index === headers[0].length - 1 && dataCell.cell.section === "body") {
          var diw = dataCell.cell.width - dataCell.cell.padding("horizontal");
          var dih = dataCell.cell.height - dataCell.cell.padding("vertical");
          if (dataCell.row.index < dataTable2.length) {
            if (dataTable2[dataCell.row.index]) {
              var img = new Image();
              img.src =
                activeSubTab2 === "1"
                  ? `${process.env.REACT_APP_SELF_URL}${
                      dataTable2[dataCell.row.index].details.thumbnail
                    }`
                  : typeof dataTable2[dataCell.row.index].details === "object"
                  ? `${process.env.REACT_APP_SELF_URL}/${
                      dataTable2[dataCell.row.index].details.url
                    }`
                  : `${process.env.REACT_APP_SELF_URL}/${dataTable2[dataCell.row.index].details}`;
              var poll = setInterval(function () {
                if (img.naturalWidth) {
                  clearInterval(poll);
                }
              }, 10);
              doc.addImage(
                dataTable2[dataCell.row.index]
                  ? img
                  : "https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg",
                dataCell.cell.x,
                dataCell.cell.y + 5,
                dih > diw ? diw : 0 / dih,
                dih > diw ? 0 / diw : dih
              );
            }
            a = a + 1;
          }
        }
      }
    },
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`Content_Report_Library_${role}.pdf`);
};
