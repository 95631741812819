import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
// -------------------ASSET------------------------
//css
import "./style.css";
// -------------------COMPONENT--------------------
import Header from "../../../components/VerticalLayout/Header";

import { exportPDF } from "../../../components/SuperAdmin/Download/PersetujuanKonten/PDF/DownloadPDF";
// -------------------REDUX--------------------
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// SIDE MENU
import SideMenuBI from "../../../components/SideMenuBI";
import PersebaranPengunjung from "./Measures/PersebaranPengunjung";
import DemografiPengunjung from "./Measures/DemografiPengunjung";
import TrafficKonten from "./Measures/TrafficKonten";
import ISP from "./Measures/ISP";
import DeviceFingerprint from "./Measures/DeviceFingerprint";
import Survey from "./Measures/Survey";
import UserBehavior from "./Measures/UserBehavior";
import VisitorJourney from "./Measures/VisitorJourney";

import { setDataLogHistory } from "../../../store/data/actions";

const MainSectionViewer = ({ item }) => {
  return (
    <React.Fragment>
      {
        {
          "Persebaran Pengunjung": <PersebaranPengunjung />,
          "Demografi Pengunjung": <DemografiPengunjung />,
          "Trafik Konten": <TrafficKonten />,
          ISP: <ISP />,
          "Device Fingerprint": <DeviceFingerprint />,
          Survey: <Survey />,
          "User Behavior": <UserBehavior />,
          "Visitor Journey": <VisitorJourney />,
        }[item]
      }
    </React.Fragment>
  );
};

function PersetujuanKontenSuperAdmin(props) {
  let role = window.localStorage.getItem("role");
  //handle data xls
  const [data, setData] = useState([]);
  const [idContent, setIdContent] = useState("");
  const [categoryContent, setCategoryContent] = useState("0");

  //handle data when change tab
  const handleDataVideo = (vid) => {
    setData(vid);
  };

  const handleDataBanner = (banner) => {
    setData(banner);
  };

  const handleDataBerita = (berita) => {
    setData(berita);
  };

  //handle change tab
  const [activeTab, setActiveTab] = useState("1");
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  //handle download pdf
  const handleDownloadPDF = () => {
    exportPDF(data, activeTab);
  };

  const tabStyle = {
    color: "#c42127",
  };
  const customStyle = {
    borderBottom: "3px solid #c42127",
    width: "40%",
    display: "block",
    borderRadius: "5px 5px 0 0",
  };
  useEffect(() => {
    let notifContentType = props.dataNotif.data;
    if (notifContentType !== "empty") {
      switch (notifContentType.data.content_type) {
        case "video":
          handleChangeTab("1");
          break;
        case "banner":
          handleChangeTab("2");
          break;
        case "news":
          handleChangeTab("3");
          break;
        default:
          handleChangeTab("1");
          break;
      }
      setIdContent(notifContentType.data.content._id);
      setCategoryContent(notifContentType.data.content.category);
    }
  }, [props.dataNotif.data]);

  const [menu, setMenu] = React.useState("");
  const changeMainSectionView = (item) => setMenu(item);

  return (
    <React.Fragment>
      <div className="page-content">
        <header>
          <Header title="Business Intelligence" className="SuperAdminHeader" />
        </header>
        <main>
          <div className="container-fluid">
            <Row>
              <Col md="2">
                <SideMenuBI active={menu} action={changeMainSectionView} />
              </Col>
              <Col md="10">
                <MainSectionViewer item={menu} />
              </Col>
            </Row>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { dataNotif } = state.dataReducer;
  return { dataNotif };
};
export default connect(mapStateToProps, null)(withRouter(PersetujuanKontenSuperAdmin));
