import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Filter from "../../../../components/Filter/filter";
import _ from "lodash";
// ------------------API-----------------------
import API from "../../../../services";
// -------------------ASSET--------------------
//css
import "./style.scss";
//icon
import IconGreenCheckList from "../../../../assets/icon/IconGreenChecklist.svg";
import IconEmergency from "../../../../assets/icon/IconEmergency.svg";

// ------------------Redux----------------
import { connect } from "react-redux";
import { setTabDetail, detailData } from "../../../../store/actions";
import noImage from "../../../../assets/images/noImage.jpg";
// ------------------Component----------------
import ModalPreviewContent from "../../Popup/Konten/PreviewContent";
import ModalPreviewEmergencyDoc from "../../Popup/Konten/PreviewEmergencyDoc";
import FilterCalendar from "../../../Filter/filterCalendar";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
const TableKontenSupport = (props) => {
  let history = useHistory();
  const searchRef = useRef();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  //notif
  const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [notif, setNotif] = useState(false);
  //alert
  const [alert, setAlert] = useState("");
  //calendar
  const [sDate, setStartDate] = useState("");
  const [eDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [modalCalendar, setModalCalendar] = useState(false);
  const [tanggal, setTanggal] = useState("");
  const toggleModalCalendar = () => {
    setModalCalendar(!modalCalendar);
  };
  let enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const handleSelectedDate = (date) => {
    let startDate = moment(date[0].startDate);
    let endDate = moment(date[0].endDate);
    //throw data to check availability
    setStartDate(startDate.format("DD-MM-YYYY"));
    setEndDate(endDate.format("DD-MM-YYYY"));
    //data for
    setSelectedDate(`${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}`);
    setTanggal(enumerateDaysBetweenDates(startDate, endDate));
  };
  let typeContent = "";
  switch (props.activeTab) {
    case "1":
      typeContent = "video";
      break;
    case "2":
      typeContent = "banner";
      break;
    case "3":
      typeContent = "news";
      break;
    default:
      break;
  }
  const fetchAPI = () => {
    setLoading(true);
    let param = {
      type: "content",
    };
    API.postKontenSupport(typeContent, param)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.count == 0) {
          setIsEmpty(true);
        }
        setLoading(false);
        props.handleData(res.data.data);

        let result =
          props.idContent && props.idContent.length
            ? res.data.data.filter((o) => {
                return o._id !== props.idContent;
              })
            : [];
        let filter = res.data.data
          .filter((o) => {
            return o._id == props.idContent;
          })
          .pop();
        result.unshift(filter);

        if (result[0] == undefined) {
          setRows(res.data.data);
        } else {
          setRows(result);
          document.getElementById("tableKontenDefault").rows[1].style.transition = "0.8s";
          document.getElementById("tableKontenDefault").rows[1].style.backgroundColor = "#e6f3ff";
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push("/pages-500");
        setRows(null);
      });
  };

  //set search data based on input value
  const handleSearch = () => {
    const searchValue = searchRef.current.value;
    setSearch(searchValue);
  };
  //if post modal success, then refresh data
  useEffect(() => {
    fetchAPI();
  }, [refresh, props.idContent]);
  //throw data to parent for download
  const filterData = (item) => {
    //
    props.handleData(item);
  };

  //if search input change
  useEffect(() => {
    filterData(items);
  }, [search, props.activeTab, tanggal]);

  //filtering data
  var items = [];
  if (rows && rows.length > 0) {
    items = rows.filter((data) => {
      const status =
        data.status == "approved" || data.status == "emergency"
          ? "Lolos Kurasi"
          : data.status == "scheduled"
          ? "Tayang"
          : data.status == "declined"
          ? "Tidak Lolos"
          : data.status == "Terjadwal"
          ? "Terjadwal"
          : data.status == "submitted"
          ? "Baru"
          : "";
      const index = [
        data.details.title,
        data.requested_by
          ? data.requested_by.organization
            ? data.requested_by.organization.name
              ? data.requested_by.organization.name
              : ""
            : ""
          : "",
        data.category,
        data.location && data.location.join(),
        data.proposed_duration,
        data.proposed_date
          .map((date) => moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"))
          .toString(),
        status,
      ];
      if (
        Filter.byIndex({
          index: index,
          search: search,
          date: tanggal,
        })
      ) {
        return data;
      }
    });
  }

  //modal preview
  const [modalKonten, setModalKonten] = useState(false);
  const [dataModalKonten, setDataModalKonten] = useState({});
  const toggleModalKonten = () => {
    setModalKonten(!modalKonten);
  };
  const handleModalKonten = (title, data) => {
    setDataModalKonten({
      url: data.details.url,
      title: title,
      type: data.type,
    });
    toggleModalKonten();
  };

  //modal doc
  const [modalDoc, setModalDoc] = useState(false);
  const [dataModalDoc, setDataModalDoc] = useState({});
  const toggleModalDoc = () => {
    setModalDoc(!modalDoc);
  };
  const handleModalDoc = (title, data) => {
    setDataModalDoc({
      url: data.emergency,
      title: title,
    });
    toggleModalDoc();
  };

  //modal detail
  const [modalDetail, setModalDetail] = useState(false);
  const [dataModalDetail, setDataModalDetail] = useState({});
  const toggleModalDetail = () => {
    setModalDetail(!modalDetail);
  };
  const handleModalActivedContent = (data) => {
    setDataModalDetail({
      data: data,
      title:
        props.activeTab == "3"
          ? typeof data.title !== undefined
            ? data.title
            : "-"
          : typeof data.details.title !== undefined
          ? data.details.title
          : "-",
      param: {
        idContent: data._id,
        idProvince: data.proposed_provinsi._id,
      },
    });
    toggleModalDetail();
  };
  //modal preview content
  const [modalPreviewContent, setModalPreviewContent] = useState(false);
  const [dataModalPreviewContent, setDataModalPreviewContent] = useState({});
  const toggleModalPreviewContent = () => {
    setModalPreviewContent(!modalPreviewContent);
  };
  const handleModalPreviewContent = (data) => {
    setDataModalPreviewContent({
      link: data.link ? data.link : "-",
      title:
        props.activeTab == "3"
          ? data.title
            ? data.title
            : "-"
          : data.details.title
          ? data.details.title
          : "-",
      url: data.details.url,
      type: data.type ?? "-",
    });
    toggleModalPreviewContent();
  };

  const handleFetch = () => {
    setRefresh(refresh + 1);
  };

  const handleDetail = (data) => {
    props.setTabDetail("video");
    props.detailData(data);
    history.push(`/support-center/detail-konten`);
  };

  const ButtonAction = ({ title, data, status }) => {
    if (typeof data.emergency !== "undefined" && data.status == "submitted") {
      return (
        <img
          className="btnAction"
          src={IconEmergency}
          onClick={() => handleModalDoc(title, data)}></img>
      );
    } else if (data.status == "scheduled") {
      return (
        <Button onClick={() => handleDetail(data)} className="btn-rounded btnDetail">
          Detail
        </Button>
      );
    } else {
      return <></>;
    }
  };
  const handleLink = (link) => {
    window.location.href = link;
  };
  const PreviewComponent = ({ it }) => {
    return (
      <center style={{ width: "fit-content" }}>
        {!it.tanggal_pembuatan.includes("2019") ? (
          typeContent == "video" || typeContent == "banner" ? (
            <img
              alt={props.activeTab == "3" ? it.title : it.details.title}
              className="wrapperImgYT"
              onClick={() => handleModalPreviewContent(it)}
              src={
                it.details
                  ? it.details.thumbnail
                    ? `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.thumbnail : ""}`
                    : `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.url : ""}`
                  : "-"
              }></img>
          ) : typeContent == "news" ? (
            <img
              alt={props.activeTab == "3" ? it.title : it.details.title}
              className="wrapperImgYT"
              onClick={() => handleLink(it.link)}
              src={
                it.details
                  ? it.details.thumbnail
                    ? `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.thumbnail : ""}`
                    : `${process.env.REACT_APP_BE_URL}/${it.details ? it.details.url : ""}`
                  : "-"
              }></img>
          ) : (
            <></>
          )
        ) : (
          <img
            alt={props.activeTab == "3" ? it.title : it.details.title}
            className="wrapperImgYT"
            src={noImage}></img>
        )}
      </center>
    );
  };
  const reverseData = _.orderBy(items, "status", "desc");
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Judul",
        field: "judul",
        sort: "asc",
        width: 200,
      },
      {
        label: "Organisasi",
        field: "organisasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori",
        field: "kategori",
        sort: "asc",
        width: 150,
      },
      {
        label: "Kategori Lokasi",
        field: "kategorilokasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Durasi",
        field: "durasi",
        sort: "asc",
        width: 150,
      },
      {
        label: "Provinsi",
        field: "p",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tanggal Tayang",
        field: "tanggal_tayang",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Preview",
        field: "preview",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Aksi",
        field: "aksi",
        sort: "disabled",
        width: 150,
      },
    ],

    rows:
      rows === null
        ? [
            {
              provinsi: [
                <Row key="a" className="justify-content-md-center">
                  Terjadi Kesalahan
                </Row>,
              ],
            },
          ]
        : items.length !== 0
        ? items.map((it, index) => ({
            no: index + 1,
            judul:
              props.activeTab == "3"
                ? it.title
                  ? it.title
                  : "-"
                : it.details.title
                ? it.details.title
                : "-",
            organisasi: it.requested_by.organization.name ?? "-",
            kategori: it.category ?? "-",
            kategorilokasi: it.location
              ? it.location.length > 2
                ? `${it ? (it.location[0] ? it.location[0] : "-") : "-"}, ${
                    it ? (it.location[1] ? it.location[1] : "-") : "-"
                  }, ${it ? (it.location[2] ? it.location[2] : "-") : "-"}...`
                : it.location.length > 1
                ? `${it.location[0] ? it.location[0] : "-"},${
                    it.location[1] ? it.location[1] : "-"
                  }`
                : `${it.location[0] ? it.location[0] : "-"}`
              : "-",
            p:
              it.list_provinsi.length > 2
                ? `${it ? (it.list_provinsi[0] ? it.list_provinsi[0].name : "-") : "-"}, ${
                    it ? (it.list_provinsi[1] ? it.list_provinsi[1].name : "-") : "-"
                  }, ${it ? (it.list_provinsi[2] ? it.list_provinsi[2].name : "-") : "-"}...`
                : it.list_provinsi.length > 1
                ? `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"},${
                    it.list_provinsi[1] ? it.list_provinsi[1].name : "-"
                  }`
                : `${it.list_provinsi[0] ? it.list_provinsi[0].name : "-"}`,
            durasi: it.proposed_duration ?? "-",
            tanggal_tayang:
              it.proposed_date.length > 0
                ? `${moment(it.proposed_date[0], "DD-MM-YYYY").format("YYYYMMDD")} 
              ${moment(it.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")}\n s/d \n${moment(
                    it.proposed_date.slice(-1),
                    "DD-MM-YYYY"
                  ).format("DD-MM-YYYY")}`
                : "-",
            status: it ? (
              it.status == "submitted" ? (
                <span
                  style={{
                    color:
                      it.status_kurator[it.status_kurator.length - 1].status == "Revisi"
                        ? "#E2B674"
                        : it.status_kurator[it.status_kurator.length - 1].status ==
                          "Tidak Lolos Kurasi"
                        ? "#C42127"
                        : it.status_kurator[it.status_kurator.length - 1].status == "Lolos Kurasi"
                        ? "#55BA59"
                        : it.status_kurator[it.status_kurator.length - 1].status == "Belum Kurasi"
                        ? "#000"
                        : "#000",
                  }}>
                  {it.status_kurator[it.status_kurator.length - 1].status == "Revisi"
                    ? "Revisi"
                    : it.status_kurator[it.status_kurator.length - 1].status == "Tidak Lolos Kurasi"
                    ? "Tidak Lolos Kurasi"
                    : it.status_kurator[it.status_kurator.length - 1].status == "Lolos Kurasi"
                    ? "Lolos Kurasi"
                    : it.status_kurator[it.status_kurator.length - 1].status == "Belum Kurasi"
                    ? "Baru"
                    : "-"}
                </span>
              ) : (
                <span
                  style={{
                    color:
                      it.status == "scheduled"
                        ? "#E2B674"
                        : it.status == "declined"
                        ? "#C42127"
                        : "#000",
                  }}>
                  {it.status == "scheduled" ? "Tayang" : it.status == "declined" ? "Ditolak" : "-"}
                </span>
              )
            ) : (
              <></>
            ),
            preview: <PreviewComponent it={it} />,
            aksi: <ButtonAction title={it.details.title} data={it} status={it.status} />,
          }))
        : [],
  };

  return (
    <React.Fragment>
      {alert == "successActivedContent" ? (
        <SweetAlert
          timeout={3}
          title="Konten Berhasil di Aktifkan"
          showConfirm={false}
          onConfirm={handleFetch}>
          <img src={IconGreenCheckList}></img>
        </SweetAlert>
      ) : alert == "failedActivedContent" ? (
        <SweetAlert
          timeout={3}
          title={"Konten Tidak Berhasil di Aktifkan"}
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : alert == "failedFetch" ? (
        <SweetAlert
          timeout={3}
          error
          title="User tidak berhasil di kurasi"
          showConfirm={false}
          onConfirm={handleFetch}></SweetAlert>
      ) : (
        ""
      )}
      <FilterCalendar
        modalCalendar={modalCalendar}
        toggleModalCalendar={toggleModalCalendar}
        selectedDate={handleSelectedDate}
      />
      <ModalPreviewContent
        ModalPreviewContent={modalPreviewContent}
        dataModalPreviewContent={dataModalPreviewContent}
        toggleModalPreviewContent={toggleModalPreviewContent}
        fetchData={handleFetch}
        activeTab={typeContent}
      />
      <ModalPreviewEmergencyDoc
        dataModalDoc={dataModalDoc}
        modalDoc={modalDoc}
        toggleModalDoc={toggleModalDoc}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card className="wrapperCard">
                <CardBody>
                  <div className="d-flex ml-auto w-50 my-3">
                    <Button
                      color=""
                      onClick={toggleModalCalendar}
                      id="tanggal"
                      className="inputWrapper btnTanggal"
                      style={{ marginRight: "10px" }}>
                      <p className="selectedDate">
                        {selectedDate}
                        <span class="mdi mdi-18px mdi-calendar-range" style={{ float: "right" }} />
                      </p>
                    </Button>
                    <input
                      placeholder="Cari..."
                      ref={searchRef}
                      className="form-control"
                      onChange={handleSearch}
                      type="text"
                    />
                  </div>
                  {rows.length === 0 && !isEmpty ? (
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton count={10} />
                    </div>
                  ) : isEmpty ? (
                    <div className="justify-content-md-center">
                      Tidak ada konten yang dapat di aktifkan
                    </div>
                  ) : (
                    <div style={{ whiteSpace: "pre-line" }}>
                      <MDBDataTable
                        id="TableKontenSupport"
                        noBottomColumns={true}
                        className="text-center"
                        responsive
                        disableRetreatAfterSorting
                        striped
                        bordered
                        data={data}
                        displayEntries={false}
                        pagesAmount={5}
                        entries={10}
                        searching={false}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.dataReducer;
  return { data };
};

export default connect(mapStateToProps, { setTabDetail, detailData })(TableKontenSupport);
