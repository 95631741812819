import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import "./style.css";

const ModalPreviewDoc = ({ modalDoc, toggleModalDoc, dataModalDoc }) => {
  // console.log("dataModalDoc.docType", dataModalDoc.docType);
  let urlDok = "";
  switch (dataModalDoc.docType) {
    case "Surat Tugas":
      urlDok = dataModalDoc.url
        ? dataModalDoc.url.suratTugas[0]
          ? dataModalDoc.url.suratTugas[0].path
            ? dataModalDoc.url.suratTugas[0].path
            : "-"
          : "-"
        : "-";
      break;

    case "Surat PP":
      urlDok = dataModalDoc.url
        ? dataModalDoc.url.suratPernyataan[0]
          ? dataModalDoc.url.suratPernyataan[0].path
            ? dataModalDoc.url.suratPernyataan[0].path
            : "-"
          : "-"
        : "-";

      break;

    case "Surat KTP":
      urlDok = dataModalDoc.url
        ? dataModalDoc.url.fileKtp[0]
          ? dataModalDoc.url.fileKtp[0].path
            ? dataModalDoc.url.fileKtp[0].path
            : "-"
          : "-"
        : "-";

      break;

    case "Swafoto KTP":
      urlDok = dataModalDoc.url
        ? dataModalDoc.url.fileSelfie[0]
          ? dataModalDoc.url.fileSelfie[0].path
            ? dataModalDoc.url.fileSelfie[0].path
            : "-"
          : "-"
        : "-";

      break;

    default:
      break;
  }
  return (
    <MDBContainer>
      <MDBModal size="lg" isOpen={modalDoc} toggle={toggleModalDoc} centered={true}>
        <MDBModalHeader centered toggle={toggleModalDoc}>
          <p>DOKUMEN PENUNJANG</p>
          <p className="textSubTitle">{dataModalDoc.docType}</p>
        </MDBModalHeader>
        <MDBModalBody>
          {dataModalDoc.url !== "Surat KTP" || dataModalDoc.url == "Swafoto KTP" ? (
            <iframe
              src={`${process.env.REACT_APP_BE_URL}/${urlDok}`}
              target="_top"
              className="iframe_doc"
              frameborder="0"
              allowFullScreen></iframe>
          ) : (
            <img
              src={`${process.env.REACT_APP_BE_URL}/${urlDok}`}
              className="w-100"
              allowFullScreen></img>
          )}
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ModalPreviewDoc;
