import React, { useState, useEffect } from "react";
import { MDBModal, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./style.css";
import moment from "moment";
// ------------------API-----------------------
import API from "../../../../../../services";
import _ from "lodash";

const ModalDetailPengguna = ({
  modalDetailPengguna,
  toggleModalDetailPengguna,
  dataModalDetailPengguna,
}) => {
  let data = dataModalDetailPengguna !== undefined ? dataModalDetailPengguna.data : [];
  if (data !== undefined)
    return (
      <MDBModal
        id="ModalDetailPengguna"
        isOpen={modalDetailPengguna}
        toggle={toggleModalDetailPengguna}
        size="lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="w-100 text-center">
              <p className="topTitleEdit">DETAIL PENGGUNA</p>
            </div>
            <button
              type="button"
              className="close pull-right"
              aria-label="Close"
              onClick={toggleModalDetailPengguna}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
            <div className="w-40 mx-auto wrapperSubTitleVerif">
              {/* <h4 className="subTitle">{data.title.length == 0 ? data.details.title : data.title}</h4> */}
            </div>

            <div className="w-100 mx-auto wrapperVerification">
              <MDBTable responsive borderless id="TableDetailKonten">
                <MDBTableHead>
                  <tr>
                    <th style={{ width: "140px" }}>Username</th>
                    <th>Nama</th>
                    <th>Email</th>
                    <th>Waktu Registrasi</th>
                    {_.has(data.doc_penunjang, "tanggal_perubahan") ? (
                      <th>Aktivitas Terbaru</th>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <th>Status</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  <tr>
                    <td>{data.username ?? "-"}</td>
                    <td>{data.name ?? "-"}</td>
                    <td>{data.email ?? "-"}</td>
                    <td>
                      {data
                        ? data.doc_penunjang
                          ? data.doc_penunjang.tanggal_pembuatan
                            ? `
                                ${moment(data.doc_penunjang.tanggal_pembuatan).format("DD-MM-YYYY")}
                              `
                            : "-"
                          : data.tanggal_pembuatan
                          ? moment(data.tanggal_pembuatan).format("DD-MM-YYYY")
                          : "-"
                        : "-"}
                    </td>
                    {_.has(data.doc_penunjang, "tanggal_perubahan") ? (
                      <td>{moment(data.doc_penunjang.tanggal_perubahan).format("DD-MM-YYYY")}</td>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <td>
                      {data.status === "approved"
                        ? "Aktif"
                        : data.status === "requested"
                        ? "Tidak Aktif"
                        : data.status === "confirmation"
                        ? "Belum dikonfirmasi"
                        : ""}
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
      </MDBModal>
    );
  else {
    return <></>;
  }
};

export default ModalDetailPengguna;
