import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

import Header from "../../../components/VerticalLayout/Header";
import "./style.css";
import { Col, Row, Container, Alert } from "reactstrap";

import issue from "../../../assets/images/Issue.png";

import CardHomeCP from "../../../components/Card/Support/CardHome/CardHome";
import CardMap from "../../../components/Card/Support/CardHome/CardMap";
import CardAverage from "../../../components/Card/Support/CardHome/CardAverage";
import CardBarChart from "../../../components/Card/Support/CardHome/CardBarChart/index";
import CardRating from "../../../components/Card/Support/CardHome/CardRating";

import API from "../../../services";

export default function Home() {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);

  const onDismiss = () => setVisible(false);

  // untuk loading skeleton
  const skeletonCostume = <Skeleton circle={true} height={50} width={50} />;

  const getIssueData = () => {
    API.getHomeSupport()
      .then((res) => {
        if (res !== undefined) {
          setLoading(false);
          setData(res.data.data);
        } else {
          setLoading(false);
          setData([]);
        }
      })
      .catch((err) => {
        onDismiss();
        setError("Gagal Memuat Data");
        setTimeout(() => {
          onDismiss();
        }, 3000);
        setLoading(false);
      });
  };

  useEffect(() => {
    getIssueData();
  }, []);

  return (
    <div>
      <Header title="Home" />
      <Container>
        {error == "" ? (
          ""
        ) : (
          <Alert color="danger" isOpen={visible} toggle={onDismiss}>
            {error}
          </Alert>
        )}
      </Container>
      <Container fluid>
        <Row className="mt-5 mb-5">
          <Col md="4">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp">
                <div>
                  <img src={issue} alt="people" />
                </div>
                <div className="title-total">ISSUE HARI INI</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                  <div className="total-content">
                    {data.jumlah_ticket_hari_ini == undefined ? "0" : data.jumlah_ticket_hari_ini}
                  </div>
                )}
              </div>
              {/* <div className="v1"></div> */}
            </Row>
          </Col>
          <Col md="4">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp">
                <div>
                  <img src={issue} alt="curate" />
                </div>
                <div className="title-total">ISSUE MINGGU INI</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                  <div className="total-content">
                    {data.jumlah_ticket_minggu_ini == undefined
                      ? "0"
                      : data.jumlah_ticket_minggu_ini}
                  </div>
                )}
              </div>
              {/* <div className="v1"></div> */}
            </Row>
          </Col>
          <Col md="4">
            <Row
              className="d-flex justify-content-center"
              style={{ borderRight: "solid", borderColor: "#C0C0C0", borderRightWidth: "thin" }}>
              <div className="wrap-content-partner-cp">
                <div>
                  <img src={issue} alt="curated" />
                </div>
                <div className="title-total">ISSUE BULAN INI</div>
                {loading == true ? (
                  skeletonCostume
                ) : (
                  <div className="total-content">
                    {data.jumlah_ticket_bulan_ini == undefined ? "0" : data.jumlah_ticket_bulan_ini}
                  </div>
                )}
              </div>
              {/* <div className="v1"></div> */}
            </Row>
          </Col>
        </Row>
        {loading == true ? (
          <Row className="justify-content-center mb-5">
            <Col md="8">
              <Skeleton count={3} />
            </Col>
          </Row>
        ) : (
          <Container fluid>
            <CardAverage setError={setError} />
            <CardBarChart setError={setError} />
            <CardHomeCP data={data} setError={setError} />
            {/* <CardMap setError={setError} /> */}
            <CardRating setError={setError} />
          </Container>
        )}
      </Container>
    </div>
  );
}
