import React, { useEffect, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
// --------Redux---------
import { connect } from "react-redux";
import { getDatasNotif } from "../../../store/actions"

import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment";

//i18n
import { withNamespaces } from "react-i18next";

const NotificationDropdown = (props) => {
  let history = useHistory();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState([]);
  // const [notif, setNotif] = useState(true);

  const getNotif = () => {
    setData(props.data)
  };

  useEffect(() => {
    
    getNotif()
    return () => { };
  }, [props.data]);

  const notif = window.localStorage.getItem("notif");

  const handleNotif = () => {
    props.data.length !== 0
      ? props.data[0] !== undefined
        ? window.localStorage.setItem("notif", (props ? props.data[0] ? props.data[0]._id ? "" : "" : "" : ""))
        : window.localStorage.setItem("notif", "")
      : window.localStorage.setItem("notif", "")
  };

  const differenceTime = (date) => {
    var dateNow = moment(Date.now());
    const dates = moment(date);
    const diff = dateNow.diff(dates, "seconds");

    if (diff <= 60) {
      return "a moment ago";
    } else if (diff < 3600) {
      return `${(diff / 60).toFixed()} minutes ago`;
    } else if (diff <= 3600 * 24) {
      return `${(diff / 3600).toFixed()} hours ago`;
    } else if (diff <= 86400 * 30) {
      return `${(diff / 86400).toFixed()} days ago`;
    } else {
      return "long time ago";
    }
  };

  const handleClickNotif = (val) => {
    if (val.notif_type === "created") {
      props.getDatasNotif(val)
      history.push('/kurator/konten')
    }
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li">
        <DropdownToggle
          onClick={() => { handleNotif() }}
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown">
          {(data.length !== 0 && props.data != undefined && notif !== (props ? props.data[0] ? props.data[0]._id ? "" : "" : "" : "")) ?
            <i className="bx bx-bell bx-tada"></i>
            :
            <i className="bx bx-bell"></i>
          }
          {/* {(data.length !== 0 && props.data != undefined && props.data != undefined && notif !== (props ? props.data[0] ? props.data[0]._id ? "" : "" : "" : "")) && <span className="badge badge-danger badge-pill">-</span>} */}
        </DropdownToggle>

        <DropdownMenu style={{ transform: "none", width: "300px", maxHeight: "400px", overflowY: "scroll" }} className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {"Notifications"} </h6>
              </Col>
            </Row>
          </div>

          {data.length === 0 ?
            <SimpleBar>
              <a className="text-reset notification-item">
                <div className="media">
                  {/* <div onClick={() => console.log("")} className="media-body"> */}
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">No notifications</p>
                  </div>
                  {/* </div> */}
                </div>
              </a>
            </SimpleBar>
            :
            <SimpleBar>
              {data.map((val) => {
                return (
                  <a className="text-reset notification-item">
                    <div className="media">
                      <div onClick={() => handleClickNotif(val)} className="media-body">
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{val.message}</p>
                          <li className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            {differenceTime(val.tanggal_pembuatan)}{" "}
                          </li>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </SimpleBar>
          }
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const mapStateToProps = state => {
  return {}
};
export default connect(mapStateToProps, { getDatasNotif })(withRouter(NotificationDropdown));
