import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// import Home from "../pages/Home/Admin";
// import DetailKonten from "../pages/SiteAnalysis/Admin";

// TAK ADA
// import SiteAnalysisCP from "../pages/SiteAnalysis/CP";
// import PersetujuanKontenSuperAdmin from "../pages/PersetujuanKonten/SuperAdmin";
// import RegistrasiAdmin from "../pages/RegistrasiAdmin/SuperAdmin";
// import DashboardSuperAdmin from "../pages/DashboardSuperAdmin";
// ====================================

// import BusinessIntelligence from "../pages/BusinessIntelligence/Admin";
// import LogHistory from "../pages/BusinessIntelligence/Admin/LogHistory";
// import Artikel from "../pages/Artikel";
// import KontenTayangKurator from "../pages/KontenTayang/Kurator";
// import KontenKurator from "../pages/Konten/Kurator";
// import ReviewKontenKurator from "../pages/ReviewKontenKurator/Kurator";
// import userProfile from "../pages/Authentication/user-profile";

// import TambahPenggunaAdmin from "../pages/TambahPengguna/index";
// import PenggunaAdmin from "../pages/Pengguna/Admin";
// import KontenDefault from "../pages/KontenDefault/Admin";
// import KontenStatistik from "../pages/KontenStatistik";
// import KontenReport from "../pages/KontenReport";
// import Konten from "../pages/Konten/Admin";
// import CreateKontenAdmin from "../pages/BuatKonten/Admin";
// import CreateKontenDefault from "../pages/CreateKontenDefault/Admin";
// import SiteStatistics from "../pages/SiteStatistics";
// import UniqueDeviceAdmin from "../pages/UniqueDevice/Admin";
// import SiteChecker from "../pages/SiteChecker";
// import EditArticle from "../pages/Artikel/EditArticle";
// import CreateArticle from "../pages/Artikel/CreateArticle";
// import CPAnalisis from "../pages/CPAnalisis";
import CPAnalisisDetail from "../components/Admin/Table/CPTableKonten"; // JANGAN DI GANTI

// import PesebaranPengunjung from "../pages/BusinessIntelligence/Admin/Measures/PersebaranPengunjung";
// import DemografiPengunjung from "../pages/BusinessIntelligence/Admin/Measures/DemografiPengunjung";
// import TrafficKonten from "../pages/BusinessIntelligence/Admin/Measures/TrafficKonten";
// import SummaryTrafficKonten from "../pages/BusinessIntelligence/Admin/Measures/SummaryTrafficKonten";
// import ISP from "../pages/BusinessIntelligence/Admin/Measures/ISP";
// import DeviceFingerPrint from "../pages/BusinessIntelligence/Admin/Measures/DeviceFingerprint";
// import Survey from "../pages/BusinessIntelligence/Admin/Measures/Survey";
// import UserBehavior from "../pages/BusinessIntelligence/Admin/Measures/UserBehavior";
// import VisitorJourney from "../pages/BusinessIntelligence/Admin/Measures/VisitorJourney";
// import ContentPreviewAdmin from "../pages/DetailKontenPreview/Admin";
// import KontenTayangAdmin from "../pages/KontenTayang/Admin";
// import SurveyAdmin from "../pages/Survey/Admin";
// import BuatSurveyAdmin from "../pages/BuatSurvey";
// import HasilSurvey from "../pages/HasilSurvey";
// import HasilSurveyDefault from "../pages/HasilSurveyDefault";
// import SurveyDefaultPreview from "../pages/PreviewSurveyDefault/Admin";
// import DuplikatSurvey from "../pages/DuplikatSurvey";
// import superAdminProfile from "../pages/Authentication/superAdminProfile";
// import SiteAnalysis from "../pages/SiteAnalysis/Admin";
// import BuatSurveyDefault from "../pages/BuatSurveyDefault";
//500 error
import Pages500 from "../pages/Utility/pages-500";

// Async
import Artikel from "../pagesAsync/ArtikelAsync";
import Video from "../pagesAsync/VideoAsync";
import Home from "../pagesAsync/HomeAsync/AdminAsync";
import DetailKonten from "../pagesAsync/SiteAnalysisAsync/AdminAsync";
import BusinessIntelligence from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync";
import LogHistory from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync";
import TambahPenggunaAdmin from "../pagesAsync/TambahPenggunaAsync";
import PenggunaAdmin from "../pagesAsync/PenggunaAdminAsync";
import KontenDefault from "../pagesAsync/KontenDefaultAsync/AdminAsync";
import KontenStatistik from "../pagesAsync/KontenStatistikAsync";
import KontenReport from "../pagesAsync/KontenReportAsync";
import Konten from "../pagesAsync/KontenAsync/AdminAsync";
import CreateKontenAdmin from "../pagesAsync/BuatKontenAsync/AdminAsync";
import CreateKontenDefault from "../pagesAsync/CreateKontenDefaultAsync/AdminAsync";
import SiteStatistics from "../pagesAsync/SiteStatisticsAsync";
import UniqueDeviceAdmin from "../pagesAsync/UniqueDeviceAsync/AdminAsync";
import SiteChecker from "../pagesAsync/SiteCheckerAsync";
import EditArticle from "../pagesAsync/EditArticleAsync";
import CreateArticle from "../pagesAsync/ArtikelAsync/CreateArtikelAsync";
import CPAnalisis from "../pagesAsync/CPAnalisisAsync";
import PesebaranPengunjung from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/PersebaranPengunjungAsync";
import DemografiPengunjung from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/DemografiPengunjungAsync";
import TrafficKonten from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/TrafficKontenAsync";
import SummaryTrafficKonten from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/SummaryTrafficKontenAsync";
import ISP from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/ISPAsync";
import DeviceFingerPrint from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/DeviceFingerPrintAsync";
import Survey from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/SurveyAsync";
import UserBehavior from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/UserBehaviorAsync";
import VisitorJourney from "../pagesAsync/BusinessIntellegenceAsync/AdminAsync/Measures/VisitorJourneyAsync";
import ContentPreviewAdmin from "../pagesAsync/DetailKontenPreviewAsync/AdminAsync";
import KontenTayangAdmin from "../pagesAsync/KontenTayangAsync/AdminAsync";
import SurveyAdmin from "../pagesAsync/SurveyAdminAsync";
import BuatSurveyAdmin from "../pagesAsync/BuatSurveyAdminAsync";
import HasilSurvey from "../pagesAsync/HasilSurveyAsync";
import HasilSurveyDefault from "../pagesAsync/HasilSurveyDefaultAsync";
import SurveyDefaultPreview from "../pagesAsync/SurveyDefaultPreviewAsync";
import DuplikatSurvey from "../pagesAsync/DuplikatSurveyAsync";
import superAdminProfile from "../pagesAsync/AuthenticationAsync/SuperAdminProfileAsync";
import SiteAnalysis from "../pagesAsync/SiteAnalysisAsync/AdminAsync";

import BannerLandingPage from "../pagesAsync/BannerLandingPageAsync";

import BuatSurveyDefault from "../pagesAsync/BuatSurveyDefaultAsync";

import EditVideo from "../pagesAsync/VideoAsync/EditVideoAsync";
import CreateVideo from "../pagesAsync/VideoAsync/CreateVideoAsync";

const AppAdmin = (props) => {
  return (
    <Switch>
      <Redirect exact from={`${props.match.url}`} to={`${props.match.url}admin/Home`} />
      <Route exact path={`${props.match.url}admin/Home`} component={Home} />
      <Route exact path={`${props.match.url}admin/artikel`} component={Artikel} />
      <Route exact path={`${props.match.url}admin/video`} component={Video} />
      <Route exact path={`${props.match.url}admin/edit-article`} component={EditArticle} />
      <Route exact path={`${props.match.url}admin/create-article`} component={CreateArticle} />
      <Route exact path={`${props.match.url}admin/edit-video`} component={EditVideo} />
      <Route exact path={`${props.match.url}admin/create-video`} component={CreateVideo} />

      <Route exact path={`${props.match.url}admin/pengguna`} component={PenggunaAdmin} />
      <Route
        exact
        path={`${props.match.url}admin/tambah-pengguna`}
        component={TambahPenggunaAdmin}
      />
      <Route exact path={`${props.match.url}admin/dashboardAdmin`} component={""} />
      {/* <Route exact path={`${props.match.url}superadmin/registrasiAdmin`} component={RegistrasiAdmin} />
            <Route exact path={`${props.match.url}superadmin/dashboardAdmin`} component={DashboardSuperAdmin} />
            <Route exact path={`${props.match.url}superadmin/businessIntelligence`} component={businessIntelligence} />
            <Route exact path={`${props.match.url}superadmin/profile`} component={superAdminProfile} /> */}
      <Route exact path={`${props.match.url}admin/profile`} component={superAdminProfile} />
      <Route
        exact
        path={`${props.match.url}admin/konten-default-create`}
        component={CreateKontenDefault}
      />
      <Route exact path={`${props.match.url}admin/konten-default`} component={KontenDefault} />
      <Route
        exact
        path={`${props.match.url}admin/konten-preview`}
        component={ContentPreviewAdmin}
      />
      <Route exact path={`${props.match.url}admin/konten`} component={Konten} />
      <Route exact path={`${props.match.url}admin/konten-create`} component={CreateKontenAdmin} />
      <Route exact path={`${props.match.url}admin/pengguna`} component={PenggunaAdmin} />
      <Route exact path={`${props.match.url}admin/konten-statistik`} component={KontenStatistik} />
      <Route
        exact
        path={`${props.match.url}admin/konten-statistik-detail`}
        component={DetailKonten}
      />
      <Route exact path={`${props.match.url}admin/konten-tayang`} component={KontenTayangAdmin} />
      <Route exact path={`${props.match.url}admin/konten-preview`} component={KontenDefault} />
      <Route exact path={`${props.match.url}admin/konten-report`} component={KontenReport} />

      <Route exact path={`${props.match.url}admin/statistik-site`} component={SiteStatistics} />
      <Route exact path={`${props.match.url}admin/unique-device`} component={UniqueDeviceAdmin} />
      <Route exact path={`${props.match.url}admin/site-checker`} component={SiteChecker} />

      <Route exact path={`${props.match.url}admin/survey/:id`} component={SurveyAdmin} />
      <Route exact path={`${props.match.url}admin/survey`} component={SurveyAdmin} />
      <Route exact path={`${props.match.url}admin/create/survey`} component={BuatSurveyAdmin} />
      <Route exact path={`${props.match.url}admin/result/survey`} component={HasilSurvey} />
      <Route
        exact
        path={`${props.match.url}admin/result/survey_default`}
        component={HasilSurveyDefault}
      />
      <Route
        exact
        path={`${props.match.url}admin/survey/preview`}
        component={SurveyDefaultPreview}
      />
      <Route exact path={`${props.match.url}admin/duplikat/survey`} component={DuplikatSurvey} />
      <Route
        exact
        path={`${props.match.url}admin/create/survey_default`}
        component={BuatSurveyDefault}
      />

      <Route exact path={`${props.match.url}admin/cp-analitics`} component={CPAnalisis} />
      <Route
        exact
        path={`${props.match.url}admin/cp-analitics-detail`}
        component={CPAnalisisDetail}
      />
      <Route
        exact
        path={`${props.match.url}admin/cp-analitics-detail-statistik-konten`}
        component={SiteAnalysis}
      />
      {/* <Route exact path={`${props.match.url}admin/site-analysis`} component={SiteAnalysis} /> */}
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence`}
        component={BusinessIntelligence}
      />
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence-PersebaranPengunjung`}
        component={PesebaranPengunjung}
      />
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence-DemografiPengunjung`}
        component={DemografiPengunjung}
      />
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence-TrafficKonten`}
        component={TrafficKonten}
      />
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence-SummaryTrafficKonten`}
        component={SummaryTrafficKonten}
      />
      <Route exact path={`${props.match.url}admin/business-intelligence-ISP`} component={ISP} />
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence-DeviceFingerPrint`}
        component={DeviceFingerPrint}
      />
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence-Survey`}
        component={Survey}
      />
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence-UserBehavior`}
        component={UserBehavior}
      />
      <Route
        exact
        path={`${props.match.url}admin/business-intelligence-VisitorJourney`}
        component={VisitorJourney}
      />
      <Route exact path={`${props.match.url}admin/banner-landing-page`} component={BannerLandingPage} />
      <Route exact path={`${props.match.url}admin/log-history`} component={LogHistory} />
      <Route exact path={`${props.match.url}page-500`} component={Pages500} />

      <Redirect to="/" />
    </Switch>
  );
};

export default AppAdmin;
