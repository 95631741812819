import React, { useState } from "react";
import { MDBModal } from "mdbreact";
import { Spinner, FormText } from "reactstrap";
import "./style.css";
// ------------------API-----------------------
import API from "../../../../../services";
// ------------------Component-----------------------
import FilterDeclinedCat from "./FilterDeclinedCat";
const ModalDecline = ({
  modalDecline,
  toggleModalDecline,
  dataModalDecline,
  handleAlert,
  fetchData,
}) => {
  const typeContent =
    dataModalDecline.activeTab == "1"
      ? "video"
      : dataModalDecline.activeTab == "2"
      ? "banner"
      : "news";
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [kategori, setKategori] = useState("");

  let param = {
    idContent: dataModalDecline.id,
    type: typeContent,
    comment: comment,
    kategori: kategori,
  };

  const onHandleChange = (e) => {
    setComment(e.target.value);
  };
  const [error, setError] = useState({
    kategori: "",
    comment: "",
  });
  const [listKategori] = useState([
    "Informasi / Pesan dalam Konten",
    "Jadwal (Tanggal / Jam) Penayangan Konten",
    "Durasi Konten",
  ]);
  const filterData = (filter, type) => {
    switch (type) {
      case "Kategori Alasan ditolak":
        setKategori(filter);
        break;
    }
  };

  const onHandleSubmit = () => {
    if (comment === "") {
      setError((prev) => ({
        ...prev,
        comment: "Kolom Komentar Tidak Boleh Kosong",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        comment: "",
      }));
    }
    if (kategori === "") {
      setError((prev) => ({
        ...prev,
        kategori: "Kolom Kategori Tidak Boleh Kosong",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        kategori: "",
      }));
    }
    if (comment !== "" && kategori !== "") {
      setIsLoading(true);
      API.postDeclinePersetujuanKonten(param)
        .then(async (res) => {
          setIsLoading(false);
          // console.log("res tdklolos", res)
          setComment("");
          if (res.data.success) {
            new Promise(() => {
              toggleModalDecline();
              handleAlert.setAlert("fail");
              setTimeout(() => {
                handleAlert.setAlert("");
              }, 2000);
            }, fetchData());
          } else {
            new Promise(() => {
              toggleModalDecline();
              handleAlert.setAlert("failedFetch");
              setTimeout(() => {
                handleAlert.setAlert("");
              }, 2000);
            }, fetchData());
          }
        })
        .catch((e) => {
          setIsLoading(false);
          new Promise(() => {
            toggleModalDecline();
            handleAlert.setAlert("failedDecline");
            setTimeout(() => {
              handleAlert.setAlert("");
            }, 2000);
          }, fetchData());
        });
    }
  };

  return (
    <MDBModal isOpen={modalDecline} toggle={toggleModalDecline} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <div className="w-100 text-center">
            <p className="topTitleDecline">TOLAK KONTEN</p>
          </div>
          <button
            type="button"
            className="close pull-right"
            aria-label="Close"
            onClick={toggleModalDecline}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body d-flex flex-column justify-content-md-center align-items-center">
          <div className="w-40 mx-auto wrapperSubTitleVerif">
            <h4 className="subTitle">{dataModalDecline && (dataModalDecline.title ?? "-")}</h4>
          </div>

          <div className="w-40 mx-auto wrapperVerification">
            <h4 className="subTitleDecline">Anda yakin ingin Tolak Konten ini?</h4>
          </div>

          <div className="form-group wrapperTextAreaDecline">
            <label>Kategori alasan ditolak: </label>
            <FilterDeclinedCat
              type={"Kategori Alasan ditolak"}
              filter={kategori}
              clicked={filterData}
              list={listKategori}
            />
            {
              <FormText className="formTextError" color="#c42127">
                {error.kategori}
              </FormText>
            }
          </div>
          <div className="form-group wrapperTextAreaDecline">
            <label>Alasan konten ditolak: </label>
            <textarea
              onChange={(e) => onHandleChange(e)}
              value={comment}
              className="form-control textAreaDecline requiredField"
              placeholder="Tulis Alasan Anda Disini..."
              rows="5"></textarea>
            {
              <FormText className="formTextError" color="#c42127">
                {error.comment}
              </FormText>
            }
          </div>

          <div className="mx-auto wrapperBtnVerification">
            {isLoading ? (
              <center>
                <Spinner color="danger" />
              </center>
            ) : (
              <button
                type="button"
                onClick={onHandleSubmit}
                className="w-100 btn btnDecline btn-rounded">
                Tolak
              </button>
            )}
          </div>
        </div>
      </div>
    </MDBModal>
  );
};

export default ModalDecline;
