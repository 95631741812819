import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDF = (dataTable, dataTable2, activeTab, activeSubTab1, activeSubTab2) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title;
  switch (activeTab) {
    case "1":
      title = "KONTEN";
      break;
    case "2":
      title = "KONTEN ARCHIEVE";
      break;
    default:
      break;
  }
  let headers;
  let data;
  if (activeTab == "1") {
    headers = [
      [
        "No",
        "Judul",
        "Organisasi",
        "Kategori",
        "Kategori Lokasi",
        "Durasi",
        "Provinsi",
        "Tanggal Tayang",
        "Status",
        "Preview",
      ],
    ];
    data = dataTable.map((data,index) => {
      return [
        index + 1,
        data.title ? data.title ?? "-" : data.details.title ?? "-",
        data.requested_by.organization.name ?? "-",
        data.category ?? "-",
        Array.isArray(data.location) && data.location.length? data.location : "Tidak ada kategori lokasi",
        data.proposed_duration ?? "-",
        data.list_provinsi.length > 0
          ? data.list_provinsi.map((prov) => prov.name).toString()
          : "-",
        data.proposed_date.length > 0
          ? `${moment(data.proposed_date[0], "DD-MM-YYYY").format("DD-MM-YYYY")}\n s/d \n${moment(
              data.proposed_date.slice(-1),
              "DD-MM-YYYY"
            ).format("DD-MM-YYYY")} `
          : "-",
        data.status == "submitted"? 
          data.status_kurator[data.status_kurator.length - 1].status == "Revisi"?
          "Revisi"
          :data.status_kurator[data.status_kurator.length - 1].status == "Tidak Lolos Kurasi"?
          "Tidak Lolos Kurasi"
          :data.status_kurator[data.status_kurator.length - 1].status == "Lolos Kurasi"?
          "Lolos Kurasi"
          :data.status_kurator[data.status_kurator.length - 1].status == "Belum Kurasi"?
          "Baru"
          :"-"
          :data.status == "scheduled"?
          "Tayang"
          :data.status == "declined"?
          "Ditolak"
          :"-",
      ];
    });
  } else {
    headers = [["No", "Judul", "Kategori", "Status", "Preview"]];
    data = dataTable2.map((data, index) => {
      return [
        index + 1,
        data.title ? data.title : data.details.title ? data.details.title : "-",
        data.category ?? "-",
        data.status_kurator[data.status_kurator.length - 1].status == "Tidak Lolos Kurasi"
          ? "Tidak Lolos Kurasi"
          : data.status_kurator[data.status_kurator.length - 1].status == "Lolos Kurasi"
          ? "Lolos Kurasi"
          : data.status == "pending"
          ? "Pending"
          : "-",
      ];
    });
  }
  let a = 0;
  let content = {
    startY: 50,
    head: headers,
    body: data,
    didDrawCell: async function (dataCell) {
      if (activeTab === "2") {
        if (dataCell.column.index === headers[0].length - 1 && dataCell.cell.section === "body") {
          var diw = dataCell.cell.width - dataCell.cell.padding("horizontal");
          var dih = dataCell.cell.height - dataCell.cell.padding("vertical");
          if (dataCell.row.index < dataTable2.length) {
            if (dataTable2[dataCell.row.index]) {
              var img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.src =
                activeSubTab2 === "1"
                  ? `${process.env.REACT_APP_SELF_URL}/${
                      dataTable2[dataCell.row.index].details.thumbnail
                    }`
                  : `${process.env.REACT_APP_SELF_URL}/${dataTable2[dataCell.row.index].details.url}`;
              var poll = setInterval(function () {
                if (img.naturalWidth) {
                  clearInterval(poll);
                }
              }, 10);
              try {
                doc.addImage(
                  img,
                  dataCell.cell.x,
                  dataCell.cell.y + 5,
                  dih > diw ? diw : 0 / dih,
                  dih > diw ? 0 / diw : dih
                );
              } catch (e) {
                doc.addImage(
                  "https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg",
                  dataCell.cell.x,
                  dataCell.cell.y + 5,
                  dih > diw ? diw : 0 / dih,
                  dih > diw ? 0 / diw : dih
                );
              }
            }
            a = a + 1;
          }
        }
      } else {
        if (dataCell.column.index === headers[0].length - 1 && dataCell.cell.section === "body") {
          var diw = dataCell.cell.width - dataCell.cell.padding("horizontal");
          var dih = dataCell.cell.height - dataCell.cell.padding("vertical");
          if (dataCell.row.index < dataTable.length) {
            if (dataTable[dataCell.row.index]) {
              var img = new Image();
              img.setAttribute("crossOrigin", "anonymous");
              img.src =
                activeSubTab1 === "1"
                  ? `${process.env.REACT_APP_SELF_URL}/${
                      dataTable[dataCell.row.index].details.thumbnail
                    }`
                  : `${process.env.REACT_APP_SELF_URL}/${dataTable[dataCell.row.index].details.url}`;
  
              var poll = setInterval(function () {
                if (img.naturalWidth) {
                  clearInterval(poll);
                }
              }, 10);
              try {
                doc.addImage(
                  img,
                  dataCell.cell.x,
                  dataCell.cell.y + 5,
                  dih > diw ? diw : 0 / dih,
                  dih > diw ? 0 / diw : dih
                );
              } catch (e) {
                doc.addImage(
                  "https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg",
                  dataCell.cell.x,
                  dataCell.cell.y + 5,
                  dih > diw ? diw : 0 / dih,
                  dih > diw ? 0 / diw : dih
                );
              }
            }
            a = a + 1;
          }
        }
      }
      a = a + 1;
    },
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`Konten_${activeTab === "1" ? role : `Archieve_${role}`}.pdf`);
};
