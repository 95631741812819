import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const exportPDF = (dataTable) => {
  let role = window.localStorage.getItem("role");
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let title = "Data Tiket";
  const headers = [
    [
      "No",
      "No. Tiket",
      "Nama/Organisasi",
      "No Handphone",
      "Jenis",
      "Kategori",
      "Level",
      "Tanggal Masuk",
      "Tanggal Closed",
      "Status",
      "Kepuasan",
      "Pertanyaan",
    ],
  ];

  const data = dataTable.map((it, index) => {
    return [
      index + 1,
      it.ticketNum ?? "-",
      it.name ? it.name : it.email ? it.email : "-",
      it.phone ?? "-",
      it ? (it.type ? it.type ?? "-" : "-") : "-",
      it ? (it.kategori ? it.kategori ?? "-" : "-") : "-",
      it ? (it.level ? it.level : "-") : "-",
      it ? (it.tanggal_pembuatan ? moment(it.tanggal_pembuatan).format("DD-MM-YYYY") : "-") : "-",
      it
        ? it.closed
          ? it.closed.closedAt
            ? moment(it.closed.closedAt).format("DD-MM-YYYY")
            : "-"
          : "-"
        : "-",
      it ? (it.status ? it.status.toLowerCase() : "-") : "-",
      it ? (it.closed ? it.closed.closedType : "") : "",
      it.message,
    ];
  });
  let a = 0;
  let content = {
    startY: 50,
    startX: 20,
    head: headers,
    body: data,
    columnStyles: {
      0: { cellWidth: 30 }, // no
      1: { cellWidth: 40 }, // no. tiket
      2: { cellWidth: 50 }, // organisasi
      3: { cellWidth: 90 }, // no. handphone
      4: { cellWidth: 50 }, // jenis
      5: { cellWidth: 50 }, // kategori
      6: { cellWidth: 50 }, // level
      7: { cellWidth: 75 }, // tanggal masuk
      8: { cellWidth: 75 }, // tanggal closed
      9: { cellWidth: 50 }, // status
      10: { cellWidth: 50 }, // kepuasan
      11: { cellWidth: 150, maxWidth: 150 }, // pertanyaan
    },
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`Ticket_Report_${role}.pdf`);
};
