import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import "./datatables.scss";

import "./style.css";
import FilterButton from "./dropdownFilter.js";
import PopupVideo from "../../Popup/KontenTayang/PopupVideo";
import PopupBanner from "../../Popup/KontenTayang/PopupBanner";
import News from "../../../../assets/images/news_bakti.png";
import API from "../../../../services";

const KontenTayangTable = (props) => {
  let history = useHistory();
  const [modalVideo, setModalVideo] = useState(false);
  const [modalBanner, setModalBanner] = useState(false);
  const [dataVideo, setDataVideo] = useState({});
  const [dataBanner, setDataBanner] = useState({});
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("Aceh");

  let toggleVideo = (i) => {
    setDataVideo(i);
    setModalVideo(!modalVideo);
  };

  let toggleBanner = (i) => {
    setDataBanner(i);
    setModalBanner(!modalBanner);
  };

  //Create Data for Row
  function createData(jamtayang, provinsi, video, banner, berita) {
    return { jamtayang, provinsi, video, banner, berita };
  }

  async function fetchKontenTayang() {
    API.postKontenTayang({ provinsi: filter.toUpperCase() })
      .then((res) => {
        if (res.data.data.length !== 0) {
          let rowsData = [];
          const json = res.data;
          for (let i of json.data) {
            let a = typeof i.video.content_id === "undefined" ? i.video : i.video.content_id;
            let b =
              typeof i.banner.content_id === "undefined"
                ? i.banner.details
                : i.banner.content_id.details;

            let video = "";
            video =
              /* eslint-disable */
              [
                <a key={i.video._id} onClick={() => toggleVideo(i)}>
                  {a.type === "youtube" ? (
                    <img
                      className="img-thumbnail"
                      src={`http://img.youtube.com/vi/${a.details.url}/0.jpg`}
                      alt={a.url}
                      onError={(e) => {
                        e.target.src = News; // If Error
                      }}
                    />
                  ) : (
                      <center>
                        <div className="img-thumbnail">
                          <ReactPlayer
                            url={`${process.env.REACT_APP_BE_URL}/${a.details.url}`}
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </center>
                    )}
                </a>,
              ];

            let banner = "";
            banner =
              /* eslint-disable */
              [
                <a key={i.banner._id} onClick={() => toggleBanner(i)}>
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_BE_URL}/${b}`}
                    alt={b}
                    onError={(e) => {
                      e.target.src = News; //If Error
                    }}
                  />
                </a>,
              ];

            let news = "";
            news = [
              <a key={i.news._id} href={i.news.link} target="_blank" rel="noopener noreferrer">
                <img
                  className="img-thumbnail"
                  src={News}
                  href={i.news.link}
                  alt={i.news.link}></img>
              </a>,
            ];

            rowsData.push(
              createData(i.approved_time_start, i.approved_provinsi, video, banner, news)
            );
          }
          setRows(rowsData);
        } else {
          setRows([
            {
              jamtayang: "Tidak ada data",
              durasi: "",
              video: "",
              banner: "",
              berita: "",
            },
          ]);
        }
      })
      .catch((err) => {
        history.push("/pages-500");
        setRows([
          {
            jamtayang: "Terjadi Kesalahan",
            durasi: "",
            video: "",
            banner: "",
            berita: "",
          },
        ]);
      });
  }

  useEffect(() => {
    fetchKontenTayang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const data = {
    columns: [
      {
        label: "Jam Tayang",
        field: "jamtayang",
        sort: "asc",
        width: 200,
      },
      {
        label: "Provinsi",
        field: "provinsi",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Video",
        field: "video",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Banner",
        field: "banner",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Berita",
        field: "berita",
        sort: "disabled",
        width: 200,
      },
    ],
    rows:
      rows.length === 0
        ? [
          {
            jamtayang: "",
            durasi: "",
            video: "",
            banner: "",
            berita: "",
          },
        ]
        : rows,
  };

  const filterData = (filt) => {
    setFilter(filt);
    setRows([]); // Clear table
  };

  return (
    <React.Fragment>
      <PopupVideo
        modalVid={modalVideo}
        toggleVid={() => toggleVideo(dataVideo)}
        data={dataVideo}></PopupVideo>
      <PopupBanner
        modalBanner={modalBanner}
        toggleBanner={() => toggleBanner(dataBanner)}
        data={dataBanner}></PopupBanner>

      <Card style={{ boxShadow: "0px 0px 10px #D3D3D3" }}>
        <Row style={{ padding: "10px 10px" }}>
          <Col
            sm="6"
            md={{ size: 2, offset: 3 }}
            style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
            Provinsi
          </Col>
          <Col sm="6" md={{ size: 5 }}>
            <FilterButton filter={filter} clicked={filterData} />
          </Col>
          <Col sm="0" md={{ size: 3 }} />
        </Row>
      </Card>

      <Card
        style={{ boxShadow: "0px 0px 10px #D3D3D3", paddingBottom: "30px", paddingTop: "20px" }}>
        <CardBody>
          {rows.length === 0 ? (
            <div>
              <h1>
                <Skeleton />
              </h1>
              <Skeleton count={10} />
            </div>
          ) : (
              <MDBDataTable
                className="text-center"
                responsive
                striped
                bordered
                searching={false}
                displayEntries={false}
                info={false}
                entries={10} //How much data that you want to show in 1 table
                disableRetreatAfterSorting //Show red Warning after use
                data={data}
              />
            )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default KontenTayangTable;
