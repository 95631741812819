// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_headText__3Pvzd {\n\ttext-align: center !important;\n\tmargin-left: 30px !important;\n\tcolor: #55BA59 !important;\n}\n\n.styles_title__ikY0W {\n\tcolor: #333;\n\tfont-weight: bold;\n\ttext-transform: uppercase;\n}\n\n.styles_sub_title__3tNq8 {\n\tcolor: #888;\n}\n\n.styles_text__SKx7w {\n\tcolor: #888;\n\tfont-weight: bold;\n\tmargin-bottom: 50px;\n}\n\n.styles_btn_cancel__co4uk {\n\tcursor: pointer;\n}\n\n.styles_wrapper__1J3XB {\n\tmargin-bottom: 50px;\n\tdisplay: block !important;\n}\n\n.styles_btn_confirm__1C6-b {\n\tbackground-color: #55BA59 !important;\n\tpadding: 10px 40px !important;\n\tcolor: #fff !important;\n\tcursor: pointer;\n\tfont-size: 18px !important;\n\tmargin-bottom: 10px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"headText": "styles_headText__3Pvzd",
	"title": "styles_title__ikY0W",
	"sub_title": "styles_sub_title__3tNq8",
	"text": "styles_text__SKx7w",
	"btn_cancel": "styles_btn_cancel__co4uk",
	"wrapper": "styles_wrapper__1J3XB",
	"btn_confirm": "styles_btn_confirm__1C6-b"
};
module.exports = exports;
