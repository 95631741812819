import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Row, Card, Col, Button, Spinner } from "reactstrap";

import "tui-chart/dist/tui-chart.css";
import { PieChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import style from "./style.module.css";
import "./toastui.scss";

// import API from "../../../../services";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import DownloadStatusXLS from "./Download/XLS/DownloadStatusPerkawinanDetail";
// import DownloadTopicsXLS from "./Download/XLS/DownloadTopicsDetail";
export default function CardChart(props) {
  const DonutChartToast = (param, title, type) => {
    const data = {
      categories: [title],
      series: (type === "status"
        ? [
            {
              name: "Lajang",
              data: param ? (param.status ? param.status.lajang ?? 0 : 0) : 0,
            },
            {
              name: "Menikah",
              data: param ? (param.status ? param.status.menikah ?? 0 : 0) : 0,
            },
          ]
        : [
            {
              name: "Agribisnis",
              data: param ? param.agribisnis ?? 0 : 0,
            },
            {
              name: "Berita",
              data: param ? param.berita ?? 0 : 0,
            },
            {
              name: "Edukasi",
              data: param ? param.edukasi ?? 0 : 0,
            },
            {
              name: "Kesehatan",
              data: param ? param.kesehatan ?? 0 : 0,
            },
            {
              name: "Keterampilan Non Teknis",
              data: param ? param.keterampilan_non_teknis ?? 0 : 0,
            },
            {
              name: "Kewirausahaan",
              data: param ? param.kewirausahaan ?? 0 : 0,
            },
            {
              name: "Komunitas",
              data: param ? param.komunitas ?? 0 : 0,
            },
            {
              name: "Olahraga",
              data: param ? param.olahraga ?? 0 : 0,
            },
            {
              name: "Pariwisata",
              data: param ? param.pariwisata ?? 0 : 0,
            },
            {
              name: "Sains & Teknologi",
              data: param ? param["sains_&_teknologi"] ?? 0 : 0,
            },
          ]) ?? [
        {
          name: "",
          data: 0,
        },
      ],
    };

    const options = {
      chart: {
        width: 500,
        height: type == "status" ? 350 : 400,
        format: function (value, chartType, areaType, valuetype, legendName) {
          if (areaType === "makingSeriesLabel") {
            // formatting at series area
            value = value;
          }
          return value;
        },
      },
      series: {
        radiusRange: ["40%", "100%"],
        showLabel: true,
        // showLegend: true,
        // labelAlign: "outer",
        labelFilter: function (labelInfo) {
          return labelInfo.ratio > 0.3; // Less than 5% does not expose.
        },
      },
      tooltip: {
        suffix: "",
      },
      legend: {
        align: "bottom",
        // visible: type == "status" ? false : true,
        showCheckbox: false,
      },
      chartExportMenu: {
        // visible: false, // default is true.
        filename: "Chart_Status_Perkawinan_Demografi_Pengunjung",
      },
    };

    var theme = {
      // series: {
      series: {
        colors: [
          "#84a2e3",
          "#F0908E",
          "#91A7D5",
          "#ced591",
          "#EBB389",
          "#D591D2",
          "#91d5ce",
          "#c22525",
          "#f0f06c",
          "#ebc51e",
        ],
        label: {
          color: "#fff",
          fontFamily: "sans-serif",
        },
      },
      // },
    };

    // untuk apply theme
    TuiChart.registerTheme("demografiTheme", theme);
    options.theme = "demografiTheme";

    return (
      <div style={{ color: "white" }}>
        <PieChart data={data} options={options} theme={theme} />
      </div>
    );
  };

  return (
    <div>
      <Row className="mt-5 mb-5" style={{ height: "500px" }}>
        <Col md="12">
          {/* <Col md="12"> */}
          <Card body style={{ height: "100%", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
            <div className="p-3">
              <Row>
                <div className="mt-1 mx-auto">
                  <div className={`${style.titleCardBI} d-flex justify-content-center`}>
                    TOTAL {props.title.toUpperCase()} BERDASARKAN STATUS PERKAWINAN
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    {props.loading ? (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <Spinner color="primary" />
                      </div>
                    ) : props.data && props.data.length !== 0 ? (
                      DonutChartToast(props.data, props.title, "status")
                    ) : (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <span className={style.titleCardBI}>Tidak Ada Data</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col md="12">
          <Card
            body
            style={{ height: "100%", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
            <div className="p-3">
              <Row>
                <div className="mt-1 mx-auto">
                  <div className={`${style.titleCardBI} d-flex justify-content-center`}>
                    TOTAL {props.title.toUpperCase()} BERDASARKAN TOPIK FAVORIT
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    {props.loadingTopics ? (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <Spinner color="primary" />
                      </div>
                    ) : props.dataTopics && props.dataTopics.length !== 0 ? (
                      DonutChartToast(props.dataTopics, props.title, "topik")
                    ) : (
                      <div style={{ marginBottom: "150px", marginTop: "150px" }}>
                        <span className={style.titleCardBI}>Tidak Ada Data</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
      </Row> */}
    </div>
  );
}
