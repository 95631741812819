import React, { useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const DownloadXLS = (props) => {
  let role = window.localStorage.getItem("role");
  useEffect(() => {
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [props.data]);

  return (
    <>
      {props.activeTab === "1" ? (
        <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
          <thead>{`DATA SURVEY ${role}`}</thead>
          <thead>
            <tr>
              <th>No</th>
             
              <th>Nama Survei</th>
              <th>Jumlah Pertanyaan</th>
              <th>Provinsi</th>
              <th>Tanggal Awal</th>
              <th>Tanggal Akhir </th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>

                <td>{data ? data._id : "-"}</td>
                <td>{data ? data.questions.length : "-"}</td>
                <td>
                  {data.proposed_provinsi.length > 2
                    ? `${
                        data ? (data.proposed_provinsi[0] ? data.proposed_provinsi[0] : "-") : "-"
                      }, ${
                        data ? (data.proposed_provinsi[1] ? data.proposed_provinsi[1] : "-") : "-"
                      }, ${
                        data ? (data.proposed_provinsi[2] ? data.proposed_provinsi[2] : "-") : "-"
                      }...`
                    : data.proposed_provinsi.length > 1
                    ? `${data.proposed_provinsi[0] ? data.proposed_provinsi[0] : "-"},${
                        data.proposed_provinsi[1] ? data.proposed_provinsi[1] : "-"
                      }`
                    : `${data.proposed_provinsi[0] ? data.proposed_provinsi[0] : "-"}`}
                </td>
                <td>
                  {data
                    ? data.questions[0]
                      ? moment(data.questions[0].tanggal_pembuatan).format("DD-MM-YYYY")
                      : "-"
                    : "-"}
                </td>
                <td>
                  {data
                    ? data.questions[0]
                      ? moment(data.questions[0].tanggal_pembaruan).format("DD-MM-YYYY")
                      : "-"
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table style={{ display: "none" }} id="DownloadTableXLS" className="DownloadTableXLS">
          <thead>{`DATA SURVEY DEFAULT ${role}`}</thead>
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Survei</th>
              <th>Jumlah Pertanyaan</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data ? data.name : "-"}</td>
                <td>{data ? data.questions.length : "-"}</td>
                <td>{data ? data.status : "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default DownloadXLS;
