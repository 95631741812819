import React from "react";
import { CardBody, Card, Row, Button, Col, Spinner } from "reactstrap";

// STYLE
import style from "./style.module.css";

// ASSETS
import manIcon from "../../assets/icon/manicon.png";
import womanIcon from "../../assets/icon/womanicon.png";

// DOWNLOAD XLS
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import DownloadXLS from "./Download/XLS/DownloadGenderDetail";

export default function CardGender(props) {
  return (
    <div className="my-5">
      <Card className="p-3" style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
        <CardBody>
          <Row
            style={{
              paddingBottom: "15px",
              paddingTop: "15px",
            }}>
            <CardBody
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span className={style.titleCardBI}>
                TOTAL {props.title.toUpperCase()} BERDASARKAN JENIS KELAMIN
              </span>
              {/* <div className="float-right">
                <span>
                  Download <a href="#">XLS</a>
                </span>
              </div> */}

              {/* DOWNLOAD XLS */}
              {/* <div className="float-right">
                <span className="mr-2">Download</span>
                <ReactHTMLTableToExcel
                  id="TableXLS"
                  className="DownloadTableXLS"
                  table="DownloadTableXLS"
                  filename={`gender_details_${props.date}`}
                  sheet="users"
                  buttonText="XLS"
                />
              </div>
              <DownloadXLS
                id="DownloadTableXLS"
                className="DownloadTableXLS"
                data={props.data}
                title={props.title.toUpperCase()}
                date={props.date}
              /> */}
            </CardBody>
          </Row>
          <Row className="text-center my-3">
            <Col lg="6">
              <Row style={{ borderRight: "1px solid #C0C0C0" }}>
                <Col lg="4">
                  <div>
                    <img src={manIcon} />
                  </div>
                </Col>
                <Col lg="4">
                  <div className={style.titleMan}>LAKI - LAKI</div>
                  {props.loading ? (
                    <div className="my-3">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <div className={style.valueMan}>
                      {(props.data
                        ? props.data.gender
                          ? props.data.gender.pria ?? 0
                          : 0
                        : 0
                      ).toLocaleString()}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Row className="justify-content-end">
                <Col lg="4">
                  <div className={style.titleWoman}>PEREMPUAN</div>
                  {props.loading ? (
                    <div className="my-3">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <div className={style.valueWoman}>
                      {(props.data
                        ? props.data.gender
                          ? props.data.gender.wanita ?? 0
                          : 0
                        : 0
                      ).toLocaleString()}
                    </div>
                  )}
                </Col>
                <Col lg="4">
                  <div>
                    <img src={womanIcon} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
