import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

const DownloadXLS = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Table style={{ display: "none" }} id="DownloadTableXLSPeakHour" className="DownloadTableXLS">
      <thead>
        Trafik Peak Hour {props.org} Konten {props.content} Provinsi {props.prov}
      </thead>
      <thead>
        <tr>
          <th>Jam</th>
          {data ? (
            Object.values(data).length !== 0 ? (
              Object.keys(data.impress).map((e, i) => <th key={i}>{e}</th>)
            ) : (
              <th>-</th>
            )
          ) : (
            <th>-</th>
          )}
        </tr>
      </thead>
      <tbody>
        {data ? (
          Object.values(data).length !== 0 ? (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Impresi</td>
                {data && data.length !== 0
                  ? Object.values(data.impress).map((e, i) => <td key={i}>{e}</td>)
                  : "-"}
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Klik</td>
                {data && data.length !== 0
                  ? Object.values(data.clicked).map((e, i) => <td key={i}>{e}</td>)
                  : "-"}
              </tr>
            </>
          ) : (
            <>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Impresi</td>
                <td>-</td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td>Total Klik</td>
                <td>-</td>
              </tr>
            </>
          )
        ) : (
          <>
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>Total Impresi</td>
              <td>-</td>
            </tr>
            <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
              <td>Total Klik</td>
              <td>-</td>
            </tr>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default DownloadXLS;
