import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import API from "../../../services";

// ASSETS
import { useSelector } from "react-redux";

// STYLES
import "../styles.css";

// import Axios from "axios";

export default function FilterOrgProv(props) {
  const dataReduxLogHistory = useSelector((state) => state.dataReducer.dataLogHistory);

  // const [pilihOrganization, setPilihOrganization] = useState("Pilih Organisasi");

  const [listTitle, setListTitle] = useState([]);

  const [dataTitle, setDataTitle] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.filter.condition == "3"
      ? dataReduxLogHistory.data.filter.survey_title
      : ""
  );
  const [dataDate, setDataDate] = useState(
    Object.keys(dataReduxLogHistory.data).length !== 0
      ? dataReduxLogHistory.data.filter.start_date
      : "Tanggal"
  );

  const [listProvince, setListProvince] = useState([]);

  const getListProvince = (param) => {
    const titleFilter = param.filter((res) => {
      return res.title == dataTitle;
    });
    titleFilter.length !== 0 && setListProvince(titleFilter[0].province);
  };

  const getListTitle = () => {
    API.getFilterSurvey()
      .then((res) => {
        if (res.data.success) {
          setListTitle(res.data.data);
          getListProvince(res.data.data);
        } else {
          setListTitle([]);
        }
      })
      .catch((e) => {
        setListTitle(null);
      });
  };

  const onChangeTitle = (e) => {
    setDataTitle(e.title);
    setDataDate(e.date);
    setListProvince(e.province);
    // setDataProvince(e.province[0]);
  };

  //   const getListProvince = (param) => {
  //     const titleFilter = param.filter((res) => {
  //       return res.title == dataTitle;
  //     });
  //     titleFilter.length !== 0 && setListProvince(titleFilter[0].province);
  //   };

  const onChangeClearTitle = () => {
    setDataTitle("");
    setDataDate("Tanggal");
    setListProvince([]);
  };

  useEffect(() => {
    if (props.activeTab === "3") {
      props.setDataSave({
        survey_title: dataTitle,
        start_date: dataDate,
        measure: "survey",
        condition: "3",
      });
    }
    props.setTitle(dataTitle);
    props.setDate(dataDate);
    props.setListProvince(listProvince);
    getListTitle();
    const handleProps = () => {};
    handleProps();
    return () => {
      handleProps();
    };
  }, [dataTitle, props.activeTab]);

  const getData = () => {
    if (dataTitle == "") {
      return alert("Judul belum dipilih");
    } else {
      props.setShowComponent(false);
      props.setData([]);
      props.setLoading(true);
      const param = { date: dataDate, title: dataTitle };
      // console.log(param);

      // axios.post(Helper.getUserAPI(), data, {
      //   headers: headers
      // }
      // {
      //   headers: { "Access-Control-Allow-Origin": "*" },
      // }
      API.getDataStatistikSurveyDemography(param)

        // Axios.post(`http://api-staging.devlabs.id/bi/survey/question/demography`, param, {
        //   headers: { "Access-Control-Allow-Origin": "*" },
        // })
        // API.getDataStatistikSurveyDemography(param)
        .then((res) => {
          // console.log("masook");
          if (res.data.success) {
            props.setData(res.data.data);
            // console.log("a" ,res.data.data)
            props.setLoading(false);
            props.setShowComponent(true);
            // console.log(res.data);
          } else {
            // console.log("erroorrrssss");
            // props.setShowComponent(true);
            props.setData(null);
            props.setLoading(false);
            props.setShowComponent(true);
          }
        })
        .catch((e) => {
          props.setData(null);
          props.setLoading(false);
          props.setShowComponent(true);
          setTimeout(() => {
            alert(`Tidak ada data pada filter judul survey "${dataTitle}"`)
          }, 500);
          // console.log("errorrrr", e);
        });
    }
  };

  useEffect(() => {
    if (
      Object.keys(dataReduxLogHistory.data).length !== 0 &&
      dataReduxLogHistory.data.measure === "survey" &&
      dataReduxLogHistory.data.filter.condition == "3"
    ) {
      getData();
    }
  }, []);

  const resetFilter = () => {
    props.setShowComponent(false);
    props.setData([]);
    props.setLoading(false);
    onChangeClearTitle();
  };

  return (
    <>
      <Row className="py-2 mb-2">
        <Col lg="4" style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <div>Judul Survei</div>
          <Autocomplete
            // classes={style.dropdownAutocomplete}
            id="combo-box-demo"
            options={listTitle !== null ? listTitle : []}
            defaultValue={{ title: dataTitle }}
            value={{ title: dataTitle }}
            getOptionLabel={(option) => {
              return option.title;
            }}
            onChange={(e, value) => {
              value !== null ? onChangeTitle(value) : onChangeClearTitle();
            }}
            renderOption={(option) => (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    {option.title}
                    {
                      <span className="ml-2" style={{ fontSize: "10px" }}>
                        {option.date}
                      </span>
                    }
                  </div>
                </div>
              </React.Fragment>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // value={dataTitle}
                placeholder="Cari Judul Survei"
              />
            )}
            placeholder="Cari Judul Survei"
          />
        </Col>
        {/* <Col style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          <div>Tanggal Awal Survei</div>
          <ButtonDropdown
            style={{ width: "100%" }}
            disabled={true}
            isOpen={dropdown}
            toggle={toggleDropdown}>
            <DropdownToggle
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "20px",
                borderColor: "#2C3280",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}>
              <div
                style={{
                  color: dataTitle == "" ? "grey" : "#2C3280",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <span>{dataDate}</span>
              </div>
            </DropdownToggle>
          </ButtonDropdown>
        </Col> */}
        <Col lg="1" style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {" "}
          <div style={{ color: "transparent" }}>-</div>
          <Button
            style={{
              backgroundColor: "#2C3280",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "100%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={getData}>
            <span style={{ fontSize: "11px" }}>Cari</span>
          </Button>
        </Col>
        <Col lg="1" style={{ paddingLeft: "3px", paddingRight: "3px" }}>
          {" "}
          <div style={{ color: "transparent" }}>-</div>
          <Button
            disabled={dataTitle == "" && !props.showComponent}
            style={{
              backgroundColor: "#C42127",
              borderRadius: "20px",
              borderColor: "#2C3280",
              minWidth: "100%",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onClick={resetFilter}>
            <span style={{ fontSize: "11px" }}>Reset</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}
