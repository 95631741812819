import React from "react";
import { CardBody, Card, Row, Button, Col, Spinner } from "reactstrap";

// STYLE
import style from "./style.module.css";

// ASSETS
import ispIcon from "../../assets/images/isp_terdaftar.svg";
import ipIcon from "../../assets/images/ip_address.svg";
import markerIcon from "../../assets/images/marker_site.svg";

export default function CardISP(props) {
  return (
    <div className="my-5">
      <Col lg="12" style={{ minHeight: "100%" }}>
        <Row className="text-center h-100">
          <Col md="12" lg="4">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={ispIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>ISP TERINTEGRASI</div>
            </Col>
            {props.loading ? (
              <div className="my-3">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueISP}>
                {" "}
                {(props.data ? props.data.isp ?? 0 : 0).toLocaleString()}
              </Col>
            )}
          </Col>
          <Col
            md="12"
            lg="4"
            className="my-3"
            style={{ borderLeft: "1px solid #C0C0C0", borderRight: "1px solid #C0C0C0" }}>
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={ipIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>IP ADDRESS TERINTEGRASI ICMS</div>
            </Col>
            {props.loading ? (
              <div className="my-3">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueISP}>
                {" "}
                {(props.data ? props.data.ip ?? 0 : 0).toLocaleString()}
              </Col>
            )}
          </Col>
          <Col md="12" lg="4">
            <Col
              xs="12"
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img style={{ height: "50px" }} src={markerIcon} />
            </Col>
            <Col
              xs="12"
              className={`${style.titlePendidikan} mt-2`}
              style={{
                height: "33%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className={style.titlePendidikan}>LOKASI SITE AI TERINTEGRASI ICMS</div>
            </Col>
            {props.loading ? (
              <div className="my-3">
                <Spinner color="primary" />
              </div>
            ) : (
              <Col xs="12" style={{ height: "33%" }} className={style.valueISP}>
                {" "}
                {(props.data ? props.data.lokasi ?? 0 : 0).toLocaleString()}
              </Col>
            )}
          </Col>
        </Row>
      </Col>
      <Row className="text-center my-3 text-dark"></Row>
    </div>
  );
}
