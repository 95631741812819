import React, { useState } from "react";
import moment from "moment";
import { MDBModalHeader, MDBNavLink } from "mdbreact";
import { CardBody, Card, Row, Button, Col, Spinner, Modal, ModalBody } from "reactstrap";

// STYLE
import style from "./style.module.css";

// ASSETS
import berita from "../../assets/images/berita.png";

// DOWNLOAD XLS
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadXLSUB from "./Download/XLS/DownloadCardNewsUB";

export default function CardKontenNews(props) {
  // MODAL DOWNLOAD
  const [modalDownloadXLSUB, setModalDownloadXLSUB] = useState(false);
  const toggleDownloadXLSUB = () => {
    setModalDownloadXLSUB(!modalDownloadXLSUB);
  };
  return (
    <div className="my-5">
      <Card className="p-3" style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
        <CardBody>
          <Row
            style={{
              paddingBottom: "15px",
              paddingTop: "15px",
            }}>
            <CardBody style={{ display: "flex", alignItems: "center" }}>
              <img style={{ height: "30px" }} className="mr-2" src={berita} />
              <span className={style.titleCardBI} style={{ color: "#C18ECE" }}>
                KONTEN BERITA
              </span>
            </CardBody>
            <div>
              <Modal
                size="md"
                isOpen={modalDownloadXLSUB}
                toggle={toggleDownloadXLSUB}
                centered={true}>
                <MDBModalHeader toggle={toggleDownloadXLSUB}>
                  <span className={style.headText}>Download XLS</span>
                </MDBModalHeader>
                <ModalBody>
                  <center className="container-popup">
                    <div className={style.text}>Anda Yakin Untuk Download XLS?</div>
                    <Button color="" className={style.btn_reject} onClick={toggleDownloadXLSUB}>
                      Batal
                    </Button>
                    <Button
                      color=""
                      className={style.btn_confirm_xls}
                      onClick={toggleDownloadXLSUB}>
                      <ReactHTMLTableToExcel
                        id="TableXLS"
                        className={style.DownloadTableXLS}
                        table="DownloadTableXLSNewsUB"
                        filename={`news-user-behavior ${moment(new Date()).format(
                          "DD-MM-YYYY"
                        )}`}
                        sheet="users"
                        buttonText="Download"
                      />
                    </Button>
                  </center>
                </ModalBody>
              </Modal>
              {props.loading ? (
                ""
              ) : (
                <Col
                  className="mt-1"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}>
                  <MDBNavLink to="#" className={style.textDownload}>
                    Download
                  </MDBNavLink>
                  <MDBNavLink onClick={toggleDownloadXLSUB} link to="#">
                    XLS
                  </MDBNavLink>
                </Col>
              )}
              <DownloadXLSUB
                id="DownloadTableXLSNewsUB"
                className="DownloadTableXLS"
                data={props.data}
                date={props.date}
              />
            </div>
          </Row>
          <Row className="text-center my-3 text-dark">
            <Col lg="6">
              <div className={style.titlePendidikan}>JUMLAH KLIK CARD</div>
              {props.loading ? (
                <div className="my-3">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className={style.valuePendidikan}>
                  {props.data ? (props.data.card ? props.data.card.News ?? 0 : 0) : 0}
                </div>
              )}
            </Col>
            <Col lg="6" style={{ borderLeft: "1px solid #C0C0C0" }}>
              <div className={style.titlePendidikan}>JUMLAH KLIK POP UP</div>
              {props.loading ? (
                <div className="my-3">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className={style.valuePendidikan}>
                  {props.data ? (props.data.popup ? props.data.popup.News ?? 0 : 0) : 0}
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
