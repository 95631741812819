import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//data
import dataReducer from "./data/reducer";

// reducer disini direcah lagi ke file" lebi specific
// liat link di atas untuk tau reducer tersebut kemana
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  dataReducer,
});

export default rootReducer;
